<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
	   		<router-link v-if="isAdmin || isTM" :to="{path: '/modules'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to suite tools</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
		    <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
        <router-link :to="{ path: '/recycledtags' }" class="final-breadcumb"> Professional Updating Search </router-link>
      </div>
    </div>	

  	<div class="col-sm-12">
      <div class="col-xl-8 col-sm-11 justify-content-center col-centered">	
  		  <search/>
      </div>
  	</div>
  </div>
</template>

<script>
  import Search from "@/components/ProfessionalUpdatingComponents/Search.vue";

	export default {
    name: 'Search.vue',
    data() {
      return {
        tags: [],
        technologies: [],
        idProfile:"",
      }
    },
    components: {
      Search
    },
    computed:{
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      },
      isTM(){
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
            return true;
          }
        }
        return false
      }
    },
    mounted(){
    },
    methods: {
      close() {
        document.getElementById("message").style.display = "none"
      },
      close2() {
        document.getElementById("message2").style.display = "none"
      }
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>
