<template>
  <div id="chat-container">
    <interview-form :analysisRegistered="analysisRegistered"/>
  </div>
</template>

<script>
  import InterviewForm from '@/components/AnalysisComponents/InterviewForm.vue'

  export default {
    name: 'InterviewFormView',
    components: {
      InterviewForm
    },
    props: {
      analysisRegistered: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
#chat-container {
  overflow: auto !important;
}
</style>