import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'position_selection/';

class BusinessProcessService {

  findOne(selectionProcessId) {
    return http.get(API_URL_PATH + 'findOne?id=' + selectionProcessId, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area=' + idArea, { headers: authHeader() });
  }

  create(position_selection) {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    const hour = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateHour = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;

    position_selection.created_at = dateHour
    position_selection.last_edited = dateHour
    return http.post(API_URL_PATH + 'create', { position_selection: position_selection }, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);

    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  addUsersToBusinessProcess(id, users) {
    return http.post(API_URL_PATH + 'addUsersToBusinessProcess', { id: id, users: users }, { headers: authHeader() });
  }

  addCandidatesToBusinessProcess(id, candidates) {
    return http.post(API_URL_PATH + 'addCandidatesToBusinessProcess', { id: id, candidates: candidates }, { headers: authHeader() });
  }

  removeUsersFromBusinessProcess(id) {
    return http.delete(API_URL_PATH + 'removeUsersFromBusinessProcess?id=' + id, { headers: authHeader() });
  }

  update(udpateBusinessProcess) {
    return http.put(API_URL_PATH + 'update', { position_selection: udpateBusinessProcess }, { headers: authHeader() });
  }

  addTestsToBusinessProcess(id, tests) {
    return http.post(API_URL_PATH + 'addTestsToBusinessProcess', { id: id, tests: tests }, { headers: authHeader() });
  }

  addS4ProcessesToBusinessProcess (id, s4processes) {
    return http.post(API_URL_PATH + 'addS4ProcessesToBusinessProcess', { id: id, s4processes: s4processes }, { headers: authHeader() });
  }

  ranking(idBusinessProcess) {
    return http.get(API_URL_PATH + 'ranking?id=' + idBusinessProcess, { headers: authHeader() });
  }
}

export default new BusinessProcessService();