<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<a class="btn btn-secondary float-right" @click="backToSettings()"><font-awesome-icon icon="arrow-left" /> Back to profiles settings</a>
				<button :disabled="disableChanges" class="btn btn-secondary float-right createClass" style="margin-top: 0.7% !important;" @click="addLevel()" type="button"><font-awesome-icon icon="plus"/> ADD LEVEL</button>
            
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/settings-profiles' }" class="breadcumb"> Profiles settings > </router-link>
				<router-link :to="{ path: '/profile-levels-configuration' }" class="final-breadcumb"> Configuration of the Levels of Expertise </router-link>
			</div>
		</div>
		
		<div class="col-sm-12 col-centered">
			<profile-levels-configuration :addLevelProp="addLevelProp"/>
		</div>

		<b-modal id="cancel-modal" ref="cancel-modal" title="Cancel-modal" hide-footer>
			<template #modal-title>
				¿Do you really want to exit?
			</template>
			<div>
				<p>If you exit now, changes will be lost. </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('cancel-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="backToSettings()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
	import ProfileLevelsConfiguration from "@/components/AnalysisComponents/ProfileLevelsConfiguration.vue";
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

	export default {
	  	name: 'CreateProfileConfigurationView',
        data() {
            return {
                addLevelProp: true,
				disableChanges: false
            }
        },
	  	components: {
	      	ProfileLevelsConfiguration
	    },
   		props: {
      	},
		mounted () {
            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
                response => {
                    if (response.status == 200) {
                        if (response.data.length !== 0) {
                            this.disableChanges = true
                        }
                        else this.disableChanges = false
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
		},
		methods: {
            backToSettings() {
                this.$router.push({ path: 'settings-profiles'})
            },
			addLevel () {
				console.log('addLevel')
				if (this.addLevelProp) this.addLevelProp = false
				else this.addLevelProp = true
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>