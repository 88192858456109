<template>
  <div>
    <div class="row toggle-form">
      <div class="col-md-6">
        <p class="text-form-align">Do you have any working experience? &nbsp;&nbsp;&nbsp;</p>
      </div>
      <div class="col-md-1 col-input-width">
        <div class="form-check form-switch dichotomic-switch"><input @change="changeOption" class="form-check-input checkbox-required" type="checkbox" v-model="hasJobs"></div>
      </div>
      <div class="col-md-1">
        <p class="text-form-align-left">&nbsp;&nbsp;&nbsp;</p>
      </div>
    </div>

    <FormulateForm v-if="hasJobs"
      ref="experience_data"
      name="experience_data"
      v-model="result">
      <FormulateInput
        type="group"
        name="experience_jobs"
        label="Describe your Professional Career"
        add-label="+ Add job"
        :repeatable="true"
        validation="required"
      >
          <FormulateInput @input="input" name="job" :label="'Please, indicate the job title' + rawObligatoryAsterisk" validation="required" />
          <FormulateInput @input="input" type="textarea" name="description" label="Job description" help="Brief description of your work in the job between 50-150 words" validation="required" />
          <FormulateInput @input="input" name="company" label="Write the oficial name of the Company" validation="required" />
          <div class="row order-pos-form">
            <div class="col-md-6">
              <FormulateInput @input="input" type="date" name="date_start" label="When did you start working for this company?" help="Date when you started your job" :validation="'after:1970-01-01|before:'+ today" />
            </div>
            <div class="col-md-6">
              <FormulateInput @input="input" type="date" name="date_end" label="When did you finish working for this company?" help="Date when you finished your job" :validation="'after:1970-01-01|before:'+ today" />
            </div>
          </div>
      </FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>

  export default { 
    name: 'ExperienceStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {},
        hasJobs: false,
        isSubmitted: false,
        rawObligatoryAsterisk: "*",
        today: null,
      } 
    }, 
    mounted() {
      this.fetchData()
      var date = new Date();
      this.today = date.toISOString().split('T')[0]
    },
    methods: {
      formatDate(date) {
        if (date == null) return "";

        let newDate = date.split(" ");
        let year = "";
        let month = "";
        const monthNames = {
          "Jan": "01", 
          "Feb": "02", 
          "Mar": "03",
          "Apr": "04", 
          "May": "05", 
          "Jun": "06",
          "Jul": "07", 
          "Aug": "08",
          "Sep": "09", 
          "Oct": "10", 
          "Nov": "11", 
          "Dec": "12"
        };

        if (newDate[0] == "Present" || newDate[0] == "Present"){
          year = new Date().getFullYear().toString();
          month = "01";
        } else {
          year = newDate[1];
          month = monthNames[newDate[0]];
        }

        return year + "-" + month;
      },
      async fetchData() {
        let data = await this.$parent.$parent.data;
        if (data.jobs != undefined && data.jobs != ''){
          this.result.experience_jobs = data.jobs
          this.isSubmitted = true
          this.hasJobs = true;
        } else{
          this.result.experience_jobs = []
          this.hasJobs = false;
          this.isSubmitted = false
        }

        let tmpJobs = [];
        this.result.experience_jobs.forEach((job) => {
          let jobTmp = job;
          const dateTmp = jobTmp.date.split(" - ");

          const start = this.formatDate(dateTmp[0]);
          const end = this.formatDate(dateTmp[1]);

          jobTmp.date_start = start;
          jobTmp.date_end = end;

          tmpJobs.push(jobTmp)
        })

        this.result.experience_jobs = tmpJobs

        if (this.isSubmitted || !this.hasJobs)
          this.$emit('can-continue', {value: true});
        else
          this.$emit('can-continue', {value: false});
      },
      isValid() {
        let allValid = false;
        if (this.result.experience_jobs != undefined){
          this.result.experience_jobs.forEach((r) => {
            if (Object.keys(r).length == 5 && Object.values(r).every(el => el != undefined)) {
              allValid = true;
            } else {
              allValid = false;
            }
          })
        }
        return allValid;
      },
      changeOption() {
        if (!this.hasJobs){
          this.result.experience_jobs = [];
          this.$emit('can-continue', {value: true});
        } else {
          this.$emit('can-continue', {value: false});
        }
      },
      input(){
        if (this.hasJobs && !this.isSubmitted) {
          if (this.isValid()){
            this.$emit('can-continue', {value: true});
            this.isSubmitted = true;
          } else {
            this.$emit('can-continue', {value: false});
          }
        }
      },
    } 
  }
</script>