<template>
    <div>
        <div class="col-md-12">
            <div class="row secondNavbar"> 
                <div class="col-5 links-container">
                    <router-link to="/tests" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Tests</router-link>
                </div>
                <div v-if="isAdmin" class="col-7 noMargin whiteColor">
                    <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                    <router-link :to="{ path: '/tests' }" class="breadcumb"> Tests > </router-link>
                    <router-link :to="{ path: '/results' }" class="final-breadcumb"> Results </router-link>
                </div>
                <div v-else class="col-7 noMargin whiteColor">
                    <router-link :to="{ path: '/tests' }" class="breadcumb"> Tests > </router-link>
                    <router-link :to="{ path: '/results' }" class="final-breadcumb"> Results </router-link>
                </div>
            </div>
            <br>
            <global-results/>
            <br><br>
            <individual-results/>
        </div>
    </div>
</template>

<script>
  	import GlobalResults from "@/components/TestComponents/GlobalResults.vue";
    import IndividualResults from "@/components/TestComponents/IndividualResults.vue";

	  export default {
        name: 'ResultsTest',
        components: {
            GlobalResults,
            IndividualResults
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            isAdmin() {
                if (this.currentUser && this.currentUser.role) {
                    if (this.currentUser.role.name === 'admin') {
                        return true;
                    }
                }
                return false;
            }
        }
	  };

</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>