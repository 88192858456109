<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-6 links-container">
				<router-link v-if="isAdmin || isTM" :to="{ path: '/evaluation' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Evaluation</router-link>
				<router-link v-else :to="{ path: '/examiner' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to Home</router-link>
				<router-link to="/create-test" class="btn btn-secondary float-right createClass"><font-awesome-icon
						icon="plus" /> CREATE ASSESSMENT TEST</router-link>
			</div>
			<div v-if="isAdmin" class="col-6 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
				<router-link :to="{ path: '/tests' }" class="final-breadcumb">Assessment Tests </router-link>
			</div>
			<div v-else class="col-6 noMargin whiteColor">
				<router-link :to="{ path: '/tests' }" class="final-breadcumb">Assessment Tests </router-link>
			</div>
		</div>

		<div class="px-4">
			<div id="message" v-if="testRegistered" class="alert alert-success col-md-12 align-item-center">
				Test registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message2" v-if="testUpdated" class="alert alert-success col-md-12 align-item-center">
				Test updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
			<div id="message3" v-if="questionsAdded" class="alert alert-success col-md-12 align-item-center">
				Questions added succesfully! <b-button @click="close3" class="cross-button"><font-awesome-icon
						class="cross" icon="times" /> </b-button>
			</div>
		</div>

		<tests-list :headers="headers" />
	</div>
</template>

<script>
import TestsList from "@/components/TestComponents/List.vue";

export default {
	name: 'QuestionsView',
	components: {
		TestsList
	},
	props: {
		testRegistered: {
			type: Boolean,
			default: false
		},
		testUpdated: {
			type: Boolean,
			default: false
		},
		questionsAdded: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		headers() {
			return [
				{ key: 'title', label: 'Title', sortable: true, tdClass: 'tdBig' }, { key: 'done', label: 'Attempts', sortable: true, tdClass: 'tdMedium' }, { key: 'average_qualification', label: 'Average Score (Max. score: 100)', sortable: true, tdClass: 'tdBig' }, { key: 'difficulty_level', label: 'Level of difficulty', sortable: true, tdClass: 'tdMedium' }, { key: 'created_at', label: 'Created at', tdClass: 'tdMedium' }, { key: 'updated_at', label: 'Updated at', tdClass: 'tdMedium' }, { key: 'options', label: 'Options', tdClass: 'tdMedium' }
			];
		},
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		},
		close3() {
			document.getElementById("message3").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.nav-link-user-submenu {
	color: #13283B !important;
}

.alert {
	margin-right: 30px;
}
</style>
