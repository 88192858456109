<template>
  <Progress
    :transitionDuration="transitionDuration"
    :radius="radius"
    :strokeWidth="strokeWidth"
    :value="value"
    :strokeColor="strokeColor"
  >
    <template v-slot:footer>
      <b>{{footer}}</b>
    </template>
  </Progress>
</template>

<script>
import Progress from "easy-circular-progress";

export default {
  name: "ProgressCircleComponent",
  components: {
    Progress
  },
  props: {
    transitionDuration: Number,
    radius: Number,
    strokeWidth: Number,
    value: Number,
    footer: String,
    strokeColor: String
  }
};
</script>