export default class Test {
  constructor(id,title,description,done,average_qualification,id_difficulty_level,questions_same_difficulty,
  	questions_average_difficulty,start_date,end_date,time_limit,number_questions,incorrect_value,number_attempts,
  	id_language,shuffle,id_area) {
    this.id = id;
    this.title = title;
  	this.description = description;
  	this.done = done;
  	this.average_qualification = average_qualification;
  	this.id_difficulty_level = id_difficulty_level;
  	this.questions_same_difficulty = questions_same_difficulty;
  	this.questions_average_difficulty = questions_average_difficulty;
  	this.start_date = start_date;
  	this.end_date = end_date;
  	this.time_limit = time_limit;
  	this.number_questions = number_questions;
  	this.incorrect_value = incorrect_value;
  	this.number_attempts = number_attempts;
  	this.id_language = id_language;
  	this.shuffle = shuffle;
  	this.id_area = id_area;
  }
}