<template>
	<div class="dashboard">
		<div v-if="!showWelcome" class="welcome justify-content-center">
			<p class="welcome-message">{{ $t('dashboards.general.welcome-back') }} {{ currentUser.username }}!</p>
		</div>
		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName" :cardImage="card.image" :cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>

		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
import FooterMini from "@/components/FooterMini.vue";
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
import MenuCard from '@/components/MenuCard';

export default {
	name: 'UserDashboardView',
	components: {
		MenuCard,
		appFooter: FooterMini
	},
	data() {
		return {
			cards: []
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		}
	},
	props: {
		showWelcome: Boolean
	},
	mounted() {
		const recaptcha = this.$recaptchaInstance

		if (recaptcha) recaptcha.hideBadge();

		if (this.currentUser.company.hard_skills_module_enabled) this.cards.push({
			"routeName": "/available-tests",
			"image": "images/icons/icono_test.svg",
			"title": this.$t('dashboards.user.tests.title'),
			"description": this.$t('dashboards.user.tests.description')
		})

		if (this.currentUser.company.cv_module_enabled) this.cards.push({
			"routeName": "/analyses",
			"image": "images/icons/cv_results_icon.svg",
			"title": this.$t('dashboards.user.cvs.title'),
			"description": this.$t('dashboards.user.cvs.description')
		})

		if (this.currentUser.company.evaluation_360_module_enabled) this.cards.push({
			"routeName": "/evaluation-360",
			"image": "images/icons/logoBEI360.svg",
			"title": "BEI/360 Evaluation",
			"description": "Pending and completed evaluations to do"
		})

		if (this.currentUser.company.cv_module_enabled) {
			ProfileAnalysisDataService.findCVsByUser(this.currentUser.id).then(
				response => {
					this.cards.forEach(card => {
						if (card.title === "My CVs" || card.title === "Mis CVs") {
							if (response.data.length === 0) card.routeName = "/create-analysis"
							else card.routeName = "/analyses"
						}
					})
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			)
		}
	}
};
</script>

<style scoped>
.welcome {
	background-color: #8080807e;
	padding-left: 0px;
	padding-right: 0px;
	height: 40px;
	font-size: 18px;
	font-weight: bold;
	position: relative;
}

#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.welcome-message {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	padding: 0;
	list-style-type: none;
}

.available-tests {
	color: #4FC0E8;
	font-weight: bold;
}
</style>