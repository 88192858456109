<template>
	<div class="col-sm-10 justify-content-center">
        <div class="row noMargin">
            <div class="col-sm-10">
                <h4 class="titleClass">Select a level of "{{selectedLevelName.toUpperCase()}}" to configure</h4>
            </div>
        </div>
        <div class="row my-2 noMargin">
            <div class="col-sm-6"> 
                <div class="row noMargin">
                    <div class="col-sm-6 ">
                        <select class="form-select" data-vv-name="technology_selector" v-model="levelText" id="technology_selector_questions_level" @change="onChange($event)">
                            <option v-for="element in profileLevelsFather" v-bind:key="element.name" :value="element.name" >
                                <template slot="button-content">
                                    <img v-bind:src="element.logo" class="card-avatar card-avatar--circle" />
                                    {{ element.name.toUpperCase() }}
                                </template>
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row noMargin">
            <div class="col-sm-10">
                <h4 class="titleClass">Select an AREA to display Profiles Required to configure</h4>
            </div>
        </div>
        <div class="row my-2 noMargin">
            <div class="col-sm-6"> 
                <div class="row noMargin">
                    <div class="col-sm-7 ">
                        <select class="form-select" data-vv-name="technology_selector" v-model="areaModel" id="technology_selector_questions_level" @change="onChangeArea($event)">
                            <option v-for="element in areas" v-bind:key="element.name" :value="element.name" >
                                <template slot="button-content">
                                    <img v-bind:src="element.logo" class="card-avatar card-avatar--circle" />
                                    {{ element.name.toUpperCase() }}
                                </template>
                            </option>
                        </select> 
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-show="showMenu">
                <div class="row noMargin">
                    <div class="col-sm-10 noMargin noMarginLow">
                        <h4>TECHNOLOGIES to readapt</h4>
                    </div>
                </div>
                <div v-if="techsToShow !=null && techsToShow !=undefined && techsToShow.length >0">
                    <div
                    v-for="(element,index) in techsToShow"
                    :key="element.name"
                    >
                        <div class="row noMargin ">
                            <div class="col-sm-5 list-group-item ">
                                <img v-bind:src="element.logo" height="20em" class="card-avatar--circle" />
                                {{ element.name.toUpperCase() }}
                            </div>
                            <div class="col-sm-4 ">
                                <select class="form-select centerText" data-vv-name="technology_selector" v-model="element.level" id="technology_selector_questions_level" @change="changeLevel($event,index)" >
                                    <option v-for="level in profileLevels" v-bind:key="level.name" :value="level.name" >
                                        <template slot="button-content">
                                            {{ level.name.toUpperCase() }}
                                        </template>
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-1">
                                <button v-b-tooltip.hover title="Configure reskilling" class="btn btn-secondary" @click="openConfig(element.name, element.level,element.id, element.logo)"><font-awesome-icon icon="cog" /></button>
                            </div>

                            <b-collapse v-if="secondTech != null && secondTech != undefined" v-bind:id="'toggleT-' + index.toString()" >
                                <div class="col-10 noMargin">
                                    <div class="row noMargin">
                                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                                            <h5 class="profile-name-title">INFORMATION</h5>
                                            <button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleInfo'+ index.toString()" @click="changeShowStateInfo(index)">
                                                <font-awesome-icon style="display: block;" v-bind:id="'iconShowInfo-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                <font-awesome-icon style="display: none;" v-bind:id="'iconNotShowInfo-'+ index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                            </button>
                                        </div>
                                        <b-collapse v-bind:id="'toggleInfo' + index.toString()">
                                            <div class="row noMargin">
                                                <div class="col-12">
                                                    <div class="row noMargin">
                                                        <div class="col-6">
                                                            <div class="alignCenterColumns">  
                                                                <img v-bind:src="dataFatherTechnology.logo" height="20em" class="card-avatar--circle" />
                                                                <h5 class="profile-name">{{dataFatherTechnology.name.toUpperCase()}} {{dataFatherTechnology.level.toUpperCase()}}</h5> 
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="alignCenterColumns">  
                                                                <img v-bind:src="element.logo" height="20em" class="card-avatar--circle" />
                                                                <h5 class="profile-name">{{element.name.toUpperCase()}} {{element.level.toUpperCase()}}</h5> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 class="title " @click="visibleCertifications = !visibleCertifications">Certifications
                                                        <font-awesome-icon v-if="!visibleCertifications" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                    </h6>
                                                    <div class="alignCenterColumns">
                                                        <b-collapse id="collapseId" v-model="visibleCertifications"> 
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="divItems" v-if="dataFatherTechnology.certifications.length != 0 && dataFatherTechnology.certifications.length != undefined">
                                                                        <div class="added-certifications" v-for="certificationsItem in dataFatherTechnology.certifications" :key="certificationsItem.id" >
                                                                            <CertificationsCapsule :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divItems" v-else>
                                                                        <p>No Specific Certifications Requested.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="divItems" v-if="(secondTech.certifications.difference != 0 && secondTech.certifications.difference != undefined) 
                                                                    ||((secondTech.certifications.similar != 0 && secondTech.certifications.similar != undefined))" >
                                                                        <div class="added-certifications" v-for="certificationsItem in secondTech.certifications.similar" :key="certificationsItem.id" >
                                                                            <CertificationsCapsule class="greenCapsuleClass" :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                                        </div>
                                                                        <div class="added-certifications" v-for="certificationsItem in secondTech.certifications.difference" :key="certificationsItem.id" >
                                                                            <CertificationsCapsule class="redCapsuleClass" :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divItems" v-else>
                                                                        <p>No Specific Certifications Requested.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>
                                                    <h6 class="title" @click="visibleEducation = !visibleEducation">Education
                                                        <font-awesome-icon v-if="!visibleEducation" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                    </h6>
                                                    <div class="alignCenterColumns">
                                                        <b-collapse id="collapseId" v-model="visibleEducation"> 
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="divItems" v-if="dataFatherTechnology.education.length != 0 && dataFatherTechnology.education.length != undefined">
                                                                        <div class="added-education" v-for="educationItem in dataFatherTechnology.education" :key="educationItem.id" >
                                                                            <EducationCapsule :name="educationItem.level" :item="educationItem" :del="del"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divItems" v-else>
                                                                        <p>No education defined.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="divItems" v-if="(secondTech.education.difference != 0 && secondTech.education.difference != undefined) 
                                                                    ||((secondTech.education.similar != 0 && secondTech.education.similar != undefined))" >
                                                                        <div class="added-education" v-for="educationItem in secondTech.education.similar" :key="educationItem.id" >
                                                                            <EducationCapsule class="greenCapsuleClass" :name="educationItem.level" :item="educationItem" :del="del"/>
                                                                        </div>
                                                                        <div class="added-education" v-for="educationItem in secondTech.education.difference" :key="educationItem.id" >
                                                                            <EducationCapsule class="redCapsuleClass" :name="educationItem.level" :item="educationItem" :del="del"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divItems" v-else>
                                                                        <p>No education defined.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>
                                                    <h6 class="title " @click="visibleExp = !visibleExp">Years of Experience
                                                        <font-awesome-icon v-if="!visibleExp" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                    </h6>
                                                    <div class="alignCenterColumns">
                                                        <b-collapse id="collapseId" v-model="visibleExp"> 
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="divItems">
                                                                        <p v-if=" dataFatherTechnology.experience_time_min_years.is_required" style="font-weight: bold; font-size: 17px;">Min. years: {{ dataFatherTechnology.experience_time_min_years.value}}</p>
                                                                        <p v-else>Min. years: {{ dataFatherTechnology.experience_time_min_years.value}}</p>

                                                                        <p v-if=" dataFatherTechnology.experience_time_max_years != null && dataFatherTechnology.experience_time_max_years.value != undefined &&  dataFatherTechnology.experience_time_max_years.is_required" style="font-weight: bold; font-size: 17px;">Max. years: {{ dataFatherTechnology.experience_time_max_years.value}}</p>
                                                                        <p v-if=" dataFatherTechnology.experience_time_max_years != null && dataFatherTechnology.experience_time_max_years.value != undefined && ! dataFatherTechnology.experience_time_max_years.is_required">Max. years: {{ dataFatherTechnology.experience_time_max_years.value}}</p>

                                                                        <p v-if="dataFatherTechnology.experience_time_max_years == null || dataFatherTechnology.experience_time_max_years.value == undefined">Max. years: No limit</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="divItems">
                                                                        <div v-if=" secondTech.min_experience.difference != null && secondTech.min_experience.difference !=undefined"> 
                                                                            <p v-if="secondTech.min_experience.difference < 0" style="font-weight: bold; font-size: 17px;">{{- secondTech.min_experience.difference}} year/s required</p>
                                                                        </div>
                                                                        <div v-else>
                                                                            <p style="font-weight: bold; font-size: 17px;">No experience required</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>
                                                    <h6 class="title " @click="visibleTech = !visibleTech">Technical Skills
                                                        <font-awesome-icon v-if="!visibleTech" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                    </h6>
                                                    <div class="alignCenterColumns">
                                                        <b-collapse id="collapseId" v-model="visibleTech"> 
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <template v-for="skill in dataFatherTechnology.skills">
                                                                        <div :key="skill.id" v-if="skill.is_tech == 1">
                                                                            <h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                                            <div class="divItems" v-if="skill.items.length != 0">
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="divItems" v-else>
                                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="divItems" v-if="!dataFatherTechnology.hayTechnologySkills">
                                                                        <p>No Technical Skills defined.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <template v-for="skill in secondTech.skills">
                                                                        <div :key="skill.id" v-if="skill.is_tech == 1">
                                                                            <h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                                            <div class="divItems" v-if="skill.difference.length != 0 || skill.similar.length != 0">
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.similar" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule class="greenCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.difference" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule class="redCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="divItems" v-else>
                                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                                            </div> 
                                                                        </div>
                                                                    </template>
                                                                    <div class="divItems" v-if="!dataFatherTechnology.hayTechnologySkills">
                                                                        <p>No Technical Skills defined.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>
                                                    <h6 class="title " @click="visibleNotTech = !visibleNotTech">Non-Technical Skills
                                                        <font-awesome-icon v-if="!visibleNotTech" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                    </h6>
                                                    <div class="alignCenterColumns">
                                                        <b-collapse id="collapseId" v-model="visibleNotTech"> 
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <template v-for="skill in  dataFatherTechnology.skills">
                                                                        <div :key="skill.id" v-if="skill.is_tech == 0">
                                                                            <h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                                            <div class="divItems" v-if="skill.items.length != 0">
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="divItems" v-else>
                                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                                            </div> 
                                                                        </div>
                                                                    </template>
                                                                    <div class="divItems" v-if="!dataFatherTechnology.hayNonTechnologySkills">
                                                                        <p>No Non-Technical Skills defined.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <template v-for="skill in secondTech.skills">
                                                                        <div :key="skill.id" v-if="skill.is_tech == 0">
                                                                            <h6 class="subtitle title">{{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                                            <div class="divItems" v-if="skill.difference.length != 0 || skill.similar.length != 0">
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.similar" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule class="greenCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                                <div class="added-frameworks" v-for="(item, index) in skill.difference" :key="item.item + '-' + index" >
                                                                                    <SkillsCapsule class="redCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="divItems" v-else>
                                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <div class="divItems" v-if="!secondTech.hayNonTechnologySkills">
                                                                        <p>No Non-Technical Skills defined.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-collapse>
                                                    </div>  
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                    <hr class="thicker">
                                    <div class="row justify-content-center">
                                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                                            <h5 class="profile-name-title">SETTINGS</h5>
                                            <button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleSettings'" @click="changeShowStateSettings()">
                                                <font-awesome-icon style="display: block;" v-bind:id="'iconShowSettings' " class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                <font-awesome-icon style="display: none;" v-bind:id="'iconNotShowSettings'" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                            </button>
                                        </div>
                                        <b-collapse v-bind:id="'toggleSettings'">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="alignCenterColumns">  
                                                            <h5 class="profile-name noBold"> Set Time to Reskill To &nbsp;</h5>
                                                            <h5 class="profile-name"> {{element.name.toUpperCase()}} <img v-bind:src="element.logo" height="20em" class="card-avatar--circle" />
                                                            {{element.level.toUpperCase()}}</h5> 
                                                        </div>
                                                        <div class="col-10 aligCenterColumns">
                                                            <hr class="thicker">
                                                        </div> 
                                                    </div>
                                                    <div class="row my-2">
                                                        <div class="col-6">
                                                            <div class="alignCenterColumns">  
                                                                <h5 class="title">Field</h5>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="alignCenterColumns">  
                                                                <h5 class="title">Time to learn &nbsp;</h5>
                                                                <h5 class="profile-name noBold"> (in hours) </h5>
                                                            </div>
                                                        </div>
                                                        <hr class="thicker">
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div>
                                                                <h6 class="title">Certifications</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 alignCenterColumns" v-if="secondTech.certifications == undefined">
                                                                No extra certifications needed
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div v-if="secondTech.certifications!= undefined"> 
                                                            <div v-for="certificationsItem in secondTech.certifications.difference" :key="certificationsItem.id" class="row">                             
                                                            <div class="col-6 alignCenterColumns">
                                                                <div class="added-certifications" >
                                                                    <CertificationsCapsule :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 alignCenterColumns">
                                                                    <input class="form-input fitContent" max="100" min="1" type="number">
                                                                </div>           
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div>
                                                                <h6 class="title">Education</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 alignCenterColumns" v-if="secondTech.education == undefined">
                                                            No extra education needed
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div v-if="secondTech.education!= undefined"> 
                                                            <div v-for="educationItem in secondTech.education.difference" :key="educationItem.id" class="row">                             
                                                                <div class="col-6 alignCenterColumns">
                                                                    <div class="added-education" >
                                                                        <EducationCapsule  :name="educationItem.level" :item="educationItem" :del="del"/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 alignCenterColumns">
                                                                    <input class="form-input fitContent" max="100" min="1" type="number">
                                                                </div>           
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div>
                                                                <h6 class="title">Technical Skills</h6>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 alignCenterColumns" v-if="secondTech.education == undefined">
                                                                No extra Tech Skills needed
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div v-if="secondTech.education!= undefined"> 
                                                            <div v-for="educationItem in secondTech.education.difference" :key="educationItem.id" class="row">                             
                                                                <div class="col-6 alignCenterColumns">
                                                                    <div class="added-education" >
                                                                        <EducationCapsule  :name="educationItem.level" :item="educationItem" :del="del"/>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 alignCenterColumns">
                                                                    <input class="form-input fitContent" max="100" min="1" type="number">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>   
                    </div>
                </div>
                <div v-else class="row noMargin">
                    <div class="col-sm-10 noMargin noMarginLow">
                        <h5><font-awesome-icon icon="times-circle" class="black-color"/> No technologies to display.</h5>
                    </div>
                </div>
        </div>
        <template v-for="(errorMessage, index) in errorsMessages">
            <div :id="index" class="alert alert-danger alert-edit d-flex align-items-center justify-content-center" v-bind:key="errorMessage">
                {{errorMessage}} <b-button @click="close(index)" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
            </div>
        </template>
	</div>
</template>

<script>
    import AreasDataService from '@/services/AreasDataService';
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
	import EducationCapsule from "@/components/Others/EducationCapsule.vue"
	import ProgrammingSkillsCapsule from "@/components/Others/ProgrammingSkillsCapsule.vue"
	import CertificationsCapsule from "@/components/Others/CertificationsCapsule.vue"
	import FrameworksCapsule from "@/components/Others/FrameworksCapsule.vue"
	import ToolsCapsule from "@/components/Others/ToolsCapsule.vue"
	import OthersCapsule from "@/components/Others/OthersCapsule.vue"
	import SkillsCapsule from "@/components/Others/SkillsCapsule.vue"

	export default {
		name: 'RecycledTagsListComponent',
		data() {
            return {
                selectedLevel: [],
                selectedLevelName: "",
                selectedTechId: "",
                selectedLevelId: "",
                easyProfiles: [],
                posibleProfiles: [],
                showMenu: true,
                showData: false,
                allTechnologies: [],
                techsToShow: [],
                profileLevelsFather: [],
                profileLevels: [],
                flagIsEmpty : false, 
                technologiesUpdated: false, 
                experienceTimeHours: [],
                errorsMessages: [],
                successfull: null, 
                technologyText: "Select technology",
                levelText: "Select a level",
                configureTechName: "",
                dataFatherTechnology: "",
                del: false,
                compareObjects: [],
                secondTech: null,
                collapseId: true,
                visibleCertifications: false,
                visibleEducation: false,
                visibleExp: false,
                visibleTech: false,
                visibleNotTech: false,
                logoFatherTech: "", 
                areas: "",
                areaModel: "",
                selectedAreaId: ""
            }
	    },
        components: {
			EducationCapsule,
			FrameworksCapsule,
			ProgrammingSkillsCapsule,
			ToolsCapsule,
			OthersCapsule,
			CertificationsCapsule,
			SkillsCapsule,
	    },
        mounted(){
            this.selectedTechId = this.$route.query.idProfile; 
            if(this.selectedTechId == undefined || this.selectedTechId == null){
                this.$route.push('/config-profile');
            }
            
            ProfileConfigurationDataService.findLevelsConfigurationByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    for(let i=0; i<response.data.length; i++){
                        if(response.data[i].id == this.selectedTechId){
                            this.selectedLevelName = response.data[i].name;
                            this.logoFatherTech = this.$awsBucketRoute + response.data[i].logo
                        }
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );

            AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {
                    if (response.status == 200){
                        this.areas = response.data
                        this.areaModel = this.areas[0].name
                        this.selectedAreaId = this.areas[0].id
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                        
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );

            ProfileConfigurationDataService.findProfileLevelsByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    this.profileLevelsFather = response.data; 
                    this.levelText = response.data[0].name;
                    this.selectedLevelId = response.data[0].id;
                    this.getAllData(); 
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );

            this.getLevelsFromCompany()
        },
	  	methods: {
            capitalizarPrimeraLetra(str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
            getLevelsFromCompany(){
                ProfileConfigurationDataService.findProfileLevelsByArea(this.selectedAreaId).then(
                    response => {
                        this.profileLevels = response.data; 
                        this.levelText = response.data[0].name;
                        this.selectedLevelId = response.data[0].id;
                        this.getAllData(); 
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getAllData(){
                ProfileConfigurationDataService.findProfileLevel(this.selectedTechId, this.selectedLevelId).then(
                    response => {
                        this.dataFatherTechnology = response.data;
                        this.dataFatherTechnology.id = this.selectedTechId; 
                        this.dataFatherTechnology.name = this.selectedLevelName;
                        this.dataFatherTechnology.level = this.levelText;
                        this.dataFatherTechnology.logo = this.logoFatherTech
                        var skillsForTechnologyAndProfile = this.dataFatherTechnology.skills
                        var hayTechSkills = false
                        var hayNonTechSkills = false
                        for (var j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                            if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                hayTechSkills = true
                            } else {
                                hayNonTechSkills = true
                            }
                        }
                        if(hayTechSkills) {
                            this.dataFatherTechnology.hayTechnologySkills = true
                        } else {
                            this.dataFatherTechnology.hayTechnologySkills = false
                        }
                        if(hayNonTechSkills) {
                            this.dataFatherTechnolog.hayNonTechnologySkills = true
                        } else {
                            this.dataFatherTechnology.hayNonTechnologySkills = false
                        }
                        
                        this.compareObjects.push(this.dataFatherTechnology);
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );

                ProfileConfigurationDataService.recycledProfilesByProfile(this.selectedTechId, this.selectedLevelId, this.selectedAreaId).then(
                    response => {
                        if(response.data.available_profiles != undefined && response.data.available_profiles.length > 0){
                            this.techsToShow = response.data.available_profiles; 
                            for(var i=0; i<this.techsToShow.length; i++){
                                this.experienceTimeHours[i] = 0;
                                this.techsToShow[i].logo = this.$awsBucketRoute + this.techsToShow[i].logo;
                                this.techsToShow[i].level = this.levelText; 
                                this.techsToShow[i].levelId = this.selectedLevelId;
                            }
                        } else if(response.data.recycled_profiles != undefined && response.data.recycled_profiles.length > 0){
                            this.techsToShow = response.data.recycled_profiles

                            for(var i=0; i<this.techsToShow.length; i++){
                                this.techsToShow[i].logo = this.$awsBucketRoute + this.techsToShow[i].logo;
                                this.techsToShow[i].name = this.techsToShow[i].name_profile; 
                                this.techsToShow[i].id = this.techsToShow[i].id_profile;

                                if(this.techsToShow[i].name_parent_profile == this.levelText){
                                    this.techsToShow[i].level = this.techsToShow[i].name_recycle_profile;
                                    this.techsToShow[i].levelId = this.techsToShow[i].id_recycle_profile_level;
                                    let effort = JSON.parse(this.techsToShow[i].effort);
                                    this.experienceTimeHours[i] = parseInt(effort.years);
                                } else {
                                    this.techsToShow[i].level = this.levelText; 
                                    this.techsToShow[i].levelId = this.selectedLevelId;
                                    this.experienceTimeHours[i] = 0;
                                }
                            }
                        } else {
                            this.techsToShow = []
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            changeLevel(event,index){
                if(event.target.value != undefined){
                    for(var i=0; i<this.profileLevels.length; i++){
                        if(event.target.value == this.profileLevels[i].name)
                            this.techsToShow[index].levelId = this.profileLevels[i].id;
                    }
                }
                this.experienceTimeHours[index] = 0;
            },
            onChange(event){
                if(event.target.value != undefined){
                    for(var i=0; i<this.profileLevels.length; i++){
                        if(event.target.value == this.profileLevels[i].name)
                            this.selectedLevelId = this.profileLevels[i].id;
                    }
                }
                this.getAllData();
            },
            onChangeArea(event){
                if(event.target.value != undefined){
                    for(var i=0; i<this.areas.length; i++){
                        if(event.target.value == this.areas[i].name)
                            this.selectedAreaId = this.areas[i].id;
                    }
                }

                this.getLevelsFromCompany()
                this.getAllData(); 
            },
            openConfig(name,level,id,logo){
                this.$router.push({ name: "update-professional-updating", params: {id_area: this.selectedAreaId, id_origin: parseInt(this.selectedTechId), 
                level_origin_id: this.getLevelId(this.levelText), level_destiny_id: this.getLevelId(level), level_origin: this.levelText, id_destiny: id, level_destiny: level} })
            },
            openModal(name,level,id,logo){
                this.compareObjects = []

                this.configureTechName = name; 
                this.configureTechLevel = level; 
                
                let levelID = this.getLevelId(level);

                this.visibleCertifications = false
                this.visibleEducation = false
                this.visibleExp = false
                this.visibleTech = false
                this.visibleNotTech = false 

                ProfileConfigurationDataService.findProfileLevel(this.selectedTechId, this.selectedLevelId).then(
                    response => {
                        this.dataFatherTechnology = response.data;
                        this.dataFatherTechnology.id = this.selectedTechId; 
                        this.dataFatherTechnology.name = this.selectedLevelName;
                        this.dataFatherTechnology.level = this.levelText;
                        this.dataFatherTechnology.logo = this.logoFatherTech
                        var skillsForTechnologyAndProfile = this.dataFatherTechnology.skills
                        var hayTechSkills = false
                        var hayNonTechSkills = false
                        for (var j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                            if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                hayTechSkills = true
                            } else {
                                hayNonTechSkills = true
                            }
                        }
                        if(hayTechSkills) {
                            this.dataFatherTechnology.hayTechnologySkills = true
                        } else {
                            this.dataFatherTechnology.hayTechnologySkills = false
                        }
                        if(hayNonTechSkills) {
                            this.dataFatherTechnolog.hayNonTechnologySkills = true
                        } else {
                            this.dataFatherTechnology.hayNonTechnologySkills = false
                        }
                        
                        this.compareObjects.push(this.dataFatherTechnology);
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
                
                ProfileConfigurationDataService.compareProfilesLevels(this.selectedTechId, this.selectedLevelId, id,levelID).then(
                    response => {
                        this.secondTech = response.data
                        this.secondTech.id = id; 
                        this.secondTech.name = name;
                        this.secondTech.level = level; 
                        this.secondTech.logo = logo;
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
                this.$refs['configure-modal'].show();
            },
            getLevelId(name){
                for(let i=0; i<this.profileLevels.length;i++){
                    if(name.toUpperCase() == this.profileLevels[i].name.toUpperCase())
                        return this.profileLevels[i].id; 
                }
            },
            getProfileInfo(){
                ProfileConfigurationDataService.findProfileLevel(this.selectedTechId, this.selectedLevelId).then(
                    response => {
                        console.log('')
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            applyChanges(){
                let arrayElements = [];
                var errors = []
                this.successfull = true

                for(var i=0; i<this.techsToShow.length; i++){
                    if(this.experienceTimeHours[i] < 0){
                        errors.push("Weight of years of experience time must be positive.")
                        this.successfull = false
                    }else {
                        let effort = { hours : this.experienceTimeHours[i] }; 
                        arrayElements.push({id_recycle_profile:this.techsToShow[i].id,id_recycle_profile_level:this.techsToShow[i].levelId, effort: effort});
                    }
                }

                if(!this.successfull){
                    this.errorsMessages = errors; 
                } else {
                    ProfileConfigurationDataService.updateRecycleProfile(this.selectedTechId,this.selectedLevelId,arrayElements).then(
                        response => {
                            console.log('')
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            },
            async getChildInfo(id,levelID,level)  {
                await ProfileConfigurationDataService.compareProfilesLevels(this.selectedTechId, this.selectedLevelId, id,levelID).then(
                    response => {
                        this.secondTech = response.data
                        this.secondTech.id = id;
                        this.secondTech.level = level;
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
        	async changeShowState(index,id,level) {
                if(document.getElementById('iconShow-' + index.toString()).style.display == "block") {
                    await ProfileConfigurationDataService.compareProfilesLevels(this.selectedTechId, this.selectedLevelId, id,this.getLevelId(level)).then(
                        response => {
                            this.secondTech = response.data
                            this.secondTech.id = id;
                            this.secondTech.level = level;

                            document.getElementById('iconShow-' + index.toString()).style.display = "none"
                            document.getElementById('iconNotShow-' + index.toString()).style.display = "block" 
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    document.getElementById('iconShow-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShow-' + index.toString()).style.display = "none"
                }
			},
            changeShowStateInfo(index) {
				if(document.getElementById('iconShowInfo-' + index.toString()).style.display == "block") {
					document.getElementById('iconShowInfo-' + index.toString()).style.display = "none"
					document.getElementById('iconNotShowInfo-' + index.toString()).style.display = "block"
				} else {
					document.getElementById('iconShowInfo-' + index.toString()).style.display = "block"
					document.getElementById('iconNotShowInfo-' + index.toString()).style.display = "none"
				}
			},
            changeShowStateSettings(){
              	if(document.getElementById('iconShowSettings' ).style.display == "block") {
					document.getElementById('iconShowSettings').style.display = "none"
					document.getElementById('iconNotShowSettings').style.display = "block"
				} else {
					document.getElementById('iconShowSettings').style.display = "block"
					document.getElementById('iconNotShowSettings').style.display = "none"
				}  
            }
	    }
	};
</script>

<style scoped>
.fitContent{
    height:fit-content;
    margin-top: 5%;
}

.noBold{
    font-weight: 500!important;
}

.redCapsuleClass{
    background:#FF8888  !important;;
}

.greenCapsuleClass {
    background: #CFE8A7 !important;
}

.componentMargins{
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin{
    padding-top: 0% !important;
    justify-content: center;
}

.subTitleClass{
    background-color: #8fe4eb;
    text-align: center !important;
}

.alignCenterColumns{
    display: flex !important;
    justify-content: center !important;
}

.noMarginLow{
    padding-bottom: 0% !important;
}

.titleClass{
    margin-bottom: -2% !important;
    margin-top: 1% !important;
    justify-content: center;
}

.resetColor{
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText{
    text-align: center !important;
}

.dropdown-content {
  z-index: 999;
  position: fixed;
  display: flex !important;
  margin: 0% !important; 
}

.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.divItems {
    text-align: left;
    margin-left: 25px;
    margin-right: 25px;
	margin-bottom: 1em;
}
</style>