<template>
	<div class="col-sm-12">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link :to="{path: '/admin'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
			</div>
			<div class="whiteColor">Settings</div>
		</div>
			
		<div class="col-sm-12 col-centered">
			<admin-settings/>
		</div>
	</div>
</template>

<script>
	import AdminSettings from "@/components/SettingsComponents/UserSettings.vue";

	export default {
	  	name: 'UserSettingsView',
	  	components: {
	    	AdminSettings
	    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>
