import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 's4/';

class S4DataService {
  findSkills(id) {
    return http.get(API_URL_PATH + 'findSkills', { headers: authHeader() });
  }

  getProcessesByArea(id) {
    return http.get(API_URL_PATH + 'findProcessesByArea?id_area='+id, { headers: authHeader() });
  }

  getDataProcessById(id) {
    return http.get(API_URL_PATH + 'findOneProcess?id='+id, { headers: authHeader() });
  }

  ranking(idArea) {
    return http.get(API_URL_PATH + 'ranking?id_area=' + idArea, { headers: authHeader() });
  }
}

export default new S4DataService();