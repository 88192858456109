<template>
	<div v-if="!pdf" class="table-container technologiesStyle my-2">
  		<b-table ref="recyclingProfilesTable"
	        id="recyclingProfilesList"
	        :fields="headers" 
	        :items="recyclingProfiles">
            <template #cell(name)="data">
                <div class="row row-update-profile">
                	<div class="col-sm-auto">
                		<img v-bind:src="logos[data.item.profile.toLowerCase()]" height="50em" class="analysis-card-avatar--circle" />
                	</div>
                	<div class="col-sm-auto profile-title">
                		<p><b>{{ data.item.profile.toUpperCase() }}</b></p>
                	</div>
                </div>
            </template>
            <template #cell(level)="data">
                <p :style="'background:' + styleLevels[data.item.profile_level.toLowerCase()].liColor" class="levelStyle">{{ data.item.profile_level.toUpperCase() }}</p>
            </template>
            <template #cell(numHours)="data">
                <p v-if="data.item.total_hours > 0 && data.item.total_hours < 100" class="lowHoursColor"><b>{{data.item.total_hours}} hours</b></p>
                <p v-else-if="data.item.total_hours >= 100 && data.item.total_hours < 200" class="mediumHoursColor"><b>{{data.item.total_hours}} hours</b></p>
                <p v-else-if="data.item.total_hours >= 250 && data.item.total_hours < 500" class="highHoursColor"><b>{{data.item.total_hours}} hours</b></p>
                <p v-else-if="data.item.total_hours >= 500" class="veryHighHoursColor"><b>{{data.item.total_hours}} hours</b></p>
		        <p v-else>No profiles to update from this profile</p>
            </template>
            <template #cell(options)="data">
				<button v-b-tooltip.hover title="Show updating profiles" class="btn btn-secondary" @click="showRequirements(data.item.profile, data.item.data, data.item.total_hours)">
					<font-awesome-icon icon="eye" />
				</button>
	      	</template>
        </b-table>

        <b-modal id="show-reqs-modal" ref="show-reqs-modal" scrollable title="Show-Requirements-modal" centered size="lg" ok-only>
			<template #modal-title>
				Minimum requirements to incorporate new skills: from {{sourceProfile}} to {{profileToReadapt}}
			</template>
			<div class="row">
				<h4 class="tab-title-modal">Min. experience</h4>
				<div class="row">
					<p v-if="minExperience == 1" class="align-left mb-3">Minimum experience required to readapt to {{profileToReadapt}} is <b>COMPLETED</b></p>
					<p v-else-if="minExperience > 0" class="align-left mb-3">Minimum experience required to readapt to {{profileToReadapt}} is <b>NOT COMPLETED</b> ({{minExperience*100}}/100)</p>
					<p v-else class="align-left mb-3">No experience required</p>
				</div>
				<template v-for="requirement in selectedProfileRequirements">
					<template v-if="requirement.section == 'skills'">
						<template v-for="skill in requirement.data">
							<h4 class="tab-title-modal">{{requirement.section.charAt(0).toUpperCase() + requirement.section.slice(1)}} ({{skill.skill.charAt(0).toUpperCase() + skill.skill.slice(1).replace("_", " ")}})</h4>
							<div class="row mb-2" v-for="skillItem in skill.data">
								<div class="col-sm-10">
									<ul class="item-capsule">
										<li class="item-capsule-data-bold">{{ skillItem.name }}</li>
									</ul>
								</div>
								<div class="col-sm-2 align-items-right">
									<p>{{skillItem.hours}} h</p>
								</div>
							</div>
						</template>
					</template>
					<template v-else-if="requirement.section != 'min_experience'">
						<h4 class="tab-title-modal">{{requirement.section.charAt(0).toUpperCase() + requirement.section.slice(1)}}</h4>
						<div class="row mb-2" v-for="reqItem in requirement.data">
							<div class="col-sm-10">
								<ul class="item-capsule">
									<li class="item-capsule-data-bold">{{ reqItem.name }}</li>
								</ul>
							</div>
							<div class="col-sm-2 align-items-right">
								<p>{{reqItem.hours}} h</p>
							</div>
						</div>
					</template>
				</template>
				<hr class="thicker">
				<div class="row">
					<div class="col-sm-6">
						<p><b>TOTAL HOURS</b></p>
					</div>
					<div class="col-sm-6 align-items-right">
						<p><b>{{selectedProfileTotalHours}} h</b></p>
					</div>
				</div>
			</div>
	    </b-modal>
	</div>
	<div v-else class="table-container technologiesStyle my-2" style="margin-top: 0px !important">
		<b-table ref="recyclingProfilesTable"
			id="recyclingProfilesList"
			thead-class="d-none"
			:fields="headersPDF" 
			:items="recyclingProfiles" style="font-size: 13px; padding-top: 0px; padding-right: 0px; padding-left: 0px; border: none; box-shadow: 0 0 0 0; margin-bottom: 0px !important;">
			<template #cell(name)="data">
				<div class="row row-update-profile" style="float: left; margin-left: 10%">
					<div class="col-sm-auto">
						<img v-bind:src="logos[data.item.profile.toLowerCase()]" width="30" class="analysis-card-avatar--circle" />
					</div>
					<div class="col-sm-auto profile-title">
						<p><b>{{ data.item.profile.toUpperCase() }}</b></p>
					</div>
				</div>
			</template>
			<template #cell(level)="data">
				<p :style="'background:' + styleLevels[data.item.profile_level.toLowerCase()].liColor" class="levelStyle">{{ data.item.profile_level.toUpperCase() }}</p>
			</template>
			<template #cell(numHours)="data">
				<p v-if="data.item.total_hours > 0 && data.item.total_hours < 100" class="lowHoursColor"><b>{{data.item.total_hours}} hours</b></p>
				<p v-else-if="data.item.total_hours >= 100 && data.item.total_hours < 200" class="mediumHoursColor"><b>{{data.item.total_hours}} hours</b></p>
				<p v-else-if="data.item.total_hours >= 250 && data.item.total_hours < 500" class="highHoursColor"><b>{{data.item.total_hours}} hours</b></p>
				<p v-else-if="data.item.total_hours >= 500" class="veryHighHoursColor"><b>{{data.item.total_hours}} hours</b></p>
				<p v-else>No profiles to update from this profile</p>
			</template>
		</b-table>

		<b-modal id="show-reqs-modal" ref="show-reqs-modal" scrollable title="Show-Requirements-modal" centered size="lg">
			<template #modal-title>
			Minimum requirements to incorporate new skills: from {{sourceProfile}} to {{profileToReadapt}}
			</template>
			<div class="row">
				<h4 class="tab-title-modal">Min. experience</h4>
				<div class="row">
					<p v-if="minExperience == 1" class="align-left mb-3">Minimum experience required to readapt to {{profileToReadapt}} is <b>COMPLETED</b></p>
					<p v-else-if="minExperience > 0" class="align-left mb-3">Minimum experience required to readapt to {{profileToReadapt}} is <b>NOT COMPLETED</b> ({{minExperience*100}}/100)</p>
					<p v-else class="align-left mb-3">No experience required</p>
				</div>
				<template v-for="requirement in selectedProfileRequirements">
					<template v-if="requirement.section == 'skills'">
						<template v-for="skill in requirement.data">
							<h4 class="tab-title-modal">{{requirement.section.charAt(0).toUpperCase() + requirement.section.slice(1)}} ({{skill.skill.charAt(0).toUpperCase() + skill.skill.slice(1).replace("_", " ")}})</h4>
							<div class="row mb-2" v-for="skillItem in skill.data">
								<div class="col-sm-10">
									<ul class="item-capsule">
									<li class="item-capsule-data-bold">{{ skillItem.name }}</li>
									</ul>
								</div>
								<div class="col-sm-2 align-items-right">
									<p>{{skillItem.hours}} h</p>
								</div>
							</div>
						</template>
					</template>
					<template v-else-if="requirement.section != 'min_experience'">
						<h4 class="tab-title-modal">{{requirement.section.charAt(0).toUpperCase() + requirement.section.slice(1)}}</h4>
						<div class="row mb-2" v-for="reqItem in requirement.data">
							<div class="col-sm-10">
								<ul class="item-capsule">
								<li class="item-capsule-data-bold">{{ reqItem.name }}</li>
								</ul>
							</div>
							<div class="col-sm-2 align-items-right">
								<p>{{reqItem.hours}} h</p>
							</div>
						</div>
					</template>
				</template>
				<hr class="thicker">
				<div class="row">
					<div class="col-sm-6">
						<p><b>TOTAL HOURS</b></p>
					</div>
					<div class="col-sm-6 align-items-right">
						<p><b>{{selectedProfileTotalHours}} h</b></p>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
	    name: "UpdateResultComponent",
	    data() {
		  	return {
		  		profileToReadapt: "",
		  		selectedProfileTotalHours: -1,
		  		selectedProfileRequirements: [],
		  		selectedProfileIndex: -1,
		  		minExperience: -1
	    	}
		},
	  	props: {
			recyclingProfiles: Array,
			logos: Object,
			styleLevels: Object,
			sourceProfile: String,
			pdf: {
				type: Boolean,
				default: false
			}
		},
		mounted() {
	  	},
	  	computed: {
			rows() {
				return this.recyclingProfiles.length
			},
			headers(){
				return [
					{ key: 'name', label: "Required Profile that can be reskilled from " + this.sourceProfile }, 
					{ key: 'level', label: "Level of Expertise" },
					{ key: 'numHours', label: 'Reskilling Effort (number of hours)' }, 
					{ key: 'options', label: 'Options' }
				]
			},
			headersPDF(){
				return [
					{ key: 'name', label: "Required Profile that can be reskilled from " + this.sourceProfile, tdClass: 'm-width-60' }, 
					{ key: 'level', label: "Level of Expertise", tdClass: 'm-width-50'},
					{ key: 'numHours', label: 'Reskilling Effort (number of hours)', tdClass: 'm-width-50' }, 
				]
			} 
	  	},
		methods: {
			showRequirements(profileToReadapt, requirements, totalHours) {
				this.profileToReadapt = profileToReadapt;
				this.selectedProfileRequirements = requirements;
				this.selectedProfileTotalHours = totalHours;
				this.minExperience = this.selectedProfileRequirements.filter(req => req.section == 'min_experience').map(function(e){ return e.value });
				this.$refs['show-reqs-modal'].show();
			}
		}
	};
</script>

<style>
.m-width-60{
	min-width: 60px;
	max-width: 60px;
	
}

.m-width-50{
	min-width: 50px;
	max-width: 50px;
}
</style>