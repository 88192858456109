<template>
	<div class="home">
		<div class="home-header">
      <div class="row header-row">
        <DataProtectionPolicyComponent/>
      </div> 
    </div>
	</div>
</template>

<script>
  import CookieConsent from "vue-cookieconsent";
  import Footer from "@/components/Footer.vue";
  import HomeHeader from "@/components/HomeHeader.vue";
  import HomeBody from "@/components/HomeBody.vue";
  import DataProtectionPolicyComponent from "@/components/DataProtectionPolicyComponent.vue"
  
  export default {
    name: "Home",
    components: {
      CookieConsent,
      appFooter: Footer,
      appHomeBody: HomeBody,
      appHomeHeader: HomeHeader,
      DataProtectionPolicyComponent
    },
    methods: {
    },
    mounted() {
      const recaptcha = this.$recaptchaInstance

      if (recaptcha)
        recaptcha.showBadge()

      const currentUser = this.$store.state.auth.user;
      if (currentUser && currentUser.role) {
        if (currentUser.role.name === 'admin') {
          this.$router.push('/admin');
        } else if (currentUser.role.name === 'user') {
          this.$router.push('/user');
        } else if (currentUser.role.name === 'examiner') {
          this.$router.push('/examiner');
        } else if (currentUser.role.name === 'validator') {
          this.$router.push('/questions-validator');
        } else if (currentUser.role.name === 'certifier') {
          this.$router.push('/certifications-validator');
        } else if (currentUser.role.name === 'superadmin') {
          this.$router.push('/superadmin');
        }
      }
    }
  };
</script>
