<template>
    <div class="dashboard">
        <div class="row secondNavbar"> 
            <div class="col-5 links-container">
                <router-link :to="{path: '/talent_manager'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/admin-configuration' }" class="final-breadcumb"> Configuration </router-link>
            </div>
        </div>

        <div class="cards w-75">
            <div class="row full-width justify-content-center">
                <template v-for="card in cards">
                    <menu-card :cardRouteName="card.routeName"
                        :cardImage="card.image"
                        :cardTitle="card.title"
                        :cardDescription="card.description" v-bind:key="card.title"></menu-card>
                </template>
            </div>
        </div>
        <div class="fixed-bottom">
            <appFooter></appFooter>
        </div>
    </div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';

	export default {
        name: 'AdminConfigurationDashboardView',
        data() {
            return {
            };
        },
        components: {
            MenuCard,
            appFooter: FooterMini
        },
        props: {
            showWelcome: Boolean
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            cards() {
                return [
                    {
                        "routeName": "/admin-configuration-softskills",
                        "image": "images/icons/settings_icon.svg",
                        "title": 'Soft Skills',
                        "description": 'Configuration of the Directory of Competences'
                    }
                ]
            } 
        },
        mounted() {
            const recaptcha = this.$recaptchaInstance

            if (recaptcha)
                recaptcha.hideBadge()
        }
	};
</script>

<style scoped>
.welcome {
	background-color: #8080807e;
	padding-left: 0px;
    padding-right: 0px;
	height: 40px;
	font-size: 18px;
	font-weight: bold;
	position: relative;
}

#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
.welcome-message {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>