<template>
    <div v-if="dataLoading" class="text-center text-loading">
        <b-spinner class="align-middle analysis-spinner"></b-spinner>
        <strong class="text-loading-msg"> Loading data...</strong>
    </div>
    <div v-else class="row technology-skills-div mt-2">
        <div id="message" v-if="message != ''" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="message = ''" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>
        <form action class="form" @submit.prevent="handleSend">
            <div class="row mb-3 mt-2">
                <h4 class="h4-class"> Evaluation of {{userEvaluted.name}} {{userEvaluted.surname}}</h4>
            </div>
            <div class="container-fluid" v-if="byGroups">
                <div class="row" v-for="(group, index) in competencesToEvaluate"  :key="group.id">
                    <div class="col-12 padding-0">
                        <div class="row">
                            <div class="col-1">
                                <p class="title-groups margin-top-10">Group</p>
                            </div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-9">
                                        <p class="float-left margin-top-1-5">{{group.name}}</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="p-weight-evaluation-group-results">Weight: &nbsp;&nbsp;&nbsp;{{group.weight}}%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <button class="btn btn-secondary margin-top-10" type="button" v-b-toggle="'toggleG-' + index.toString()" @click="changeShowGroupState(index)">
                                    <font-awesome-icon style="display: none;" v-bind:id="'iconShowG-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                    <font-awesome-icon style="display: block;" v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                        <div class="row">
                            <div class="col-10 col-centered">
                                <div class="row" v-for="(competence, index_competence) in group.selectedCompetences"  :key="group.id + '-' + competence.id">
                                    <div class="col-12 padding-0">
                                        <div class="row">
                                            <div class="col-2">
                                                <p class="title-groups margin-top-5">Competence</p>
                                            </div>
                                            <div class="col-9">
                                                <div class="row">
                                                    <div class="col-9">
                                                        <p class="float-left margin-top-1-8">{{competence.name}}</p>
                                                    </div>
                                                    <div class="col-3">
                                                        <p class="p-weight-evaluation-competence-results">Weight: &nbsp;&nbsp;&nbsp;{{competence.weight}}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1">
                                                <button class="btn btn-secondary margin-top-15" type="button" v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()" @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                    <font-awesome-icon style="display: none;" v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                    <font-awesome-icon style="display: block;" v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <b-collapse visible v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">       
                                        <div class="row">
                                            <div class="col-8 col-centered">
                                                <div class="mb-3">
                                                    <p class="competence-comments">Comments</p>
                                                    <textarea class="input-competence-comments" rows="3" v-model="competence.comment" maxlength="512"></textarea>
                                                </div>
                                                <div class="row">
                                                    <p class="title-reached-level">Reached Level</p>
                                                </div>
                                                <div class="row">
                                                    <b-form-select v-model="competence.selectedLevel" class="mb-3 select-level-form">
                                                        <option :value="index_level" v-for="(level, index_level) in competence.levels" :key="level.id">
                                                            Level {{index_level+1}}:&nbsp;&nbsp;&nbsp;{{level.name}}
                                                        </option>
                                                    </b-form-select>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <hr>
                </div>
                <div class="row" style="margin-bottom: 2%;">
                    <div class="col-sm-12 padding-0 align-item-right">
                        <button class="btn btn-primary form-submit" type="submit">Send evaluation</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
    
<script>
    import SoftskillsDataService from '@/services/SoftskillsDataService';
    import UserDataService from '@/services/UserDataService';

    export default {
        name: 'CompleteComponent',
        data() {
            return {
                evaluation: {},
                skillsMap: [],
                competencesWithoutGroups: [],
                competencesByGroups: [],
                competencesToEvaluate: [],
                userEvaluted: {},
                groupsWeights: false,
                competencesWeights: false,
                byGroups: false,
                isUser: false,
                dataLoading: false,
                message: "",
                successful: false,
            }
        },
        watch: {
        },
        components: {
        },
        computed: {
        },
        mounted() {
            this.dataLoading = true;
            UserDataService.findOne(this.$route.query.id_user).then(
                response => {
                    this.userEvaluted = response.data;
                },
                error => {
                    this.dataLoading = false
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
            SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
                response => {
                    this.skillsMap = response.data

                    if (this.skillsMap.length > 0) {
                        this.competencesByGroups = this.skillsMap

                        if (this.skillsMap[0].type === "group") this.byGroups = true
                        else if (this.skillsMap[0].type === 'competence') this.byGroups = false
                    }

                    SoftskillsDataService.findUserEvaluationWeights(this.$route.query.id, this.$route.query.id_user, this.$store.state.auth.user.id, this.$route.query.evaluation_process).then(
                        response => {
                            this.groupsWeights = response.data
        
                            if (this.byGroups) {
                                this.groupsWeights.forEach(weight => {
                                    this.competencesByGroups.forEach(group => {
                                        if (group.id === weight.soft_skill.id) this.competencesToEvaluate.push(group)
                                    })
                                })
        
                                this.competencesToEvaluate.forEach(group => {
                                    group.selectedCompetences = []
                                })
        
                                this.groupsWeights.forEach(weight => {
                                    this.competencesToEvaluate.forEach(group => {
                                        if (group.id === weight.soft_skill.id) {
                                            group.weight = weight.weight
                                        }
        
                                        group.competences.forEach(competence => {
                                            console.log('competence: ', competence)
                                            if (competence.id === weight.soft_skill.id) {
                                                competence.weight = weight.weight

                                                competence.levels.shift()
        
                                                group.selectedCompetences.push(competence)
                                            }
                                        })
                                    })
                                })
                            }
        
                            this.dataLoading = false
                        },
                        error => {
                            this.dataLoading = false
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                },
                error => {
                    this.dataLoading = false
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        methods: {
            changeShowGroupState(index) {
                if(document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                    document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                    document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
                }
            },
            changeShowCompetenceState(index) {
                if(document.getElementById('iconShowC-' + index.toString()).style.display == "block") {
                    document.getElementById('iconShowC-' + index.toString()).style.display = "none"
                    document.getElementById('iconNotShowC-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowC-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShowC-' + index.toString()).style.display = "none"
                }
            },
            changeShowCompetenceFromGroupState(index, index_competence) {
                if(document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                    document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                    document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                    document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                }
            },
            changeShowCompetenceWithoutGroupState(index_competence) {
                if(document.getElementById('iconShowC-' + index_competence.toString()).style.display == "block") {
                    document.getElementById('iconShowC-' + index_competence.toString()).style.display = "none"
                    document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "block"
                } else {
                    document.getElementById('iconShowC-' + index_competence.toString()).style.display = "block"
                    document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "none"
                }
            },
            handleSend() {
                const evaluations = [];
                this.competencesToEvaluate.forEach(group => {
                    group.selectedCompetences.forEach(competence => {
                        if (!competence.selectedLevel) {
                            this.message = "Must choose a level for each competition";
                            this.successful = false;
                            return;
                        }
                        evaluations.push({
                            id_evaluation: parseInt(this.$route.query.id),
                            id_evaluator: parseInt(this.$store.state.auth.user.id),
                            id_user: parseInt(this.$route.query.id_user),
                            id_competence: parseInt(competence.id),
                            score: parseInt(competence.selectedLevel),
                            description: competence.comment
                        })
                    })
                })
                
                if (!this.message == '') {
                    return;
                }

                SoftskillsDataService.addScoresToEvaluation(evaluations, this.$route.query.evaluation_process).then(
                    response => {
                        this.$router.push('/evaluation-360');
                    },
                    error => {
                        this.dataLoading = false
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            }
        }
    };
</script>