<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/tags'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Tags</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/tags' }" class="breadcumb"> Tags > </router-link>
        <router-link :to="{ path: '/create-tag' }" class="final-breadcumb"> Create Tag </router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered">	
  		<create-tag/>
  	</div>
  </div>
</template>

<script>
  import CreateTag from "@/components/TagComponents/Create.vue";

	export default {
    name: 'CreateTagView',
    components: {
      CreateTag
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important; 
  padding-right: 0px !important; 
  padding-top: 0px !important; 
}
</style>