export default class Certification {
  constructor(name, acronym, entity, expiration_years, url, duration, slug, validated, id_area) {
  	this.name = name;
    this.acronym = acronym;
  	this.entity = entity;
  	this.expiration_years = expiration_years;
  	this.url = url;
  	this.duration = duration;
    this.slug = slug;
  	this.validated = validated;
    this.id_area = id_area;
  }
}