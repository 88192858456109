<template>
	<div :id="chartId" ref="chartdiv"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
	name: 'BubbleChart',
	data() {
		return {
			chart: null,
		}
	},
	props: {
		chartId: String,
		data: Array,
		bubbleSizeCategory: String,
		bubbleSizeTitle: String,
		xAxesCategory: String,
		yAxesValue: String,
		xAxesTitle: String,
		yAxesTitle: String,
		isUserResult: {
			type: Boolean,
			default: false
		},
		tooltipNoDataMsg: {
			type: String,
			default: "No data available"
		}
	},
	mounted() {
		let data = []
		if (this.data != undefined)
			data = JSON.parse(JSON.stringify(this.data))

		this.chart = am4core.create(this.chartId, am4charts.XYChart);

		if (0 == data.length) {
			let label = this.chart.createChild(am4core.Label);
			label.text = this.tooltipNoDataMsg;
			label.fontSize = 20;
			label.align = "center";
			label.isMeasured = false;
			label.x = am4core.percent(50);
			label.y = 100;
			label.horizontalCenter = "middle";
		} else {
			this.chart.data = this.data;

			this.chart.data.sort((a, b) => {
				return a.order - b.order
			})

			console.log('this.chart.data: ', this.chart.data)

			var valueAxisX = this.chart.xAxes.push(new am4charts.ValueAxis());
			valueAxisX.renderer.ticks.template.disabled = true;
			valueAxisX.renderer.axisFills.template.disabled = true;
			valueAxisX.title.text = this.xAxesTitle
			valueAxisX.title.fontWeight = "bold"

			var categoryAxisY = this.chart.yAxes.push(new am4charts.CategoryAxis());
			categoryAxisY.dataFields.category = this.yAxesValue;
			categoryAxisY.renderer.grid.template.disabled = true;
			categoryAxisY.renderer.axisFills.template.disabled = true;
			categoryAxisY.renderer.ticks.template.disabled = true;
			categoryAxisY.sortBy = "order"

			var series = this.chart.series.push(new am4charts.LineSeries());
			series.dataFields.valueX = this.xAxesCategory;
			series.dataFields.categoryY = this.yAxesValue;
			series.dataFields.value = this.bubbleSizeCategory;
			series.strokeOpacity = 0;
			series.sequencedInterpolation = true;
			series.tooltip.pointerOrientation = "vertical";

			var bullet = series.bullets.push(new am4core.Circle());
			bullet.strokeOpacity = 0;
			bullet.strokeWidth = 2;
			bullet.fillOpacity = 0.5;
			bullet.stroke = am4core.color("#ffffff");
			bullet.hiddenState.properties.opacity = 0;

			if (this.isUserResult) {
				bullet.tooltipText = "[bold]" + this.xAxesTitle + ": {valueX.value}[/]\n" + this.bubbleSizeTitle + ": {value.value}\nIs correct?: {isCorrect}\n" + this.yAxesTitle + ": {categoryY}";
			} else {
				bullet.tooltipText = "[bold]" + this.xAxesTitle + ": {valueX.value}[/]\n" + this.bubbleSizeTitle + ": {value.value}\nRate: {rate} %\n" + this.yAxesTitle + ": {categoryY}";
			}

			var outline = this.chart.plotContainer.createChild(am4core.Circle);
			outline.fillOpacity = 0;
			outline.strokeOpacity = 0.8;
			outline.stroke = am4core.color("#ff0000");
			outline.strokeWidth = 2;
			outline.hide(0);

			var blurFilter = new am4core.BlurFilter();
			outline.filters.push(blurFilter);

			bullet.events.on("over", function (event) {
				var target = event.target;
				outline.radius = target.pixelRadius + 2;
				outline.x = target.pixelX;
				outline.y = target.pixelY;
				outline.show();
			})

			bullet.events.on("out", function (event) {
				outline.hide();
			})

			var hoverState = bullet.states.create("hover");
			hoverState.properties.fillOpacity = 1;
			hoverState.properties.strokeOpacity = 1;

			series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });

			const isUserResult = this.isUserResult;

			bullet.adapter.add("fill", function (fill, target) {
				if (isUserResult) {
					if (target.dataItem.dataContext.isCorrect) {
						return am4core.color("#00FF00");
					} else {
						return am4core.color("#FF0000");
					}
				} else {
					let s = 100;
					let l = 50;
					let r, g, b;

					let value = target.dataItem.dataContext.rate / 100
					let h = ((1 - value) * 120).toString(10);

					if (120 > h && h >= 114) {
						return am4core.color("#FF0000");
					} else if (114 > h && h >= 108) {
						return am4core.color("#FF1A00");
					} else if (108 > h && h >= 102) {
						return am4core.color("#FF3300");
					} else if (102 > h && h >= 96) {
						return am4core.color("#FF4D00");
					} else if (96 > h && h >= 90) {
						return am4core.color("#FF6600");
					} else if (90 > h && h >= 84) {
						return am4core.color("#FF8000");
					} else if (84 > h && h >= 78) {
						return am4core.color("#FF9900");
					} else if (78 > h && h >= 72) {
						return am4core.color("#FFB300");
					} else if (72 > h && h >= 66) {
						return am4core.color("#FFCC00");
					} else if (66 > h && h >= 60) {
						return am4core.color("#FFE500");
					} else if (60 > h && h >= 54) {
						return am4core.color("#FFDD33");
					} else if (54 > h && h >= 53) {
						return am4core.color("#E5FF00");
					} else if (53 > h && h >= 48) {
						return am4core.color("#E5FF00");
					} else if (48 > h && h >= 42) {
						return am4core.color("#CCFF00");
					} else if (42 > h && h >= 36) {
						return am4core.color("#B3FF00");
					} else if (36 > h && h >= 30) {
						return am4core.color("#99FF00");
					} else if (30 > h && h >= 24) {
						return am4core.color("#80FF00");
					} else if (24 > h && h >= 23) {
						return am4core.color("#FF8000");
					} else if (23 > h && h >= 18) {
						return am4core.color("#FF8000");
					} else if (18 > h && h >= 12) {
						return am4core.color("#4DFF00");
					} else if (12 > h && h >= 6) {
						return am4core.color("#33FF00");
					} else if (6 > h && h > 0) {
						return am4core.color("#1AFF00");
					} else if (h == 0) {
						return am4core.color("#00FF00");
					}
				}
			});

			bullet.adapter.add("tooltipY", function (tooltipY, target) {
				return -target.radius;
			})

			this.chart.cursor = new am4charts.XYCursor();
			this.chart.cursor.behavior = "zoomXY";
			this.chart.cursor.snapToSeries = series;

			this.chart.scrollbarX = new am4core.Scrollbar();
			this.chart.scrollbarY = new am4core.Scrollbar();

			if (this.data.length != 0) {
				this.chart.exporting.menu = new am4core.ExportMenu();
				this.chart.exporting.menu.items = [
					{
						"label": "...",
						"menu": [
							{
								"label": "Image",
								"menu": [
									{ "type": "png", "label": "PNG" },
									{ "type": "jpg", "label": "JPG" },
									{ "type": "svg", "label": "SVG" }
								]
							}, {
								"label": "Data",
								"menu": [
									{ "type": "json", "label": "JSON" },
									{ "type": "csv", "label": "CSV" },
									{ "type": "xlsx", "label": "XLSX" },
									{ "type": "html", "label": "HTML" }
								]
							}, {
								"label": "Print", "type": "print"
							}
						]
					}
				]
				this.chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
			}
		}
	}
};
</script>
