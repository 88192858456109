<template>
	<div class="col-sm-12">
		<b-progress class="full-progress-bar-analysis" :max="100">
			<template v-for="(level, index) in levelsData">
				<template v-if="index + 1 < levels">
					<b-progress-bar :value="levelsData[index + 1].min_range - level.min_range" :label="noLabel" :style="level.styleProperties" show-progress animated :key="level.id"></b-progress-bar>
				</template>
				<template v-if="index + 1 === levels">
					<b-progress-bar :value="progress - level.min_range" :label="progress + ' %'" :style="level.styleProperties" show-progress animated :key="level.id"></b-progress-bar>
				</template>
			</template>
		</b-progress>
	</div>
</template>

<script>
	import Progress from "@/components/Others/ProgressCircle";
	export default {
		name: "ProgressTechHeader",
		data() {
			return {
				styleProperties: null,
				noLabel: ' ',
				levels: 0
			}
		},
		components: {
			Progress,
		},
		props: {
			progress: Number,
			levelsData: {
				type: Array,
				required: false,
				default: () => ([])
			}
		},
		mounted () {
			console.log('levelsData: ', this.levelsData)
			this.levelsData.forEach(level => {
				if (this.progress >= level.min_range) this.levels = this.levels + 1
				level.styleProperties = "text-align: right; padding-right: 7px; color: " + this.getContrastColor(level.color) + " !important; background: " + level.color + " !important;"
			})
		},
		methods: {
			getContrastColor(hexColor) {
				// Eliminar el carácter '#' si está presente
				hexColor = hexColor.replace('#', '');

				// Convertir los valores hexadecimales a RGB
				let r = parseInt(hexColor.substring(0, 2), 16);
				let g = parseInt(hexColor.substring(2, 4), 16);
				let b = parseInt(hexColor.substring(4, 6), 16);

				// Calcular el brillo según la fórmula de luminancia
				let brightness = (r * 299 + g * 587 + b * 114) / 1000;

				// Si el brillo es menor que 128, devolver blanco, de lo contrario, devolver negro
				return brightness > 128 ? 'black' : 'white';
			}
		}
	};
</script>

