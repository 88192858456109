<template>
  <reset-password-form/>
</template>

<script>
  import ResetPasswordForm from "@/components/UserForms/ResetPasswordForm.vue";

  export default {
    name: "ResetPasswordView",
    components: {
      ResetPasswordForm
    },
  };
</script>