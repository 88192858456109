<template>
	<div>
		<h4 class="reports-title">Column diagram with correct/wrong answers distribution</h4>
    <select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedQuestion" @change="onChange($event)">
      <option hidden value="">Select a question</option>
      <option v-for="question in questions" :value="question.id">{{question.id}} - {{question.question_title}}</option>
    </select>
    <div v-if="graphLoading" class="text-center text-loading">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Loading graph...</strong>
    </div>
    <div class="row" v-else>
      <div v-if="selectedQuestionObj.id != undefined" class="col-sm-2">
        <h3 class="graph-title">Question {{selectedQuestionObj.id}} <b-button class="btn btn-info" v-b-tooltip.hover v-bind:title="selectedQuestionObj.question_title"><font-awesome-icon icon="info-circle" /></b-button></h3>
      </div>
      <div :class="selectedQuestionObj.id != undefined ? 'col-sm-10' : 'col-sm-12'">
        <horizontal-stacked-barr 
          :ref="chartId + selectedQuestionObj.id"
          :chartId="chartId + selectedQuestionObj.id"
          :key="graphLoaded"
          :xAxesCategory="xAxesCategory" 
          :yAxesValue="yAxesValue" 
          :xAxesTitle="xAxesTitle"
          :yAxesTitle="yAxesTitle"
          :isDichotomic="isDichotomic"
          :isLabelPercentage="isLabelPercentage"
          :data="selectedQuestionObj.data"
          :tooltipNoDataMsg="tooltipNoDataMsg"></horizontal-stacked-barr>
      </div>
    </div>
	</div>
</template>

<script>
  import ReportsDataService from '@/services/ReportsDataService';
  import HorizontalStackedBarr from "@/components/Others/Charts/HorizontalStackedBarr";

  export default {
    name: 'UserPercentageCorrectGraphByQuestion',
    data() {
    	return {
    		chartId: "chartUserPercentageCorrectGraphByQuestion",
        questions: [],
	    	xAxesCategory: "perc",
	    	yAxesValue: "answer",
	    	xAxesTitle: "Percentage",
	    	yAxesTitle: "Answers",
	    	isDichotomic: true,
	    	isLabelPercentage: true,
        graphLoaded: false,
        graphLoading: false,
        selectedQuestion: "",
        selectedQuestionObj: {},
        tooltipNoDataMsg: "Please, select a question"
    	}
    },
    components: {
    	HorizontalStackedBarr
    },
    props: {
      user: Object,
      userAreaID: Number
    },
    methods: {
      onChange(event) {
        this.graphLoading = true;

        this.selectedQuestion = event.target.value

        this.questions.forEach((question) => {
          if (parseInt(this.selectedQuestion) == parseInt(question.id))
            this.selectedQuestionObj = question;
        });

        this.graphLoaded = this.graphLoaded ? false : true;
        this.graphLoading = false;          
      },
    	async fetchData() {
    		await ReportsDataService.userQuestionsResultsInArea(this.user.id, this.userAreaID).then(
          response => {
            if (response.status == 200) {
              let tmpArray = [];
              let userQuestionsData = response.data;

              userQuestionsData.forEach((element) => {
                var tmp = {}
                tmp.id = element.id_question
                tmp.question_title = element.question.question_title
                tmp.data = []
                tmp.data[0] = {}
                tmp.data[0].perc = parseFloat(element.average_correct_ocurrences).toFixed(2)
                tmp.data[0].ocurrences = element.total_correct_ocurrences
                tmp.data[0].totalOcurrences = element.total_correct_ocurrences + element.total_wrong_ocurrences
                tmp.data[0].answer = "Correct"
                tmp.data[0].userAnswer = element.is_correct === true ? true : false
                tmp.data[1] = {}
                tmp.data[1].perc = parseFloat(element.average_wrong_ocurrences).toFixed(2)
                tmp.data[1].ocurrences = element.total_wrong_ocurrences
                tmp.data[1].totalOcurrences = element.total_correct_ocurrences + element.total_wrong_ocurrences
                tmp.data[1].answer = "Wrong"
                tmp.data[1].userAnswer = element.is_correct === false ? true : false
                tmpArray.push(tmp)
              })

              this.questions = tmpArray;

              this.graphLoaded = this.graphLoaded ? false : true;
              this.graphLoading = false;
            }
				  },
          error => {
            console.error((error.response && error.response.data) ||
              error.message ||
              error.toString())

            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
		    );
    	}
    },
    mounted() {
    	this.graphLoading = true;
      this.fetchData();
  	}
  };
</script>