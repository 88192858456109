<template>
	<div>
        <div class="row noMargin">
            <div class="col-sm-12 px-4">
                <h4 class="titleClass">Select a level of "{{selectedLevelName.toUpperCase()}}" to configure</h4>
            </div>
        </div>
        <div class="row my-4 noMargin">
            <div class="col-sm-6"> 
                <div class="row noMargin">
                    <div class="col-sm-6 ">
                        <select class="form-select" data-vv-name="technology_selector" v-model="levelText" id="technology_selector_questions_level" @change="onChange($event)">
                            <option v-for="element in profileLevelsFather" v-bind:key="element.name" :value="element.name" >
                                <template slot="button-content">
                                    <img v-bind:src="element.logo" class="card-avatar card-avatar--circle" />
                                    {{ element.name.toUpperCase() }}
                                </template>
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row noMargin">
            <div class="col-sm-12 px-4">
                <h4 class="titleClass">Select an AREA to display Profiles Required to configure</h4>
            </div>
        </div>
        <div class="row my-5 noMargin">
            <div class="col-sm-6"> 
                <div class="row noMargin">
                    <div class="col-sm-7 ">
                        <select class="form-select" data-vv-name="technology_selector" v-model="areaModel" id="technology_selector_questions_level" @change="onChangeArea($event)">
                            <option v-for="element in areas" v-bind:key="element.name" :value="element.name" >
                                <template slot="button-content">
                                    <img v-bind:src="element.logo" class="card-avatar card-avatar--circle" />
                                    {{ element.name.toUpperCase() }}
                                </template>
                            </option>
                        </select> 
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-show="showMenu">
            <div class="row noMargin">
                <div class="col-sm-12 px-4 noMargin noMarginLow">
                    <h4>Profiles Required to Reskill</h4>
                </div>
            </div>
            <div v-if="techsToShow !=null && techsToShow !=undefined && techsToShow.length >0">
                <div
                    v-for="(element,index) in techsToShow"
                    :key="element.name"
                >
                    <div class="row noMargin ">
                        <div class="col-sm-5 list-group-item ">
                            <img v-if="!element.logo.includes('null')" v-bind:src="element.logo" height="20em" class="card-avatar--circle" />
                            {{ element.name.toUpperCase() }}
                        </div>
                        <div class="col-sm-4 ">
                            <select class="form-select centerText" data-vv-name="technology_selector" v-model="element.level" id="technology_selector_questions_level" @change="changeLevel($event,index)" >
                                <option v-for="level in profileLevels" v-bind:key="level.name" :value="level.name" >
                                    <template slot="button-content">
                                        {{ level.name.toUpperCase() }}
                                    </template>
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-1">
                            <button v-b-tooltip.hover title="Configure reskilling" class="btn btn-secondary" @click="openConfig(element.level,element.id)"><font-awesome-icon icon="cog" /></button>
                        </div>
                    </div>   
                </div>
            </div>
            <div v-else class="row noMargin">
                <div class="col-sm-12 px-4 noMargin noMarginLow">
                    <h5><font-awesome-icon icon="times-circle" class="black-color"/> No technologies to display.</h5>
                </div>
            </div>
        </div>
        <div class="row" v-show="showMenu">
            <div class="row noMargin">
                <div class="col-sm-14 px-4 noMargin noMarginLow">
                    <h4 class="mt-4">Upskill to next level</h4>
                </div>
            </div>
            <div class="row noMargin" v-if="levelToUpskill !== undefined">
                <div class="col-sm-2 list-group-item ">
                    <img v-if="!logoFatherTech.includes('null')" v-bind:src="logoFatherTech" height="20em" class="card-avatar--circle" />
                    {{ selectedLevelName.toUpperCase() }}
                </div>
                <div class="col-sm-1 list-group-item ">
                    {{ levelToUpskill.toUpperCase() }}
                </div>
                <div class="col-sm-1">
                    <button v-b-tooltip.hover title="Configure upskilling" class="btn btn-secondary pt-0" @click="openConfig(levelToUpskill,selectedTechId)"><font-awesome-icon icon="cog" /></button>
                </div>
            </div>
            <div class="row noMargin" v-else>
                <div class="col-sm-5">
                    Not able to upskill because base level is top level.
                </div>
            </div>  
        </div>
        <template v-for="(errorMessage, index) in errorsMessages">
            <div :id="index" class="alert alert-danger alert-edit d-flex align-items-center justify-content-center" v-bind:key="errorMessage">
                {{errorMessage}} <b-button @click="close(index)" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
            </div>
        </template>
	</div>
</template>

<script>
    import AreasDataService from '@/services/AreasDataService';
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
	import EducationCapsule from "@/components/Others/EducationCapsule.vue"
	import ProgrammingSkillsCapsule from "@/components/Others/ProgrammingSkillsCapsule.vue"
	import CertificationsCapsule from "@/components/Others/CertificationsCapsule.vue"
	import FrameworksCapsule from "@/components/Others/FrameworksCapsule.vue"
	import ToolsCapsule from "@/components/Others/ToolsCapsule.vue"
	import OthersCapsule from "@/components/Others/OthersCapsule.vue"
	import SkillsCapsule from "@/components/Others/SkillsCapsule.vue"

	export default {
		name: 'RecycledTagsListComponent',
		data() {
            return {
                selectedLevel: [],
                selectedLevelName: "",
                selectedTechId: "",
                selectedLevelId: "",
                levelToUpskill: "",
                easyProfiles: [],
                posibleProfiles: [],
                showMenu: true,
                showData: false,
                allTechnologies: [],
                techsToShow: [],
                profileLevelsFather: [],
                profileLevels: [],
                flagIsEmpty : false, 
                technologiesUpdated: false, 
                errorsMessages: [],
                successfull: null, 
                technologyText: "Select technology",
                levelText: "Select a level",
                configureTechName: "",
                dataFatherTechnology: "",
                del: false,
                compareObjects: [],
                secondTech: null,
                collapseId: true,
                visibleCertifications: false,
                visibleEducation: false,
                visibleExp: false,
                visibleTech: false,
                visibleNotTech: false,
                logoFatherTech: "", 
                areas: "",
                areaModel: "",
                selectedAreaId: ""

            }
	    },
        components: {
			EducationCapsule,
			FrameworksCapsule,
			ProgrammingSkillsCapsule,
			ToolsCapsule,
			OthersCapsule,
			CertificationsCapsule,
			SkillsCapsule,
	    },
        watch: {
            levelText () {
                if (this.levelText === 'Select a level') this.levelToUpskill = ''
                else {
                    this.profileLevelsFather.forEach((level, index) => {
                        if (level.name === this.levelText) {
                            if (this.profileLevelsFather[index+1] !== undefined) this.levelToUpskill = this.profileLevelsFather[index+1].name
                            else this.levelToUpskill = undefined
                        }
                    })
                }
            }
        },
        mounted(){
            this.selectedTechId = this.$route.query.id_profile; 
            if(this.selectedTechId == undefined || this.selectedTechId == null){
                this.$router.push('/config-profile');
            }

            ProfileConfigurationDataService.findLevelsConfigurationByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    if(response.data.length <= 0 || response.data == undefined || response.data == null)
                        this.$router.push('/config-profile');
                    else {
                        for(let i=0; i<response.data.length; i++){
                            if(response.data[i].id == this.selectedTechId){
                                this.selectedLevelName = response.data[i].name;
                                this.logoFatherTech = this.$awsBucketRoute + response.data[i].logo
                            }
                        }
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.$router.push('/config-profile');

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );

            AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {
                    if (response.status == 200){
                        this.areas = response.data
                        for(let i=0; i<response.data.length; i++){
                            if(this.$store.state.auth.user.active_areas[this.$session.get("tabID")] == response.data[i].id){
                                this.areaModel = this.areas[i].name
                                this.selectedAreaId = this.areas[i].id
                            }
                        }

                        this.getLevelsFromCompany()
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );

            ProfileConfigurationDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                response => {
                    if(response.data.length <= 0 || response.data == undefined || response.data == null)
                        this.$router.push('/config-profile');
                    else {
                        this.profileLevelsFather = response.data;

                        this.levelText = response.data[0].name;
                        this.selectedLevelId = response.data[0].id;
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.$router.push('/config-profile');

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
	  	methods: {
            capitalizarPrimeraLetra(str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
            getLevelsFromCompany(){
                ProfileConfigurationDataService.findByArea(this.selectedAreaId).then(
                    response => {
                        this.profileLevels = response.data; 
                        if(this.profileLevels.length > 0)
                            this.getAllData();
                        else {
                            this.techsToShow = []
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );
            },
            getAllData(){
                ProfileConfigurationDataService.recycledProfilesByProfile(this.selectedTechId, this.selectedAreaId).then(
                    response => {
                        if(response.data != undefined && response.data.length > 0){
                            this.techsToShow = response.data
                            for(var i=0; i<this.techsToShow.length; i++){
                                this.techsToShow[i].logo = this.$awsBucketRoute + this.techsToShow[i].logo;
                                this.techsToShow[i].level = this.profileLevels[0].name
                                this.techsToShow[i].levelId = this.profileLevels[0].level
                            }                        
                        } else {
                            this.techsToShow = []
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            changeLevel(event,index){
                if(event.target.value != undefined){
                    for(var i=0; i<this.profileLevels.length; i++){
                        if(event.target.value == this.profileLevels[i].name)
                            this.techsToShow[index].levelId = this.profileLevels[i].id;
                    }
                }
            },
            onChange(event){
                if(event.target.value != undefined){
                    for(var i=0; i<this.profileLevels.length; i++){
                        if(event.target.value == this.profileLevels[i].name)
                            this.selectedLevelId = this.profileLevels[i].id;
                    }
                }
            },
            onChangeArea(event){
                if(event.target.value != undefined){
                    for(var i=0; i<this.areas.length; i++){
                        if(event.target.value == this.areas[i].name)
                            this.selectedAreaId = this.areas[i].id;
                    }
                }
                
                this.getLevelsFromCompany()
            },
            openConfig(level,id){
			    this.$router.push({ name: "update-professional-updating", params: {id_area: this.selectedAreaId, id_origin: parseInt(this.selectedTechId), 
                level_origin_id: this.selectedLevelId, level_destiny_id: this.getLevelId(level), level_origin: this.levelText, id_destiny: parseInt(id), level_destiny: level} })
            },
            getLevelId(name){
                for(let i=0; i<this.profileLevels.length;i++){
                    if(name.toUpperCase() == this.profileLevels[i].name.toUpperCase())
                        return this.profileLevels[i].id; 
                }
            },
            getProfileInfo(){
                ProfileConfigurationDataService.findProfileLevel(this.selectedTechId, this.selectedLevelId).then(
                    response => {
                        console.log('')
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            },
        	async changeShowState(index,id,level) {
                if(document.getElementById('iconShow-' + index.toString()).style.display == "block") {
                    await ProfileConfigurationDataService.compareProfilesLevels(this.selectedTechId, this.selectedLevelId, id,this.getLevelId(level)).then(
                        response => {
                            this.secondTech = response.data
                            this.secondTech.id = id;
                            this.secondTech.level = level;
                            document.getElementById('iconShow-' + index.toString()).style.display = "none"
                            document.getElementById('iconNotShow-' + index.toString()).style.display = "block"
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    document.getElementById('iconShow-' + index.toString()).style.display = "block"
                    document.getElementById('iconNotShow-' + index.toString()).style.display = "none"
                }
			},
            changeShowStateInfo(index) {
				if(document.getElementById('iconShowInfo-' + index.toString()).style.display == "block") {
					document.getElementById('iconShowInfo-' + index.toString()).style.display = "none"
					document.getElementById('iconNotShowInfo-' + index.toString()).style.display = "block"
				} else {
					document.getElementById('iconShowInfo-' + index.toString()).style.display = "block"
					document.getElementById('iconNotShowInfo-' + index.toString()).style.display = "none"
				}
			},
            changeShowStateSettings(){
              	if(document.getElementById('iconShowSettings' ).style.display == "block") {
					document.getElementById('iconShowSettings').style.display = "none"
					document.getElementById('iconNotShowSettings').style.display = "block"
				} else {
					document.getElementById('iconShowSettings').style.display = "block"
					document.getElementById('iconNotShowSettings').style.display = "none"
				}  
            }
	    }
	};
</script>

<style scoped>
.fitContent{
    height:fit-content;
    margin-top: 5%;

}

.noBold{
    font-weight: 500!important;
}

.componentMargins{
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin{
    padding-top: 0% !important;
    justify-content: center;
}

.subTitleClass{
    background-color: #8fe4eb;
    text-align: center !important;
}

.alignCenterColumns{
    display: flex !important;
    justify-content: center !important;
}

.noMarginLow{
    padding-bottom: 0% !important;
}

.titleClass{
    margin-bottom: -2% !important;
    margin-top: 1% !important;
    justify-content: center;
}

.resetColor{
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText{
    text-align: center !important;
}

.dropdown-content {
    z-index: 999;
    position: fixed;
    display: flex !important;
    margin: 0% !important; 
}

.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.divItems {
    text-align: left;
    margin-left: 25px;
    margin-right: 25px;
	margin-bottom: 1em;
}
</style>