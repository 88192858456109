<template>
    <div class="form-container">
        <div id="message" v-if="message"
            :class="successfull ? 'message alert alert-success' : 'message alert alert-danger'">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>

        <form action class="form" @submit.prevent="handleCreate">
            <div class="col-sm-12">
                <div class="col-md-6 align-item-center mt-4">
                    <div class="form-group">
                        <label class="my-2" for="exampleFormControlSelect1">Username*<b-button class="btn btn-info"
                                v-b-tooltip.hover title="You must choose a username to login"><font-awesome-icon
                                    icon="info-circle" /></b-button></label>
                        <input type="text" class="form-control inputStyle" id="username" required
                            v-model="user.username" name="username" v-validate="'required|min:4|max:20'"
                            data-vv-name="username" placeholder="Username" />
                        <div v-if="submitted && veeErrors.has('username')" class="alert alert-danger">
                            {{ veeErrors.first('username') }}
                        </div>
                        <label class="my-2" for="exampleFormControlSelect1">Name*<b-button class="btn btn-info"
                                v-b-tooltip.hover title="You must enter your name"><font-awesome-icon
                                    icon="info-circle" /></b-button></label>

                        <input type="text" class="form-control inputStyle" id="name" required v-model="user.name"
                            name="name" v-validate="'required'" data-vv-name="name" placeholder="Name" />
                        <div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
                            {{ veeErrors.first('name') }}
                        </div>
                        <label class="my-2" for="exampleFormControlSelect1">Surname*<b-button class="btn btn-info"
                                v-b-tooltip.hover title="You must enter your surname"><font-awesome-icon
                                    icon="info-circle" /></b-button></label>

                        <input type="text" class="form-control inputStyle" id="surname" required v-model="user.surname"
                            name="surname" v-validate="'required'" data-vv-name="surname" placeholder="Surname" />
                        <div v-if="submitted && veeErrors.has('surname')" class="alert alert-danger">
                            {{ veeErrors.first('surname') }}
                        </div>
                        <label class="my-2" for="exampleFormControlSelect1">Password*<b-button class="btn btn-info"
                                v-b-tooltip.hover
                                title="You must choose a password to login. 8 characters minimum (1 number, 1 alphanumeric character and 1 uppercase letter)."><font-awesome-icon
                                    icon="info-circle" /></b-button></label>
                        <div>
                            <b-input-group>
                                <b-form-input :type="visibility" class="form-control inputStyle" id="password" required
                                    v-model="user.password" v-validate="'required|min:8|alphaNumUpperCaseUnique'"
                                    data-vv-name="password" name="password" placeholder="Password">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-button @click="changeVisibility" variant="outline-secondary">
                                        <div v-if="visibility == 'password'">
                                            <font-awesome-icon icon="eye" />
                                        </div>
                                        <div v-else>
                                            <font-awesome-icon icon="eye-slash" />
                                        </div>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div v-if="submitted && veeErrors.has('password')" class="alert alert-danger">
                            {{ veeErrors.first('password') }}
                        </div>

                        <label class="my-2" for="exampleFormControlSelect1">Mail*<b-button class="btn btn-info"
                                v-b-tooltip.hover title="You must enter a valid email address"><font-awesome-icon
                                    icon="info-circle" /></b-button></label>
                        <input class="form-control inputStyle" id="mail" required v-model="user.email"
                            v-validate="'required|email|max:50'" type="email" data-vv-name="email" placeholder="Email"
                            name="mail" />
                        <div v-if="submitted && veeErrors.has('email')" class="alert alert-danger">
                            {{ veeErrors.first('email') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-2">
                    <input class="btn btn-primary form-submit" type="submit" value="Create Administrator User">
                </div>
                <div class="col-2">
                    <button class="btn btn-secondary btn-cancel" type="button"
                        @click="backToCompanies()"><font-awesome-icon icon="ban" /> Cancel</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UserDataService from "@/services/UserDataService"
import AuthDataService from "@/services/AuthDataService"
import AreasDataService from "@/services/AreasDataService"

export default {
    name: 'CreateAdminUserComponent',
    data() {
        return {
            areas: [],
            roles: [],
            roleSelected: [],
            user: {
                username: "",
                name: "",
                surname: "",
                password: "",
                email: "",
                role: { id: 1, name: "admin" },
                areas: []
            },
            languages: [],
            image: "",
            fileName: "",
            submitted: false,
            message: "",
            successfull: false,
            selectedTags: [],
            tags: [],
            repositories: [],
            multiselection: null,
            visibility: 'password',
            files: null
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        this.addCustomValidationRules();
        this.findAreasByCompany();
    },
    methods: {
        findAreasByCompany() {
            AreasDataService.findByCompany(parseInt(this.$route.query.id)).then(
                response => {
                    if (response.status == 200) {
                        this.areas = response.data
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        addCustomValidationRules() {
            this.$validator.extend('alphaNumUpperCaseUnique', {
                getMessage: (field) =>
                    `The password field must be at least 8 characters long, be alphanumeric, contain at least one number, one capital letter and not have three characters repeated in a row.`,
                validate: (value) => {
                    console.log('1')
                    if (!/^(?=.*\d)(?=.*[A-Z])(?!.*(.)\1\1)[a-zA-Z0-9]{8,}$/.test(value)) {
                        return false;
                    }
                    console.log('2')

                    if (value.length < 8) {
                        return false;
                    }
                    console.log('3')

                    if (!/[A-Z]/.test(value)) {
                        return false;
                    }
                    console.log('4')

                    const charMap = new Map();
                    console.log('charMap: ', charMap)
                    console.log('value: ', value)
                    console.log('6')

                    return true;
                },
            });
        },
        backToCompanies() {
            this.$router.replace({ name: "companies", params: {} });
        },
        changeVisibility() {
            if (this.visibility == 'password') {
                this.visibility = 'text';
            } else {
                this.visibility = 'password';
            }
        },
        onFileChange(e) {
            this.files = e.target.files || e.dataTransfer.files;
            if (!this.files.length)
                return;
            this.fileName = this.files[0].name;
            this.createImage(this.files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        handleCreate() {
            console.log('handleCreate')
            this.successfull = true
            this.submitted = true;

            if (this.user.username != '' || this.user.password != '' || this.user.email != '') {
                this.$validator.validate().then(isValid => {
                    console.log('isValid: ', isValid)
                    if (isValid) {
                        this.roleSelected.push(this.user.role);
                        var data = { user: this.user, roles: this.roleSelected, id_company: parseInt(this.$route.query.id) };

                        AuthDataService.register(data).then(
                            async response => {
                                console.log('response register: ', response)
                                var user_id = response.data.id
                                let idsAreas = []

                                this.areas.forEach((item) => {
                                    idsAreas.push(item.id)
                                })

                                await AreasDataService.addUserToAreas(user_id, idsAreas).then(
                                    response => {
                                        if (response.status == 200) {
                                            this.message = 'Users correctly added to the area!'
                                            this.successfull = true
                                        }
                                    },
                                    error => {
                                        this.successfull = false

                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        this.message = "Error creating new admin!"

                                        if (error.response.status === 300) {
                                            console.error('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );

                                let userSettings = { id_user: user_id }

                                await UserDataService.createAccountSettings(userSettings).then(
                                    response => {
                                        if (response.status == 200) {
                                            this.message = 'Admin successfully created!'
                                            this.submitted = false;
                                            this.user = { username: "", name: "", surname: "", password: "", email: "", role: { id: 1, name: "admin" }, areas: [] }
                                        }
                                    },
                                    error => {
                                        this.successfull = false

                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        this.message = "Error creating new admin!"

                                        if (error.response.status === 300) {
                                            console.error('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            },
                            error => {
                                this.successfull = false

                                console.error((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.data.message === 'Failed! Username is already in use!')
                                    this.message = "Error! Username is already in use!"
                                else if (error.response.data.message === 'Failed! Email is already in use!')
                                    this.message = "Error! Email is already in use!"
                                else
                                    this.message = "Error creating new admin!"

                                if (error.response.status === 300) {
                                    console.error('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    }
                });
            }
        },
        close() {
            document.getElementById("message").style.display = "none"
            this.message = ''
        }
    }
};
</script>