<template>
	<div class="col-sm-12">
		<div class="secondNavbar"> 
			<div  class="links-container">
		<router-link :to="{path: '/user'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{$t('dashboards.general.back-home-title')}}</router-link>
			</div>
			<div class="whiteColor">{{$t('dashboards.user.tests.title')}}</div>
		</div>
		<div id="message" v-if="testAttempted" class="alert alert-success col-md-12 mx-4 align-item-center">
			Test attempted succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>
		<user-test-list/>
	</div>
</template>

<script>
  	import UserTestList from "@/components/TestComponents/UserTestList.vue";

	export default {
	  	name: 'UserTestsView',
	  	components: {
	      	UserTestList
	    },
	    props: {
	        testAttempted: {
				type: Boolean,
				default: false
	        }
	    },
		methods: {
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};

</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>