<template>
  <div class="col-md-12">
    <div class="full-container-login">
      <div class="home-header">
        <div class="row header-row">
          <appHomeHeader></appHomeHeader>
          <div id="login-header-image" class="col-lg-6 col-md-12">
			      <img class="main form-image-logo" src="images/icons/arbol_azul.png">
            <div class="login" style="height: 333px !important; opacity: 0 !important;"></div>
          </div>  
        </div> 
      </div>
      <appHomeBody></appHomeBody>
      <appFooter></appFooter>
    </div>
  </div>
</template>

<script>
  import Footer from "@/components/Footer.vue";
  import HomeHeader from "@/components/HomeHeader.vue";
  import HomeBody from "@/components/HomeBody.vue";

  export default {
    name: "Home",
    components: {
      appFooter: Footer,
      appHomeBody: HomeBody,
      appHomeHeader: HomeHeader
    },
    methods: {
    },
    mounted() {
      const recaptcha = this.$recaptchaInstance

      if (recaptcha)
        recaptcha.showBadge()

      const currentUser = this.$store.state.auth.user;
      if (currentUser && currentUser.role) {
        if (currentUser.role.name === 'admin') {
          this.$router.push('/admin');
        } else if (currentUser.role.name === 'user') {
          this.$router.push('/user');
        } else if (currentUser.role.name === 'examiner') {
          this.$router.push('/examiner');
        } else if (currentUser.role.name === 'validator') {
          this.$router.push('/questions-validator');
        } else if (currentUser.role.name === 'certifier') {
          this.$router.push('/certifications-validator');
        } else if (currentUser.role.name === 'superadmin') {
          this.$router.push('/superadmin');
        }
      }
    }
  };
</script>
