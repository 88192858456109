<template>
	<div class="full-container-login">
		<div class="home-header">
			<div class="row header-row">
			    <appHomeHeader></appHomeHeader>
			    <div id="login-header-image" class="col-lg-6 col-md-12">
					<img class="main form-image-logo" src="images/icons/arbol_azul.png">
					<div class="login">
						<h1 class="form-title">{{title}}</h1>
						<div id="message" v-if="previousRegister" class="alert alert-success">
							Registration has been completed succesfully! Enter your credentials to sign in the platform. <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
						</div>
						<div id="message2" v-if="requestResetPassword" class="alert alert-success">
							Reset password invitation sended successfully. Visit your email for more instructions! <b-button @click="close2" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
						</div>
						<div id="message3" v-if="resetPassword" class="alert alert-success">
							Password changed succesfully. Now, you can access with your new password! <b-button @click="close3" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
						</div>
						<form action class="form" @submit.prevent="handleLogin">
							<div class="form-group">
								<input class="form-input pl-3 username" type="text" required placeholder="Username" v-model="user.username">
								<div v-if="veeErrors.has('username')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									Username is required!
								</div>
							</div>
							<div class="form-group">
								<input class="form-input pl-3 password" :type="visibility" required placeholder="Password" v-model="user.password">

								<a @click="showPassword()" style="position: absolute; right: 26px; top: 3px;" class="notunderlined" v-if="visibility == 'password'">
									<font-awesome-icon icon="eye" />
								</a>

								<a @click="hidePassword()" style="position: absolute; right: 26px; top: 3px;" class="notunderlined" v-if="visibility == 'text'">
									<font-awesome-icon icon="eye-slash" />
								</a>

								<div v-if="veeErrors.has('password')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									Password is required!
								</div>
							</div>
							<div class="form-group">
								<router-link to="/request-reset-password" class="btn btn-secondary small-info float-right">Forgot password</router-link>
							</div>
							<input class="btn btn-primary form-submit" type="submit" value="Login">
							<div class="form-group">
								<div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
							</div>
						</form>
					</div>
			    </div>
		    </div>
		</div>
		<appHomeBody></appHomeBody>
    	<appFooter></appFooter>
	</div>
</template>

<script>
	import User from '@/models/user';
	import Footer from "@/components/Footer.vue";
	import HomeHeader from "@/components/HomeHeader.vue";
	import HomeBody from "@/components/HomeBody.vue";

	export default {
		name: 'Login',
		components: {
			appFooter: Footer,
			appHomeBody: HomeBody,
			appHomeHeader: HomeHeader
	    },
		data() {
			return {
				user: new User('', ''),
				loading: false,
				message: '',
				title: this.$t('dashboards.general.welcome-back'),
				visibility: 'password'
			};
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.status.loggedIn;
			}
		},
		created() {
			const recaptcha = this.$recaptchaInstance

			if (recaptcha)
				recaptcha.showBadge()

			if (this.loggedIn && this.$store.state.auth.user != null) {
				if (this.$store.state.auth.user.role.name === 'user') {
					this.$router.push('/user');
				} else if (this.$store.state.auth.user.role.name === 'admin') {
					this.$router.push('/admin');
				} else if (this.$store.state.auth.user.role.name === 'examiner') {
					this.$router.push('/examiner');
				} else if (this.$store.state.auth.user.role.name === 'validator') {
					this.$router.push('/questions-validator');
				} else if (this.$store.state.auth.user.role.name === 'certifier') {
					this.$router.push('/certifications-validator');
				} else if (this.$store.state.auth.user.role.name === 'superadmin') {
					this.$router.push('/superadmin');
				} else if (this.$store.state.auth.user.role.name === 'talent_manager') {
					this.$router.push('/talent_manager');
				} else if (this.$store.state.auth.user.role.name === 'manager') {
					this.$router.push('/manager');
				}
			}
		},
		methods: {
			handleLogin() {
				this.loading = true;
				this.$validator.validateAll().then(isValid => {
					if (!isValid) {
						this.loading = false;
						return;
					}
					if (this.user.username && this.user.password) {
						this.$store.dispatch('auth/login', this.user).then(
							(response) => {
								if(response.status != undefined && response.status == 401){
									this.message = response.message
								} else {
									this.$root.$emit('mountedMethod');
									if (this.$store.state.auth.user.role.name === 'user') {
										this.$router.push('/user');
										localStorage.setItem('homeRoute', '/user')
									} else if (this.$store.state.auth.user.role.name === 'admin') {
										this.$router.push('/admin');
										localStorage.setItem('homeRoute', '/admin')
									} else if (this.$store.state.auth.user.role.name === 'examiner') {
										this.$router.push('/examiner');
										localStorage.setItem('homeRoute', '/examiner')
									} else if (this.$store.state.auth.user.role.name === 'validator') {
										this.$router.push('/questions-validator');
										localStorage.setItem('homeRoute', '/questions-validator')
									} else if (this.$store.state.auth.user.role.name === 'certifier') {
										this.$router.push('/certifications-validator');
										localStorage.setItem('homeRoute', '/certifications-validator')
									} else if (this.$store.state.auth.user.role.name === 'superadmin') {
										this.$router.push('/superadmin');
										localStorage.setItem('homeRoute', '/superadmin')
									} else if (this.$store.state.auth.user.role.name === 'talent_manager') {
										this.$router.push('/talent_manager');
										localStorage.setItem('homeRoute', '/talent_manager')
									} else if (this.$store.state.auth.user.role.name === 'manager') {
										this.$router.push('/manager');
										localStorage.setItem('homeRoute', '/manager')
									}
								}
							},
							error => {
								this.loading = false;
								this.message = error.response.data.message
								console.log((error.response && error.response.data) ||
									error.message ||
									error.toString());
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
						);
					}
				});
			},
			showPassword() {
				this.visibility = 'text';
			},
			hidePassword() {
				this.visibility = 'password';
			},
			close() {
				document.getElementById("message").style.display = "none"
			},
			close2() {
				document.getElementById("message2").style.display = "none"
			},
			close3() {
				document.getElementById("message3").style.display = "none"
			},
			close4() {
				document.getElementById("message4").style.display = "none"
			}
		},
	    props: {
	        previousRegister: {
				type: Boolean,
				default: false
	        },
	        requestResetPassword: {
				type: Boolean,
				default: false
	        },
	        resetPassword: {
				type: Boolean,
				default: false
	        },
			refreshCache: {
				type: Boolean,
				default: false
	        }
	    }
	};
</script>