<template>
	<div class="technologiesStyle my-2">
		<template v-if="certifications.length >0">
			<div id="messageDelete" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      		</div>
			<div class="row" style="margin-top: 1%;">
				<div class="col-xl-3 col-lg-4">
					<b-pagination
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="certificationsList"
					></b-pagination>
				</div>
				<div class="col-xl-6 col-lg-4">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
					<div class="numPerPage paginationSelectorTable mx-3 my-2">Validation: </div>
					<b-form-select class="numPerPage paginationSelectorTable mx-1" v-model="filterModel" @change="changeFilter" :options="filterBy"> </b-form-select>
					<button class="btn btn-primary table-selection-button" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
					<button class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete {{selectedItems.length}} rows</button> 
				</div>
				<div class="col-xl-3 col-lg-3 margin-on-lg">
					<b-form-input 
						id="filter-input"
						v-model="filter"
						type="search"
						:placeholder="$t('others.search-placeholder-msg')"
					></b-form-input>
				</div>
			</div>
			<b-table ref="certificationsTable"
				id="certificationsList"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="headers" 
				:items="returnCertifications()"
				:filter="filter"
				select-mode="multi"
				responsive="sm"
				selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(name)="data">
					<p class="text-left">{{ data.item.name }}</p>
				</template>
				<template #cell(acronym)="data">
					<p v-if="data.item.acronym != '' && data.item.acronym != null">{{ data.item.acronym }}</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(entity)="data">
					<p v-if="data.item.entity != '' && data.item.entity != null">{{ data.item.entity }}</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(expiration_years)="data">
					<p v-if="data.item.expiration_years != '' && data.item.expiration_years != null">{{ data.item.expiration_years }}</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(url)="data">
					<button class="btn btn-secondary" type="button" v-if="data.item.url != '' && data.item.url != null" v-bind:title="data.item.url" @click="openNewTab(data.item.url)"><font-awesome-icon icon="link" /></button>
					<p v-else>Url not available</p>
				</template>
				<template #cell(duration)="data">
					<p v-if="data.item.duration != '' && data.item.duration != null">{{ data.item.duration }} h</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(validated)="data">
					<p v-if="data.item.validated != null && data.item.validated"><font-awesome-icon class="completed-icon-yes" icon="check" /></p>
					<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Edit" :to="{path: '/update-certification?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
					<template v-if="data.item.validated != null">
						<button v-if="data.item.validated == false" v-b-tooltip.hover title="Validate certification" class="btn btn-secondary" @click="showValidateModal(data.item.id, data.index)"><font-awesome-icon icon="check-circle" /></button>
					</template>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
		</template>

      	<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete certification
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} certifications? </p>
				<p v-else> Do you really want to delete this certification? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="deleteCertification()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
		</b-modal>

		<b-modal id="validate-modal" ref="validate-modal" title="Validate-modal" hide-footer>
			<template #modal-title>
				Validate certification
			</template>
			<div>
				<p>Do you really want to validate this certification?. This action is irreversible. </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="validateCert()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('validate-modal')" value="No">
		</b-modal>
	</div>
</template>

<script>
	import CertificationsDataService from '@/services/CertificationsDataService';

	export default {
		name: 'CertificationConfiguration',
		data() {
			return {
				headers: [
					{ key: 'name', sortable: true, label: 'Name' },{ key: 'acronym', sortable: true, label: 'Acronym' },{ key: 'entity', sortable: true, label: 'Entity' },{ key: 'expiration_years', sortable: true, label: 'Years valid' },{ key: 'url', label: 'Link to the course' },{ key: 'duration', sortable: true, label: 'Duration' },{ key: 'validated', label: 'Validated' },{ key: 'options', label: 'Options' }, 
				],
				filterBy: [{value: 0, text: "All"},{value: 1, text: "Validated"}, {value:2, text: "Not validated"}],
				filterModel: 0,
				filterByValidated: false,
				filterByNotValidated: false,
				message: "",
				successfull: false,
				perPage: 10,
				filter: null,
				selectedItems: [],
				selectedCertification: "",
				selectedCertificationIndex: "",
				pageOptions: this.$paginationOptions,
				currentPage: 1
			}
	    },
	    props: {
	    	certifications: Array
	    },
		mounted() {
			if(this.$store.state.auth.user.table_records_per_page != undefined)
				this.perPage = this.$store.state.auth.user.table_records_per_page
	  	},
	    computed: {
			rows() {
				return this.certifications.length
			}
	    },
	  	methods: {
	  		onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["certificationsTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["certificationsTable"].clearSelected();
	      	},
	  		validateCert() {
				CertificationsDataService.validate(this.selectedCertification).then(
					response => {
						this.message = response.data.message
						if (response.status == 200) {
							this.successfull = true
						} else {
							this.successfull = false
						}
						this.$refs['validate-modal'].hide();
						this.certifications[this.selectedCertificationIndex].validated = true;
						this.$refs.certificationsTable.refresh();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	  		},
	  		deleteCertification(){
				let selectedRows = this.selectedItems.map(element => element.id);

				CertificationsDataService.delete(selectedRows).then(
					response => {
						if (response.status == 200) {
				      		this.successfull = true
							this.$emit('deleteCertification');
				      	} else {
				      		this.successfull = false
				      	}

						this.$refs['delete-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	}, 
			returnCertifications(){
				if(!this.filterByValidated && !this.filterByNotValidated){
					return this.certifications;
				}
				else if(this.filterByValidated){
					return this.certifications.filter(field => field.validated == true)
				}
				else {
					return this.certifications.filter(field => field.validated == false)
				}
			},
			changeFilter(){
				const item = this.filterModel; 
				if(item == 0){
					this.filterByValidated = false; 
					this.filterByNotValidated = false; 
				} else if (item == 1){
					this.filterByValidated = true; 
					this.filterByNotValidated = false; 
				} else {
					this.filterByValidated = false; 
					this.filterByNotValidated = true; 
				}
			},
	      	showValidateModal(id, index) {
				this.selectedCertification = id
				this.selectedCertificationIndex = index
				this.$refs['validate-modal'].show();
	      	},
	      	showDeleteModalMultiple() {
	      		this.$refs['delete-modal'].show();
	      	},
			close() {
				document.getElementById("messageDelete").style.display = "none"
			},
			openNewTab (url) {
				window.open(url, "_blank")
			}
	    }
	};
</script>