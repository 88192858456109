<template>
	<div class="wrapper">
		<h4>Assessment Test '{{ test.title }}'</h4>

		<countdown v-show="countdownExists" :time="time" :transform="transform" @progress="handleCountdownProgress"
			@end="handleCreate">
			<template slot-scope="props">
				<h2 style="text-align:center">
					Remaining time: {{ props.minutes }}, {{ props.seconds }}.
				</h2>
			</template>
		</countdown>

		<form action class="form" @submit.prevent="showModal">
			<notifications position="top center" animation-type="velocity" width="50%" />
			<div class="test-container">
				<div class="question-container not-selectable" v-bind:id="'question-container_' + index"
					v-for="(question, index) in questions" v-bind:key="question.id">
					<h4 class="question-title">Question {{ index + 1 }}/{{ questions.length }}</h4>
					<br>
					<p class="title not">{{ question.question_title }}</p>
					<div class="content" v-if="question.question_content != '' && question.question_title != null">
						<div class="code" v-html="question.question_content"></div>
					</div>
					<div class="list" v-if="question.multiselection">
						<div class="col-sm-12">
							<label><input type="checkbox" @click="registerCheckedOption(index, 1, 0)"
									v-bind:id="'answer_one_' + question.id"> {{ question.answer_one }}</label>
						</div>
						<div class="col-sm-12">
							<label><input type="checkbox" @click="registerCheckedOption(index, 2, 0)"
									v-bind:id="'answer_two_' + question.id"> {{ question.answer_two }}</label>
						</div>
						<div class="col-sm-12">
							<label><input type="checkbox" @click="registerCheckedOption(index, 3, 0)"
									v-bind:id="'answer_three_' + question.id"> {{ question.answer_three }}</label>
						</div>
						<div class="col-sm-12">
							<label><input type="checkbox" @click="registerCheckedOption(index, 4, 0)"
									v-bind:id="'answer_four_' + question.id"> {{ question.answer_four }}</label>
						</div>
					</div>
					<div class="list" v-else>
						<div class="col-sm-12">
							<label><input class="form-input" type="radio" name="multiselection" value="false"
									@click="registerCheckedOption(index, 1, 1)" v-bind:id="'answer_one_' + question.id">
								{{ question.answer_one }}</label>
						</div>
						<div class="col-sm-12">
							<label><input class="form-input" type="radio" name="multiselection" value="false"
									@click="registerCheckedOption(index, 2, 1)" v-bind:id="'answer_two_' + question.id">
								{{ question.answer_two }}</label>
						</div>
						<div class="col-sm-12">
							<label><input class="form-input" type="radio" name="multiselection" value="false"
									@click="registerCheckedOption(index, 3, 1)"
									v-bind:id="'answer_three_' + question.id">
								{{ question.answer_three }}</label>
						</div>
						<div class="col-sm-12">
							<label><input class="form-input" type="radio" name="multiselection" value="false"
									@click="registerCheckedOption(index, 4, 1)"
									v-bind:id="'answer_four_' + question.id">
								{{ question.answer_four }}</label>
						</div>
					</div>
					<div class="buttons">
						<button type="button" class="btn btn-secondary float-left previous"
							@click="previousQuestion(index)" v-bind:value="index" v-bind:id="'prev_' + question.id"
							v-if="index != 0"><font-awesome-icon icon="arrow-circle-left" /> Previous</button>
						<button type="button" class="btn btn-secondary float-right next" @click="nextQuestion(index)"
							v-bind:value="index" v-bind:id="'next_' + question.id"
							v-if="index != questions.length - 1">Next
							<font-awesome-icon icon="arrow-circle-right" /></button>
					</div>
					<div v-if="index == questions.length - 1" class="form-group col-lg-2 align-item-center">
						<button type="submit" class="btn btn-primary">Finish</button>
					</div>
				</div>
			</div>
		</form>

		<b-modal id="create-modal" ref="create-modal" title="Create-modal" hide-footer>
			<template #modal-title>
				Finish test
			</template>
			<div>
				<p>Do you really want to finish your test? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('create-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="handleCreate()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import Vue from 'vue';
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import Test from '@/models/test';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(Notifications, { velocity })

export default {
	name: "TestAttemptComponent",
	components: { VueCountdown },
	data: () => ({
		test: new Test('', '', '', '', '', '', '',
			'', '', '', '', '', '', '',
			'', ''),
		questions: [],
		submitted: false,
		shuffle: false,
		time: 10000000,
		countdownExists: false,
	}),
	mounted() {
		if (this.$route.params.time_limit) {
			this.countdownExists = true;
			this.time = this.$route.params.time_limit * 60 * 1000;
		}
		window.addEventListener('beforeunload', this.redirect);

		TestDataService.getQuestionsForAttempt(this.$route.query.id).then(
			response => {
				this.questions = response.data.questions
				this.shuffle = response.data.shuffle

				if (this.shuffle) {
					var arrayIndices = []
					var questionsTemp = []

					for (var i = 0; i < this.questions.length; i++) {
						var aleatorio = Math.random() * (1000 - 1) + 1
						arrayIndices.push({ 'indice': i, 'valor': aleatorio })
					}

					arrayIndices.sort(function (a, b) {
						return (a.valor - b.valor)
					})

					for (var i = 0; i < arrayIndices.length; i++) {
						questionsTemp.push(this.questions[arrayIndices[i].indice])
					}

					this.questions = questionsTemp
				}

				for (var i = 0; i < this.questions.length; i++) {
					this.questions[i].answer_one_selected = 0;
					this.questions[i].answer_two_selected = 0;
					this.questions[i].answer_three_selected = 0;
					this.questions[i].answer_four_selected = 0;
				}
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
		TestDataService.findOne(this.$route.query.id).then(
			response => {
				this.test = response.data;
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	props: {
		idAttempt: Number
	},
	methods: {
		redirect(event) {
			var confirmationMessage = "\o/";
			event.returnValue = confirmationMessage;
			return confirmationMessage;
		},
		nextQuestion(index) {
			var nextIndex = index + 1
			document.getElementById('question-container_' + index).style.display = 'none'
			document.getElementById('question-container_' + nextIndex).style.display = 'block'
		},
		previousQuestion(index) {
			var previousIndex = index - 1
			document.getElementById('question-container_' + index).style.display = 'none'
			document.getElementById('question-container_' + previousIndex).style.display = 'block'
		},
		registerCheckedOption(index, answer_number, type) {
			if (type == 0) {
				if (answer_number == 1) {
					if (this.questions[index].answer_one_selected == 0) {
						this.questions[index].answer_one_selected = 1
					} else {
						this.questions[index].answer_one_selected = 0
					}
				} else if (answer_number == 2) {
					if (this.questions[index].answer_two_selected == 0) {
						this.questions[index].answer_two_selected = 1
					} else {
						this.questions[index].answer_two_selected = 0
					}
				} else if (answer_number == 3) {
					if (this.questions[index].answer_three_selected == 0) {
						this.questions[index].answer_three_selected = 1
					} else {
						this.questions[index].answer_three_selected = 0
					}
				} else {
					if (this.questions[index].answer_four_selected == 0) {
						this.questions[index].answer_four_selected = 1
					} else {
						this.questions[index].answer_four_selected = 0
					}
				}
			} else {
				if (answer_number == 1) {
					this.questions[index].answer_one_selected = 1
					this.questions[index].answer_two_selected = 0
					this.questions[index].answer_three_selected = 0
					this.questions[index].answer_four_selected = 0
				} else if (answer_number == 2) {
					this.questions[index].answer_one_selected = 0
					this.questions[index].answer_two_selected = 1
					this.questions[index].answer_three_selected = 0
					this.questions[index].answer_four_selected = 0
				} else if (answer_number == 3) {
					this.questions[index].answer_one_selected = 0
					this.questions[index].answer_two_selected = 0
					this.questions[index].answer_three_selected = 1
					this.questions[index].answer_four_selected = 0
				} else {
					this.questions[index].answer_one_selected = 0
					this.questions[index].answer_two_selected = 0
					this.questions[index].answer_three_selected = 0
					this.questions[index].answer_four_selected = 1
				}
			}
		},

		showModal() {
			this.$refs['create-modal'].show();
		},
		handleCreate() {
			this.submitted = true;
			TestDataService.updateAttempt(this.$route.params.idAttempt, this.questions).then(
				response => {
					if (response.status == 200) {
						this.successful = true;
						this.$router.push({ name: "user-list-tests", params: { testAttempted: true } });
					} else {
						this.message = response.data.message
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = error.response.data.message

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		transform(props) {
			Object.entries(props).forEach(([key, value]) => {
				const digits = value < 10 ? `0${value}` : value;

				const word = value < 2 ? key.replace(/s$/, '') : key;

				props[key] = `${digits} ${word}`;
			});

			return props;
		},
		handleCountdownProgress(data) {
			if (data.minutes < 1 && data.seconds == 59 && data.hours == 0) {
				this.$notify({
					type: 'error',
					duration: '5000',
					title: 'Alert',
					text: 'Less that a minute remaining!'
				});
			}
		}
	}
};
</script>

<style scoped>
.not-selectable {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome and Opera */
}
</style>