<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option value="all">All areas</option>
			<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:forceVertical="true"
				:data="data"
				:chartTitle = "graphTitle"></barr-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"
	
	export default {
		name: 'UsersGraphCVByAdmins',
		data() {
			return {
				chartId: "chartUsersGraphCVByAdmins",
				data: [],
				temp: [],
				graphTitle: "Users CVs analysed per admins by date",
				xAxesCategory: "date",
				yAxesValue: "cv_count",
				xAxesTitle: "Date",
				yAxesTitle: "Number of CVs Analysed",
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedAreaName: null
			}
		},
		components: {
			BarrChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea)){
						this.selectedAreaName = area.name
						this.graphTitle = "Users CVs analysed per admins of " + this.selectedAreaName + " by date";
					}
				});

				this.graphLoading = true;

				if ("all" == this.selectedArea){
					this.selectedAreaName = null;
					this.fetchDataCompany();
				} else {
					this.fetchDataArea();
				}
			},
			async fetchDataCompany() {
				await ReportsDataService.analyzedCVsByCompanyAdmins(this.$store.state.auth.user.id_company).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			async fetchDataArea() {
				await ReportsDataService.analyzedCVsByCompanyAdminsInArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.graphLoading = true;
			this.selectedArea = "all";
			this.selectedAreaName = null;
			
			this.fetchDataCompany();
		}
	};
</script>