<template>
	<div>
		<section>
			<h4 style="margin-top: 0%; text-align: left;border-bottom: 3px solid;padding-bottom: 9px; margin-bottom: 14px; font-size: 18.66px; padding-left: 0px !important">Personal Data</h4>
			<div class="row">
				<div class="col-sm-12 pt-0 pb-2" style="margin-left: 0px;">
					<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B;">General information</h5>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-5" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-3 pt-0 pb-0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;"><b>Name:</b></p>
						</div>
						<div class="col-sm-9 pt-0 pb-0">
							<p v-if="data.name != ''" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{data.name}}</p>
							<p v-else="" style="text-align: left;">Not available</p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-3 pt-0 pb-0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;"><b>Email:</b></p>
						</div>
						<div class="col-sm-9 pt-0 pb-0">
							<p v-if="data.email != ''" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{data.email}}</p>
							<p v-else="" style="text-align: left;">Not available</p>
						</div>
					</div>
				</div>
				<div class="col-sm-7">
					<div class="row">
						<div class="col-sm-6 pt-0 pb-0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;"><b>Experience years:</b></p>
						</div>
						<div class="col-sm-6 pt-0 pb-0">
							<p style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{experience.years}} years and {{experience.months}} months</p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 pt-0 pb-0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;"><b>Phone:</b></p>
						</div>
						<div class="col-sm-6 pt-0 pb-0">
							<p v-if="data.phone_number != ''" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{data.phone_number}}</p>
							<p v-else="" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">Not available</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pt-0 pb-0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px; color: #13283B;"><b>Description:</b></p>
						</div>
						<div class="col-sm-12 pt-0 pb-0">
							<p v-if="data.description != ''" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">{{data.description}}</p>
							<p v-else="" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">Not available</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; padding-top: 5px; color: #13283B;">Professional Career</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="jobs.length == 0">
							<p class="no-margin-bottom" style="text-align: left; font-size: 14.66px; margin-bottom: 0px">No professional career registered</p>
						</div>
						<template v-else>
							
							<ul class="col-sm-12 pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
								<li v-for="item in jobs" v-bind:key="item" style="list-style: none; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">&bull; {{item.date}}. {{ item.job }} / ({{ item.company }}) during {{item.duration.years}} years, {{item.duration.months}} months</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>
			
		<template v-for="(skillValues, skillName, index) in skills">
		<section>
				<div class="row" v-bind:key="skillName">
					<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
						<div class="row">
							<div class="col-sm-12 pb-1">
								<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">{{skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", " ").slice(1)}}</h5>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 pt-0 pb-0" v-if="skillValues.length == 0">
								<p class="no-margin-bottom" style="text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">No skills registered</p>
							</div>
							<template v-else>
								<ul class="pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
									<li v-for="skill in skillValues" v-bind:key="skill" class="col-sm-3 no-margin-bottom" style="float: left; display: inline; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">&bull; {{skill.charAt(0).toUpperCase() + skill.slice(1)}}</li>
								</ul>
							</template>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">Languages</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="languages.length == 0">
							<p class="no-margin-bottom" style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">No languages registered</p>
						</div>
						<template v-else>
							<ul class="col-sm-12 pt-0 pb-0" style="margin-bottom: 0px">
								<li v-for="language in languages" v-bind:key="language" class="no-margin-bottom" style="list-style: none; text-align: left;  margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">&bull; {{language.language}} (Level: {{ language.level }})</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">Awards</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="awards.length == 0">
							<p class="no-margin-bottom" style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">No awards registered</p>
						</div>
						<template v-else>
							<ul class="col-sm-12 pt-0 pb-0" style="padding-left: 0px; margin-bottom: 0px">
								<li v-for="award in awards" v-bind:key="award" style="list-style: none; text-align: left; margin-left: 13px; font-size: 14.66px; margin-bottom: 0px">&bull; {{award.replace('-', '')}}</li>
							</ul>
						</template>
					</div>
				</div>
			</div>
		</section>
		<section>
			<div class="row">
				<div class="col-sm-12" style="padding-left: 13px !important; padding-right: 13px !important;">
					<div class="row">
						<div class="col-sm-12 pb-1">
							<h5 class="mb-0" style="padding: 0 !important; font-size: 14.66px; margin-bottom: 0px; color: #13283B; padding-top: 5px">Keywords</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4 pt-0 pb-0" v-if="keywords.length == 0">
							<p class="no-margin-bottom" style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">No keywords registered</p>
						</div>
						<template v-else>
							<div class="col-sm-4 pt-0 pb-0" v-for="keyword in keywords" v-bind:key="keyword">
								<p class="no-margin-bottom" style="text-align: left; margin-left: 0px; font-size: 14.66px; margin-bottom: 0px">{{keyword}}</p>
							</div>
						</template>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
	  	name: "PersonalDataPDF",
		data() {
	      return {
	      	pruebas: ['Prueba 1', 'Prueba 2', 'Prueba 3', 'Prueba 4', 'Prueba 5', 'Prueba 6', 'Prueba 7'],
	      	pruebas2: [{study_site: 'Universidad de Salamanca', type_education_date: 'Doctorado, ingeniería informática (2014 - 2017)'},
			  {study_site: 'Universidad de Salamanca', type_education_date: 'Master, sistemas inteligentes · (2013 - 2014)'},
			  {study_site: 'Universidad de Salamanca', type_education: 'De 2019)'},
			  {study_site: 'Universidad de Salamanca', type_education_date: 'Master, comercio electronico · (2010 - 2011)'}],
	      }
	    },
	  	props: {
	  	   data: Object,
		   awards: Array,
		   certifications: Array,
		   keywords: Array,
		   links: Array,
		   skills: Object,
		   education: Array,
		   jobs: Array,
		   languages: Array,
		   experience: Object,
		   pdf: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			redirectExternalLink(link) {
				if (link.indexOf('https://') <= -1 || link.indexOf('http://') <= -1) {
					link = 'https://'+link
				}
				window.open(
				  link,
				  '_blank' // <- This is what makes it open in a new window.
				);
			}
		}
	};
</script>