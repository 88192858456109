<template>
  <div>
    <horizontal-stepper ref="stepper" :key="reloadComponent" :steps="formSteps" :top-buttons="true"
      @completed-step="completeStep" @stepper-finished="finishForm"></horizontal-stepper>

    <b-modal id="finish-modal" ref="finish-modal" title="Finish-modal" hide-footer>
      <template #modal-title>
        You have fully completed your Profile
      </template>
      <div>
        <p>You will be redirected to your profile summary </p>
      </div>
      <input class="btn btn-primary form-submit" type="button" @click="redirect()" value="Accept">
    </b-modal>
  </div>
</template>

<script>
import HorizontalStepper from 'vue-stepper';
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
import PersonalDataStep from './InterviewFormSteps/PersonalDataStep.vue';
import ExperienceStep from './InterviewFormSteps/ExperienceStep.vue';
import SkillsStep from './InterviewFormSteps/SkillsStep.vue';
import EducationAndLanguagesStep from './InterviewFormSteps/EducationAndLanguagesStep.vue';
import CertificationsStep from './InterviewFormSteps/CertificationsStep.vue';

export default {
  name: 'InterviewForm',
  components: {
    HorizontalStepper,
  },
  data() {
    return {
      data: {},
      reloadComponent: false,
      formSteps: [
        {
          icon: 'person',
          name: 'first',
          title: 'Personal data',
          subtitle: 'Basic questions about personal data',
          component: PersonalDataStep,
          top_buttons: true,
          completed: false
        },
        {
          icon: 'grade',
          name: 'second',
          title: 'Skills',
          subtitle: 'Provide information about your skills',
          component: SkillsStep,
          top_buttons: true,
          completed: false
        },
        {
          icon: 'work',
          name: 'third',
          title: 'Experience',
          subtitle: 'Let us know about your working experience',
          component: ExperienceStep,
          top_buttons: true,
          completed: false
        },
        {
          icon: 'school',
          name: 'fourth',
          title: 'Education and Languages',
          subtitle: 'Inform us about your educational and language proficiency',
          component: EducationAndLanguagesStep,
          top_buttons: true,
          completed: false
        },
        {
          icon: 'school',
          name: 'fifth',
          title: 'Certifications',
          subtitle: 'Please include your certifications',
          component: CertificationsStep,
          top_buttons: true,
          completed: false
        }
      ],
      profile: {},
      idProfile: ''
    }
  },
  props: {
    analysisRegistered: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await ProfileAnalysisDataService.findByChatToken(this.$route.query.token).then(
        response => {
          if (response.status != 200) {
            this.$router.push({ name: "profile" });
          } else {
            this.idProfile = response.data.id
            this.profile = response.data

            if (this.profile.name != '') {
              let nameArr = this.profile.name.split(/(\s+)/).filter(function (e) { return e.trim().length > 0; });
              this.data.name = nameArr[0];
              this.data.surnames = nameArr.slice(-2).join(" ");
            }
            if (this.profile.email != '') {
              this.data.email = this.profile.email
            }

            if (this.profile.certifications != '') {
              let certifications = this.profile.certifications
              certifications = certifications.replaceAll("'", "\"");
              certifications = JSON.parse(certifications)
              this.data.certifications = certifications
            }

            if (this.profile.skills != '') {
              let skills = this.profile.skills
              skills = skills.replaceAll("'", "\"");
              skills = JSON.parse(skills)
              this.data.skills = skills
            }

            if (this.profile.education != '') {
              let education = this.profile.education
              education = education.replaceAll("'", "\"");
              education = JSON.parse(education)
              this.data.education = education
            }

            if (this.profile.experience_jobs != '') {
              let jobs = this.profile.experience_jobs;
              jobs = jobs.replaceAll("'", "\"");
              jobs = JSON.parse(jobs)
              this.data.jobs = jobs
            }

            if (this.profile.experience != '') {
              let experience = this.profile.experience_time;
              experience = experience.replaceAll("'", "\"");
              experience = JSON.parse(experience)
              this.data.experience = experience
            }

            if (this.profile.languages != '') {
              let languages = this.profile.languages;
              languages = languages.replaceAll("'", "\"");
              languages = JSON.parse(languages)
              this.data.languages = languages
            }

            this.reloadComponent = this.reloadComponent ? false : true;
          }
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    completeStep(payload) {
      this.formSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      })
    },
    formatExperienceTime(jobs) {
      let result = { 'years': 0, 'months': 0 };

      jobs.forEach((job) => {
        let months = 0;
        let years = 0;

        if (job.date_end.split("-") != null && job.date_start.split("-") != null) {
          const end = job.date_end.toString()
          const start = job.date_start.toString()

          const date1 = new Date(Date.parse(end));
          const date2 = new Date(Date.parse(start));

          let diff = 0;
          if (date1.getTime() > date2.getTime()) {
            diff = Math.floor(date1.getTime() - date2.getTime());
            const day = 1000 * 60 * 60 * 24;
            const days = Math.floor(diff / day);
            months = Math.floor(days / 31);
            years = Math.floor(months / 12);

            if (months > 12)
              months = months - (12 * years);

            result.years += years;
            result.months += months;
          } else {
            months = 0;
            years = 0;
          }
        }
      });

      if (result.months > 12) {
        let yearsToAdd = Math.floor(result.months / 12);
        let monthsTotal = result.months - (yearsToAdd * 12);

        result.years += yearsToAdd;
        result.months = monthsTotal;
      }

      return result
    },
    formatEducationDates(education) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      if (education[0].date_start == undefined) return education;

      let result = [];
      education.forEach((ed) => {
        let edTmp = {};
        edTmp.study_site = ed.study_site;
        if (ed.education_other_type == null) {
          edTmp.type_education_date = ed.type + ", " + ed.education_type + " (" + new Date(Date.parse(ed.date_start.toString() + "-01")).getFullYear() + " - " + new Date(Date.parse(ed.date_end.toString() + "-01")).getFullYear() + ")"
        } else {
          edTmp.type_education_date = ed.education_other_type + ", " + ed.education_type + " (" + new Date(Date.parse(ed.date_start.toString() + "-01")).getFullYear() + " - " + new Date(Date.parse(ed.date_end.toString() + "-01")).getFullYear() + ")"
        }

        result.push(edTmp);
      })

      return result;
    },
    formatJobDates(jobs) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      if (jobs[0].date_start == undefined) return jobs;

      let result = [];
      jobs.forEach((job) => {
        let jobTmp = {};

        let monthsTmp = 0;
        let yearsTmp = 0;
        if (job.date_end.split("-") != null && job.date_start.split("-") != null) {
          const end = job.date_end.toString()
          const start = job.date_start.toString()

          const date1 = new Date(Date.parse(end));
          const date2 = new Date(Date.parse(start));

          let diff = 0;
          if (date1.getTime() > date2.getTime()) {
            diff = Math.floor(date1.getTime() - date2.getTime());
            const day = 1000 * 60 * 60 * 24;
            const days = Math.floor(diff / day);
            monthsTmp = Math.floor(days / 31);
            yearsTmp = Math.floor(monthsTmp / 12);
          } else {
            monthsTmp = 0;
            yearsTmp = 0;
          }

          if (isNaN(monthsTmp) || monthsTmp == null || monthsTmp == undefined)
            monthsTmp = 0;

          if (isNaN(yearsTmp) || yearsTmp == null || yearsTmp == undefined)
            yearsTmp = 0;

          if (monthsTmp > 12)
            monthsTmp = monthsTmp - (12 * yearsTmp);
        } else {
          yearsTmp = 0;
          monthsTmp = 0;
        }

        jobTmp.duration = { years: yearsTmp, months: monthsTmp }
        jobTmp.job = job.job;
        jobTmp.description = job.description
        jobTmp.company = job.company
        jobTmp.date = monthNames[parseInt(new Date(Date.parse(job.date_start.toString() + "-01")).getMonth())] + " " +
          new Date(Date.parse(job.date_start.toString() + "-01")).getFullYear().toString() + " - " +
          monthNames[parseInt(new Date(Date.parse(job.date_end.toString() + "-01")).getMonth())] + " " +
          new Date(Date.parse(job.date_end.toString() + "-01")).getFullYear().toString();

        result.push(jobTmp);
      });

      return result;
    },
    formatCerts(certifications) {
      let result = [];
      certifications.forEach((cert) => {
        result.push(cert.cert_name);
      })

      return result;
    },
    formatSkills(skills) {
      let result = {};

      if (!Array.isArray(skills)) return skills;

      skills.forEach((skill) => {
        let skillTmp = [];

        if (result[skill.type] == undefined)
          result[skill.type] = [];

        result[skill.type].push(skill.skill_name);
      })

      return result;
    },
    finishForm(payload) {
      let newUserData = {};

      this.$refs["stepper"].$children.forEach((step) => {
        Object.entries(step._data.result).forEach(([key, value]) => {
          if ("experience_jobs" === key) {
            newUserData[key] = this.formatJobDates(value);
            if (value[0].date_start != undefined)
              newUserData['experience_time'] = this.formatExperienceTime(value);
          } else if ("education" === key) {
            newUserData[key] = this.formatEducationDates(value);
          } else if ("skills" === key) {
            newUserData[key] = this.formatSkills(value);
          } else if ("certifications" === key) {
            newUserData[key] = this.formatCerts(value);
          } else if ("languages" === key) {
            newUserData[key] = value;
          } else if ("name" === key) {
            newUserData[key] = step._data.result.name + " " + step._data.result.surnames;
          } else {
            newUserData[key] = value;
          }
        })
      });

      delete newUserData["surnames"]

      newUserData["chatbot_token"] = this.$route.query.token;

      console.log('newUserData: ', newUserData)

      ProfileAnalysisDataService.updateToForm(newUserData).then(
        response => {
          if (response.status != 200) {
            this.$router.push({ name: "profile" });
          } else {
            this.$refs['finish-modal'].show();
          }
        },
        error => {
          this.$router.push({ name: "profile" });
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    redirect() {
      this.$router.replace({ name: "profile-analysis", params: {}, query: { id: this.idProfile } })
    },
  }
}
</script>

<style scoped>
.stepper-box .content[data-v-3ee86246] {
  margin: 5.5rem 0 !important;
}
</style>