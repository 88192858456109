<template>
    <div class="form-container">
        <h6>In disuse</h6>
	</div>
</template>

<script>
    import 'vue-range-component/dist/vue-range-slider.css'
    import VueRangeSlider from 'vue-range-component'
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

    export default {
        name: 'EditProfileConfigurationComponent',
        data() {
            return {
                prevMinTraineeRange: 0,
                prevMaxTraineeRange: 100,
                prevMinJuniorRange: 0,
                prevMaxJuniorRange: 100,
                prevMinMiddleRange: 0,
                prevMaxMiddleRange: 100,
                prevMinSeniorRange: 0,
                prevMaxSeniorRange: 100,
                minTraineeRange: 0,
                maxTraineeRange: 100,
                minJuniorRange: 0,
                maxJuniorRange: 100,
                minMiddleRange: 0,
                maxMiddleRange: 100,
                minSeniorRange: 0,
                maxSeniorRange: 100,
                technologyNameTitle: '',
                profileNameTitle: '',
                technology: null,
                programmingSkillsItem: null,
                programmingSkillsArray: [],
                prevProgrammingSkillsWeight: 0,
                programmingSkillsWeight: 0,
                educationItem: null,
                educationArray: [],
                prevEducationWeight: 0,
                educationWeight: 0,
                certificationsItem: null,
                certificationsArray: [],
                prevCertificationsWeight: 0,
                certificationsWeight: 0,
                minYearsExperienceTime: null,
                maxYearsExperienceTime: null,
                prevExperienceTimeWeight: 0,
                experienceTimeWeight: 0,
                toolsItem: null,
                toolsArray: [],
                otherSkillsItem: null,
                otherSkillsArray: [],
                prevOtherSkillsWeight: 0,
                otherSkillsWeight: 0,
                frameworksItem: null,
                frameworksArray: [],
                prevLastYears: 0,
                prevLastYearsWeight: 0,
                lastYears: 0,
                lastYearsWeight: 0,
                errorsMessages: [],
                updatedTechnology: null,
                traineeRange: [0, 100],
                juniorRange: [0, 100],
                middleRange: [0, 100],
                seniorRange: [0, 100],
                traineeChanged: false,
                juniorChanged: false,
                middleChanged: false,
                seniorChanged: false,
                totalWeight: 100
            }
        },
        watch: {
            traineeRange: function () {
                if(this.juniorChanged) {
                } else if(this.middleChanged) {
                } else if(this.seniorChanged) {
                } else {
                    var minTrainee = this.traineeRange[0]
                    var maxTrainee = this.traineeRange[1]
                    var minJunior = this.juniorRange[0]
                    var maxJunior = this.juniorRange[1]
                    var minMiddle = this.middleRange[0]
                    var maxMiddle = this.middleRange[1]
                    var minSenior = this.seniorRange[0]
                    var maxSenior = this.seniorRange[1]

                    minJunior = maxTrainee + 1

                    if(minJunior == maxJunior) {
                        maxJunior = maxJunior + 1
                        minMiddle = minMiddle + 1

                        if(minMiddle == maxMiddle) {
                            maxMiddle = maxMiddle + 1
                            minSenior = minSenior + 1
                        }
                    }

                    if(minJunior>100) minJunior = 100
                    if(maxJunior>100) maxJunior = 100
                    if(minMiddle>100) minMiddle = 100
                    if(maxMiddle>100) maxMiddle = 100
                    if(minSenior>100) minSenior = 100
                    if(maxSenior>100) maxSenior = 100

                    this.traineeChanged = true

                    this.juniorRange = [minJunior, maxJunior]
                    this.middleRange = [minMiddle, maxMiddle]
                    this.seniorRange = [minSenior, maxSenior]
                }
            },
            juniorRange: function () {
                if(this.traineeChanged) {
                    this.traineeChanged = false
                } else if(this.middleChanged) {
                    //this.middleChanged = false
                } else if(this.seniorChanged) {
                    //this.seniorChanged = false
                } else {
                    var minTrainee = this.traineeRange[0]
                    var maxTrainee = this.traineeRange[1]
                    var minJunior = this.juniorRange[0]
                    var maxJunior = this.juniorRange[1]
                    var minMiddle = this.middleRange[0]
                    var maxMiddle = this.middleRange[1]
                    var minSenior = this.seniorRange[0]
                    var maxSenior = this.seniorRange[1]

                    if(minJunior > 0) maxTrainee = minJunior -1
                    else maxTrainee = 0

                    minMiddle = maxJunior + 1

                    if(minMiddle == maxMiddle) {
                        maxMiddle = maxMiddle + 1
                        minSenior = minSenior + 1
                    }

                    if(minMiddle>100) minMiddle = 100
                    if(maxMiddle>100) maxMiddle = 100
                    if(minSenior>100) minSenior = 100
                    if(maxSenior>100) maxSenior = 100

                    this.juniorChanged = true

                    this.traineeRange = [minTrainee, maxTrainee]
                    this.middleRange = [minMiddle, maxMiddle]
                    this.seniorRange = [minSenior, maxSenior]
                }
            },
            middleRange: function () {
                if(this.traineeChanged) {
                } else if(this.juniorChanged) {
                } else if(this.seniorChanged) {
                } else {
                    var minTrainee = this.traineeRange[0]
                    var maxTrainee = this.traineeRange[1]
                    var minJunior = this.juniorRange[0]
                    var maxJunior = this.juniorRange[1]
                    var minMiddle = this.middleRange[0]
                    var maxMiddle = this.middleRange[1]
                    var minSenior = this.seniorRange[0]
                    var maxSenior = this.seniorRange[1]

                    maxJunior = minMiddle - 1

                    if(minJunior == maxJunior) {
                        minJunior = maxJunior - 1
                        maxTrainee = maxTrainee - 1
                    }

                    if(minJunior<0) minJunior = 0
                    if(maxJunior<0) maxJunior = 0
                    if(minTrainee<0) minTrainee = 0
                    if(maxTrainee<0) maxTrainee = 0

                    if(maxMiddle < 100) minSenior = maxMiddle + 1
                    else minSenior = 100

                    this.middleChanged = true

                    this.traineeRange = [minTrainee, maxTrainee]
                    this.juniorRange = [minJunior, maxJunior]
                    this.seniorRange = [minSenior, maxSenior]
                }
            },
            seniorRange: function () {
                if(this.traineeChanged) {
                    this.traineeChanged = false
                } else if(this.juniorChanged) {
                    this.juniorChanged = false
                } else if(this.middleChanged) {
                    this.middleChanged = false
                } else {
                    var minTrainee = this.traineeRange[0]
                    var maxTrainee = this.traineeRange[1]
                    var minJunior = this.juniorRange[0]
                    var maxJunior = this.juniorRange[1]
                    var minMiddle = this.middleRange[0]
                    var maxMiddle = this.middleRange[1]
                    var minSenior = this.seniorRange[0]
                    var maxSenior = this.seniorRange[1]

                    maxMiddle = minSenior - 1

                    if(maxMiddle == minMiddle) {
                        minMiddle = minMiddle - 1
                        maxJunior = maxJunior - 1

                        if(maxJunior == minJunior) {
                            minJunior = maxJunior - 1
                            maxTrainee = maxTrainee - 1
                        }
                    }

                    if(minJunior<0) minJunior = 0
                    if(maxJunior<0) maxJunior = 0
                    if(minMiddle<0) minMiddle = 0
                    if(maxMiddle<0) maxMiddle = 0
                    if(minTrainee<0) minTrainee = 0
                    if(maxTrainee<0) maxTrainee = 0

                    this.seniorChanged = true

                    this.traineeRange = [minTrainee, maxTrainee]
                    this.juniorRange = [minJunior, maxJunior]
                    this.middleRange = [minMiddle, maxMiddle]
                    
                    this.traineeChanged = false
                    this.juniorChanged = false
                    this.middleChanged = false
                    this.seniorChanged = false
                }
            },
            programmingSkillsWeight: function () {
                this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
            },
            certificationsWeight: function () {
                this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
            },
            educationWeight: function () {
                this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
            },
            experienceTimeWeight: function () {
                this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
            },
            otherSkillsWeight: function () {
                this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
            }
        },
        created() {
            this.min = 0
            this.max = 100
            this.enableCross = false
            this.formatter = value => `${value}%`
            this.tooltipMerge = false
            this.bgStyle = {
                backgroundColor: '#efefef'
            }
            this.tooltipStyle = {
                backgroundColor: '#0075ff',
                borderColor: '#0075ff',
                color: '#ffffff'
            }
            this.processStyle = {
                backgroundColor: '#0075ff'
            }
        },
        components: {
            VueRangeSlider
        },
        props: {
        },
        beforeMount() {  
        },
        mounted() {
            ProfileConfigurationDataService.getProfile(this.$route.query.idTechnology).then(
                response => {
                    this.technology = response.data
                    this.prevMaxTraineeRange = this.technology.profiles[0].profile_technology_configuration.range
                    this.prevMinJuniorRange = this.technology.profiles[0].profile_technology_configuration.range + 1
                    this.prevMaxJuniorRange = this.technology.profiles[1].profile_technology_configuration.range
                    this.prevMinMiddleRange = this.technology.profiles[1].profile_technology_configuration.range + 1
                    this.prevMaxMiddleRange = this.technology.profiles[2].profile_technology_configuration.range
                    this.prevMinSeniorRange = this.technology.profiles[2].profile_technology_configuration.range + 1

                    
                    this.traineeRange = [0,this.prevMaxTraineeRange]
                    this.juniorRange = [this.prevMinJuniorRange,this.prevMaxJuniorRange]
                    this.middleRange = [this.prevMinMiddleRange,this.prevMaxMiddleRange]
                    this.seniorRange = [this.prevMinSeniorRange,100]

                    this.technologyNameTitle = this.technology.name
                    this.programmingSkillsWeight = this.technology.profiles[0].profile_technology_configuration.programming_skills_weight
                    this.certificationsWeight = this.technology.profiles[0].profile_technology_configuration.certifications_weight
                    this.educationWeight = this.technology.profiles[0].profile_technology_configuration.education_weight
                    this.experienceTimeWeight = this.technology.profiles[0].profile_technology_configuration.experience_time_weight
                    this.otherSkillsWeight = this.technology.profiles[0].profile_technology_configuration.other_skills_weight
                    this.lastYears = this.technology.profiles[0].profile_technology_configuration.last_years_limit
                    this.lastYearsWeight = this.technology.profiles[0].profile_technology_configuration.last_years_weight

                    this.prevProgrammingSkillsWeight = this.technology.profiles[0].profile_technology_configuration.programming_skills_weight
                    this.prevCertificationsWeight = this.technology.profiles[0].profile_technology_configuration.certifications_weight
                    this.prevEducationWeight = this.technology.profiles[0].profile_technology_configuration.education_weight
                    this.prevExperienceTimeWeight = this.technology.profiles[0].profile_technology_configuration.experience_time_weight
                    this.prevOtherSkillsWeight = this.technology.profiles[0].profile_technology_configuration.other_skills_weight
                    this.prevLastYears = this.technology.profiles[0].profile_technology_configuration.last_years_limit
                    this.prevLastYearsWeight = this.technology.profiles[0].profile_technology_configuration.last_years_weight

                    this.totalWeight = this.programmingSkillsWeight + this.certificationsWeight + this.educationWeight + this.experienceTimeWeight + this.otherSkillsWeight
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            )
        },
        methods: {
            showCancelModal() {
                if(this.prevMaxTraineeRange != this.maxTraineeRange || this.prevMaxJuniorRange != this.maxJuniorRange ||
                    this.prevMaxMiddleRange != this.maxMiddleRange || this.prevMaxSeniorRange != this.maxSeniorRange ||
                    this.prevProgrammingSkillsWeight != this.programmingSkillsWeight || this.prevCertificationsWeight != this.certificationsWeight ||
                    this.prevEducationWeight != this.educationWeight || this.prevExperienceTimeWeight != this.experienceTimeWeight ||
                    this.prevOtherSkillsWeight != this.otherSkillsWeight || this.prevLastYears != this.lastYears ||
                    this.prevLastYearsWeight != this.lastYearsWeight) {
                    this.$refs['cancel-modal'].show();
                } else {
                    this.backToConfiguration()
                }
            },
            backToConfiguration() {
                this.$router.push({ path: 'config-profile'})
            },
            handleEdit() {
                var yearsNumber = parseInt(this.lastYears)
                var yearsString = this.lastYears.toString()

                this.errorsMessages = []

                if(this.traineeRange[0] != 0) {
                    this.errorsMessages.push("The lower limit for the TRAINEE profile must be 0%")
                }
                
                if (this.traineeRange[1] != (this.juniorRange[0] -1)) {
                    this.errorsMessages.push("The upper limit of the TRAINEE profile must be immediately before the lower limit of the JUNIOR profile. For example: 19% - 20%")
                }
                
                if (this.juniorRange[1] != (this.middleRange[0] -1)) {
                    this.errorsMessages.push("The upper limit of the JUNIOR profile must be immediately before the lower limit of the MIDDLE profile. For example: 49% - 50%")
                }
                
                if (this.middleRange[1] != (this.seniorRange[0] -1)) {
                    this.errorsMessages.push("The upper limit of the MIDDLE profile must be immediately before the lower limit of the SENIOR profile. For example: 69% - 70%")
                }
                
                if(this.seniorRange[1] != 100) {
                    this.errorsMessages.push("The upper limit for the SENIOR profile must be 100%")
                }
                
                if((parseInt(this.programmingSkillsWeight) + parseInt(this.certificationsWeight) + parseInt(this.educationWeight) + parseInt(this.experienceTimeWeight) + parseInt(this.otherSkillsWeight)) != 100) {
                    var sum = parseInt(this.programmingSkillsWeight) + parseInt(this.certificationsWeight) + parseInt(this.educationWeight) + parseInt(this.experienceTimeWeight) + parseInt(this.otherSkillsWeight)
                    this.errorsMessages.push("The sum of the weights of the hard skills must be 100%. Now is: " + sum + "%")
                }
                
                if(yearsNumber < 0 || yearsString == '') {
                    this.errorsMessages.push("Number of last years is required and must be positive")
                }
                
                if(this.errorsMessages.length == 0) {
                    this.updatedTechnology = this.technology
                    this.updatedTechnology.profiles[0].profile_technology_configuration.range = parseFloat(this.traineeRange[1])
                    this.updatedTechnology.profiles[1].profile_technology_configuration.range = parseFloat(this.juniorRange[1])
                    this.updatedTechnology.profiles[2].profile_technology_configuration.range = parseFloat(this.middleRange[1])
                    this.updatedTechnology.profiles[3].profile_technology_configuration.range = parseFloat(this.seniorRange[1])

                    this.updatedTechnology.profiles[0].profile_technology_configuration.programming_skills_weight = parseFloat(this.programmingSkillsWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.programming_skills_weight = parseFloat(this.programmingSkillsWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.programming_skills_weight = parseFloat(this.programmingSkillsWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.programming_skills_weight = parseFloat(this.programmingSkillsWeight)

                    this.updatedTechnology.profiles[0].profile_technology_configuration.certifications_weight = parseFloat(this.certificationsWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.certifications_weight = parseFloat(this.certificationsWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.certifications_weight = parseFloat(this.certificationsWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.certifications_weight = parseFloat(this.certificationsWeight)

                    this.updatedTechnology.profiles[0].profile_technology_configuration.education_weight = parseFloat(this.educationWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.education_weight = parseFloat(this.educationWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.education_weight = parseFloat(this.educationWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.education_weight = parseFloat(this.educationWeight)

                    this.updatedTechnology.profiles[0].profile_technology_configuration.experience_time_weight = parseFloat(this.experienceTimeWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.experience_time_weight = parseFloat(this.experienceTimeWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.experience_time_weight = parseFloat(this.experienceTimeWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.experience_time_weight = parseFloat(this.experienceTimeWeight)

                    this.updatedTechnology.profiles[0].profile_technology_configuration.other_skills_weight = parseFloat(this.otherSkillsWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.other_skills_weight = parseFloat(this.otherSkillsWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.other_skills_weight = parseFloat(this.otherSkillsWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.other_skills_weight = parseFloat(this.otherSkillsWeight)

                    this.updatedTechnology.profiles[0].profile_technology_configuration.last_years_weight = parseFloat(this.lastYearsWeight)
                    this.updatedTechnology.profiles[1].profile_technology_configuration.last_years_weight = parseFloat(this.lastYearsWeight)
                    this.updatedTechnology.profiles[2].profile_technology_configuration.last_years_weight = parseFloat(this.lastYearsWeight)
                    this.updatedTechnology.profiles[3].profile_technology_configuration.last_years_weight = parseFloat(this.lastYearsWeight)
                    
                    this.updatedTechnology.profiles[0].profile_technology_configuration.last_years_limit = parseFloat(this.lastYears)
                    this.updatedTechnology.profiles[0].profile_technology_configuration.last_years_limit = parseFloat(this.lastYears)
                    this.updatedTechnology.profiles[0].profile_technology_configuration.last_years_limit = parseFloat(this.lastYears)
                    this.updatedTechnology.profiles[0].profile_technology_configuration.last_years_limit = parseFloat(this.lastYears)

                    ProfileConfigurationDataService.updateTechnology(this.updatedTechnology).then(
                        response => {
                            if (response.status == 200) {
                                this.$router.push({ path: 'config-profile'})
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            }
        },
        emits: ["delete-education", "delete-frameworks", "programming-skills-delete", "other-skills-weight", "tools-weight"]
    }
</script>

<style scoped>
.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.slider {
    background-color: white !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px white !important;
    width: 90% !important;
    padding: 20px 10px 30px 10px !important;
}

.range-row {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle-top {
    margin-top: 0px;
}

.subtitle {
    margin-top: 25px;
}

.weightSlider {
    width: 100% !important;
}

.skillDiv {
    width: 12% !important;
}

.skillH6 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.sliderDiv {
    width: 65% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.valueDiv {
    width: 5% !important;
}

.valueP {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.finalHardSkills {
    margin-bottom: 1rem !important;
}

.finalLastYears {
    margin-bottom: 1rem !important;
}

.lastYearsInput {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 60px;
}

.inputProfileConfig {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 100%;
}

.divItems {
    text-align: left;
    margin-left: 30px;
    margin-right: 100px;
}

.alert-edit {
    padding: 5px !important;
    width: 100% !important;
}

.noDisplay {
    display: none !important;
}

.rangeDiv {
    margin-top: 30px;
}

.lastYearsDiv {
    margin-top: 40px;
}

.range-slider {
    margin-left: 85px;
}

.rangeH6 {
    margin-left: 30px;
}

.number-input {
    margin-left: 94px;
}

.numberDiv {
    position: relative;
}

.numberText {
    position: absolute;
    bottom: 0;
    margin-bottom: 0rem !important;
}

.valid-value-class {
    color: black;
}

.invalid-value-class {
    color: red;
}

.title-total-weight {
    float: right;
}

.text-total-weight {
    float: left;
}
</style>