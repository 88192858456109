<template>
	<div class="row">
		<div class="col-sm-12">
			<div class="analysis-profile-container">
				<div class="row">
					<div class="col-sm-3">
						<div class="card-image">
							<img src="http://00dev00.progradum.com/images/icons/user.png" class="profile-img" />
						</div>
					</div>
					<div class="col-sm-6">
						<div class="row">
							<h5 style="text-align: left;">Maria Díaz Lorenzo</h5>
						</div>
						<div class="row">
							<div class="col-sm-6 pt-0 pb-0">
								<p style="text-align: left; font-size: 16px;"><font-awesome-icon
										icon="user" />&nbsp;&nbsp;Mujer</p>
							</div>
							<div class="col-sm-6 pt-0 pb-0">
								<p style="text-align: left; font-size: 16px;"><font-awesome-icon
										icon="calendar" />&nbsp;&nbsp;21-25</p>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6 pt-0">
								<p style="text-align: left; font-size: 16px;"><font-awesome-icon
										icon="globe" />&nbsp;&nbsp;Salamanca</p>
							</div>
							<div class="col-sm-6 pt-0">
								<p style="text-align: left; font-size: 16px;"><font-awesome-icon
										icon="graduation-cap" />&nbsp;&nbsp;Grado en Ingeniería Informática</p>
							</div>
						</div>
					</div>
					<div class="col-sm-3">
						<vue-ellipse-progress class="ellipse-progress-general" :progress="66" fontSize="3rem"
							:size="125" :color="color" :thickness="8" :fontColor="color" :legend="true"
							:legendValue="66">
							<span slot="legend-value">%</span>
						</vue-ellipse-progress>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12">
			<div class="analysis-profile-container pb-2">
				<div class="row">
					<div class="col-sm-12" style="margin-bottom: 1% !important;">
						<div class="form-group row">
							<h5 style="text-align: left; margin-bottom: 2%;">SKILLS</h5>
							<div class="col-sm-3" v-for="(skill, index) in softskills" :key="index"
								style="padding-left: 1%; padding-right: 1%; margin-bottom: 2%;">
								<div class="row">
									<div class="col-sm-7">
										<h6 class="rangeH6" style="margin-top: 10%;">{{ (index + 1) }}. {{ skill.name }}
										</h6>
									</div>
									<div class="col-sm-5 range-slider">
										<vue-ellipse-progress v-if="skill.type == 'personal_skills'"
											class="ellipse-progress-general" :progress="skill.progress" fontSize="1rem"
											:size="70" :color="color2" :thickness="4" :fontColor="color2" :legend="true"
											:legendValue="skill.progress">
											<span slot="legend-value">%</span>
										</vue-ellipse-progress>
										<vue-ellipse-progress v-else-if="skill.type == 'social_skills'"
											class="ellipse-progress-general" :progress="skill.progress" fontSize="1rem"
											:size="70" :color="color3" :thickness="4" :fontColor="color3" :legend="true"
											:legendValue="skill.progress">
											<span slot="legend-value">%</span>
										</vue-ellipse-progress>
										<vue-ellipse-progress v-else-if="skill.type == 'formal_skills'"
											class="ellipse-progress-general" :progress="skill.progress" fontSize="1rem"
											:size="70" :color="color4" :thickness="4" :fontColor="color4" :legend="true"
											:legendValue="skill.progress">
											<span slot="legend-value">%</span>
										</vue-ellipse-progress>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12">
			<div class="row competences-personality-container">
				<div class="col-sm-6 competences-container pb-2">
					<h5 style="text-align: left; margin-bottom: 2%;">COMPETENCES</h5>
					<div class="row" v-for="(competence, index) in competences" :key="index"
						style="padding-left: 1%; padding-right: 1%">
						<div class="col-sm-6">
							<h6 class="rangeH6">{{ competence.name }}</h6>
						</div>
						<div class="col-sm-6 range-slider">
							<star-rating :star-size="20" :read-only="true" :max-rating="5" :show-rating="false"
								:rating="competence.rate"></star-rating>
						</div>
					</div>
				</div>
				<div class="col-sm-6 personality-container pb-2">
					<h5 style="text-align: left; margin-bottom: 2%;">PERSONALITY SUMMARY</h5>
					<h3 style="margin-left: 3%;">Auténtico</h3>
					<h3 style="margin-left: 3%;">Formal - Introvertido</h3>
				</div>
			</div>
			<div class="analysis-profile-container pb-2">
				<div class="row">
					<h5 style="text-align: left; margin-bottom: 2%; margin-left: 1%;">AVERAGE SKILLS</h5>
					<div id="chartdiv" style="height: 900px !important;"></div>
				</div>
			</div>
			<div class="analysis-profile-container pb-2">
				<div class="row">
					<h5 style="text-align: left; margin-left: 1%;">COMMENTS</h5>
					<b-form-textarea style="margin-left: 1%; margin-right: 1%; width: 98%;"
						placeholder="Write your comments here"></b-form-textarea>
				</div>
			</div>
			<div class="row">
				<button class="btn btn-secondary" type="button"> SAVE </button>
			</div>
		</div>
	</div>
</template>

<script>
import S4DataService from "@/services/S4DataService";
import StarRating from 'vue-star-rating'

export default {
	name: "UserS4Component",
	data() {
		return {
			color: "#62c4e4",
			color2: "purple",
			color3: "green",
			color4: "blue",
			softskills: {},
			competences: [{ name: 'Empatía', rate: 3 }, { name: 'Asertividad', rate: 3 },
			{ name: 'Toma de decisiones', rate: 4 }, { name: 'Resistencia al estrés', rate: 3 },
			{ name: 'Flexibilidad', rate: 4 }, { name: 'Persuasión', rate: 3 }]
		}
	},
	components: {
		StarRating
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		async fetchData() {
			await S4DataService.findSkills().then(
				response => {
					this.softskills = response.data

					this.softskills.forEach(softskill => {
						softskill.progress = Math.floor(Math.random() * 100) + 1;
					});

					let softskillsTmp = this.softskills
					am4core.ready(function () {
						am4core.useTheme(am4themes_animated);
						var chart = am4core.create("chartdiv", am4charts.XYChart);
						chart.hiddenState.properties.opacity = 0;

						chart.data = []
						softskillsTmp.forEach((softskill, index) => {
							let tmpObj = {};
							tmpObj.skill = index + 1;
							tmpObj.required = (Math.floor(Math.random() * 100) + 1);
							tmpObj.name = softskill.name;
							tmpObj.value = softskill.progress;
							tmpObj.mean = Math.floor(Math.random() * 100) + 1;
							chart.data.push(tmpObj);
						});

						let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
						categoryAxis.renderer.grid.template.location = 0;
						categoryAxis.dataFields.category = "name";
						categoryAxis.fontSize = 11;
						categoryAxis.renderer.grid.template.location = 0;
						categoryAxis.renderer.minGridDistance = 0;
						categoryAxis.renderer.inversed = true,
							categoryAxis.renderer.cellStartLocation = 0.1;
						categoryAxis.renderer.cellEndLocation = 0.9;

						chart.xAxes.push(new am4charts.ValueAxis());

						let series1 = chart.series.push(new am4charts.ColumnSeries());
						series1.dataFields.categoryY = "name";
						series1.dataFields.valueX = "value";
						series1.columns.template.tooltipText = "Value: {valueX.value}";
						series1.columns.template.tooltipY = 0;
						series1.columns.template.strokeOpacity = 0;
						series1.columns.template.fill = "#008800";

						series1.columns.template.adapter.add("fill", function (fill, target) {
							let category = target.dataItem.categories.categoryY;
							category = category.substr(category.length - 3);
							category = category.slice(0, -1);

							if (target.dataItem && (target.dataItem.valueX > target.dataItem._dataContext.required)) {
								return "#008800";
							}
							else {
								return "#FF0000";
							}
						});

						let series2 = chart.series.push(new am4charts.ColumnSeries());
						series2.dataFields.categoryY = "name";
						series2.dataFields.valueX = "required";
						series2.columns.template.tooltipText = "Required: {valueX.value}";
						series2.columns.template.tooltipY = 0;
						series2.columns.template.strokeOpacity = 0;
						series2.columns.template.fill = "#62c4e4";

						let series = chart.series.push(new am4charts.ColumnSeries());
						series.dataFields.categoryY = "name";
						series.dataFields.valueX = "mean";
						series.columns.template.tooltipText = "Mean: {valueX.value}";
						series.columns.template.tooltipY = 0;
						series.columns.template.strokeOpacity = 0;
						series.columns.template.fill = "#13283b";
					});
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = error.response.data.message

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			)
		},
	}
}
</script>

<style>
.profile-img {
	margin: 5%;
	margin-top: 14%;
	height: 6em;
	border-radius: 50%;
}

.ellipse-progress-general {
	margin-top: 10%;
}

#chartdiv {
	width: 100%;
	height: 500px;
}
</style>