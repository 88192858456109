<template>
	<div class="table-container technologiesStyle">
		<div class="row mt-3">
			<router-link v-b-tooltip.hover title="Go to ranking for soft skills evaluated candidates"
				:to="{ path: '/soft-skills-ranking' }"
				class="btn btn-primary ranking-btn white-color align-item-center"><font-awesome-icon class="white-color"
					icon="trophy" /> Soft Skills Ranking</router-link>
		</div>

		<template v-if="s4Processes.length > 0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="s4ProcessesList"></b-pagination>
				</div>
				<div class="col-sm-7">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
						rows</button>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button"
						type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash"
							class="white-color" /> Delete {{ selectedItems.length }} rows</button>
				</div>
				<div class="col-sm-3">
					<b-form-input id="filter-input" v-model="filter" type="search"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>

			<b-table ref="s4ProcessesTable" id="s4ProcessesList" :per-page="perPage" :current-page="currentPage"
				:fields="headers" :items="s4Processes" :filter="filter" select-mode="multi" responsive="sm" selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(name)="data">
					{{ data.item.name }}
				</template>
				<template #cell(created_at)="data">
					{{ data.item.created_at | formatEnglishDate }}
				</template>
				<template #cell(last_edited)="data">
					{{ data.item.last_edited | formatEnglishDate }}
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Statistics"
						:to="{ path: '/softskills-statistics?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
					<router-link v-b-tooltip.hover title="Edit"
						:to="{ path: '/softskills-update-process?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.
			</h3>
		</template>
		<div id="messageDelete" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete soft skills process
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} soft skills
					processes?
				</p>
				<p v-else> Do you really want to delete this soft skills process? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteS4Process()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import S4DataService from '@/services/S4DataService';

export default {
	name: 'S4ProcessesListComponent',
	data() {
		return {
			headers: [
				{ key: 'name', sortable: true, label: 'Name' }, { key: 'created_at', sortable: true, label: 'Created At' }, { key: 'last_edited', sortable: true, label: 'Updated At' }, { key: 'options', label: 'Options' },
			],
			message: "",
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			selectedItems: [],
			selectedS4Process: null,
			selectedS4ProcessIndex: null
		}
	},
	props: {
		s4Processes: Array
	},
	computed: {
		rows() {
			return this.s4Processes.length
		}
	},
	mounted() {
		if (this.$store.state.auth.user.table_records_per_page != undefined)
			this.perPage = this.$store.state.auth.user.table_records_per_page
	},
	methods: {
		onRowSelected(items) {
			this.selectedItems = items;
		},
		selectAllRows() {
			this.$refs["s4ProcessesTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["s4ProcessesTable"].clearSelected();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		deleteS4Process() {
			let selectedRows = this.selectedItems.map(element => element.id);

			S4DataService.delete(selectedRows).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true

						this.s4Processes = this.s4Processes.filter(function (value, index) {
							return selectedRows.indexOf(value.id) == -1;
						});
					} else {
						this.successfull = false
					}

					this.$refs['delete-modal'].hide();
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			document.getElementById("messageDelete").style.display = "none"
		}
	}
};
</script>