<template>
  <div style="margin: 10% !important;">
    <div class="col-sm-12" style="margin-bottom: 1% !important;">
      <div class="form-group">
        <h5 style="margin-bottom: 2% !important;">Personal skills</h5>
        <div v-for="(skill, index) in softskillsByType.personal_skills" :key="index" class="row">
          <div class="col-sm-5">
            <h6 class="rangeH6">{{skill.name}}</h6>
          </div>
          <div class="col-sm-7 range-slider">
            <form name="registrationForm">
              <input type="range" name="inputName" id="inputId" style="width: 90% !important;" :value="skill.value" :min="min" :max="max" oninput="outputId.value = inputId.value + '%'">
              <output name="outputName" id="outputId" style="margin-left: 5px !important;">{{skill.value}}%</output>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12" style="margin-bottom: 1% !important;">
      <div class="form-group">
        <h5 style="margin-bottom: 2% !important;">Social skills</h5>
        <div v-for="(skill, index) in softskillsByType.social_skills" :key="index" class="row">
          <div class="col-sm-5">
            <h6 class="rangeH6">{{skill.name}}</h6>
          </div>
          <div class="col-sm-7 range-slider">
            <form name="registrationForm">
              <input type="range" name="inputName" id="inputId" style="width: 90% !important;" :value="skill.value" :min="min" :max="max" oninput="outputId.value = inputId.value + '%'">
              <output name="outputName" id="outputId" style="margin-left: 5px !important;">{{skill.value}}%</output>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12" style="margin-bottom: 1% !important;">
      <div class="form-group">
        <h5 style="margin-bottom: 2% !important;">Formal skills</h5>
        <div v-for="(skill, index) in softskillsByType.formal_skills" :key="index" class="row">
          <div class="col-sm-5">
            <h6 class="rangeH6">{{skill.name}}</h6>
          </div>
          <div class="col-sm-7 range-slider">
            <form name="registrationForm">
              <input type="range" name="inputName" id="inputId" style="width: 90% !important;" :value="skill.value" :min="min" :max="max" oninput="outputId.value = inputId.value + '%'">
              <output name="outputName" id="outputId" style="margin-left: 5px !important;">{{skill.value}}%</output>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import S4DataService from "@/services/S4DataService";

  export default { 
    name: 'PersonalDataStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return {
        value: 0,
        min: 0,
        max: 100,
        enableCross: false,
        tooltipMerge: false,
        bgStyle: { backgroundColor: '#efefef' },
        tooltipStyle: {
          backgroundColor: '#0075ff',
          borderColor: '#0075ff',
          color: '#ffffff'
        },
        processStyle: {
          backgroundColor: '#0075ff'
        },
        result: {},
        softskills: [],
        softskillsByType: {personal_skills: [], social_skills: [], formal_skills: []},
        optionsPersonalSkills: {},
        optionsSocialSkills: {},
        tmp: {},
        isSubmitted: false
      } 
    },
    async mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await S4DataService.findSkills().then(
          async response => {
            this.softskills = response.data

            await S4DataService.getDataProcessById(parseInt(this.$route.query.id)).then(
              response => {
                if (response.status == 200) {
                  let skillSettings = response.data.skill_settings
                  this.successfull = true;
                  
                  this.softskills.forEach(softskill => {
                    skillSettings.forEach(skillSetting => {
                      if (skillSetting.skill.name === softskill.name) softskill.value = skillSetting.value
                    })
                  });

                  this.softskills.forEach(softskill => {
                    if (softskill.type === 'personal_skills') this.softskillsByType.personal_skills.push(softskill)
                    else if (softskill.type === 'social_skills') this.softskillsByType.social_skills.push(softskill)
                    else this.softskillsByType.formal_skills.push(softskill)
                  });
                }
              },
              error => {
                console.log((error.response && error.response.data) ||
                  error.message ||
                  error.toString())
                this.message = error.response.data.message

                if (error.response.status === 300) {
                  console.log('Logout: Token expired')
                  localStorage.removeItem('user');
                  localStorage.removeItem('company');
                  this.$store.state.auth.user = null
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/login');
                }
              }
            );
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())
            this.message = error.response.data.message

            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );

        this.$emit('can-continue', {value: true});
      }
    }
  }
</script>