<template>
	<div class="form-container technologiesStyle mt-2">        
		<div class="col-sm-12">
			<div class="form-group">
				<label>Name *</label>
				<input class="form-input" type="text" id="name" v-validate="'required'" required v-model="area.name" data-vv-name="name">
				<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					{{veeErrors.first('name')}}
				</div>
			</div>
		</div>
		<div class="col-sm-12">
			<div class="form-group">
				<label>Description</label>
				<tiptap v-model="area.description"/>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>

		<div class="form-group row justify-content-center">
			<div class="col-sm-1">
				<input class="btn btn-primary form-submit" type="button" @click="handleUpdate()" value="Update">
			</div>
			<div class="col-sm-1">
				<button class="btn btn-secondary btn-cancel" type="button" @click="backToAreasList()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>
	</div>
</template>

<script>
    import AreasDataService from '@/services/AreasDataService';
	import Tiptap from '@/components/Others/Tiptap'

	export default {
		name: 'UpdateAreaComponent',
		data() {
	      	return {
				areas: [],
                area: {name: '', description: '', id_company: this.$store.state.auth.user.id_company},
	            message: "",
                successfull: false,
	      	    submitted: false
	      	}
	    },
		components: {
		    Tiptap
		},
		props: {
		},
	    computed: {
	    },
        mounted() {
            AreasDataService.findOne(this.$route.query.id).then(
                response => {
                    this.area = response.data
                },
                error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
                }
            );

			AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {					
                    this.areas = response.data
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
                }
		    );
        },
	  	methods: {
            backToAreasList() {
                this.$router.replace({ name: "admin-configuration-areas", params: {areaAdded: false, areaUpdated: false} });
            },
			handleUpdate() {
                this.submitted = true

                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.area.description = this.area.description.replace("<p>", "").replace("</p>", "")
                        this.area.description = this.area.description.trim()

                        this.area.name = this.area.name.replace("<p>", "").replace("</p>", "")
                        this.area.name = this.area.name.trim()

                        if (this.area.name.length != 0) {
							let repeatedName = false
							for (let i = 0; i < this.areas.length; i++) {
								if (this.areas[i].name.trim().toLowerCase() === this.area.name.trim().toLowerCase()) {
									repeatedName = true
									break;
								}
							}
							
							if (!repeatedName) {
								AreasDataService.update(this.area).then(
									response => {
										this.$router.push({ name: "admin-configuration-areas", params: {areaAdded: false, areaUpdated: true} });
									},
									error => {
										console.log((error.response && error.response.data) ||
											error.message ||
											error.toString())
										
										if (error.response.status === 300) {
											console.log('Logout: Token expired')
											localStorage.removeItem('user');
											localStorage.removeItem('company');
											this.$store.state.auth.user = null
											this.$store.dispatch('auth/logout');
											this.$router.push('/login');
										}
									}
								);
							} else {
								this.message = "Area name is already in use. Please, change it."
							}
                        } else {
                            this.message = "Please, fill the name of the area"
                        }
                    }
                });
	      	},
			close() {
				document.getElementById("message").style.display = "none"
			}
	    }
	};
</script>