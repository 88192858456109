<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link v-if="isAdmin" :to="{path: '/admin-configuration-users'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Users Configuration</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/admin-configuration' }" class="breadcumb"> Configuration > </router-link>
				<router-link :to="{ path: '/admin-configuration-users' }" class="breadcumb"> Users > </router-link>
				<router-link :to="{ path: '/admin-configuration-users-update-user' }" class="final-breadcumb"> Update User </router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered">
			<update-user :id_user="id_user"/>
		</div>
	</div>
</template>

<script>
  	import UpdateUser from "@/components/Users/Update.vue";

	export default {
	  	name: 'UpdateUserView',
	  	data () {
			return {
				user: {}
			}
	  	},
	  	components: {
	      	UpdateUser
	    },
	    computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			}
	    },
	    methods: {
	    },
		props: {
		    id_user: Number
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>