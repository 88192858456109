<template>
	<div class="full-container-login">
		<div class="home-header">
			<div class="row header-row">
			    <appHomeHeader></appHomeHeader>
			    <div id="login-header-image" class="col-lg-6 col-md-12">
					<img class="main form-image-logo" src="images/icons/arbol_azul.png">
					<div class="login" style="min-height: 333px !important;">
						<h1 class="form-title">{{title}}</h1>
						<form action class="form" @submit.prevent="handleForm">
							<div class="form-group">
								<input class="form-input" :type="visibility" id="password" v-validate="'required|min:8|max:40'" placeholder="New password" v-model="resetPassword.password" data-vv-name="password" ref="password">
								
								<a @click="showPassword()" style="position: absolute; right: 26px; top: 3px;" class="notunderlined" v-if="visibility == 'password'">
									<font-awesome-icon icon="eye" />
								</a>

								<a @click="hidePassword()" style="position: absolute; right: 26px; top: 3px;" class="notunderlined" v-if="visibility == 'text'">
									<font-awesome-icon icon="eye-slash" />
								</a>

								<div v-if="veeErrors.has('password')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									New password is required and must be between 8 and 40 characters long!
								</div>
							</div>
							<div class="form-group" style="padding-bottom: 0px !important;">
								<input class="form-input" v-validate="'required|confirmed:password'" :type="visibility" id="password-repeat" placeholder="Repeat password" data-vv-name="password-repeat" v-model="resetPassword.repeat_password" data-vv-as="password">
								<div v-if="veeErrors.has('password-repeat')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									Both passwords must match!
								</div>
							</div>
							<input class="btn btn-primary form-submit" type="submit" value="Reset">
							<div class="form-group">
								<div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<appHomeBody></appHomeBody>
    	<appFooter></appFooter>
	</div>
</template>

<script>
	import ResetPassword from '@/models/reset_password';
	import UserDataService from '@/services/UserDataService';
	import Footer from "@/components/Footer.vue";
	import HomeHeader from "@/components/HomeHeader.vue";
	import HomeBody from "@/components/HomeBody.vue";

	export default {
		name: 'ResetPassword',
		data() {
			return {
				resetPassword: new ResetPassword('',''),
				loading: false,
				message: '',
				title: "RESET PASSWORD",
				visibility: 'password'
			};
		},
		components: {
			appFooter: Footer,
			appHomeBody: HomeBody,
			appHomeHeader: HomeHeader
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.status.loggedIn;
			}
		},
		created() {
			const recaptcha = this.$recaptchaInstance

			if (recaptcha)
				recaptcha.showBadge()

			if (this.loggedIn) {
				if (this.$store.state.auth.user.role.name === 'user') {
					this.$router.push('/user');
				} else if (this.$store.state.auth.user.role.name === 'admin') {
					this.$router.push('/admin');
				} else if (this.$store.state.auth.user.role.name === 'examiner') {
					this.$router.push('/examiner');
				} else if (this.$store.state.auth.user.role.name === 'validator') {
					this.$router.push('/questions-validator');
				} else if (this.$store.state.auth.user.role.name === 'certifier') {
					this.$router.push('/certifications-validator');
				} else if (this.$store.state.auth.user.role.name === 'superadmin') {
					this.$router.push('/superadmin');
				}
			}
		},
		mounted() {
		    UserDataService.findByPasswordToken(this.$route.query.token).then(
			    response => {
			        if (response.status != 200) {
			        	this.$router.push('/login');
			        }
			    },
			    error => {
		          	console.log((error.response && error.response.data) ||
			          error.message ||
			          error.toString())
		          	this.$router.push('/login');
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
			    }
		    );
		},
		methods: {
			handleForm() {
				this.loading = true;
				this.$validator.validateAll().then(isValid => {
					if (!isValid) {
						this.loading = false;
						return;
					} else {
						UserDataService.resetPassword(this.resetPassword, this.$route.query.token).then(
							response => {
								if (response.status == 200) {
									this.$router.push({ name: "login", params: {resetPassword: true}});
								} else {
									this.message = response.data.message
								}
							},
							error => {
								console.log((error.response && error.response.data) ||
								error.message ||
								error.toString())
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
						);
					}
				});
			},
			showPassword() {
				this.visibility = 'text';
			},
			hidePassword() {
				this.visibility = 'password';
			}
		}
	};
</script>