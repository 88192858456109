<template>
	<div v-if="data.type != null" class="profile-container" ref="profile-container-id">
		<div v-if="!pdf">
			<h2 v-if="nextLevel != ''" class="tech-analysis-title">{{technology.toUpperCase()}} {{ type.toUpperCase() }} (Next Level of Expertise: {{nextLevel}})</h2>
			<h2 v-else class="tech-analysis-title">{{technology.toUpperCase()}} {{ type.toUpperCase() }} </h2>
			<h5 v-if="experienceProfile != undefined" class="tech-analysis-title">{{experienceProfile.years}} years and {{experienceProfile.months}} months of experience identified in this profile</h5>
			<h5 v-else class="tech-analysis-title">Time of experience could not be extracted for this profile</h5>
			<div class="row">
				<div class="col-sm-6">
					<div class="col-sm-12">
						<h4>Summary</h4>
						<div id="chart-container" class="col-sm-12 profile-progress-container">
							<radar-chart :data="radarChartData" v-if="graphLoaded" :key="graphLoaded" :options="radarChartOptions"/>
						</div>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="row">
						<h4>Hard Skills review (accomplished data)</h4>
						<p class="alert alert-primary">* Green tags display those accomplished requirements, while red ones show not detected ones. </p>
						<div class="col-sm-12 level-name">
							<b-card no-body>
								<b-tabs pills card>
									<div v-for="(technologyProfileDataLevel, levelName, indexTech) in technologyLevels" v-bind:key="levelName">
										<b-tab :class="levelName + '_tab'" :active="compareNames(levelName.toLowerCase(), type.toLowerCase())">
											<template v-if="compareNames(levelName.toLowerCase(), type.toLowerCase())" #title>
												<font-awesome-icon icon="star" /> <strong>{{levelName.charAt(0).toUpperCase() + levelName.slice(1)}}</strong>
											</template>
											<template v-else #title>
												<strong>{{levelName.charAt(0).toUpperCase() + levelName.slice(1)}}</strong>
											</template>
											<template v-for="(reqs_skills, level, index) in technologyProfileDataTmp">
												<div v-if="level.toLowerCase() == levelName.toLowerCase()" class="row" v-for="(reqs, skillName, index2) in reqs_skills">
													<h6>{{skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", " ").slice(1)}}</h6>
													<p>
														<span v-for="item in reqs" style="float: left;">
															<template v-if="skillName in userSkills">
																<div v-if="userSkills[skillName].filter(s => s.toLowerCase() == item.item.toLowerCase()).length > 0" class="alert alert-success" :class="item.is_required? 'alert alert-success bold-req':'alert alert-success' " >{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
																<div v-else class="alert alert-danger" :class="item.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
															</template>
															<template v-else>
																<div class="alert alert-danger" :class="item.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
															</template>
														</span>
													</p>
												</div>
											</template>
											
											<div class="row">
												<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
													<h6 v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">Years of experience (max. requested)</h6>
													<h6 v-else>Years of experience (min. required)</h6>
												</template>
												<template v-else>
													<h6>Years of experience (min. required)</h6>
												</template>
												<p>
													<span style="float: left;">
														<template v-if="experienceProfile != undefined">
															<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
																<template v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">
																	<template v-if="technologyProfileDataLevel.experience_time_max_years.is_required">
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																</template>
																<template v-else>
																	<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																</template>
															</template>
															<template v-else>
																<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																	<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
																<template v-else>
																	<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
															</template>
														</template>
														<template v-else>
															<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
																<template v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">
																	<template v-if="technologyProfileDataLevel.experience_time_max_years.is_required">
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																</template>
																<template v-else>
																	<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																</template>
															</template>
															<template v-else>
																<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																	<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
																<template v-else>
																	<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
															</template>
														</template>
													</span>
												</p>
											</div>
											<div class="row">
												<h6>Certifications</h6>
												<p>
													<template v-if="userCertifications != undefined && userCertifications.length > 0">
														<span v-for="certification in technologyProfileDataLevel.certifications" v-bind:key="certification.id" style="float: left;">
															<div v-if="userCertifications.filter(cert => cert.toLowerCase() == certification.name.toLowerCase()).length > 0" class="alert alert-success" :class="certification.is_required? 'alert alert-success bold-req':'alert alert-success' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}} <br></div>
															<div v-else class="alert alert-danger" :class="certification.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
													<template v-else>
														<span v-for="certification in technologyProfileDataLevel.certifications" v-bind:key="certification.id" style="float: left;">
															<div class="alert alert-danger" :class="certification.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
												</p>
											</div>
											<div class="row">
												<h6>Education</h6>
												<p>
													<template v-if="userEducation != undefined && userEducation.length > 0">
														<span v-for="education in technologyProfileDataLevel.education" v-bind:key="education.level" style="float: left;">
															<div v-if="userEducation.filter(ed => ed.trim().toLowerCase() == education.level.trim().toLowerCase()).length > 0" class="alert alert-success" :class="education.is_required? 'alert alert-success bold-req':'alert alert-success' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}} <br></div>
															<div v-else class="alert alert-danger" :class="education.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
													<template v-else>
														<span v-for="education in technologyProfileDataLevel.education" v-bind:key="education.level" style="float: left;">
															<div class="alert alert-danger" :class="education.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
												</p>
											</div>
										</b-tab>
									</div>
								</b-tabs>
							</b-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<template v-if="nextLevel != ''">
				<h2 class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px; margin-bottom: 1px">{{technology.toUpperCase()}} {{ type.toUpperCase() }}</h2>
				<div style="display: flex">
					<h2 class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px; margin-bottom: 1px">(Next Level of Expertise: {{nextLevel}}) </h2>
					<h5 v-if="experienceProfile != undefined" class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px; font-weight: 400 !important;">Experience: {{experienceProfile.years}} years and {{experienceProfile.months}} months</h5>
					<h5 v-else class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px;">Time of experience could not be extracted for this profile</h5>
				</div>
			</template>
			<template v-else>
				<div style="display: flex">
					<h2 class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px; margin-bottom: 1px">{{technology.toUpperCase()}} {{ type.toUpperCase() }} </h2>
					<h5 v-if="experienceProfile != undefined" class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px; font-weight: 400 !important;">Experience: {{experienceProfile.years}} years and {{experienceProfile.months}} months</h5>
					<h5 v-else class="tech-analysis-title" style="font-size: 14.66px; margin-left: 3px;">Time of experience could not be extracted for this profile</h5>
				</div>
			</template>		
			<div class="row" style="margin-left: 0px">
				<div class="col-sm-6" style="padding-left: 3px; padding-top: 0px;">
					<h5 class="mb-0" style="padding: 0 !important; margin-top: 0px;  margin-bottom: 14px !important; font-size: 14.66px">Summary</h5>
					<div id="chart-container"  style="width: 100% !important;" class="col-sm-12 profile-progress-container">
						<img width="300" v-if="graphLoaded" style="margin-left: -24px" :src="'https://image-charts.com/chart.js/2.8.0?bkg=white&c=' + chartLinkOptions(radarChartData) + '&width=400'" alt="Static Chart"/>
					</div>
				</div>
				<div class="col-sm-6" style="padding: 0px">
					<h5 class="mb-0" style="padding: 0 !important; margin-top: 0px;  margin-bottom: 14px !important; font-size: 14.66px">Hard Skills review (accomplished data)</h5>
					<div class="row" style="max-height: 195px; overflow: auto; margin-bottom: 7px; width: 313px">
						<div class="col-sm-12 level-name" style="padding-right: 0px">
							<b-card no-body>
								<b-tabs style="line-height: 10px;" pills card>
									<div v-for="(technologyProfileDataLevel, levelName, indexTech) in technologyLevels" v-bind:key="levelName">
										<b-tab style="padding: 5px" :class="levelName + '_tab'" :active="compareNames(levelName.toLowerCase(), type.toLowerCase())">
											<template v-if="compareNames(levelName.toLowerCase(), type.toLowerCase())" #title style="padding: 0px">
												<font-awesome-icon icon="star" style="font-size: 9.66px" /> <strong style="font-size: 9.66px">{{levelName.charAt(0).toUpperCase() + levelName.slice(1)}}</strong>
											</template>
											<template v-else #title>
												<strong style="font-size: 9.66px">{{levelName.charAt(0).toUpperCase() + levelName.slice(1)}}</strong>
											</template>
											
											<template v-for="(reqs_skills, level, index) in technologyProfileDataTmp">
												<div v-if="level.toLowerCase() == levelName.toLowerCase()" class="row" v-for="(reqs, skillName, index2) in reqs_skills">
													<h6 style="font-size: 9.66px">{{skillName.charAt(0).toUpperCase() + skillName.replaceAll("_", " ").slice(1)}}</h6>
													<p>
														<span v-for="item in reqs" style="float: left; font-size: 9.66px;">
															<template v-if="skillName in userSkills">
																<div v-if="userSkills[skillName].filter(s => s.toLowerCase() == item.item.toLowerCase()).length > 0" class="alert alert-success" :class="item.is_required? 'alert alert-success bold-req':'alert alert-success' " >{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
																<div v-else class="alert alert-danger" :class="item.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
															</template>
															<template v-else>
																<div class="alert alert-danger" :class="item.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{item.item.charAt(0).toUpperCase() + item.item.replaceAll("_", " ").slice(1)}}</div>
															</template>
														</span>
													</p>
												</div>
											</template>
											<div class="row">
												<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
														<h6 style="font-size: 9.66px" v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">Years of experience (max. requested)</h6>
														<h6 style="font-size: 9.66px" v-else>Years of experience (min. required)</h6>
												</template>
												<template v-else>
													<h6 style="font-size: 13px">Years of experience (min. required)</h6>
												</template>
												<p>
													<span style="float: left; font-size: 9.66px;">
														<template v-if="experienceProfile != undefined">
															<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
																<template v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">
																	<template v-if="technologyProfileDataLevel.experience_time_max_years.is_required">
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																</template>
																<template v-else>
																	<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																</template>
															</template>
															<template v-else>
																<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																	<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
																<template v-else>
																	<div v-if="experienceProfile.years >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
															</template>
														</template>
														<template v-else>
															<template v-if="technologyProfileDataLevel.experience_time_max_years != null && technologyProfileDataLevel.experience_time_max_years != undefined">
																<template v-if="Object.keys(technologyProfileDataLevel.experience_time_max_years).length > 0">
																	<template v-if="technologyProfileDataLevel.experience_time_max_years.is_required">
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_max_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_max_years.value}} Years</div>
																	</template>
																</template>
																<template v-else>
																	<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																	<template v-else>
																		<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																		<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	</template>
																</template>
															</template>
															<template v-else>
																<template v-if="technologyProfileDataLevel.experience_time_min_years.is_required">
																	<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger bold-req">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
																<template v-else>
																	<div v-if="0 >= technologyProfileDataLevel.experience_time_min_years.value && userExpYearsImp > 0" class="alert alert-success">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																	<div v-else class="alert alert-danger">{{technologyProfileDataLevel.experience_time_min_years.value}} Years</div>
																</template>
															</template>
														</template>
													</span>
												</p>
											</div>
											<div class="row">
												<h6 style="font-size: 9.66px">Certificationssss</h6>
												<p style="font-size: 9.66px">
													<template v-if="userCertifications != undefined && userCertifications.length > 0">
														<span v-for="certification in technologyProfileDataLevel.certifications" v-bind:key="certification.id" style="float: left;">
															<div v-if="userCertifications.filter(cert => cert.toLowerCase() == certification.name.toLowerCase()).length > 0" class="alert alert-success" :class="certification.is_required? 'alert alert-success bold-req':'alert alert-success' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}} <br></div>
															<div v-else class="alert alert-danger" :class="certification.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
													<template v-else>
														<span v-for="certification in technologyProfileDataLevel.certifications" v-bind:key="certification.id" style="float: left;">
															<div class="alert alert-danger" :class="certification.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{certification.name.charAt(0).toUpperCase() + certification.name.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
												</p>
											</div>
											<div class="row">
												<h6 style="font-size: 9.66px">Education</h6>
												<p style="font-size: 9.66px">
													<template v-if="userEducation != undefined && userEducation.length > 0 && acommplishedReqs.education != undefined">
														<span v-for="education in technologyProfileDataLevel.education" v-bind:key="education.level" style="float: left;">
															<div v-if="acommplishedReqs.education.filter(ed => ed.toLowerCase() == education.level.toLowerCase()).length > 0" class="alert alert-success" :class="education.is_required? 'alert alert-success bold-req':'alert alert-success' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}} <br></div>
															<div v-else class="alert alert-danger" :class="education.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
													<template v-else>
														<span v-for="education in technologyProfileDataLevel.education" v-bind:key="education.level" style="float: left;">
															<div class="alert alert-danger" :class="education.is_required? 'alert alert-danger bold-req':'alert alert-danger' ">{{education.level.charAt(0).toUpperCase() + education.level.replaceAll("_", " ").slice(1)}}</div>
														</span>
													</template>
												</p>
											</div>
										</b-tab>
									</div>
								</b-tabs>
							</b-card>
						</div>
					</div>
					<small style="margin-top: 1px; font-size: 12px"><em>...for more information check interactive profile</em></small>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Progress from "@/components/Others/ProgressCircle";
	import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
	import RadarChart from "@/components/Others/Charts/RadarChart.js";

	export default {
		name: "TechAnalysis",
		components: {
			Progress,
			RadarChart
		},
		props: {
			technology: String,
			improvement: Object,
			progress: Number,
			type: String,
			data: Object,
			experienceProfile: Object,
			technologyLevels: Object,
			userSkills: Object,
			userCertifications: Array,
			userEducation: Array,
			acommplishedReqs: Object,
			levelsData: {
				type: Array,
				required: false,
				default: () => ([])
			},
			pdf: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				drawGraph: false,
				pdfChartData: null,
				programming_skills: [],
				percDataGraphTmp: {},
				scaledProgrammingSkills: 0,
				scaledExperience: 0,
				scaledOtherSkills: 0,
				scaledCertifications: 0,
				scaledEducation: 0,
				progressNumber: 0,
				weights: [],
				selectedLevel: "",
				graphLoaded:false,
				technologyProfileDataTmp:{},
				nextLevel: "",
				radarChartOptions: {
					elements: {
						line: {
							borderWidth: 3
						}
					},
					responsive: true,
					mantainAspectRatio: false,
					scale: {
						ticks: {
							beginAtZero: true,
							max: 100,
							min: 0
						}
					}
				},
				datasetIndexes: {},
				radarChartData: {
					labels: [],
					datasets: [],
					legend: {
						display: true,
						position: "right"
					}
				}
			}
		},
		computed: {
			userIsAdmin() {
				if (this.currentUser && this.currentUser.role) {
					return this.currentUser.role.name === 'admin';
				}
				return false;
			}
		},
		mounted(){
			this.drawGraph = true

			console.log('this.acomplishedReqs: ', this.acommplishedReqs)
		},
		created() {
			let labels = []
			let data = []

			labels.push('Job experience time')
			data.push(this.improvement['score_experience_time'])
			
			labels.push('Education')
			data.push(this.improvement['score_education'])
			
			labels.push('Certifications')
			data.push(this.improvement['score_certifications'])

			this.technologyLevels[this.type].skills.forEach((skill) => {
				let skillName = skill.skill

				let scoreName = 'score_'+skillName
				skillName = skillName.replaceAll("_", " ")

				labels.push(skillName.charAt(0).toUpperCase() + skillName.slice(1))

				data.push(this.improvement[scoreName])
			})

			for (let i = 0; i < this.levelsData.length; i++) {
				this.technologyProfileDataTmp[this.levelsData[i].name.toLowerCase()] = {}
				this.percDataGraphTmp[this.levelsData[i].name.toLowerCase()] = []
			}

			this.userExpYearsImp = parseInt(this.improvement["score_experience_time"])

			Object.entries(this.technologyLevels).forEach(([level, values]) => {
				if (values == undefined)
					this.percDataGraphTmp[level].push(totalPerc);

				let counter = 0;
				let totalPerc = 0;

				if (level.toLowerCase() != this.type.toLowerCase()){
					if (this.experienceProfile == undefined) {
						this.percDataGraphTmp[level].push(0);
					} else {
						if (values.experience_time_max_years != null  && values.experience_time_max_years != undefined){
							if (Object.keys(values.experience_time_max_years).length > 0){
								if (parseInt(this.experienceProfile.years) >= parseInt(values.experience_time_max_years.value) && this.experienceProfile.years > 0)
									this.percDataGraphTmp[level].push(100);
								else
									this.percDataGraphTmp[level].push(0);
							} else if (Object.keys(values.experience_time_min_years).length > 0) {
								if (parseInt(this.experienceProfile.years) >= parseInt(values.experience_time_min_years.value) && this.experienceProfile.years > 0)
									this.percDataGraphTmp[level].push(100);
								else
									this.percDataGraphTmp[level].push(0);
							} else {
								this.percDataGraphTmp[level].push(0);
							}
						} else if (Object.keys(values.experience_time_min_years).length > 0) {
							if (parseInt(this.experienceProfile.years) >= parseInt(values.experience_time_min_years.value) && this.experienceProfile.years > 0)
								this.percDataGraphTmp[level].push(100);
							else
								this.percDataGraphTmp[level].push(0);
						} else {
							this.percDataGraphTmp[level].push(0);
						}
					}

					if (values.education !== undefined && values.education.length > 0) {
						values.education.forEach(item => {
							if (item.is_required === undefined) item.is_required = 0
						})
					}

					if (this.userEducation.length == 0){
						let numObligatoryEd = 0
						if (values.education !== undefined && values.education.length > 0) {
							numObligatoryEd = values.education.filter(e => e.is_required === 1)
						}
						if (numObligatoryEd.length > 0)
							this.percDataGraphTmp[level].push(0);
						else
							this.percDataGraphTmp[level].push(100);
					} else {
						let numObligatoryEd = 0
						if (values.education !== undefined && values.education.length > 0) numObligatoryEd = values.education.filter(e => e.is_required === 1)
						if (numObligatoryEd.length > 0){
							counter = 0;
							numObligatoryEd.forEach((education) => {
								if (this.userEducation.education != null){
									if (this.userEducation.education.filter(ed => ed.toLowerCase() == education.level.toLowerCase()).length > 0)
										counter = counter + 1;
								} else {
									if (this.userEducation.filter(ed => ed.toLowerCase().replace(/\s/g, '') == education.level.toLowerCase().replace(/\s/g, '')).length > 0)
										counter = counter + 1;
								}
							});

							totalPerc = (counter / numObligatoryEd.length) * 100
							this.percDataGraphTmp[level].push(totalPerc);
						} else {
							this.percDataGraphTmp[level].push(100);
						}
					}

					if (values.certifications !== undefined && values.certifications.length > 0) {
						values.certifications.forEach(item => {
							if (item.is_required === undefined) item.is_required = 0
						})
					}

					if (this.userCertifications.length == 0){
						let numObligatoryCert = 0
						if (values.certifications !== undefined && values.certifications.length > 0) {
							numObligatoryCert = values.certifications.filter(e => e.is_required === 1)
						}
						
						if (numObligatoryCert.length > 0)
							this.percDataGraphTmp[level].push(0);
						else
							this.percDataGraphTmp[level].push(100);
					} else {
						let numObligatoryCert = 0
						if (values.certifications !== undefined && values.certifications.length > 0) numObligatoryCert = values.certifications.filter(e => e.is_required === 1)
						if (numObligatoryCert.length > 0) {
							counter = 0;
							numObligatoryCert.forEach((certification) => {

								if (this.userCertifications.filter(cert => cert.toLowerCase() == certification.name.toLowerCase()).length > 0)
									counter = counter + 1;
							})
							totalPerc = (counter / numObligatoryCert.length) * 100
							this.percDataGraphTmp[level].push(totalPerc);
						} else {
							this.percDataGraphTmp[level].push(100);
						}
						
					}
				}

				if (values.skills !== undefined && values.skills.length > 0) {
					values.skills.forEach((skill) => {
						let skillLevelName = skill.skill
						let skillLevelReqs = this.userSkills[skillLevelName];

						this.technologyProfileDataTmp[level][skillLevelName] = skill.items

						if (skill.items !== undefined && skill.items.length > 0) {
							skill.items.forEach(item => {
								if (item.is_required === undefined) item.is_required = 0
							})
						}

						counter = 0;
						if (level.toLowerCase() != this.type.toLowerCase()){
							let numObligatory = 0
							if (skill.items !== undefined && skill.items.length > 0) {
								numObligatory = skill.items.filter(e => e.is_required === 1)
							}
							if (numObligatory.length > 0){
								if (skillLevelReqs != null){
									numObligatory.forEach((s) => {
										if (skillLevelReqs.filter(item => item.toLowerCase() == s.item.toLowerCase()).length > 0)
											counter = counter + 1;
									});

									totalPerc = (counter / numObligatory.length) * 100
									this.percDataGraphTmp[level].push(totalPerc);
								} else {
									this.percDataGraphTmp[level].push(0);
								}
							} else {
								this.percDataGraphTmp[level].push(100);
							}
						}
					})
				}
			})

			this.radarChartData.labels = labels

			for (let i = 0; i < this.levelsData.length; i++) {
				let tmpObj = {}
				tmpObj.label = this.levelsData[i].name.charAt(0).toUpperCase() + this.levelsData[i].name.slice(1);
				tmpObj.data = []
				tmpObj.fill = true
				tmpObj.backgroundColor = this.levelsData[i].color + '33'
				tmpObj.borderColor = this.levelsData[i].color
				tmpObj.pointBackgroundColor = this.levelsData[i].color
				tmpObj.pointBorderColor = '#FFF'
				tmpObj.pointHoverBackgroundColor = '#FFF'
				tmpObj.pointHoverBorderColor = 'rgb(255, 99, 132)'
				
				this.radarChartData.datasets.push(tmpObj)
			}

			for (let i = 0; i < this.levelsData.length; i++) {
				this.datasetIndexes[this.levelsData[i].name.toLowerCase()] = i
			}

			this.radarChartData.datasets[this.datasetIndexes[this.type]].data = data
			this.radarChartData.datasets[this.datasetIndexes[this.type]].label = "Current level - " + this.type.charAt(0).toUpperCase() + this.type.slice(1);

			Object.entries(this.technologyLevels).forEach(([level, values]) => {
				if (this.type != level){
					this.radarChartData.datasets[this.datasetIndexes[level]].data = this.percDataGraphTmp[level];
					this.radarChartData.datasets[this.datasetIndexes[level]].label = level.charAt(0).toUpperCase() + level.slice(1) + " Level";
					this.radarChartData.datasets[this.datasetIndexes[level]].backgroundColor = 'rgba(255, 255, 255, 0)'
				}
			});

			for (let i = 0; i < this.levelsData.length; i++) {
				if (this.levelsData[i].name.toLowerCase() === this.type.toLowerCase() && i + 1 < this.levelsData.length) {
					this.nextLevel = this.levelsData[i+1].name.toUpperCase() + ' - ' + this.levelsData[i+1].min_range + '%'
					this.graphLoaded = this.graphLoaded ? false : true
					return
				} else
					this.nextLevel = ''
			}
			this.graphLoaded = this.graphLoaded ? false : true
		},
		methods: {
			hexToRgbA(hex){
				var c;
				if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
					c= hex.substring(1).split('');
					if(c.length== 3){
						c= [c[0], c[0], c[1], c[1], c[2], c[2]];
					}
					c= '0x'+c.join('');
					return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
				}
			},
			chartLinkOptions(options) {
				for(var dataset of options.datasets){
					dataset.backgroundColor =  this.hexToRgbA(dataset.backgroundColor.toLowerCase())
					dataset.borderColor = this.hexToRgbA(dataset.borderColor.toLowerCase())
					dataset.pointBackgroundColor = this.hexToRgbA(dataset.pointBackgroundColor.toLowerCase())
					dataset.pointBorderColor = this.hexToRgbA(dataset.pointBorderColor.toLowerCase())
					dataset.pointHoverBackgroundColor = this.hexToRgbA(dataset.pointHoverBackgroundColor.toLowerCase())
					dataset.pointHoverBorderColor = this.hexToRgbA(dataset.pointHoverBorderColor.toLowerCase())
					dataset.fill = false
				}

				var radarOptions = {
					type: 'radar',
					data: options,
					options: {
						elements: {
							line: {
								borderWidth: 3
							}
						},
						responsive: true,
						mantainAspectRatio: false,
						scale: {
							ticks: {
								beginAtZero: true,
								max: 100,
								min: 0
							}
						}
					}
				}

				return JSON.stringify(radarOptions)
			},
			compareNames(iterator, levelName){
				if(iterator == levelName){
					return true;
				} else return false; 
			},
			redirectToChatbot() {
				ProfileAnalysisDataService.findLastByUser(this.$store.state.auth.user.id).then(
					response => {
						if (response.status == 200) 
						this.$router.replace({ name: "chat", params: {}, query: {token: response.data.token} })
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.error('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>

<style scoped>
#chart-container {
	width: 80% !important;
}

.alert-interview {
	padding-bottom: 0px !important;
}

.tabs {
	width: 100%;
}

.bold-req {
	font-weight: bold;
}

.card {
	max-width: 55em !important;
	margin-top: 0px !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	border: 0 !important;
}

.card-header:first-child {
	background-color: rgb(255 255 255 / 3%) !important;
}
</style> 