<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link v-if="isAdmin" :to="{path: '/admin-reports-examiner'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Examiner Reports</router-link>
        <router-link v-else :to="{path: '/examiner-talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
        <reports-downloader v-if="selectedUser != null" :documentTitle="documentTitle" :testListTitle="testListTitle"/>
      </div>
      <div v-if="isAdmin" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/admin-reports-examiner' }" class="breadcumb"> Examiner Reports > </router-link>
        <router-link :to="{ path: '/examiner-exams-reports' }" class="final-breadcumb"> Exams Reports </router-link>
      </div>
      <div v-else class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/examiner-talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/examiner-exams-reports' }" class="final-breadcumb"> Exams Reports </router-link>
      </div>
    </div>
    
    <h4 class="reports-available-title">Reports available</h4>
    <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport" @change="onChange($event)" >
			<option :value="undefined">Select a report to view</option>
      <option value="columns-skill">Column diagrams displaying skills in Progradum Tech.</option>
      <option value="exams-by-user">Exam reports by user</option>
		</select>

    <question-graph-by-technology v-if="selectedReport == 'columns-skill'" ref="chart1" :areas="areas"/>

    <div class="row user-title" v-if="selectedReport == 'exams-by-user'" >
      <h3 style="margin-bottom: 2%; margin-left: 1%;">Exam reports by user</h3>
      <div class="col-sm-6">
        <select class="form-select area_selector" data-vv-name="user_selector" v-model="selectedUser" @change="onChangeUser($event)">
          <option hidden value="">Select an user</option>
          <option v-for="username in usersList" :value="username">{{username}}</option>
        </select>
      </div>
      <div class="col-sm-6">
        <select class="form-select area_selector" data-vv-name="area_selector" v-model="userSelectedArea" @change="onChangeArea($event)">
          <option hidden value="">Select an available area for user</option>
          <option v-for="area in userAreas" :value="area.id">{{area.name}}</option>
        </select>
      </div>      
    </div>
    <div v-if="showResults">
      <h3 class="user-name" style="margin-left: 3%;">User "{{selectedUser}}"</h3>
      <h4 class="reports-available-title">Reports available</h4>
      <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport2" @change="onChange($event)" >
        <option :value="undefined">Select a report to view</option>
        <option value="user-exams-graph-by-marks">Exams diagram by Marks</option>
        <option value="user-answers-graph-by-question">Column diagram with answers distribution</option>
        <option value="user-percentage-correct-graph-by-question">Column diagram with correct/wrong answers distribution</option>
        <option value="user-bubble-graph-by-question">Number of questions answered in an area and its value of correct/wrong distribution</option>
      </select>
      <user-exams-graph-by-marks v-if="selectedReport2 == 'user-exams-graph-by-marks'" :key="'user-exams-graph-by-marks-' + userSelectedArea" :user="selectedUserObject" :userAreaID="userSelectedArea" ref="chart2"/>
      <user-answers-graph-by-question v-if="selectedReport2 == 'user-answers-graph-by-question'" :key="'user-answers-graph-by-question-' + userSelectedArea" :user="selectedUserObject" :userAreaID="userSelectedArea" ref="chart3"/>
      <user-percentage-correct-graph-by-question v-if="selectedReport2 == 'user-percentage-correct-graph-by-question'" :key="'user-percentage-correct-graph-by-question-' + userSelectedArea" :user="selectedUserObject" :userAreaID="userSelectedArea" ref="chart4"/>
      <user-bubble-graph-by-question v-if="selectedReport2 == 'user-bubble-graph-by-question'" :key="'user-bubble-graph-by-question-' + userSelectedArea" :user="selectedUserObject" :userAreaID="userSelectedArea" ref="chart5"/>
    </div>

    <div id="message" v-if="message" class="col-md-6 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
    </div>
  </div>
</template>

<script>
  import QuestionGraphByTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByTechnology.vue";
  import UserExamsGraphByMarks from "@/components/ReportsComponents/Results/Users/UserExamsGraphByMarks.vue";
  import UserAnswersGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserAnswersGraphByQuestion.vue";
  import UserPercentageCorrectGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserPercentageCorrectGraphByQuestion.vue";
  import UserBubbleGraphByQuestion from "@/components/ReportsComponents/Results/Users/UserBubbleGraphByQuestion.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";
  import UsersDataService from '@/services/UserDataService';
  import AreasDataService from "@/services/AreasDataService";

  export default {
    name: 'ExamsReportsExaminerView',
    data() {
      return {
        selectedUser: "",
        selectedUserObject: {},
        userSelectedArea: "",
        userAreas: [],
        users: [],
        showResults: false,
        usersList: [],
        message: "",
        graphContent: [
          {title: "Columns diagram by technology"},
          {title: "Exams diagram by Marks"},
          {title: "Bubble diagram of question distribution"}
        ],
        testListTitle: {
          "chart3": "Column diagram with answers distribution of question",
          "chart4": "Column diagram with correct/wrong answers distribution of question",
        },
        documentTitle: "examiner_exam_reports.pdf",
        areas: [],
        selectedReport: undefined,
        selectedReport2: undefined
      }
    },
    components: {
      QuestionGraphByTechnology,
      UserExamsGraphByMarks,
      UserAnswersGraphByQuestion,
      UserPercentageCorrectGraphByQuestion,
      UserBubbleGraphByQuestion,
      ReportsDownloader
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      }
    },
    mounted() {
      if (this.isAdmin)
        this.getListOfAreasFromAdmin();
      else
        this.areas = this.$store.state.auth.user.areas;

      this.getAllUsersFromCompany();
    },
    methods: {
      onChangeUser(event) {
        this.showResults = false;

        this.selectedUser = event.target.value

        this.users.forEach((user) => {
          if (this.selectedUser === user.username)
            this.selectedUserObject = user;
        });

        this.getAreasFromUser();
      },
      onChangeArea(event) {
        this.showResults = true;
        this.graphLoading = true;
      },
      getAllUsersFromCompany() {
        UsersDataService.getAllUsersFromCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              let usersInfo = response.data;
              usersInfo.forEach((user) => {
                this.users.push(user);
                this.usersList.push(user.username);
              });
            } else {
              console.log('Error: ', response)
              this.message = 'An error ocurred while retrieving users'
            }
          },
          error => {
            console.error((error.response && error.response.data) ||
              error.message ||
              error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      },
      getAreasFromUser() {
        UsersDataService.findOne(this.selectedUserObject.id).then(
          response => {
            if (response.status == 200) {
              let userInfo = response.data;

              this.userAreas = userInfo.areas;
            }
          },
          error => {
            console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      },
      async getListOfAreasFromAdmin() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.user-title {
    margin-top: 2%;
    margin-left: 1%;
}

.user-name {
    margin-left: 2%;
}

.report_selector {
  margin-left: 3%;
  margin-right: 3%; 
  margin-top: 2%;
  max-width: 94%;
}

.reports-available-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}
</style>