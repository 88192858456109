<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link v-if="isUser && isFirstCV" :to="{path: '/user'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back home</router-link>
				<router-link v-else-if="!showAdminBoard || isTM" :to="{path: '/analyses'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to my analysis</router-link>
				<router-link v-else :to="{path: '/user-analysis'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to analysis</router-link>
			</div>
			<div class="col-7 noMargin whiteColor" v-if="isAdmin || isTM">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/user-analysis' }" class="breadcumb"> CV Results > </router-link>
				<router-link :to="{ path: '/create-analysis' }" class="final-breadcumb"> Upload New CV </router-link>
			</div>

			
			<div class="col-7 noMargin whiteColor" v-else-if="isUser && isFirstCV">
				<router-link :to="{ path: '/create-analysis' }" class="final-breadcumb"> Upload New CV </router-link>
			</div>

			<div class="col-7 noMargin whiteColor" v-else>
				<router-link :to="{ path: '/analyses' }" class="breadcumb"> Professional Profile Analysis > </router-link>
				<router-link :to="{ path: '/create-analysis' }" class="final-breadcumb"> Upload New CV </router-link>
			</div>
		</div>
		
		<div class="col-sm-12 px-4 col-centered">
			<create-analysis/>
		</div>
	</div>
</template>

<script>
	import CreateAnalysis from "@/components/AnalysisComponents/Create.vue";
	import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";

	export default {
	  	name: 'CreateAnalysisView',
	  	components: {
	      	CreateAnalysis
	    },
		data () {
			return {
				isFirstCV: true
			}
		},
	    computed: {
	      	currentUser() {
				return this.$store.state.auth.user;
			},
		  	isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			},
			isTM(){
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
						return true;
					}
				}
				return false;
			},
			isUser() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'user') {
						return true;
					}
				}
				return false;
			},
			showAdminBoard() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			}
	  	},
		async mounted () {
			await ProfileAnalysisDataService.findCVsByUser(this.currentUser.id).then(
				response => {
					if (response.data.length === 0) this.isFirstCV = true
					else this.isFirstCV = false
				},
				error => {
					console.error((error.response && error.response.data) ||
					error.message ||
					error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			)
		}
	};
</script>


<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>