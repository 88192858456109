import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'question/';

class QuestionDataService {

  create(question, tags, idArea) {
    var answer_one_correct, answer_two_correct, answer_three_correct, answer_four_correct;
    var answer_one_value, answer_two_value, answer_three_value, answer_four_value;
    var numberCorrect = 0;

    if (question.answer_one_correct == "") {
      answer_one_correct = 0
    } else {
      numberCorrect += 1;
      answer_one_correct = 1
    }
    if (question.answer_one_value == "") {
      answer_one_value = 0
    } else {
      answer_one_value = question.answer_one_value
    }
    if (question.answer_two_correct == "") {
      answer_two_correct = 0
    } else {
      numberCorrect += 1;
      answer_two_correct = 1
    }
    if (question.answer_two_value == "") {
      answer_two_value = 0
    } else {
      answer_two_value = question.answer_two_value
    }
    if (question.answer_three_correct == "") {
      answer_three_correct = 0
    } else {
      numberCorrect += 1;
      answer_three_correct = 1
    }
    if (question.answer_three_value == "") {
      answer_three_value = 0
    } else {
      answer_three_value = question.answer_three_value
    }
    if (question.answer_four_correct == "") {
      answer_four_correct = 0
    } else {
      numberCorrect += 1;
      answer_four_correct = 1
    }
    if (question.answer_four_value == "") {
      answer_four_value = 0
    } else {
      answer_four_value = question.answer_four_value
    }
    if (numberCorrect == 1) {
      answer_one_value = 0;
      answer_two_value = 0;
      answer_three_value = 0;
      answer_four_value = 0;
    }

    const questionParam = {
      id_language: question.id_language, id_difficulty_level: question.id_difficulty_level, 
      question_title: question.question_title, question_content: question.question_content, 
      answer_one: question.answer_one, answer_two: question.answer_two, answer_three: question.answer_three, answer_four: question.answer_four,
      answer_one_correct: answer_one_correct, answer_two_correct: answer_two_correct, answer_three_correct: answer_three_correct, answer_four_correct: answer_four_correct, 
      answer_one_value: answer_one_value, answer_two_value: answer_two_value, answer_three_value: answer_three_value, answer_four_value: answer_four_value,
      multiselection: question.multiselection, id_user_owner: question.id_user_owner, id_repository: question.id_repository
    };

    return http.post(API_URL_PATH + 'create', { question: questionParam, tags: tags, id_area: idArea }, { headers: authHeader() });
  }

  getQuestionBoard(idArea) {
    return http.get(API_URL_PATH + 'createBoard?id_area='+idArea, { headers: authHeader() });
  }

  getTags(id_question) {
    return http.get(API_URL_PATH + 'tags?id='+id_question, { headers: authHeader() });
  }

  findAll() {
    return http.get(API_URL_PATH + 'findAll', { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findByRepository(id_repository) {
    return http.get(API_URL_PATH + 'findByRepository?id='+id_repository, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+parseInt(id), { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }
  
  validate (id) {
    const body = {id: parseInt(id)};
    return http.put(API_URL_PATH + 'validate', body, { headers: authHeader() });
  }

  avoid (id) {
    const body = {id: parseInt(id)};
    return http.put(API_URL_PATH + 'avoid', body, { headers: authHeader() });
  }

  update(question, tags, idArea) {
    var answer_one_correct, answer_two_correct, answer_three_correct, answer_four_correct;
    var answer_one_value, answer_two_value, answer_three_value, answer_four_value;
    var numberCorrect = 0;

    if (question.answer_one_correct == "") {
      answer_one_correct = 0
    } else {
      numberCorrect += 1;
      answer_one_correct = 1
    }
    if (question.answer_one_value == "") {
      answer_one_value = 0
    } else {
      answer_one_value = question.answer_one_value
    }
    if (question.answer_two_correct == "") {
      answer_two_correct = 0
    } else {
      numberCorrect += 1;
      answer_two_correct = 1
    }
    if (question.answer_two_value == "") {
      answer_two_value = 0
    } else {
      answer_two_value = question.answer_two_value
    }
    if (question.answer_three_correct == "") {
      answer_three_correct = 0
    } else {
      numberCorrect += 1;
      answer_three_correct = 1
    }
    if (question.answer_three_value == "") {
      answer_three_value = 0
    } else {
      answer_three_value = question.answer_three_value
    }
    if (question.answer_four_correct == "") {
      answer_four_correct = 0
    } else {
      numberCorrect += 1;
      answer_four_correct = 1
    }
    if (question.answer_four_value == "") {
      answer_four_value = 0
    } else {
      answer_four_value = question.answer_four_value
    }
    if (numberCorrect == 1) {
      answer_one_value = 0;
      answer_two_value = 0;
      answer_three_value = 0;
      answer_four_value = 0;
    }

    const questionParam = {
      id: question.id, id_language: question.id_language, id_difficulty_level: question.id_difficulty_level, question_title: question.question_title,
      question_content: question.question_content, answer_one: question.answer_one, answer_two: question.answer_two, answer_three: question.answer_three, answer_four: question.answer_four,
      answer_one_correct: answer_one_correct, answer_two_correct: answer_two_correct, answer_three_correct: answer_three_correct,
      answer_four_correct: answer_four_correct, answer_one_value: answer_one_value,
      answer_two_value: answer_two_value, answer_three_value: answer_three_value, answer_four_value: answer_four_value,
      multiselection: question.multiselection, id_user_modify: question.id_user_modify
    };

    return http.put(API_URL_PATH + 'update', { question: questionParam, tags: tags, id_area: idArea }, { headers: authHeader() });
  }

  filterByTags(tags) {
    return http.post(API_URL_PATH + 'filterByTags', { tags: tags }, { headers: authHeader() });
  }

  availableQuestions(params) {

    return http.post(API_URL_PATH + 'availableQuestions', { tags: params.tags, difficulty_levels: params.difficulty_levels, language: params.language, id_area: params.id_area }, { headers: authHeader() });
  }

}

export default new QuestionDataService();