<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/examiner-talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/examiner-talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/examiner-general-reports' }" class="final-breadcumb"> General Reports </router-link>
      </div>
    </div>

    <UsersRegistredGraphByRole ref="chart1" :areas="areas"/>
    <UsersGraphByCompany ref="chart2" :areas="areas"/>
    <ExamsGraphByCompany ref="chart3" :areas="areas"/>
    <QuestionsGraphByCompany ref="chart4" :areas="areas"/>
    <ProfilesGraphByCompany ref="chart5" :areas="areas"/>
    <CVsGraphByCompany ref="chart6" :areas="areas"/>
  </div>
</template>

<script>
  import UsersRegistredGraphByRole from "@/components/ReportsComponents/General/UsersRegistredGraphByRole.vue";
  import UsersGraphByCompany from "@/components/ReportsComponents/General/UsersGraphByCompany.vue";
  import ExamsGraphByCompany from "@/components/ReportsComponents/General/ExamsGraphByCompany.vue";
  import QuestionsGraphByCompany from "@/components/ReportsComponents/General/QuestionsGraphByCompany.vue";
  import ProfilesGraphByCompany from "@/components/ReportsComponents/General/ProfilesGraphByCompany.vue";
  import CVsGraphByCompany from "@/components/ReportsComponents/General/CVsGraphByCompany.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";

  export default {
    name: 'GeneralReportsExaminerView',
    components: {
      UsersRegistredGraphByRole,
      UsersGraphByCompany,
      ExamsGraphByCompany,
      QuestionsGraphByCompany,
      ProfilesGraphByCompany,
      CVsGraphByCompany,
      ReportsDownloader
    },
    data() {
      return {
        graphContent: [
          {title: "Users registered by role"},
          {title: "Users registered by company"},
          {title: "Exams by company"},
          {title: "Questions by company"},
          {title: "Profiles created by company"},
          {title: "Users CVs analyzed by company"}
        ],
        documentTitle: "user_reports.pdf",
        areas: []
      }
    },
    mounted() {
      this.areas = this.$store.state.auth.user.areas;
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>