import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'position_selection/';

class SelectionProcessService {
  findOne(selectionProcessId) {
    return http.get(API_URL_PATH + 'findOne?id=' + selectionProcessId, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findByProfile(idProfile) {
    return http.get(API_URL_PATH + 'findByProfile?id_profile=' + idProfile, { headers: authHeader() });
  }
}

export default new SelectionProcessService();