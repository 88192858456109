<template>
    <div style="margin: 10% !important;">
        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Name</label>
                <br>
                <input class="form-input multiselection" type="text"  name="name-value" v-model="name">
            </div>
        </div>
        
        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Type of process</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="type-value" value="Internal Evaluation" v-model="typeOfProcess"> Internal Evaluation</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="type-value" value="External Selection" v-model="typeOfProcess"> External Selection</label>
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Language</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="language-value" value="Spanish" v-model="language"> Spanish</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="language-value" value="English" v-model="language"> English</label>
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Country</label>
                <br>
                <input class="form-input multiselection" type="text"  name="country-value" v-model="country">
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>City</label>
                <br>
                <input class="form-input multiselection" type="text"  name="city-value" v-model="city">
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label><input class="form-input multiselection" type="checkbox"  name="notifications-value" v-model="notifications"> Send notifications to candidates</label>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="form-group">
                <label><input class="form-input multiselection" type="checkbox"  name="active-value" v-model="active"> Process is active</label>
            </div>
        </div>
    </div>
</template>

<script>
    export default { 
        name: 'PersonalDataStep',
        props: ['clickedNext', 'currentStep'],
        data () { 
            return {
                result: {},
                isSubmitted: false,
                name: '',
                typeOfProcess: false,
                language: null,
                country: '',
                city: '',
                notifications: false,
                active: false
            } 
        }, 
        mounted() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.$emit('can-continue', {value: true});
            }
        }
    }
</script>

<style scoped>
.title-class {
    margin-left: 5% !important;
}

#app > div > div > div.col-sm-8.col-centered > div > div > div.content > div > form > div:nth-child(4) > div > div > div:nth-child(1) > div > div {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 30px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
</style>