import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = '/auth/';

class AuthDataService {

  registerBoard() {
    return http.get(API_URL_PATH + 'signupBoard');
  }

  register(request) {
    const body = { username: request.user.username, name: request.user.name, surname: request.user.surname, email: request.user.email, password: request.user.password, roles: request.roles, id_company: request.id_company };

    return http.post(API_URL_PATH + 'signup', body, { headers: authHeader() });
  }

  confirmAccountRegister(token) {
    return http.get(API_URL_PATH + 'findByAccessToken?token='+token);
  }

  validateToken() {
    return http.get(API_URL_PATH + 'validateToken', { headers: authHeader() });
  }

  login(user) {
    const body = {username: user.username, password: user.password}
    return http.post(API_URL_PATH + 'signin', body)
      .then(
        response => {
          if (response.status == 200){
            if (response.data.accessToken) {
              localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
          } else {
            console.log("Error in login")
            console.log(response)
            return response.data;
          }
        },
        error => {
          console.log((error.response && error.response.data) ||
          error.message ||
          error.toString())
          return {status:401, message: error.response.data.message}
        }
      );
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('company');
  }
}

export default new AuthDataService();