<template>
  <div class="wrapper">
    <template v-if="!submitted">
      <h1 class="mt-4" style="margin-left: 2% !important;">Hard Skills Test '{{ test.title }}'</h1>
      <template v-if="!started">
        <div class="start-button-container">
          <button style="border-color: #4fc0e8; background-color: #4fc0e8; color: black;" @click="startTest()"
            type="submit" class="btn btn-primary">Start test</button>
        </div>
      </template>
      <template v-else>
        <VueCountdown v-if="time !== null" :time="time" :transform="transform" @progress="handleCountdownProgress"
          @end="handleCreate">
          <template slot-scope="props">
            <h2 style="text-align:center">
              Remaining time: {{ props.minutes }}, {{ props.seconds }}.
            </h2>
          </template>
        </VueCountdown>
        <h5 class="mb-0" style="text-align: center !important;" v-if="time !== null"> Remaining time: {{
      remaining.minutes }}
          minutes, {{ remaining.seconds }} seconds.</h5>
        <h5 class="mb-0" style="text-align: center !important;" v-else> Remaining time: No time limit.</h5>
        <form action class="form" @submit.prevent="showModal">
          <notifications position="top center" animation-type="velocity" width="50%" />

          <div class="test-container">
            <div class="question-container" v-bind:id="'question-container_' + index"
              v-for="(question, index) in questions" v-bind:key="question.id">
              <h5 class="question-title">Question {{ index + 1 }}/{{ questions.length }}</h5>
              <br>
              <p class="title">{{ question.question_title }}</p>

              <div class="content" v-if="question.question_content != '' && question.question_title != null">
                <div class="code" v-html="question.question_content"></div>
              </div>
              <div class="list" v-if="question.multiselection">
                <div class="col-sm-12">
                  <label><input type="checkbox" @click="registerCheckedOption(index, 1, 0)"
                      v-bind:id="'answer_one_' + question.id"> {{ question.answer_one }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input type="checkbox" @click="registerCheckedOption(index, 2, 0)"
                      v-bind:id="'answer_two_' + question.id"> {{ question.answer_two }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input type="checkbox" @click="registerCheckedOption(index, 3, 0)"
                      v-bind:id="'answer_three_' + question.id"> {{ question.answer_three }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input type="checkbox" @click="registerCheckedOption(index, 4, 0)"
                      v-bind:id="'answer_four_' + question.id"> {{ question.answer_four }}</label>
                </div>
              </div>
              <div class="list" v-else>
                <div class="col-sm-12">
                  <label><input class="form-input" type="radio" name="multiselection" value="false"
                      @click="registerCheckedOption(index, 1, 1)" v-bind:id="'answer_one_' + question.id">
                    {{ question.answer_one }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input class="form-input" type="radio" name="multiselection" value="false"
                      @click="registerCheckedOption(index, 2, 1)" v-bind:id="'answer_two_' + question.id">
                    {{ question.answer_two }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input class="form-input" type="radio" name="multiselection" value="false"
                      @click="registerCheckedOption(index, 3, 1)" v-bind:id="'answer_three_' + question.id">
                    {{ question.answer_three }}</label>
                </div>
                <div class="col-sm-12">
                  <label><input class="form-input" type="radio" name="multiselection" value="false"
                      @click="registerCheckedOption(index, 4, 1)" v-bind:id="'answer_four_' + question.id">
                    {{ question.answer_four }}</label>
                </div>
              </div>
              <div class="buttons">
                <button type="button" class="btn btn-secondary float-left previous" @click="previousQuestion(index)"
                  v-bind:value="index" v-bind:id="'prev_' + question.id" v-if="index != 0"><font-awesome-icon
                    icon="arrow-circle-left" /> Previous</button>
                <button type="button" class="btn btn-secondary float-right next" @click="nextQuestion(index)"
                  v-bind:value="index" v-bind:id="'next_' + question.id" v-if="index != questions.length - 1">Next
                  <font-awesome-icon icon="arrow-circle-right" /></button>
              </div>
              <div v-if="index == questions.length - 1" class="form-group col-lg-2 align-item-center">
                <button type="submit" class="btn btn-primary">Finish</button>
              </div>
            </div>
          </div>
        </form>

        <b-modal id="create-modal" ref="create-modal" title="Create-modal" hide-footer>
          <template #modal-title>
            Finish test
          </template>
          <div>
            <p>Do you really want to finish your test? </p>
          </div>
          <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
            @click="$bvModal.hide('create-modal')" value="No">
          <input class="btn btn-primary form-submit" type="button" @click="handleCreate()" value="Yes">
        </b-modal>
      </template>
    </template>
    <template v-else>
      <h1 class="mt-4" style="margin-left: 2% !important;">Hard Skills Test '{{ test.title }}'</h1>
      <h5 class="mb-0" style="margin-left: 3% !important; margin-top: 1% !important;">Test successfully completed! If
        you wish, you can close the page.</h5>
    </template>
  </div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import Vue from 'vue';
import Test from '@/models/test';
import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

export default {
  name: "TestAttemptComponent",
  components: {
    VueCountdown
  },
  data: () => ({
    test: new Test('', '', '', '', '', '', '',
      '', '', '', '', '', '', '',
      '', ''),
    questions: [],
    selection_process: {},
    submitted: false,
    shuffle: false,
    time: null,
    remaining: {
      minutes: null,
      seconds: null,
    },
    countdownExists: true,
    started: false,
    idAttempt: null
  }),
  mounted() {
    TestDataService.getQuestionsForCandidateAttempt(this.$route.params.id, this.$route.params.candidate_token, this.$route.params.process_id).then(
      response => {
        this.test = response.data
        if (response.data.time_limit !== null) {
          this.time = response.data.time_limit * 60 * 1000
          this.countdownExists
        } else {
          this.countdownExists = false
          this.time = null
        }
        this.questions = response.data.questions
        this.shuffle = response.data.shuffle

        if (this.shuffle) {
          var arrayIndices = []
          var questionsTemp = []

          for (var i = 0; i < this.questions.length; i++) {
            var aleatorio = Math.random() * (1000 - 1) + 1
            arrayIndices.push({ 'indice': i, 'valor': aleatorio })
          }

          arrayIndices.sort(function (a, b) {
            return (a.valor - b.valor)
          })

          for (var i = 0; i < arrayIndices.length; i++) {
            questionsTemp.push(this.questions[arrayIndices[i].indice])
          }

          this.questions = questionsTemp
        }

        for (var i = 0; i < this.questions.length; i++) {
          this.questions[i].answer_one_selected = 0;
          this.questions[i].answer_two_selected = 0;
          this.questions[i].answer_three_selected = 0;
          this.questions[i].answer_four_selected = 0;
        }
      },
      error => {
        console.log((error.response && error.response.data) ||
          error.message ||
          error.toString())
      }
    );
  },
  methods: {
    startTest() {
      TestDataService.createCandidateAttempt(this.$route.params.id, this.$route.params.candidate_token, this.$route.params.process_id).then(
        response => {
          this.idAttempt = response.data.id_attempt
          this.started = true
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())
          this.message = error.response.data.message

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    redirect(event) {
      var confirmationMessage = "\o/";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    nextQuestion(index) {
      var nextIndex = index + 1
      document.getElementById('question-container_' + index).style.display = 'none'
      document.getElementById('question-container_' + nextIndex).style.display = 'block'
    },
    previousQuestion(index) {
      var previousIndex = index - 1
      document.getElementById('question-container_' + index).style.display = 'none'
      document.getElementById('question-container_' + previousIndex).style.display = 'block'
    },
    registerCheckedOption(index, answer_number, type) {
      if (type == 0) {
        if (answer_number == 1) {
          if (this.questions[index].answer_one_selected == 0) {
            this.questions[index].answer_one_selected = 1
          } else {
            this.questions[index].answer_one_selected = 0
          }
        } else if (answer_number == 2) {
          if (this.questions[index].answer_two_selected == 0) {
            this.questions[index].answer_two_selected = 1
          } else {
            this.questions[index].answer_two_selected = 0
          }
        } else if (answer_number == 3) {
          if (this.questions[index].answer_three_selected == 0) {
            this.questions[index].answer_three_selected = 1
          } else {
            this.questions[index].answer_three_selected = 0
          }
        } else {
          if (this.questions[index].answer_four_selected == 0) {
            this.questions[index].answer_four_selected = 1
          } else {
            this.questions[index].answer_four_selected = 0
          }
        }
      } else {
        if (answer_number == 1) {
          this.questions[index].answer_one_selected = 1
          this.questions[index].answer_two_selected = 0
          this.questions[index].answer_three_selected = 0
          this.questions[index].answer_four_selected = 0
        } else if (answer_number == 2) {
          this.questions[index].answer_one_selected = 0
          this.questions[index].answer_two_selected = 1
          this.questions[index].answer_three_selected = 0
          this.questions[index].answer_four_selected = 0
        } else if (answer_number == 3) {
          this.questions[index].answer_one_selected = 0
          this.questions[index].answer_two_selected = 0
          this.questions[index].answer_three_selected = 1
          this.questions[index].answer_four_selected = 0
        } else {
          this.questions[index].answer_one_selected = 0
          this.questions[index].answer_two_selected = 0
          this.questions[index].answer_three_selected = 0
          this.questions[index].answer_four_selected = 1
        }
      }
    },
    showModal() {
      this.$refs['create-modal'].show();
    },
    handleCreate() {
      var new_questions = []
      this.questions.forEach(question => {
        let new_question = {
          question_id: question.test_question.id_question,
          answer_one_selected: question.answer_one_selected,
          answer_two_selected: question.answer_two_selected,
          answer_three_selected: question.answer_three_selected,
          answer_four_selected: question.answer_four_selected,
        }
        new_questions.push(new_question)
      })

      TestDataService.updateCandidateAttempt(this.idAttempt, new_questions, this.$route.params.process_id).then(
        response => {
          this.$refs['create-modal'].hide();
          this.submitted = true
          this.countdownExists = false

        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())
          this.message = error.response.data.message

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;

        const word = value < 2 ? key.replace(/s$/, '') : key;

        props[key] = `${digits} ${word}`;
      });

      return props;
    },
    handleCountdownProgress(data) {
      this.remaining.minutes = data.minutes
      this.remaining.seconds = data.seconds

      if (data.minutes < 1 && data.seconds == 59 && data.hours == 0) {
        this.$notify({
          type: 'error',
          duration: '5000',
          title: 'Alert',
          text: 'Less that a minute remaining!'
        });
      }
    }
  }
};
</script>

<style>
.start-button-container {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.question-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: none;
  text-align: left;
  border: 1px solid #eee;
}

.question-container:first-child {
  display: block;
}

.question-container .content {
  background: #eee;
  margin-bottom: 3%;
  margin-left: 2%;
  margin-right: 2%;
}

.question-container .content .code {
  position: relative;
  overflow: hidden;
  margin-left: 3%;
  padding-top: 2%;
}

.question-container .buttons {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 50%;
  padding-top: 3%;
  margin: 0 auto;
}

.question-container .title {
  padding: 7px;
  margin-left: 1.5%;
}

.question-container .list {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: 2%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #13283B;
  text-align: left;
  font-weight: bold !important;
}

label {
  color: #000;
}

a:hover {
  cursor: pointer;
}

.qkb-msg-bubble-component__text {
  position: relative;
  padding: .75rem 1rem;
  border-radius: 6px;
  text-align: left;
}

p.account-msg {
  color: #fff;
  margin-top: 5%;
}

.previous-register {
  width: 100%;
  margin: 0 auto;
  max-width: 50%;
}

.alert-profile {
  margin: 0rem 0 0 !important;
}


.row {
  margin-right: 0px !important;
}

.alert-warning {
  margin: 0 1rem 0 0 !important;
}

img.img-partners {
  padding: 20px;
  max-height: 200px;
}

img.img-cards {
  padding: 2px;
}

img.main {
  width: 56%;
}

img.side {
  width: 47%;
}

img.top-menu {
  width: 71%;
}

.test-other-configurations label {
  width: 100%;
}

.btn:hover,
a:hover {
  opacity: 0.8
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.title-row {
  margin-top: 1% !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #62C4E4 !important;
  border-color: #62C4E4 !important;
  border-radius: 12px !important;
  min-width: 120px;

  text-transform: uppercase;
  font-weight: bold !important;

  -webkit-box-shadow: 3px 3px 9px -2px rgb(0 0 0 / 18%);
  -moz-box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.18);
  box-shadow: 3px 3px 9px -2px rgb(0 0 0 / 18%);
}

.btn-secondary {
  color: #62C4E4 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-weight: bold !important;
}

.btn-save {
  width: 16% !important;
}

.center-row {
  justify-content: space-around;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.mt-row {
  margin-top: 1% !important;
}

.links-container {
  width: 100%;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  right: 0;
  margin-right: 27px;
}

.form-group {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 12px;
  padding-top: 0;
}

.form-group-progradum {
  position: relative;
  overflow: hidden;
  padding: 12px;
  padding-top: 0;
}

h1.profile {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.form-group input {
  width: 100%;
  border-color: transparent;
  border-bottom: 1.5px solid #000;
}

.form-group-progradum input {
  width: 100%;
  border-color: transparent;
  border-bottom: 1.5px solid #000;
}

.table-container {
  position: relative;
  width: 100%;
  margin: 0;
  max-width: 100%;
}

.form-group input:focus-visible {
  outline: none;
}

.form-group-progradum input:focus-visible {
  outline: none;
}

.small-info {
  font-size: 14px !important;
  font-weight: bold;
}

input[type=checkbox],
input[type=radio] {
  float: left;
  width: 25px;
  margin-top: 5px;
}

input[type=checkbox]:hover {
  cursor: pointer;
}

.float-right {
  float: right
}

.float-left {
  float: left !important;
}

.text-align-left {
  text-align: left !important;
}

.p-inline-block-without-margin {
  display: inline-block !important;
  margin-bottom: 0px !important;
}

.inline-block {
  display: inline-block !important;
}

.col-title-analyses {
  width: 21% !important;
}

.info-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 1px solid #eee;
}

hr.title-underline {
  height: 10px !important;
  color: #62C4E4;
  opacity: 1;
  margin-top: 0;
  max-width: 50px;
  width: 100%;
}

.form-submit {
  margin-top: 5%;
}

.individual {
  padding: 1%;
}

.full-container .subcontainer {
  position: relative;
  overflow: hidden;
  width: 50%;
  float: left;
  height: 100vh;
}

.title-side {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
}

input:-internal-autofill-selected {
  background-color: transparent;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.logged-navbar {
  float: right;
  width: 100%;
  max-width: 100%;
  padding-left: 365px;
}

.v-sidebar-menu {
  max-width: 225px !important;
}

.vsm--link_hover {
  background: #2c3e50 !important;
}

.vsm_collapsed {
  max-width: 50px !important;
}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
  height: 20px !important;
  width: 20px !important;
}

.v-sidebar-menu,
.v-sidebar-menu .vsm--toggle-btn {
  background-color: #2c3e50 !important;
}

.vsm_collapsed .vsm--item {
  background-color: #2c3e50 !important;
  max-width: 175px !important;
}

.vsm_collapsed .vsm--item a {
  max-width: 175px !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  background-color: #2c3e50 !important;
  max-width: 125px !important;
}

ul li {
  list-style: none;
}

table p {
  margin: 0 !important;
}

.v-sidebar-menu img.header-image {
  margin: 5px;
}

.v-sidebar-menu .vsm--title {
  font-weight: bold;
}

.collapsed-sidebar {
  max-width: 99% !important;
}

.no-margin {
  margin: auto !important;
}

.main-container {
  position: relative;
  overflow: hidden;
  max-width: 88%;
  float: right;
  width: 100%;
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 6px 0px 0px 0px #62c4e4 inset !important;
  box-shadow: 6px 0px 0px 0px #62c4e4 inset !important;
}

.black {
  color: #000 !important;
}

.form-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.form-group label {
  float: left;
  font-weight: bold;
  text-align: left;
}

.form-group-progradum label {
  float: left;
  font-weight: bold;
  text-align: left;
}

.company-buttons {
  margin-left: -9rem !important;
}

table.table td {
  vertical-align: middle;
}

.html_container {
  position: relative;
  overflow: hidden;
  margin-top: 3px;
  background-color: #61616112;
}

table.table .html_container p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.thead-dark {
  background-color: #000;
  color: #fff;
}

.thead-light {
  background-color: #e9ecef;
  color: #000;
}

.form-row {
  position: relative;
  overflow: hidden;
}

.category-result {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

table.table .tags-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

legend.col-form-label {
  float: left;
  text-align: left;
  font-weight: bold;
  color: #000;
}

.custom-control {
  position: relative;
  overflow: hidden;
  float: left;
}

.custom-control input[type="radio"] {
  width: 15px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

.custom-radio {
  width: 10%;
  float: left;
}

.custom-checkbox {
  margin-right: 15px;
}

#table-style-variant {
  float: left;
  margin-top: 7px;
}

.align-item-center {
  margin: 0 auto;
}

.align-item-left {
  float: left;
}

.align-item-right {
  float: right;
}

.more-correct-answer {
  display: none;
}

.svg-inline--fa {
  color: #62c4e4 !important;
}

.v-sidebar-menu .vsm--link {
  color: #62c4e4 !important;
}

.clean-answers-values {
  display: none;
}

#table-settings {
  display: none;
  border: 1px solid #eee;
  margin-bottom: 2%;
}

button.open {
  display: block;
  padding: 1%;
}

button.close {
  display: none;
  padding: 1%;
}

.test-random-options label {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 1%;
}

.btn:focus {
  box-shadow: none !important;
}

input.inline {
  display: inline-block;
  float: left;
  width: 50%;
  margin-left: 2%;
}

#manual_percentages_configuration {
  display: none;
}

#manual_percentages_configuration input {
  width: 70%;
  float: left;
}

.table th {
  color: #2c3e50 !important;
}

.right-short-input {
  width: 100%;
  max-width: 35% !important;
  float: left;
}

.question-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: none;
  text-align: left;
  border: 1px solid #eee;
}

.question-container:first-child {
  display: block;
}

.question-container .content {
  background: #eee;
  margin-bottom: 3%;
  margin-left: 2%;
  margin-right: 2%;
}

.question-container .content .code {
  position: relative;
  overflow: hidden;
  margin-left: 3%;
  padding-top: 2%;
}

.question-container .buttons {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 50%;
  padding-top: 3%;
  margin: 0 auto;
}

.results-filter-search-title {
  width: 100%;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}

.question-container .title {
  padding: 7px;
  margin-left: 1.5%;
}

.question-container .list {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: 2%;
}

.cookie-law {
  right: 0px !important;
  left: 2%;
  background-color: #BFBFBF;
}

.bg-blue-700 {
  background-color: #4fc0e8 !important;
}

.completed-icon-yes {
  color: green !important;
}

.completed-icon-no {
  color: red !important;
}

.profileLevelTag {
  width: 49%;
  margin-top: 1%;
  font-size: 22px;
  font-weight: bold;
  border: 0;
  border-radius: 26px;
}

.profile-title {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.row-update-profile {
  justify-content: center;
}

.analysis-profile-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 1.5px solid #d2cccc;
  margin-bottom: 2%;
}

.competences-personality-container {
  padding: 0 !important;
  margin-left: 0 !important;
  overflow: hidden;
  margin-bottom: 2%;
}

.competences-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 1.5px solid #d2cccc;
  margin-bottom: 2%;
}

.company-uploaded-cv-class {
  margin-left: 3% !important;
}

.color-picker-div {
  margin-bottom: 30% !important;
}

.personality-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: 1.5px solid #d2cccc;
  margin-bottom: 2%;
}

.analysis-profile-container h5 {
  padding: 10px;
}

.analysis-profile-container .profile-info-container {
  text-align: left;
  width: 48%;
  margin-left: 12px;
  margin-right: 12px;
}

.analysis-profile-container ul li {
  list-style: outside;
}

.personal-data-container {
  max-width: 90%;
  text-align: left;
  margin: 0 auto;
  margin-left: 25px;
}

.analysis-profile-container table.table {
  width: 100%;
  max-width: 97%;
  margin: 0 auto;
  margin-bottom: 2%;
}

.profile-container .profile-definition {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 3%;
  margin-bottom: 6%;
}

.missingColor {
  background: #eb8934;
}

.titleClass {
  margin-bottom: 0% !important;
  margin-top: 1% !important;
}

.technologyAvatar {
  width: 43px !important;
  display: initial !important;
  padding: inherit;
}

.imageAvatar {
  padding: 2%;
}

.levelStyle {
  border-radius: 12px;
  display: inline-flex;
  padding: 4px;
  font-size: small;
  font-weight: bold;
}

.percentajeStyle {
  border-radius: 12px;
  display: inline-flex;
  padding: 7px;
  margin-top: 10%;
}

.arrow-1 {
  width: 100px;
  height: 30px;
  display: flex;
}

.arrow-1:before {
  content: "";
  background: currentColor;
  width: 15px;
  clip-path: polygon(0 10px, calc(100% - 15px) 10px, calc(100% - 15px) 0, 100% 50%, calc(100% - 15px) 100%, calc(100% - 15px) calc(100% - 10px), 0 calc(100% - 10px));
  animation: a1 1.5s infinite linear;
}

@keyframes a1 {

  90%,
  100% {
    flex-grow: 1
  }
}

.lowHoursColor {
  color: #6fb63c;
}

.mediumHoursColor {
  color: #d3b300;
}

.highHoursColor {
  color: #da5213;
}

.veryHighHoursColor {
  color: #ff0000;
}

.item-capsule {
  list-style: none;
  padding: 7px;
  background: #13c7d479;
  border-radius: 12px;
  display: inline-flex;
  margin-right: 0.5em;
  margin-bottom: 0px ! important;
}

.item-capsule-capsule-data {
  padding: 0 10px;
  line-height: 1;
  color: rgb(0, 0, 0);
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.tab-title-modal {
  font-weight: 900 !important;
  text-align: left;
  border-bottom: 5px solid;
  padding-bottom: 10px;
  margin-bottom: 35px;
  line-height: 1, 2;
  color: #13283B;
}

.tdSmall {
  width: 200px;
}

.tdMedium {
  width: 350px;
}

.tdBig {
  width: 500px;
}

.tdXSmall {
  width: 100px;
}

.difficultyLevelTag {
  border-radius: 0.25rem;
}

.margin-left-6 {
  margin-left: 6% !important;
}

.margin-top-1 {
  margin-top: 1% !important;
}

.margin-top-1-5 {
  margin-top: 1.5% !important;
}

.margin-top-1-8 {
  margin-top: 1.8% !important;
}

.margin-top-2 {
  margin-top: 2% !important;
}

.margin-top-3 {
  margin-top: 3% !important;
}

.margin-top-4 {
  margin-top: 4% !important;
}

.margin-top-5 {
  margin-top: 5% !important;
}

.margin-top-10 {
  margin-top: 10% !important;
}

.margin-top-15 {
  margin-top: 15% !important;
}

.margin-top-45 {
  margin-top: 45% !important;
}

.ProseMirror {
  border: 1.5px solid #000;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 0.75em;
  background-color: #61616112;
  color: #000;
  text-align: left;
  border-radius: 0.25rem;
}

.ProseMirror p {
  margin-bottom: 1px !important;
  margin-top: 1px !important;
}

.no-results-msg {
  text-align: center;
  margin-top: 17%;
}

.no-results-msg-interview {
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%;
}

.no-results-profile-msg {
  text-align: center;
  margin-top: 6%;
  margin-bottom: 4%;
}

.ti-input {
  border-radius: 0.25rem;
  border: 1px solid #ced4da !important;
  height: 39px;
}

.ti-tag,
.ti-tag-user-test {
  background-color: #2c3e50 !important;
  color: #62c4e4 !important;
  font-weight: bold !important;
  border-radius: 0.25rem;
}

.ti-tag-user-test {
  font-size: 13px;
}

ul.ti-tags {
  position: relative;
  overflow: hidden;
  width: 100%;
}

ul.ti-tags.small li {
  font-size: 11px;
  float: left;
  padding: 3px;
  margin-left: 3px;
}

.ti-autocomplete {
  position: relative !important;
  border-radius: 0.25rem;
  text-align: left;
}

li.ti-item {
  background-color: #2c3e50;
  color: #62c4e4 !important;
}

li.ti-item:hover {
  background-color: transparent !important;
}

.vue-tags-input {
  max-width: 100% !important;
}

li.inline-tags {
  float: left;
  padding: 5px;
  margin-left: 10px;
}

li.inline-tags:first-child {
  margin-left: 0px !important;
}

ul.inline-tags,
ul.inline-tags-test {
  position: relative;
  overflow: hidden;
  padding-left: 0;
  padding-bottom: 1%;
}

li.inline-tags-test {
  display: inline-block;
  padding: 5px;
  margin-left: 10px;
}

ul.inline-tags-test {
  text-align: center;
  width: 100%;
}

.profile-card {
  max-width: 100% !important;
  margin: 5px !important;
}

.profile-card p {
  margin: 0;
  padding: 5px;
}

.align-items-left {
  text-align: left;
}

.align-items-right {
  text-align: right;
}

.align-items-center {
  text-align: center;
}

.card {
  align-items: center;
  background: #fff;
  border-radius: .3em;
  border: 0.0625em solid #fff;
  box-shadow: 0 0 1.7em 0 #abbaab;
  display: flex;
  flex-direction: column;
  max-width: 18em;
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 12%;
  margin-bottom: 4%;
}

.cards {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  margin-bottom: 4rem;
}

.cards-ranking {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  margin-bottom: 4rem;
}

.card-info {
  padding: 1.2em 1em;
  position: relative;
  text-align: center;
  width: 100%;
}

.card-image {
  margin: auto;
  width: 100%;
  height: 122%;
  max-width: 200px;
  position: relative;
}

.card--is-edit .card-image {
  margin-bottom: 1em;
}

.card-avatar {
  display: block;
  height: auto;
  width: 100%;
  transform: scale(1.05, 1.05);
}

h5.card-title {
  margin-top: 7%;
}

.card-avatar-analysis-profile {
  display: block;
  height: auto;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 7%;
  width: 100%;
}

#title-analysis-profile {
  margin-top: 3%;
}

.subtitle-analysis-profile {
  float: left;
}

.card-avatar--circle {
  border-radius: 50%;
}

.analysis-card-avatar--circle {
  border-radius: 50%;
}

.card-name {
  font-size: 1.8em;
  margin-bottom: .2em;
}

.card-email {
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 0;
}

.card-button {
  align-items: center;
  border: 0;
  cursor: pointer;
  display: flex;
  user-select: none;
}

.card-button>.material-icons {
  font-size: inherit;
}

.card-button[disabled] {
  cursor: no-drop;
  opacity: .6;
  pointer-events: none;
}

.card-expander {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 .75em 0 #abbaab;
  bottom: 0;
  color: coral;
  font-size: 1.5em;
  left: 50%;
  padding: .5em;
  position: absolute;
  transform: translate3d(-50%, 50%, 0);
  transition: all .175s ease-in-out;
}

.card-expander:hover,
.card-expander:focus {
  box-shadow: 0 .3em 2em 0 #abbaab;
}

.card-expander--is-open {
  background: coral;
  color: white;
  transform: translate3d(-50%, 50%, 0) rotate(-225deg);
}

.card-expander--is-open:hover,
.card-expander--is-open:focus {
  box-shadow: .15em -.3em 2em 0 #abbaab;
}

.card-edit {
  background: transparent;
  border-radius: .2em;
  color: #bbb;
  padding: .25em;
  position: absolute;
  right: .5em;
  top: .5em;
}

.card-edit:hover,
.card-edit:focus {
  background: #eee;
  color: coral;
}

.card-shelf {
  max-height: 0;
  overflow: auto;
  transition: all .175s ease-in-out;
  width: 100%;
}

.card-shelf--is-open {
  margin-top: 1em;
  max-height: inherit;
}

.card--is-edit .card-shelf--is-open {
  margin-top: 0;
}

.card-content {
  padding: 1em;
}

.card-bio {
  margin-top: 0;
  width: 100%;
}

.card-bio:last-child {
  margin-bottom: 0;
}

.card-fields {
  display: flex;
  justify-content: center;
  padding: 7px;
}

.card-fields h1,
.card-fields h2 {
  color: #000;
}

.card-input {
  border-radius: .3em;
  margin: .25em;
  padding: .5em 1em;
  width: 100%;
}

.card-input--inline {
  display: inline-block;
  width: 50%;
}

@media (max-width: 991px) {
  .row {
    margin-right: 0px !important;
  }

  .col-sm-6,
  .col-sm-4,
  .col-sm-10,
  .col-sm-2,
  .col-sm-3 {
    float: left;
  }

  .col-sm-12 {
    position: relative;
    overflow: hidden;
  }

  #footer-img-form {
    display: none !important;
  }

  #login-header-image>img {
    display: none !important;
  }

  .login {
    position: relative;
    padding: 2rem;
    background-color: #fff !important;
    width: 80% !important;
    max-width: 80% !important;
    margin: 10% !important;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }

  .table-responsive-sm {
    overflow-x: auto;
  }
}

.top-menu {
  display: none;
}

.logged-navbar .top-menu {
  display: inherit;
}

.home h1 {
  font-size: 28px;
  font-weight: lighter !important;
  color: #fff;
}

.vsm--scroll-wrapper .vsm--item a,
.vsm--scroll-wrapper .vsm--item .vsm--icon {
  color: #fff !important;
}


.vsm--scroll-wrapper .vsm--item .vsm--link_active a,
.vsm--scroll-wrapper .vsm--item .vsm--link_active .vsm--icon,
.vsm--scroll-wrapper .vsm--item .vsm--link_active .vsm--title {
  color: #62c4e4 !important;
}


.vsm--toggle-btn .svg-inline--fa {
  color: #fff !important;
  font-size: 25px;
}

.logged-navbar.collapsed-sidebar {
  padding-left: 85px !important;
  max-width: 100% !important;
}

#app>.bg-white {
  padding-left: 0px;
}

#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0.7rem;
}

.dashboard {
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 0rem !important;
}

#app .logged-navbar.collapsed-sidebar~.bg-white {
  padding-left: 85px;
}

#app h4 {
  font-weight: 900 !important;
  text-align: left;
  border-bottom: 5px solid;
  padding-bottom: 10px;
  margin-bottom: 35px;
}

.chat-container {
  background-color: white;
  height: 100vh;
  padding-top: 2em;
}

.col-sm-6 {
  padding: 20px;
}

img.main {
  max-width: 415px;
}

.table-responsive-sm {
  -webkit-box-shadow: 3px 3px 9px -2px rgb(0 0 0 / 18%);
  -moz-box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.18);
  box-shadow: 3px 3px 9px -2px rgb(0 0 0 / 18%);
  border-radius: 30px;
  padding: 30px;
  border: 1px solid rgb(0 0 0 / 3%);
  padding-bottom: 5px;
  padding-top: 20px;
}

.table-responsive-sm tbody tr:last-child td {
  border: 0 !important;
}

.table-responsive-sm thead th {
  border-bottom: 3px solid #dee2e6 !important;
}

.table-responsive-sm .table td {
  padding: 3px;
}

.progress {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1%;
  height: 1.5rem !important;
  font-weight: bold !important;
}

.progress-bar-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #d2cecec7;
  border-radius: 9px;
}

.analysis-spinner {
  width: 3rem;
  height: 3rem;
}

.text-loading-msg {
  font-size: 2rem;
}

.full-progress-bar {
  max-width: 100%;
}

.full-progress-bar-analysis {
  max-width: 94%;
  margin-left: 5%;
}

.full-progress-bar-chatbot {
  max-width: 54.3%;
  margin-left: 23%;
}

.progress-bar-chatbot-message {
  width: 100%;
  padding-left: 23%;
  text-align: left;
}

.full-progress-bar-chatbot-interview {
  max-width: 54.3%;
  align-content: center;
}

.full-progress-bar-chatbot-interview-message {
  margin-left: 23%;
  margin-top: 3%;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #2c3e50 !important;
  border-color: #2c3e50 !important;
}

.page-link {
  color: #2c3e50 !important;
}

.qkb-board {
  width: 100% !important;
  left: 0 !important;
  max-width: 890px;
  top: 190px !important;
  bottom: 0 !important;
  height: 100% !important;
  max-height: 70%;
  border-radius: 0 !important;
  position: fixed !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.progress-bar {
  background-color: #4FC0E8 !important;
  overflow: visible !important;
}

.test-navigation {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.test-navigation ul {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: contents;
}

.test-navigation ul li {
  float: left;
}

.profile-progress-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.profile-progress-container .circle-container {
  float: left;
}

#logoImgFileInput {
  margin-top: 10%;
}

label img.info {
  width: 16px;
  margin-left: 7px;
  padding-bottom: 5px;
}

.profile-ranges {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.profile-ranges p {
  margin: 0;
  font-weight: bold;
  color: #2c3e50;
  font-size: 15px;
  text-transform: uppercase;
}

.profile-ranges div {
  border-left: 2px solid #2c3e50;
  border-right: 2px solid #2c3e50;
}

.profile-ranges .trainee {
  width: 25%;
  float: left;
  border-left: 4px solid #2c3e50;
}

.profile-ranges .junior {
  width: 25%;
  float: left;
}

.profile-ranges .middle {
  width: 25%;
  float: left;
}

.profile-ranges .senior {
  width: 25%;
  float: left;
  border-right: 4px solid #2c3e50;
}

.btn-chatbot {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 50px;
}

.btn-chatbot-relative {
  position: relative;
}

.restart-chatbot {
  position: absolute;
  bottom: 3%;
  left: 0
}

#chat-container {
  height: 87.8%;
}

tr.alert-success td .svg-inline--fa {
  color: #0f5132 !important;
}

tr.alert-danger td .svg-inline--fa {
  color: #842029 !important;
}

table.table ul.inline-tags {
  margin-bottom: 0% !important;
}

.paginationSelectorTable {
  float: left;
  height: 67%;
}

#click-info-msg {
  margin-bottom: 2% !important;
}

.cross-button {
  float: right;
  padding-top: 0px !important;
}

.cross {
  color: black !important;
}

.chart-container h6.title {
  text-align: center;
  font-weight: bold;
}

.chart-container .col-sm-6 {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 50%;
  float: left;
}

.chart-container .col-sm-6 div:first-of-type {
  width: 100%;
  max-width: 65%;
  margin: 0 auto;
  float: none;
}

.paragraph {
  position: relative;
  overflow: hidden;
  text-indent: 30px;
}

.modal-body .question-container {
  background-color: #eeeeee8a;
  padding: 15px;
}

.modal-body .question-container div.alert {
  margin: 0 !important;
  padding: 5px !important;
}

.clickable table.table tr:hover {
  opacity: 0.9;
  cursor: pointer;
}

.chat-button-container {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  margin-right: 1%;
  margin-bottom: 1%;
  z-index: 1;
}

.recalculate-button-container {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 3%;
  margin-bottom: 2%;
  z-index: 1;
}

.recalculate-button {
  color: #b7e9f3;
  background-color: #13283b;
  font-size: 20px;
  border-color: #13283b !important;
  height: 85px;
  width: 120px;
  border-radius: 22px;
}

.recalculating-button {
  color: #b7e9f3;
  background-color: #13283b;
  font-size: 20px;
  border-color: #13283b !important;
  height: 85px;
  width: 145px;
  border-radius: 22px;
}

.vue-bot {
  position: fixed;
}

.chat-button-container button {
  background-color: #2c3e50;
  width: 56px;
  height: 56px;
  display: block;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 50%;
  color: #fff;
  fill: #fff;
  cursor: pointer;
  box-shadow: 0 3px 5px 0 rgb(0 0 0 / 15%);
  transition: opacity .2s linear;
}

.chat-button-container button svg {
  fill: #fff;
  color: #fff;
  display: block;
  position: absolute;
  top: 53%;
  left: 55%;
  width: 27px;
  height: auto;
  margin: -11px 0 0 -13px;
  line-height: 1em;
  vertical-align: middle;
}

.btn-info .svg-inline--fa {
  color: #2c3e50 !important;
  font-size: 19px;
}

.btn-cancel {
  line-height: 2.5 !important;
}

.container {
  position: relative;
  overflow: hidden;
}

.profile-analyzed {
  position: absolute !important;
  bottom: 0;
  right: 0;
  left: 0;
}

.align-left {
  text-align: left !important;
}

.alert-percentage {
  color: red;
}

.btn-danger .svg-inline--fa {
  color: red !important;
}

#home-header-title {
  left: 0;
  right: 62%;
  margin-left: auto;
  margin-right: auto;
  top: 20%;
  margin-top: 5%;
}

#home-header-image {
  margin-top: 18%;
}

#header-title-logo {
  max-width: 534px;
  width: 82%;
}

#header-image-logo {
  max-width: 628px;
}

.text-left {
  text-align: left;
  margin-left: 13%;
}

.home-mid {
  padding-bottom: 4%;
  background-color: #4FC0E8;
}

.partners {
  padding-top: 5%;
  width: 61% !important;
  margin-left: 22%;
  margin-right: 22%;
}

.home-description-upper,
.home-description-lower {
  width: 61% !important;
  float: left;
  margin-top: 3% !important;
  margin-left: 22%;
  margin-right: 22%;
}

.home-description-upper {
  z-index: 10;
  margin-bottom: 5%;
}

.desc-text {
  font-size: 2rem;
  float: left;
  text-align: left;
}

.image-grid-element img {
  width: 28%;
}

.button-info {
  margin-top: 4%;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card-grid-element {
  display: inline
}

.card-grid-element img {
  width: 50%;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.image-grid li:nth-child(3) img {
  margin-top: 8%;
}

.image-grid-element {
  display: inline
}

.red-btn {
  background-color: #FE5000 !important;
  border-color: #FE5000 !important;
}

.username,
.password,
.email,
.password-repeat {
  width: 100%;
  border-radius: 25px;
  border: 1.5px solid #000;
  text-indent: 10px;
  border-color: black !important;
}

.register {
  position: relative;
  padding: 2rem;
  background-color: #fff !important;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  margin-top: 20%;
  margin-bottom: 14%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.register h1 {
  color: #000;
  font-size: 25px;
}

#register {
  position: relative;
}

#register-title {
  margin-bottom: 12%;
  text-align: center;
}

#register-line {
  border: none;
  border-top: 1px dashed #f00;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 50%;
}

#login-content {
  display: flex;
  align-items: center;
}

#login-content p {
  margin-top: 16px;
  width: 177%;
}

#login-btn {
  width: 100%;
  text-align: left;
}

.register-mid {
  padding-bottom: 3%;
  margin-top: 7%;
  background-color: #4FC0E8;
}

#label-agreement-checkbox {
  text-align: center !important;
}

#agreement-checkbox {
  border-radius: 25px;
  border: 1.5px solid #000;
}

.request-reset-password {
  position: relative;
  padding: 2rem;
  background-color: #fff !important;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  margin-top: 20%;
  margin-bottom: 46%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.request-reset-password h1 {
  color: #000;
  font-size: 25px;
}

.contact-form {
  position: relative;
  padding: 2rem;
  background-color: #fff !important;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  margin-top: 10%;
  margin-bottom: 16.65%;
  margin-right: 40%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.full-container-login {
  background-color: #F2F2F2;
  width: 100%;
}

.login {
  position: relative;
  padding: 2rem;
  background-color: #fff !important;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  margin-top: 10%;
  margin-right: 40%;
  margin-bottom: 20%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.login h1 {
  color: #000;
  font-size: 25px;
}

.form-image-logo {
  position: absolute;
  max-width: 628px !important;
  margin-top: 2%;
  right: 4%;
  display: inline-block;
}

#login {
  position: relative;
}

.form-title {
  margin-bottom: 12%;
  text-align: center;
}

#login-line {
  border: none;
  border-top: 1px dashed #f00;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 50%;
}

#sign-up-content {
  display: flex;
  align-items: center;
}

#sign-up-content p {
  margin-top: 16px;
  width: 177%;
}

#signup-btn {
  width: 100%;
  text-align: left;
}

.login-mid {
  padding-bottom: 3%;
  margin-top: 11%;
  background-color: #4FC0E8;
}

#remember-checkbox {
  border-radius: 25px;
  border: 1.5px solid #000;
}

#app {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F2F2F2 !important;
  height: 100vh;
}

li a.router-link-exact-active {
  text-decoration: underline;
}

nav {
  min-height: 72px;
}

.navbar {
  background-color: #F2F2F2;
  display: block !important;
}

.navbar .nav-item-home .nav-link {
  color: #13283B !important;
  font-size: 18px;
  font-weight: bold;
}

.navbar .nav-item-user .nav-link {
  color: #13283B !important;
  font-size: 18px;
}

.navbar .nav-item-user-submenu .nav-link-user-submenu {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

.suite-tools-submenu {
  color: #13283B !important;
  font-size: 18px;
  font-weight: bold;
}

.home-buttons {
  font-size: 16px !important;
}

.navbar-nav {
  float: right !important;
}

.header-image {
  margin-left: 30px;
}

.submenu {
  background-color: #4FC0E8;
  height: 40px;
  font-size: 20px;
}

.dropdown-toggle {
  color: #13283B !important;
}


li.nav-item-user {
  margin-left: 5em;
  margin-right: 5em;
}

li.nav-item-user-submenu {
  margin-left: 5em;
  margin-right: 5em;
}

div#subnavbarNav {
  padding-left: 30px;
}

.primary-btn {
  padding: 0.6em 2em;
  border-radius: 26px;
  color: #000000;
  background-color: #fff;
  font-size: 1.1em;
  border: 0;
  cursor: pointer;
  margin: 1em;
  transition-duration: 0.4s;
}

.primary-btn:hover {
  color: #fff;
  background-color: #4FC0E8;
}

.primary-btn-user {
  padding: 0.6em 2em;
  border-radius: 26px;
  color: #000000;
  background-color: #4FC0E8;
  font-size: 1.1em;
  border: 0;
  cursor: pointer;
  margin: 1em;
  transition-duration: 0.4s;
}

.primary-btn-user:hover {
  background-color: #51c8f3;
}

.nameCircle {
  border-radius: 50%;
  width: 61px;
  height: 61px;
  font-size: 2rem;
  color: #000000;
  text-align: center;
  margin-top: 14px;
  right: 40%;
  position: relative;
  right: 164%;
  padding-top: 7%;
  background-color: #4FC0E8;
}

.user-dropdown {
  margin-left: auto;
  background-color: #b7e9f3;
  border-radius: 26px !important;
}

#chartGraphBullet {
  width: 94%;
  height: 500px;
  margin-bottom: 6%;
  margin-left: 3%;
  margin-right: 3%;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.vue-form {
  max-width: 500px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.vue-form ul {
  padding: 0px;
}

.vue-form fieldset {
  margin: 24px 0 0 0;
  border: none;
}

.vue-form legend {
  padding-bottom: 10px;
  font-size: 2rem;
  border-bottom: 1px solid #ecf0f1;
}

.vue-form div {
  position: relative;
  margin: 20px 0;
}

.vue-form h4,
.vue-form .label {
  font-size: 1.3rem;
  color: #94aab0;
  margin-bottom: 10px;
}

.vue-form .label {
  display: block;
}

.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select,
.vue-form legend {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form select {
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  -webkit-box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}

.vue-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}

.vue-form input[type="submit"] {
  border: none;
  background: #2c3e50;
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}

.vue-form input[type="submit"]:focus {
  outline: none;
  background: #2b3e51;
}

.vue-form input[type="submit"]:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.vue-form .error-message {
  height: 35px;
  margin: 0px;
}

.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}

.vue-form .error {
  border-color: #e94b35 !important;
}

.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}

.counter {
  padding-right: 0px !important;
  text-align: right !important;
}

.text-normal {
  color: #ffffff;
}

.footer {
  background-color: #13283B;
}

#footer-img-form {
  position: absolute;
  left: 63%;
  width: 28%;
}

#footer-sign {
  color: #ffffff;
  margin-top: 3%;
  margin-left: -5%;
  margin: 0;
  font-size: 24px;
}

.footer-links a {
  float: left;
  margin-left: 17%;
  margin-bottom: 4%;
  color: #fff;
}

.noMargin {
  padding-top: 0% !important;
}

.noMarginP {
  margin-bottom: 0% !important;
}

#contact-title {
  margin-left: 27%;
  z-index: 10;
}

#footer-title {
  padding-left: 16%;
}

.nav-pills li a {
  background-color: transparent;
  color: #4ec1e9;
}

.nav-pills li a.active {
  background-color: #4ec1e9 !important;
  color: #fff !important;
}

.form-check-input:checked {
  background-color: #4ec1e9 !important;
  border-color: #4ec1e9 !important;
}

.level-name ul.nav li:nth-of-type(1) a {
  color: #5ABBD1 !important;
}

.level-name ul.nav li:nth-of-type(2) a {
  color: #AFE89E !important;
}

.level-name ul.nav li:nth-of-type(3) a {
  color: #E8E379 !important;
}

.level-name ul.nav li:nth-of-type(4) a {
  color: #E89292 !important;
}

.level-name ul.nav li:nth-of-type(1) a.active {
  color: #fff !important;
  background-color: #67CAE9 !important;
}

.level-name ul.nav li:nth-of-type(1) a.active>.svg-inline--fa {
  color: #fff !important;
}

.level-name ul.nav li:nth-of-type(2) a.active {
  color: #000 !important;
  background-color: #B9FCA9 !important;
}

.level-name ul.nav li:nth-of-type(2) a.active>.svg-inline--fa {
  color: #000 !important;
}

.level-name ul.nav li:nth-of-type(3) a.active {
  color: #000 !important;
  background-color: #F9F682 !important;
}

.level-name ul.nav li:nth-of-type(3) a.active>.svg-inline--fa {
  color: #000 !important;
}

.level-name ul.nav li:nth-of-type(4) a.active {
  color: #fff !important;
  background-color: #FFADAD !important;
}

.level-name ul.nav li:nth-of-type(4) a.active>.svg-inline--fa {
  color: #fff !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent !important;
}

.trainee-selected {
  color: #5ABBD1 !important;
}

.junior-selected {
  color: #AFE89E !important;
}

.middle-selected {
  color: #E8E379 !important;
}

.senior-selected {
  color: #E89292 !important;
}

.trainee-progress-selected {
  text-align: right;
  padding-right: 7px;
  color: #000000 !important;
  background: #5ABBD1 !important;
}

.junior-progress-selected {
  text-align: right;
  padding-right: 7px;
  color: #000000 !important;
  background: #AFE89E !important;
}

.middle-progress-selected {
  text-align: right;
  padding-right: 7px;
  color: #000000 !important;
  background: #E8E379 !important;
}

.senior-progress-selected {
  text-align: right;
  padding-right: 7px;
  color: #000000 !important;
  background: #E89292 !important;
}

#footer-img {
  width: 33px;
  float: left;
  margin-left: 1em;
}

.logo {
  padding: 0px !important;
}

.text-logo {
  position: relative;
}

.text-title {
  float: right;
  margin-right: 1em !important;
  margin-bottom: 0px !important;
  margin: 0;
  color: #ffffff;
  width: auto;
  font-size: 24px;
}

.footer-title {
  float: right;
  margin-right: 0em !important;
  margin-bottom: 0px !important;
  color: #ffffff;
  width: auto;
  font-size: 24px;
}

.input-first {
  width: 80% !important;
}

.input-competence {
  width: 100% !important;
}

.title-first {
  font-weight: bold !important;
  margin-bottom: 0% !important;
  margin-right: 1% !important;
  font-size: 18px !important;
}

.title-groups {
  font-weight: bold !important;
  margin-bottom: 0% !important;
  margin-right: 1% !important;
  margin-left: 1% !important;
  font-size: 18px !important;
}

.title-groups-without-margin {
  font-weight: bold !important;
  font-size: 18px !important;
}

.input-weight {
  width: 10% !important;
}

.input-num-levels {
  width: 25% !important;
}

.title-weight {
  font-weight: bold !important;
  margin-bottom: 0% !important;
  margin-right: 2% !important;
  margin-left: 10% !important;
  font-size: 18px !important;
}

.title-weight-group {
  font-weight: bold !important;
  margin-bottom: 0% !important;
  margin-right: 2% !important;
  margin-left: 6% !important;
  font-size: 18px !important;
}

.title-weight-competence {
  font-weight: bold !important;
  margin-bottom: 0% !important;
  margin-right: 2% !important;
  margin-left: 17% !important;
  font-size: 17px !important;
}

.input-description {
  width: 79% !important;
  margin-left: -1% !important;
}

.input-description-competence {
  width: 99% !important;
  float: left !important;
}

.input-description-group {
  width: 99% !important;
  float: left !important;
}

.title-description {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-left: 10% !important;
  float: left !important;
  font-size: 18px !important;
}

.title-description-competence {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-right: 35% !important;
  float: left !important;
  font-size: 17px !important;
}

.title-description-group {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-right: 35% !important;
  float: left !important;
  font-size: 18px !important;
}

.select-level-form {
  margin-left: 2% !important;
  width: 98% !important;
}

.input-level-description {
  width: 71% !important;
  margin-left: 3% !important;
}

.input-competence-comments {
  width: 100% !important;
}

.input-final-comments-description {
  width: 90% !important;
  margin-left: 2.3% !important;
}

.input-level-description-competence {
  width: 99% !important;
  float: left !important;
}

.level-description {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-left: 16% !important;
  float: left !important;
}

.competence-comments {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  float: left !important;
}

.level-description-competence {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-right: 35% !important;
  float: left !important;
}

.title-levels {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-left: 10% !important;
  width: auto !important;
  font-size: 18px !important;
}

.form-group-margin {
  margin-left: 12% !important;
}

.margin-left-10 {
  margin-left: 10% !important;
}

.title-levels-competence {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  width: auto !important;
  font-size: 17px !important;
  float: left !important;
}

.title-min-level {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-left: 12% !important;
  width: auto !important;
  font-size: 17px !important;
}

.title-reached-level {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  width: auto !important;
  font-size: 17px !important;
}

.title-competences {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  float: left !important;
  width: auto !important;
  font-size: 18px !important;
}

.title-competences-evaluation {
  font-weight: bold !important;
  margin-bottom: 0.5% !important;
  margin-left: 10% !important;
  width: auto !important;
  font-size: 18px !important;
}

.title-level {
  font-weight: bold !important;
  margin-left: 11% !important;
  margin-right: 1% !important;
  margin-bottom: 0% !important;
  height: auto !important;
  float: left !important;
}

.title-level-competence {
  font-weight: bold !important;
  margin-left: 5% !important;
  margin-bottom: 0% !important;
  height: auto !important;
  float: left !important;
}

.title-competence {
  font-weight: bold !important;
  margin-left: 3% !important;
  margin-bottom: 0% !important;
  height: auto !important;
  float: left !important;
  font-size: 17px !important;
}

.input-level {
  width: 72% !important;
}

.input-level-competence {
  width: 100% !important;
}

.num-levels-select {
  width: auto !important;
}

.multiselect__content-wrapper {
  position: relative !important;
}

.stepper-box .content {
  margin: 1.2rem !important;
}

.formulate-form,
.toggle-form {
  margin-top: 5% !important;
}

.formulate-input {
  margin-top: 2% !important;
}

.formulate-input input {
  border-color: transparent !important;
  border-bottom: 1.5px solid #000 !important;
}

.formulate-input[data-classification='group']>.formulate-input-wrapper>.formulate-input-label {
  width: 33% !important;
  font-size: 1.3em !important;
  font-weight: 600 !important;
  width: 100% !important;
  background-color: #b7e9f3 !important;
  padding: 1%;
}

.formulate-input .formulate-input-label {
  font-weight: 600 !important;
  font-size: 1 em !important;
}

.disabled-class {
  opacity: 0.5;
  pointer-events: none;
}

.skill-title {
  float: left;
}

.ranking-title-value {
  float: left;
  font-size: 0.8rem;
}

.skill-title-col {
  display: flex;
  align-items: center;
}

.obligatory-asterisk {
  color: #FF0000;
}

.text-form-align {
  float: right;
}

.circle i {
  background-color: #62c4e4 !important;
}

.stepper-button {
  color: #FFFFFF !important;
  background-color: #2c3e50 !important;
}

.stepper-button-top {
  border: 2px solid #2c3e50 !important;
  color: #2c3e50 !important;
}

.formulate-input-element--button button {
  color: #62c4e4 !important;
}

.formulate-input-element--button button:active {
  background-color: #62c4e4 !important;
  border-color: #62c4e4 !important;
  color: #FFFFFF !important;
}

.text-form-align-left {
  float: left;
}

.add-button {
  width: auto !important;
  margin: 0 0 0 auto;
}

.start-button {
  width: auto !important;
  margin: 0 0 0 auto;
  float: right !important;
}

.radio-button-class {
  width: 100% !important;
}

.radio-button-class label {
  font-weight: normal !important;
}

.align-center-vert {
  margin-top: 1% !important;
  margin-bottom: 1% !important;
}

.align-center-vert-checkbox {
  margin-top: 28% !important;
  margin-bottom: 0% !important;
}

.add-competence-button {
  width: auto !important;
  margin: 0 auto;
}

.add-competence-to-group-button {
  width: auto !important;
  margin: 0 0 0 auto !important;
  float: right !important;
}

.div-competences {
  margin-left: 0 !important;
  margin-top: 2% !important;
}

.div-configuration {
  margin-top: 1% !important;
}

.col-input-width {
  width: 2.33% !important;
}

.order-title {
  margin-bottom: 0px;
  margin-top: 2px !important;
  float: left !important;
  display: inline-block !important;
}

.order-pos-form {
  margin: 0 auto !important;
  width: 96% !important;
}

.order-pos-form-bottom {
  margin-bottom: 5% !important;
}

.order-pos-form-top {
  margin: 3% !important;
  margin-top: 8% !important;
}

.formulate-input-wrapper {
  margin: 0 auto !important;
  width: 89% !important;
}

.formulate-input-label {
  float: left;
}

.formulate-input-element--submit button {
  border-color: #62c4e4 !important;
  background-color: #62c4e4 !important;
}

.formulate-input .formulate-input-element {
  max-width: none !important;
  margin: 0 auto !important;
  width: 100% !important;
}

.reset-button {
  width: auto !important;
  margin: 0 0 0 auto;
  background-color: #ED4646 !important;
  border-color: #ED4646 !important;
  float: right !important;
}

.save-configuration-button {
  width: auto !important;
  margin: 0 0 0 auto;
  background-color: #62C4E4 !important;
  border-color: #62C4E4 !important;
  margin-left: 5% !important;
  float: right !important;
}

.delete-button {
  background-color: #ED4646 !important;
  border-color: #ED4646 !important;
}

.save-changes-button {
  background-color: #13283B !important;
  border-color: #13283B !important;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.table-selection-button {
  float: left;
  margin-left: 2%;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.vc-chrome {
  position: relative;
  top: 35px;
  right: 0;
  z-index: 9;
}

.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
}

#name-profile {
  margin-left: 2%;
  width: 81% !important;
}

#name-profile-title {
  margin-left: 1%;
}

.col-top-edit-profile {
  margin-top: 4% !important;
}

.dot-tag-color {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.textarea-message {
  border-color: black !important;
  width: 79%;
  margin-top: 3%;
  text-indent: 10px;
  resize: none !important;
}

.current-color-color-picker {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.username-top-menu {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  color: #00627c;
  font-weight: 600;
  margin-right: 1em;
}

.question-title {
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 0% !important;
}

.reset-pass-mid {
  padding-bottom: 3%;
  margin-top: 16%;
  background-color: #4FC0E8;
}

.level-name .alert {
  padding: 1px !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.validated-question {
  background-color: rgb(225, 255, 225) !important;
}

.numberInput {
  width: 40px !important;
}

.breadcumb {
  text-decoration: none;
  z-index: 1;
  color: #FFFFFF;
}

.breadcumb:hover {
  text-decoration: none;
  z-index: 1;
  color: #62C4E4;
}

.final-breadcumb {
  text-decoration: none;
  z-index: 1;
  color: #FFFFFF;
  pointer-events: none;
}

.numberInputDecInc>p>a {
  text-decoration: none !important;
  color: #0075ff !important;
  width: 35px !important;
}

.secondNavbar {
  background-color: #13283B;
  box-sizing: content-box;
  height: auto !important;
}

.whiteColor {
  color: #FFFF;
  font-weight: 500;
  margin-left: 5%;
  font-size: 22px;
  text-align: left;
}

.white-color {
  color: #FFFFFF !important;
}

.black-color {
  color: #000000 !important;
}

.advanced-search-input,
.advanced-search-input input.advanced-input,
.advanced-search-input span {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif !important;
}

.advanced-search-input input.advanced-input::-webkit-input-placeholder {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif !important;
}

.advanced-search-input input.advanced-input:-ms-input-placeholder {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif !important;
}

.advanced-search-input input.advanced-input:-moz-placeholder {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif !important;
}

.advanced-search-input input.advanced-input::-moz-placeholder {
  font-family: Myriad Pro Regular, Avenir, Helvetica, Arial, sans-serif !important;
}

.createClass {
  background-color: #FFFF !important;
  background: border-box;
  border-radius: 26px !important;
  color: black !important;
  padding: inherit !important;
  margin-top: 0.2% !important;
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.h4-class {
  margin-bottom: 0 !important;
}

.h4-div-class {
  margin-left: 0 !important;
}

.p-text {
  text-align: right !important;
  font-weight: bold !important;
  margin-bottom: 0% !important;
  font-size: 17px !important;
  margin-top: 2% !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-10 {
  padding-left: 10% !important;
}

.p-weight {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 7.5% !important;
}

.p-weight-evaluators {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 10% !important;
}

.p-weight-evaluation {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 3.5% !important;
}

.p-weight-evaluation-competence {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 10.5% !important;
}

.p-weight-evaluation-competence-results {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 5.8% !important;
}

.p-weight-evaluation-group-results {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 4.5% !important;
}

.p-weight-evaluation-group {
  text-align: right !important;
  font-weight: bold !important;
  margin-top: 8% !important;
}

.p-percentage {
  margin-left: -2rem !important;
  margin-bottom: 0px;
  margin-top: 35% !important;
}

.p-percentage-evaluators {
  margin-top: 16% !important;
  margin-left: -1rem !important;
}

.p-percentage-competence {
  margin-top: 35% !important;
  margin-left: -2rem !important;
}

.p-percentage-group {
  margin-top: 20% !important;
  margin-left: -3rem !important;
}

.marginTopClass {
  margin-top: 0.5% !important;
}

.download-pdf-btn {
  background-color: #62C4E4 !important;
  background: border-box;
  margin-top: 4px;
  font-size: 16px !important;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

.download-softskill-btn {
  background-color: #62C4E4 !important;
  background: border-box;
  margin-top: -27px;
  font-size: 16px !important;
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

.titulo_op {
  float: left;
  font-weight: bold;
  text-align: left;
  color: #2c3e50 !important;
}

.content_op {
  padding-bottom: 1%;
  width: 100% !important;
  position: relative;
  overflow: hidden;
}

.div-dichotomic {
  float: left !important;
  margin-left: 5% !important;
}

.config-video-switch {
  margin-left: 2% !important;
  float: left;
}

.config-switch {
  margin-left: 2% !important;
  padding-top: 15%;
  float: left;
}

.level-name .svg-inline--fa {
  color: #FFD700 !important;
}

.download-pdf-btn svg,
.nav-link.active svg,
.icon-white {
  color: #fff !important;
}

.technologiesStyle {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.reports-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

img.card-profile--circle {
  width: 100%;
  max-width: 9%;
  margin: 0 auto;
  border-radius: 50%;
}

#chartUsersGraphByDate,
#chartUsersGraphCVByCompany,
#chartQuestionGraphByLevel,
#chartQuestionGraphByTechnology,
#chartUsersGraphCVByAdmins,
#chartExamsGraphByCompany,
#chartUsersGraphByCompany,
#chartUsersRegistredGraphByRole,
#chartQuestionsGraphByCompany,
#chartProfilesGraphByCompany,
#chartCVsGraphByCompany,
#chartValidatedQuestionsGraphByCompany,
#chartUsersGraphCVByLevels,
#chartTestResultsByCompanyDifficultyLevel,
#chartUsersGraphTotalCVByCompany,
#chartUserExamsGraphByMarks,
#chartUserBubbleGraphByQuestion,
#chartQuestionGraphByLanguage,
#chartQuestionGraphByTechnology,
#chartQuestionGraphByLanguageTechnology,
#chartRegisteredCertificationsByAreas,
#chartRegisteredCertificationsByAreasProfileLevels,
#chartRegisteredCertificationsByAreasProfiles {
  width: 94%;
  height: 500px;
  margin-bottom: 6%;
  margin-left: 3%;
  margin-right: 3%;
}

#area-selector-graph {
  margin-left: 13% !important;
}

.pad {
  width: 72% !important;
}

.horizontal-stacked-barr-chart {
  width: 94%;
  height: 500px;
  margin-bottom: 6%;
  margin-left: 3%;
  margin-right: 3%;
}

.graph-title {
  margin-top: 32%;
  margin-left: 30%;
}

.area_selector,
.multiselect-tag-graph,
#technology_selector_questions_lang {
  max-width: 94%;
  margin-left: 3%;
}

.text-loading {
  color: #51c8f3 !important;
  margin-top: 11%;
  margin-bottom: 15%;
}

.x-badger-accordion {
  background-color: #FFFFFF;
  border-bottom: 3px solid #FFFFFF;
  color: #62C4E4;
}

.badger-accordion__header {
  background-color: #FFFFFF;
  border-top: 3px solid #62C4E4;
  cursor: pointer;
  font-weight: 700;
  padding: 0.5em;
  text-transform: uppercase;
  transition: all ease-in-out 0.3s;
}

.badger-accordion__header:hover {
  background-color: #62C4E4;
  color: #FFFFFF;
}

.badger-accordion__panel {
  max-height: 75vh;
  overflow: hidden;
}

.badger-accordion__panel.-ba-is-hidden {
  max-height: 0 !important;
  visibility: hidden;
}

.badger-accordion__panel--inner {
  padding: 0.5em 2em;
}

.badger-accordion--initialized .badger-accordion__panel {
  transition: all ease-in-out 0.3s;
}

.tech-analysis-title {
  margin-left: 30px;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
}

#progressbar .trainee-active {
  color: #5ABBD1 !important;
}

#progressbar .junior-active {
  margin-left: 17%;
  color: #AFE89E !important;
}

#progressbar .middle-active {
  margin-left: 19%;
  color: #E8E379 !important;
}

#progressbar .senior-active {
  margin-left: 19%;
  color: #E89292 !important;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar #trainee:before {
  color: #000000;
  content: "0 %"
}

#progressbar #junior:before {
  color: #000000;
  content: "25 %"
}

#progressbar #middle:before {
  color: #000000;
  content: "50 %"
}

#progressbar #senior:before {
  color: #000000;
  content: "75 %"
}

#progressbar li.trainee-active:before,
#progressbar li.junior-active:before,
#progressbar li.middle-active:before,
#progressbar li.senior-active:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li.trainee-active:after,
#progressbar li.junior-active:after,
#progressbar li.middle-active:after,
#progressbar li.senior-active:after {
  content: '';
  height: 2px;
  position: absolute;
  left: 98%;
  top: 25px;
  z-index: 0;
}

#progressbar li.trainee-active:after {
  width: 301%;
}

#progressbar li.junior-active:after,
#progressbar li.middle-active:after {
  width: 340%;
}

#progressbar li.senior-active:after {
  width: 375%;
}

#progressbar li.trainee-active:before,
#progressbar li.trainee-active:after {
  background: #5ABBD1;
}

#progressbar li.junior-active:before,
#progressbar li.junior-active:after {
  background: #AFE89E;
}

#progressbar li.middle-active:before,
#progressbar li.middle-active:after {
  background: #E8E379;
}

#progressbar li.senior-active:before,
#progressbar li.senior-active:after {
  background: #E89292;
}

.redCapsuleClass {
  background: #FF8888 !important;
}

.greenCapsuleClass {
  background: #CFE8A7 !important;
}

.pending-status {
  color: #FFA500;
}

.archived-status {
  color: #008000
}

.active-status {
  color: #5ABBD1;
}

.eraseFormGroupStyles {
  border-color: initial !important;
  margin-left: -1rem !important;
  margin-top: auto !important;
}

.makeItBold {
  font-weight: bold;
}

.doubleTab {
  margin-left: 40px;
}

.tab {
  margin-left: 20px;
}

.avoidLineJump {
  display: inline-block;
}

.changeBackgroundToGrey {
  background-color: #F2F2F2;
}

.linksInBlue {
  color: #0d6efd;
  text-decoration: underline;
}

.red-color {
  color: red
}
</style>
