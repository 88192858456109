<template>
    <div class="col-sm-12">
        <div v-if="dataLoading" class="text-center text-loading">
            <b-spinner class="align-middle analysis-spinner"></b-spinner>
            <strong class="text-loading-msg"> Loading data...</strong>
        </div>
        <div v-else class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link v-if="from.path == '/business-process'" class="btn btn-secondary float-right"
                    :to="from"><font-awesome-icon icon="arrow-left" /> Back to Evaluation Process </router-link>
                <router-link v-else-if="from.path == '/softskills-evaluation-results-admin'"
                    :to="{ path: '/softskills-evaluation-results-admin?id=' + summary.id }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to {{
            nameEvaluation }}</router-link>
            </div>

            <div v-if="isAdmin" class="col-7 noMargin whiteColor">
                <div v-if="from.path == '/softskills-evaluation-results-admin'">
                    <router-link :to="{ path: '/modules' }" class="breadcumb"> Settings > </router-link>
                    <router-link :to="{ path: '/softskills-configuration' }" class="breadcumb"> BEI/360 Evaluation
                        Configuration > </router-link>
                    <router-link :to="{ path: '/softskills-evaluation-result-user' }" class="final-breadcumb"> {{
            nameUser }} {{ surnameUser }} Evaluation </router-link>
                </div>
                <div v-else-if="from.path == '/business-process'">
                    <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                    <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process >
                    </router-link>
                    <router-link :to="{ path: '/softskills-evaluation-result-user' }" class="final-breadcumb"> {{
            nameUser }} {{ surnameUser }} Evaluation </router-link>
                </div>
            </div>
        </div>

        <div class="col-sm-12 px-4 col-centered">
            <evaluation-result-user v-if="summary != null" :summary="summary" />
        </div>
    </div>
</template>

<script>
import EvaluationResultUser from "@/components/SoftSkillsComponent/EvaluationResultUser.vue";
import SoftskillsDataService from '@/services/SoftskillsDataService';

export default {
    components: {
        EvaluationResultUser
    },
    data() {
        return {
            nameUser: "",
            surnameUser: "",
            nameEvaluation: "",
            summary: null,
            dataLoading: true,
            from: null
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isAdmin() {
            if (this.currentUser && this.currentUser.role) {
                if (this.currentUser.role.name === 'admin') {
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
        SoftskillsDataService.findUserEvaluationSummary(this.$route.query.id_evaluation, this.$route.query.id_user, this.$route.query.id_position_selection).then(
            response => {
                this.summary = response.data;
                
                this.dataLoading = false;

                if (this.$route.query.id_position_selection.toString() === '-1') this.summary.evaluation = this.summary
                this.nameUser = this.summary.users_evaluations[0].user.name;
                this.surnameUser = this.summary.users_evaluations[0].user.surname;
                this.nameEvaluation = this.summary.name;
                console.log('this.summary: ', this.summary)
            },
            error => {
                this.dataLoading = false
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        )
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}
</style>