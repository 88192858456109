<template>
	<div class="col-sm-12">
		<div class="row secondNavbar" v-if="from">
			<div v-if="from.path == '/tests'" class="col-5 links-container">
				<router-link to="/tests" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" />
					Back to Tests</router-link>
			</div>
			<div v-else class="col-5 links-container">
				<router-link :to="{ path: from.path + '?id=' + from.query.id }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
					Process</router-link>
			</div>

			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link v-if="from.path == '/tests'" :to="{ path: '/tests' }" class="breadcumb"> Tests >
				</router-link>
				<router-link v-else :to="{ path: '/business-process' }" class="breadcumb"> Bussines Process >
				</router-link>
				<router-link :to="{ path: '/test-edit-questions' }" class="final-breadcumb"> Test '{{ test.title }}'
				</router-link>
			</div>
			<div v-else class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/tests' }" class="breadcumb"> Tests > </router-link>
				<router-link :to="{ path: '/test-edit-questions' }" class="final-breadcumb"> Test '{{ test.title }}'
				</router-link>
			</div>
		</div>
		<edit-questions :testFlag="testFlag" :questionIndex="questionIndex" />
	</div>
</template>

<script>
import EditQuestions from "@/components/TestComponents/EditQuestions.vue";
import TestDataService from "@/services/TestDataService";
import Test from '@/models/test';

export default {
	name: 'EditQuestionsView',
	components: {
		EditQuestions
	},
	data: () => {
		return {
			from: null,
			labelFrom: "",
			test: new Test('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''),
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from;
		});
	},
	props: {
		testFlag: Boolean,
		questionIndex: Number,
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		TestDataService.findOne(this.$route.query.id).then(
			response => {
				this.test = response.data;
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}
</style>