<template>
	<div class="col-sm-12 justify-content-center my-2">
        <div class="row">
            <div class="row noMargin">
                <div class="col-sm-12 px-5 noMargin noMarginLow">
                    <h4>Configuration from {{selectedLevelName}} {{level_origin}} to {{secondTechName}} {{level_destiny}}</h4>
                </div>
            </div>
            <div class="row noMargin ">
                <div class="col-12 px-5 noMargin">
                    <div class="row noMargin">
                        <div class="col-6">
                            <div class="alignCenterColumns">  
                                <img v-if="!logoFatherTech.includes('null')" v-bind:src="logoFatherTech" height="20em" class="card-avatar--circle" />
                                <h5 class="profile-name">{{selectedLevelName.toUpperCase()}} {{level_origin.toUpperCase()}}</h5> 
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="alignCenterColumns">  
                                <img v-if="!logoSecondTech.includes('null')" v-bind:src="logoSecondTech" height="20em" class="card-avatar--circle" />
                                <h5 class="profile-name">{{secondTechName.toUpperCase()}} {{level_destiny.toUpperCase()}}</h5> 
                            </div>
                        </div>
                    </div>
                    <div class="row noMargin">
                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                            <h5 class="profile-name-title">INFORMATION</h5>
                            <button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleInfo'" @click="changeShowStateInfo()">
                                <font-awesome-icon style="display: block;" v-bind:id="'iconShowInfo-'" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                <font-awesome-icon style="display: none;" v-bind:id="'iconNotShowInfo-'" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                            </button>
                        </div>
                        <b-collapse v-bind:id="'toggleInfo'" v-if="dataFatherTechnology != null && secondTech != null">
                            <div class="row noMargin">
                                <div class="col-12">
                                    <h6 class="title" @click="visibleCertifications = !visibleCertifications">Certifications
                                        <font-awesome-icon v-if="!visibleCertifications" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                    </h6>
                                    <b-collapse id="collapseId" v-model="visibleCertifications" v-if="dataFatherTechnology.certifications != null && dataFatherTechnology.certifications != undefined
                                    && secondTech.certifications != undefined && secondTech.certifications != null"> 
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="alignCenterColumns">
                                                    <div class="divItems" v-if="dataFatherTechnology.certifications.length != 0 && dataFatherTechnology.certifications.length != undefined">
                                                        <div class="added-certifications" v-for="certificationsItem in dataFatherTechnology.certifications" :key="certificationsItem.id" >
                                                            <CertificationsCapsule :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                        </div>
                                                    </div>
                                                    <div class="divItems" v-else>
                                                        <p>No Specific Certifications Requested.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="divItems" v-if="(secondTech.certifications.difference != 0 && secondTech.certifications.difference != undefined) 
                                                ||((secondTech.certifications.similar != 0 && secondTech.certifications.similar != undefined))" >
                                                    <div class="added-certifications" v-for="certificationsItem in secondTech.certifications.similar" :key="certificationsItem.id" >
                                                        <CertificationsCapsule class="greenCapsuleClass" :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                    </div>
                                                    <div class="added-certifications" v-for="certificationsItem in secondTech.certifications.difference" :key="certificationsItem.id" >
                                                        <CertificationsCapsule class="redCapsuleClass" :name="certificationsItem.certification.name" :item="certificationsItem" :del="del"/>
                                                    </div>
                                                </div>
                                                <div class="divItems" v-else>
                                                    <p>No Specific Certifications Requested.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                    <h6 class="title" @click="visibleEducation = !visibleEducation">Education
                                        <font-awesome-icon v-if="!visibleEducation" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                    </h6>
                                    <div class="alignCenterColumns">
                                        <b-collapse id="collapseId" v-model="visibleEducation"> 
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="divItems" v-if="dataFatherTechnology.education.length != 0 && dataFatherTechnology.education.length != undefined">
                                                        <div class="added-education" v-for="educationItem in dataFatherTechnology.education" :key="educationItem.id" >
                                                            <EducationCapsule :name="educationItem.level" :item="educationItem" :del="del"/>
                                                        </div>
                                                    </div>
                                                    <div class="divItems" v-else>
                                                        <p>No education defined.</p>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="divItems" v-if="(secondTech.education.difference != 0 && secondTech.education.difference != undefined) 
                                                    ||((secondTech.education.similar != 0 && secondTech.education.similar != undefined))" >
                                                        <div class="added-education" v-for="educationItem in secondTech.education.similar" :key="educationItem.id" >
                                                            <EducationCapsule class="greenCapsuleClass" :name="educationItem.level" :item="educationItem" :del="del"/>
                                                        </div>
                                                        <div class="added-education" v-for="educationItem in secondTech.education.difference" :key="educationItem.id" >
                                                            <EducationCapsule class="redCapsuleClass" :name="educationItem.level" :item="educationItem" :del="del"/>
                                                        </div>
                                                    </div>
                                                    <div class="divItems" v-else>
                                                        <p>No education defined.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                    <h6 class="title " @click="visibleExp = !visibleExp">Years of Experience
                                        <font-awesome-icon v-if="!visibleExp" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                    </h6>
                                    <div>
                                        <b-collapse id="collapseId" v-model="visibleExp"> 
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="divItems">
                                                        <p v-if=" dataFatherTechnology.experience_time_min_years.is_required" style="font-weight: bold; font-size: 17px;">Min. years: {{ dataFatherTechnology.experience_time_min_years.value}}</p>
                                                        <p v-else>Min. years: {{ dataFatherTechnology.experience_time_min_years.value}}</p>
                                                        <p v-if=" dataFatherTechnology.experience_time_max_years != null && dataFatherTechnology.experience_time_max_years.value != undefined &&  dataFatherTechnology.experience_time_max_years.is_required" style="font-weight: bold; font-size: 17px;">Max. years: {{ dataFatherTechnology.experience_time_max_years.value}}</p>
                                                        <p v-if=" dataFatherTechnology.experience_time_max_years != null && dataFatherTechnology.experience_time_max_years.value != undefined && ! dataFatherTechnology.experience_time_max_years.is_required">Max. years: {{ dataFatherTechnology.experience_time_max_years.value}}</p>
                                                        <p v-if="dataFatherTechnology.experience_time_max_years == null || dataFatherTechnology.experience_time_max_years.value == undefined">Max. years: No limit</p>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="divItems">
                                                        <div v-if=" secondTech.min_experience.difference != null && secondTech.min_experience.difference !=undefined"> 
                                                            <p v-if="secondTech.min_experience.difference < 0" style="font-weight: bold; font-size: 17px;">{{- secondTech.min_experience.difference}} more year/s required</p>
                                                            <p v-else style="font-weight: bold; font-size: 17px;">No extra experience required</p>

                                                        </div>
                                                        <div v-else>
                                                            <p style="font-weight: bold; font-size: 17px;">No extra experience required</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                    <h6 class="title" @click="visibleTech = !visibleTech">Technical Skills
                                        <font-awesome-icon v-if="!visibleTech" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                    </h6>
                                    <div class="alignCenterColumns">
                                        <b-collapse id="collapseId" v-model="visibleTech"> 
                                            <div class="row">
                                                <div class="col-6">
                                                    <template v-for="skill in dataFatherTechnology.skills">
                                                        <div :key="skill.id" v-if="skill.is_tech == 1">
                                                            <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            <div class="divItems" v-if="skill.items.length != 0">
                                                                <div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                            </div>
                                                            <div class="divItems" v-else>
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div class="divItems" v-if="!dataFatherTechnology.hayTechnologySkills">
                                                        <p>No Technical Skills defined.</p>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <template v-for="skill in secondTech.skills">
                                                        <div :key="skill.id" v-if="skill.is_tech == 1">
                                                            <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            <div class="divItems" v-if="skill.difference.length != 0 || skill.similar.length != 0">
                                                                <div class="added-frameworks" v-for="(item, index) in skill.similar" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule class="greenCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                                <div class="added-frameworks" v-for="(item, index) in skill.difference" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule class="redCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                            </div>
                                                            <div class="divItems" v-else>
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                    <div class="divItems" v-if="!secondTech.hayTechSkillsDifferent && !secondTech.hayTechSkillsSimilar">
                                                        <p>No Technical Skills defined.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                    <h6 class="title" @click="visibleNotTech = !visibleNotTech">Non-Technical Skills
                                        <font-awesome-icon v-if="!visibleNotTech" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon v-else class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                    </h6>
                                    <div class="alignCenterColumns">
                                        <b-collapse id="collapseId" v-model="visibleNotTech"> 
                                            <div class="row">
                                                <div class="col-6">
                                                    <template v-for="skill in  dataFatherTechnology.skills">
                                                        <div :key="skill.id" v-if="skill.is_tech == 0">
                                                            <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            <div class="divItems" v-if="skill.items.length != 0">
                                                                <div class="added-frameworks" v-for="(item, index) in skill.items" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                            </div>
                                                            <div class="divItems" v-else>
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                            </div> 
                                                        </div>
                                                    </template>
                                                    <div class="divItems" v-if="!dataFatherTechnology.hayNonTechnologySkills">
                                                        <p>No Non-Technical Skills defined.</p>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <template v-for="skill in secondTech.skills">
                                                        <div :key="skill.id" v-if="skill.is_tech == 0">
                                                            <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            <div class="divItems" v-if="skill.difference.length != 0 || skill.similar.length != 0">
                                                                <div class="added-frameworks" v-for="(item, index) in skill.similar" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule class="greenCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                                <div class="added-frameworks" v-for="(item, index) in skill.difference" :key="item.item + '-' + index" >
                                                                    <SkillsCapsule class="redCapsuleClass" :name="item.item" :item="item" :del="del"/>
                                                                </div>
                                                            </div>
                                                            <div class="divItems" v-else>
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} defined.</p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div class="divItems" v-if="!secondTech.hayNonTechSkillsDifferent && !secondTech.hayNonTechSkillsSimilar">
                                                        <p>No Non-Technical Skills defined.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>  
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <hr class="thicker">
                    <div class="row noMargin">
                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                            <h5 class="profile-name-title">SETTINGS</h5>
                                <button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleSettings'" @click="changeShowStateSettings()">
                                    <font-awesome-icon style="display: block;" v-bind:id="'iconShowSettings' " class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                    <font-awesome-icon style="display: none;" v-bind:id="'iconNotShowSettings'" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                </button>
                        </div>
                        <b-collapse v-bind:id="'toggleSettings'" v-if="dataFatherTechnology != null && secondTech != null && configurator != null">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">  
                                            <h5 class="profile-name noBold"> Set Time to Reskill To <img v-bind:src="secondTech.logo" height="20em" class="card-avatar--circle" />
                                            "{{secondTechName.toUpperCase()}} {{level_destiny.toUpperCase()}}"</h5>
                                        </div>
                                        <div class="col-6">
                                            <button class="btn btn-primary reset-button" type="button" @click="showResetModal"><font-awesome-icon icon="undo" class="white-color" /> Reset</button>
                                            <button class="btn btn-primary save-configuration-button" type="button" @click="handleSave"><font-awesome-icon icon="save" class="white-color" /> Save</button>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-6">
                                            <div class="alignCenterColumns">  
                                                <h5 class="title">Field</h5>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="alignCenterColumns">  
                                                <h5 class="title">Time to learn &nbsp;</h5>
                                                <h5 class="profile-name noBold"> (in hours) </h5>
                                            </div>
                                        </div>
                                        <hr class="thicker">
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div>                                               
                                                <h6 class="title" @click="visibleSettingsCertifications = !visibleSettingsCertifications">Certifications
                                                    <font-awesome-icon v-if="!visibleSettingsCertifications && (configurator.certifications != 0 && configurator.certifications != undefined)" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                    <font-awesome-icon v-if="visibleSettingsCertifications && configurator.certifications != 0 && configurator.certifications != undefined" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-if="(configurator.certifications == 0 || configurator.certifications == undefined)">
                                            No extra certifications needed
                                        </div>
                                    </div>
                                    <b-collapse id="collapseId" v-model="visibleSettingsCertifications"> 
                                        <div class="row">
                                            <div v-if="(configurator.certifications != 0 && configurator.certifications != undefined)"> 
                                                <div v-for="certificationsItem in configurator.certifications" :key="certificationsItem.id" class="row">                             
                                                    <div class="col-6 alignCenterColumns">
                                                        <div class="added-certifications" >
                                                            <CertificationsCapsule :name="certificationsItem.name" :item="certificationsItem" :del="del"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 alignCenterColumns">
                                                        <p> <input class="form-input fitContent" max="100000" min="0" type="number" oninput="validity.valid||(value=0);" v-model.number="certificationsItem.hours"> &nbsp; hours </p>
                                                    </div>           
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                    <div class="row">
                                        <div class="col-6">
                                            <div>
                                                <h6 class="title" @click="visibleSettingsEducation = !visibleSettingsEducation">Education
                                                    <font-awesome-icon v-if="!visibleSettingsEducation && (configurator.education != 0 && configurator.education != undefined)" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                    <font-awesome-icon v-if="visibleSettingsEducation && configurator.education != 0 && configurator.education != undefined" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-if="configurator.education == 0 || configurator.education == undefined">
                                            No extra education needed
                                        </div>
                                    </div>
                                    <b-collapse id="collapseId" v-model="visibleSettingsEducation"> 
                                        <div class="row">
                                            <div v-if="configurator.education != 0 && configurator.education != undefined"> 
                                                <div v-for="educationItem in configurator.education" :key="educationItem.id" class="row">                             
                                                    <div class="col-6 alignCenterColumns">
                                                        <div class="added-education" >
                                                            <EducationCapsule :name="educationItem.name" :item="educationItem" :del="del"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 alignCenterColumns">
                                                        <p> <input class="form-input fitContent" max="100000" min="0" type="number" oninput="validity.valid||(value=0);" v-model.number="educationItem.hours" > &nbsp; hours </p>
                                                    </div>           
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                    <div class="row">
                                        <div class="col-6">
                                            <div>
                                                <h6 class="title">Experience</h6>
                                            </div>
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-if="configurator.min_experience == null && secondTech.min_experience == undefined || secondTech.min_experience.difference > 0 " >
                                                No extra experience needed
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-else >
                                            <h6 class="title">{{- configurator.min_experience}} more year/s required</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div>     
                                                <h6 class="title" @click="visibleSettingsTech = !visibleSettingsTech">Technical Skills
                                                    <font-awesome-icon v-if="!visibleSettingsTech && configurator.hayTechSkillsDifferent" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                    <font-awesome-icon v-if="visibleSettingsTech && configurator.hayTechSkillsDifferent" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-if="!configurator.hayTechSkillsDifferent">
                                                No extra Tech Skills needed
                                        </div>
                                    </div>
                                    <b-collapse id="collapseId" v-model="visibleSettingsTech"> 
                                        <div class="row">
                                            <div v-if="configurator.skills!= undefined"> 
                                                <template v-for="(skill,index) in configurator.skills">
                                                    <div :key="skill.id" v-if="skill.is_tech == 1">
                                                        <div v-if="skill.difference.length != 0">
                                                            <div class="col-6">                                                     
                                                                <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}
                                                                    <button class="btn btn-secondary expand-button" type="button" v-b-toggle="'toggleSkill-' + index.toString()" @click="changeShowStateSkill(index)">
                                                                        <font-awesome-icon style="display: block;" v-bind:id="'iconShowSkill-'+ index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                                        <font-awesome-icon style="display: none;" v-bind:id="'iconNotShowSkill-'+ index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                                    </button>
                                                                </h6>
                                                            </div>
                                                            <b-collapse visible v-bind:id="'toggleSkill-' + index.toString()">
                                                                <div class="divItems">
                                                                    <div v-for="(item, index) in skill.difference" :key="item.item + '-' + index" class="row">
                                                                        <div class="col-6 alignCenterColumns">
                                                                            <div class="added-frameworks">
                                                                                <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 alignCenterColumns">
                                                                            <p> <input class="form-input fitContent" max="1000000" min="0" type="number" oninput="validity.valid||(value=0);" v-model.number="item.hours">  &nbsp; hours </p>
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                            </b-collapse>
                                                        </div>
                                                        <div v-else class="row">
                                                            <div class="col-6">                                                     
                                                                <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            </div>
                                                            <div class="col-6 alignCenterColumns">                                                                          
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} needed</p>
                                                            </div>
                                                        </div>                                                                
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </b-collapse>
                                    <div class="row">
                                        <div class="col-6">
                                            <div>
                                                <h6 class="title" @click="visibleSettingsNotTech = !visibleSettingsNotTech">Non-Technical Skills
                                                    <font-awesome-icon v-if="!visibleSettingsNotTech && configurator.hayNonTechSkillsDifferent" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                                    <font-awesome-icon v-if="visibleSettingsNotTech && configurator.hayNonTechSkillsDifferent" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="col-6 alignCenterColumns" v-if="!configurator.hayNonTechSkillsDifferent">
                                            No extra Non-Tech Skills needed
                                        </div>
                                    </div>
                                    <b-collapse id="collapseId" v-model="visibleSettingsNotTech"> 
                                        <div class="row">
                                            <div v-if="configurator.skills!= undefined"> 
                                                <template v-for="skill in configurator.skills">
                                                    <div :key="skill.id" v-if="skill.is_tech == 0">
                                                        <div v-if="skill.difference.length != 0">
                                                            <div class="col-6">                                                     
                                                                <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            </div>
                                                            <div class="divItems">
                                                                <div v-for="(item, index) in skill.difference" :key="item.item + '-' + index" class="row">
                                                                    <div class="col-6 alignCenterColumns">
                                                                        <div class="added-frameworks">
                                                                            <SkillsCapsule :name="item.item" :item="item" :del="del"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 alignCenterColumns">
                                                                        <p> <input class="form-input fitContent" max="100000" min="0" oninput="validity.valid||(value=0);" type="number" v-model.number="item.hours"> &nbsp; hours </p>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else class="row">
                                                            <div class="col-6">                                                     
                                                                <h6 class="subtitle title"> &nbsp; - {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}}</h6>
                                                            </div>
                                                            <div class="col-6 alignCenterColumns">                                                                          
                                                                <p>No {{capitalizarPrimeraLetra(skill.skill.replaceAll('_', ' '))}} needed</p>
                                                            </div>
                                                        </div>                                                                
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                </div>     
            </div>   
        </div>

        <b-modal id="reset-modal" ref="reset-modal" title="Reset-modal" hide-footer>
            <template #modal-title>
                Reset all
            </template>
            <div>
                <p>Do you really want to reset the readaptation configuration? </p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('reset-modal')" value="No">
            <input class="btn btn-primary form-submit" type="button" @click="resetComparison" value="Yes">
        </b-modal>

        <template v-for="(errorMessage, index) in errorsMessages">
            <div :id="index" class="alert alert-danger alert-edit d-flex align-items-center justify-content-center" v-bind:key="errorMessage">
                {{errorMessage}} <b-button @click="close(index)" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
            </div>
        </template>
	</div>
</template>

<script>
	import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
	import EducationCapsule from "@/components/Others/EducationCapsule.vue"
	import ProgrammingSkillsCapsule from "@/components/Others/ProgrammingSkillsCapsule.vue"
	import CertificationsCapsule from "@/components/Others/CertificationsCapsule.vue"
	import FrameworksCapsule from "@/components/Others/FrameworksCapsule.vue"
	import ToolsCapsule from "@/components/Others/ToolsCapsule.vue"
	import OthersCapsule from "@/components/Others/OthersCapsule.vue"
	import SkillsCapsule from "@/components/Others/SkillsCapsule.vue"

	export default {
		name: 'Update',
		data() {
            return {
                selectedLevel: [],
                selectedLevelName: "",
                selectedTechId: "",
                selectedLevelId: "",
                profileLevels: [],
                errorsMessages: [],
                del: false,
                dataFatherTechnology: null,
                configurator: null,
                secondTech: null,
                collapseId: true,
                visibleCertifications: false,
                visibleEducation: false,
                visibleExp: false,
                visibleTech: false,
                visibleNotTech: false,
                visibleSettingsCertifications: true,
                visibleSettingsEducation: true,
                visibleSettingsExp: true,
                visibleSettingsTech: true,
                visibleSettingsNotTech: true,
                logoFatherTech: "", 
                selectedAreaId: "",
                logoSecondTech: "",
                secondTechName: "",
                cols:2,
                newComparison: true
            }
	    },
        props: {
            id_origin: Number,
            id_destiny: Number,
            id_area: Number,
            level_origin_id: Number,
            level_destiny_id: Number,
            level_origin: String,
            level_destiny: String,
        },
        components: {
			EducationCapsule,
			FrameworksCapsule,
			ProgrammingSkillsCapsule,
			ToolsCapsule,
			OthersCapsule,
			CertificationsCapsule,
			SkillsCapsule,
	    },
        computed: {
            columns () {
                let columns = []
                let mid = Math.ceil(this.items.length / this.cols)
                for (let col = 0; col < this.cols; col++) {
                    columns.push(this.items.slice(col * mid, col * mid + mid))
                }
                return columns
            }
        },
         mounted(){
            this.selectedTechId = this.id_origin
            this.selectedLevelId = this.level_origin_id
            this.selectedAreaId = this.id_area

            if(this.selectedAreaId == this.$store.state.auth.user.active_areas[this.$session.get("tabID")]){
                this.getBothNames()
            } else {
                ProfileConfigurationDataService.findLevelsConfigurationByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                    response => {
                        for(let i=0; i<response.data.length; i++){
                            if(response.data[i].id == this.selectedTechId){
                                this.selectedLevelName = response.data[i].name;
                                this.logoFatherTech = this.$awsBucketRoute + response.data[i].logo
                                break; 
                            }
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );

                ProfileConfigurationDataService.findLevelsConfigurationByArea(this.selectedAreaId).then(
                    response => {
                        for(let i=0; i<response.data.length; i++){
                            if(response.data[i].id == this.id_destiny){
                                this.secondTechName = response.data[i].name
                                this.logoSecondTech = this.$awsBucketRoute + response.data[i].logo
                                break; 
                            }
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );
            }

            this.getAllData()
        },
	  	methods: {
            capitalizarPrimeraLetra(str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
            restrictDecimal (input) {
                input=input.match(/^\d+\.?\d{0,2}/);
            },
            async getBothNames(){
                await ProfileConfigurationDataService.findLevelsConfigurationByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                    response => {
                        for(let i=0; i<response.data.length; i++){
                            if(response.data[i].id == this.selectedTechId){
                                this.selectedLevelName = response.data[i].name;
                                this.logoFatherTech = this.$awsBucketRoute + response.data[i].logo
                            }
                            if(response.data[i].id == this.id_destiny){
                                this.secondTechName = response.data[i].name
                                this.logoSecondTech = this.$awsBucketRoute + response.data[i].logo
                            }
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );
            },
            getAllData(){
                ProfileConfigurationDataService.findProfileLevel(this.selectedTechId, this.selectedLevelId).then(
                    response => {
                        this.dataFatherTechnology = response.data;
                        this.dataFatherTechnology.id = this.selectedTechId; 
                        this.dataFatherTechnology.name = this.selectedLevelName;
                        this.dataFatherTechnology.level = this.level_origin;
                        this.dataFatherTechnology.logo = this.logoFatherTech
                        var skillsForTechnologyAndProfile = this.dataFatherTechnology.skills
                        let hayTechSkills = false
                        let hayNonTechSkills = false
                        for (let j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                            if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                hayTechSkills = true
                            } else {
                                hayNonTechSkills = true
                            }
                        }
                        this.dataFatherTechnology.hayTechnologySkills = hayTechSkills
                        this.dataFatherTechnology.hayNonTechnologySkills = hayNonTechSkills      
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );

                ProfileConfigurationDataService.compareProfilesLevels(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id).then(
                    response => {
                        this.secondTech = response.data
                        this.secondTech.id = this.id_destiny; 
                        this.secondTech.name = this.secondTechName;
                        this.secondTech.level = this.level_destiny; 
                        this.secondTech.logo = this.logoSecondTech;
                        var skillsForTechnologyAndProfile = this.secondTech.skills
                        let hayTechSkillsDifferent = false
                        let hayTechSkillsSimilar = false
                        let hayNonTechSkillsDifferent = false
                        let hayNonTechSkillsSimilar= false

                        for (let j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                            if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                if(skillsForTechnologyAndProfile[j].difference.length > 0)
                                    hayTechSkillsDifferent = true
                                else if(skillsForTechnologyAndProfile[j].similar.length > 0)
                                    hayTechSkillsSimilar = true
                            } else {
                                 if(skillsForTechnologyAndProfile[j].difference.length > 0)
                                    hayNonTechSkillsDifferent = true
                                else if(skillsForTechnologyAndProfile[j].similar.length > 0)
                                    hayNonTechSkillsSimilar = true
                            }
                        }
                        
                        this.secondTech.hayTechSkillsDifferent = hayTechSkillsDifferent
                        this.secondTech.hayTechSkillsSimilar = hayTechSkillsSimilar
                        this.secondTech.hayNonTechSkillsDifferent = hayNonTechSkillsDifferent
                        this.secondTech.hayNonTechSkillsSimilar = hayNonTechSkillsSimilar
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );

                this.getComparatorData()
            },
            getComparatorData(){
                ProfileConfigurationDataService.findRecycleProfilesEffort(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id).then(
                    response => {
                        this.newComparison = false
                        if(response.data != null && response.data != undefined){
                            this.configurator = response.data.data
                            let skillsForTechnologyAndProfile = this.configurator.skills
                            let hayTechSkills = false
                            let hayNonTechSkills = false
                            for (let j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                                if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                    hayTechSkills = true
                                } else {
                                    hayNonTechSkills = true
                                }
                            }
                            this.configurator.hayTechnologySkills = hayTechSkills
                            this.configurator.hayNonTechnologySkills = hayNonTechSkills
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        this.newComparison = true

                        ProfileConfigurationDataService.createProfileLevelsEffortComparison(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id).then(
                            response => {
                                this.configurator = response.data

                                let skillsForTechnologyAndProfile = this.configurator.skills
                                let hayTechSkills = false
                                let hayNonTechSkills = false
                                for (let j = 0; j < skillsForTechnologyAndProfile.length; j++) {
                                    if(skillsForTechnologyAndProfile[j].is_tech == 1) {
                                        hayTechSkills = true
                                    } else {
                                        hayNonTechSkills = true
                                    }
                                }
                                this.configurator.hayTechnologySkills = hayTechSkills
                                this.configurator.hayNonTechnologySkills = hayNonTechSkills
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                        
                        if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
                            localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
                    }
                );
            },
            handleSave(){
                if(this.newComparison){
                    ProfileConfigurationDataService.createRecycleProfilesEffort(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id, this.configurator).then(
                        response => {
                            this.$router.replace({ name: "professional-updating", params: {technologiesUpdated:true}, query: {idProfile: this.selectedTechId} })
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    ProfileConfigurationDataService.updateRecycleProfilesEffort(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id, this.configurator).then(
                        response => {
                            this.$router.replace({ name: "professional-updating", params: {technologiesUpdated:true}, query: {idProfile: this.selectedTechId} })
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            } 
                        }
                    );
                }
            },
            resetComparison(){
                if(!this.newComparison){
                    ProfileConfigurationDataService.deleteRecycleProfilesEffort(this.selectedTechId, this.selectedLevelId, this.id_destiny,this.level_destiny_id).then(
                        response => {
                            console.log('')
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else this.$router.replace({ name: "professional-updating", params: {technologiesUpdated:true}, query: {idProfile: this.selectedTechId} })
            },
            async updateRecycling(){
                await ProfileAnalysisDataService.updateCVsRecycleDataByCompany(this.$store.state.auth.user.id_company).then(
                    response => {
                        this.$router.replace({ name: "professional-updating", params: {technologiesUpdated:true}, query: {idProfile: this.selectedTechId} })
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            showResetModal(){
                this.$refs['reset-modal'].show();
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            },
            changeShowStateInfo() {
				if(document.getElementById('iconShowInfo-').style.display == "block") {
					document.getElementById('iconShowInfo-').style.display = "none"
					document.getElementById('iconNotShowInfo-' ).style.display = "block"
				} else {
					document.getElementById('iconShowInfo-').style.display = "block"
					document.getElementById('iconNotShowInfo-').style.display = "none"
				}
			},
            changeShowStateSettings(){
              	if(document.getElementById('iconShowSettings' ).style.display == "block") {
					document.getElementById('iconShowSettings').style.display = "none"
					document.getElementById('iconNotShowSettings').style.display = "block"
				} else {
					document.getElementById('iconShowSettings').style.display = "block"
					document.getElementById('iconNotShowSettings').style.display = "none"
				}  
            },
            changeShowStateSkill(index){
				if(document.getElementById('iconShowSkill-' + index.toString()).style.display == "block") {
					document.getElementById('iconShowSkill-' + index.toString()).style.display = "none"
					document.getElementById('iconNotShowSkill-' + index.toString()).style.display = "block"
				} else {
					document.getElementById('iconShowSkill-' + index.toString()).style.display = "block"
					document.getElementById('iconNotShowSkill-' + index.toString()).style.display = "none"
				}
			}
	    }
	};
</script>

<style scoped>
.fitContent{
    height:fit-content;
    margin-top: 5%;
    text-align: center;
}

.noBold{
    font-weight: 500!important;
}

.noMargin{
    padding-top: 0% !important;
    justify-content: center;
}

.alignCenterColumns{
    display: flex !important;
    justify-content: center !important;
}

.noMarginLow{
    padding-bottom: 0% !important;
}

.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.divItems {
    text-align: left;
    margin-left: 25px;
    margin-right: 25px;
	margin-bottom: 1em;
}
</style>