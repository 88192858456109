<template>
	<div>
    <div class="home-mid">
      <div class="row">
        <div class="home-description-upper">
          <p class="desc-text" v-html="textPart1"></p>
          <p class="desc-text" v-html="textPart2"></p>
          <div class="row">
            <div class="col-lg-3 col-sm-6 col-xs-12" v-for="item in cardImages"><img class="img-fluid img-cards" :src="item.image_path" /></div>
          </div>
          <p class="desc-text" v-html="textPart3"></p>
        </div>
      </div>
    </div>
    <div class="partners">
      <div class="row">
        <img src="images/diploma_ice_3.jpg" />
      </div>
    </div>
    <div class="partners3">
      <h2 class="text-center mt-3">{{partnersTitle}}</h2>
      <div class="row">
        <template v-for="item in partnerImages">
          <div v-if="item.image_path.includes('talentech')" class="col-md-4 col-xs-12 no-margin" >
            <img :src="item.image_path" />
          </div>
          <div v-else class="col-md-4 col-xs-12" >
            <img :src="item.image_path" />
          </div>
        </template>
      </div>
    </div>
	</div>
</template>

<script>
  export default {
    name: "HomeBody",
    data: () => ({
      partnersTitle: "Our partners and customers",
      textPart1: '<b>Suite</b> that automates the capture, acquisition and management of <b>human capital.</b>',
      textPart2: 'Itssssss facilitates the identication and assessment of talent in an objective, measurable and comparable way, by highlighting the knowledge, skills and experiences acquired.',
      textPart3: 'Aimed at <b>companies</b> of any type and sector, <b>individuals</b> and <b>public administrations</b>',
      partnerImages: [
        {
          image_path:
            'images/air_institute_logo.png',
        },
        {
          image_path:
            'images/aplifisa_logo.png',
        },
        {
          image_path:
            'images/talentech_logo_tmp.png',
        }
      ],
      cardImages: [
        {
          image_path:
            'images/home_cv_validation_card.svg',
        },
        {
          image_path:
            'images/home_cert_hard_skills_card.svg',
        },
        {
          image_path:
            'images/home_soft_skills_card.svg',
        },
        {
          image_path:
            'images/home_interview_card.svg',
        }
      ]
    }),
    methods: {
    }
  };
</script>