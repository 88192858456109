<template>
	<div class="menu-card">
		<transition name="slide" mode="out-in">
			<span v-if="cardRouteName == '/available-tests' && availableTests > 0" class="circle-icon-notification">{{availableTests}}</span>
		</transition>
		<router-link :to="cardRouteName" style="text-decoration: none; color: #13283B">
			<div class="row justify-content-center menu-card-top">
				<img class="card-img-top" :src="cardImage" alt="">
			</div>					
			<div class="row card-body" style="width: 100%;">
				<div class="col-md-12">
					<h5 class="card-title">{{cardTitle}}</h5>
				</div>
				<p class="card-text">{{cardDescription}}</p>
			</div>
		</router-link>
	</div>
</template>

<script>
	import TestDataService from '@/services/TestDataService';

	export default {
		name: 'MenuCard',
		data() {
			return {
				tests: [],
		  		availableTests: 0,
		  		interval: null,
			}
		},
		props: {
			cardRouteName: String,
			cardImage: String,
			cardTitle: String,
			cardDescription: String,
		},
		methods: {
			setCount (val) {
				this.interval = setInterval(() => {
					if (this.availableTests >= val)
						return;
			    	this.availableTests++
			    }, 200)
		    }
		},
		mounted() {
			if (this.cardRouteName == '/available-tests'){
				if (this.$store.state.auth.user.active_areas != undefined && this.$store.state.auth.user.active_areas != null) {
					const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
					TestDataService.findTestsForUser(this.$store.state.auth.user.id, currentArea).then(
					      response => {
					      	this.tests = response.data

					      	let availableTestsTmp = 0; 
							for(var i=0; i<this.tests.length; i++) {
								if(this.tests[i].max_attempts > this.tests[i].attempts) {
									availableTestsTmp++;
								}
							}

							this.setCount(availableTestsTmp);
					      },
					      error => {
				          	console.log((error.response && error.response.data) ||
					          error.message ||
					          error.toString())
							
							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
						}
					);
				}
			}
		}
	}
</script>

<style scoped>
.circle-icon-notification {
	position: absolute;
	top: -16px;
	right: -12px;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	background: #4FC0E8;
	justify-content: center;
	display: flex;
	font-size: 24px;
}

.menu-card {
	align-items: center;
	background: #fff;
	border-radius: 2rem;
	border: 2px solid rgb(232, 232, 232);
	display: block;
	max-width: 18em;
	position: relative;
	width: 100%;
	height: 17em;
	margin: 5px 10px 5px 10px;
}

.menu-card-top {
	border-radius: 2rem 2rem 0rem 0rem;
	border-bottom: 1px solid rgb(232, 232, 232);
	width: 100%;
	background-color: #FFFFFF;
	margin: 3% 0 0 0;
}

.card-img-top {
	margin: 1.5rem;
	height: 6em;
}

.card-body {
	margin: 0 0 0 0;
}

.card-text {
	text-align: left;
	font-size: 85%;
}

.slide-enter {
	top: 10px;
	opacity: 0.5;
}

.slide-enter-active, .slide-leave-active {
	transition: all .15s;
}

.slide-enter-to {
	top: 0px;
	opacity: 1;
}

.slide-leave {
	top: 0px;
	opacity: 1;
}

.slide-leave-to {
	top: -30px;
	opacity: 0;
	transform: blur(8px);
}
</style>