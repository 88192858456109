<template>
	<div v-bind:class="{ 'table-container': !home, 'col-sm-12': home }" class="technologiesStyle my-2">
		<h4>{{ $t('dashboards.areas.cvs.internal-cvs') }} ({{ users.length }} {{ $t('dashboards.areas.cvs.users') }})  <font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.cvs.internal-cvs-description')"/></h4>

		<div id="messageDelete" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>

		<template v-if="users.length > 0">
			<div class="row title-row">
				<div class="col-xl-4 col-md-6 col-sm-12 ">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="usersAnalyses" limit="2"></b-pagination>
				</div>
				<div class="col-xl-3 col-md-6 col-sm-12">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
				</div>
				<div class="col-xl-5 col-md-12 col-sm-12 search-input">
					<b-form-input id="filter-input" v-model="filter" type="search"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>

			<b-table ref="analysesTable" id="usersAnalyses" :per-page="perPage" :current-page="currentPage"
				:fields="headers" :items="users" :filter="filter" responsive="sm">
				<template #cell(username)="data">
					{{ data.item.username }}
				</template>
				<template #cell(name)="data">
					{{ data.item.name }} {{ data.item.surname }}
				</template>
				<template #cell(options)="data">
					<router-link v-if="data.item.last_cv_id_profile !== undefined" v-b-tooltip.hover
						:title="$t('dashboards.areas.cvs.show-internal-cv')"
						:to="{ path: '/profile-analysis?id=' + data.item.last_cv_id_profile }"><font-awesome-icon
							icon="eye" /> </router-link>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No users CV found
				for this company.</h3>
		</template>

		<b-modal id="send-modal" ref="send-modal" title="Send-modal" hide-footer>
			<template #modal-title>
				Send chatbot invitation
			</template>
			<div>
				<p>Do you really want to invite this user to chat with Aris for completing his/her profile? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('send-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="sendEmail()" value="Yes">
		</b-modal>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete analysis
			</template>
			<div>
				<p>Do you really want to delete this profile analysis? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteAnalysis()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import UserDataService from '@/services/UserDataService';

export default {
	name: 'AnalysesListComponent',
	data() {
		return {
			headers: [
				{ key: 'username', sortable: true, label: 'User' },
				{ key: 'name', sortable: true, label: 'Name' },
				{ key: 'options', label: 'Options' },
			],
			users: [],
			message: "",
			successfull: false,
			perPage: 10,
			pageOptions: this.$paginationOptions,
			currentPage: 1,
			selectedId: null,
			filter: null,
			selectedAnalysis: null,
			selectedAnalysisIndex: null
		}
	},
	props: {
		home: Boolean
	},
	mounted() {
		const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

		ProfileAnalysisDataService.findLatestUserCVInArea(currentArea).then(
			response => {
				if (response.status == 200) {
					let responseUsers = response.data.users
					try {
						responseUsers.forEach((user) => {
							let userObject = {}
							userObject.username = user.username
							userObject.id = user.id
							userObject.results = [];
							userObject.name = user.name + " " + user.surname;
							if (user.curriculum_results.length > 0) {
								userObject.last_cv_id_profile = user.curriculum_results[0].id
							}
							this.users.push(userObject)
						});
					}
					catch (e) {
						this.message = "There was an error while parsing the profiles analysis data. Please, contact your admin!"
					}
				}
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	computed: {
		rows() {
			return this.users.length
		}
	},
	methods: {
		onAnalysisSelected(id) {
			this.$router.push('/profile-analysis?id=' + id)
		},
		showSendModal(item) {
			console.log('')
		},
		sendEmail() {
			UserDataService.sendEmail(this.selectedId).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true
					} else {
						this.successfull = false
					}
					this.$refs['send-modal'].hide();
				},
				error => {
					this.message = error.response.data.message
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		showDeleteModal(id, index) {
			this.selectedAnalysis = id
			this.selectedAnalysisIndex = index
			this.$refs['delete-modal'].show();
		},
		deleteAnalysis() {
			ProfileAnalysisDataService.delete(this.selectedAnalysis).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true
						this.users.splice(this.selectedAnalysis, 1)

					} else {
						this.successfull = false
					}
					this.$refs['delete-modal'].hide();
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			document.getElementById("messageDelete").style.display = "none"
		}
	}
};
</script>