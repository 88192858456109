<template>
	<div class="">
		<div class="links-container">
			<router-link to="/repositories" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to repositories</router-link>
		</div>
		<create-repository/>
	</div>
</template>

<script>
  	import CreateRepository from "@/components/RepositoryComponents/Create.vue";

	export default {
	  	name: 'CreateRepositoryView',
	  	components: {
	      	CreateRepository
	    }
	};
</script>