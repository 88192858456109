<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/reports-admin'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Admin Reports</router-link>
        <reports-downloader :reportsTitle="reportsTitle" :documentTitle="documentTitle"/>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{ $t('dashboards.general.reporting') }} > </router-link>
        <router-link :to="{ path: '/reports-admin' }" class="breadcumb"> General Reports > </router-link>
        <router-link :to="{ path: '/admin-general-reports' }" class="final-breadcumb"> General </router-link>
      </div>
    </div>

    <h4 class="reports-available-title">Reports available</h4>
    <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport" @change="onChange($event)" >
			<option :value="undefined">Select a report to view</option>
      <option value="users-per-role">Registered users per role</option>
      <option value="users-per-company">Registered users by each of the areas</option>
      <option value="exams-per-company">Exams created per each of the areas</option>
      <option value="questions-per-company">Questions per each of the areas</option>
      <option value="profiles-per-company">Profiles created per area</option>
      <option value="cvs-per-company">User CVs analysed per area</option>
		</select>

    <UsersRegistredGraphByRole v-if="selectedReport == 'users-per-role'" ref="chart1" :areas="areas"/>
    <UsersGraphByCompany v-if="selectedReport == 'users-per-company'" ref="chart2"/>
    <ExamsGraphByCompany v-if="selectedReport == 'exams-per-company'" ref="chart3"/>
    <QuestionsGraphByCompany v-if="selectedReport == 'questions-per-company'" ref="chart4"/>
    <ProfilesGraphByCompany v-if="selectedReport == 'profiles-per-company'" ref="chart5"/>
    <CVsGraphByCompany v-if="selectedReport == 'cvs-per-company'" ref="chart6"/>
  </div>
</template>

<script>
  import UsersRegistredGraphByRole from "@/components/ReportsComponents/General/UsersRegistredGraphByRole.vue";
  import UsersGraphByCompany from "@/components/ReportsComponents/General/UsersGraphByCompany.vue";
  import ExamsGraphByCompany from "@/components/ReportsComponents/General/ExamsGraphByCompany.vue";
  import QuestionsGraphByCompany from "@/components/ReportsComponents/General/QuestionsGraphByCompany.vue";
  import ProfilesGraphByCompany from "@/components/ReportsComponents/General/ProfilesGraphByCompany.vue";
  import CVsGraphByCompany from "@/components/ReportsComponents/General/CVsGraphByCompany.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";
  import AreasDataService from "@/services/AreasDataService";
  
  export default {
    name: 'GeneralReportsView',
    components: {
      UsersRegistredGraphByRole,
      UsersGraphByCompany,
      ExamsGraphByCompany,
      QuestionsGraphByCompany,
      ProfilesGraphByCompany,
      CVsGraphByCompany,
      ReportsDownloader
    },
    data() {
      return {
        reportsTitle: "General Reports",
        documentTitle: "admin_general_reports.pdf",
        areas: [],
        selectedReport: undefined
      }
    },
    mounted() {
      this.getListOfAreas();
    },
    methods: {
      async getListOfAreas() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.report_selector {
  margin-left: 3%;
  margin-right: 3%; 
  margin-top: 2%;
  max-width: 94%;
}

.reports-available-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}
</style>