<template>
	<form class="vue-form" @submit.prevent="submit">
    <div class="error-message">
      <p v-show="!email.valid">Oh, please enter a valid email address.</p>
    </div>

    <fieldset>
      <div>
        <input type="text" placeholder="Name" name="name" id="name" required="" v-model="name">
      </div>

      <div>
        <input type="email" placeholder="Email" name="email" id="email-contact" required=""
          :class="{ email , error: !email.valid }"
          v-model="email.value">
      </div>

      <div>
        <textarea class="message" placeholder="Comment" name="textarea" id="textarea" required=""  style="margin-left: 0px !important;"
          v-model="message.text" 
          :maxlength="message.maxlength" maxlength="512"></textarea>
        <span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
      </div>

      <div>
        <input class="btn btn-primary" type="submit" value="Submit">
      </div>
    </fieldset>
  </form>
</template>

<script> 
  var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  export default {
    name: "ContactFormFooter",
    data: () => ({
      name: "",
      email: {
        value: "",
        valid: true
      },
      message: {
        text: "",
        maxlength: 255
      },
      submitted: false
    }),
    methods: {
      submit: function() {
        this.submitted = true;
      },
      validate: function(type, value) {
        if (type === "email") {
          this.email.valid = this.isEmail(value) ? true : false;
        }
      },
      isEmail: function(value) {
        return emailRegExp.test(value);
      },
      checkAll: function(event) {
        this.selection.features = event.target.checked ? this.features : [];
      }
    },
    watch: {
      "email.value": function(value) {
        this.validate("email", value);
      }
    }
  };
</script>