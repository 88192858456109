<template>
  <div id="interview">
    <h5 class="full-progress-bar-chatbot-interview-message">Analized profile:</h5>
    <b-progress :max="100" class="full-progress-bar-chatbot-interview">
      <b-progress-bar :value="definedPercentage" :label="definedPercentage+'%'" show-progress animated></b-progress-bar>
    </b-progress>
    <VueBotUI
      :messages="data"
      :options="botOptions"
      :is-open="true"
      @init="restartChatbot"
      @msg-send="messageSendHandler"
    />
    <button class="btn btn-secondary restart-chatbot" @click="restartChatbot()"><font-awesome-icon icon="robot" /> Restart chatbot conversation</button>

    <b-modal id="finish-modal" ref="finish-modal" title="Finish-modal" hide-footer>
			<template #modal-title>
        You have fully completed your Profile
			</template>
			<div>
				<p>You will be redirected to your profile summary </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="redirect()" value="Accept">
		</b-modal>
  </div>
</template>

<script>
  import { VueBotUI } from 'vue-bot-ui'
  import UserDataService from "@/services/UserDataService";
  import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";

  export default {
    name: 'Chat',
    components: {
      VueBotUI,
    },
    data () {
      return {
        data: [],
        botOptions: {
          botTitle: 'ARIS Chatbot',
          colorScheme: '#2c3e50',
          msgBubbleBgUser: '#2c3e50',
          inputPlaceholder: 'Type your message here',
          botAvatarImg: 'images/icons/robot.png',
          botAvatarSize: 64,
          idProfile: ''
        },
        profile: {},
        definedPercentage: 0
      }
    },
    mounted() {
      ProfileAnalysisDataService.findByChatToken(this.$route.query.token).then(
        response => {
          if (response.status != 200) {
            this.$router.push({ name: "profile"});
          } else {
            this.idProfile = response.data.id
            this.profile = response.data
            
            var totalFields = 6
            var countDefinedFields = 0
            
            if (this.profile.certifications != '') {
              let certifications = this.profile.certifications
              certifications = certifications.replaceAll("'", "\"");
              certifications = JSON.parse(certifications)
              this.certifications = certifications
              countDefinedFields++;
            }
            
            if (this.profile.programming_skills != '') {
              let programming_skills = this.profile.programming_skills
              programming_skills = programming_skills.replaceAll("'", "\"");
              programming_skills = JSON.parse(programming_skills)
              this.programming_skills = programming_skills
              countDefinedFields++;
            }
            
            if (this.profile.other_skills != '') {
              let other_skills = this.profile.other_skills.substring(1, this.profile.other_skills.length-1);
              other_skills = other_skills.replaceAll("'", "");
              this.other_skills =  other_skills.split(',');
            }
            
            if (this.profile.education != '') {
              let education = this.profile.education
              education = education.replaceAll("'", "\"");
              education = JSON.parse(education)
              this.education = education
              countDefinedFields++;
            }
            
            if (this.profile.experience_jobs != '') {
              let jobs = this.profile.experience_jobs;
              jobs = jobs.replaceAll("'", "\"");
              jobs = JSON.parse(jobs)
              this.jobs = jobs
              countDefinedFields++;
            }
            
            if (this.profile.experience != '') {
              let experience = this.profile.experience_time;
              experience = experience.replaceAll("'", "\"");
              experience = JSON.parse(experience)
              this.experience = experience
              countDefinedFields++;
            }
            
            if (this.profile.languages != '') {
              let languages = this.profile.languages;
              languages = languages.replaceAll("'", "\"");
              languages = JSON.parse(languages)
              this.languages = languages
              countDefinedFields++;
            }
            
            this.definedPercentage = (countDefinedFields/totalFields)*100;
            this.definedPercentage = Math.round(this.definedPercentage, -1);
          }
        },
        error => {
          this.$router.push({ name: "profile"});
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    computed: {
    },
    props: {
      analysisRegistered: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      restartChatbot() {
        UserDataService.sendChatbotMessage('/restart', this.$route.query.token).then(
          response => {
            UserDataService.sendChatbotMessage('/inform_chatbot_token{"chatbot_token": "'+this.$route.query.token+'" }',this.$route.query.token).then(
              response => {
                UserDataService.sendChatbotMessage('hello',this.$route.query.token).then(
                  res => {
                    if (res.status == 200) {
                      let data = res.data
                      for (var j=0; j<data.length; j++) {
                        var responseType = 'text';
                        var responseText = data[j].text
                        let responseOptions = []
                        var responseDisableInput = false
                        if (data[j].buttons != undefined) {
                          responseType = 'button'
                          responseDisableInput = true
                          for (var i=0; i < data[j].buttons.length; i++) {
                            let object = {}
                            object.text = data[j].buttons[i].title
                            object.value = data[j].buttons[i].payload
                            object.action = 'postback'
                            responseOptions.push(object)
                          }
                        }
                        let chatbotResponse = {
                          agent: 'bot',
                          type: responseType,
                          text: responseText,
                          disableInput: responseDisableInput,
                          options: responseOptions
                        }
                        this.data.push(chatbotResponse)
                      }
                    }
                  },
                  err => {
                    console.log((err.response && err.response.data) ||
                      err.message ||
                      err.toString())
                  }
                );
              },
              err => {
                console.log((err.response && err.response.data) ||
                  err.message ||
                  err.toString())
              }
            );
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())

            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      },
      messageSendHandler(e) {
        var message = e.text
        if (message != undefined && message != '') {
          let userMessage = {
            agent: 'user',
            type: 'text',
            text: message
          }
          this.data.push(userMessage)
          if (e.action != undefined) {
            message = e.value
          }
          UserDataService.sendChatbotMessage(message,this.$route.query.token).then(
            response => {
              let data = response.data
              let chatFinished = false

              for (var j=0; j<data.length; j++) {
                if(data[j].text != 'chatbot_finished') {
                  var responseType = 'text';
                  var responseText = data[j].text
                  let responseOptions = []
                  var responseDisableInput = false
                  if (data[j].buttons != undefined) {
                    responseType = 'button'
                    responseDisableInput = true
                    for (var i=0; i < data[j].buttons.length; i++) {
                      let object = {}
                      object.text = data[j].buttons[i].title
                      object.value = data[j].buttons[i].payload
                      object.action = 'postback'
                      responseOptions.push(object)
                    }
                  }
                  let chatbotResponse = {
                    agent: 'bot',
                    type: responseType,
                    text: responseText,
                    disableInput: responseDisableInput,
                    options: responseOptions
                  }
                  this.data.push(chatbotResponse)
                } else {
                  chatFinished = true;
                }
                
                ProfileAnalysisDataService.findByChatToken(this.$route.query.token).then(
                  response => {
                    if (response.status != 200) {
                      this.$router.push({ name: "profile"});
                    } else {
                      this.idProfile = response.data.id
                      this.profile = response.data
                      
                      var totalFields = 6
                      var countDefinedFields = 0
                      
                      if (this.profile.certifications != '') {
                        let certifications = this.profile.certifications
                        certifications = certifications.replaceAll("'", "\"");
                        certifications = JSON.parse(certifications)
                        this.certifications = certifications
                        countDefinedFields++;
                      }
                      
                      if (this.profile.programming_skills != '') {
                        let programming_skills = this.profile.programming_skills
                        programming_skills = programming_skills.replaceAll("'", "\"");
                        programming_skills = JSON.parse(programming_skills)
                        this.programming_skills = programming_skills
                        countDefinedFields++;
                      }
                      
                      if (this.profile.other_skills != '') {
                        let other_skills = this.profile.other_skills.substring(1, this.profile.other_skills.length-1);
                        other_skills = other_skills.replaceAll("'", "");
                        this.other_skills =  other_skills.split(',');
                      }
                      
                      if (this.profile.education != '') {
                        let education = this.profile.education
                        education = education.replaceAll("'", "\"");
                        education = JSON.parse(education)
                        this.education = education
                        countDefinedFields++;
                      }
                      
                      if (this.profile.experience_jobs != '') {
                        let jobs = this.profile.experience_jobs;
                        jobs = jobs.replaceAll("'", "\"");
                        jobs = JSON.parse(jobs)
                        this.jobs = jobs
                        countDefinedFields++;
                      }
                      
                      if (this.profile.experience != '') {
                        let experience = this.profile.experience_time;
                        experience = experience.replaceAll("'", "\"");
                        experience = JSON.parse(experience)
                        this.experience = experience
                        countDefinedFields++;
                      }
                      
                      if (this.profile.languages != '') {
                        let languages = this.profile.languages;
                        languages = languages.replaceAll("'", "\"");
                        languages = JSON.parse(languages)
                        this.languages = languages
                        countDefinedFields++;
                      }
                      
                      this.definedPercentage = (countDefinedFields/totalFields)*100;
                      this.definedPercentage = Math.round(this.definedPercentage, -1);
                    }
                  },
                  error => {
                    this.$router.push({ name: "profile"});
                    console.log((error.response && error.response.data) ||
                      error.message ||
                      error.toString())

                    if (error.response.status === 300) {
                      console.log('Logout: Token expired')
                      localStorage.removeItem('user');
                      localStorage.removeItem('company');
                      this.$store.state.auth.user = null
                      this.$store.dispatch('auth/logout');
                      this.$router.push('/login');
                    }
                  }
                );
              }
              
              if(chatFinished) {
                this.showAlert();
              }
            },
            error => {
              console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())

              if (error.response.status === 300) {
                console.log('Logout: Token expired')
                localStorage.removeItem('user');
                localStorage.removeItem('company');
                this.$store.state.auth.user = null
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }
            }
          );
        }
      },
      showAlert() {
        this.$refs['finish-modal'].show();
      },
      redirect() {
				this.$router.replace({ name: "profile-analysis", params: {}, query: {id: this.idProfile} })
			}
    }
  }
</script>