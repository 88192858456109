<template>
    <div class="row technology-skills-div mt-2 mx-4">
        <template v-if="evaluations.length > 0">
            <div class="row" style="margin-top: 1%;">
                <div class="col-xl-3 col-lg-4">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="evaluationsList"></b-pagination>
                </div>
                <div class="col-xl-6 col-lg-4">
                    <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
                        :options="pageOptions"></b-form-select>
                    <button class="btn btn-primary table-selection-button" type="button"
                        @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
                        rows</button>
                    <button class="btn btn-primary table-selection-button" type="button"
                        @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
                    <button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button"
                        type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash"
                            class="white-color" /> Delete {{ selectedItems.length }} rows</button>
                </div>
                <div class="col-xl-3 col-lg-3 margin-on-lg">
                    <b-form-input id="filter-input" v-model="filter" type="search"
                        :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                </div>
            </div>

            <b-table ref="evaluationsTable" id="evaluationsList" :per-page="perPage" :current-page="currentPage"
                :fields="headers" :items="evaluations" :filter="filter" select-mode="multi" responsive="sm" selectable
                @row-selected="onRowSelected">
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not Selected</span>
                    </template>
                </template>
                <template #cell(name)="data">
                    {{ data.item.name }}
                </template>
                <template #cell(created_at)="data">
                    {{ data.item.created_at | formatEnglishDate }}
                </template>
                <template #cell(total_evaluators)="data">
                    {{ data.item.total_evaluators }}
                </template>
                <template #cell(total_users)="data">
                    {{ data.item.total_users }}
                </template>
                <template #cell(total_evaluated_users)="data">
                    <template v-if="data.item.total_users > 0">
                        {{ data.item.total_evaluated_users }}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
                <template #cell(total_non_evaluated_users)="data">
                    <template v-if="data.item.total_users > 0">
                        {{ data.item.total_non_evaluated_users }}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
                <template #cell(reusable)="data">
                    <p v-if="data.item.total_evaluators == 0 && data.item.total_users == 0"><font-awesome-icon
                            class="completed-icon-yes" icon="check" /></p>
                    <p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
                </template>
                <template #cell(options)="data">
                    <router-link :to="{ path: '/softskills-evaluation-results-admin?id=' + data.item.id }"
                        class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
                </template>
            </b-table>
        </template>
        <template v-else>
            <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.
            </h3>
        </template>

        <div id="message" v-if="message != ''" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>

        <b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
            <template #modal-title>
                Delete evaluations
            </template>
            <div>
                <p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} evaluations?
                </p>
                <p v-else> Do you really want to delete this evaluation? </p>
            </div>
            <input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
                @click="$bvModal.hide('delete-modal')" value="No">
            <input class="btn btn-primary form-submit" type="button" @click="deleteEvaluations()" value="Yes">
        </b-modal>
    </div>
</template>

<script>
import SoftskillsDataService from '@/services/SoftskillsDataService';
import Tiptap from '@/components/Others/Tiptap'

export default {
    name: 'EvaluationConfigurationComponent',
    data() {
        return {
            evaluations: [],
            itemToDelete: '',
            selectedItems: [],
            selectedItem: null,
            selectedItemIndex: null,
            perPage: 10,
            currentPage: 1,
            filter: null,
            pageOptions: this.$paginationOptions,
            successfull: false,
            message: "",
            headers: [
                { key: 'name', label: 'Name', sortable: true, tdClass: 'tdMedium' },
                { key: 'created_at', label: 'Date', sortable: true, tdClass: 'tdMedium' },
                { key: 'total_evaluators', label: 'Num. of evaluators', tdClass: 'tdSmall' },
                { key: 'total_users', label: 'Num. of users', tdClass: 'tdSmall' },
                { key: 'total_evaluated_users', label: 'Total evaluated', tdClass: 'tdSmall' },
                { key: 'total_non_evaluated_users', label: 'Total non evaluated', tdClass: 'tdSmall' },
                { key: 'reusable', label: 'Reusable in Evaluation Processes', tdClass: 'tdMedium' },
                { key: 'options', label: 'Options', tdClass: 'tdMedium' }
            ]
        }
    },
    components: {
        Tiptap
    },
    computed: {
        rows() {
            return this.evaluations.length
        }
    },
    mounted() {
        SoftskillsDataService.findEvaluationsByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
            response => {
                this.evaluations = response.data
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    watch: {
    },
    methods: {
        onRowSelected(items) {
            this.selectedItems = items;
        },
        selectAllRows() {
            this.$refs["evaluationsTable"].selectAllRows();
        },
        clearSelected() {
            this.$refs["evaluationsTable"].clearSelected();
        },
        showDeleteModalMultiple() {
            this.$refs['delete-modal'].show();
        },
        deleteEvaluations() {
            const selectedRows = this.selectedItems.map(element => element.id);

            SoftskillsDataService.bulkDeleteEvaluation(selectedRows).then(
                response => {
                    this.message = response.data.message
                    if (response.status == 200) {
                        this.successfull = true

                        this.evaluations = this.evaluations.filter(function (value, index) {
                            return selectedRows.indexOf(value.id) == -1;
                        });

                    } else {
                        this.successfull = false
                    }

                    this.$refs['delete-modal'].hide();
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        close() {
            this.message = ''
        }
    }
};
</script>

<style scoped>
@media (max-width: 967px) {
    .margin-on-lg {
        margin-top: 1%;
    }
}
</style>