<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div  class="links-container">
        <router-link :to="{path: '/reports-admin'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{
          $t("general.reporting")
        }} menu</router-link>
        <reports-downloader :graphContent="graphContent" :documentTitle="documentTitle"/>
      </div>
      <div class="whiteColor">Users reports</div>
    </div>

    <select v-if="!isSuperAdmin" class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option value="all">All areas</option>
      		<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		</select>

    <users-graph-by-date ref="chart1" :areas="areas"/>
    <UsersGraphCVByCompany ref="chart2" :areas="areas"/>
    <UsersGraphCVByAdmins ref="chart3" :areas="areas"/>
    <UsersGraphTotalCVByCompany ref="chart4"/>
    <UsersGraphCVByLevels ref="chart5" :areas="areas"/>
  </div>
</template>

<script>
  import UsersGraphByDate from "@/components/ReportsComponents/Users/UsersGraphByDate.vue";
  import UsersGraphCVByCompany from "@/components/ReportsComponents/Users/UsersGraphCVByCompany.vue";
  import UsersGraphCVByAdmins from "@/components/ReportsComponents/Users/UsersGraphCVByAdmins.vue";
  import UsersGraphTotalCVByCompany from "@/components/ReportsComponents/Users/UsersGraphTotalCVByCompany.vue";
  import UsersGraphCVByLevels from "@/components/ReportsComponents/Users/UsersGraphCVByLevels.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";
  import AreasDataService from "@/services/AreasDataService";

  export default {
    name: 'GeneralReportsView',
    components: {
      UsersGraphByDate,
      UsersGraphCVByCompany,
      UsersGraphCVByAdmins,
      UsersGraphTotalCVByCompany,
      UsersGraphCVByLevels,
      ReportsDownloader
    },
    data() {
      return {
        graphContent: [
          {title: "Users registered by date"},
          {title: "Users CV analyzed by company"},
          {title: "Users CV analyzed by admins"},
          {title: "Total User CVs analyzed by company"},
          {title: "Users CV analyzed by level"}
        ],
        documentTitle: "user_reports.pdf",
        areas: []
      }
    },
    mounted() {
      this.getListOfAreas();
    },
    methods: {
      async getListOfAreas() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>