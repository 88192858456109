<template>
    <div v-bind:class="{ 'table-container': !home, 'col-sm-12': home }" class="technologiesStyle">
        <h4>Individual Results</h4>
        <div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="usersAnalyses"></b-pagination>
        <b-table ref="usersAnalyses" id="usersAnalyses" :per-page="perPage" :current-page="currentPage"
            :fields="headers" :items="users" responsive="sm">
            <template #cell(username)="data">
                {{ data.item.username }}
            </template>
            <template #cell(score)="data">
                {{ data.item.test_attempts[0].qualification || '-' }}
            </template>
            <template #cell(options)="data">
                <div v-if="data.item.test_attempts != undefined">
                    <b-button v-b-tooltip.hover title="Show individual results" v-if="!data.detailsShowing"
                        @click="data.toggleDetails"><font-awesome-icon icon="eye" /> </b-button>
                    <b-button v-b-tooltip.hover title="Hide individual results" v-else
                        @click="data.toggleDetails"><font-awesome-icon icon="eye-slash" /> </b-button>
                </div>
            </template>
            <template #row-details="data">
                <div class="col-sm-12 card profile-card individual" v-bind:key="data.item.id">
                    <div class="table-container">
                        <h5>Global results <small>(all attempts)</small></h5>
                        <table class="table">
                            <thead>
                                <th>Attempt number</th>
                                <th>Grades</th>
                                <th>Penalty</th>
                                <th>Questions without answer</th>
                                <th>Created at</th>
                            </thead>
                            <tbody>
                                <tr v-for="attempt in data.item.test_attempts" v-bind:key="attempt.id">
                                    <td>{{ attempt.attempt_number }}</td>
                                    <td v-if="attempt.qualification !== null">{{ attempt.qualification.toFixed(2) }} ({{
        attempt.total_correct_questions }}
                                        / {{ attempt.total_questions }})</td>
                                    <td v-else>-</td>
                                    <td>{{ attempt.incorrect_value }}</td>
                                    <td v-if="attempt.total_questions_without_value !== undefined">{{
        attempt.total_questions_without_value }}</td>
                                    <td v-else>-</td>
                                    <td>{{ attempt.created_at | formatEnglishDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Results per question <small>(last attempt)</small></h5>
                        <table class="table">
                            <thead>
                                <th>Question</th>
                                <th>Areas</th>
                                <th>Response</th>
                            </thead>
                            <tbody>
                                <tr v-for="question in data.item.questions_results" v-bind:key="question.id"
                                    v-bind:class="{ 'alert-success': question.is_correct, 'alert-danger': !question.is_correct }">
                                    <td>{{ question.title }}</td>
                                    <td>
                                        <div class="tags-container">
                                            <ul class="inline-tags">
                                                <li v-for="tag in question.tags" v-bind:key="tag.id" tabindex="0"
                                                    class="ti-tag ti-valid inline-tags">
                                                    <div class="ti-content">
                                                        <div class="ti-tag-center">
                                                            <span class="">{{ tag.name }}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td v-if="question.is_correct"><font-awesome-icon icon="check" /> <b>Correct</b>
                                    </td>
                                    <td v-else><font-awesome-icon icon="ban" /> <b>Wrong</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col-sm-12 chart-container">
                            <h5>Results per category <small>(last attempt)</small></h5>
                            <div class="row">
                                <div class="col-sm-6" v-for="chart in data.item.chartData" v-bind:key="chart.id">
                                    <h6 class="title">Category "<b>{{ chart.id }}</b>" Results Summary</h6>
                                    <pie-chart :data="chart" :options="chartOptions"></pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import PieChart from "@/components/Others/Charts/PieChart.js";

export default {
    name: "IndividualResults",
    data() {
        return {
            headers: [
                { key: "username", label: "User" },
                { key: "score", label: "Score", sortable: true },
                { key: "options", label: "Options" },
            ],
            perPage: 10,
            currentPage: 1,
            rows: 10,
            message: "",
            home: Boolean,
            users: [],
            test: {},
            questionsResults: [],
            usersChartData: [],
            chartLabels: ["Correct Answers", "Wrong Answers"],
            chartBackgroundColor: ["#41B883", "#E46651"],
            chartOptions: {
                hoverBorderWidth: 20
            },
            chartsData: []
        };
    },
    components: {
        PieChart
    },
    mounted() {
        TestDataService.globalResults(this.$route.query.id).then(
            (response) => {
                if (response.status == 200) {
                    this.test = response.data.test;
                    this.users = response.data.users_results;
                    for (var x = 0; x < this.users.length; x++) {
                        if (this.users[x].test_attempts != undefined) {
                            this.users[x].test_attempts.forEach(test_attempt => {
                                test_attempt.incorrect_value = this.test.incorrect_value
                                test_attempt.question_value = 10 / test_attempt.total_questions

                                for (let i = 0; i <= (test_attempt.total_questions - test_attempt.total_correct_questions); i++) {
                                    if (test_attempt.qualification === ((test_attempt.total_correct_questions * test_attempt.question_value) - (i * test_attempt.question_value * test_attempt.incorrect_value))) {
                                        test_attempt.total_questions_without_value = test_attempt.total_questions - i - test_attempt.total_correct_questions
                                    }
                                }
                            })

                            this.users[x].chartData = [];

                            let resultsPerTag = []
                            let noTag = {}
                            noTag.tagName = "NO TAG"
                            noTag.results = [0, 0]
                            for (var i = 0; i < this.users[x].questions_results.length; i++) {
                                if (this.users[x].questions_results[i].tags.length === 0) {
                                    if (this.users[x].questions_results[i].is_correct) {
                                        noTag.results[0] += 1
                                    } else {
                                        noTag.results[1] += 1
                                    }
                                }
                                for (var j = 0; j < this.users[x].questions_results[i].tags.length; j++) {
                                    var flag = false
                                    for (var k = 0; k < resultsPerTag.length; k++) {
                                        if (resultsPerTag[k].tagName == this.users[x].questions_results[i].tags[j].name) {
                                            flag = true
                                            if (this.users[x].questions_results[i].is_correct) {
                                                resultsPerTag[k].results[0] += 1
                                            } else {
                                                resultsPerTag[k].results[1] += 1
                                            }
                                            break;
                                        }
                                    }
                                    if (!flag) {
                                        let resultsPerTagObject = {}
                                        resultsPerTagObject.tagName = this.users[x].questions_results[i].tags[j].name
                                        resultsPerTagObject.results = [0, 0]
                                        if (this.users[x].questions_results[i].is_correct) {
                                            resultsPerTagObject.results[0] += 1
                                        } else {
                                            resultsPerTagObject.results[1] += 1
                                        }
                                        resultsPerTag.push(resultsPerTagObject)
                                    }
                                }
                            }
                            if (noTag.results[0] !== 0 || noTag.results[1] !== 0)
                                resultsPerTag.push(noTag)

                            for (var y = 0; y < resultsPerTag.length; y++) {
                                let chartDataObject = {}
                                chartDataObject.id = resultsPerTag[y].tagName
                                chartDataObject.labels = this.chartLabels;
                                chartDataObject.datasets = [
                                    {
                                        label: 'Tag "' + resultsPerTag[y].tagName + '" Results Summary',
                                        backgroundColor: this.chartBackgroundColor,
                                        data: resultsPerTag[y].results
                                    }
                                ];

                                this.users[x].chartData.push(chartDataObject)
                            }
                        }
                    }
                    console.log('this.users: ', this.users)
                }
            },
            (error) => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString());
            }
        );
    },
    methods: {
        close() {
            document.getElementById("message").style.display = "none"
        }
    }
};
</script>