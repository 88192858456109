<template>
	<div class="table-container  technologiesStyle">
		<div id="messageDelete" v-if="message"
			:class="successfull ? 'message alert alert-success' : 'messsage alert alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>

		<div class="row mt-1" v-if="users.length > 0">
			<div class="row mt-1">
				<div class="col-sm-2">
					<b-pagination class="pagination" v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="usersList"></b-pagination>
				</div>
				<div class="col-sm-1">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
				</div>
				<div class="col-sm-2">
					<div class="row">
						<div class="col-sm-3">
							<p>Roles: </p>
						</div>
						<div class="col-sm-9">
							<b-form-select class="numPerPage paginationSelectorTable" v-model="rolesModel"
								@change="filterUsers" :options="roles"> </b-form-select>
						</div>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="row">
						<div class="col-sm-3">
							<p>Active: </p>
						</div>
						<div class="col-sm-9">
							<b-form-select class="numPerPage paginationSelectorTable mx-1" v-model="activeModel"
								@change="filterUsers" :options="actives"> </b-form-select>
						</div>
					</div>
				</div>
				<div class="col-sm-5">
					<button v-b-tooltip.hover class="btn btn-primary table-selection-button" type="button"
						@click="selectAllRows"><font-awesome-icon icon="check" class="white-color" />&nbsp;Select all
						rows</button>
					<button v-if="!selectedItems.length == 0" v-b-tooltip.hover
						class="btn btn-primary table-selection-button" type="button"
						@click="clearSelected"><font-awesome-icon icon="ban" class="white-color" />&nbsp;Clear
						All</button>
					<button v-else v-b-tooltip.hover class="btn btn-primary table-selection-button"
						style="margin-right: 5% !important;" type="button" @click="clearSelected"><font-awesome-icon
							icon="ban" class="white-color" />&nbsp;Clear All</button>
					<button v-b-tooltip.hover v-if="selectedItems.length > 0"
						class="btn btn-primary table-selection-button delete-button"
						style="margin-right: 5% !important;" type="button" @click="showDeleteModalMultiple">
						<font-awesome-icon icon="trash" class="white-color" />&nbsp;Delete {{ selectedItems.length }}
						rows
					</button>
					<b-form-input id="filter-input" v-model="filter" type="search" style="width: 25% !important;"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>
		</div>

		<div class="row">
			<h6 class="text-center mt-2 mb-1"><b-button class="btn btn-info"><font-awesome-icon
						icon="info-circle" />&nbsp;&nbsp;</b-button>Administrators from a company can not be selected or
				deleted. These are shown in light green.</h6>
		</div>

		<br v-if="users.length > 0">
		<b-table ref="configurationUsersTable" v-if="users.length > 0" id="usersList" :per-page="perPage"
			:current-page="currentPage" :fields="headers" :items="filterUsers()" :tbody-tr-class="rowClass"
			:filter="filter" responsive="sm" select-mode="multi" :selectable="selectable" @row-selected="onRowSelected">
			<template #cell(area)="data">
				<p>{{ data.item.areas.length }}</p>
			</template>
			<template #cell(username)="data">
				<p>{{ data.item.username }}</p>
			</template>
			<template #cell(name)="data">
				<p>{{ data.item.name }}</p>
			</template>
			<template #cell(surname)="data">
				<p>{{ data.item.surname }}</p>
			</template>
			<template #cell(roles)="data">
				<p v-if="data.item.role.name != null">{{ data.item.role.name.toUpperCase() }}</p>
			</template>
			<template #cell(active)="data">
				<p v-if="data.item.active != null && data.item.active"><font-awesome-icon class="completed-icon-yes"
						icon="check" /></p>
				<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
			</template>

			<template #cell(created_at)="data">
				<p>{{ data.item.created_at | formatEnglishDate }}</p>
			</template>

			<template #cell(options)="data">
				<button v-b-tooltip.hover :disabled="(data.item.email == undefined || data.item.email == '')"
					:title="(data.item.email == undefined || data.item.email == '') ? '' : data.item.email"
					:class="(data.item.email == undefined || data.item.email == '') ? 'icon-disabled btn btn-secondary not-clickable' : 'btn btn-secondary not-clickable'">
					<font-awesome-icon icon="envelope" />
				</button>

				<router-link v-if="data.item.id == userIdSession" v-b-tooltip.hover title="Edit"
					:to="{ path: '/user-profile' }" class="btn btn-secondary"><font-awesome-icon
						icon="edit"/></router-link>

				<router-link v-else v-b-tooltip.hover title="Edit"
					:to="{ path: '/admin-configuration-users-update-user?id=' + data.item.id }"
					class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>

				<button v-if="data.item.id != userIdSession && data.item.active" v-b-tooltip.hover
					title="Deactivate user" class="btn btn-secondary"
					@click="showDeactivateUserModal(data.item, data.index)" :disabled="data.item.role.name == 'admin' ? true : false"><font-awesome-icon icon="ban" /></button>
				<button v-if="data.item.id != userIdSession && !data.item.active" v-b-tooltip.hover
					title="Activate user" class="btn btn-secondary"
					@click="showActivateUserModal(data.item, data.index)"><font-awesome-icon icon="check" /></button>
				<button v-if="data.item.id != userIdSession" v-b-tooltip.hover title="Send Mail to Reset Password"
					class="btn btn-secondary" @click="showSendModal(data.item)"><font-awesome-icon
						icon="paper-plane" /></button>
			</template>
		</b-table>
		<template v-else>
			<h3 class="no-results-msg" v-if="!dataLoading"><font-awesome-icon icon="times-circle" class="black-color" />
				No results found.</h3>
			<h3 class="no-results-msg" v-else><b-spinner
					class="align-middle analysis-spinner"></b-spinner>&nbsp;&nbsp;Loading data...</h3>
		</template>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete User
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} users? </p>
				<p v-else> Do you really want to delete this user? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteUser()" value="Yes">
		</b-modal>

		<b-modal id="activate-modal" ref="activate-modal" title="Activate-modal" hide-footer>
			<template #modal-title>
				Activate User
			</template>
			<div>
				<p>Do you really want to activate this user account? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('activate-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="activateUser()" value="Yes">
		</b-modal>

		<b-modal id="deactivate-modal" ref="deactivate-modal" title="Deactivate-modal" hide-footer>
			<template #modal-title>
				Deactivate User
			</template>
			<div>
				<p>Do you really want to deactivate this user account? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('deactivate-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deactivateUser()" value="Yes">
		</b-modal>

		<b-modal id="send-modal" ref="send-modal" title="Send-modal" hide-footer>
			<template #modal-title>
				Send mail to reset password
			</template>
			<div>
				<p>Do you really want to send {{ selectedUserNameSurname }} an email to reset his/her password?</p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="sendChangePassword(selectedUserId)"
				value="Yes">
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('send-modal')" value="No">
		</b-modal>
	</div>
</template>

<script>
import UserDataService from '@/services/UserDataService';
import AuthDataService from '@/services/AuthDataService';

export default {
	name: 'UsersListComponent',
	data() {
		return {
			dataLoading: false,
			users: [],
			message: "",
			roles: [],
			actives: ["All", "Yes", "No"],
			rolesModel: "All",
			activeModel: "All",
			successfull: false,
			perPage: 5,
			pageOptions: this.$paginationOptions,
			currentPage: 1,
			filter: null,
			clickable: true,
			selectedUser: null,
			selectedUserIndex: null,
			selectedUserToActivate: null,
			selectedUserToActivateIndex: null,
			selectedUserToDeactivate: null,
			selectedUserToDeactivateIndex: null,
			selectedUserNameSurname: null,
			selectedUserId: null,
			userIdSession: "",
			selectable: true,
			selectedItems: []
		}
	},
	components: {
	},
	computed: {
		rows() {
			return this.users.length
		},
		headers() {
			return [
				{ key: 'area', sortable: true, label: 'Number of areas belonging' },
				{ key: 'username', sortable: true, label: 'Username' },
				{ key: 'name', sortable: true, label: 'Name' },
				{ key: 'surname', sortable: true, label: 'Surname' },
				{ key: 'roles', sortable: true, label: 'Role' },
				{ key: 'active', sortable: true, label: 'Active' },
				{ key: 'created_at', sortable: true, label: 'Created at' },
				{ key: 'options', label: 'Options' }
			];
		}
	},
	beforeMount() {
		this.dataLoading = true
	},
	mounted() {
		this.userIdSession = this.$store.state.auth.user.id;
		this.findUsersByCompany();
		this.roles.push("All");
		this.registerBoard();
	},
	methods: {
		onRowSelected(items) {
			this.selectedItems = items.filter(item => item.role.name !== 'admin')
			items = items.filter(item => item.role.name !== 'admin')
		},
		selectAllRows() {
			this.$refs["configurationUsersTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["configurationUsersTable"].clearSelected();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		findUsersByCompany() {
			this.dataLoading = true
			UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
				response => {
					if (200 == response.status) {
						this.users = response.data
					} else {
						this.message = "Error while retrieving users from database";
						this.successfull = false;
					}
					this.dataLoading = false
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while retrieving users from database";
					this.successfull = false;
					this.dataLoading = false

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		registerBoard() {
			this.dataLoading = true
			AuthDataService.registerBoard().then(
				response => {
					for (var i = 0; i < response.data.length; i++) {
						this.roles.push(response.data[i].name);
					}
					this.dataLoading = false
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while registering board";
					this.successfull = false;

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
					this.dataLoading = false
				}
			);
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return
			if (item.role.name == 'admin') return 'disabled-row'
		},
		showSendModal(item) {
			this.selectedUserNameSurname = item.name + ' ' + item.surname
			this.selectedUserId = item.id
			this.$refs['send-modal'].show();
		},
		sendChangePassword(idUser) {
			UserDataService.sendPasswordResetAlert(idUser).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true
						this.$refs['send-modal'].hide();
					} else {
						this.message = "Error while sending password reset message";
						this.successfull = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while sending password reset message";
					this.successfull = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		deleteUser() {
			const idsTmp = this.selectedItems.map(function (e) { return e.id })
			UserDataService.delete(idsTmp).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true
					} else {
						this.message = "Error while deleting users";
						this.successfull = false;
					}
					this.users.splice(this.selectedUserIndex, 1)
					this.$refs['delete-modal'].hide();
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while deleting users. Please note that there are users who cannot be deleted because they have activity within the platform.";
					this.successfull = false;
					this.$refs['delete-modal'].hide();

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		filterUsers() {
			if (this.rolesModel == "All") {
				if (this.activeModel == "All") {
					return this.users;
				}
				else {
					const active = this.activeModel == "Yes" ? true : false;
					return this.users.filter(field => field.active == active);
				}
			} else {
				if (this.activeModel == "All") {
					return this.users.filter(field => field.role.name == this.rolesModel);
				} else {
					const active = this.activeModel == "Yes" ? true : false;
					return this.users.filter(field => field.role.name == this.rolesModel && field.active == active);
				}
			}
		},
		close() {
			document.getElementById("messageDelete").style.display = "none"
		},
		showActivateUserModal(user, index) {
			this.selectedUserToActivate = user
			this.selectedUserToActivateIndex = index
			this.$refs['activate-modal'].show();
		},
		showDeactivateUserModal(user, index) {
			this.selectedUserToDeactivate = user
			this.selectedUserToDeactivateIndex = index
			this.$refs['deactivate-modal'].show();
		},
		activateUser() {
			this.selectedUserToActivate.active = true

			console.log('this.selectedUserToActivate: ', this.selectedUserToActivate)

			UserDataService.updateActive({ 'user': this.selectedUserToActivate }).then(
				response => {
					if (200 == response.status) {
						this.$refs['activate-modal'].hide();
					} else {
						this.message = "Error while activating user account";
						this.successfull = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while activating user account";
					this.successfull = false;

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		deactivateUser() {
			this.selectedUserToDeactivate.active = false

			UserDataService.update({ 'user': this.selectedUserToDeactivate }).then(
				response => {
					if (200 == response.status) {
						this.$refs['deactivate-modal'].hide();
					} else {
						this.message = "Error while deactivating user account";
						this.successfull = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					this.message = "There was an error while deactivating user account";
					this.successfull = false;

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		}
	}
};
</script>

<style scoped>
.pagination-filter {
	display: flex;
	align-items: center;
}

.pagination {
	margin-bottom: 0px !important;
}

.numPerPage {
	height: 100%;
}
</style>