<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin-configuration' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Configuration</router-link>
				<router-link to="/admin-configuration-areas-create-area"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> CREATE
					AREA</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/admin-configuration' }" class="breadcumb"> Configuration > </router-link>
				<router-link :to="{ path: '/admin-configuration-areas' }" class="final-breadcumb"> Areas </router-link>
			</div>
		</div>

		<div id="message" v-if="areaAdded" class="alert alert-success col-sm-11 align-item-center">
			Area succesfully created! <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
					icon="times" /> </b-button>
		</div>
		<div id="message2" v-if="areaUpdated" class="alert alert-success col-sm-11 align-item-center">
			Area succesfully updated! <b-button @click="close2" class="cross-button"><font-awesome-icon class="cross"
					icon="times" /> </b-button>
		</div>
		<div id="message3" v-if="areaDeleted" class="alert alert-success col-sm-11 align-item-center mt-3">
			Area succesfully deleted! <b-button @click="close3" class="cross-button"><font-awesome-icon class="cross"
					icon="times" /> </b-button>
		</div>

		<div class="col-sm-12 col-centered">
			<areas-list @deleteArea="deleteArea" />
		</div>
	</div>
</template>

<script>
import AreasList from "@/components/AreasComponents/List.vue";

export default {
	name: 'AreasManagementView',
	data() {
		return {
			areaDeleted: false
		}
	},
	components: {
		AreasList
	},
	props: {
		areaAdded: {
			type: Boolean,
			default: false
		},
		areaUpdated: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
		deleteArea() {
			this.areaDeleted = true
		},
		close() {
			document.getElementById("message").style.display = "none"
		},
		close2() {
			document.getElementById("message2").style.display = "none"
		},
		close3() {
			document.getElementById("message3").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.nav-link-user-submenu {
	color: #13283B !important;
}
</style>
