<template>
    <div class= "col-sm-12" style="padding: 2% !important">
        <template v-if="tests.length > 0">
            <div class="row" style="margin-top: 1%;">
                <div class="col-sm-2">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="testsList"
                    ></b-pagination>
                </div>
                <div class="col-sm-7">
                    <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
                    <div class="numPerPage paginationSelectorTable mx-3 my-2">Level of difficulty: </div>
                    <b-form-select class="numPerPage paginationSelectorTable" v-model="difficultyModel" @change="filterTests" :options="levels"> </b-form-select>  
                </div>
                <div class="col-sm-3">
                    <b-form-input 
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        :placeholder="$t('others.search-placeholder-msg')"
                    ></b-form-input>
                </div>
            </div>
          
            <b-table v-if="show" ref="testsTable"
                id="testsList"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="headers" 
                :items="filterTests()"
                :filter="filter"
                responsive="sm">
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not Selected</span>
                    </template>
                </template>
                <template #cell(title)="data">
                    {{data.item.title}}
                    <ul class="ti-tags small">
                        <li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
                            <div class="ti-content">
                                <div class="ti-tag-center">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>
                <template #cell(description)="data">
                    <div v-if="data.item.description != null && data.item.description !='' ">
                        {{data.item.description}}
                    </div>
                    <div v-else>
                        No description provided
                    </div>
                </template>
                <template #cell(difficulty_level)="data">
                    <p v-if="data.item.numberQuestions != 0" class="difficultyLevelTag" :style="'background-color: ' + colorDiff[data.item.difficulty_level.name]">
                        {{data.item.difficulty_level.name}} 
                    </p>
                    <p v-else class="difficultyLevelTag" :style="'background-color: ' + colorDiff[data.item.difficulty_level.name]">
                        {{data.item.difficulty_level.name.toUpperCase()}}
                    </p>
                </template>
                <template #cell(created_at)="data">
                    {{ data.item.created_at | formatEnglishDate }}
                </template>
                <template #cell(options)="data">
                    <router-link v-if="$route.query.type === 'user'" :disabled="data.item.user_results.length == 0" v-b-tooltip.hover title="Summary" :to="{path: '/test-result?id=' + data.item.id + '&id_user=' + id_user}" class="btn btn-secondary"><font-awesome-icon icon="list" /></router-link>
                    <router-link v-else :disabled="data.item.candidate_results.length == 0" v-b-tooltip.hover title="Summary" :to="{path: '/test-result?id=' + data.item.id + '&id_user=' + id_user + '&type=candidate'}" class="btn btn-secondary"><font-awesome-icon icon="list" /></router-link>
                </template>
            </b-table>
        </template>
        <template v-else>
            <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
        </template>
    </div>
</template>

<script>
    import TestDataService from "@/services/TestDataService";

    export default {
        name: "TestBusinessProcessListComponent",
        data() {
            return {
                show: false,
                id_user: this.$route.query.id_user,
                tests: [],
                successfull: false,
                message: "",
                perPage: 10,
                pageOptions: this.$paginationOptions,
                currentPage: 1,
                filter: null,
                difficulties: [],
                colorDiff: {},
                testsId: [],
                difficultyModel: "All",
                levels: ["All", "Low", "Medium", "High","Very High" ],
                headers: [
                    { key: "title", label: "Title", sortable: true, tdClass: "tdBig" },
                    { key: "description", label: "Description", tdClass: "tdBig" },
                    {
                        key: "difficulty_level",
                        label: "Level of difficulty",
                        sortable: true,
                        tdClass: "tdMedium",
                    },
                    { key: "created_at", label: "Created at", tdClass: "tdMedium" },
                    { key: "options", label: "Options", tdClass: "tdMedium" },
                ]
            }
        },
        mounted() {
            this.getTestBoard();
            this.getTests();
        },
        computed: {
            rows() {
                return this.tests.length
            }
        },
        methods: {
            getTestBoard(){
                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                TestDataService.getTestBoard(currentArea).then(
                    response => {
                        if (response.status == 200) {
                            this.difficulties = response.data.difficulty_levels
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            getTests(){
                if (this.$route.query.type === 'user') {
                    TestDataService.findUserResultsByBusinessProcess(this.$route.query.id_user, this.$route.query.id_business_process).then(
                        response => {
                            if (response.status == 200){
                                let dataTmp = response.data;

                                dataTmp.forEach((d) => {
                                    this.testsId.push(d.id)
                                }) 

                                TestDataService.findQuestionsForTestsId(this.testsId).then(
                                    response2 => {
                                        if (response2.status == 200) {
                                            let tests = response2.data;

                                            for(var i=0; i<tests.length; i++) {
                                                var questions = tests[i].questions
                                                var averageDifficulty = 0
                                                var weightSum = 0

                                                questions.forEach((question) => {
                                                    var questionWeight = 0;
                                                    for(var k=0; k<this.difficulties.length; k++) {
                                                        if(this.difficulties[k].id == question.id_difficulty_level) {
                                                            questionWeight = this.difficulties[k].level
                                                            break;
                                                        }
                                                    }
                                                        
                                                    weightSum = weightSum + questionWeight;
                                                }) 
                                                    
                                                if (questions.length > 0)
                                                    averageDifficulty = weightSum / questions.length;
                                                else
                                                    averageDifficulty = 0;

                                                for(var k=0; k<dataTmp.length; k++) {
                                                    if(dataTmp[k].id == tests[i].test.id) {
                                                        dataTmp[k].averageDifficulty = averageDifficulty.toFixed("2")
                                                        break;
                                                    }
                                                }
                                                
                                            }
                                            
                                            this.tests = dataTmp

                                            this.difficulties.forEach((difficulty) => {
                                                this.colorDiff[difficulty.name] = difficulty.color
                                            })

                                            let finallyTests = []

                                            this.tests.forEach(test => {
                                                if (test.user_results.length > 0) finallyTests.push(test)
                                            })

                                            this.tests = finallyTests

                                            this.show = true;
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }
                        },
                        error => {
                            console.error((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                        
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    TestDataService.findCandidateResultsByBusinessProcess(this.$route.query.id_user, this.$route.query.id_business_process).then(
                        response => {
                            if (response.status == 200){
                                let dataTmp = response.data;

                                dataTmp.forEach((d) => {
                                    this.testsId.push(d.id)
                                }) 

                                TestDataService.findQuestionsForTestsId(this.testsId).then(
                                    response2 => {
                                        if (response2.status == 200) {
                                            let tests = response2.data;

                                            for(var i=0; i<tests.length; i++) {
                                                var questions = tests[i].questions
                                                var averageDifficulty = 0
                                                var weightSum = 0

                                                questions.forEach((question) => {
                                                    var questionWeight = 0;
                                                    for(var k=0; k<this.difficulties.length; k++) {
                                                        if(this.difficulties[k].id == question.id_difficulty_level) {
                                                            questionWeight = this.difficulties[k].level
                                                            break;
                                                        }
                                                    }
                                                        
                                                    weightSum = weightSum + questionWeight;
                                                }) 
                                                    
                                                if (questions.length > 0)
                                                    averageDifficulty = weightSum / questions.length;
                                                else
                                                    averageDifficulty = 0;

                                                for(var k=0; k<dataTmp.length; k++) {
                                                    if(dataTmp[k].id == tests[i].test.id) {
                                                        dataTmp[k].averageDifficulty = averageDifficulty.toFixed("2")
                                                        break;
                                                    }
                                                }
                                                
                                            }
                                            
                                            this.tests = dataTmp

                                            this.difficulties.forEach((difficulty) => {
                                                this.colorDiff[difficulty.name] = difficulty.color
                                            })

                                            let finallyTests = []

                                            this.tests.forEach(test => {
                                                if (test.candidate_results.length > 0) finallyTests.push(test)
                                            })

                                            this.tests = finallyTests

                                            this.show = true;
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }
                        },
                        error => {
                            console.error((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                        
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }
            },
            filterTests(){
                if(this.difficultyModel == "All"){
                    return this.tests;
                } else {
                    return this.tests.filter(field => field.difficulty_level.name == this.difficultyModel);
                }
            }
        }
    };
</script>
