<template>
	<div class="col-sm-12">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link :to="getRoleRoute()" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
			</div>
			<div class="whiteColor">My Account</div>
		</div>
			
		<div class="col-sm-12 col-centered">
			<profile-component/>
		</div>
	</div>
</template>

<script>
	import ProfileComponent from "@/components/ProfileComponent.vue";

	export default {
	  	name: 'AdminSettingsView',
	  	components: {
	    	ProfileComponent
	    },
	    computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			},
			isTM(){
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
						return true;
					}
				}
				return false
	        }
		},
		methods: {
			getRoleRoute() {
				return {path: '/' + this.$store.state.auth.user.role.name};
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>
