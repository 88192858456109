<template>
	<div class="full-container-login">
		<div class="home-header">
			<div class="row header-row">
			    <appHomeHeader></appHomeHeader>
			    <div id="login-header-image" class="col-lg-6 col-md-12">
					<img class="main form-image-logo" src="images/icons/arbol_azul.png">
					<div class="login" style="height: 333px !important;">
						<h1 class="form-title">{{title}}</h1>
						<form action class="form" @submit.prevent="handleForm">
							<div class="form-group">
								<input class="form-input" type="text" id="username" required placeholder="Username" v-model="user.username">
								<div v-if="veeErrors.has('username')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									Username is required!
								</div>
							</div>
							<div class="form-group">
								<input class="form-input" type="email" id="email" required placeholder="Email" v-model="user.email">
								<div v-if="veeErrors.has('email')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
									Email is required!
								</div>
							</div>
							<input class="btn btn-primary form-submit" type="submit" value="Send">
							<div class="form-group">
								<div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<appHomeBody></appHomeBody>
    	<appFooter></appFooter>
	</div>
</template>

<script>
	import User from '@/models/user';
	import UserDataService from '@/services/UserDataService';
	import Footer from "@/components/Footer.vue";
  	import HomeHeader from "@/components/HomeHeader.vue";
  	import HomeBody from "@/components/HomeBody.vue";

	export default {
		name: 'RequestResetPassword',
		components: {
			appFooter: Footer,
			appHomeBody: HomeBody,
			appHomeHeader: HomeHeader
	    },
		data() {
			return {
				user: new User('',''),
				loading: false,
				message: '',
				title: "REQUEST RESET PASSWORD"
			};
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.status.loggedIn;
			}
		},
		created() {
			const recaptcha = this.$recaptchaInstance

			if (recaptcha)
				recaptcha.showBadge()
			
			if (this.loggedIn) {
				if (this.$store.state.auth.user.role.name === 'user') {
					this.$router.push('/user');
				} else if (this.$store.state.auth.user.role.name === 'admin') {
					this.$router.push('/admin');
				} else if (this.$store.state.auth.user.role.name === 'examiner') {
					this.$router.push('/examiner');
				} else if (this.$store.state.auth.user.role.name === 'validator') {
					this.$router.push('/questions-validator');
				} else if (this.$store.state.auth.user.role.name === 'certifier') {
					this.$router.push('/certifications-validator');
				} else if (this.$store.state.auth.user.role.name === 'superadmin') {
					this.$router.push('/superadmin');
				}
			}
		},
		methods: {
			handleForm() {
				this.loading = true;
				UserDataService.findByRequestPasswordForm(this.user).then(
					response => {
						if (response.status == 200) 
							this.$router.push({ name: "login", params: {requestResetPassword: true}})
						else
							this.message = response.data.message
					},
					error => {
						this.message = error.response.data.message
						console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>