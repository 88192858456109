<template>
  	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link :to="{path: '/videoconferences'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Recorded Video Interviews</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/videoconferences' }" class="breadcumb"> Recorded Video Interviews > </router-link>
				<router-link :to="{ path: '/create-videoconference' }" class="final-breadcumb"> Create videoconference </router-link>
			</div>
		</div>

		<div class="col-sm-8 col-centered mt-4">
			<create-videoconference/>
		</div>
  	</div>
</template>

<script>
  	import CreateVideoconference from "@/components/VideoconferencesComponents/CreateVideoconference";

	export default {
	  	name: 'Create',
	  	components: {
	      	CreateVideoconference,
	    },
		methods: {
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>