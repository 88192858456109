<template>
  <login-form :previousRegister="previousRegister" :requestResetPassword="requestResetPassword" :resetPassword="resetPassword"/>
</template>

<script>
  import LoginForm from "@/components/UserForms/LoginForm.vue";

  export default {
    name: "LoginView",
    components: {
      LoginForm
    },
    data: () => ({
    }),
    props: {
      previousRegister: {
        type: Boolean,
        default: false
      },
      requestResetPassword: {
        type: Boolean,
        default: false
      },
      resetPassword: {
        type: Boolean,
        default: false
      }
    },
    created(){
    }
  };
</script>