<template>
	<div>
		<h4 class="reports-title">Number of questions answered in an area and its value of correct/wrong distribution
		</h4>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<bubble-chart :ref="chartId" :chartId="chartId" :key="graphLoaded" :xAxesCategory="xAxesCategory"
				:yAxesValue="yAxesValue" :xAxesTitle="xAxesTitle" :yAxesTitle="yAxesTitle"
				:bubbleSizeCategory="bubbleSizeCategory" :bubbleSizeTitle="bubbleSizeTitle" :isUserResult="isUserResult"
				:data="data"></bubble-chart>
		</div>
	</div>
</template>

<script>
import ReportsDataService from '@/services/ReportsDataService';
import BubbleChart from "@/components/Others/Charts/BubbleChart";

export default {
	name: 'UserBubbleGraphByQuestion',
	data() {
		return {
			chartId: "chartUserBubbleGraphByQuestion",
			data: [],
			xAxesCategory: "id",
			yAxesValue: "level",
			bubbleSizeCategory: "count",
			xAxesTitle: "Question id",
			yAxesTitle: "Question level",
			bubbleSizeTitle: "Number of times asked",
			isUserResult: true,
			graphLoaded: false,
			graphLoading: false
		}
	},
	components: {
		BubbleChart
	},
	props: {
		user: Object,
		userAreaID: Number
	},
	methods: {
		async fetchData() {
			await ReportsDataService.userQuestionsActivityComparedResultsInArea(this.user.id, this.userAreaID).then(
				response => {
					if (response.status == 200) {
						let tmpArray = []

						let testResults = response.data;
						console.log('tests result: ', testResults)
						testResults.forEach((element) => {
							var tmp = {}
							tmp.id = element.id_question
							tmp.level = element.question.difficulty_level.name
							tmp.order = element.question.difficulty_level.id
							tmp.count = element.total_ocurrences
							tmp.isCorrect = element.is_correct

							tmpArray.push(tmp)
						})

						this.data = tmpArray;

						this.graphLoaded = this.graphLoaded ? false : true;
						this.graphLoading = false;
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.graphLoading = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		}
	},
	created() {
		this.graphLoading = true;
		this.fetchData();
	}
};
</script>