<template>
	<div v-bind:class = "{'table-container':!home, 'col-sm-12': home}">
		<h4>Questions repositories</h4>
		<div class="row">
			<div class="col-sm-3">
				<b-pagination
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
					aria-controls="repositoriesList"
			    ></b-pagination>
			</div>
			<div class="col-sm-4">
				<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
			</div>
			<div class="col-sm-5">
				<b-form-input 
					id="filter-input"
	              	v-model="filter"
	              	type="search"
	              	:placeholder="$t('others.search-placeholder-msg')"
            	></b-form-input>
			</div>
		</div>
		
		<b-table ref="repositoriesTable"
			id="repositoriesList"
			:per-page="perPage"
			:current-page="currentPage"
			:fields="headers" 
			:items="repositories"
			:filter="filter"
			responsive="sm"
			selectable
			@row-selected="onRowSelected"
			v-bind:class = "{'clickable':clickable}">
			<template #cell(name)="data">
		        {{ data.item.name }}
	      	</template>
			<template #cell(description)="data">
		        {{ data.item.description }}
	      	</template>
			<template #cell(numberQuestions)="data">
		        {{ data.item.numberQuestions }}
	      	</template>
			<template #cell(created_at)="data">
		        {{ data.item.created_at | formatEnglishDate}}
	      	</template>
			<template #cell(updated_at)="data">
		        {{ data.item.updated_at | formatEnglishDate}}
	      	</template>
			<template #cell(options)="data">
				<router-link v-b-tooltip.hover title="Edit" :to="{path: '/update-repository?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
				<button v-b-tooltip.hover title="Delete" class="btn btn-secondary" @click="deleteRepository(data.item.id, data.index)"><font-awesome-icon icon="trash" /></button>
				<router-link v-b-tooltip.hover title="Add question" :to="{path: '/create-question?id_repository=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="plus" /></router-link>
	      	</template>
		</b-table>
		<div v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}}
      	</div>
	</div>
</template>

<script>
	import RepositoryDataService from '@/services/RepositoryDataService';

	export default {
		name: 'QuestionsListComponent',
		data() {
			return {
				repositories: [],
				message: "",
				successfull: false,
				perPage: 10,
				filter: null,
				pageOptions: this.$paginationOptions,
				currentPage: 1,
				clickable: true
			}
	    },
		mounted() {
			this.perPage = this.$store.state.auth.user.table_records_per_page

		    RepositoryDataService.findAll(this.$store.state.auth.user.id_company).then(
				response => {
					this.repositories = response.data
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
	  	},
	    props: {
	    	home: Boolean,
	    	headers: Array,
	    },
	    computed: {
			rows() {
				return this.repositories.length
			}
	    },
	  	methods: {
	  		onRowSelected(items) {
		        let selectedRepository = items[0]
		        var idSelectedRepository = selectedRepository.id;
		        this.$router.push('/questions?id_repository='+idSelectedRepository)
	      	},
	  		deleteRepository(id, index) {
	      		if(confirm("Do you really want to delete this repository and all his related questions?")) {
		      		RepositoryDataService.delete(id).then(
						response => {
							this.message = response.data.message
							if (response.status == 200) {
								this.successfull = true
							} else {
								this.successfull = false
							}
							this.repositories.splice(index, 1)
						},
						error => {
							console.log((error.response && error.response.data) ||
								error.message ||
								error.toString())
							
							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
						}
				    );
		      	}
	      	}
	    }
	};
</script>