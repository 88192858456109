<template>
	<div class="dashboard">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link :to="{ path: '/modules' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to suite tools</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/evaluation' }" class="breadcumb"> {{ title }} </router-link>
			</div>
		</div>

		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName" :cardImage="card.image" :cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
import FooterMini from "@/components/FooterMini.vue";
import MenuCard from '@/components/MenuCard';

export default {
	name: 'SubmenuEvaluationAdmin',
	data() {
		return {
			title: "Evaluation",
			cards: []
		}
	},
	components: {
		MenuCard,
		appFooter: FooterMini
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager') {
					return true;
				}
			}
			return false
		},
	},
	mounted() {
		console.log('this.currentUser: ', this.currentUser)
		if (this.currentUser.company.cv_module_enabled) this.cards.push({
			"routeName": "/user-analysis",
			"image": "images/icons2024/admin_area_suitetoolsevaluationresources_CV.svg",
			"title": "CV Results",
			"description": "Uploaded Professional Profiles"
		})

		if (this.currentUser.company.hard_skills_module_enabled) this.cards.push({
			"routeName": "/tests",
			"image": "images/icons2024/admin_area_suitetoolsevaluationresources_hardskills.svg",
			"title": "Hard Skills",
			"description": "Available Assessment Tests and Results"
		})

		if (this.currentUser.company.soft_skills_module_enabled) this.cards.push({
			"routeName": "/softskills-list",
			"image": "images/icons2024/admin_area_suitetoolsevaluationresources_hsoftskills.svg",
			"title": "Soft Skills",
			"description": "Internal and external softskills evaluations"
		})

		if (this.currentUser.company.evaluation_360_module_enabled) this.cards.push({
			"routeName": "/evaluation-360",
			"image": "images/icons2024/admin_area_suitetoolsevaluationresources_BEI360.svg",
			"title": "BEI/360 Evaluation",
			"description": "Pending and completed evaluations"
		})
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	padding: 0;
	list-style-type: none;
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>