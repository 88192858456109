<template>
  <div>
    <FormulateForm
      ref="personal_data"
      name="personal_data"
      @input="input"
      v-model="result">
      <FormulateInput
        name="email"
        type="email"
        v-model="tmp.email"
        label="Please, write your email address"
        validation="required|email"
      />
      <FormulateInput
        name="name"
        label="What is your name?"
        validation="required"
      />
      <FormulateInput
        name="surnames"
        label="What are your surnames?"
        validation="required"
      />
    </FormulateForm>
  </div>
</template>

<script>
  export default { 
    name: 'PersonalDataStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {},
        tmp: {},
        isSubmitted: false
      } 
    }, 
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        const data = await this.$parent.$parent.data;

        if (data.name != undefined && data.name != ''){
          this.result.name = data.name
          this.isSubmitted = true
        } else{
          this.isSubmitted = false
        }

        if (data.surnames != undefined && data.surnames != ''){
          this.result.surnames = data.surnames
          this.isSubmitted = true
        } else {
          this.isSubmitted = false
        }

        if (data.email != undefined && data.email != ''){
          this.result.email = data.email
          this.isSubmitted = true
        } else{
          this.isSubmitted = false
        }

        if (this.isSubmitted)
          this.$emit('can-continue', {value: true});
        else
          this.$emit('can-continue', {value: false});
      },
      isValid() {
        if (Object.keys(this.result).length == 3 && Object.values(this.result).every(el => el != undefined)) {
          return true;
        } else {
          return false;
        }
      },
      input(){
        if (!this.isSubmitted){
          if (this.isValid()){
            this.$emit('can-continue', {value: true});
            this.isSubmitted = true;
          } else {
            this.$emit('can-continue', {value: false});
          }
        }
      }
    }
  }
</script>