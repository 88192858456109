<template>
	<div class="form-container technologiesStyle mt-2 row">
		<div class="col-sm-6">
			<div class="form-group">
				<label>Name *</label>
				<input class="form-input" type="text" v-validate="'required'" required v-model="difficulty.name" data-vv-name="name">
				<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					{{veeErrors.first('name')}}
				</div>
			</div>
			<div class="form-group">
				<label>Weight * <b-button class="btn btn-info" v-b-tooltip.hover title="1 (very easy), 2, 3, ... (very difficult)"><font-awesome-icon icon="info-circle" /></b-button></label>
				<input class="form-input" type="number" v-validate="'required'" data-vv-name="level" v-model="difficulty.level">
				<div v-if="submitted && veeErrors.has('level')" class="alert alert-danger">
					{{veeErrors.first('level')}}
				</div>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="form-group">
				<label>Color *</label>
				<div class="color-picker-div">
					<div v-if="difficultyIsSet">
						<color-picker-selector :color="difficulty.color" v-model="difficulty.color"/>	
					</div>
				</div>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>
		
		<div class="form-group row justify-content-center">
			<div class="col-sm-1">
				<button class="btn btn-primary form-submit" type="button" @click="handleUpdate()">Update</button>
			</div>
			<div class="col-sm-1">
				<button class="btn btn-secondary btn-cancel" @click="backToDifficulties()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>
 	</div>
</template>

<script>
	import DifficultyDataService from "@/services/DifficultyDataService";
	import Difficulty from '@/models/difficulty';
	import ColorPickerSelector from '@/components/Others/ColorPicker';

	export default {
	    name: "UpdateDifficultyComponent",
	    components: {
	    	ColorPickerSelector
	    },
	    data() {
		  	return {
		  		title: "Update Difficulty Level",
		    	difficulty: new Difficulty('','','','',this.$store.state.auth.user.id_area),
		        message: "",
			  	successfull: false,
			  	submitted: false,
			  	difficultyIsSet: false
	    	}
		},
		beforeMount() {
			DifficultyDataService.findOne(this.$route.query.id).then(
				response => {
					this.difficulty = response.data
					this.difficultyIsSet = true;
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
		      }
		    );
	  	},
		methods: {
			backToDifficulties() {
				this.$router.push({ name: "difficulties"});
			},
			handleUpdate() {
	  			this.submitted = true
				this.successful = true

	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					DifficultyDataService.update(this.difficulty).then(
	  						response => {
	  							if (response.status == 200) {
	  								this.successful = true;
	  								this.$router.push({ name: "difficulties", params: {difficultyUpdated: true} }).catch(()=>{});
	  							}
	  						},
	  						error => {
					          	this.message = error.response.data.message
								this.successful = false

	  							console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
	  						}
  						);
	  				}
	  			});
	  		},
			close() {
				this.message = ""
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>