<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<div class="row" style="padding-left: 2% !important; padding-right: 2% !important;">
			<div class="col-md-6">
				<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChangeArea($event)">
					<option hidden value="">Select an area</option>
		      		<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		    	</select>
			</div>
			<div class="col-md-6">
				<select class="form-select area_selector" data-vv-name="profile_selector" v-model="selectedProfile" @change="onChangeProfile($event)">
					<option hidden value="">Select an available profile</option>
		      		<option v-for="profile in profiles" :value="profile.name">{{profile.name}}</option>
		    	</select>
			</div>
		</div>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<pie-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:category="category" 
				:value="value"
				:data="data"
				:tooltipNoDataMsg="tooltipNoDataMsg"
				:chartTitle = "graphTitle"></pie-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
	import PieChart from "@/components/Others/Charts/PieChart.vue"

	export default {
		name: 'UsersGraphCVByLevels',
		data() {
			return {
				chartId: "chartUsersGraphCVByLevels",
				data: [],
				temp: [],
				graphTitle: "Users CVs analysed by category and profile",
				selectedTechId: 2,
				profiles: [],
				category: "level",
				value: "count",
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedProfile:"",
				selectedAreaName: null,
				selectedProfileName: null,
				tooltipNoDataMsg: "No data available"
			}
		},
		components: {
			PieChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChangeArea(event) {
				this.graphLoading = true;
				this.selectedArea = event.target.value
				this.tooltipNoDataMsg = "Please, choose a profile from the selected area to visualize the results.";

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea)){
						this.selectedAreaName = area.name
						this.graphTitle = "Users CVs analysed in " + this.selectedAreaName + " for " + this.selectedProfileName + " profiles";
					}
				});

				this.selectedProfileName = null;

				this.getListOfProfilesFromArea();
			},
			onChangeProfile(event) {
				this.graphLoading = true;
				this.selectedProfile = event.target.value

				this.profiles.forEach((profile) => {
					if (profile.name == this.selectedProfile)
						this.selectedProfileName = profile.name
				});

				this.fetchData();
			},
			getListOfProfilesFromArea() {
				ProfileConfigurationDataService.findLevelsConfigurationByArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							let profilesResponse = response.data;

							let profilesTmp = [];
							profilesResponse.forEach((profile) => {
								let tech = {};
								tech.id = profile.id.toString();
								tech.name = profile.name.charAt(0).toUpperCase() + profile.name.slice(1); // Set uppercase for technologies

								profilesTmp.push(tech)
							});

							this.profiles = profilesTmp;
							if(this.profiles.length > 0){
								if(this.profiles[0].name != "" && this.profiles[0].name !=null){
									this.selectedProfile = this.profiles[0].name; 
									this.selectedProfileName = this.profiles[0].name; 
									this.fetchData();
								}
							}
									
							this.graphLoading = false;
						}
					},
					error => {
						this.graphLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			async fetchData() {
				await ReportsDataService.profileAnalysesLevelByArea(this.selectedArea, this.selectedProfile.toLowerCase()).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.getColorsForLevels();
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			getColorsForLevels() {
				ProfileConfigurationDataService.findByArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.levelsData = response.data

							let dataTmp = [];
							this.data.forEach((d) => {
								let element = this.levelsData.filter(e => e.name.toLowerCase() == d.level.toLowerCase())
								dataTmp.push({count: d.count, level: d.level.charAt(0).toUpperCase() + d.level.slice(1), color: element[0].color});
							});

							this.data = dataTmp;
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						} else {
							console.error("Technology Weights couldn´t be retrieved")
						}
					},
					error => {
						this.graphLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.tooltipNoDataMsg = "Please, choose an area.";
		}
	};
</script>