<template>
	<div class="row">
		<div class="col-sm-12" v-if="dataS4">
			<h4 style="margin-top: 1%;">{{ dataS4.name }} - {{ dataS4.country }} ({{ dataS4.city }})</h4>
		</div>
		<vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
			:paginate-elements-by-height="1400" filename="professional_soft_skills_report" :pdf-quality="2"
			:manual-pagination="false" pdf-format="a4" pdf-content-width="800px" ref="html2Pdf">
			<pdf-soft-skills-report slot="pdf-content" />
		</vue-html2pdf>
		<div class="analysis-profile-container pb-2">
			<div class="row">
				<div class="col-sm-4">
					<div id="chart-container" class="col-sm-12 profile-progress-container">
						<pie-chart :data="dataPie" :options="pieChartOptions" :height="250"></pie-chart>
					</div>
				</div>
				<div class="col-sm-4">
					<div id="chart-container" class="col-sm-12 profile-progress-container">
						<bar-chart :data="dataBar" :options="barChartOptions" :height="280" />
					</div>
				</div>
				<div class="col-sm-4">
					<div id="chart-container" class="col-sm-12 profile-progress-container">
						<radar-chart :data="radarChartData" :key="3" :options="radarChartOptions" :height="400" />
					</div>
				</div>
			</div>
		</div>
		<div class="analysis-profile-container">
			<div class="row">
				<h5 style="text-align: center;">Participants in the process</h5>
				<div class="col-sm-12">
					<div class="row">
						<div class="col-sm-6" style="padding-left: 8px !important">
							<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
								aria-controls="items"></b-pagination>
						</div>
						<div class="col-sm-6 text-end">
							<b-form-input id="filter-input" v-model="filter" type="search"
								:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
						</div>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row">
						<div class="col-sm-8 text-start">
							<p> 8 Users found</p>
						</div>
						<div class="col-sm-4 text-end">
							<p>
								Sort By
								<button class="btn btn-secondary" type="button" v-b-tooltip.hover title="Sort by">
									<font-awesome-icon style="display: block;" class="trash-icon" icon="chevron-down" />
								</button>
								<button class="btn btn-secondary" type="button" v-b-tooltip.hover
									title="Display as list" @click="show = true">
									<font-awesome-icon style="display: block;" class="trash-icon" icon="list" />
								</button>
								<button class="btn btn-secondary" type="button" v-b-tooltip.hover
									title="Display as a matrix" @click="show = false">
									<font-awesome-icon style="display: block;" class="trash-icon" icon="table" />
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div v-if="show" class="col-sm-12">
					<hr class="thicker">
					<b-table ref="users" id="userList" :fields="headers" :items="items" :filter="filter"
						:per-page="perPage" :current-page="currentPage">
						<template #cell(avatar)="data">
							<img class="profile-img" v-bind:src="data.item.avatar" />
						</template>
						<template #cell(status)="data">
							<p v-if="data.item.status == 'Completed'"><font-awesome-icon v-b-tooltip.hover
									:title="data.item.status" class="completed-icon-yes" icon="check" /></p>
							<p v-else><font-awesome-icon v-b-tooltip.hover :title="data.item.status"
									class="completed-icon-no" icon="times"></font-awesome-icon></p>
						</template>
						<template #cell(afinity)="data">
							{{ data.item.afinity }}
						</template>
						<template #cell(options)="data">
							<router-link v-b-tooltip.hover title="More Info"
								:to="{ path: '/softskills-user?id_area=' + id + '&id=' + data.item.id }"
								class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
						</template>
					</b-table>
				</div>
				<div v-else class="col-sm-12">
					<hr class="thicker">
					<div class="row" style="padding-left: 10px !important;">
						<div v-for="(user, index) in items.slice((currentPage - 1) * perPage, ((currentPage - 1) * perPage) + perPage)"
							:key="index" class="col-sm-4 card candidate"
							style="max-width: 30% !important; justify-content: space-between !important;">
							<div class="row full-width">
								<div class="col-md-12" style="padding-bottom: 0px !important;">
									<img class="profile-img" v-bind:src="user.avatar" />
								</div>
								<div class="col-md-12" style="padding-top: 0px !important;">
									<router-link v-b-tooltip.hover title="More Info"
										:to="{ path: '/softskills-user?id_area=' + id + '&id=' + user.id }"
										class="btn btn-secondary">
										{{ user.name }}
									</router-link>
									<hr class="thicker">
								</div>
								<div class="col-md-6">
									<div class="row full-width">
										<div v-if="user.status == 'Completed'"
											style="margin: 13px !important; margin-left: 4px !important">
											<font-awesome-icon class="completed-icon-yes" icon="check" />
										</div>
										<div v-else style="margin: 13px !important; margin-left: 4px !important">
											<font-awesome-icon class="completed-icon-no" icon="times" />
										</div>
										<p class="text-center" style="font-size: 12px !important;">{{ user.status }}</p>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row full-width"
										style="padding-left: 10px !important; font-size: 12px !important">
										<div>
											<vue-ellipse-progress class="ellipse-progress-general"
												:progress="user.afinity" fontSize="1rem" :size="38" :thickness="3"
												:legend="true" :legendValue="user.afinity">
												<span slot="legend-value">%</span>
											</vue-ellipse-progress>
										</div>
										<p class="text-center">Afinity</p>
									</div>
								</div>
								<div class="col-md-12" style="padding-top: 0px !important;">
									<hr class="thicker">
									<p>{{ user.date }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import S4DataService from "@/services/S4DataService";
import PieChart from "@/components/Others/Charts/PieChart.js";
import RadarChart from "@/components/Others/Charts/RadarChart.js";
import BarChart from "@/components/Others/Charts/BarChart.js";
import PdfSoftSkillsReport from '../AnalysisComponents/pdfSoftSkillsReport.vue';
import VueHtml2pdf from 'vue-html2pdf';

export default {
	name: "StatisticsS4Component",
	components: {
		PieChart,
		RadarChart,
		BarChart,
		PdfSoftSkillsReport,
		VueHtml2pdf,
	},
	data() {
		return {
			reloadComponent: false,
			show: true,
			id: this.$route.query.id,
			dataS4: { id: "", name: "", type: "", id_language: 0, country: "", city: "", status: "", send_notification: false, id_area: 0, created_at: "", last_edited: "" },
			headers: [
				{ key: 'name', label: 'Name', sortable: true },
				{ key: 'status', label: 'Status', sortable: true },
				{ key: 'date', label: 'Date', sortable: true },
				{ key: 'afinity', label: 'Afinity', sortable: true },
				{ key: 'ranking', label: 'Ranking', sortable: true },
				{ key: 'options', label: 'Options' },
			],
			perPage: 6,
			currentPage: 1,
			filter: null,
			dataPie: {
				datasets: [
					{
						backgroundColor: ["#41B883", "#E46651"],
						data: [1, 10],
						fill: true,
					}
				],
				labels: ["Completed Answers", "Pending Answers"],
			},
			pieChartOptions: {},
			dataBar: {
				datasets: [
					{
						backgroundColor: "#4FC0E8",
						data: [6],
						fill: true,
						label: "Yes"
					},
					{
						backgroundColor: "#13283B",
						data: [8],
						fill: true,
						label: "No",
					},
					{
						backgroundColor: "#BFBFBF",
						data: [0],
						fill: true,
						label: "Don't know"
					}
				],
				labels: ["Attempt 1"]
			},
			barChartOptions: {
				scale: {
					ticks: {
						beginAtZero: true,
						max: 100,
						min: 0
					}
				},
			},
			items: [
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 1,
					name: "María Díaz Lorenzo",
					status: "Completed",
					date: "19/07/2022",
					afinity: 72,
					ranking: "1"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 2,
					name: "Ignacio Santamaria",
					status: "Completed",
					date: "29/07/2022",
					afinity: 72,
					ranking: "2"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 3,
					name: "Alesandro Gómez",
					status: "Incompleted",
					date: "29/07/2022",
					afinity: 72,
					ranking: "3"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 4,
					name: "Oscar Sánchez",
					status: "Completed",
					date: "29/07/2022",
					afinity: 72,
					ranking: "4"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 5,
					name: "Luis Blázquez",
					status: "Completed",
					date: "29/07/2022",
					afinity: 80,
					ranking: "5"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 6,
					name: "Javier Parra",
					status: "Completed",
					date: "29/07/2022",
					afinity: 2,
					ranking: "6"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 7,
					name: "Alberto García",
					status: "Completed",
					date: "29/07/2022",
					afinity: 32,
					ranking: "8"
				},
				{
					avatar: "http://00dev00.progradum.com/images/icons/user.png",
					id: 8,
					name: "Cristina Santa Cruz",
					status: "Completed",
					date: "29/07/2022",
					afinity: 12,
					ranking: "7"
				}
			],
			radarChartOptions: {
				elements: {
					line: {
						borderWidth: 3
					}
				},
				responsive: true,
				mantainAspectRatio: false,
				scale: {
					ticks: {
						beginAtZero: true,
						max: 100,
						min: 0
					}
				},
				legend: {
					display: true,
					position: "top"
				}
			},
			radarChartData: {
				labels: ['X', 'Y', 'Z', 'A', 'C', 'P'],
				datasets: [
					{
						label: "Trainee",
						data: [20, 30, 40, 15, 45, 90],
						fill: true,
						backgroundColor: 'rgba(103, 202, 233, 0.2)',
						borderColor: '#5ABBD1',
						pointBackgroundColor: '#5ABBD1',
						pointBorderColor: '#FFF',
						pointHoverBackgroundColor: '#FFF',
						pointHoverBorderColor: 'rgb(255, 99, 132)'
					},
					{
						label: "Junior",
						data: [60, 20, 10, 15, 45, 90],
						fill: true,
						backgroundColor: 'rgba(185, 252, 169, 0.2)',
						borderColor: '#AFE89E',
						pointBackgroundColor: '#AFE89E',
						pointBorderColor: '#FFF',
						pointHoverBackgroundColor: '#FFF',
						pointHoverBorderColor: 'rgb(96, 255, 27)'
					},
				],
			}
		}
	},
	methods: {
		async getDataProcess() {
			await S4DataService.getDataProcessById(this.id).then(
				response => {
					this.dataS4 = response.data;
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		downloadPdf() {
			this.$refs.html2Pdf.generatePdf()
		}
	},
	computed: {
		rows() {
			return this.items.length
		}
	},
	mounted() {
		this.getDataProcess();
	},
};
</script>

<style>
.profile-img {
	margin: 5%;
	height: 2em;
	border-radius: 50%;
}
</style>