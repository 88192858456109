<template>
  <request-reset-password-form/>
</template>

<script>
  import RequestResetPasswordForm from "@/components/UserForms/RequestResetPasswordForm.vue";

  export default {
    name: "RequestResetPasswordView",
    components: {
      RequestResetPasswordForm
    }
  };
</script>