import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'user/';
const API_URL_PATH_POSITION_SELECTION = 'position_selection/';

class UserDataService {

  getPublicContent() {
    return http.get(API_URL_PATH + 'all');
  }

  getUserBoard() {
    return http.get(API_URL_PATH + 'user', { headers: authHeader() });
  }

  getAdminBoard() {
    return http.get(API_URL_PATH + 'admin', { headers: authHeader() });
  }

  getSuperadminBoard() {
    return http.get(API_URL_PATH + 'superadmin', { headers: authHeader() });
  }

  getLoaderBoard() {
    return http.get(API_URL_PATH + 'loader', { headers: authHeader() });
  }

  getExaminerBoard() {
    return http.get(API_URL_PATH + 'examiner', { headers: authHeader() });
  }

  getValidatorBoard() {
    return http.get(API_URL_PATH + 'validator', { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findByCompany(idCompany) {
    return http.get(API_URL_PATH + 'findByCompany?id_company='+idCompany, { headers: authHeader() });
  }

  sendEmail(id_user) {
    return http.get(API_URL_PATH + 'sendEmail?id='+id_user,{ headers: authHeader() });
  }

  findByChatToken(token) {
    return http.get(API_URL_PATH + 'findByChatToken?token='+token);
  }

  findByPasswordToken(token) {
    return http.get(API_URL_PATH + 'findByPasswordToken?token='+token);
  }

  getAllUsersFromCompany(id_company){
    return http.get(API_URL_PATH + "findByCompany?id_company="+id_company,{ headers: authHeader() });
  }

  findByRequestPasswordForm(user) {
    return http.post(API_URL_PATH + 'findByRequestPasswordForm', { user: user });
  }

  sendChatbotMessage(message, token) {
    return http.post(API_URL_PATH + 'sendChatbotMessage', { sender: 'testuser', message: message, token: token });
  }

  sendChatbotInvitationToEmail(token) {
    return http.post(API_URL_PATH + 'sendChatbotInvitationToEmail', { token: token }, { headers: authHeader() });
  }

  sendReminderEmailBusinessProcess(userId, businessProcessId) {
    return http.post(API_URL_PATH_POSITION_SELECTION + 'sendReminderEmailBusinessProcess', { user_id: userId, business_process_id: businessProcessId }, { headers: authHeader() });
  }

  sendReminderEmailBusinessProcessToCandidate(candidateId, businessProcessId) {
    return http.post(API_URL_PATH_POSITION_SELECTION + 'sendReminderEmailBusinessProcessToCandidate', { candidate_id: candidateId, business_process_id: businessProcessId }, { headers: authHeader() });
  }

  sendPasswordResetAlert(id){
    return http.get(API_URL_PATH + 'sendPasswordResetAlertEmail?id=' +id, { headers: authHeader() } );
  }
  resetPassword(resetPasswordModel, password_token) {
    return http.post(API_URL_PATH + 'resetPassword', { token: password_token, password: resetPasswordModel.password});
  }

  updateAvatar(imageFile, imageName,  id_user, token, onUploadImage) {
    var formData = new FormData();
    formData.append("file", imageFile);

    return http.post(API_URL_PATH + 'updateAvatar', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "id_user": id_user,
        'x-access-token': token, 
        'filename': imageName
      },
      onUploadImage
    });
  }

  updateUser(data) {
    const user = {
      id: data.user.id,
      username: data.user.username,
      name: data.user.name,
      surname: data.user.surname,
      email: data.user.email,
      id_role: data.user.role.id,
      active: data.user.active,
    }

    return http.post(API_URL_PATH + 'update', {user:user},{ headers: authHeader()});
  }

  update(data) {
    const user = {
      id: data.user.id,
      username: data.user.username,
      name: data.user.name,
      surname: data.user.surname,
      email: data.user.email
    }

    return http.post(API_URL_PATH + 'update', {user:user},{ headers: authHeader()});
  }

  updateActive(data) {
    const user = {
      id: data.user.id,
      active: data.user.active,
      username: data.user.username,
      name: data.user.name,
      surname: data.user.surname,
      email: data.user.email
    }

    return http.post(API_URL_PATH + 'update', {user:user},{ headers: authHeader()});
  }

  updatePassword(id, currentPassword, newPassword) {
    const formData = {id: id, old_password: currentPassword, new_password: newPassword};
    return http.put(API_URL_PATH + 'updatePassword', formData, {headers: authHeader()});
  }

  delete(ids) {
    let idsTmp = [];
    Array.prototype.push.apply(idsTmp, ids);
  
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
  
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  createAccountSettings(user_settings) {
    return http.post(API_URL_PATH + 'createAccountSettings', { user_settings: user_settings }, { headers: authHeader() });
  }

  updateAccountSettings(user_settings) {
    return http.put(API_URL_PATH + 'updateAccountSettings', { user_settings: user_settings }, { headers: authHeader() });
  }

  getAccountSettings(id_user){
    return http.get(API_URL_PATH + 'getAccountSettings?id_user=' +id_user, { headers: authHeader() } );
  }

  addUsersToArea(idArea, idsUsers) {
    return http.post(API_URL_PATH + 'addUserToAreas', {id_area: idArea, ids: idsUsers},{ headers: authHeader()});
  }

  addUserToAreas(idUser, idsAreas) {
    return http.post(API_URL_PATH + 'addUserToAreas', {id_user: idUser, ids: idsAreas},{ headers: authHeader()});
  }

}

export default new UserDataService();