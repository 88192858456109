<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div  class="links-container">
        <router-link :to="{path: '/certifications-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
        <reports-downloader :graphContent="graphContent" :documentTitle="documentTitle"/>
      </div>
      <div class="whiteColor">General reports</div>
    </div>

    <RegisteredCertificationsByAreas ref="chart1" :areas="areas"/>
    <RegisteredCertificationsByAreaProfiles ref="chart2" :areas="areas"/>
    <RegisteredCertificationsByAreaProfileLevels ref="chart3" :areas="areas" />
  </div>
</template>

<script>
  import RegisteredCertificationsByAreas from "@/components/ReportsComponents/Certifications/RegisteredCertificationsByAreas.vue";
  import RegisteredCertificationsByAreaProfiles  from "@/components/ReportsComponents/Certifications/RegisteredCertificationsByAreaProfiles.vue";
  import RegisteredCertificationsByAreaProfileLevels  from "@/components/ReportsComponents/Certifications/RegisteredCertificationsByAreaProfileLevels.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";

  export default {
    name: 'GeneralReportsView',
    components: {
      RegisteredCertificationsByAreas,
      RegisteredCertificationsByAreaProfiles,
      RegisteredCertificationsByAreaProfileLevels,
      ReportsDownloader
    },
    data() {
      return {
        graphContent: [
          {title: "Registered certifications by areas"},
          {title: "Registered certifications by area profiles"}
        ],
        documentTitle: "certifications_validator_general_reports.pdf",
        areas: []
      }
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      }
    },
    mounted() {
      this.areas = this.$store.state.auth.user.areas;
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>