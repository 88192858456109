<template>
	<div class="form-container technologiesStyle mt-2">   
        <div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>

        <div class="col-sm-4" style="margin: 0 auto;padding-bottom: 10px;">
            <div class="alert alert-warning">
                {{ $t('dashboards.areas.profile.creation-advertisment') }}
            </div>
        </div>

		<div class="col-sm-4" style="margin: 0 auto;">
			<div class="form-group">
				<label>Name *</label>
				<input ref="nameInput" class="form-input" type="text" id="name" v-validate="'required'" required v-model="name" data-vv-name="name">
				<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					{{veeErrors.first('name')}}
				</div>
			</div>
		</div>
          
		<div class="form-group row justify-content-center">
			<div class="col-xl-2 col-lg-3">
				<button class="btn btn-primary form-submit" type="button" @click="handleCreate()">Create</button>
			</div>
			<div class="col-xl-2 col-lg-3">
				<button class="btn btn-secondary btn-cancel" type="button" @click="showCancelModal()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>
	</div>
</template>

<script>
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

    export default {
        name: 'CreateProfileConfigurationComponent',
        data() {
            return {
                name: "",
                technologies: [],
                message: "",
                successfull: false,
                submitted: false
            }
        },
        components: {
        },
        props: {
        },
        beforeMount() {  
        },
        mounted() {
            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

            ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
                response => {
                    this.technologies = response.data
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        methods: {
            showCancelModal() {
                this.backToConfiguration()
            },
            backToConfiguration() {
                this.$router.push({ name: 'profile-configuration'})
            },
            async handleCreate() {
                this.submitted = true
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.successfull = true

                        if(this.name.toString().trim().length == 0) {
                            this.successfull = false
                            this.message = 'Profile name is empty!'
                        } else {
                            let created = false
                            for (var i=0; i < this.technologies.length; i++ ) {
                                if(this.technologies[i].name.toLowerCase() === this.name.toString().toLowerCase()) {
                                    created = true
                                }
                            }

                            if (created) {
                                this.successfull = false
                                this.message = 'This profile is already created!'
                                this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })
                            }
                        }

                        if(this.successfull) {
                            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                            ProfileConfigurationDataService.createProfile(this.name, currentArea).then(
                                response => {
                                    if (response.status == 200) {
                                        this.message = 'Profile created succesfully!'
                                        this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })
                                    } else {
                                        this.successfull = false
                                        this.message = 'Error creating the profile!'
                                        this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })
                                    }
                                },
                                error => {
                                    this.successfull = false
                                    this.message = 'Error creating the profile!'
                                    this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })

                                    console.log((error.response && error.response.data) ||
                                        error.message ||
                                        error.toString())
                                    
                                    if (error.response.status === 300) {
                                        console.log('Logout: Token expired')
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('company');
                                        this.$store.state.auth.user = null
                                        this.$store.dispatch('auth/logout');
                                        this.$router.push('/login');
                                    }
                                }
                            );
                        }
                    }
                });
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            }
        },
        emits: ["delete-education", "delete-frameworks", "programming-skills-delete", "other-skills-weight", "tools-weight"],
    };
</script>

<style scoped>
.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.slider {
    background-color: white !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px white !important;
    width: 90% !important;
    padding: 20px 10px 30px 10px !important;
}

.range-row {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle-top {
    margin-top: 0px;
}

.subtitle {
    margin-top: 25px;
}

.weightSlider {
    width: 100% !important;
}

.skillDiv {
    width: 12% !important;
}

.skillH6 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.sliderDiv {
    width: 65% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.valueDiv {
    width: 5% !important;
}

.valueP {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.finalHardSkills {
    margin-bottom: 1rem !important;
}

.finalLastYears {
    margin-bottom: 1rem !important;
}

.lastYearsInput {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 60px;
}

.inputProfileConfig {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 100%;
    margin-left: 30px;
    margin-bottom: 15px;
}

.divItems {
    text-align: left;
    margin-left: 30px;
    margin-right: 100px;
}

.alert-edit {
    padding: 5px !important;
    width: 100% !important;
    margin-left: 30px;
}

.noDisplay {
    display: none !important;
}
</style>