<template>
	<div class="col-sm-12">
		<div v-if="isSettingsPath" class="row secondNavbar"> 
			<div class="col-6 links-container">
				<router-link class="btn btn-secondary float-right" :to="{ path: '/config-profile' }"><font-awesome-icon icon="arrow-left" /> Back to Configuration</router-link>
			</div>
			<div class="col-6 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/config-profile' }" class="breadcumb"> Configuration of Configuration of Profiles > </router-link>
				<router-link :to="{ path: '/profile-ranking' }" class="final-breadcumb"> Profile Ranking for '{{profileNameTitle.toUpperCase()}}' profiles </router-link>
			</div>
		</div>
		<div v-else class="row secondNavbar"> 
			<div class="col-6 links-container">
				<router-link class="btn btn-secondary float-right" :to="from">
					<p v-if="from.path != '/config-profile'">
						<font-awesome-icon icon="arrow-left" /> Back to CV Results
					</p>
					<p v-else>
						<font-awesome-icon icon="arrow-left" /> Back to Configuration of Profiles
					</p>
				</router-link>
			</div>
			<div class="col-6 noMargin whiteColor">
				<div v-if="from.path != '/config-profile'">
					<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
					<router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
					<router-link :to="{ path: '/user-analysis' }" class="breadcumb"> CV Results > </router-link>
					<router-link :to="{ path: '/profile-ranking' }" class="final-breadcumb"> Ranking </router-link>
				</div>
				<div v-else>
					<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
					<router-link :to="{ path: '/config-profile' }" class="breadcumb"> Configuration of Profiles > </router-link>
					<router-link :to="{ path: '/profile-ranking' }" class="final-breadcumb"> Ranking </router-link>
				</div>
			</div>
		</div>
		
		<div class="col-sm-8 col-centered">	
			<profile-ranking />
		</div>
	</div>
</template>

<script>
	import ProfileRanking from "@/components/AnalysisComponents/ProfileRankingComponent.vue";
	import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

	export default {
	  	name: 'ProfileRankingView',
	  	components: {
	      	ProfileRanking
	    },
   		data() {
			return {
				profile: null,
				profileNameTitle: "",
				from: null,
				labelFrom: "",
			}
		},
		props: {
			isSettingsPath: {
				type: Boolean,
				default: false
			}
		},
		mounted() {
			ProfileConfigurationDataService.getProfile(this.$route.query.id_profile).then(
				response => {
					if (response.status == 200) {
						this.profile = response.data
						this.profileNameTitle = this.profile.name
					} else {
						console.error("Error in getProfile")
					}
				}
			)
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.from = from;
			});
		},
		methods: {
			backToConfiguration() {
				this.$router.push({ path: 'config-profile'})
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>