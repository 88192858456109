<template>
	<div class="table-container  technologiesStyle" ref="main-div">
		<template v-if="usersToShow.length >0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination
						class="pagination"
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="usersList"
					></b-pagination>
				</div>
				<div class="col-sm-1">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
				</div>
				<div class="col-sm-2">
					<button class="btn btn-primary table-selection-button" style="float: right;" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
				</div>
				<div class="col-sm-2">
					<button ref="clear-selected" class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
				</div>
				<div class="col-sm-2">
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" style="float: none; margin-left: 0;" type="button" @click="handleSave"><font-awesome-icon icon="user-minus" class="white-color" /> Remove Users</button>  
				</div>
				<div class="col-sm-3">
					<b-form-input 
						id="filter-input"
						v-model="filter"
						type="search"
						:placeholder="$t('others.search-placeholder-msg')"
					></b-form-input>
				</div>
			</div>
			<br>
			<b-table ref="usersTable"
				id="usersList"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="headers"
				:items="usersToShow"
				:filter="filter"
				select-mode="multi"
				responsive="sm"
				selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(role)="data">
					<p>{{data.item.role.name.toUpperCase()}}</p>
				</template>
				<template #cell(created_at)="data">
					<p>{{ data.item.created_at | formatEnglishDate}}</p>
				</template>
				<template #cell(updated_at)="data">
					<p>{{ data.item.updated_at | formatEnglishDate }}</p>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg" v-if="!dataLoading"><font-awesome-icon icon="times-circle" class="black-color"/> No Users in Area</h3>
			<h3 class="no-results-msg" v-else><b-spinner class="align-middle analysis-spinner"></b-spinner>&nbsp;&nbsp;Loading data...</h3>
		</template>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>
	</div>
</template>

<script>
    import AreasDataService from '@/services/AreasDataService';
    import UserDataService from '@/services/UserDataService';

	export default {
		name: 'ManageUsersComponent',
		data() {
			return {
				dataLoading: false,
				users: [],
				usersIdsPrev: [],
				usersToShow: [],
				area: {},
				filter: null,
				perPage: 10,
				currentPage: 1,
				pageOptions: this.$paginationOptions,
				selectedItems: [],
				message: "",
				successfull: false,
				submitted: false,
				headers: [
					{ key: 'username', label: 'User', sortable: true, tdClass: 'tdMedium' },
					{ key: 'name', label: 'Name', sortable: true, tdClass: 'tdMedium' },
					{ key: 'surname', label: 'Surname', sortable: true, tdClass: 'tdMedium' },
					{ key: 'role', label: 'Role', sortable: true, tdClass: 'tdMedium' },
					{ key: 'email', label: 'Email', sortable: true, tdClass: 'tdMedium' }
				]
			}
	    },
		components: {
		},
		props: {
		},
	    computed: {
            rows() {
                return this.users.length
            }
	    },
        mounted() {
			this.dataLoading = true
			AreasDataService.findOne(this.$route.query.id).then(
				response => {
					this.area = response.data

					UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
						response => {
							this.users = response.data

							for (let i = 0; i < this.users.length; i++) {
								let inArea = false
								for (let j = 0; j < this.users[i].areas.length; j++) {
									if(this.users[i].areas[j].id === this.area.id) {
										inArea = true
										this.usersIdsPrev.push(this.users[i].id)
									}
								}

								if(inArea) {
									this.usersToShow.push(this.users[i])
								}
							}
							this.dataLoading = false
						},
						error => {
							console.log((error.response && error.response.data) ||
								error.message ||
								error.toString())
							
							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
							this.dataLoading = false
						}
					);
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
					this.dataLoading = false
				}
			);
        },
	  	methods: {
            backToAreasList() {
                this.$router.replace({ name: "admin-configuration-areas", params: {areaAdded: false, areaUpdated: false} });
            },
	  		onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["usersTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["usersTable"].clearSelected();
	      	},
			handleSave() {
                let usersIds = []

                this.selectedItems.forEach((item) => {
                    usersIds.push(item.id)
                })

				AreasDataService.removeUsersFromArea(this.area.id, usersIds).then(
					response => {
						this.$router.replace({ name: "admin-configuration-areas", params: {areaAdded: false, areaUpdated: true} });
					},
					error => {
						this.message = "Error removing users from area. Please, try again later."
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
                );
	      	},
			close() {
				document.getElementById("message").style.display = "none"
			}
	    }
	};
</script>