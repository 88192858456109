<template>
    <div class="question-container">
        <p class="title">{{modalQuestion.question_title}}</p>
        <div class="content" v-if="modalQuestion.question_content != '' && modalQuestion.question_title != null">
            <div class="code" v-html="modalQuestion.question_content"></div>
        </div>

        <div class="list" v-if="modalQuestion.multiselection">
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_one_correct}">
                <label><input type="checkbox"> {{modalQuestion.answer_one}}</label>
                <p v-if="modalQuestion.answer_one_value != 0 && modalQuestion.answer_one_correct">[{{modalQuestion.answer_one_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_two_correct}">
                <label><input type="checkbox"> {{modalQuestion.answer_two}}</label>
                <p v-if="modalQuestion.answer_two_value != 0 && modalQuestion.answer_two_correct">[{{modalQuestion.answer_two_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_three_correct}">
                <label><input type="checkbox"> {{modalQuestion.answer_three}}</label>
                <p v-if="modalQuestion.answer_three_value != 0 && modalQuestion.answer_three_correct">[{{modalQuestion.answer_three_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_four_correct}">
                <label><input type="checkbox"> {{modalQuestion.answer_four}}</label>
                <p v-if="modalQuestion.answer_four_value != 0 && modalQuestion.answer_four_correct">[{{modalQuestion.answer_four_value}} %]</p>
            </div>
        </div>

        <div class="list" v-else>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_one_correct}">
                <label><input class="form-input" type="radio" name="multiselection" value="false" v-model="multiselection"> {{modalQuestion.answer_one}}</label>
                <p v-if="modalQuestion.answer_one_value != 0 && modalQuestion.answer_one_correct">[{{modalQuestion.answer_one_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_two_correct}">
                <label><input class="form-input" type="radio" name="multiselection" value="false" v-model="multiselection"> {{modalQuestion.answer_two}}</label>
                <p v-if="modalQuestion.answer_two_value != 0 && modalQuestion.answer_two_correct">[{{modalQuestion.answer_two_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_three_correct}">
                <label><input class="form-input" type="radio" name="multiselection" value="false" v-model="multiselection"> {{modalQuestion.answer_three}}</label>
                <p v-if="modalQuestion.answer_three_value != 0 && modalQuestion.answer_three_correct">[{{modalQuestion.answer_three_value}} %]</p>
            </div>
            <div class="col-sm-12" v-bind:class = "{'alert alert-success':modalQuestion.answer_four_correct}">
                <label><input class="form-input" type="radio" name="multiselection" value="false" v-model="multiselection"> {{modalQuestion.answer_four}}</label>
                <p v-if="modalQuestion.answer_four_value != 0 && modalQuestion.answer_four_correct">[{{modalQuestion.answer_four_value}} %]</p>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'QuestionsModalComponent',
        data() {
            return {
                multiselection: '',
            }
        },
	    props: {
	    	modalQuestion: Object
	    }
	};
</script>