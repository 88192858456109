<template>
  <div>
    <div class="row toggle-form">
      <div class="col-md-6">
        <p class="text-form-align">Do you have any certification?&nbsp;&nbsp;&nbsp;</p>
      </div>
      <div class="col-md-1 col-input-width">
        <div class="form-check form-switch dichotomic-switch"><input @change="changeOption" class="form-check-input checkbox-required" type="checkbox" v-model="hasCertifications"></div>
      </div>
      <div class="col-md-1">
        <p class="text-form-align-left">&nbsp;&nbsp;&nbsp;</p>
      </div>
    </div>

    <FormulateForm v-if="hasCertifications"
      ref="cert_data"
      name="cert_data"
      v-model="result"
      @input="input"
      #default="{ isValid }">
      <FormulateInput
        type="group"
        name="certifications"
        label="List all the certifications you have"
        add-label="+ Add certification"
        :repeatable="true"
      ><FormulateInput name="cert_name" /></FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
  export default { 
    name: 'CertificationsStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {'certifications': []},
        hasCertifications: false,
        isSubmitted: false
      } 
    }, 
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        let data = await this.$parent.$parent.data;
        if (data.certifications != undefined && data.certifications != ''){
          this.result.certifications = data.certifications
          this.isSubmitted = true
          this.hasCertifications = true;
        } else{
          this.result.certifications = []
          this.hasCertifications = false;
          this.isSubmitted = false
        }

        if (this.isSubmitted || !this.hasCertifications)
          this.$emit('can-continue', {value: true});
        else
          this.$emit('can-continue', {value: false});
      },
      isValid() {
        if (this.result.certifications.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      changeOption() {
        if (!this.hasCertifications){
          this.result.certifications = [];
          this.$emit('can-continue', {value: true});
        } else{
          this.$emit('can-continue', {value: false});
        }
      },
      input(){
        if (this.hasCertifications && !this.isSubmitted) {
          if (this.isValid()){
            this.$emit('can-continue', {value: true});
            this.isSubmitted = true;
          } else {
            this.$emit('can-continue', {value: false});
          }
        }
      }
    } 
  }
</script>