<template>
	<div class="dashboard">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link :to="{path: '/certifications-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
			</div>
			<div class="whiteColor">{{ title }}</div>
		</div>

		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName"
						:cardImage="card.image"
						:cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';
	import i18n from "@/i18n"

	export default {
		name: 'SubmenuTalentManagementQuestionsValidator',
		data() {
			return {
				title: i18n.t('dashboards.general.reporting'),
				cards: [
					{
						"routeName": "/certifications-validator-general-reports",
						"image": "/images/icons2024/admin_generalreportsgeneral.svg",
						"title": "General",
						"description": "General statistics and reports"
					}
				]
			}
		},
		components: {
			MenuCard,
			appFooter: FooterMini
		}
	};
  
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>