<template>
  <div style="margin: 10% !important;">
    <div class="col-sm-12" style="margin-bottom: 1% !important;" v-if="$typeOfProcess === 'Internal Evaluation'">
      <h5>Internal Evaluation</h5>
      <div class="form-group">
        <div class="form-group" v-for="(user, index) in users">
          <label><input class="form-input multiselection" type="checkbox"  :name="'user-' + index" v-model="user.selected"> {{user.name}} {{user.surname}} ({{user.email}})</label>
        </div>
      </div>
    </div>
    

    <div class="row mt-2" v-else>
      <div class="form-group col-12">
        <div class="row mb-2">
          <div class="col-md-9">
            <h5>External Selection</h5>
          </div>
          <div class="col-md-3">
            <button class="btn btn-primary" @click="addNewCandidateToList()">
              <font-awesome-icon class="white-color" icon="plus" /> Add candidate
            </button>
          </div>
        </div>

        <template v-if="externalCandidatesToAdd.length > 0">
          <div v-for="(val, index) in externalCandidatesToAdd" :key="index" class="row">
            <div class="form-group col-md-1">
              <p>Name</p>
            </div>
            <div class="form-group col-md-5">
              <input id="name_input" ref="name_input" v-model="val.name" />
            </div>
            <div class="form-group col-md-1">
              <p>Email</p>
            </div>
            <div class="form-group col-md-4">
              <input type="email" id="email_input" ref="email_input" v-model="val.email" />
            </div>
            <div class="col-md-1 plus-minus-filter-btn">
              <button class="btn btn-secondary" @click="removeNewCandidateToList(index)" v-b-tooltip.hover title="Remove candidate"><font-awesome-icon icon="minus" /></button>
            </div>
          </div>
        </template>
        <template v-else>
          <h5 class="no-results-msg-interview"> No external candidates.</h5>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import UserDataService from "@/services/UserDataService"

  export default { 
    name: 'PersonalDataStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {},
        users: [],
        tmp: {},
        isSubmitted: false,
		  	externalCandidatesToAdd: [],
      } 
    }, 
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await UserDataService.getAllUsersFromCompany(this.$store.state.auth.user.id_company).then(
          response => {
            this.users = response.data

            this.users.forEach(user => {
              user.selected = false
            })
          },
          error => {
            console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
            this.message = error.response.data.message

            if (error.response.status === 300) {
                console.log('Logout: Token expired')
                localStorage.removeItem('user');
                localStorage.removeItem('company');
                this.$store.state.auth.user = null
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            }
          }
        );
        
        this.$emit('can-continue', {value: true});
      },
      addNewCandidateToList() {
      	this.externalCandidatesToAdd.push({name: '', email: ''})
      },
      removeNewCandidateToList(index) {
      	this.externalCandidatesToAdd.splice(index, 1)
      }
    }
  }
</script>