<template>
  <div class="chat-container">
    <chatbot />
  </div>
</template>

<script>
import Chatbot from "@/components/ChatComponents/Interview.vue";
  
  export default {
    name: "Interview",
    components: {
      Chatbot,
    },
    mounted() {
      ProfileAnalysisDataService.findByChatToken(this.$route.query.token).then(
        (response) => {
          if (response.status != 200) {
            this.$router.push({ name: "profile" });
          } else {
            this.idProfile = response.data.id;
            this.profile = response.data;

            //Progress
            var totalFields = 6;
            var countDefinedFields = 0;

            //Certifications
            if (this.profile.certifications != "") {
              let certifications = this.profile.certifications;
              certifications = certifications.replaceAll("'", '"');
              certifications = JSON.parse(certifications);
              this.certifications = certifications;
              countDefinedFields++;
            }

            //Programming skills
            if (this.profile.programming_skills != "") {
              let programming_skills = this.profile.programming_skills;
              programming_skills = programming_skills.replaceAll("'", '"');
              programming_skills = JSON.parse(programming_skills);
              this.programming_skills = programming_skills;
              countDefinedFields++;
            }

            //Other skills
            if (this.profile.other_skills != "") {
              let other_skills = this.profile.other_skills.substring(
                1,
                this.profile.other_skills.length - 1
              );
              other_skills = other_skills.replaceAll("'", "");
              this.other_skills = other_skills.split(",");
            }

            //Education
            if (this.profile.education != "") {
              let education = this.profile.education;
              education = education.replaceAll("'", '"');
              education = JSON.parse(education);
              this.education = education;
              countDefinedFields++;
            }

            //Jobs
            if (this.profile.experience_jobs != "") {
              let jobs = this.profile.experience_jobs;
              jobs = jobs.replaceAll("'", '"');
              jobs = JSON.parse(jobs);
              this.jobs = jobs;
              countDefinedFields++;
            }

            //Experience
            if (this.profile.experience != "") {
              let experience = this.profile.experience_time;
              experience = experience.replaceAll("'", '"');
              experience = JSON.parse(experience);
              this.experience = experience;
              countDefinedFields++;
            }

            //Languages
            if (this.profile.languages != "") {
              let languages = this.profile.languages;
              languages = languages.replaceAll("'", '"');
              languages = JSON.parse(languages);
              this.languages = languages;
              countDefinedFields++;
            }

            this.definedPercentage = (countDefinedFields / totalFields) * 100;
            this.definedPercentage = Math.round(this.definedPercentage, -1);
          }
        },
        (error) => {
          this.$router.push({ name: "profile" });
          console.log(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );
        }
      );
    }
  };
</script>