<template>
	<div v-bind:class="{ 'table-container': !home, 'col-sm-12': home }" class="technologiesStyle my-2">
		<div id="messageDelete" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>

			<div id="message" v-if="analysisRegistered && !errorMsg" class="alert alert-success col-md-12"
				style="width: auto; color: black !important;">
				{{ $t('dashboards.user.cvs.cv-uploaded-msg') }} <b-button @click="close"
					class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
			</div>

			<div id="message" v-if="analysisRegistered && errorMsg" class="alert alert-danger col-md-12"
				style="width: auto">
				{{ errorMsg }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>
		</div>

		<template v-if="analyses.length > 0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="profileAnalyses"></b-pagination>
				</div>
				<div class="col-sm-7">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
						rows</button>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button"
						type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash"
							class="white-color" /> Delete {{ selectedItems.length }} rows</button>
				</div>
			</div>

			<b-table ref="analysesTable" id="profileAnalyses" :per-page="perPage" :current-page="currentPage"
				:fields="headers" :items="analyses" :filter="filter" select-mode="multi" responsive="sm" selectable
				@row-selected="onRowSelected">
				<template #cell(progress)="data">
					{{ data.item.progress }} %
				</template>
				<template #cell(created_at)="data">
					{{ data.item.created_at | formatEnglishDate }}
				</template>
				<template #cell(validated)="data">
					<p v-if="data.item.validated"><font-awesome-icon class="completed-icon-yes" icon="check" /></p>
					<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Show analysis"
						:to="{ path: '/profile-analysis?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 v-if="numAreaProfiles > 0" class="no-results-msg"><font-awesome icon="times-circle" /><font-awesome-icon
					icon="times-circle" class="black-color" /> No results found. Please, upload your CV.</h3>
			<h3 v-else class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
				found.</h3>
		</template>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete profile analysis
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} profile
					analysis? </p>
				<p v-else> Do you really want to delete this profile analysis? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteAnalysis()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';

export default {
	name: 'AnalysesListComponent',
	data() {
		return {
			analyses: [],
			message: "",
			successfull: false,
			perPage: 10,
			pageOptions: this.$paginationOptions,
			currentPage: 1,
			filter: null,
			selectedItems: [],
			selectedId: null,
			selectedIdIndex: null,
		}
	},
	mounted() {
		if (this.$store.state.auth.user.table_records_per_page != undefined)
			this.perPage = this.$store.state.auth.user.table_records_per_page

		ProfileAnalysisDataService.findCVsByUser(this.$store.state.auth.user.id).then(
			response => {
				this.analyses = response.data
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	computed: {
		rows() {
			return this.analyses.length
		},
		headers() {
			if (this.$store.state.auth.user.role.name == 'admin') {
				return [
					{ key: 'progress', sortable: true, label: 'Professional Profile % completed' },
					{ key: 'created_at', sortable: true, label: 'Uploaded at' },
					{ key: 'validated', label: 'Validated' },
					{ key: 'options', label: 'Options' },
				]
			} else {
				return [
					{ key: 'progress', sortable: true, label: 'Professional Profile % completed' },
					{ key: 'created_at', sortable: true, label: 'Uploaded at' },
					{ key: 'options', label: 'Options' },
				]
			}
		}
	},
	props: {
		home: Boolean,
		numAreaProfiles: Number,
		analysisRegistered: {
			type: Boolean,
			default: false
		},
		errorMsg: {
			type: String,
			default: ''
		}
	},
	methods: {
		onRowSelected(items) {
			this.selectedItems = items;
		},
		selectAllRows() {
			this.$refs["analysesTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["analysesTable"].clearSelected();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		deleteAnalysis() {
			let selectedRows = this.selectedItems.map(element => element.id);

			ProfileAnalysisDataService.delete(selectedRows).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true

						this.analyses = this.analyses.filter(function (value, index) {
							return selectedRows.indexOf(value.id) == -1;
						});
					}

					this.$refs['delete-modal'].hide();
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.successfull = false
					this.message = error.message
					this.$refs['delete-modal'].hide();

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		redirectToChatbot() {
			ProfileAnalysisDataService.findLastByUser(this.$store.state.auth.user.id).then(
				response => {
					if (response.status == 200)
						this.$router.replace({ name: "chat", params: {}, query: { token: response.data.token } })
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			document.getElementById("messageDelete").style.display = "none"
		}
	}
};
</script>

<style scoped>
.profile-completed-icon-yes {
	color: green !important;
}

.profile-completed-icon-no {
	color: red !important;
}
</style>