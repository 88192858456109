<template>
	<div>
		<h4 class="reports-title">Registered certifications by areas</h4>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:data="data"
				:tooltipNoDataMsg="tooltipNoDataMsg"></barr-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"

	export default {
		name: 'RegisteredCertificationsByAreas',
		data() {
			return {
				chartId: "chartRegisteredCertificationsByAreas",
				data: [],
					temp: [],
				xAxesCategory: "name",
				yAxesValue: "total_certifications",
				xAxesTitle: "Areas",
				yAxesTitle: "Number of Certifications",
					graphLoaded: false,
					graphLoading: false,
					selectedArea: "",
					selectedAreaName: null,
					tooltipNoDataMsg: "Please, select an area."
			}
		},
		components: {
			BarrChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea))
						this.selectedAreaName = area.name
				});

				this.graphLoading = true;

				this.fetchData();
			},
			async fetchData() {
				await ReportsDataService.registeredCertificationsByAreas(this.$store.state.auth.user.id_company).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.tooltipNoDataMsg = this.data.length > 0 ? this.tooltipNoDataMsg : "There are no certifications in this area"; 
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.graphLoading = true;
			this.selectedAreaName = null;

			this.fetchData();
		}
	};
</script>