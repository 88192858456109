<template>
	<div  v-bind:class = "{'table-container':!home, 'col-sm-12': home} " class="technologiesStyle my-2">
		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>

  		<template v-if="testsTable.length > 0">
	  		<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination
				     	v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="availableTests"
				    ></b-pagination>
				</div>
				<div class="col-sm-7">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
					<div class="numPerPage paginationSelectorTable mx-3 my-2">Show: </div>
					<b-form-select class="numPerPage paginationSelectorTable" v-model="testOptionModel" @change="filterTests" :options="optionsTests"> </b-form-select>  
				</div>
				<div class="col-sm-3">
					<b-form-input 
						id="filter-input"
		              	v-model="filter"
		              	type="search"
		              	:placeholder="$t('others.search-placeholder-msg')"
	            	></b-form-input>
				</div>
			</div>
		
		  	<b-table 
				id="availableTests"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="headers" 
				:items="testsTable"
				:filter="filter"
				responsive="sm">
				<template #cell(title)="data">
					{{data.item.title}}
		      	</template>
				<template #cell(difficulty_level)="data">
					<p class="difficultyLevelTag" :style="'background-color: ' + data.item.colorDiff[data.item.difficulty_level]">{{data.item.difficulty_level.toUpperCase()}} </p>
		      	</template>
		      	<template #cell(num_questions)="data">
					{{data.item.num_questions}}
		      	</template>
				<template #cell(qualification)="data">
					<div v-if="data.item.qualification != null">
						{{data.item.qualification}}
					</div>
					<div v-else>
						Not attempted
					</div>
		      	</template>
				<template #cell(attempts)="data">
					{{data.item.attempts}}
		      	</template>
				<template #cell(max_attempts)="data">
					{{parseInt(data.item.max_attempts) - parseInt(data.item.attempts)}}
		      	</template>
				<template #cell(time_limit)="data">
					<p v-if="data.item.time_limit != '' && data.item.time_limit !=null">
						{{data.item.time_limit}} min
					</p>
					<p v-else>
						No time limit
					</p>
		      	</template>
				<template #cell(options)="data">
					<b-button v-if="data.item.attempts < data.item.max_attempts" @click="showModal(data.item)" class="btn btn-secondary" v-b-tooltip.hover title="Attempt test"> <font-awesome-icon icon="play" /></b-button>
					<router-link v-if="data.item.attempts > 0" :to="{path: '/test-result?id=' + data.item.id + '&id_user=' + id_user}" class="btn btn-secondary" v-b-tooltip.hover title="See test results"><font-awesome-icon icon="eye" /></router-link>
		      	</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No tests found.</h3>
		</template>

		<b-modal id="create-modal" ref="create-modal" title="Create-modal" hide-footer>
			<template #modal-title>
				Start Assessment Test
			</template>
			<div>
				<p>You are about to begin a test. Do you want to continue? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('create-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="startAttempt()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";

	export default {
	    name: "TestListComponent",
	    data(){
	    	return {
		        tests: [],
		        testsTable: [],
		        successfull: false,
		        message: "",
		        perPage: 10,
		        pageOptions: this.$paginationOptions,
	        	currentPage: 1,
	        	filter: null,
				questions: [],
				colorDiff: {},
				difficulties: [],
				testsId: [],
				testId: -1,
				time: -1,
				testOptionModel: 0,
				optionsTests: [{value:0, text:"All"},{value:1, text:"Finished"},{value:2, text:"Available"}],
				id_user: this.$store.state.auth.user.id,
			}
	    },
	    computed: {
			rows() {
				return this.testsTable.length
			},
			headers() {
				return [
					{ key: 'title', label: this.$t('dashboards.user.tests.table-headers.title-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'difficulty_level', label: this.$t('dashboards.user.tests.table-headers.level-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'num_questions', label: this.$t('dashboards.user.tests.table-headers.num-questions-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'qualification', label: this.$t('dashboards.user.tests.table-headers.last-score-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'attempts', label: this.$t('dashboards.user.tests.table-headers.attempts-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'max_attempts', label:this.$t('dashboards.user.tests.table-headers.max-attempts-column'), sortable: true, tdClass: 'tdSmall' },
					{ key: 'time_limit', label: this.$t('dashboards.user.tests.table-headers.time-limit-column'),sortable: true, tdClass: 'tdSmall' },
					{ key: 'options', label: this.$t('dashboards.user.tests.table-headers.options-column'), tdClass: 'tdSmall' }
				]
			}
	    },
	    props: {
	    	home: Boolean
	    },
		mounted() {
			this.getTestBoard();
			this.getTestsForUser();
	  	},
		methods: {
			getTestBoard(){
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				TestDataService.getTestBoard(currentArea).then(
					response => {
						if (response.status == 200){
							this.difficulties = response.data.difficulty_levels;
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			getTestsForUser(){
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				TestDataService.findTestsForUser(this.$store.state.auth.user.id, currentArea).then(
					response => {
						if (response.status == 200){
							for(var i=0; i<response.data.length; i++) {
								this.testsId.push(response.data[i].id)
							}

							TestDataService.findQuestionsForTestsId(this.testsId).then(
								response2 => {
									if (response2.status == 200){
										for(var i=0; i<response2.data.length; i++) {
											var questions = response2.data[i].questions

											var averageDifficulty = 0
											var weightSum = 0

											for(var j=0; j<questions.length; j++) {
												var questionWeight = 0;
												var assigned = false;
												for(var k=0; k<this.difficulties.length; k++) {
													if(this.difficulties[k].id == questions[j].id_difficulty) {
														questionWeight = this.difficulties[k].level
														assigned = true;
													}

													if(assigned) break;
												}

												weightSum = weightSum + questionWeight;
											}

											averageDifficulty = weightSum / questions.length;
											for(k=0; k<response.data.length; k++) {
												var assigned2 = false;
												if(response.data[k].id == response2.data[i].test.id) {
													response.data[k].averageDifficulty = averageDifficulty.toFixed("2")
													assigned = true;
												}

												if(assigned2) break;
											}
										}

										this.tests = response.data

										for( i=0; i<this.difficulties.length; i++) {
											this.colorDiff[this.difficulties[i].name] = this.difficulties[i].color
										}
										for(i=0; i<this.tests.length; i++) {
											this.tests[i].colorDiff = this.colorDiff
											this.tests[i].num_questions = response2.data[i].questions.length
											this.tests[i].time_limit = response2.data[i].test.time_limit
										}
										this.filterTests();
									}
								},
								error => {
									console.log((error.response && error.response.data) ||
										error.message ||
										error.toString())

									if (error.response.status === 300) {
										console.log('Logout: Token expired')
										localStorage.removeItem('user');
										localStorage.removeItem('company');
										this.$store.state.auth.user = null
										this.$store.dispatch('auth/logout');
										this.$router.push('/login');
									}
								}
							);
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			close() {
				document.getElementById("message").style.display = "none"
			}, 
			showModal(data) {
				this.$refs['create-modal'].show();
				this.testId = data.id;  
				this.time = data.time_limit;
			},
			filterTests(){
				if (this.testOptionModel == 0) {
					this.testsTable = this.tests
				} else if (this.testOptionModel == 1) {
					this.testsTable = this.tests.filter(field => field.max_attempts == field.attempts);
				} else {
					this.testsTable = this.tests.filter(field => field.max_attempts != field.attempts);
				}
			},
			startAttempt(){
				if(this.testId != -1)
					this.submitted = true;
				
				TestDataService.createAttempt(this.testId, this.$store.state.auth.user.id).then(
					response => {
						if (response.status == 200) {
							const idAttempt = response.data.id;
							this.$router.push({ name: "attempt-test", params: {idAttempt: idAttempt, time_limit: this.time}, query: {id: this.testId}});
						} else {
							this.message = response.data.message
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = error.response.data.message

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};	
</script>

<style scoped>
.nav-link-user-submenu {
	color:#13283B !important;
}
</style>
