<template>
    <div class="dashboard">
        <div v-if="!showWelcome" class="welcome justify-content-center">
            <p class="welcome-message">{{$t('dashboards.general.welcome-back')}} {{currentUser.username}}!</p>
        </div>
        <div class="cards w-100">
            <div class="row full-width justify-content-center">
                <template v-for="card in cards">
                    <menu-card :cardRouteName="card.routeName"
                        :cardImage="card.image"
                        :cardTitle="card.title"
                        :cardDescription="card.description" v-bind:key="card.title"></menu-card>
                </template>
            </div>
        </div>
        <div class="fixed-bottom">
            <appFooter></appFooter>
        </div>
    </div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';

	export default {
        name: 'AdminAreasDashboardView',
        data() {
            return {
                cards: []
            };
        },
        components: {
            MenuCard,
            appFooter: FooterMini
        },
        props: {
            showWelcome: Boolean
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            const recaptcha = this.$recaptchaInstance

            if (recaptcha)
                recaptcha.hideBadge()

            let currentArea = undefined;
            if (this.$store.state.auth.user.active_areas)
                currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

            if (currentArea != undefined) {
                if (this.currentUser.company.cv_module_enabled || this.currentUser.company.hard_skills_module_enabled || this.currentUser.company.evaluation_360_module_enabled) this.cards.push({
                    "routeName": "/settings",
                    "image": "images/icons2024/admin_companyconfiguration.svg",
                    "title": this.$t('dashboards.admin.settings.title'),
                    "description": this.$t('dashboards.admin.settings.description')
                })
                this.cards.push({
                    "routeName": "/modules",
                    "image": "/images/icons2024/admin_area_suitetools.svg",
                    "title": this.$t('dashboards.admin.modules.title'),
                    "description": this.$t('dashboards.admin.modules.description')
                })
            } else {
                this.cards.push({
                    "routeName": "/admin-configuration",
                    "image": "images/icons2024/admin_companyconfiguration.svg",
                    "title": 'Company configuration',
                    "description": 'Manage your company information'
                })
                this.cards.push({
                    "routeName": "/talent-management",
                    "image": "images/icons2024/admin_reporting.svg",
                    "title": this.$t('dashboards.admin.talent-management.title'),
                    "description": this.$t('dashboards.admin.talent-management.description')
                })
            }
        }
	};
</script>

<style scoped>
.welcome {
	background-color: #8080807e;
	padding-left: 0px;
    padding-right: 0px;
	height: 40px;
	font-size: 18px;
	font-weight: bold;
	position: relative;
}

#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.welcome-message {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>