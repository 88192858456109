<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link v-if="isAdmin" :to="{path: '/admin-reports-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Validator Reports</router-link>
        <router-link v-else :to="{path: '/questions-validator-talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
        <reports-downloader :reportsTitle="reportsTitle" :documentTitle="documentTitle" />
      </div>
      <div v-if="isAdmin" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/admin-reports-validator' }" class="breadcumb"> Validator Reports > </router-link>
        <router-link :to="{ path: '/questions-validator-exams-reports' }" class="final-breadcumb"> Exams Reports </router-link>
      </div>
      <div v-else class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/questions-validator-talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/questions-validator-exams-reports' }" class="final-breadcumb"> Exams Reports </router-link>
      </div>
    </div>

    <h4 class="reports-available-title">Reports available</h4>
    <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport" @change="onChange($event)" >
			<option :value="undefined">Select a report to view</option>
      <option value="columns-skills">Column diagrams displaying skills</option>
      <option value="validated-per-language">Validated questions per language</option>
      <option value="validated-per-difficult">Validated questions per level of difficulty and skills</option>
      <option value="validated-per-skill">Validated questions per language and skill</option>
      <option value="validated-non-validated">Validated and non validated questions</option>
		</select>

    <question-graph-by-technology v-if="selectedReport == 'columns-skills'" ref="chart1" :areas="areas"/>
    <question-graph-by-language v-if="selectedReport == 'validated-per-language'" ref="chart2" :areas="areas"/>
    <question-graph-by-level v-if="selectedReport == 'validated-per-difficult'" ref="chart3" :areas="areas"/>
    <question-graph-by-language-technology v-if="selectedReport == 'validated-per-skill'" ref="chart4" :areas="areas"/>
    <validated-questions-graph-by-company v-if="selectedReport == 'validated-non-validated'" ref="chart5" :areas="areas"/>
  </div>
</template>

<script>
  import QuestionGraphByTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByTechnology.vue";
  import QuestionGraphByLanguage from "@/components/ReportsComponents/Questions/QuestionGraphByLanguage.vue";
  import QuestionGraphByLevel from "@/components/ReportsComponents/Questions/QuestionGraphByLevel.vue";
  import QuestionGraphByLanguageTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByLanguageTechnology.vue";
  import ValidatedQuestionsGraphByCompany from "@/components/ReportsComponents/Questions/ValidatedQuestionsGraphByCompany.vue";
  import TestsResultsByCompanyDifficultyLevel from "@/components/ReportsComponents/Questions/TestsResultsByCompanyDifficultyLevel.vue";
  import AreasDataService from "@/services/AreasDataService";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";
  
  export default {
    name: 'ExamsReportsView',
    components: {
      QuestionGraphByTechnology,
      QuestionGraphByLanguage,
      QuestionGraphByLevel,
      QuestionGraphByLanguageTechnology,
      ValidatedQuestionsGraphByCompany,
      TestsResultsByCompanyDifficultyLevel,
      ReportsDownloader
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      }
    },
    data() {
      return {
        reportsTitle: "Exams Reports",
        documentTitle: "questions_validator_exam_reports.pdf",
        areas: [],
        selectedReport: undefined
      }
    },
    mounted() {
      if (this.isAdmin)
        this.getListOfAreasFromAdmin();
      else
        this.areas = this.$store.state.auth.user.areas;
    },
    methods: {
      async getListOfAreasFromAdmin() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.report_selector {
  margin-left: 3%;
  margin-right: 3%; 
  margin-top: 2%;
  max-width: 94%;
}

.reports-available-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}
</style>