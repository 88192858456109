import { render, staticRenderFns } from "./UsersGraphByCompany.vue?vue&type=template&id=ba0f9fc8&"
import script from "./UsersGraphByCompany.vue?vue&type=script&lang=js&"
export * from "./UsersGraphByCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports