<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/reports-admin'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Admin Reports</router-link>
        <reports-downloader :reportsTitle="reportsTitle" :documentTitle="documentTitle"/>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{ $t('dashboards.general.reporting') }} > </router-link>
        <router-link :to="{ path: '/reports-admin' }" class="breadcumb"> General Reports > </router-link>
        <router-link :to="{ path: '/admin-exams-reports' }" class="final-breadcumb"> Exams Reports </router-link>
      </div>
    </div>

    <h4 class="reports-available-title">Reports available</h4>
    <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport" @change="onChange($event)" >
			<option :value="undefined">Select a report to view</option>
      <option value="validated-questions">Validated and non validated questions</option>
      <option value="skills">Column diagrams displaying skills</option>
      <option value="results-by-level">Test results by level of the exams</option>
		</select>

    <ValidatedQuestionsGraphByCompany v-if="selectedReport == 'validated-questions'" ref="chart1" :areas="areas"/>
    <QuestionGraphByTechnology v-if="selectedReport == 'skills'"  ref="chart2" :areas="areas"/>
    <TestsResultsByCompanyDifficultyLevel v-if="selectedReport == 'results-by-level'"  ref="chart3" :areas="areas"/>
  </div>
</template>

<script>
  import ValidatedQuestionsGraphByCompany from "@/components/ReportsComponents/Questions/ValidatedQuestionsGraphByCompany.vue";
  import QuestionGraphByTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByTechnology.vue";
  import TestsResultsByCompanyDifficultyLevel from "@/components/ReportsComponents/Questions/TestsResultsByCompanyDifficultyLevel.vue";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";
  import AreasDataService from "@/services/AreasDataService";
  
  export default {
    name: 'ExamsReportsView',
    components: {
      ValidatedQuestionsGraphByCompany,
      QuestionGraphByTechnology,
      TestsResultsByCompanyDifficultyLevel,
      ReportsDownloader
    },
    data() {
      return {
        reportsTitle: "Exams Reports",
        documentTitle: "admin_exam_reports.pdf",
        areas: [],
        selectedReport: undefined
      }
    },
    mounted() {
      this.getListOfAreas();
    },
    methods: {
      async getListOfAreas() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.report_selector {
  margin-left: 3%;
  margin-right: 3%; 
  margin-top: 2%;
  max-width: 94%;
}

.reports-available-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}
</style>