<template>
	<div class="col-sm-12">
		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>

		<b-card no-body>
			<b-tabs pills card>
				<div v-for="(elem, index) in config" v-bind:key="elem.title">
					<b-tab :class="elem.title + '_tab'" active>
						<template #title>
						<strong>{{elem.title.charAt(0).toUpperCase() + elem.title.slice(1)}}</strong>
					</template>
					<template v-if="isAdmin">
						<general-config ref="general" v-if="elem.id == 0" :areaConfig="areaConfig" :key="configLoaded"/>
						<meetings-config ref="meetings" v-if="elem.id == 1" :areaConfig="areaConfig" :key="configLoaded"/>
						<notifications-config ref="notifications" v-if="elem.id == 2" :areaConfig="areaConfig" :key="configLoaded"/>
					</template>
					<template v-else>
						<meetings-config ref="meetings" v-if="elem.id == 0" :areaConfig="areaConfig" :key="configLoaded"/>
						<notifications-config ref="notifications" v-if="elem.id == 1" :areaConfig="areaConfig" :key="configLoaded"/>
					</template>
					</b-tab>
				</div>
			</b-tabs>
		</b-card>

		<div class="row center-row mt-2 mb-3">
      		<button class="btn btn-primary btn-save" type="button" @click="saveSettings()">
      			<font-awesome-icon class="white-color" icon="save" /> Save
      		</button>
      	</div>
	</div>
</template>

<script>
	import GeneralConfig from "@/components/VideoconferencesComponents/Configuration/GeneralConfig";
	import MeetingsConfig from "@/components/VideoconferencesComponents/Configuration/MeetingsConfig";
	import NotificationsConfig from "@/components/VideoconferencesComponents/Configuration/NotificationsConfig";
	import InterviewsDataService from '@/services/InterviewsDataService';

	export default {
	  	name: 'ConfigurationVideconference',
	  	data() {
			return {
				areaConfig: {},
				isSuperAdmin: false,
				message: "",
				successfull: false,
				configLoaded: false
			}
	    },
	    computed: {
	    	isAdmin() {
	    		return this.$store.state.auth.user.role.name === 'admin';
	    	},
	    	config() {
	    		if (this.$store.state.auth.user.role.name === 'admin') {
	    			return [
			          {title: "General", id: 0},
			          {title: "Meetings", id: 1},
			          {title: "Notifications", id: 2},
			        ]
	    		} else {
	    			return [
			          {title: "Meetings", id: 0},
			          {title: "Notifications", id: 1},
			        ]
	    		}
	    	}
	    },
	    components: {
	    	GeneralConfig,
	    	MeetingsConfig,
	    	NotificationsConfig
	    },
	    mounted() {
	    	this.getVideoconferencesConfigurationFromArea();
	    },
		methods: {
			async getVideoconferencesConfigurationFromArea() {
				const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				await InterviewsDataService.findAreaSettings(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.areaConfig = response.data;
							this.configLoaded = this.configLoaded ? false : true;
						} else {
							this.message = "An error ocurred while retrieving configuration for this area.";
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
				          error.message ||
				          error.toString())
						this.message = (error.response && error.response.data) ||
				          error.message ||
				          error.toString();
				        this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			saveSettings() {
				let newConfig = {};
				this.successfull = true
				this.message = ""

				Object.entries(this.$refs).forEach(([refName, components]) => {
					let component = components[0];

					if ("general" == refName){
						newConfig[component.configParams[0].key] = component.configParams[0].value;
					} else if ("meetings" == refName) {
						newConfig[component.configParams.basic[0].key] = component.configParams.basic[0].value;
						newConfig[component.configParams.basic[1].key] = component.configParams.basic[1].value;

						newConfig[component.configParams.questions[0].key] = component.configParams.questions[0].value;
						newConfig[component.configParams.questions[1].key] = component.configParams.questions[1].value;
						newConfig[component.configParams.questions[2].key] = component.configParams.questions[2].value;

						newConfig[component.configParams.others[0].key] = component.configParams.others[0].isSet;
						newConfig[component.configParams.others[1].key] = component.configParams.others[1].isSet;
						newConfig[component.configParams.others[2].key] = component.configParams.others[2].isSet;
						newConfig[component.configParams.others[2].save.key] = component.configParams.others[2].save.saveChatForAll;
					} else if ("notifications" == refName) {
						newConfig[component.configParams[0].key] = component.configParams[0].isSet;
						newConfig[component.configParams[1].key] = component.configParams[1].isSet;
					}
				});

				newConfig.id_area = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

				if (parseFloat(newConfig.limit_interviews_per_month) < 0 || parseFloat(newConfig.max_candidates_per_interview) < 0 ||
					parseFloat(newConfig.max_questions_per_interview) < 0 || parseFloat(newConfig.time_to_answer_question) < 0 ||
					parseFloat(newConfig.time_to_display_questions) < 0 || parseFloat(newConfig.tries_per_question) < 0) {
					this.successfull = false
				}

				if (this.successfull) {
					InterviewsDataService.updateAreaInterviewSettings(newConfig).then(
						response => {
							if (response.status == 200) {
								this.areaConfig = response.data;
								this.message = "Setting successfully updated!";
								this.successfull = true;
							} else {
								this.message = "An error ocurred while retrieving configuration for this area.";
								this.successfull = false;
							}
						},
						error => {
							console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
							this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
							this.successfull = false;

							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
						}
					);
				} else {
					this.message = "Numbers must be positive or zero."
				}
			},
			close() {
				document.getElementById("message").style.display = "none"
				this.message = ""
				this.successfull = true
			}
		}
	};
</script>

<style type="text/css">
.nav-pills li a.active {
	color: #000000 !important;
}
.card {
	max-width: 100% !important;
	margin-top: 0;
}
.tabs {
	width: 100% !important;
}
</style>