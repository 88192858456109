<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<select v-if="!isSuperAdmin" class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option value="all">All areas</option>
      		<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:chartTitle = "graphTitle"
				:data="data">
			</barr-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue";

	export default {
		name: 'UsersRegistredGraphByRole',
		data() {
			return {
				chartId: "chartUsersRegistredGraphByRole",
				data: [],
				temp: [],
				graphTitle: "Registered users per role",
				xAxesCategory: "name",
				yAxesValue: "total_users",
				xAxesTitle: "Role",
				yAxesTitle: "Users",
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedAreaName: null,
				isSuperAdmin: false,
			}
		},
		components: {
			BarrChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.graphLoading = true;
				this.selectedArea = event.target.value;

				if ("all" == this.selectedArea){
					this.selectedAreaName = null;
					this.fetchDataCompany();
				} else {
					this.areas.forEach((area) => {
						if (parseInt(area.id) == parseInt(this.selectedArea))
							this.selectedAreaName = area.name
							this.graphTitle = "Registered users per role in " +  this.selectedAreaName;
					});

					this.fetchDataArea();
				}
			},
			async fetchDataGeneral() {
				await ReportsDataService.registeredUsersByRole().then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			async fetchDataCompany() {
				await ReportsDataService.registeredUserRolesByCompany(this.$store.state.auth.user.id_company).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			async fetchDataArea() {
				await ReportsDataService.registeredUserRolesByAreas(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.graphLoading = true;
			this.selectedArea = "all";
			
			if (this.$store.state.auth.user.role.name == 'superadmin'){
				this.isSuperAdmin = true
				
				this.fetchDataGeneral();
			} else{
				this.isSuperAdmin = false
				
				this.fetchDataCompany();
			}
		}
	};
</script>