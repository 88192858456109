<template>
    <div v-if="dataLoading" class="text-center text-loading">
        <b-spinner class="align-middle analysis-spinner"></b-spinner>
        <strong class="text-loading-msg"> Loading data...</strong>
    </div>
    <div v-else class="row technology-skills-div mt-2">
        <div class="row mb-3 h4-div-class">
            <h4 class="h4-class">Select an user to evaluate</h4>
        </div>
        <div class="row mb-3">
            <div class="col-sm-4 noMargin mb-3">
                <select class="form-select area_selector" data-vv-name="area_selector" v-model="user">
                    <option value="0">Select an user</option>
                    <option v-for="u in users" v-bind:key="u.id" :value="u.id">
                        <template v-if="u.role.name !== undefined">
                            {{ u.name }} {{ u.surname }} ({{ u.role.name.toUpperCase() }})
                        </template>
                        <template v-else>
                            {{ u.name }} ({{ u.role.toUpperCase() }})
                        </template>
                    </option>
                </select>
            </div>
        </div>

        <div v-if="parseInt(user) !== 0">
            <div class="row mb-3 h4-div-class">
                <h4 class="h4-class">Evaluators</h4>
            </div>
            <div class="row mb-2">
                <div class="col-2">
                    <p class="p-text">External Evaluators: </p>
                </div>
                <div class="col-5">
                    <multiselect id="hrtechnician_selector" :options="hrtechniciansToShow" :multiple="true"
                        :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                        placeholder="Search or add an External Evaluator" v-model="selectedHRTechnicians" label="label"
                        track-by="value" @select="selectOptionHRTechnicians" @remove="removeOptionHRTechnicians">
                    </multiselect>
                </div>
                <div class="col-5" v-if="hrtechniciansToAdd.length !== 0">
                    <div class="row">
                        <div class="col-3 padding-10">
                            <p class="p-weight-evaluators">Weight</p>
                        </div>
                        <div class="col-3 padding-0">
                            <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                v-model="hrtechnicianWeight"></b-input>
                        </div>
                        <div class="col-1 padding-0">
                            <p class="p-percentage-evaluators">%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-2">
                    <p class="p-text">Responsables: </p>
                </div>
                <div class="col-5">
                    <multiselect id="responsable_selector" :options="responsablesToShow" :multiple="true"
                        :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                        placeholder="Search or add a responsable" v-model="selectedResponsables" label="label"
                        track-by="value" @select="selectOptionResponsables" @remove="removeOptionResponsables">
                    </multiselect>
                </div>
                <div class="col-5" v-if="responsablesToAdd.length !== 0">
                    <div class="row">
                        <div class="col-3 padding-10">
                            <p class="p-weight-evaluators">Weight</p>
                        </div>
                        <div class="col-3 padding-0">
                            <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                v-model="responsablesWeight"></b-input>
                        </div>
                        <div class="col-1 padding-0">
                            <p class="p-percentage-evaluators">%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-2">
                    <p class="p-text">Pairs: </p>
                </div>
                <div class="col-5">
                    <multiselect id="pair_selector" :options="pairsToShow" :multiple="true" :close-on-select="false"
                        :preserve-search="true" :taggable="true" :preselect-first="false"
                        placeholder="Search or add a pair" v-model="selectedPairs" label="label" track-by="value"
                        @select="selectOptionPairs" @remove="removeOptionPairs"></multiselect>
                </div>
                <div class="col-5" v-if="pairsToAdd.length !== 0">
                    <div class="row">
                        <div class="col-3 padding-10">
                            <p class="p-weight-evaluators">Weight</p>
                        </div>
                        <div class="col-3 padding-0">
                            <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                v-model="pairsWeight"></b-input>
                        </div>
                        <div class="col-1 padding-0">
                            <p class="p-percentage-evaluators">%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-2 mb-3">
                    <p class="p-text">Colaborators: </p>
                </div>
                <div class="col-5 mb-3">
                    <multiselect id="colaborator_selector" :options="colaboratorsToShow" :multiple="true"
                        :close-on-select="false" :preserve-search="true" :taggable="true" :preselect-first="false"
                        placeholder="Search or add a colaborator" v-model="selectedColaborators" label="label"
                        track-by="value" @select="selectOptionColaborators" @remove="removeOptionColaborators">
                    </multiselect>
                </div>
                <div class="col-5 mb-3" v-if="colaboratorsToAdd.length !== 0">
                    <div class="row">
                        <div class="col-3 padding-10">
                            <p class="p-weight-evaluators">Weight</p>
                        </div>
                        <div class="col-3 padding-0">
                            <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                v-model="colaboratorsWeight"></b-input>
                        </div>
                        <div class="col-1 padding-0">
                            <p class="p-percentage-evaluators">%</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3 h4-div-class">
                <h4 class="h4-class">Skills Map</h4>
            </div>

            <p id="startFromZero" v-if="startFromZero" class="alert alert-primary alert-profile alert-interview">
                Previous evaluation configuration is deprecated. If you want to update this evaluation, you must fully
                configure
                it. <b-button @click="closeStartFromZero" class="cross-button"><font-awesome-icon class="cross"
                        icon="times" />
                </b-button>
            </p>

            <div class="row container-fluid div-configuration">
                <div class="col-2" v-if="byGroups">
                    <h6 class="order-title">Groups weights</h6>
                    <div class="form-check form-switch dichotomic-switch div-dichotomic"><input
                            id="groupsWeightsCheckbox" class="form-check-input checkbox-required" type="checkbox"
                            v-model="groupsWeights"></div>
                </div>
                <div class="col-3">
                    <h6 class="order-title">Weight per competence</h6>
                    <div class="form-check form-switch dichotomic-switch div-dichotomic"><input
                            id="competencesWeightsCheckbox" class="form-check-input checkbox-required" type="checkbox"
                            v-model="competencesWeights"></div>
                </div>
            </div>

            <div class="row container-fluid" v-if="!byGroups">
                <div class="row div-competences" v-for="(competence, index_competence) in competencesWithoutGroups"
                    :key="competence.id">
                    <div class="row">
                        <div class="col-sm-auto">
                            <input :id="'competence-' + index_competence" :ref="'competence-' + index_competence"
                                class="form-check-input margin-top-45" type="checkbox" v-model="competence.required"
                                @change="changedInputCompetenceSelected($event, competence)">
                        </div>
                        <div class="col-11 padding-0" v-if="competencesWeights && competence.required === true">
                            <div class="row">
                                <div class="col-3">
                                    <p class="title-groups margin-top-3">Competence {{ index_competence + 1 }}</p>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="float-left margin-top-3">{{ competence.name }}</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="p-weight-evaluation-competence">Weight</p>
                                        </div>
                                        <div class="col-3">
                                            <b-input class="input-evaluator-weight" type="number" min="0" max="100"
                                                v-model="competence.weight"></b-input>
                                        </div>
                                        <div class="col-1">
                                            <p class="p-percentage-competence">%</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-1">
                                    <button class="btn btn-secondary margin-top-15" type="button"
                                        v-b-toggle="'toggleC-' + index_competence.toString()"
                                        @click="changeShowCompetenceWithoutGroupState(index_competence)">
                                        <font-awesome-icon style="display: none;"
                                            v-bind:id="'iconShowC-' + index_competence.toString()" class="trash-icon"
                                            v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon style="display: block;"
                                            v-bind:id="'iconNotShowC-' + index_competence.toString()" class="trash-icon"
                                            v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-11 padding-0" v-else>
                            <div class="row">
                                <div class="col-3">
                                    <p class="title-groups margin-top-3">Competence {{ index_competence + 1 }}</p>
                                </div>
                                <div class="col-8">
                                    <p class="float-left margin-top-1-5">{{ competence.name }}</p>
                                </div>
                                <div class="col-1">
                                    <button class="btn btn-secondary margin-top-15" type="button"
                                        v-b-toggle="'toggleC-' + index_competence.toString()"
                                        @click="changeShowCompetenceWithoutGroupState(index_competence)">
                                        <font-awesome-icon style="display: none;"
                                            v-bind:id="'iconShowC-' + index_competence.toString()" class="trash-icon"
                                            v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                        <font-awesome-icon style="display: block;"
                                            v-bind:id="'iconNotShowC-' + index_competence.toString()" class="trash-icon"
                                            v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-collapse visible v-bind:id="'toggleC-' + index_competence.toString()">
                        <div class="row">
                            <div class="col-10">
                                <div class="row align-items-center">
                                    <p class="title-min-level">Min Level Required</p>
                                </div>
                                <div class="row">
                                    <b-form-group :name="'competence-' + index_competence.toString()"
                                        class="form-group-margin" v-slot="{ ariaDescribedby }">
                                        <b-form-radio class="radio-button-class test-options-display"
                                            v-for="(level, index_level) in competence.levels" :key="level.id"
                                            :id="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                            :ref="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                            v-model="competence.minLevel" :aria-describedby="ariaDescribedby"
                                            :name="'competence-' + index_competence.toString()"
                                            :value="parseInt(index_level)" :disabled="!competence.required">

                                            <p v-if="index_level !== 0"> Level
                                                {{ index_level }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                            </p>
                                            <p v-else>{{ level.name }} </p>
                                        </b-form-radio>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <hr v-if="index_competence != (competencesWithoutGroups.length - 1)">
                </div>
            </div>

            <div class="row container-fluid" v-if="byGroups">
                <div class="row div-competences">
                    <div class="row" v-for="(group, index) in competencesByGroups"
                        :key="group.id + '-' + group.required.toString()">
                        <div class="row">
                            <div class="col-sm-auto">
                                <input id="groupsRequiredCheckbox" class="form-check-input margin-top-45"
                                    type="checkbox" v-model="group.required"
                                    @change="changedInputGroupSelected($event, group)">
                            </div>

                            <div class="col-10 padding-0" v-if="groupsWeights && group.required === true">
                                <div class="row">
                                    <div class="col-2">
                                        <p class="title-groups margin-top-4">Group {{ index + 1 }}</p>
                                    </div>
                                    <div class="col-9">
                                        <div class="row">
                                            <div class="col-7">
                                                <p class="float-left margin-top-2">{{ group.name }}</p>
                                            </div>
                                            <div class="col-2">
                                                <p class="p-weight-evaluation-group">Weight</p>
                                            </div>
                                            <div class="col-2">
                                                <b-input class="input-evaluator-weight form-control" type="number"
                                                    min="0" max="100" v-model="group.weight"></b-input>
                                            </div>
                                            <div class="col-1">
                                                <p class="p-percentage-group">%</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-1">
                                        <button class="btn btn-secondary margin-top-10" type="button"
                                            v-b-toggle="'toggleG-' + index.toString()"
                                            @click="changeShowGroupState(index)">
                                            <font-awesome-icon style="display: none;"
                                                v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                            <font-awesome-icon style="display: block;"
                                                v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-10 padding-0" v-else>
                                <div class="row">
                                    <div class="col-2">
                                        <p class="title-groups margin-top-4">Group {{ index + 1 }}</p>
                                    </div>
                                    <div class="col-9">
                                        <p class="float-left margin-top-1">{{ group.name }}</p>
                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-secondary margin-top-10" type="button"
                                            v-b-toggle="'toggleG-' + index.toString()"
                                            @click="changeShowGroupState(index)">
                                            <font-awesome-icon style="display: none;"
                                                v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                            <font-awesome-icon style="display: block;"
                                                v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row align-items-center">
                                        <p class="title-competences-evaluation">Competences</p>
                                    </div>
                                    <div class="row margin-left-10"
                                        v-for="(competence, index_competence) in group.competences"
                                        :key="competence.id">
                                        <div class="row">
                                            <div class="col-sm-auto">
                                                <input
                                                    :id="'competence-' + index_competence + '-' + competence.id_group"
                                                    :ref="'competence-' + index_competence + '-' + competence.id_group"
                                                    class="form-check-input margin-top-45" type="checkbox"
                                                    v-model="competence.required" :disabled="!group.required"
                                                    @change="changedInputCompetenceSelected($event, competence)">
                                            </div>
                                            <div class="col-11 padding-0"
                                                v-if="competencesWeights && competence.required === true">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <p class="title-groups margin-top-3">Competence
                                                            {{ index_competence + 1 }}
                                                        </p>
                                                    </div>
                                                    <div class="col-8">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <p class="float-left margin-top-3">{{ competence.name }}
                                                                </p>
                                                            </div>
                                                            <div class="col-2">
                                                                <p class="p-weight-evaluation-competence">Weight</p>
                                                            </div>
                                                            <div class="col-3">
                                                                <b-input class="input-evaluator-weight" type="number"
                                                                    min="0" max="100"
                                                                    v-model="competence.weight"></b-input>
                                                            </div>
                                                            <div class="col-1">
                                                                <p class="p-percentage-competence">%</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-1">
                                                        <button class="btn btn-secondary margin-top-15" type="button"
                                                            v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                            @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                            <font-awesome-icon style="display: none;"
                                                                v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                icon="chevron-down" />
                                                            <font-awesome-icon style="display: block;"
                                                                v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Contract"
                                                                icon="chevron-up" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-11 padding-0" v-else>
                                                <div class="row">
                                                    <div class="col-3">
                                                        <p class="title-groups margin-top-3">Competence
                                                            {{ index_competence + 1 }}
                                                        </p>
                                                    </div>
                                                    <div class="col-8">
                                                        <p class="float-left margin-top-1-5">{{ competence.name }}</p>
                                                    </div>
                                                    <div class="col-1">
                                                        <button class="btn btn-secondary margin-top-15" type="button"
                                                            v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                            @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                            <font-awesome-icon style="display: none;"
                                                                v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                icon="chevron-down" />
                                                            <font-awesome-icon style="display: block;"
                                                                v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Contract"
                                                                icon="chevron-up" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-collapse visible
                                            v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="row align-items-center">
                                                        <p class="title-min-level">Min Level Required</p>
                                                    </div>
                                                    <div class="row">
                                                        <b-form-group
                                                            :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                            class="form-group-margin" v-slot="{ ariaDescribedby }">
                                                            <b-form-radio class="radio-button-class test-options-display"
                                                                v-for="(level, index_level) in competence.levels"
                                                                :key="level.id"
                                                                :id="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                                :ref="'level-' + index_competence + '-' + index_level + '-' + level.id_competence"
                                                                v-model="competence.minLevel"
                                                                :aria-describedby="ariaDescribedby"
                                                                :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                                :value="parseInt(index_level)"
                                                                :disabled="!competence.required">
                                                                <p v-if="index_level !== 0"> Level
                                                                    {{ index_level }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                                </p>
                                                                <p v-else>{{ level.name }} </p>
                                                            </b-form-radio>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                        <hr v-if="index != (competencesByGroups.length - 1)">
                    </div>
                </div>
            </div>

            <div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
                {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                        icon="times" />
                </b-button>
            </div>

            <div class="form-group col-lg-4 align-item-center">
                <div class="row">
                    <div class="col-sm-6">
                        <button class="btn btn-secondary btn-cancel" type="button"
                            @click="backToSoftskillsConfiguration()"><font-awesome-icon icon="ban" /> Cancel</button>
                    </div>
                    <div class="col-sm-6">
                        <input class="btn btn-primary form-submit" type="button" @click="handleUpdate()" value="Update">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserDataService from '@/services/UserDataService';
import SoftskillsDataService from '@/services/SoftskillsDataService';
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import Multiselect from 'vue-multiselect'

export default {
    name: 'UpdateEvaluationComponent',
    data() {
        return {
            evaluation: {},
            skillsMap: [],
            competencesWithoutGroups: [],
            competencesByGroups: [],
            allUsersInCompany: [],
            allUsersInArea: [],
            cvsUploadedByAdmins: [],
            users: [],
            hrtechnicians: [],
            responsables: [],
            pairs: [],
            colaborators: [],
            hrtechniciansToShow: [],
            responsablesToShow: [],
            pairsToShow: [],
            colaboratorsToShow: [],
            hrtechniciansToAdd: [],
            responsablesToAdd: [],
            pairsToAdd: [],
            colaboratorsToAdd: [],
            user: 0,
            userObject: {},
            selectedHRTechnicians: [],
            selectedResponsables: [],
            selectedPairs: [],
            selectedColaborators: [],
            hrtechnicianWeight: 0,
            responsablesWeight: 0,
            pairsWeight: 0,
            colaboratorsWeight: 0,
            groupsWeights: false,
            competencesWeights: false,
            successfull: false,
            byGroups: false,
            startFromZero: false,
            dataLoading: false,
            message: ""
        }
    },
    watch: {
        user(val) {
            this.hrtechnicians = []
            this.hrtechniciansToShow = []
            this.hrtechniciansToAdd = []
            this.selectedHRTechnicians = []
            this.hrtechnicianWeight = 0
            this.responsables = []
            this.responsablesToShow = []
            this.responsablesToAdd = []
            this.selectedResponsables = []
            this.responsablesWeight = 0
            this.pairs = []
            this.pairsToShow = []
            this.pairsToAdd = []
            this.selectedPairs = []
            this.pairsWeight = 0
            this.colaborators = []
            this.colaboratorsToShow = []
            this.colaboratorsToAdd = []
            this.selectedColaborators = []
            this.colaboratorsWeight = 0
            this.userObject = {}

            this.allUsersInArea.forEach(user => {
                if (user.id === val) {
                    this.userObject = user
                }
            })

            this.allUsersInArea.forEach(user => {
                if (user.id !== val && Object.keys(this.userObject).length !== 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user)
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user)
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === this.userObject.role.id) {
                        this.pairs.push(user)
                        this.pairsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    if (user.role.id !== 3 && user.role.id !== 9) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                } else if (user.id !== val && Object.keys(this.userObject).length === 0) {
                    if (user.role.id === 9) {
                        this.hrtechnicians.push(user)
                        this.hrtechniciansToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    } else if (user.role.id === 1 || user.role.id === 8) {
                        this.responsables.push(user)
                        this.responsablesToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }

                    if (user.role.id !== 3 && user.role.id !== 9) {
                        this.colaborators.push(user)
                        this.colaboratorsToShow.push({ label: user.name + " " + user.surname + " (" + user.role.name.toUpperCase() + ")", value: user.name + "-" + user.id })
                    }
                }
            })
        },
        responsablesToAdd(val) {
            this.colaboratorsToShow = []

            this.colaborators.forEach(colaborator => {
                let exists = false

                this.responsablesToAdd.forEach(responsable => {

                    if (colaborator.id === responsable) exists = true
                })

                this.pairsToAdd.forEach(pair => {

                    if (colaborator.id === pair) exists = true
                })

                if (exists === false) this.colaboratorsToShow.push({ label: colaborator.name + " " + colaborator.surname + " (" + colaborator.role.name.toUpperCase() + ")", value: colaborator.name + "-" + colaborator.id })
            })

            this.responsablesToAdd.forEach(responsable => {
                this.selectedColaborators.forEach(colaborator => {
                    var valueSplit = colaborator.value.split('-')

                    if (responsable === parseInt(valueSplit[1])) {
                        const indexColaboratorsToAdd = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
                        if (indexColaboratorsToAdd !== -1) this.colaboratorsToAdd.splice(indexColaboratorsToAdd, 1)

                        const indexSelectedColaborators = this.selectedColaborators.map(object => object.value).indexOf(colaborator.value);
                        this.selectedColaborators.splice(indexSelectedColaborators, 1)
                    }
                })
            })
        },
        pairsToAdd(val) {
            this.colaboratorsToShow = []

            this.colaborators.forEach(colaborator => {
                let exists = false

                this.responsablesToAdd.forEach(responsable => {

                    if (colaborator.id === responsable) exists = true
                })

                this.pairsToAdd.forEach(pair => {

                    if (colaborator.id === pair) exists = true
                })

                if (exists === false) this.colaboratorsToShow.push({ label: colaborator.name + " " + colaborator.surname + " (" + colaborator.role.name.toUpperCase() + ")", value: colaborator.name + "-" + colaborator.id })
            })

            this.pairsToAdd.forEach(pair => {
                this.selectedColaborators.forEach(colaborator => {
                    var valueSplit = colaborator.value.split('-')

                    if (pair === parseInt(valueSplit[1])) {
                        const indexColaboratorsToAdd = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
                        if (indexColaboratorsToAdd !== -1) this.colaboratorsToAdd.splice(indexColaboratorsToAdd, 1)

                        const indexSelectedColaborators = this.selectedColaborators.map(object => object.value).indexOf(colaborator.value);
                        this.selectedColaborators.splice(indexSelectedColaborators, 1)
                    }
                })
            })
        }
    },
    components: {
        Multiselect
    },
    computed: {
    },
    async mounted() {
        this.dataLoading = true
        await SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
            response => {
                this.skillsMap = response.data

                if (this.skillsMap.length > 0) {
                    if (this.skillsMap[0].type === "group") {
                        this.byGroups = true

                        this.skillsMap = this.skillsMap.filter(function (item) {
                            return item.is_active === true
                        })

                        this.skillsMap.forEach(group => {
                            group.competences = group.competences.filter(function (item) {
                                return item.is_active === true
                            })
                        })

                        this.skillsMap.forEach(group => {
                            group.competences.forEach(competence => {
                                competence.levels = competence.levels.filter(function (item) {
                                    return item.is_active === true
                                })
                            })
                        })

                        this.skillsMap.forEach(item => {
                            item.competences.forEach(competence => {
                                competence.levels.shift()
                            })
                        })

                        this.competencesByGroups = this.skillsMap

                        this.competencesByGroups.forEach(group => {
                            group.weight = 0
                            group.required = false

                            group.competences.forEach(competence => {
                                competence.weight = 0
                                competence.required = false
                                competence.minLevel = 0

                                competence.levels.forEach(level => {
                                    level.required = false
                                })
                            })
                        })
                    } else if (this.skillsMap[0].type === 'competence') {
                        this.byGroups = false

                        this.skillsMap = this.skillsMap.filter(function (item) {
                            return item.is_active === true
                        })

                        this.skillsMap.forEach(competence => {
                            competence.levels = competence.levels.filter(function (item) {
                                return item.is_active === true
                            })
                        })

                        this.skillsMap.forEach(item => {
                            item.levels.shift()
                        })

                        this.competencesWithoutGroups = this.skillsMap

                        this.competencesWithoutGroups.forEach(competence => {
                            competence.weight = 0
                            competence.required = false
                            competence.minLevel = 0

                            competence.levels.forEach(level => {
                                level.required = false
                            })
                        })
                    }
                } else {
                    this.successfull = false
                    this.message = 'No hay competencias.'
                }
            },
            error => {
                this.dataLoading = false
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

        await UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
            async response => {
                this.allUsersInCompany = response.data

                await UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                    async response => {
                        this.allUsersInArea = response.data

                        await ProfileAnalysisDataService.findCVsByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
                            response => {
                                for (let i = 0; i < response.data.cvs.length; i++) {
                                    for (let j = 0; j < this.allUsersInArea.length; j++) {
                                        if (response.data.cvs[i].id_user === this.allUsersInArea[j].id && this.allUsersInArea[j].role.id !== 1) {
                                            this.cvsUploadedByAdmins.push({
                                                id: response.data.cvs[i].id,
                                                name: response.data.cvs[i].name,
                                                email: response.data.cvs[i].email,
                                                role: 'candidate'
                                            })
                                        }
                                    }
                                }

                                for (let i = 0; i < this.allUsersInArea.length; i++) {
                                    this.users.push(this.allUsersInArea[i])
                                }

                                for (let i = 0; i < this.cvsUploadedByAdmins.length; i++) {
                                    this.users.push(this.cvsUploadedByAdmins[i])
                                }
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

        await SoftskillsDataService.findEvaluation(this.$route.query.id).then(
            async response => {
                this.evaluation = response.data
                if (this.evaluation.evaluated_candidate === null) {
                    this.user = this.evaluation.evaluated_user.id
                    this.userObject = this.evaluation.evaluated_user
                } else {
                    this.user = this.evaluation.evaluated_candidate.id
                    this.userObject = this.evaluation.evaluated_candidate
                }

                let totalHRTechnicianWeight = 0
                let totalResponsableWeight = 0
                let totalPairWeight = 0
                let totalColaboratorWeight = 0

                for (let i = 0; i < this.evaluation.evaluators.length; i++) {
                    let tmpEvaluator = this.evaluation.evaluators[i]

                    await UserDataService.findOne(tmpEvaluator.id).then(
                        response => {
                            let selectedEvaluator = {
                                label: tmpEvaluator.name + ' ' + tmpEvaluator.surname + ' (' + response.data.role.name.toUpperCase() + ')',
                                value: tmpEvaluator.name + '-' + tmpEvaluator.id
                            }

                            if (tmpEvaluator.soft_skills_evaluation_evaluators.role === "hr_technician") {
                                totalHRTechnicianWeight += tmpEvaluator.soft_skills_evaluation_evaluators.weight
                                this.selectedHRTechnicians.push(selectedEvaluator)
                                this.hrtechniciansToAdd.push(tmpEvaluator.id)
                            } else if (tmpEvaluator.soft_skills_evaluation_evaluators.role === "responsible") {
                                totalResponsableWeight += tmpEvaluator.soft_skills_evaluation_evaluators.weight
                                this.selectedResponsables.push(selectedEvaluator)
                                this.responsablesToAdd.push(tmpEvaluator.id)
                            } else if (tmpEvaluator.soft_skills_evaluation_evaluators.role === "pair") {
                                totalPairWeight += tmpEvaluator.soft_skills_evaluation_evaluators.weight
                                this.selectedPairs.push(selectedEvaluator)
                                this.pairsToAdd.push(tmpEvaluator.id)
                            } else {
                                totalColaboratorWeight += tmpEvaluator.soft_skills_evaluation_evaluators.weight
                                this.selectedColaborators.push(selectedEvaluator)
                                this.colaboratorsToAdd.push(tmpEvaluator.id)
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }

                this.hrtechnicianWeight = totalHRTechnicianWeight
                this.responsablesWeight = totalResponsableWeight
                this.pairsWeight = totalPairWeight
                this.colaboratorsWeight = totalColaboratorWeight

                if (this.byGroups) {
                    for (let i = 0; i < this.evaluation.weights.length; i++) {
                        let exist = false
                        for (let j = 0; j < this.competencesByGroups.length; j++) {
                            if (this.evaluation.weights[i].id_soft_skill === this.competencesByGroups[j].id) exist = true
                            else {
                                for (let k = 0; k < this.competencesByGroups[j].competences.length; k++) {
                                    if (this.evaluation.weights[i].id_soft_skill === this.competencesByGroups[j].competences[k].id) exist = true
                                }
                            }
                        }

                        if (!exist) {
                            this.startFromZero = true
                            break
                        }
                    }

                    if (!this.startFromZero) {
                        this.evaluation.weights.forEach(weight => {
                            this.competencesByGroups.forEach(group => {
                                if (group.id === weight.id_soft_skill) {
                                    group.required = true
                                    if (weight.weight === null) {
                                        group.weight = 0
                                        this.groupsWeights = false
                                    } else {
                                        group.weight = weight.weight
                                        this.groupsWeights = true
                                    }
                                }

                                group.competences.forEach(competence => {
                                    if (competence.id === weight.id_soft_skill) {
                                        competence.required = true
                                        if (weight.weight === null) {
                                            competence.weight = 0
                                            this.competencesWeights = false
                                        } else {
                                            competence.weight = weight.weight
                                            this.competencesWeights = true
                                        }

                                        competence.minLevel = weight.min_level - 1
                                    }
                                })
                            })
                        })
                    }
                } else {
                    for (let i = 0; i < this.evaluation.weights.length; i++) {
                        let exist = false
                        for (let j = 0; j < this.competencesWithoutGroups.length; j++) {
                            if (this.evaluation.weights[i].id_soft_skill === this.competencesWithoutGroups[j].id) exist = true
                        }

                        if (!exist) {
                            this.startFromZero = true
                            break
                        }
                    }

                    if (!this.startFromZero) {
                        this.evaluation.weights.forEach(weight => {
                            this.competencesWithoutGroups.forEach(competence => {
                                if (competence.id === weight.id_soft_skill) {
                                    competence.required = true
                                    if (weight.weight === null) {
                                        competence.weight = 0
                                        this.competencesWeights = false
                                    } else {
                                        competence.weight = weight.weight
                                        this.competencesWeights = true
                                    }

                                    competence.minLevel = weight.min_level - 1
                                }
                            })
                        })
                    }
                }

                this.dataLoading = false
            },
            error => {
                this.dataLoading = false
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        changeShowGroupState(index) {
            if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceState(index) {
            if (document.getElementById('iconShowC-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceFromGroupState(index, index_competence) {
            if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
            }
        },
        changeShowCompetenceWithoutGroupState(index_competence) {
            if (document.getElementById('iconShowC-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index_competence.toString()).style.display = "none"
            }
        },
        selectOptionHRTechnicians(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.hrtechniciansToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionHRTechnicians(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))

            var valueSplit = parsedOption.value.split('-')

            const index = this.hrtechniciansToAdd.indexOf(parseInt(valueSplit[1]));
            this.hrtechniciansToAdd.splice(index, 1)
        },
        selectOptionResponsables(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.responsablesToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionResponsables(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))

            var valueSplit = parsedOption.value.split('-')

            const index = this.responsablesToAdd.indexOf(parseInt(valueSplit[1]));
            this.responsablesToAdd.splice(index, 1)
        },
        selectOptionPairs(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.pairsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionPairs(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))

            var valueSplit = parsedOption.value.split('-')

            const index = this.pairsToAdd.indexOf(parseInt(valueSplit[1]));
            this.pairsToAdd.splice(index, 1)
        },
        selectOptionColaborators(option) {
            var object = {}

            var parsedOption = JSON.parse(JSON.stringify(option))

            object.label = parsedOption.label
            object.value = parsedOption.value
            var valueSplit = parsedOption.value.split('-')

            this.colaboratorsToAdd.push(parseInt(valueSplit[1]))
        },
        removeOptionColaborators(option) {
            var parsedOption = JSON.parse(JSON.stringify(option))

            var valueSplit = parsedOption.value.split('-')

            const index = this.colaboratorsToAdd.indexOf(parseInt(valueSplit[1]));
            this.colaboratorsToAdd.splice(index, 1)
        },
        backToSoftskillsConfiguration() {
            this.$router.push({ path: 'softskills-configuration' })
        },
        handleUpdate() {
            this.successfull = true

            if (this.hrtechniciansToAdd.length !== 0 || this.responsablesToAdd.length !== 0 || this.pairsToAdd.length !== 0 || this.colaboratorsToAdd.length !== 0) {
                let totalWeights = 0

                if (this.hrtechniciansToAdd.length !== 0) totalWeights += parseFloat(this.hrtechnicianWeight)
                if (this.responsablesToAdd.length !== 0) totalWeights += parseFloat(this.responsablesWeight)
                if (this.pairsToAdd.length !== 0) totalWeights += parseFloat(this.pairsWeight)
                if (this.colaboratorsToAdd.length !== 0) totalWeights += parseFloat(this.colaboratorsWeight)

                if (parseFloat(totalWeights) === 100) {
                    if (this.byGroups) {
                        let groupsRequired = false

                        this.competencesByGroups.forEach(group => {
                            if (group.required) {
                                let competencesRequired = false

                                groupsRequired = true

                                group.competences.forEach(competence => {
                                    if (competence.required) competencesRequired = true
                                })

                                if (!competencesRequired) {
                                    this.successfull = false
                                    this.message = 'You must select at least one competence per selected group'
                                }
                            }
                        })

                        if (groupsRequired) {
                            if (this.groupsWeights) {
                                let totalGroupsWeights = 0
                                this.competencesByGroups.forEach(group => {
                                    if (group.required) totalGroupsWeights += parseFloat(group.weight)
                                })

                                if (totalGroupsWeights === 100) {
                                    if (this.competencesWeights) {
                                        this.competencesByGroups.forEach(group => {
                                            if (group.required) {
                                                let totalCompetencesWeightsByGroups = 0

                                                group.competences.forEach(competence => {
                                                    totalCompetencesWeightsByGroups += parseFloat(competence.weight)
                                                })

                                                if (totalCompetencesWeightsByGroups === 100) {
                                                    console.log('')
                                                } else {
                                                    this.successfull = false
                                                    this.message = 'The sum of the weights of the competences of some group is not 100% (currently: ' + totalCompetencesWeightsByGroups + '%)'
                                                }
                                            }
                                        })

                                        if (this.successfull) this.createEvaluations()
                                    } else {
                                        if (this.successfull) this.createEvaluations()
                                    }
                                } else {
                                    this.successfull = false
                                    this.message = 'The sum of the weights of the groups is not 100% (currently: ' + totalGroupsWeights + '%)'
                                }
                            } else {
                                if (this.competencesWeights) {
                                    this.competencesByGroups.forEach(group => {
                                        if (group.required) {
                                            let totalCompetencesWeightsByGroups = 0

                                            group.competences.forEach(competence => {
                                                totalCompetencesWeightsByGroups += parseFloat(competence.weight)
                                            })

                                            if (totalCompetencesWeightsByGroups === 100) {
                                                console.log('')
                                            } else {
                                                this.successfull = false
                                                this.message = 'The sum of the weights of the competences of some group is not 100% (currently: ' + totalCompetencesWeightsByGroups + '%)'
                                            }
                                        }
                                    })

                                    if (this.successfull) this.createEvaluations()
                                } else {
                                    if (this.successfull) this.createEvaluations()
                                }
                            }

                        } else {
                            this.successfull = false
                            this.message = 'You must select at least one group'
                        }
                    } else {
                        let competencesRequired = false

                        this.competencesWithoutGroups.forEach(competence => {
                            if (competence.required) {
                                competencesRequired = true
                            }
                        })

                        if (competencesRequired) {
                            if (this.competencesWeights) {
                                let totalCompetencesWeightsWithoutGroups = 0

                                this.competencesWithoutGroups.forEach(competence => {
                                    if (competence.required) {
                                        totalCompetencesWeightsWithoutGroups += parseFloat(competence.weight)
                                    }
                                })

                                if (totalCompetencesWeightsWithoutGroups === 100) {
                                    if (this.successfull) this.createEvaluations()
                                } else {
                                    this.successfull = false
                                    this.message = 'The sum of the weights of the competences is not 100% (currently: ' + totalCompetencesWeightsWithoutGroups + '%)'
                                }
                            } else {
                                if (this.successfull) this.createEvaluations()
                            }
                        } else {
                            this.successfull = false
                            this.message = 'You must select at least one competence'
                        }
                    }
                } else {
                    this.successfull = false
                    this.message = 'The sum of the weights of the evaluators is not 100% (currently: ' + totalWeights + '%)'
                }

            } else {
                this.successfull = false
                this.message = 'You must assign at least one evaluator'
            }
        },
        createEvaluations() {
            if (this.byGroups) {
                let evaluation = {}
                let tipo = ''

                if (Object.keys(this.userObject).length !== 0) {
                    evaluation = {
                        id_evaluated_user: this.userObject.id,
                        average_result: 0,
                        done: 0,
                        id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
                    }
                    tipo = 'user'
                } else {
                    evaluation = {
                        id_evaluated_candidate: this.user,
                        average_result: 0,
                        done: 0,
                        id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
                    }
                    tipo = 'candidate'
                }

                SoftskillsDataService.deleteEvaluation(this.$route.query.id).then(
                    response => {
                        SoftskillsDataService.createEvaluation(evaluation, tipo).then(
                            response => {
                                let evaluationCreated = response.data

                                let numHRTechnicians = this.hrtechniciansToAdd.length
                                let numResponsables = this.responsablesToAdd.length
                                let numPairs = this.pairsToAdd.length
                                let numColaborators = this.colaboratorsToAdd.length

                                let evaluatorsToAdd = []

                                this.hrtechniciansToAdd.forEach(hrtechnician => {
                                    let evaluator = {
                                        id_evaluator: hrtechnician,
                                        role: 'hr_technician',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.hrtechnicianWeight / numHRTechnicians),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.responsablesToAdd.forEach(responsable => {
                                    let evaluator = {
                                        id_evaluator: responsable,
                                        role: 'responsible',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.responsablesWeight / numResponsables),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.pairsToAdd.forEach(pair => {
                                    let evaluator = {
                                        id_evaluator: pair,
                                        role: 'pair',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.pairsWeight / numPairs),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.colaboratorsToAdd.forEach(colaborator => {
                                    let evaluator = {
                                        id_evaluator: colaborator,
                                        role: 'collaborator',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.colaboratorsWeight / numColaborators),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, evaluationCreated.id).then(
                                    response => {
                                        let weightsToAdd = []

                                        let numGroups = 0

                                        this.competencesByGroups.forEach(group => {
                                            if (group.required) {
                                                numGroups += 1
                                            }
                                        })

                                        this.competencesByGroups.forEach(group => {
                                            if (group.required) {
                                                let groupWeightToAdd = {}

                                                if (this.groupsWeights) {
                                                    groupWeightToAdd = {
                                                        id_evaluation: evaluationCreated.id,
                                                        id_soft_skill: group.id,
                                                        weight: parseFloat(group.weight),
                                                        min_level: null
                                                    }
                                                } else {
                                                    groupWeightToAdd = {
                                                        id_evaluation: evaluationCreated.id,
                                                        id_soft_skill: group.id,
                                                        weight: parseFloat(100 / numGroups),
                                                        min_level: null
                                                    }
                                                }

                                                weightsToAdd.push(groupWeightToAdd)

                                                let numCompetences = 0

                                                group.competences.forEach(competence => {
                                                    if (competence.required) {
                                                        numCompetences += 1
                                                    }
                                                })

                                                group.competences.forEach(competence => {
                                                    if (competence.required) {
                                                        let competenceWeightToAdd = {}

                                                        if (this.competencesWeights) {
                                                            competenceWeightToAdd = {
                                                                id_evaluation: evaluationCreated.id,
                                                                id_soft_skill: competence.id,
                                                                weight: parseFloat(competence.weight),
                                                                min_level: parseInt(competence.minLevel + 1)
                                                            }
                                                        } else {
                                                            competenceWeightToAdd = {
                                                                id_evaluation: evaluationCreated.id,
                                                                id_soft_skill: competence.id,
                                                                weight: parseFloat(100 / numCompetences),
                                                                min_level: parseInt(competence.minLevel + 1)
                                                            }
                                                        }

                                                        weightsToAdd.push(competenceWeightToAdd)
                                                    }
                                                })
                                            }
                                        })

                                        SoftskillsDataService.addWeightsToEvaluation(weightsToAdd, evaluationCreated.id).then(
                                            response => {
                                                this.$router.push({ name: "softskills-configuration" });
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    },
                                    error => {
                                        console.log((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            } else {
                let evaluation = {
                    id_evaluated: this.userObject.id,
                    average_result: 0,
                    done: 0,
                    id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
                }

                SoftskillsDataService.deleteEvaluation(this.$route.query.id).then(
                    response => {
                        SoftskillsDataService.createEvaluation(evaluation).then(
                            response => {
                                let evaluationCreated = response.data
                                let numHRTechnicians = this.hrtechniciansToAdd.length
                                let numResponsables = this.responsablesToAdd.length
                                let numPairs = this.pairsToAdd.length
                                let numColaborators = this.colaboratorsToAdd.length

                                let evaluatorsToAdd = []

                                this.hrtechniciansToAdd.forEach(hrtechnician => {
                                    let evaluator = {
                                        id_evaluator: hrtechnician,
                                        role: 'hr_technician',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.hrtechnicianWeight / numHRTechnicians),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.responsablesToAdd.forEach(responsable => {
                                    let evaluator = {
                                        id_evaluator: responsable,
                                        role: 'responsible',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.responsablesWeight / numResponsables),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.pairsToAdd.forEach(pair => {
                                    let evaluator = {
                                        id_evaluator: pair,
                                        role: 'pair',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.pairsWeight / numPairs),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                this.colaboratorsToAdd.forEach(colaborator => {
                                    let evaluator = {
                                        id_evaluator: colaborator,
                                        role: 'collaborator',
                                        id_evaluation: evaluationCreated.id,
                                        weight: parseFloat(this.colaboratorsWeight / numColaborators),
                                        done: 0
                                    }

                                    evaluatorsToAdd.push(evaluator)
                                })

                                SoftskillsDataService.addEvaluatorsToEvaluation(evaluatorsToAdd, evaluationCreated.id).then(
                                    response => {
                                        let weightsToAdd = []

                                        let numCompetences = 0

                                        this.competencesWithoutGroups.forEach(competence => {
                                            if (competence.required) {
                                                numCompetences += 1
                                            }
                                        })

                                        this.competencesWithoutGroups.forEach(competence => {
                                            if (competence.required) {
                                                let competenceWeightToAdd = {}

                                                if (this.competencesWeights) {
                                                    competenceWeightToAdd = {
                                                        id_evaluation: evaluationCreated.id,
                                                        id_soft_skill: competence.id,
                                                        weight: parseFloat(competence.weight),
                                                        min_level: parseInt(competence.minLevel + 1)
                                                    }
                                                } else {
                                                    competenceWeightToAdd = {
                                                        id_evaluation: evaluationCreated.id,
                                                        id_soft_skill: competence.id,
                                                        weight: parseFloat(100 / numCompetences),
                                                        min_level: parseInt(competence.minLevel + 1)
                                                    }
                                                }

                                                weightsToAdd.push(competenceWeightToAdd)
                                            }
                                        })

                                        SoftskillsDataService.addWeightsToEvaluation(weightsToAdd, evaluationCreated.id).then(
                                            response => {
                                                this.$router.push({ name: "softskills-configuration" });
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    },
                                    error => {
                                        console.log((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            }
        },
        close() {
            document.getElementById("message").style.display = "none"
            this.message = ''
        },
        closeStartFromZero() {
            document.getElementById("startFromZero").style.display = "none"
        },
        changedInputGroupSelected(event, group) {
            this.competencesByGroups.forEach(gr => {
                if (gr.id === group.id) {
                    if (gr.required) {
                        gr.competences.forEach(c => {
                            c.required = true
                        })
                    } else {
                        gr.competences.forEach(c => {
                            c.required = false
                        })
                    }
                }
            })

            this.$forceUpdate()
        },
        changedInputCompetenceSelected(event, competence) {
            if (!competence.required) competence.minLevel = 0

            this.$forceUpdate()
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>