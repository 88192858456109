<template>
    <div>
        <div class="row mt-4">
            <h5 class="mb-0" style="text-align: left !important;">Change password</h5>
        </div>
        <div class="row">
            <form action class="form">
                <div class="row">
                    <div class="col-sm-4">
                        <label class="my-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Current Password*<b-button class="btn btn-info" v-b-tooltip.hover title="You must enter your current password"><font-awesome-icon icon="info-circle" /></b-button></label>
                        <b-input-group>
                            <b-form-input 
                                :type="visibility"
                                class="form-control inputStyle"
                                id="currentPassword"
                                required
                                v-model="currentPassword"
                                v-validate="'required|min:8|max:40'"
                                data-vv-name="currentPassword" 
                                name="currentPassword"
                                placeholder="Current Password">
                            </b-form-input>
                            <b-input-group-append>
                                <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'password'">
                                    <font-awesome-icon icon="eye" />
                                </a>

                                <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'text'">
                                    <font-awesome-icon icon="eye-slash" />
                                </a>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-if="veeErrors.has('currentPassword')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
                            Current password is required!
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label class="my-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">New Password*<b-button class="btn btn-info" v-b-tooltip.hover title="You must enter your current password"><font-awesome-icon icon="info-circle" /></b-button></label>
                        <b-input-group>
                            <b-form-input 
                                :type="visibility"
                                class="form-control inputStyle"
                                id="newPassword"
                                required
                                v-model="newPassword"
                                v-validate="'required|min:8|max:40'"
                                data-vv-name="newPassword" 
                                name="newPassword"
                                placeholder="New Password">
                            </b-form-input>
                            <b-input-group-append>
                                <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'password'">
                                    <font-awesome-icon icon="eye" />
                                </a>

                                <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'text'">
                                    <font-awesome-icon icon="eye-slash" />
                                </a>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-if="veeErrors.has('newPassword')" class="alert alert-danger py-0 mt-2 mb-0" role="alert">
                            New password is required and must be between 8 and 40 characters long!
                        </div>
                    </div>
                </div>
                <div id="messagePassword" v-if="messagePassword != ''" class="alert" :class="successfullPassword ? 'alert-success' : 'alert-danger'">
                    {{messagePassword}} <b-button @click="closePasswordMessage" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
                </div>
                <div class="row justify-content-center mb-2 mt-2">
                    <div class="col-2">
                        <input class="btn btn-primary form-submit" type="button" value="Update password" @click="handleUpdatePassword()">
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import UserDataService from "@/services/UserDataService";

	export default {
		name: 'UpdatePasswordComponent',
		data() {
            return {
                currentPassword: "",
                newPassword: "",
                messagePassword: "",
                visibility: 'password'
            }
        },
        methods: {
            handleUpdatePassword(){
                this.$validator.validate().then(isValid => {
                    if (!isValid) {
                        return
                    } else {
                        UserDataService.updatePassword(this.userId, this.currentPassword, this.newPassword).then(
                            response => {
                                if (response.status == 200) {
                                    this.messagePassword = "Password successfully updated!";
                                    this.successfullPassword = true;

                                    this.currentPassword = "";
                                    this.newPassword = "";
                                    this.$validator.reset();
                                } else {
                                    this.successfullPassword = false;
                                    this.messagePassword = "Error: Invalid password";
                                }
                            },
                            error => {
                                console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                                this.successfullPassword = false;
                                this.messagePassword = "Error while updating password!";
                                
                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    }
                });
            },
            changeVisibility(){
                if(this.visibility == 'password'){
                    this.visibility = 'text';
                } else this.visibility = 'password';
            },
            closePasswordMessage(){
				this.messagePassword = "";
			}
        },
        props: {
		    userId: Number
		}
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>