<template>
	<div class="table-container technologiesStyle" style="padding-top: 5% !important;">
		<template v-if="s4Processes.length > 0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="s4ProcessesList"></b-pagination>
				</div>
				<div class="col-sm-7">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="addAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
						rows</button>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="dismissAllRows"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
				</div>
				<div class="col-sm-3">
					<b-form-input id="filter-input" v-model="filter" type="search"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>
			<b-table v-if="show" ref="s4ProcessesTable" id="s4ProcessesList" :per-page="perPage"
				:current-page="currentPage" :fields="headers" :items="s4Processes" :filter="filter" responsive="sm"
				:tbody-tr-class="rowClass">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(name)="data">
					{{ data.item.name }}
				</template>
				<template #cell(options)="data">
					<button v-b-tooltip.hover title="Add" v-if="!data.item.show" class="btn btn-secondary"
						@click="addS4(data.item, data.index)"><font-awesome-icon icon="plus" /></button>
					<button v-b-tooltip.hover title="Dismiss" v-else class="btn btn-secondary"
						@click="dismissS4(data.item, data.index)"><font-awesome-icon icon="ban" /></button>
					<router-link v-b-tooltip.hover title="Show Softskills Process"
						:to="{ path: '/softskills-statistics?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
				</template>
			</b-table>
			<div id="message" v-if="message != ''" class="alert"
				:class="successfull ? 'alert-success' : 'alert-danger'">
				{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" />
				</b-button>
			</div>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.
			</h3>
		</template>
	</div>
</template>

<script>
import $ from 'jquery';
import BusinessProcessService from "@/services/BusinessProcessService";
import S4DataService from "@/services/S4DataService";

export default {
	name: 'AddS4',
	data() {
		return {
			headers: [
				{ key: 'name', sortable: true, label: 'Name' }, { key: 'options', label: 'Options', tdClass: 'tdSmall' },
			],
			message: "",
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			s4Processes: [],
			selectedItems: [],
			selectedS4Process: null,
			selectedS4ProcessIndex: null,
			show: false,
		}
	},
	computed: {
		rows() {
			return this.s4Processes.length
		}
	},
	mounted() {
		if (this.$store.state.auth.user.table_records_per_page != undefined) {
			this.perPage = this.$store.state.auth.user.table_records_per_page
		}

		this.getS4Processes();
	},
	watch: {
		selectedItems: {
			handler: function (val) {
				if (val.length != 0) {
					this.$emit('can-continue', { value: true });
				} else {
					this.$emit('can-continue', { value: false });
				}
			},
			deep: true
		},
		clickedNext(val) {
			if (val === true) {
				this.$form.$touch();
			}
		}
	},
	methods: {
		async getS4Processes() {
			await S4DataService.getProcessesByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					this.s4Processes = response.data;

					this.s4Processes.forEach(s4 => {
						s4.show = false;
					});
					this.show = true;
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		highlightSelectedS4Processes() {
			BusinessProcessService.findOne(this.$route.query.id).then(
				response => {
					this.s4Processes.forEach(s4Process => {
						s4Process.selectedItem = false;
						response.data.s4_processes.forEach(s4Selected => {
							if (s4Process.id == s4Selected.id) {
								s4Process.selected = true;
								this.selectedItems.push(s4Process);
								return;
							}
							s4Process.index = s4Process.id;
						});
					});
					this.show = true;
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			this.message = "";
		},
		addS4(item, index) {
			this.$refs["s4ProcessesTable"].refresh();
			this.selectedItems = [];
			this.s4Processes.forEach((s4, indexS4) => {
				s4.show = false;
				$('.table tr ').eq(indexS4 + 1).removeClass('validated-question');
			})
			$('.table tr ').eq(index + 1).addClass('validated-question');
			item.show = true;
			this.selectedItems.push(item);
		},
		dismissS4(item, index) {
			this.$refs["s4ProcessesTable"].refresh();
			this.selectedItems = [];
			this.s4Processes.forEach(s4 => {
				s4.show = false;
			})
			item.show = false;
			$('.table tr ').eq(index + 1).removeClass('validated-question');
			this.selectedItems = this.selectedItems.filter((item2) => item2.id !== item.id);
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return;
			if (item.selected) return 'validated-question';
		},
		addAllRows() {
			this.$refs["s4ProcessesTable"].refresh();
			this.s4Processes.forEach(function (value, index) {
				$('#validate_' + index).css('display', 'none')
				$('#avoid_' + index).css('display', 'block')
				$('.table tr ').eq(index + 1).addClass('validated-question');
			});
			this.selectedItems = this.s4Processes;
		},
		dismissAllRows() {
			this.$refs["s4ProcessesTable"].refresh();
			this.s4Processes.forEach(function (value, index) {
				$('#validate_' + index).css('display', 'block')
				$('#avoid_' + index).css('display', 'none')
				$('.table tr ').eq(index + 1).removeClass('validated-question');
			});
			this.selectedItems = [];
		}
	}
};
</script>