<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/professionalUpdating?idProfile='+idProfile}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Reskilling/Upskilling</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/config-profile' }" class="breadcumb"> Configuration of the Levels of Expertise > </router-link>
        <router-link :to="{ path: '/professionalUpdating?idProfile='+idProfile }" class="breadcumb"> Reskilling/Upskilling ></router-link>
        <router-link :to="{ path: '/recycledtags' }" class="final-breadcumb"> Update </router-link>
      </div>
    </div>	

  	<div class="col-sm-12">
      <div class="col-sm-12 justify-content-center col-centered">	
  		  <update :id_origin="id_origin" :id_destiny="id_destiny" :id_area="id_area" :level_origin="level_origin" :level_destiny="level_destiny" 
          :level_origin_id="level_origin_id" :level_destiny_id="level_destiny_id"
        />
      </div>
  	</div>
  </div>
</template>

<script>
  import Update from "@/components/ProfessionalUpdatingComponents/Update.vue";

	export default {
    name: 'UpdateUpdating.vue',
    data() {
      return {
          tags: [],
          technologies: [],
          idProfile:"",
        }
    },
    components: {
      Update
    },
    props: {
      id_origin: Number,
      id_destiny: Number,
      id_area: Number,
      level_origin_id: Number,
      level_destiny_id: Number,
      level_origin: String,
      level_destiny: String
    },
    mounted(){
      this.idProfile = this.id_origin

      if(this.id_origin == undefined || this.id_destiny == undefined || this.id_area==undefined || this.level_origin_id == undefined
        || this.level_destiny_id == undefined || this.level_origin == undefined || this.level_destiny == undefined)
            this.$router.push('/config-profile')
    },
    methods: {
      close() {
        document.getElementById("message").style.display = "none"
      },
      close2() {
        document.getElementById("message2").style.display = "none"
      }
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>
