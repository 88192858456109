<template>
    <div class="col-sm-12">
        <div class="row secondNavbar"> 
            <div class="col-5 links-container">
                <router-link :to="{path: '/softskills-configuration'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to BEI/360 Evaluation Configuration</router-link>
            </div>
    
            <div v-if="isAdmin" class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Settings > </router-link>
                <router-link :to="{ path: '/softskills-configuration' }" class="breadcumb"> BEI/360 Evaluation Configuration > </router-link>
                <router-link :to="{ path: '/softskills-evaluation-results-admin' }" class="final-breadcumb"> {{ title }} </router-link>
            </div>
        </div>
    
        <div class="col-sm-12 px-4 col-centered">
            <evaluation-results-admin
                v-if="evaluation != null"
                :title="title"
                :evaluated="evaluation.users_evaluations"
                :evaluators="evaluation.evaluation_evaluators"
                :groupsWeights="evaluation.weights"
                :description="evaluation.description"
            />
        </div>
    </div>
</template>
  
<script>
    import EvaluationResultsAdmin from "@/components/SoftSkillsComponent/EvaluationResultsAdmin.vue";
    import SoftskillsDataService from '@/services/SoftskillsDataService';

    export default {
        components: {
            EvaluationResultsAdmin
        },
        props: {
        },
        data () {
            return {
                title: "",
                evaluation: null
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            isAdmin() {
                if (this.currentUser && this.currentUser.role) {
                    if (this.currentUser.role.name === 'admin') {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted () {
            SoftskillsDataService.findEvaluationSummary(this.$route.query.id).then(
                response => {
                    this.evaluation = response.data;
                    this.title = this.evaluation.name;
                },
                error => {
                    this.dataLoading = false
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        }
    };

</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>