<template>
	<div :id="chartId" ref="chartdiv"></div>
</template>

<script>
	import * as am4core from "@amcharts/amcharts4/core";
	import * as am4charts from "@amcharts/amcharts4/charts";

	export default {
	    name: 'BarrChart',
	    data() {
	    	return {
	    		chart: null,
				numVariables:0
	    	}
   		},
	    props: {
	    	chartId: String,
			chartTitle: String,
	    	data: Array,
	    	xAxesCategory: String,
	    	yAxesValue: String,
	    	xAxesTitle: String,
	    	yAxesTitle: String,
	    	tooltipNoDataMsg : {
	    		type: String,
	    		default: "No data available"
	    	},
			forceVertical: Boolean,
	    },
	    mounted() {
	    	let data = []

	    	if (this.data != undefined)
	    		data = JSON.parse(JSON.stringify(this.data))
			
	    	this.chart = am4core.create(this.chartId, am4charts.XYChart);
	
			data.forEach((element, index, array) => {
				element.id_data = index;
			});

	    	if (0 == data.length) {
			    let label = this.chart.createChild(am4core.Label);
				label.text = this.tooltipNoDataMsg;
				label.fontSize = 20;
				label.align = "center";
				label.isMeasured = false;
				label.x = am4core.percent(50);
				label.y = 100;
				label.horizontalCenter = "middle";
			} else {
				this.chart.data = data;

				this.chart.exporting.menu = new am4core.ExportMenu();
				this.chart.exporting.menu.items = [
					{
						"label": "...",
						"menu": [
						{
							"label": "Image",
							"menu": [
							{ "type": "png", "label": "PNG" },
							{ "type": "jpg", "label": "JPG" },
							{ "type": "svg", "label": "SVG" }
							]
						}, {
							"label": "Data",
							"menu": [
							{ "type": "json", "label": "JSON" },
							{ "type": "csv", "label": "CSV" },
							{ "type": "xlsx", "label": "XLSX" },
							{ "type": "html", "label": "HTML" }
							]
						}, {
							"label": "Print", "type": "print"
						}
						]
					}
				]

				this.chart.exporting.filePrefix = this.chartTitle

				this.chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
				this.numVariables = data.length
				if(this.forceVertical){
					this.setVerticalBarrChart();
				}
				else{
					if (data.length > 7) {
						this.setHorizontalBarrChart();
					} else {
						this.setVerticalBarrChart();
					}
				}
				
			}
	    },
	    methods: {
	    	setHorizontalBarrChart() {
	    		let xAxesCategoryTmp = this.yAxesValue
	    		let yAxesValueTmp = this.xAxesCategory

	    		let xAxesTitleTmp = this.yAxesTitle
	    		let yAxesTitleTmp = this.xAxesTitle

				let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
				categoryAxis.renderer.grid.template.location = 0;
				categoryAxis.dataFields.category = yAxesValueTmp;
				categoryAxis.renderer.minGridDistance = 1;
				categoryAxis.renderer.inversed = true;
				categoryAxis.renderer.grid.template.disabled = true;
				categoryAxis.title.text = yAxesTitleTmp
				categoryAxis.title.fontWeight = "bold"

				var valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = xAxesTitleTmp
				valueAxis.title.fontWeight = "bold"
				
				if (this.chartId !== "chartUsersRegistredGraphByRole" && this.chartId !== "chartUsersGraphByCompany"
				&& this.chartId !== "chartExamsGraphByCompany" && this.chartId !== "chartQuestionsGraphByCompany"
				&& this.chartId !== "chartProfilesGraphByCompany" && this.chartId !== "chartCVsGraphByCompany"
				&& this.chartId !== "chartValidatedQuestionsGraphByCompany" && this.chartId !== "chartQuestionGraphByTechnology"
				&& this.chartId !== "chartUsersGraphTotalCVByCompany" && this.chartId !== "chartUsersGraphCVByAdmins"
				&& this.chartId !== "chartUsersGraphCVByCompany" ) {
					valueAxis.max = 10;
				}
				valueAxis.min = 0;
				valueAxis.strictMinMax = true

				var series = this.chart.series.push(new am4charts.ColumnSeries());
				series.dataFields.categoryY = yAxesValueTmp;
				series.dataFields.valueX = xAxesCategoryTmp;
				series.name = "Visits";

				let checkKeyPresenceInArray = key => this.data.some(obj => Object.keys(obj).includes(key));
				const isKeyPresent = checkKeyPresenceInArray('exam_title')

				if (isKeyPresent)
					series.columns.template.tooltipText = "{exam_title}: [bold]{valueY}[/]";
				else
					series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
				series.columns.template.strokeOpacity = 0;
				series.columns.template.column.cornerRadiusBottomRight = 5;
				series.columns.template.column.cornerRadiusTopRight = 5;
				series.columns.template.width = am4core.percent(70);

				let colors = ["#845EC2","#D65DB1","#FF6F91","#FF9671","#FFC75F","#F9F871","#13283B"]
				let colorSet = new am4core.ColorSet();

				let gradient = new am4core.LinearGradient();
				gradient.addColor(am4core.color("white"));
				gradient.addColor(am4core.color("blue"));
				

				var labelBullet = series.bullets.push(new am4charts.LabelBullet())
				labelBullet.label.horizontalCenter = "left";
				labelBullet.label.dx = 10;
				labelBullet.label.text = "{values.valueX.workingValue}";
				labelBullet.locationX = 1;

	    	},
	    	setVerticalBarrChart() {
				console.log(this.chartId)
				let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
				categoryAxis.dataFields.category = this.xAxesCategory;
				categoryAxis.renderer.grid.template.location = 0;
				categoryAxis.renderer.minGridDistance = 30;
				categoryAxis.title.text = this.xAxesTitle
				categoryAxis.title.fontWeight = "bold"
				categoryAxis.title.dy = 15

				var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = this.yAxesTitle
				valueAxis.title.fontWeight = "bold"
				
				if (this.chartId !== "chartUsersRegistredGraphByRole" && this.chartId !== "chartUsersGraphByCompany"
				&& this.chartId !== "chartExamsGraphByCompany" && this.chartId !== "chartQuestionsGraphByCompany"
				&& this.chartId !== "chartProfilesGraphByCompany" && this.chartId !== "chartCVsGraphByCompany"
				&& this.chartId !== "chartValidatedQuestionsGraphByCompany" && this.chartId !== "chartQuestionGraphByTechnology"
				&& this.chartId !== "chartUsersGraphTotalCVByCompany" && this.chartId !== "chartUsersGraphCVByAdmins"
				&& this.chartId !== "chartUsersGraphCVByCompany" && this.chartId !== "chartUserExamsGraphByMarks") {
					valueAxis.max = 10;
				}
				if(this.chartId == "chartUserExamsGraphByMarks")
					valueAxis.max = 100;
				valueAxis.min = 0;
				valueAxis.strictMinMax = true
			
				var series = this.chart.series.push(new am4charts.ColumnSeries());
				series.dataFields.valueY = this.yAxesValue;
				series.dataFields.categoryX = this.xAxesCategory;
				series.name = "Visits";

				let colors2Vars = [am4core.color("#4FC0E8"),am4core.color("#13283B")]
				let colors3Vars = ["#4FC0E8","#007195","#13283B"]
				let colors4Vars = ["8FF9FF","#4FC0E8","#007195","#13283B"]
				let colors5Vars = ["8FF9FF","#4FC0E8","#26A5CC","#007195","#13283B"]
				let colors6Vars = ["8FF9FF","##70DCFF","#4FC0E8","#26A5CC","#007195","#13283B"]
				let colors = ["#8FF9FF","#70DCFF","#4FC0E8","#26A5CC","#007195","#004162","#13283B"]
				let numElements = this.numVariables


				series.columns.template.width = am4core.percent(70);
				series.columns.template.maxWidth = 50;


				let checkKeyPresenceInArray = key => this.data.some(obj => Object.keys(obj).includes(key));
				const isKeyPresent = checkKeyPresenceInArray('exam_title')

				if (isKeyPresent)
					series.columns.template.tooltipText = "{exam_title}: [bold]{valueY}[/]";
				else
					series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
				series.columns.template.fillOpacity = .8;

				var columnTemplate = series.columns.template;
				columnTemplate.strokeWidth = 1;
				columnTemplate.strokeOpacity = 1;

				if(this.numVariables > 18){
					this.chart.scrollbarX = new am4core.Scrollbar();
				
					console.log('SCROLL BAR', this.chart.scrollbarX)

					this.chart.xAxes.getIndex(0).start = 0;  // Iniciar en 0%
					this.chart.xAxes.getIndex(0).end = 0.2;  // Terminar en 20%
				}
				
	    	}
	    }
	};
</script>