<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: '/softskills-list' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" />
          Back to Soft Skills Processes</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/softskills-update-process' }" class="final-breadcumb"> Update Soft Skills Process
        </router-link>
      </div>
    </div>

    <div class="col-sm-8 col-centered">
      <UpdateS4Process />
    </div>
  </div>
</template>

<script>
import UpdateS4Process from "@/components/S4Components/Update.vue";

export default {
  name: 'UpdateS4ProcessView',
  components: {
    UpdateS4Process
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}
</style>