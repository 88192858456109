<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/difficulties'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Levels of Difficulty</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/difficulties' }" class="breadcumb"> Levels of Difficulty > </router-link>
        <router-link :to="{ path: '/create-level' }" class="final-breadcumb"> Create Level of Difficulty </router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered">	
  		<create-difficulty/>
  	</div>
  </div>
</template>

<script>
  import CreateDifficulty from "@/components/DifficultyComponents/Create.vue";

	export default {
    name: 'CreateLanguageView',
    components: {
      CreateDifficulty
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>