<template>
	<div class="form-container technologiesStyle">
        <div class="row mt-2">
            <h5 class="mb-0" style="text-align: left !important;">General information</h5>
        </div>
		<form action class="form" @submit.prevent="handleCreate">
            <div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
                {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Username *<b-button class="btn btn-info" v-b-tooltip.hover title="You must choose a username to login"><font-awesome-icon icon="info-circle" /></b-button></label>
                                                
                    <input
                        type="text"
                        class="form-control inputStyle"
                        id="username"
                        required
                        v-model="user.username"
                        name="username"
                        v-validate="'required|min:6|max:20'" 
                        data-vv-name="username"
                        placeholder="Username"
                    />
                    <div v-if="veeErrors.has('username')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('username')}}
                    </div>
                </div>

                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Name *<b-button class="btn btn-info" v-b-tooltip.hover title="You must enter your name"><font-awesome-icon icon="info-circle" /></b-button></label>
                                                
                    <input
                        type="text"
                        class="form-control inputStyle"
                        id="name"
                        required
                        v-model="user.name"
                        name="name"
                        v-validate="'required'" 
                        data-vv-name="name" 
                        placeholder="Name"
                    />
                    <div v-if="veeErrors.has('name')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('name')}}
                    </div>
                </div>
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Surname *<b-button class="btn btn-info" v-b-tooltip.hover title="You must enter your surname"><font-awesome-icon icon="info-circle" /></b-button></label>
                                                
                    <input
                        type="text"
                        class="form-control inputStyle"
                        id="surname"
                        required
                        v-model="user.surname"
                        name="surname"
                        v-validate="'required'" 
                        data-vv-name="surname" 
                        placeholder="Surname"
                    />
                    <div v-if="veeErrors.has('surname')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('surname')}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Password *<b-button class="btn btn-info" v-b-tooltip.hover title="You must choose a password to login"><font-awesome-icon icon="info-circle" /></b-button></label>
                    <div>
                    <b-input-group>
                        <b-form-input 
                            :type="visibility"
                            class="form-control inputStyle"
                            id="password"
                            required
                            v-model="user.password"
                            v-validate="'required|min:8'"
                            data-vv-name="password" 
                            name="password"
                            placeholder="Password">
                        </b-form-input>
                        <b-input-group-append>
                            <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'password'">
                                <font-awesome-icon icon="eye" />
                            </a>

                            <a @click="changeVisibility" style="position: absolute; right: 10px; top: 7px;" class="notunderlined" v-if="visibility == 'text'">
                                <font-awesome-icon icon="eye-slash" />
                            </a>
                        </b-input-group-append>
                    </b-input-group>
                    </div>
                    <div v-if="veeErrors.has('password')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('password')}}
                    </div>
                </div>
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Mail *<b-button class="btn btn-info" v-b-tooltip.hover title="You must enter a valid email address"><font-awesome-icon icon="info-circle" /></b-button></label>
                    <input
                        class="form-control inputStyle"
                        id="mail"
                        required
                        v-model="user.email"
                        v-validate="'required|email|max:50'"
                        type="email" 
                        data-vv-name="email"  
                        placeholder="Email"
                        name="mail"
                    />
                    <div v-if="veeErrors.has('email')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('email')}}
                    </div> 
                </div>
                <div class="col-sm-4">
                    <label class="mt-2" for="exampleFormControlSelect1" style="float: left !important; margin-left: 1px !important;">Role *<b-button class="btn btn-info" v-b-tooltip.hover title="You must choose a role"><font-awesome-icon icon="info-circle" /></b-button></label>
                    <select class="form-select" id="role" v-validate="'required'" data-vv-name="role" v-model="user.role">
                        <option :key="-1" :value="null">Select an option...</option>
                        <option v-for="item in roles" v-bind:key="item.id" v-bind:value="item">
                            {{ item.name.toUpperCase() }}
                        </option>
                    </select>
                    <div v-if="veeErrors.has('role')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('role')}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="row">
                        <label class="mt-2" for="exampleFormControlSelect1" style="text-align: left !important; margin-left: 1px !important;">Areas *<b-button class="btn btn-info" v-b-tooltip.hover title="You must select at least one area"><font-awesome-icon icon="info-circle" /></b-button></label>
                    </div>
                    <div class="row">
                        <multiselect id="area" v-validate="'required'" data-vv-name="area" style="width: 98% !important;"
                            :options="areasToShow"
                            :multiple="true"
                            :close-on-select="false" 
                            :preserve-search="true"
                            :taggable="true"
                            :preselect-first="false"
                            placeholder="Search or add an area"
                            v-model="selectedAreas"
                            label="label"
                            track-by="value"
                            @select="selectOption"
                            @remove="removeOption"></multiselect>
                    </div>
                        
                    <div v-if="veeErrors.has('area')" class="alert alert-danger py-0 mt-2 mb-0">
                        {{veeErrors.first('area')}}
                    </div>
                </div>
            </div>

            <div class="form-group row justify-content-center">
                <div class="col-lg-3 col-xl-2">
                    <input class="btn btn-primary form-submit" type="submit" value="Create">
                </div>
                <div class="col-lg-3 col-xl-2">
                    <button class="btn btn-secondary btn-cancel" type="button" @click="backToRepository()"><font-awesome-icon icon="ban" /> Cancel</button>
                </div>
            </div>
		</form>
	</div>
</template>

<script>
    import UserDataService from "@/services/UserDataService"
    import AuthDataService from "@/services/AuthDataService"
    import AreasDataService from "@/services/AreasDataService"
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'CreateUserComponent',
		data() {
            return {
                users: [],
                roles: [],
                roleSelected: [],
                areas: [],
                areasToShow: [],
                areasToAdd: [],
                selectedAreas: [],
                user: {
                    username: "",
                    name: "",
                    surname: "",
                    password: "",
                    email: "",
                    role: null,
                    areas:[]
                }, 
                languages: [],
                image: "",
                fileName: "",
                submitted: false,
                successfull: false,
                message: "",
                selectedTags: [],
                tags: [],
                repositories: [],
                multiselection: null,
                visibility: 'password',
                files: null
            }
		},
		components: {
            Multiselect
		},
        computed:{
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
		mounted() {
            AuthDataService.registerBoard().then(
                response => {
                    this.roles = response.data
                    this.roles = this.roles.filter(item=> item.name != 'superadmin');
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            )

            UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {
                    this.users = response.data
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
            
            AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {
                    let tmpAreas = response.data
                    for (let i=0; i<tmpAreas.length; i++) {
                        this.areas.push(tmpAreas[i])
                        this.areasToShow.push({label: tmpAreas[i].name.toUpperCase(), value: tmpAreas[i].name + '-' + tmpAreas[i].id})
                    }
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
		    );
	  	},
	  	methods: {
	  		backToRepository() {
	  			this.$router.replace({ name: "admin-configuration-users", params: {}});
	  		},
            changeVisibility(){
                if(this.visibility == 'password'){
                    this.visibility = 'text';
                } else this.visibility = 'password';
            },
            selectOption(option) {
				var object = {}

				var parsedOption = JSON.parse(JSON.stringify(option))

                console.log('parsedOption: ', parsedOption)

				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')
				object.id = parseInt(valueSplit[valueSplit.length - 1])
				this.areasToAdd.push(object)

                this.user.areas = this.areasToAdd
			},
			removeOption(option) {
				var parsedOption = JSON.parse(JSON.stringify(option))

				var valueSplit = parsedOption.value.split('-')
				const index = this.areasToAdd.map(object => object.id).indexOf(parseInt(valueSplit[valueSplit.length - 1]));
            
				this.areasToAdd.splice(index, 1)
                this.user.areas = this.areasToAdd
			},
	  		handleCreate() {
	  			this.submitted = true;
                this.successfull = true;

                if(!this.checkUsernameExists(this.user)) {
                    if(!this.checkEmailExists(this.user)) {
                        if (this.user.name != '' || this.user.surname != '' || this.user.username != '' || this.user.password != '' || this.user.email != '' || this.user.roles != '' || this.selectedAreas.length > 0) {
                            this.$validator.validate().then(isValid => {
                                if (isValid) {
                                    this.roleSelected.push(this.user.role);
                                    var data = {user: this.user, roles: this.roleSelected, id_company: this.$store.state.auth.user.id_company};

                                    AuthDataService.register(data)
                                        .then(async response => {
                                            let idsAreas = []

                                            this.areasToAdd.forEach((item) => {
                                                idsAreas.push(item.id)
                                            })

                                            console.log('idsAreas: ', idsAreas)

                                            await AreasDataService.addUserToAreas(response.data.id, idsAreas).then(
                                                response => {
                                                    if (response.status == 200) {
                                                        console.log('')
                                                    }
                                                },
                                                error => {
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())
                                                    
                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );

                                            let userSettings = {}
                                            if (this.user.role.name === "user") userSettings = {id_user: response.data.id, cv_analyzer_redirection: 'form'}
                                            else userSettings = {id_user: response.data.id}

                                            await UserDataService.createAccountSettings(userSettings).then(
                                                response => {
                                                    if (response.status == 200) {
                                                        this.$router.push({ name: "admin-configuration-users", params: {userRegistered: true} });
                                                    }
                                                },
                                                error => {
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())
                                                    
                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                    });
                                }
                            });
                        }
                    } else {
                        this.message = "Email already exists!"
                        this.successfull = false
                    }
                } else {
                    this.message = "Username already exists!"
                    this.successfull = false
                }
	  		},
            checkEmailExists (user) {
                let exists = false

                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].email === user.email)
                        exists = true
                }

                return exists
            },
            checkUsernameExists (user) {
                let exists = false

                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].username === user.username)
                        exists = true
                }

                return exists
            },
			close() {
                this.message = ""
				document.getElementById("message").style.display = "none"
			}
	  	}
	};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>