<template>
	<div class="table-container technologiesStyle my-2" v-if="!pdf">
		<h1>Test results</h1>
		<div class="row" style="margin-top: 1%;">
			<h5>Global results <small>(all attempts)</small></h5>
		</div>
		<table class="table mb-4">
			<thead>
				<th>Attempt number</th>
				<th>Level of difficulty</th>
				<th>Tags</th>
				<th>Score</th>
				<th>Question value</th>
				<th>Penalty</th>
				<th>Questions without answer</th>
				<th>Created at</th>
			</thead>
			<tbody>
				<tr v-for="attempt in testAttempts" v-bind:key="attempt.id">
					<td>{{ attempt.attempt_number }}</td>
					<td>{{ results.difficulty_level.toUpperCase() }}</td>
					<td>
						<div class="tags-container">
							<ul class="inline-tags-test">
								<li v-for="tag in results.testTags" v-bind:key="tag.id" tabindex="0"
									class="ti-tag ti-valid inline-tags-test">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span class="">{{ tag.name }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</td>
					<td><b>{{ attempt.qualification.toFixed(2) }}</b> ({{ attempt.total_correct_questions }} /
						{{ attempt.total_questions }})</td>
					<td>{{ attempt.question_value.toFixed(2) }}</td>
					<td>{{ attempt.incorrect_value }}</td>
					<td>{{ attempt.total_questions_without_value }}</td>
					<td>{{ attempt.created_at | formatEnglishDate }}</td>
				</tr>
			</tbody>
		</table>

		<h5>Results per question <small>(last attempt)</small></h5>
		<table class="table">
			<thead>
				<th>Question</th>
				<th>Areas</th>
				<th>Response</th>
			</thead>
			<tbody>
				<tr v-for="question in questionsResults" v-bind:key="question.id"
					v-bind:class="{ 'alert-success': question.is_correct, 'alert-danger': !question.is_correct }">
					<td>{{ question.title }}</td>
					<td>
						<div class="tags-container">
							<ul class="inline-tags">
								<li v-for="tag in question.tags" v-bind:key="tag.id" tabindex="0"
									class="ti-tag ti-valid inline-tags">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span class="">{{ tag.name }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</td>
					<td v-if="question.is_correct"><font-awesome-icon icon="check" /> <b>Correct</b>
					</td>
					<td v-else><font-awesome-icon icon="ban" /> <b>Wrong</b></td>
				</tr>
			</tbody>
		</table>

		<div class="row chart-container">
			<h5>Results per category <small>(last attempt)</small></h5>
			<div :class="chartsData.length == 1 ? 'col-sm-12' : 'col-sm-6'" v-for="data in chartsData"
				v-bind:key="data.id">
				<h6 class="title">Category "<b>{{ data.id }}</b>" Results Summary</h6>
				<pie-chart :data="data" :options="chartOptions"></pie-chart>
			</div>
		</div>
	</div>
	<div class="table-container technologiesStyle my-2" v-else>
		<div class="row" style="margin-top: 1%;">
			<h6>Global results <small>(all attempts)</small></h6>
		</div>

		<table class="table" style="font-size: 12px; padding-top: 0px;">
			<thead>
				<th>Attempt number</th>
				<th>Level of difficulty</th>
				<th>Tags</th>
				<th>Score</th>
				<th>Question value</th>
				<th>Penalty</th>
				<th>Questions without answer</th>
				<th>Created at</th>
			</thead>
			<tbody>
				<tr v-for="attempt in testAttempts" v-bind:key="attempt.id">
					<td>{{ attempt.attempt_number }}</td>
					<td>{{ results.difficulty_level.toUpperCase() }}</td>
					<td style="min-width: 200px">
						<div class="tags-container">
							<ul class="inline-tags-test" style="margin-bottom: 5px">
								<li style="font-size: 7px;margin-left: 5px !important; margin-top: 5px"
									v-for="tag in results.testTags" v-bind:key="tag.id" tabindex="0"
									class="ti-tag ti-valid inline-tags-test">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span class="">{{ tag.name }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</td>
					<td style="min-width: 100px"><b>{{ attempt.qualification.toFixed(2) }}</b>
						({{ attempt.total_correct_questions }} / {{ attempt.total_questions }})</td>
					<td>{{ attempt.question_value.toFixed(2) }}</td>
					<td>{{ attempt.incorrect_value }}</td>
					<td>{{ attempt.total_questions_without_value }}</td>
					<td>{{ attempt.created_at | formatEnglishDate }}</td>
				</tr>
			</tbody>
		</table>

		<h5>Results per question <small>(last attempt)</small></h5>
		<table class="table">
			<thead>
				<th>Question</th>
				<th>Areas</th>
				<th>Response</th>
			</thead>
			<tbody>
				<tr v-for="question in questionsResults" v-bind:key="question.id"
					v-bind:class="{ 'alert-success': question.is_correct, 'alert-danger': !question.is_correct }">
					<td>{{ question.title }}</td>
					<td>
						<div class="tags-container">
							<ul class="inline-tags">
								<li v-for="tag in question.tags" v-bind:key="tag.id" tabindex="0"
									class="ti-tag ti-valid inline-tags">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span class="">{{ tag.name }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</td>
					<td v-if="question.is_correct"><font-awesome-icon icon="check" /> <b>Correct</b>
					</td>
					<td v-else><font-awesome-icon icon="ban" /> <b>Wrong</b></td>
				</tr>
			</tbody>
		</table>

		<div class="row chart-container">
			<h6>Results per category <small>(last attempt)</small></h6>
			<div class="col-sm-6" v-for="data in chartsData" v-bind:key="data.id">
				<h6 style="font-size: 10px" class="title">Category "<b>{{ data.id }}</b>" Results Summary</h6>
				<img width="350"
					:src="'https://image-charts.com/chart.js/2.8.0?bkg=white&c=' + chartLinkOptions(data, chartOptions) + '&width=500'"
					alt="Static Chart" />
			</div>
		</div>
	</div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import Test from '@/models/test';
import PieChart from "@/components/Others/Charts/PieChart.js";

export default {
	name: "TestListComponent",
	data() {
		return {
			test: new Test('', '', '', '', '', '', '',
				'', '', '', '', '', '', '',
				'', ''),
			results: {},
			questionsResults: [],
			testAttempts: [],
			chartLabels: ["Correct Answers", "Wrong Answers"],
			chartBackgroundColor: ["#41B883", "#E46651"],
			chartOptions: {
				hoverBorderWidth: 20
			},
			chartsData: [],
		}
	},
	components: {
		PieChart
	},
	props: {
		testID: {
			type: Number,
			default: 0
		},
		userID: {
			type: Number,
			default: 0
		},
		pdf: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		console.log('this.$route.query.type: ', this.$route.query.type)
		console.log('this.userID: ', this.userID)
		let type = 'candidate'
		let id = null

		if (this.$route.query.type !== undefined) {
			type = this.$route.query.type
			if (this.userID !== null) id = this.userID
			else id = this.$route.query.id
		} else {
			if (this.userID !== null) {
				type = 'user'
				id = this.userID
			} else  {
				type = 'candidate'
				id = this.$route.query.id
			}
		}

		TestDataService.findTestResult(type, this.testID, id).then(
			response => {
				if (response.status == 200) {
					this.results = response.data;
					this.testAttempts = response.data.test_attempts;

					this.testAttempts.forEach(test_attempt => {
						test_attempt.question_value = 10 / test_attempt.total_questions
						test_attempt.incorrect_value = response.data.incorrect_value

						for (let i = 0; i <= (test_attempt.total_questions - test_attempt.total_correct_questions); i++) {
							if (test_attempt.qualification === ((test_attempt.total_correct_questions * test_attempt.question_value) - (i * test_attempt.question_value * test_attempt.incorrect_value))) {
								test_attempt.total_questions_without_value = test_attempt.total_questions - i - test_attempt.total_correct_questions
							}
							else {
								test_attempt.total_questions_without_value = "None"
							}
						}
					})

					this.questionsResults = response.data.questions_results;

					let resultsPerTag = []
					let noTag = {}
					noTag.tagName = "No Tag"
					noTag.results = [0, 0]

					this.questionsResults.forEach((questionResult) => {
						if (questionResult.tags.length === 0) {
							if (questionResult.is_correct) {
								noTag.results[0] += 1
							} else {
								noTag.results[1] += 1
							}
						}

						questionResult.tags.forEach((tag) => {
							var flag = false
							for (var k = 0; k < resultsPerTag.length; k++) {
								if (resultsPerTag[k].tagName == tag.name) {
									flag = true
									if (questionResult.is_correct) {
										resultsPerTag[k].results[0] += 1
									} else {
										resultsPerTag[k].results[1] += 1
									}
									break;
								}
							}

							if (!flag) {
								let resultsPerTagObject = {}

								resultsPerTagObject.tagName = tag.name
								resultsPerTagObject.results = [0, 0]

								if (questionResult.is_correct) {
									resultsPerTagObject.results[0] += 1
								} else {
									resultsPerTagObject.results[1] += 1
								}
								resultsPerTag.push(resultsPerTagObject)
							}
						})
					})

					if (noTag.results[0] !== 0 || noTag.results[1] !== 0)
						resultsPerTag.push(noTag)

					resultsPerTag.forEach((result) => {
						let chartDataObject = {}
						chartDataObject.id = result.tagName
						chartDataObject.labels = this.chartLabels;
						chartDataObject.datasets = [
							{
								label: 'Tag "' + result.tagName + '" Results Summary',
								backgroundColor: this.chartBackgroundColor,
								data: result.results
							}
						];
						this.chartsData.push(chartDataObject)
					});

					console.log('this.testAttempts mounted: ', this.testAttempts)
					console.log('this.questionsResults mounted: ', this.questionsResults)
				}
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	},
	methods: {
		userTestResult() {
			TestDataService.findTestResult(this.testID, this.userID).then(
				response => {
					if (response.status == 200) {
						this.results = response.data;
						this.testAttempts = response.data.test_attempts;

						this.testAttempts.forEach(test_attempt => {
							test_attempt.question_value = 10 / test_attempt.total_questions
							test_attempt.incorrect_value = response.data.incorrect_value

							for (let i = 0; i <= (test_attempt.total_questions - test_attempt.total_correct_questions); i++) {
								if (test_attempt.qualification === ((test_attempt.total_correct_questions * test_attempt.question_value) - (i * test_attempt.question_value * test_attempt.incorrect_value))) {
									test_attempt.total_questions_without_value = test_attempt.total_questions - i - test_attempt.total_correct_questions
								}
								else {
									test_attempt.total_questions_without_value = "None"
								}
							}
						})

						this.questionsResults = response.data.questions_results;

						let resultsPerTag = []
						let noTag = {}
						noTag.tagName = "No Tag"
						noTag.results = [0, 0]

						this.questionsResults.forEach((questionResult) => {
							if (questionResult.tags.length === 0) {
								if (questionResult.is_correct) {
									noTag.results[0] += 1
								} else {
									noTag.results[1] += 1
								}
							}
							questionResult.tags.forEach((tag) => {
								var flag = false
								for (var k = 0; k < resultsPerTag.length; k++) {
									if (resultsPerTag[k].tagName == tag.name) {
										flag = true
										if (questionResult.is_correct) {
											resultsPerTag[k].results[0] += 1
										} else {
											resultsPerTag[k].results[1] += 1
										}
										break;
									}
								}

								if (!flag) {
									let resultsPerTagObject = {}

									resultsPerTagObject.tagName = tag.name
									resultsPerTagObject.results = [0, 0]

									if (questionResult.is_correct) {
										resultsPerTagObject.results[0] += 1
									} else {
										resultsPerTagObject.results[1] += 1
									}
									resultsPerTag.push(resultsPerTagObject)
								}
							})
						})

						if (noTag.results[0] !== 0 || noTag.results[1] !== 0)
							resultsPerTag.push(noTag)

						resultsPerTag.forEach((result) => {
							let chartDataObject = {}
							chartDataObject.id = result.tagName
							chartDataObject.labels = this.chartLabels;
							chartDataObject.datasets = [
								{
									label: 'Tag "' + result.tagName + '" Results Summary',
									backgroundColor: this.chartBackgroundColor,
									data: result.results
								}
							];
							this.chartsData.push(chartDataObject)
						});


						console.log('this.testAttempts user: ', this.testAttempts)
						console.log('this.questionsResults user: ', this.questionsResults)
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		candidateTestResult() {
			TestDataService.findCandidateTestResult(this.testID, this.userID).then(
				response => {
					if (response.status == 200) {
						this.results = response.data;
						this.testAttempts = response.data.test_attempts;

						this.testAttempts.forEach(test_attempt => {
							test_attempt.question_value = 10 / test_attempt.total_questions
							test_attempt.incorrect_value = response.data.incorrect_value

							for (let i = 0; i <= (test_attempt.total_questions - test_attempt.total_correct_questions); i++) {
								if (test_attempt.qualification === ((test_attempt.total_correct_questions * test_attempt.question_value) - (i * test_attempt.question_value * test_attempt.incorrect_value))) {
									test_attempt.total_questions_without_value = test_attempt.total_questions - i - test_attempt.total_correct_questions
								}
								else {
									test_attempt.total_questions_without_value = "None"
								}
							}
						})

						this.questionsResults = response.data.questions_results;

						let resultsPerTag = []
						let noTag = {}
						noTag.tagName = "No Tag"
						noTag.results = [0, 0]

						this.questionsResults.forEach((questionResult) => {
							if (questionResult.tags.length === 0) {
								if (questionResult.is_correct) {
									noTag.results[0] += 1
								} else {
									noTag.results[1] += 1
								}
							}
							questionResult.tags.forEach((tag) => {
								var flag = false
								for (var k = 0; k < resultsPerTag.length; k++) {
									if (resultsPerTag[k].tagName == tag.name) {
										flag = true
										if (questionResult.is_correct) {
											resultsPerTag[k].results[0] += 1
										} else {
											resultsPerTag[k].results[1] += 1
										}
										break;
									}
								}

								if (!flag) {
									let resultsPerTagObject = {}

									resultsPerTagObject.tagName = tag.name
									resultsPerTagObject.results = [0, 0]

									if (questionResult.is_correct) {
										resultsPerTagObject.results[0] += 1
									} else {
										resultsPerTagObject.results[1] += 1
									}
									resultsPerTag.push(resultsPerTagObject)
								}
							})
						})

						if (noTag.results[0] !== 0 || noTag.results[1] !== 0)
							resultsPerTag.push(noTag)

						resultsPerTag.forEach((result) => {
							let chartDataObject = {}
							chartDataObject.id = result.tagName
							chartDataObject.labels = this.chartLabels;
							chartDataObject.datasets = [
								{
									label: 'Tag "' + result.tagName + '" Results Summary',
									backgroundColor: this.chartBackgroundColor,
									data: result.results
								}
							];
							this.chartsData.push(chartDataObject)
						});



						console.log('this.testAttempts candidate: ', this.testAttempts)
						console.log('this.questionsResults candidate: ', this.questionsResults)
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		hexToRgbA(hex) {
			var c;
			if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
				c = hex.substring(1).split('');
				if (c.length == 3) {
					c = [c[0], c[0], c[1], c[1], c[2], c[2]];
				}
				c = '0x' + c.join('');
				return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
			}
			throw new Error('Bad Hex');
		},
		chartLinkOptions(data, options) {
			for (let dataset of data.datasets) {
				var new_color_array = []
				for (let color of dataset.backgroundColor) {
					let rgba_color = this.hexToRgbA(color.toLowerCase())
					new_color_array.push(rgba_color)
				}

				dataset.backgroundColor = new_color_array
			}

			var doughnutOptions = {
				type: 'doughnut',
				data: data,
				options: options
			}

			return JSON.stringify(doughnutOptions)
		}
	}
};
</script>