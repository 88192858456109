<template>
  <div class="col-sm-12">
  	<div v-if="repositoryRegistered" class="alert alert-success col-md-6 align-item-center">
  		Repository registered succesfully!
  	</div>
  	<div v-if="repositoryUpdated" class="alert alert-success col-md-6 align-item-center">
  		Repository updated succesfully!
  	</div>
    <div v-if="questionRegistered" class="alert alert-success col-md-6 align-item-center">
      Question registered succesfully!
    </div>
    <div v-if="questionUpdated" class="alert alert-success col-md-6 align-item-center">
      Question updated succesfully!
    </div>
    <div class="links-container">
      <router-link to="/create-repository" class="btn btn-secondary float-right"><font-awesome-icon icon="list" /> Create repository</router-link>
      <router-link to="/create-question" class="btn btn-secondary float-right"><font-awesome-icon icon="question" /> Create question</router-link>
    </div>
  	<repositories :headers="headers"/>
  </div>
</template>

<script>
  import Repositories from "@/components/RepositoryComponents/List.vue";

	export default {
    name: 'RepositoriesView',
    components: {
      Repositories
    },
    computed: {
      headers() {
        return [
          { key: 'name', label: 'Name' }, { key: 'description', label: 'Description' },{ key: 'numberQuestions', label: 'Questions' }, { key: 'created_at', label: 'Created at' },{ key: 'updated_at', label: 'Updated at' },  { key: 'options', label: 'Options' }, 
        ];
      },
    },
    props: {
        repositoryRegistered: {
          type: Boolean,
          default: false
        },
        repositoryUpdated: {
          type: Boolean,
          default: false
        },
        questionRegistered: {
          type: Boolean,
          default: false
        },
        questionUpdated: {
          type: Boolean,
          default: false
        }
    }
	};
</script>