<template>
    <div class="col-sm-12">
        <div class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/evaluation' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to Evaluation</router-link>
                <router-link to="/softskills-create-process"
                    class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> CREATE
                    PROCESS</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
                <router-link :to="{ path: '/softskills-list' }" class="final-breadcumb"> Soft Skills</router-link>
            </div>
        </div>

        <div class="col-sm-8 col-centered">
            <ListS4Processes :s4Processes="s4Processes" />
        </div>
    </div>
</template>

<script>
import S4DataService from "@/services/S4DataService";
import ListS4Processes from "@/components/S4Components/List.vue";
import CreateS4Process from "@/components/S4Components/Create.vue";

export default {
    name: 'S4Home',
    data() {
        return {
            s4Processes: []
        }
    },
    components: {
        ListS4Processes,
        CreateS4Process
    },
    methods: {
        async getS4Processes() {
            await S4DataService.getProcessesByArea(9).then(
                response => {
                    this.s4Processes = response.data;
                },
                error => {
                    this.dataLoading = false;
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        handleCreate(info) {
            this.getS4Processes();
        },
        close() {
            document.getElementById("message").style.display = "none"
        },
        close2() {
            document.getElementById("message2").style.display = "none"
        }
    },
    mounted() {
        this.getS4Processes();
    },
    props: {
        s4ProcessRegistered: {
            type: Boolean,
            default: false
        },
        s4ProcessUpdated: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

</style>
