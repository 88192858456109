<template>
	<div class="">
		<div class="links-container">
			<router-link to="/repositories" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to repositories</router-link>
		</div>
		<update-repository/>
	</div>
</template>

<script>
  	import UpdateRepository from "@/components/RepositoryComponents/Update.vue";

	export default {
	  	name: 'UpdateRepositoryView',
	  	components: {
	      	UpdateRepository
	    }
	};
</script>