import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'curriculum_result/';

class AnalysisDataService {

  analysisBoard() {
    return http.get(API_URL_PATH + 'analysisBoard', { headers: authHeader() });
  }

  findCVsByUser(id_user) {
    return http.get(API_URL_PATH + 'findCVsByUser?id_user=' + id_user, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id=' + id, { headers: authHeader() });
  }

  findUsersCVsByArea(idArea) {
    return http.get(API_URL_PATH + 'findUsersCVsByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findLatestUserCVInArea(idArea) {
    return http.get(API_URL_PATH + 'findLatestUserCVInArea?id_area=' + idArea, { headers: authHeader() });
  }

  findCVsByArea(idArea) {
    return http.get(API_URL_PATH + 'findCVsByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findCandidatesCVsByArea(idArea) {
    return http.get(API_URL_PATH + 'findCandidatesCVsByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findCVsByCompany(idCompany) {
    return http.get(API_URL_PATH + 'findCVsByCompany?id_company=' + idCompany, { headers: authHeader() });
  }

  findLastByUser(id_user) {
    return http.get(API_URL_PATH + 'findLastByUser?id_user=' + id_user, { headers: authHeader() });
  }

  findByChatToken(token) {
    return http.get(API_URL_PATH + 'findByChatToken?token=' + token, { headers: authHeader() });
  }

  getFilterFields() {
    return http.get(API_URL_PATH + 'cvDataFiltering', { headers: authHeader() });
  }

  getFormQuestions() {
    return http.get(API_URL_PATH + 'questions', { headers: authHeader() });
  }

  updateToForm(userData) {
    return http.put(API_URL_PATH + 'update', userData, { headers: authHeader() });
  }

  updatePersonalData (personalData) {
    return http.put(API_URL_PATH + 'updateCVRecord', {"cv": personalData}, { headers: authHeader() });
  }

  filterCVs(filter) {
    const filterToApply = {
      id_area: filter.id_area,
      operator: filter.operator,
      filters: filter.filters
    }
    
    return http.post(API_URL_PATH + 'filterCVs', filterToApply, { headers: authHeader() });
  }

  findOrderedByPreviousProfileLevel(id_profile, id_profile_level, id_bussiness_process) {
    let uri = 'findOrderedByPreviousProfileLevel?id_profile=' + id_profile + '&id_profile_level=' + id_profile_level 
    if (id_bussiness_process) {
      uri += '&id_position_selection=' + id_bussiness_process
    } 
    return http.get(API_URL_PATH + uri, { headers: authHeader() });
  }
  
  findOrderedByProfileLevel(id_profile, id_profile_level, id_bussiness_process) {
    let uri = 'findOrderedByProfileLevel?id_profile=' + id_profile + '&id_profile_level=' + id_profile_level 
    if (id_bussiness_process) {
      uri += '&id_position_selection=' + id_bussiness_process
    } 
    return http.get(API_URL_PATH + uri, { headers: authHeader() });
  }

  findSortedCVsByRecycleProfileProximity(id_profile, id_profile_level, id_bussiness_process) {
    let uri = 'findSortedCVsByRecycleProfileProximity?id_profile=' + id_profile + '&id_profile_level=' + id_profile_level 
    if (id_bussiness_process) {
      uri += '&id_position_selection=' + id_bussiness_process
    } 
    return http.get(API_URL_PATH + uri, { headers: authHeader() });
  }
  
  updateCVsRecycleDataByCompany(id_company) {
    let jsonCompany = { id_company: id_company }
    return http.put(API_URL_PATH + 'updateCVsRecycleDataByCompany', jsonCompany, { headers: authHeader() });
  }

  findTopRecycleProfilesByCVProfiles(idCvAnalysis, numTop) {
    return http.get(API_URL_PATH + 'findTopRecycleProfilesByCVProfiles?id_cv=' + idCvAnalysis + "&number=" + numTop, { headers: authHeader() });
  }

  findUpskillingDataForCVProfiles (idCvAnalysis) {
    return http.get(API_URL_PATH + 'findUpskillingDataForCVProfiles?id_cv=' + idCvAnalysis, { headers: authHeader() });
  }

  updateCVRecord(cv) {
    let jsonCV = { cv: cv }
    return http.put(API_URL_PATH + 'updateCVRecord ', jsonCV, { headers: authHeader() });
  }
  
  findAdminSettingsForCV(id) {
    return http.get(API_URL_PATH + 'findAdminSettingsForCV?id=' + id, { headers: authHeader() });
  }

  recalculateProfiles(idArea) {
    const body = { id_area: idArea }
    return http.put(API_URL_PATH + 'recalculateProfiles', body, { headers: authHeader() });
  }

  ranking(idArea) {
    return http.get(API_URL_PATH + 'ranking?id_area=' + idArea, { headers: authHeader() });
  }

}

export default new AnalysisDataService();