<template>
    <div style="margin: 10% !important;">
        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Name</label>
                <br>
                <input class="form-input multiselection" type="text"  name="name-value" v-model="$processToEdit.name">
            </div>
        </div>
        
        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Type of process</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="type-value" value="Internal Evaluation" v-model="typeOfProcess"> Internal Evaluation</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="type-value" value="External Selection" v-model="typeOfProcess"> External Selection</label>
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Language</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="language-value" value="Spanish" v-model="language"> Spanish</label>
                <br>
                <label><input class="form-input multiselection" type="radio"  name="language-value" value="English" v-model="language"> English</label>
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>Country</label>
                <br>
                <input class="form-input multiselection" type="text"  name="country-value" v-model="$processToEdit.country">
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label>City</label>
                <br>
                <input class="form-input multiselection" type="text"  name="city-value" v-model="$processToEdit.city">
            </div>
        </div>

        <div class="col-sm-12" style="margin-bottom: 1% !important;">
            <div class="form-group">
                <label><input class="form-input multiselection" type="checkbox"  name="notifications-value" v-model="$processToEdit.send_notification"> Send notifications to candidates</label>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="form-group">
                <label><input class="form-input multiselection" type="checkbox"  name="active-value" v-model="active"> Process is active</label>
            </div>
        </div>
    </div>
</template>

<script>
	import S4DataService from "@/services/S4DataService";
    import Vue from "vue";

    export default { 
        name: 'PersonalDataStep',
        props: ['clickedNext', 'currentStep'],
        data () { 
            return {
                result: {},
                isSubmitted: false,
                typeOfProcess: false,
                language: '',
                active: false
            } 
        }, 
        mounted() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                S4DataService.getDataProcessById(parseInt(this.$route.query.id)).then(
                    response => {
                        if (response.status == 200) {
                            this.successfull = true;
                            Vue.prototype.$processToEdit = response.data
                            if (Vue.prototype.$processToEdit.status == 'active') this.active = true
                            else this.active = false

                            if (Vue.prototype.$processToEdit.type == 'evaluation') this.typeOfProcess = 'Internal Evaluation'
                            else this.typeOfProcess = 'External Selection'

                            if (Vue.prototype.$processToEdit.language.name == 'Español') this.language = 'Spanish'
                            else this.language = 'English'
                        }
                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        this.message = error.response.data.message

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
                
                this.$emit('can-continue', {value: true});
            }
        }
    }
</script>

<style scoped>
.title-class {
    margin-left: 5% !important;
}

#app > div > div > div.col-sm-8.col-centered > div > div > div.content > div > form > div:nth-child(4) > div > div > div:nth-child(1) > div > div {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 30px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
</style>