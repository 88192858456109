<template>
	<div class="form-container technologiesStyle mt-2">        
		<div class="col-sm-12">
			<div class="form-group">
				<label>Name *</label>
				<input class="form-input" type="text" v-validate="'required'" required v-model="language.name" data-vv-name="name">
				<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					{{veeErrors.first('name')}}
				</div>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>
          
		<div class="form-group row justify-content-center">
			<div class="col-sm-1">
				<button class="btn btn-primary form-submit" type="button" @click="handleCreate()">Create</button>
			</div>
		</div>
	</div>
</template>

<script>
	import LanguageDataService from "@/services/LanguageDataService";
	import Language from '@/models/language';

	export default {
	    name: "CreateLanguageComponent",
	    data() {
		  	return {
		  		title: "Create language",
		    	language: new Language('','',null),
		        message: "",
			  	successfull: false,
			  	submitted: false,
	    	}
		},
		mounted(){
			this.language.id_area = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]; 
		},
		methods: {
			handleCreate() {
	  			this.submitted = true
				this.successfull = true

	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					LanguageDataService.create(this.language).then(
	  						response => {
								this.message = response.data.message
	  							if (response.status == 200) {
	  								this.successfull = true;
									this.$router.push({ name: "languages", params: {languageRegistered: true} });
	  								this.$emit("click");
	  							}
	  						},
	  						error => {
								this.message = error.response.data.message
								this.successfull = false
	  							console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
	  						}
  						);
	  				}
	  			});
	  		},
			close() {
				this.message = ""
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>