<template>
	<div v-if="!pdf">
		<div class="row">
			<div class="col-sm-12">
				<h4 style="margin-top: 1%;">Soft Skills Evaluation Result</h4>
			</div>
		</div>
		<template>
			<div class="row">
				<div class="col-5 col-centered">
					<vue-ellipse-progress class="ellipse-progress-top-class" :progress="75" fontSize="3rem" :size="150" :color="color" :thickness="10" :fontColor="color" :legend="true" :legendValue="75">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Affinity</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="78" fontSize="2rem" :size="130" :color="color2" :thickness="7" :fontColor="color2" :legend="true" :legendValue="78">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Personal Skills</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="77" fontSize="2rem" :size="130" :color="color3" :thickness="7" :fontColor="color3" :legend="true" :legendValue="77">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Social Skills</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="73" fontSize="2rem" :size="130" :color="color4" :thickness="7" :fontColor="color4" :legend="true" :legendValue="73">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Formal Skills</p>
					</vue-ellipse-progress>
				</div>
			</div>
		</template>
	</div>
	<div v-else>
		<div class="row">
			<div class="col-sm-12">
				<h4 style="margin-top: 2%; text-align: left;border-bottom: 5px solid;padding-bottom: 10px; margin-bottom: 35px; font-size: 1.5rem">Soft Skills Evaluation Result</h4>
			</div>
		</div>
		<template>
			<div class="row">
				<div class="col-10 col-centered" style="float: none; margin: 0 auto;">
					<vue-ellipse-progress class="ellipse-progress-top-class" :progress="75" fontSize="3rem" :size="150" :color="color" :thickness="10" :fontColor="color" :legend="true" :legendValue="75">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Affinity</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="78" fontSize="2rem" :size="130" :color="color2" :thickness="7" :fontColor="color2" :legend="true" :legendValue="78">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Personal Skills</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="77" fontSize="2rem" :size="130" :color="color3" :thickness="7" :fontColor="color3" :legend="true" :legendValue="77">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Social Skills</p>
					</vue-ellipse-progress>
					<vue-ellipse-progress class="ellipse-progress-class" :progress="73" fontSize="2rem" :size="130" :color="color4" :thickness="7" :fontColor="color4" :legend="true" :legendValue="73">
						<span slot="legend-value">%</span>
						<p slot="legend-caption">Formal Skills</p>
					</vue-ellipse-progress>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
	    name: "SoftSkillsResults",
	    data() {
		  	return {
				color: "#4fc0e8",
				color2: "#b7e9f3",
				color3: "#c0c0c0",
				color4: "#13283b"
		  	}
		},
		props: {
			userID: Number,
			cvID: Number,
			userName: String,
			pdf: {
				type: Boolean,
				default: false
			}
		},
		components: {
		},
		computed: {
			isAdmin() {
		  		if (this.$store.state.auth.user.role.name == 'admin')
		  			return true
		  		else
		  			return false
			}
		},
		async mounted() {
		},
		methods: {
		}
	};
</script>

<style scoped>
.ellipse-progress-top-class {
	margin-right: 3%;
}

.ellipse-progress-class {
	margin-left: 2%;
}
</style>