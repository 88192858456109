<template>
  <div class="col-sm-12">
	<div class="row secondNavbar"> 
		<div class="col-5 links-container">
	   		<router-link :to="{path: '/videoconferences'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Recorded Video Interviews</router-link>
		</div>
		<div class="col-7 noMargin whiteColor">
			<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
			<router-link :to="{ path: '/videoconferences' }" class="breadcumb"> Recorded Video Interviews > </router-link>
			<router-link :to="{ path: '/config-videoconferences' }" class="final-breadcumb"> Configuration </router-link>
		</div>
	</div>

	<div class="col-sm-10 col-centered mt-4">
  		<configuration-videconference/>
	</div>
  </div>
</template>

<script>
	import ConfigurationVideconference from "@/components/VideoconferencesComponents/ConfigurationVideconference";

	export default {
	  	name: 'ConfigVideoconferencesView',
	  	components: {
	      ConfigurationVideconference,
	    },
		methods: {
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>