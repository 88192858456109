<template>
	<div class="col-sm-12">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link :to="{path: '/scoreboards'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to results</router-link>
			</div>
			<div class="whiteColor">Test Results List</div>
		</div>
		<tests-list :headers="headers"/>
	</div>
</template>

<script>
  	import TestsList from "@/components/ReportsComponents/Results/TestList.vue";

	export default {
	  	name: 'ExamResultsList',
	  	components: {
	      	TestsList
	    },
    	computed: {
			headers() {
				return [
					{ key: 'id', label: 'Id', sortable: true, tdClass: 'tdSmall' }, { key: 'title', label: 'Title', sortable: true, tdClass: 'tdBig' },{ key: 'description', label: 'Description', tdClass: 'tdBig' },{ key: 'done', label: 'Attempts', sortable: true, tdClass: 'tdMedium' }, { key: 'average_qualification', label: 'Average Score (Max. score: 100)', sortable: true, tdClass: 'tdBig' }, { key: 'difficulty_level', label: 'Level of difficulty', sortable: true, tdClass: 'tdMedium' }, { key: 'created_at', label: 'Created at', tdClass: 'tdMedium' }, { key: 'updated_at', label: 'Updated at', tdClass: 'tdMedium' }, { key: 'options', label: 'Options', tdClass: 'tdMedium' }
				];
			},
    	}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.nav-link-user-submenu {
	color:#13283B !important;
}
</style>
