<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<barr-chart 
			:ref="chartId"
			:key="graphLoaded"
			:chartId="chartId" 
			:xAxesCategory="xAxesCategory" 
			:yAxesValue="yAxesValue" 
			:xAxesTitle="xAxesTitle"
			:yAxesTitle="yAxesTitle"
			:forceVertical="true"
			:data="data"
			:chartTitle = "graphTitle"></barr-chart>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"
	
	export default {
		name: 'UsersGraphTotalCVByCompany',
		data() {
			return {
				chartId: "chartUsersGraphTotalCVByCompany",
				data: [],
				temp: [],
				graphTitle: "User CVs analysed by date",
				xAxesCategory: "date",
				yAxesValue: "cv_count",
				xAxesTitle: "Date",
				yAxesTitle: "Number of CVs Analysed",
				graphLoaded: false,
			}
		},
		components: {
			BarrChart
		},
		methods: {
			async fetchData() {
				await ReportsDataService.analyzedCVsByCompany(this.$store.state.auth.user.id_company).then(
					response => {
						this.data = response.data
						this.graphLoaded = this.graphLoaded ? false : true
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.fetchData();
		}
	};
</script>