import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'reports/';

class ReportsDataService {

    /* Superadmin reports */
    registeredUsersByRole() {
        return http.get(API_URL_PATH + 'registeredUsersByRole', { headers: authHeader() });
    }
    registeredUsersByCompanies() {
        return http.get(API_URL_PATH + 'registeredUsersByCompanies', { headers: authHeader() });
    }
    registeredTestsByCompanies() {
        return http.get(API_URL_PATH + 'registeredTestsByCompanies', { headers: authHeader() });
    }
    registeredQuestionsByCompanies() {
        return http.get(API_URL_PATH + 'registeredQuestionsByCompanies', { headers: authHeader() });
    }
    registeredProfilesByCompanies() {
        return http.get(API_URL_PATH + 'registeredProfilesByCompanies', { headers: authHeader() });
    }
    analyzedCVsByCompanies() {
        return http.get(API_URL_PATH + 'analyzedCVsByCompanies', { headers: authHeader() });
    }

    /* General reports */
    registeredUserRolesByCompany(idCompany) {
        return http.get(API_URL_PATH + 'registeredUserRolesByCompany?id_company='+idCompany, { headers: authHeader() });
    }
    registeredUserRolesByAreas(idArea) {
        return http.get(API_URL_PATH + 'registeredUserRolesByArea?id_area='+idArea, { headers: authHeader() });
    }
    registeredUsersByAreas(idCompany) {
        return http.get(API_URL_PATH + 'registeredUsersByAreas?id_company='+idCompany, { headers: authHeader() });
    }
    registeredTestsByAreas(idCompany) {
        return http.get(API_URL_PATH + 'registeredTestsByAreas?id_company='+idCompany, { headers: authHeader() });
    }
    registeredQuestionsByAreas(idCompany) {
        return http.get(API_URL_PATH + 'registeredQuestionsByAreas?id_company='+idCompany, { headers: authHeader() });
    }
    registeredProfilesByAreas(idCompany) {
        return http.get(API_URL_PATH + 'registeredProfilesByAreas?id_company='+idCompany, { headers: authHeader() });
    }
    analyzedCVsByAreas(idCompany) {
        return http.get(API_URL_PATH + 'analyzedCVsByAreas?id_company='+idCompany, { headers: authHeader() });
    }

    /*Users reports */
    registeredUsersByCompany(idCompany) {
        return http.get(API_URL_PATH + 'registeredUsersByCompany?id_company='+idCompany, { headers: authHeader() });
    }
    registeredUsersByArea(idArea) {
        return http.get(API_URL_PATH + 'registeredUsersByArea?id_area='+idArea, { headers: authHeader() });
    }
    totalAnalyzedCVsByUsersCompany(idCompany) {
        return http.get(API_URL_PATH + 'totalAnalyzedCVsByUsersCompany?id_company='+idCompany, { headers: authHeader() });
    }
    totalAnalyzedCVsByUsersArea(idArea) {
        return http.get(API_URL_PATH + 'totalAnalyzedCVsByUsersArea?id_area='+idArea, { headers: authHeader() });
    }
    analyzedCVsByCompanyAdmins(idCompany) {
        return http.get(API_URL_PATH + 'analyzedCVsByCompanyAdmins?id_company='+idCompany, { headers: authHeader() });
    }
    analyzedCVsByCompanyAdminsInArea(idArea) {
        return http.get(API_URL_PATH + 'analyzedCVsByCompanyAdminsInArea?id_area='+idArea, { headers: authHeader() });
    }
    analyzedCVsByCompany(idCompany) {
        return http.get(API_URL_PATH + 'analyzedCVsByCompany?id_company='+idCompany, { headers: authHeader() });
    }
    analyzedCVsByArea(idArea) {
        return http.get(API_URL_PATH + 'analyzedCVsByArea?id_area='+idArea, { headers: authHeader() });
    }
    profileAnalysesLevelByCompany(idCompany,profile) {
        return http.get(API_URL_PATH + 'profileAnalysesLevelByCompany?id_company='+idCompany+'&profile='+profile, { headers: authHeader() });
    }
    profileAnalysesLevelByArea(idArea,profile) {
        return http.get(API_URL_PATH + 'profileAnalysesLevelByArea?id_area='+idArea+'&profile='+profile, { headers: authHeader() });
    }

    /* Question reports*/
    questionsPerLanguageByArea(idArea) {
        return http.get(API_URL_PATH + 'questionsPerLanguageByArea?id_area='+idArea, { headers: authHeader() });
    }
    questionsPerDifficultyLevelByAreaTechnologies(idArea, tagIds) {
        let idsTmp = []
        Array.prototype.push.apply(idsTmp, tagIds);
        const firstElement = idsTmp.shift();
        let params = "technologies=" + firstElement;
        idsTmp.forEach((id) => {
          params += "&technologies=" + id
        })
        return http.get(API_URL_PATH + 'questionsPerDifficultyLevelByAreaTechnologies?id_area='+idArea+"&" + params , { headers: authHeader() });
    }
    questionsPerLanguageByAreaTechnologies(idArea, tagIds) {
        let idsTmp = []
        Array.prototype.push.apply(idsTmp, tagIds);
        const firstElement = idsTmp.shift();
        let params = "technologies=" + firstElement;
        idsTmp.forEach((id) => {
          params += "&technologies=" + id
        })
        return http.get(API_URL_PATH + 'questionsPerLanguageByAreaTechnologies?id_area='+idArea+"&" + params , { headers: authHeader() });
    }

    questionsValidatedAndNotValidatedByArea(idArea) {
        return http.get(API_URL_PATH + 'questionsValidatedAndNotValidatedByArea?id_area='+idArea, { headers: authHeader() });
    }
    questionsPerProfileByArea(idArea) {
        return http.get(API_URL_PATH + 'questionsPerTechnologyByArea?id_area='+idArea, { headers: authHeader() });
    }
    questionsPerDifficultyLevelByArea(idArea){
        return http.get(API_URL_PATH + 'questionsPerDifficultyLevelByArea?id_area='+idArea, { headers: authHeader() });
    }

    questionsPerProfileByCompany(idCompany) {
        return http.get(API_URL_PATH + 'questionsPerProfileByCompany?id_company='+idCompany, { headers: authHeader() });
    }
    
    /* Results reports - users */
    testResultsByUser(id_user) {
        return http.get(API_URL_PATH + 'testResultsByUser?id_user='+id_user, { headers: authHeader() });
    }
    testResultsByUserInArea(idUser, idArea) {
        return http.get(API_URL_PATH + 'testResultsByUserInArea?id_user='+idUser+'&id_area='+idArea, { headers: authHeader() });
    }
    userQuestionsActivityInArea(idUser, idArea) {
        return http.get(API_URL_PATH + 'userQuestionsActivityInArea?id_user='+idUser+'&id_area='+idArea, { headers: authHeader() });
    }
    userQuestionsResultsInArea(idUser, idArea) {
        return http.get(API_URL_PATH + 'userQuestionsResultsInArea?id_user='+idUser+'&id_area='+idArea, { headers: authHeader() });
    }
    userQuestionsActivityComparedResultsInArea(idUser, idArea) {
        return http.get(API_URL_PATH + 'userQuestionsActivityComparedResultsInArea?id_user='+idUser+'&id_area='+idArea, { headers: authHeader() });
    }
    testsResultsByCompanyUsers(idCompany) {
        return http.get(API_URL_PATH + 'testsResultsByCompanyUsers?id_company='+idCompany, { headers: authHeader() });
    }
    userQuestionsResults(id_user) {
        return http.get(API_URL_PATH + 'userQuestionsResults?id_user='+id_user, { headers: authHeader() });
    }
    userQuestionsActivityComparedResults(id_user) {
        return http.get(API_URL_PATH + 'userQuestionsActivityComparedResults?id_user='+id_user, { headers: authHeader() });
    }
    testsResultsByCompanyDifficultyLevel(idCompany) {
        return http.get(API_URL_PATH + 'testsResultsByCompanyDifficultyLevel?id_company='+idCompany, { headers: authHeader() });
    }

    /* Results reports - exams */
    testsResultsDistributionByCompany(idCompany) {
        return http.get(API_URL_PATH + 'testsResultsDistributionByCompany?id_company='+idCompany, { headers: authHeader() });
    }
    testQuestionsActivity(id_test) {
        return http.get(API_URL_PATH + 'testQuestionsActivity?id_test='+id_test, { headers: authHeader() });
    }
    testQuestionsResults(id_test) {
        return http.get(API_URL_PATH + 'testQuestionsResults?id_test='+id_test, { headers: authHeader() });
    }
    testGlobalResults(id_test) {
        return http.get(API_URL_PATH + 'testGlobalResults?id_test='+id_test, { headers: authHeader() });
    }

    /* Certification reports */
    registeredCertificationsByAreas(idCompany) {
        return http.get(API_URL_PATH + 'registeredCertificationsByAreas?id_company='+idCompany, { headers: authHeader() });
    }
    registeredCertificationsByAreaProfiles(idArea) {
        return http.get(API_URL_PATH + 'registeredCertificationsByAreaProfiles?id_area='+idArea, { headers: authHeader() });
    }
    registeredCertificationsByAreaProfileLevels(idArea) {
        return http.get(API_URL_PATH + 'registeredCertificationsByAreaProfileLevels?id_area='+idArea, { headers: authHeader() });
    }

}

export default new ReportsDataService();