import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'soft_skills/';

class SoftskillsDataService {
  createElement(softskill) {

    let newSoftskill = {}

    if (softskill.type === 'group') {
      newSoftskill = {
        id_company: softskill.id_company,
        name: softskill.name,
        type: softskill.type,
        description: softskill.description,
        order: parseInt(softskill.order)
      }
    } else if (softskill.type === 'competence') {
      if (softskill.id_group === undefined) {
        newSoftskill = {
          id_company: softskill.id_company,
          name: softskill.name,
          type: softskill.type,
          description: softskill.description,
          order: parseInt(softskill.order)
        }
      } else {
        newSoftskill = {
          id_company: softskill.id_company,
          name: softskill.name,
          type: softskill.type,
          description: softskill.description,
          order: parseInt(softskill.order),
          id_group: parseInt(softskill.id_group)
        }
      }
    } else if (softskill.type === 'level') {
      newSoftskill = {
        id_company: softskill.id_company,
        name: softskill.name,
        type: softskill.type,
        description: softskill.description,
        behavioral_indicators: softskill.behavioral_indicators,
        order: parseInt(softskill.order),
        id_competence: parseInt(softskill.id_competence)
      }
    }

    return http.post(API_URL_PATH + 'createElement', { soft_skill: newSoftskill }, { headers: authHeader() });
  }

  updateCompanyCompetencesDirectory(softskill) {
    const udpateSoftskill = {
      id: softskill.id,
      id_company: softskill.id_company,
      name: softskill.name,
      type: softskill.type,
      description: softskill.description,
      behavioral_indicators: softskill.behavioral_indicators,
      order: parseInt(softskill.order)
    }
    return http.put(API_URL_PATH + 'updateCompanyCompetencesDirectory', { soft_skill: udpateSoftskill }, { headers: authHeader() });
  }

  deleteElement(idSoftskill) {
    return http.delete(API_URL_PATH + 'deleteElement?id=' + idSoftskill, { headers: authHeader() });
  }

  deleteCompanyCompetencesDirectory(idCompany) {
    return http.delete(API_URL_PATH + 'deleteCompanyCompetencesDirectory?id_company=' + idCompany, { headers: authHeader() });
  }

  findCompanyCompetencesDirectory(idCompany) {
    return http.get(API_URL_PATH + 'findCompanyCompetencesDirectory?id_company=' + idCompany, { headers: authHeader() });
  }

  findUserEvaluationsSummary(id, id_area) {
    return http.get(API_URL_PATH + 'findUserEvaluationsSummary?id_user=' + id + '&id_area=' + id_area, { headers: authHeader() });
  }

  findUserLatestEvaluation(id) {
    return http.get(API_URL_PATH + 'findUserLatestEvaluation?id_user=' + id, { headers: authHeader() });
  }

  findCandidateLatestEvaluation(id) {
    return http.get(API_URL_PATH + 'findCandidateLatestEvaluation?id_cv=' + id, { headers: authHeader() });
  }

  createEvaluation(softskillsEvaluation) {
    return http.post(API_URL_PATH + 'createEvaluation', { soft_skills_evaluation_process: softskillsEvaluation }, { headers: authHeader() });
  }

  addEvaluatorsToEvaluation(evaluationEvaluators, idEvaluation, idBusinessProcess) {
    let arrayEvaluationEvaluators = []

    evaluationEvaluators.forEach(evaluationEvaluator => {
      let evaluator = {
        id_evaluator: parseInt(evaluationEvaluator.id_evaluator),
        role: evaluationEvaluator.role,
        id_evaluation: parseInt(evaluationEvaluator.id_evaluation),
        weight: parseFloat(evaluationEvaluator.weight),
        done: parseInt(evaluationEvaluator.done)
      }
      arrayEvaluationEvaluators.push(evaluator)
    })

    let body = { evaluators: arrayEvaluationEvaluators, id_evaluation: idEvaluation }
    if (idBusinessProcess !== undefined && idBusinessProcess !== null) body.id_position_selection = idBusinessProcess
    
    console.log('idBusinessProcess: ', idBusinessProcess)
    console.log('body: ', body)

    return http.post(API_URL_PATH + 'addEvaluatorsToEvaluation', body, { headers: authHeader() });
  }

  addUsersToEvaluation(evaluationUsers, idEvaluation, idBusinessProcess) {
    let body = { users: evaluationUsers, id_evaluation: idEvaluation }
    if (idBusinessProcess !== undefined && idBusinessProcess !== null) body.id_position_selection = idBusinessProcess

    console.log('idBusinessProcess: ', idBusinessProcess)
    console.log('body: ', body)

    return http.post(API_URL_PATH + 'addUsersToEvaluation ', body, { headers: authHeader() });
  }

  addWeightsToEvaluation(evaluationWeights, idEvaluation) {
    let arrayEvaluationWeights = []

    evaluationWeights.forEach(evaluationWeight => {
      let weightToAdd = {
        id_evaluation: evaluationWeight.id_evaluation,
        id_soft_skill: evaluationWeight.id_soft_skill,
        weight: evaluationWeight.weight,
        min_level: evaluationWeight.min_level
      }

      arrayEvaluationWeights.push(weightToAdd)
    })

    return http.post(API_URL_PATH + 'addWeightsToEvaluation', { soft_skills: arrayEvaluationWeights, id_evaluation: idEvaluation }, { headers: authHeader() });
  }

  deleteAllEvaluatorsFromEvaluation(idEvaluation) {
    return http.delete(API_URL_PATH + 'deleteAllEvaluatorsFromEvaluation?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  deleteAllWeightsFromEvaluation(idEvaluation) {
    return http.delete(API_URL_PATH + 'deleteAllWeightsFromEvaluation?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  deleteEvaluatorsFromEvaluation(idEvaluation, idsEvaluators) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, idsEvaluators);

    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    params += "&id_evaluation=" + idEvaluation

    return http.delete(API_URL_PATH + 'deleteEvaluatorsFromEvaluation?' + params, { headers: authHeader() });
  }

  findEvaluationsForEvaluator(idEvaluator) {
    return http.get(API_URL_PATH + 'findEvaluationsForEvaluator?id_evaluator=' + idEvaluator, { headers: authHeader() });
  }

  deleteWeightsFromEvaluation(idEvaluation, idsSoftskills) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, idsSoftskills);

    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    params += "&id_evaluation=" + idEvaluation

    return http.delete(API_URL_PATH + 'deleteWeightsFromEvaluation?' + params, { headers: authHeader() });
  }

  updateEvaluation(softskillsEvaluation) {
    const udpateSoftskillsEvaluation = {
      id: softskillsEvaluation.id,
      summary: softskillsEvaluation.summary
    }
    return http.put(API_URL_PATH + 'updateEvaluation', { soft_skills_evaluation: udpateSoftskillsEvaluation }, { headers: authHeader() });
  }

  deleteEvaluation(idSoftskillsEvaluation) {
    return http.delete(API_URL_PATH + 'deleteEvaluation?id_evaluation=' + idSoftskillsEvaluation, { headers: authHeader() });
  }

  bulkDeleteEvaluation(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);

    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDeleteEvaluation?' + params, { headers: authHeader() });
  }

  findEvaluationsByArea(idArea) {
    return http.get(API_URL_PATH + 'findEvaluationsByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findEvaluation(idEvaluation) {
    return http.get(API_URL_PATH + 'findEvaluation?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  findUserEvaluationWeights(idEvaluation, idUser, idEvaluator, idEvaluationProcess) {
    if (idEvaluationProcess.toString() !== '-1')
      return http.get(API_URL_PATH + 'findUserEvaluationWeights?id_evaluation=' + idEvaluation + '&id_user=' + idUser + '&id_evaluator=' + idEvaluator + '&id_position_selection=' + idEvaluationProcess, { headers: authHeader() });
    else
      return http.get(API_URL_PATH + 'findUserEvaluationWeights?id_evaluation=' + idEvaluation + '&id_user=' + idUser + '&id_evaluator=' + idEvaluator, { headers: authHeader() });
  }

  findEvaluatorEvaluationScoresForUser(idEvaluation, idUser, idEvaluator, idEvaluationProcess) {
    if (idEvaluationProcess.toString() !== '-1')
      return http.get(API_URL_PATH + 'findEvaluatorEvaluationScoresForUser?id_evaluation=' + idEvaluation + '&id_user=' + idUser + '&id_evaluator=' + idEvaluator + '&id_position_selection=' + idEvaluationProcess, { headers: authHeader() });
    else
      return http.get(API_URL_PATH + 'findEvaluatorEvaluationScoresForUser?id_evaluation=' + idEvaluation + '&id_user=' + idUser + '&id_evaluator=' + idEvaluator, { headers: authHeader() });
  }

  findEvaluationSummary(idEvaluation) {
    return http.get(API_URL_PATH + 'findEvaluationSummary?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  findUserEvaluationSummary(idEvaluation, idUser, idEvaluationProcess) {
    console.log('id PS: ', idEvaluationProcess)
    if (idEvaluationProcess.toString() !== '-1')
      return http.get(API_URL_PATH + 'findUserEvaluationSummary?id_evaluation=' + idEvaluation + '&id_user=' + idUser + '&id_position_selection=' + idEvaluationProcess, { headers: authHeader() });
    else
      return http.get(API_URL_PATH + 'findUserEvaluationSummary?id_evaluation=' + idEvaluation + '&id_user=' + idUser, { headers: authHeader() });
  }

  updateEvaluationEvaluator(evaluationEvaluator) {
    const udpateEvaluationEvaluator = {
    }
    return http.put(API_URL_PATH + 'updateEvaluationEvaluator', { soft_skills_evaluation_evaluator: udpateEvaluationEvaluator }, { headers: authHeader() });
  }

  updateEvaluationWeight(evaluationWeight) {
    const udpateEvaluationWeight = {
    }
    return http.put(API_URL_PATH + 'updateEvaluationWeight', { soft_skills_evaluation_weight: udpateEvaluationWeight }, { headers: authHeader() });
  }

  addScoresToEvaluation(evaluationScores, idEvaluationProcess) {
    if (idEvaluationProcess.toString() !== '-1')
      return http.post(API_URL_PATH + 'addScoresToEvaluation', { soft_skills_evaluation_scores: evaluationScores, id_position_selection: idEvaluationProcess }, { headers: authHeader() });
    else
      return http.post(API_URL_PATH + 'addScoresToEvaluation', { soft_skills_evaluation_scores: evaluationScores }, { headers: authHeader() });
  }

  updateEvaluationScore(evaluationScore) {
    const udpateEvaluationScore = {
    }
    return http.put(API_URL_PATH + 'updateEvaluationScore', { soft_skills_evaluation_scores: udpateEvaluationScore }, { headers: authHeader() });
  }

  deleteEvaluationScore(idEvaluation, idEvaluator, idCompetence) {
    return http.delete(API_URL_PATH + 'deleteEvaluation?id_evaluation=' + idEvaluation + '&id_evaluator=' + idEvaluator + '&id_competence=' + idCompetence, { headers: authHeader() });
  }

  deleteAllEvaluationScores(idEvaluation) {
    return http.delete(API_URL_PATH + 'deleteAllEvaluationsScores?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  findEvaluationScores(idEvaluation) {
    return http.get(API_URL_PATH + 'findEvaluationScores?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  findEvaluationEvaluators(idEvaluation) {
    return http.get(API_URL_PATH + 'findEvaluationEvaluators?id_evaluation=' + idEvaluation, { headers: authHeader() });
  }

  deactivateSoftSkills(idsSoftskills) {
    let arrayIds = []

    idsSoftskills.forEach(id => {
      arrayIds.push(id)
    })

    return http.put(API_URL_PATH + 'deactivateSoftSkills', { ids_soft_skill: arrayIds }, { headers: authHeader() });
  }

  ranking(idArea) {
    return http.get(API_URL_PATH + 'ranking?id_area=' + idArea, { headers: authHeader() });
  }
  
}

export default new SoftskillsDataService();