<template>
  	<div class="col-sm-12 pt-2">
        <div class="row col-centered">	
            <div class="col-sm-6 py-0">
                <user-settings />
            </div>
            <div class="col-sm-6 py-0">
                <div class="form-container technologiesStyle">
                    <div class="row mt-3">
                        <h5 class="mb-0" style="text-align: left !important;">File options <font-awesome-icon icon="info-circle" :title="$t('dashboards.admin.settings.cv-enhance')"/></h5>
                    </div>
                    <div class="row">
                        <form action class="form" @submit.prevent="handleUpdate">
                            <div class="row">
                                <div class="col-sm-6 py-0">
                                    <div class="row mt-3">
                                        <div class="col-sm-7 py-0">
                                            <p class="titulo_op right-align-text" style="padding-top: 5px !important;">Max. number of CVs to Upload</p>
                                        </div>
                                        <div class="col-sm-5 py-0 form-group">
                                            <input class="form-input" style="width: 80%; border-color: transparent; border-bottom: 1.5px solid #000;" max="100" min="1" type="number" v-model="maxNumberCVs">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 py-0">
                                    <div class="row mt-3">
                                        <div class="col-sm-6 py-0">
                                            <p class="titulo_op right-align-text" style="padding-top: 5px !important;">Maximum size of CV</p>
                                        </div>
                                        <div class="col-sm-6 py-0 form-group">
                                            <input class="form-input" style="width: 80%; border-color: transparent; border-bottom: 1.5px solid #000;" min="100" type="number" v-model="maxSizeCV"> Mb
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="message" v-if="message != ''" class="col-md-6 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'" style="margin-top: 10px !important; width: 100% !important;">
                                {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
                            </div>

                            <div class="col-lg-5 align-item-center">
                                <input class="btn btn-primary form-submit" block type="submit" value="Update">
                            </div>
                        </form>
                    </div>
	            </div>
            </div>
        </div>
    </div>
</template>  

<script>
    import CompanyDataService from '@/services/CompanyDataService';
    import UserSettings from '@/components/SettingsComponents/UserSettings.vue';

	export default {
		name: 'AdminSettingsComponent',
        components: {
            UserSettings
        },
		data() {
            return {
                anonymizer: false,
                maxNumberCVs: 0,
                maxSizeCV: 0, 
                message: "",
                successfull: false,
                submitted: false,
            }
	    },
		props: {
		},
	    computed: {
	    },
        mounted() {
            CompanyDataService.getAccountSettings(this.$store.state.auth.user.id_company).then(
                response => {
                    this.anonymizer = false;
                    this.maxNumberCVs = response.data.max_cv_uploads;
                    this.maxSizeCV = response.data.max_cv_uploads_filesize;
                },
                error => {
                    this.content =
                    (error.response && error.response.data) ||
                        error.message ||
                        error.toString();

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
	    },
	  	methods: {
            backToHome() {
                this.$router.push('/home');
            },
            handleUpdate(){
                this.submitted = true;
	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
                        let object = {
                            id_company: this.$store.state.auth.user.id_company,
                            max_cv_uploads: this.maxNumberCVs,
                            max_cv_uploads_filesize: this.maxSizeCV,
                            anonymizer_token_use: false,
                        }

                        CompanyDataService.updateAccountSettings(object).then(
                            response => {
                                if (response.status == 200) {
                                    this.successfull = true
                                    this.message = 'Changes were successfully saved'
                                } else {
                                    this.successfull = false
                                    this.message = 'An error ocurred while saving the data.'
                                }
                            },
                            error => {
                                this.content =
                                (error.response && error.response.data) ||
                                    error.message ||
                                    error.toString();

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
	  				} else {
	  					this.message = "Please, fill all the fields correctlly";
	  				}
	  			});
            },
			close() {
                this.successfull = false
				this.message = "";
			}
	    }
	};
</script>

<style scoped>
.componentMargins{
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin{
    padding-top: 0% !important;
}

.noMarginLow{
    padding-bottom: 0% !important;
}

.titleClass{
    margin-bottom: 3% !important;
    margin-top: 1% !important;
}

.resetColor{
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText{
    text-align: center !important;
}

.dropdown-content {
    z-index: 999;
    position: fixed;
    display: flex !important;
    margin: 0% !important; 
}

.checkbox-required {
    float: none !important;
}

.right-align-text {
    float: right !important;
}

.row-margin {
	margin-bottom: 1%;
}
</style>
