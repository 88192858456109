<template>
  <ul class="education-capsule">
    <li v-if="item.is_required" class="education-capsule-data-bold">{{ name }}</li>
    <li v-else class="education-capsule-data">{{ name }}</li>
    <li v-if="del" class="education-capsule-data" @click="emitDelete(name)">
        <font-awesome-icon icon="times" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "EducationCapsule",
  data() {
    return {};
  },
  components: {},
  props: {
    name: String,
    item: Object,
    del: Boolean
  },
  methods: {
    emitDelete(name) {
      this.$emit("delete-education", name);
    },
  }
};
</script>

<style scoped>
.education-capsule {
  list-style: none;
  padding: 7px;
  background: #13c7d479;
  border-radius: 12px;
  display: inline-flex;
  margin-right: 0.5em;
  margin-bottom: 0px ! important;
}

.education-capsule-data {
  padding: 0 10px;
  line-height: 1;
  color: rgb(0, 0, 0);
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.education-capsule-data-bold {
  padding: 0 10px;
  line-height: 1;
  color: rgb(0, 0, 0);
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  font-weight: bold;
}
</style>


