<template>
	<div class="table-container technologiesStyle">
		<div id="message" v-if="message != ''" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>
		<template v-if="selectionProcessList.length > 0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-xl-3 col-lg-4">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
						aria-controls="selectionProcessList"></b-pagination>
				</div>
				<div class="col-xl-6 col-lg-5">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
						:options="pageOptions"></b-form-select>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
						rows</button>
					<button class="btn btn-primary table-selection-button" type="button"
						@click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button"
						type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash"
							class="white-color" /> Delete {{ selectedItems.length }} rows</button>
				</div>
				<div class="col-xl-3 col-lg-3 search-input">
					<b-form-input id="filter-input" v-model="filter" type="search"
						:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
				</div>
			</div>

			<b-table ref="selectionProcessTable" id="selectionProcessList" :per-page="perPage"
				:current-page="currentPage" :fields="headers" :items="selectionProcessList" :filter="filter"
				select-mode="multi" responsive="sm" selectable @row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(name)="data">
					{{ data.item.name }}
				</template>
				<template #cell(status)="data">
					<p v-if="data.item.start_date == null || data.item.end_date == null" class="pending-status">PENDING
					</p>
					<p v-else-if="data.item.end_date < currentDate" class="archived-status">FINISHED</p>
					<p v-else-if="data.item.start_date > currentDate" class="pending-status">PENDING</p>
					<p v-else-if="data.item.start_date < currentDate && data.item.end_date > currentDate"
						class="active-status">STARTED</p>
				</template>
				<template #cell(start_date)="data">
					<p v-if="data.item.start_date != null">{{ data.item.start_date | formatEnglishDateWithoutHour }}</p>
					<p v-else>Not available</p>
				</template>
				<template #cell(end_date)="data">
					<p v-if="data.item.end_date != null">{{ data.item.end_date | formatEnglishDateWithoutHour }}</p>
					<p v-else>Not available</p>
				</template>
				<template #cell(profile)="data">
					<template v-if="data.item.profile != null">
						<img v-if="data.item.profile.logo.includes($awsBucketRoute)" v-b-tooltip.hover
							:title="data.item.profile.name" v-bind:src="data.item.profile.logo" height="30em"
							class="analysis-card-avatar--circle" />
						<img v-else v-b-tooltip.hover :title="data.item.profile.name"
							v-bind:src="$awsBucketRoute + data.item.profile.logo" height="30em"
							class="analysis-card-avatar--circle" />
					</template>
					<template v-else>
						<p>Profiles not available</p>
					</template>
				</template>
				<template #cell(num_candidates)="data">
					{{ data.item.users.length + data.item.candidates.length }}
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Evaluation Process Ranking"
						:to="{ path: '/business-process-ranking?id_business_process=' + data.item.id }"
						class="btn btn-secondary">
						<font-awesome-icon icon="trophy" />
					</router-link>
					<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show candidate list"
						class="btn btn-secondary" @click="data.toggleDetails">
						<font-awesome-icon icon="eye" />
					</button>
					<button v-else v-b-tooltip.hover title="Hide candidate list" class="btn btn-secondary"
						@click="data.toggleDetails">
						<font-awesome-icon icon="eye-slash" />
					</button>
				</template>
				<template #row-details="data">
					<selection-process-candidates-progress :id="data.item.id" :tests="data.item.tests" :evaluation="data.item.evaluation" />
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.
			</h3>
		</template>


		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete evaluation processes
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} evaluation
					processss?
				</p>
				<p v-else> Do you really want to delete this evaluation process? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteSelectionProcess()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import SelectionProcessService from '@/services/BusinessProcessService';
import SelectionProcessCandidatesProgress from '@/components/BusinessProcessComponents/SelectionProcessCandidatesProgress';

export default {
	name: 'List',
	data() {
		return {
			headers: [
				{ key: 'name', sortable: true, label: 'Name' },
				{ key: 'status', sortable: true, label: 'Process Status' },
				{ key: 'start_date', sortable: true, label: 'Start Date' },
				{ key: 'end_date', sortable: true, label: 'Finish Date' },
				{ key: 'num_candidates', label: 'Invitated' },
				{ key: 'profile', label: 'Profile' },
				{ key: 'options', label: 'Options' },
			],
			message: "",
			logos: {},
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			selectedItems: [],
			selectedSelectionProcess: null,
			selectedSelectionProcessIndex: null,
			currentDate: new Date().toISOString(),
		}
	},
	props: {
		selectionProcessList: Array,
	},
	components: {
		SelectionProcessCandidatesProgress
	},
	computed: {
		rows() {
			return this.selectionProcessList.length
		}
	},
	mounted() {
		if (this.$store.state.auth.user.table_records_per_page != undefined)
			this.perPage = this.$store.state.auth.user.table_records_per_page;
	},
	methods: {
		onRowSelected(items) {
			this.selectedItems = items;
		},
		selectAllRows() {
			this.$refs["selectionProcessTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["selectionProcessTable"].clearSelected();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		deleteSelectionProcess() {
			let selectedRows = this.selectedItems.map(element => element.id);

			SelectionProcessService.delete(selectedRows).then(
				response => {
					this.message = response.data.message

					if (response.status == 200) {
						this.successfull = true

						this.selectionProcessList = this.selectionProcessList.filter(function (value, index) {
							return selectedRows.indexOf(value.id) == -1;
						});
					} else {
						this.successfull = false
					}

					this.$refs['delete-modal'].hide();
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		close() {
			this.message = "";
		}
	}
};
</script>
