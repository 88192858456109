import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'repository/';

class RepositoryDataService {

  getRepositoryBoard() {
    return http.get(API_URL_PATH + 'repositoryBoard', { headers: authHeader() });
  }

  findAll(id_company) {
    return http.post(API_URL_PATH + 'findAll', { id_company: id_company });
  }

  findOne(id) {
    return http.post(API_URL_PATH + 'findOne', { id_repository: parseInt(id) });
  }

  delete (id) {
    return http.post(API_URL_PATH + 'delete', { id_repository: parseInt(id) });
  }

  create(repository) {
    const newRepository = {name: repository.name, description: repository.description, id_company: repository.id_company}
    return http.post(API_URL_PATH + 'create', { repository: newRepository });
  }

  update(repository) {
    const updatedRepository = {name: repository.name, description: repository.description}
    return http.post(API_URL_PATH + 'update', { id_repository:repository.id, repository: updatedRepository });
  }

}

export default new RepositoryDataService();