<template>
    <div class="form-container technologiesStyle">
        <div class="row mt-3">
            <h5 class="mb-0" style="text-align: left !important;">General options</h5>
        </div>
        <div class="row">
            <form action class="form" @submit.prevent="handleUpdate">
                <div class="row">
                    <div class="col-sm-10 py-0">
                        <div class="row mt-3">
                            <div class="col-sm-6 py-0">
                                <p class="titulo_op right-align-text" style="padding-top: 5px !important;">Items per
                                    page in tables</p>
                            </div>
                            <div class="col-sm-6 py-0">
                                <select class="form-select" v-validate="'required'"
                                    data-vv-name="items_per_page_selector" v-model="itemsPerPage"
                                    id="items_per_page_selector">
                                    <option 
                                        v-for="item in itemsPerPageOptions" 
                                        v-bind:key="item.value || item" 
                                        :value="item.value || item">
                                        {{ typeof item === 'object' ? item.text : item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="message" v-if="message != ''" class="col-md-6 align-item-center alert"
                    :class="successfull ? 'alert-success' : 'alert-danger'"
                    style="margin-top: 10px !important; width: 100% !important;">
                    {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                            icon="times" /> </b-button>
                </div>

                <div class="col-lg-5 align-item-center">
                    <input class="btn btn-primary form-submit" block type="submit" value="Update">
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import UserDataService from '@/services/UserDataService';

export default {
    name: 'UserSettingsComponent',
    data() {
        return {
            itemsPerPageOptions: this.$paginationOptions,
            itemsPerPage: '5',
            redirectOptions: ['Chatbot', 'Form'],
            redirect: 'Chatbot',
            message: "",
            successfull: false,
            submitted: false,
        }
    },
    props: {
    },
    mounted() {
        UserDataService.getAccountSettings(this.$store.state.auth.user.id).then(
            response => {
                this.itemsPerPage = response.data.table_records_per_page;
                if (this.itemsPerPage !== Number.MAX_SAFE_INTEGER) this.itemsPerPage = this.itemsPerPage.toString()
                if (response.data.cv_analyzer_redirection !== null) this.redirect = response.data.cv_analyzer_redirection.charAt(0).toUpperCase() + response.data.cv_analyzer_redirection.slice(1);
                console.log(this.itemsPerPage)
            },
            error => {
                this.content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        capitalizarPrimeraLetra(str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        backToHome() {
            this.$router.push('/home');
        },
        handleUpdate() {
            this.submitted = true;
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    if (this.itemsPerPage !== 'Show all') this.itemsPerPage = parseInt(this.itemsPerPage)
                    else this.itemsPerPage = Number.MAX_SAFE_INTEGER
                    let object = { id_user: this.$store.state.auth.user.id, table_records_per_page: this.itemsPerPage, cv_analyzer_redirection: this.redirect.toLowerCase() }
                    UserDataService.updateAccountSettings(object).then(
                        response => {
                            if (response.status == 200) {
                                let userTmp = this.$store.state.auth.user
                                userTmp.cv_analyzer_redirection = this.redirect.toLowerCase()
                                userTmp.table_records_per_page = this.itemsPerPage
                                localStorage.setItem("user", JSON.stringify(userTmp))
                                if (this.itemsPerPage !== Number.MAX_SAFE_INTEGER) this.itemsPerPage = this.itemsPerPage.toString()
                                
                                this.successfull = true
                                this.message = 'Changes were successfully saved'
                            } else {
                                this.successfull = false
                                this.message = 'An error ocurred while saving the data.'
                            }
                        },
                        error => {
                            this.content =
                                (error.response && error.response.data) ||
                                error.message ||
                                error.toString();


                            this.successfull = false
                            this.message = 'An error ocurred while saving the data.'

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    this.message = "Please, fill all the fields correctlly";
                }
            });
        },
        close() {
            this.successfull = false
            this.message = "";
        }
    }
};
</script>

<style scoped>
.componentMargins {
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin {
    padding-top: 0% !important;
}

.noMarginLow {
    padding-bottom: 0% !important;
}

.titleClass {
    margin-bottom: 3% !important;
    margin-top: 1% !important;
}

.resetColor {
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText {
    text-align: center !important;
}

.dropdown-content {
    z-index: 999;
    position: fixed;
    display: flex !important;
    margin: 0% !important;
}

.checkbox-required {
    float: none !important;
}

.right-align-text {
    float: right !important;
}

.row-margin {
    margin-bottom: 1%;
}
</style>
