<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      :allow-edit-tags="true"
      :autocomplete-items="items"
      class="tags-input"
      @tags-changed="update"
    >
      <div
        slot="autocomplete-item"
        slot-scope="props"
        class="my-item"
        @click="props.performAdd(props.item)"
      >
      {{ props.item.text }}
      </div>
    </vue-tags-input>
    <input type="hidden" id="selectedTags" :value="JSON.stringify(tags)">
  </div>
</template>

<script>
  import VueTagsInput from '@johmun/vue-tags-input';
  import $ from 'jquery'

  export default {
    components: {
      VueTagsInput,
    },
    data() {
      return {
        tag: '',
        tags: [],
        list: []
      };
    },
    mounted() {
      this.tags = this.initialized
      this.list = this.types

      $('.ti-icon-close').click(function() {
        if (this.search) {
            this.$emit("searchByTags", this.tags);
        }
      });
    },
    methods: {
      update(newTags) {
        this.tags = newTags;
        if (this.search) {
          this.$emit("searchByTags", this.tags);
        }
      }
    },
    computed: {
      items() {
        return this.list.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        });
      },
    },
    props: ['types', 'initialized', 'search']
  };
</script>