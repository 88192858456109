<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:chartTitle = "graphTitle"
				:data="data"></barr-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"
	
	export default {
		name: 'QuestionsGraphByCompany',
		data() {
			return {
				chartId: "chartQuestionsGraphByCompany",
				data: [],
				temp: [],
				graphTitle: "",
				xAxesCategory: "name",
				yAxesValue: "total_questions",
				xAxesTitle: "Area",
				yAxesTitle: "Number of questions",
				graphLoaded: false,
				graphLoading: false,
				isSuperAdmin: false
			}
		},
		components: {
			BarrChart
		},
		methods: {
			async fetchDataGeneral() {
				await ReportsDataService.registeredQuestionsByCompanies().then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.xAxesTitle = "Companies";
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			async fetchDataCompany() {
				await ReportsDataService.registeredQuestionsByAreas(this.$store.state.auth.user.id_company).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		mounted() {
			this.graphLoading = true;

			if (this.$store.state.auth.user.role.name == 'superadmin'){
				this.isSuperAdmin = true;
				this.graphTitle = "Questions per each of the companies";
				
				this.fetchDataGeneral();
			} else{
				this.isSuperAdmin = false;
				this.graphTitle = "Questions per each of the areas";
				
				this.fetchDataCompany();
			}
		}
	};
</script>