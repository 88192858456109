<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link :to="{path: '/modules'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to suite tools</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/search-profile' }" class="final-breadcumb"> Search Profile </router-link>
			</div>
		</div>
		<div class="col-sm-12 col-centered">	
			<search-filter-analysis/>
		</div>
	</div>
</template>

<script>
  	import SearchFilterAnalysis from "@/components/AnalysisComponents/SearchFilterAnalysis";

	export default {
	  	name: 'SearchFilterAnalysisView',
	  	components: {
	      	SearchFilterAnalysis
	    },
	    computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			showAdminBoard() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			}
	  	}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>