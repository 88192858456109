<template>
  <div class="col-sm-7 col-centered">
    <h5 class="changeFont"> DATA PROTECTION POLICY</h5>
    <hr class="thicker" style="color: #4FC0E8;height: 3px;">
    <div style="text-align: justify">
      <p>The provided data will be collected by Progradum SL, located at Paseo Dr. Torres Villarroel 9-11, Entreplanta - 37006 - Salamanca. You can contact us via email at info@progradum.com or by phone at 923630003.</p>
      <p>The provided data will only be used to contact you and offer our products and services, and will be preserved and safeguarded to prevent third-party access. </p>
      <p>The data will be used to provide you with the products or services requested by you, as well as others that may be of interest to you in the future.</p>
      <p>Under no circumstances will your data be transferred to third parties, except as required by law.</p>
      <p>You may exercise your rights of access, rectification, deletion, and portability, as well as limitation or opposition to processing by sending a communication to Progradum at the aforementioned address.</p>
      <p>If you are a customer of Progradum, your data will be used exclusively to fulfill billing obligations and other fiscal and legal obligations.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DataProtectionPolicyComponent',
  }
</script>
