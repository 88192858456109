<template>
    <div class="form-container my-3">
  		<h4>Edit {{profileNameTitle.toUpperCase()}} {{profileLevelNameTitle.toUpperCase()}} profile configuration</h4>

        <template v-for="(errorMessage, index) in errorsMessages">
            <div :id="index" class="alert alert-danger" v-bind:key="errorMessage">
                {{errorMessage}} <b-button @click="close(index)" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
            </div>
        </template>

        <div class="col-sm-12">
            <div class="row">
                <div class="col-6 left-col">
                    <div class="row education-div">
                        <div class="col-6 left-div">
                            <h5 class="title">Certifications&nbsp;&nbsp;&nbsp;
                                <font-awesome-icon id="openAddCert" style="display: inline;" class="add-icon" icon="plus" @click="showAddCertification()" v-b-tooltip.hover title="Open add certification" />
                                <font-awesome-icon id="closeAddCert" style="display: none;"  class="add-icon" icon="minus" @click="showAddCertification()" v-b-tooltip.hover title="Close add certification" />
                            </h5>
                        </div>
                    </div>
                    <advanced-search id="advanced_search" ref="advanced_search" class="advanced-search-class" style="display: none;" v-model="selectedCert" :options="availableCertificationsToShow" placeholder="Search a certification to add" @select="addRowCertifications()"/>
                    <b-table ref="certificationsTable"
                        id="certificationsTable"
                        :fields="headers"
                        :items="certificationsArray"
                        responsive="sm"
                        v-if="certificationsArray.length != 0"
                    >
                        <template #cell(item)="data">
                            <p>{{data.item.certification.name}}</p>
                        </template>
                        <template #cell(weight)="data" class="text-center">
                            <b-input type="number" step="0.01" v-model="data.item.weight"/>
                        </template>
                        <template #cell(is_required)="data">
                            <div class="form-check form-switch">
                                <input v-if="data.item.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required" checked>
                                <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required">
                            </div>
                        </template>
                        <template #cell(options)="data">
                            <button v-b-tooltip.hover title="Delete item" class="btn btn-secondary" @click="showDeleteModal(data.item.certification.id, data.index, 'certifications')" type="button"><font-awesome-icon icon="trash" /></button>
                        </template>
                    </b-table>
                    <p v-else>No certification items defined yet.</p>
                    
                    <div class="row education-div">
                        <div class="col-6 left-div">
                            <h5 class="title exp-time-div">Education&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus" @click="addRowEducation()" v-b-tooltip.hover title="Add item to the table" /></h5>
                        </div>
                    </div>
                    <b-table ref="educationTable"
                        id="educationTable"
                        :fields="headers"
                        :items="educationArray"
                        responsive="sm"
                        v-if="educationArray.length != 0"
                    >
                        <template #cell(item)="data">
                            <b-input v-model="data.item.level"></b-input>
                        </template>
                        <template #cell(weight)="data" class="text-center">
                            <b-input type="number" step="0.01" v-model="data.item.weight"/>
                        </template>
                        <template #cell(is_required)="data">
                            <div class="form-check form-switch">
                                <input v-if="data.item.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required" checked>
                                <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required">
                            </div>
                        </template>
                        <template #cell(options)="data">
                            <button v-b-tooltip.hover title="Delete item" class="btn btn-secondary" @click="showDeleteModal(data.item.level, data.index, 'education')" type="button"><font-awesome-icon icon="trash" /></button>
                        </template>
                    </b-table>
                    <p v-else>No education items defined yet.</p>
                    <h5 class="subtitle exp-time-div">Experience time</h5>
                    <div class="row range-row">
                        <div class="row mt-2">
                            <div class="col-xl-3 col-md-12">
                                <h6 class="skillH6 text-left mx-0">Min. years</h6>
                                <b-input type="number" id="minYearsExperienceTimeValue" class="lastYearsInputFirst" ref="minYearsExperienceTimeValue" step="1" v-model="minYearsExperienceTime.value"/>
                            </div>
                            <div class="col-xl-3 col-md-12" >
                                <p class="skillH6 text-left mx-0">Offset&nbsp;&nbsp;&nbsp;<font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.profile.offset')"/></p>
                                <b-input type="number" id="minYearsExperienceTimeOffset" class="lastYearsInput" ref="minYearsExperienceTimeOffset" min="0" step="0.5" v-model="minYearsExperienceTime.offset"/>
                            </div>
                            <div class="col-xl-3 col-md-12">
                                 <p class="skillH6 text-left mx-0">Weight&nbsp;&nbsp;&nbsp;<font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.profile.weight')"/></p>
                                <b-input type="number" id="minYearsExperienceTimeWeight" class="lastYearsInput" ref="minYearsExperienceTimeWeight" min="1" step="0.1" v-model="minYearsExperienceTime.weight"/>
                            </div>
                            <div class="col-xl-3 col-md-12">
                                <p class="skillH6 text-left mx-0">Required&nbsp;&nbsp;&nbsp;</p>
                                <div class="form-check-edit-profile form-switch">
                                    <input v-if="minYearsExperienceTime.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="minYearsExperienceTime.is_required" checked>
                                    <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="minYearsExperienceTime.is_required">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xl-3 col-md-12">
                                <h6 class="skillH6 text-left mx-0">Max. years</h6>
                                 <b-input type="number" id="maxYearsExperienceTimeValue" class="lastYearsInputFirst" ref="maxYearsExperienceTimeValue" step="1" v-model="maxYearsExperienceTime.value"/>
                            </div>
                            <div class="col-xl-3 col-md-12">
                                 <p class="skillH6 text-left mx-0">Offset&nbsp;&nbsp;&nbsp;<font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.profile.offset')"/></p>
                                <b-input type="number" id="maxYearsExperienceTimeOffset" class="lastYearsInput" ref="maxYearsExperienceTimeOffset" min="0" step="0.5" v-model="maxYearsExperienceTime.offset"/>
                            </div>
                            <div class="col-xl-3 col-md-12">
                                <p class="skillH6 text-left mx-0">Weight&nbsp;&nbsp;&nbsp;<font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.profile.weight')"/></p>
                                <b-input type="number" id="maxYearsExperienceTimeWeight" class="lastYearsInput" ref="maxYearsExperienceTimeWeight" min="1" step="0.1" v-model="maxYearsExperienceTime.weight"/>
                            </div> 
                            <div class="col-xl-3 col-md-12">
                                <p class="skillH6 text-left mx-0">Required&nbsp;&nbsp;&nbsp;</p>
                                <div class="form-check-edit-profile form-switch">
                                    <input v-if="maxYearsExperienceTime.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="maxYearsExperienceTime.is_required" checked>
                                    <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="maxYearsExperienceTime.is_required">
                                </div> 
                            </div>    
                        </div>
                    </div>
                </div>

                <div class="col-6 right-col">
                    <div class="row technology-skills-div">
                        <div class="col-6 left-div">
                            <h5 class="title">Technical Skills&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus" @click="addRowProfile()" v-b-tooltip.hover title="Add technological skill"/></h5>
                        </div>
                    </div>
                    <div class="row" v-for="(technologySkill, index) in technologySkillsArray"  :key="technologySkill.id">
                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                            <b-input v-model="technologySkill.skill"></b-input>
                            <button v-b-tooltip.hover title="Delete technological skill" class="btn btn-secondary" @click="showDeleteModal(technologySkill.skill, index, 'technologySkill')" type="button"><font-awesome-icon class="trash-icon" icon="trash" /></button>
                            <button class="btn btn-secondary" type="button" v-b-toggle="'toggleT-' + index.toString()" @click="changeShowTechState(index)">
                                <font-awesome-icon style="display: none;" v-bind:id="'iconTShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                <font-awesome-icon style="display: block;" v-bind:id="'iconTNotShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                            </button>
                        </div>
                        <b-collapse visible v-bind:id="'toggleT-' + index.toString()">
                            <div class="row items-to-skill">
                                <div class="row technology-skills-div">
                                    <div class="col-6 left-div">
                                        <h6 class="title">Items&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus" @click="addItemToTechnologySkill(technologySkill)" v-b-tooltip.hover title="Add item to the table"/></h6>
                                    </div>
                                </div>
                                <b-table ref="technologyTable"
                                    id="technologyTable"
                                    :fields="headers"
                                    :items="technologySkill.items"
                                    responsive="sm"
                                    v-if="technologySkill.items.length != 0"
                                >
                                    <template #cell(item)="data">
                                        <b-input v-model="data.item.item"></b-input>
                                    </template>
                                    <template #cell(weight)="data" class="text-center">
                                        <b-input type="number" step="0.01" v-model="data.item.weight"/>
                                    </template>
                                    <template #cell(is_required)="data">
                                        <div class="form-check form-switch">
                                            <input v-if="data.item.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required" checked>
                                            <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required">
                                        </div>
                                    </template>
                                    <template #cell(options)="data">
                                        <button v-b-tooltip.hover title="Delete item" class="btn btn-secondary" @click="showDeleteModal(technologySkill, data.index, 'technologyItem')" type="button"><font-awesome-icon icon="trash" /></button>
                                    </template>
                                </b-table>
                                <p v-else>No items defined yet.</p>
                            </div>
                        </b-collapse>
                        <hr v-if="index != (technologySkillsArray.length-1)">
                    </div>

                    <div class="row non-technology-skills-div">
                        <div class="col-6 left-div">
                            <h5 class="title">Non-Technical Skills&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus" @click="addRowNonProfile()" v-b-tooltip.hover title="Add Non-Technology Skill"/></h5>
                        </div>
                    </div>
                    <div class="row" v-for="(nonTechnologySkill, index) in nonTechnologySkillsArray"  :key="nonTechnologySkill.id">
                        <div class="d-flex align-items-center" style="margin-bottom: 15px;">
                            <b-input v-model="nonTechnologySkill.skill"></b-input>
                            <button v-b-tooltip.hover title="Delete non-technological skill" class="btn btn-secondary" @click="showDeleteModal(nonTechnologySkill.skill, index, 'nonTechnologySkill')" type="button"><font-awesome-icon class="trash-icon" icon="trash" /></button>
                            <button class="btn btn-secondary" type="button" v-b-toggle="'toggleNT-' + index.toString()" @click="changeShowNonTechState(index)">
                                <font-awesome-icon style="display: none;" v-bind:id="'iconNTShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                <font-awesome-icon style="display: block;" v-bind:id="'iconNTNotShow-' + index.toString()" class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up"/>
                            </button>
                        </div>
                        <b-collapse visible v-bind:id="'toggleNT-' + index.toString()">
                            <div class="row items-to-skill">
                                <div class="row technology-skills-div">
                                    <div class="col-6 left-div">
                                        <h6 class="title">Items&nbsp;&nbsp;&nbsp;<font-awesome-icon class="add-icon" icon="plus" @click="addItemToNonTechnologySkill(nonTechnologySkill)" v-b-tooltip.hover title="Add item to the table"/></h6>
                                    </div>
                                </div>
                                <b-table ref="technologyTable"
                                    id="technologyTable"
                                    :fields="headers"
                                    :items="nonTechnologySkill.items"
                                    responsive="sm"
                                    v-if="nonTechnologySkill.items.length != 0"
                                >
                                    <template #cell(item)="data">
                                        <b-input v-model="data.item.item"></b-input>
                                    </template>
                                    <template #cell(weight)="data" class="text-center">
                                        <b-input type="number" step="0.01" v-model="data.item.weight"/>
                                    </template>
                                    <template #cell(is_required)="data">
                                        <div class="form-check form-switch">
                                            <input v-if="data.item.is_required" class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required" checked>
                                            <input v-else class="form-check-input checkbox-required" type="checkbox" v-model="data.item.is_required">
                                        </div>
                                    </template>
                                    <template #cell(options)="data">
                                        <button v-b-tooltip.hover title="Delete item" class="btn btn-secondary" @click="showDeleteModal(nonTechnologySkill, data.index, 'nonTechnologyItem')" type="button"><font-awesome-icon icon="trash" /></button>
                                    </template>
                                </b-table>
                                <p v-else>No items defined yet.</p>
                            </div>
                        </b-collapse>
                        <hr v-if="index != (nonTechnologySkillsArray.length-1)">
                    </div>
                </div>
            </div>
        </div>
        <hr>
            
        <div class="form-group col-lg-4 align-item-center">
            <div class="row">
                <div class="col-sm-6">
                    <input class="btn btn-primary form-submit" type="button" @click="handleEdit()" value="Update" style="width: 130px;">
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-secondary btn-cancel" type="button" @click="showCancelModal()"><font-awesome-icon icon="ban" /> Cancel</button>
                </div>
            </div>
        </div>

        <b-modal id="cancel-modal" ref="cancel-modal" title="Cancel-modal" hide-footer>
			<template #modal-title>
				¿Do you really want to exit?
			</template>
			<div>
				<p>If you exit now, changes will be lost. </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('cancel-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="backToConfiguration()" value="Yes">
		</b-modal>

        <b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete item
			</template>
			<div>
				<p>Do you really want to delete the selected item? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteItem()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
    import MultiRangeSlider from "multi-range-slider-vue"
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    import AdvancedSearch from 'vue-advanced-search'
    import 'vue-advanced-search/dist/AdvancedSearch.css'
    import {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    } from 'vue-simple-accordion'
    import 'vue-simple-accordion/dist/vue-simple-accordion.css'
    import EducationCapsule from "@/components/Others/EducationCapsule.vue"
    import ProgrammingSkillsCapsule from "@/components/Others/ProgrammingSkillsCapsule.vue"
    import CertificationsCapsule from "@/components/Others/CertificationsCapsule.vue"
    import FrameworksCapsule from "@/components/Others/FrameworksCapsule.vue"
    import ToolsCapsule from "@/components/Others/ToolsCapsule.vue"
    import OthersCapsule from "@/components/Others/OthersCapsule.vue"
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
    import CertificationsDataService from "@/services/CertificationsDataService";

    export default {
        name: 'EditProfileConfigurationComponent',
        data() {
            return {
                profileNameTitle: "",
                profileLevelNameTitle: "",
                headers: [
                    { key: 'item', label: 'Item name' },
                    { key: 'weight', label: 'Weight' },
                    { key: 'is_required', label: 'Required' },  
                    { key: 'options', label: '' } 
                ],
                profile: null,
                profileLevelToEdit: null,
                selectedArray: null,
                selectedItem: null,
                selectedItemIndex: null,
                successfull: null,
                successfullHigherLevelProfile: null,
                successfullHigherLevelNonProfile: null,
                certificationsItem: null,
                certificationsArray: [],
                availableCertifications: [],
                availableCertificationsToShow: [],
                selectedCert: '',
                educationItem: null,
                educationArray: [],
                minYearsExperienceTime: {},
                maxYearsExperienceTime: {},
                technologySkillsArray: [],
                nonTechnologySkillsArray: [],
                errorsMessages: [],
                message: "",
                updatedProfile: null,
                totalWeight: null,
                index: -1,
                flag : false,
                closed: true,
            }
        },
        components: {
            MultiRangeSlider,
            VueSlider,
            EducationCapsule,
            FrameworksCapsule,
            ProgrammingSkillsCapsule,
            ToolsCapsule,
            OthersCapsule,
            CertificationsCapsule,
            VsaList,
            VsaItem,
            VsaHeading,
            VsaContent,
            VsaIcon,
            AdvancedSearch
        },
        props: {
        },
        beforeMount() {  
        },
        mounted() {
            this.totalWeight = 0

            ProfileConfigurationDataService.getProfile(this.$route.query.idProfile).then(
                response => {
                    if (response.status == 200) {
                        this.profile = response.data
                        this.profileNameTitle = this.profile.name
                        this.index = 0
                        this.flag = false
                        this.profile.profile_levels.forEach((profileLevel) => {
                            if(profileLevel.level == this.$route.query.idProfileLevel) {
                                this.flag = true
                                this.profileLevelNameTitle = profileLevel.name
                                this.profileLevelToEdit = profileLevel

                                this.certificationsArray = this.profileLevelToEdit.certifications

                                for(var t=0; t<this.certificationsArray.length; t++) {
                                    this.totalWeight = this.totalWeight + this.certificationsArray[t].weight
                                }

                                this.educationArray = this.profileLevelToEdit.profile_level_configuration.education

                                for(var t1=0; t1<this.educationArray.length; t1++) {
                                    this.totalWeight = this.totalWeight + this.educationArray[t1].weight
                                }

                                this.minYearsExperienceTime = profileLevel.profile_level_configuration.experience_time_min_years
                                this.totalWeight = this.totalWeight + this.minYearsExperienceTime.weight

                                if(profileLevel.profile_level_configuration.experience_time_max_years != null) {
                                    this.maxYearsExperienceTime = profileLevel.profile_level_configuration.experience_time_max_years
                                } else {
                                    this.maxYearsExperienceTime.is_required = 0
                                    this.maxYearsExperienceTime.offset = ""
                                    this.maxYearsExperienceTime.value = ""
                                    this.maxYearsExperienceTime.weight = ""
                                }

                                if(this.maxYearsExperienceTime.weight !== "" && this.maxYearsExperienceTime.weight !== undefined) this.totalWeight = this.totalWeight + this.maxYearsExperienceTime.weight

                                for(var k=0; k<this.profileLevelToEdit.profile_level_configuration.skills.length; k++) {
                                    this.profileLevelToEdit.profile_level_configuration.skills[k].skill = this.capitalizarPrimeraLetra(this.profileLevelToEdit.profile_level_configuration.skills[k].skill.replaceAll('_', ' '))
                                    if(this.profileLevelToEdit.profile_level_configuration.skills[k].is_tech == 1)
                                        this.technologySkillsArray.push(this.profileLevelToEdit.profile_level_configuration.skills[k])
                                    else
                                        this.nonTechnologySkillsArray.push(this.profileLevelToEdit.profile_level_configuration.skills[k])
                                }

                                for(var t2=0; t2<this.technologySkillsArray.length; t2++) {
                                    for(var t3=0; t3<this.technologySkillsArray[t2].items.length; t3++) {
                                        this.totalWeight = this.totalWeight + this.technologySkillsArray[t2].items[t3].weight
                                    }
                                }

                                for(var t4=0; t4<this.nonTechnologySkillsArray.length; t4++) {
                                    for(var t5=0; t5<this.nonTechnologySkillsArray[t4].items.length; t5++) {
                                        this.totalWeight = this.totalWeight + this.nonTechnologySkillsArray[t4].items[t5].weight
                                    }
                                }

                                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                                CertificationsDataService.findByArea(currentArea).then(
                                    response => {
                                        if (response.status == 200) {
                                            let allCertifications = response.data

                                            allCertifications = allCertifications.filter(item => item.validated === true)
                                            allCertifications.forEach((cert) => {
                                                var found2 = false
                                                        for(var z=0; z<this.certificationsArray.length; z++) {
                                                            if(this.certificationsArray[z].id == cert.id) {
                                                                found2 = true
                                                            }
                                                        }

                                                        if(!found2) {
                                                            this.availableCertifications.push(cert)
                                                            this.availableCertificationsToShow.push({
                                                                label: cert.name, 
                                                                value: cert.name + "-" + cert.id
                                                            })
                                                        }
                                            });
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())
                                        
                                        if (error.response.status === 300) {
                                            console.log('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                )
                            }
                            if(!this.flag)
                                this.index +=1
                            else return
                        });
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            )
        },
        methods: {
            changeShowTechState(index) {
                if(document.getElementById('iconTShow-' + index.toString()).style.display == "block") {
                    document.getElementById('iconTShow-' + index.toString()).style.display = "none"
                    document.getElementById('iconTNotShow-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconTShow-' + index.toString()).style.display = "block"
                    document.getElementById('iconTNotShow-' + index.toString()).style.display = "none"
                }
            },
            changeShowNonTechState(index) {
                if(document.getElementById('iconNTShow-' + index.toString()).style.display == "block") {
                    document.getElementById('iconNTShow-' + index.toString()).style.display = "none"
                    document.getElementById('iconNTNotShow-' + index.toString()).style.display = "block"
                } else {
                    document.getElementById('iconNTShow-' + index.toString()).style.display = "block"
                    document.getElementById('iconNTNotShow-' + index.toString()).style.display = "none"
                }
            },
            showAddCertification() {
                if(document.getElementById('openAddCert').style.display == "inline") {
                    document.getElementById('openAddCert').style.display = "none"
                    document.getElementById('closeAddCert').style.display = "inline"
                    document.getElementById('advanced_search').style.display = "block"
                } else {
                    document.getElementById('openAddCert').style.display = "inline"
                    document.getElementById('closeAddCert').style.display = "none"
                    document.getElementById('advanced_search').style.display = "none"
                }
            },
            
            showCancelModal() {
                this.backToConfiguration()
            },
            backToConfiguration() {
                this.$router.push({ path: 'config-profile'})
            },
            addRowCertifications() {
                var certificationSplit = this.$refs['advanced_search'].values.split('-')
                var certificationName = certificationSplit[0]
                var certificationId = certificationSplit[1]

                CertificationsDataService.findOne(certificationId).then(
                    response => {
                        var certification = response.data

                        var indexToDelete = -1
                        for(var i=0; i<this.availableCertifications.length; i++) {
                            if(this.availableCertifications[i].id == certification.id) {
                                indexToDelete = i
                            }
                        }

                        this.availableCertifications.splice(indexToDelete, 1);
                        this.availableCertificationsToShow.splice(indexToDelete, 1);

                        const newRow = {certification: certification, weight: "", is_required: 0}
                        this.certificationsArray.unshift(newRow);
                        this.$emit('input', this.certificationsArray);

                    },
                    error => {
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                )
            },
            addRowEducation() {
                const newRow = {level: "", weight: "", is_required: 0}
                this.educationArray.unshift(newRow);
                this.$emit('input', this.educationArray);
            },
            addRowProfile() {
                const newRow = {is_tech: 1, items: [], skill: ""}
                this.technologySkillsArray.unshift(newRow);
                this.$emit('input', this.technologySkillsArray);
            },
            addItemToTechnologySkill(technologySkill) {
                const newRow = {item: "", weight: "", is_required: 0}
                technologySkill.items.unshift(newRow)
                this.$emit('input', technologySkill.items);
            },
            addRowNonProfile() {
                const newRow = {is_tech: 0, items: [], skill: ""}
                this.nonTechnologySkillsArray.unshift(newRow);
                this.$emit('input', this.nonTechnologySkillsArray);
            },
            addItemToNonTechnologySkill(nonTechnologySkill) {
                const newRow = {item: "", weight: "", is_required: 0}
                nonTechnologySkill.items.unshift(newRow)
                this.$emit('input', nonTechnologySkill.items);
            },
            showDeleteModal(itemId, index, tipo) {
                this.selectedArray = tipo
                this.selectedItem = itemId
                this.selectedItemIndex = index
                this.$refs['delete-modal'].show();
            },
            handleEdit() {
                this.successfull = true
                this.closed = false
                this.successfullHigherLevelProfile = true
                this.successfullHigherLevelNonProfile = true
                this.updatedProfile = this.profile
                this.updatedProfileLevel = this.profileLevelToEdit
                this.document
                
                var errors = []

                var tempArrayEducation = []
                for (var i=0; i < this.educationArray.length; i++) {
                    if(this.educationArray[i].level.trim() === "" || this.educationArray[i].weight.toString().trim() === "") {
                        errors.push('Some education fields are empty')
                        this.successfull = false
                        break;
                    }

                    if(this.educationArray[i].weight <= 0) {
                        errors.push('Education weights must be greater than zero.')
                        this.successfull = false
                        break;
                    }

                    for (var j=0; j<tempArrayEducation.length; j++) {
                        if(this.educationArray[i].level === tempArrayEducation[j]) {
                            errors.push('There are repeated education items')
                            this.successfull = false
                            break;
                        }
                    }

                    tempArrayEducation.push(this.educationArray[i].level)

                    if(this.successfull) {
                        this.educationArray[i].weight = parseFloat(this.educationArray[i].weight)
                        if(this.educationArray[i].is_required === false) this.educationArray[i].is_required = 0
                        else if(this.educationArray[i].is_required === true) this.educationArray[i].is_required = 1
                    } else break;
                }

                var tempArrayCertifications = []
                for (var i=0; i < this.certificationsArray.length; i++) {
                    if(this.certificationsArray[i].weight === null) this.certificationsArray[i].weight = ""
                    if(this.certificationsArray[i].weight.toString().trim() === "") {
                        errors.push('Some certifications fields are empty')
                        this.successfull = false
                        break;
                    }

                    if(this.certificationsArray[i].weight <= 0) {
                        errors.push('Certifications weights must be greater than zero.')
                        this.successfull = false
                        break;
                    }

                    for (var j=0; j<tempArrayCertifications.length; j++) {
                        if(this.certificationsArray[i].certification.id === tempArrayCertifications[j]) {
                            errors.push('There are repeated certifications items')
                            this.successfull = false
                            break;
                        }
                    }

                    tempArrayCertifications.push(this.certificationsArray[i].certification.id)

                    if(this.successfull) {
                        this.certificationsArray[i].weight = parseFloat(this.certificationsArray[i].weight)
                        if(this.certificationsArray[i].is_required === false) this.certificationsArray[i].is_required = 0
                        else if(this.certificationsArray[i].is_required === true) this.certificationsArray[i].is_required = 1
                    } else break;
                }

                if(this.minYearsExperienceTime.value.toString().trim() === "" || this.minYearsExperienceTime.offset.toString().trim() === "" || this.minYearsExperienceTime.weight.toString().trim() === "") {
                    errors.push("Some min. years of experience time fields are empty")
                    this.successfull = false
                } else {
                    if(this.minYearsExperienceTime.value < 0) {
                        errors.push("Min. years of experience time value must be positive or zero.")
                        this.successfull = false
                    }

                    if(this.minYearsExperienceTime.offset < 0) {
                        errors.push("Min. years of experience time offset must be positive or zero.")
                        this.successfull = false
                    }

                    if(this.minYearsExperienceTime.weight <= 0) {
                        errors.push("Min. years of experience time weight must be positive.")
                        this.successfull = false
                    }
                }

                if(!this.isLevelTheMax()) {
                    if(this.maxYearsExperienceTime.value === undefined || this.maxYearsExperienceTime.offset === undefined || this.maxYearsExperienceTime.weight === undefined || this.maxYearsExperienceTime.value.toString().trim() === "" || this.maxYearsExperienceTime.offset.toString().trim() === "" || this.maxYearsExperienceTime.weight.toString().trim() === "") {
                        errors.push("Some fields of max. years of experience time are empty")
                        this.successfull = false
                    } else {
                        if(this.maxYearsExperienceTime.value < 0) {
                            errors.push("Value of max. years of experience time must be positive or zero.")
                            this.successfull = false
                        }

                        if(this.maxYearsExperienceTime.offset < 0) {
                            errors.push("Offset of max. years of experience time must be positive or zero.")
                            this.successfull = false
                        }

                        if(this.maxYearsExperienceTime.weight <= 0) {
                            errors.push("Weight of max. years of experience time must be positive.")
                            this.successfull = false
                        }
                
                        if(this.maxYearsExperienceTime.value >= 0 && (this.maxYearsExperienceTime.value <= this.minYearsExperienceTime.value)) {
                            errors.push("Max. years of experience time must be greater than min. years of experience time")
                            this.successfull = false
                        }
                        
                        if(this.successfull) {
                            this.minYearsExperienceTime.value = parseInt(this.minYearsExperienceTime.value)
                            this.minYearsExperienceTime.offset = parseFloat(this.minYearsExperienceTime.offset)
                            this.minYearsExperienceTime.weight = parseFloat(this.minYearsExperienceTime.weight)
                            if(this.minYearsExperienceTime.is_required === false) this.minYearsExperienceTime.is_required = 0
                            else if(this.minYearsExperienceTime.is_required === true) this.minYearsExperienceTime.is_required = 1

                            this.maxYearsExperienceTime.value = parseInt(this.maxYearsExperienceTime.value)
                            this.maxYearsExperienceTime.offset = parseFloat(this.maxYearsExperienceTime.offset)
                            this.maxYearsExperienceTime.weight = parseFloat(this.maxYearsExperienceTime.weight)
                            if(this.maxYearsExperienceTime.is_required === false) this.maxYearsExperienceTime.is_required = 0
                            else if(this.maxYearsExperienceTime.is_required === true) this.maxYearsExperienceTime.is_required = 1
                        }
                    }
                } else {
                    if(this.maxYearsExperienceTime.value !== undefined && this.maxYearsExperienceTime.offset !== undefined && this.maxYearsExperienceTime.weight !== undefined) {
                        if((this.maxYearsExperienceTime.value.toString().trim() !== "" || this.maxYearsExperienceTime.offset.toString().trim() !== "" || this.maxYearsExperienceTime.weight.toString().trim() !== "") && (this.maxYearsExperienceTime.value.toString().trim() === "" || this.maxYearsExperienceTime.offset.toString().trim() === "" || this.maxYearsExperienceTime.weight.toString().trim() === "")) {
                            errors.push("Some fields of max. years of experience time are empty")
                            this.successfull = false
                        } else if(this.maxYearsExperienceTime.value.toString().trim() !== "" && this.maxYearsExperienceTime.offset.toString().trim() !== "" && this.maxYearsExperienceTime.weight.toString().trim() !== "") {
                            if(this.maxYearsExperienceTime.value < 0) {
                                errors.push("Value of max. years of experience time must be positive or zero.")
                                this.successfull = false
                            }

                            if(this.maxYearsExperienceTime.offset < 0) {
                                errors.push("Offset of max. years of experience time must be positive or zero.")
                                this.successfull = false
                            }

                            if(this.maxYearsExperienceTime.weight <= 0) {
                                errors.push("Weight of max. years of experience time must be positive.")
                                this.successfull = false
                            }
                    
                            if(this.maxYearsExperienceTime.value >= 0 && (this.maxYearsExperienceTime.value <= this.minYearsExperienceTime.value)) {
                                errors.push("Max. years of experience time must be greater than min. years of experience time")
                                this.successfull = false
                            }
                            
                            if(this.successfull) {
                                this.minYearsExperienceTime.value = parseInt(this.minYearsExperienceTime.value)
                                this.minYearsExperienceTime.offset = parseFloat(this.minYearsExperienceTime.offset)
                                this.minYearsExperienceTime.weight = parseFloat(this.minYearsExperienceTime.weight)
                                if(this.minYearsExperienceTime.is_required === false) this.minYearsExperienceTime.is_required = 0
                                else if(this.minYearsExperienceTime.is_required === true) this.minYearsExperienceTime.is_required = 1

                                this.maxYearsExperienceTime.value = parseInt(this.maxYearsExperienceTime.value)
                                this.maxYearsExperienceTime.offset = parseFloat(this.maxYearsExperienceTime.offset)
                                this.maxYearsExperienceTime.weight = parseFloat(this.maxYearsExperienceTime.weight)
                                if(this.maxYearsExperienceTime.is_required === false) this.maxYearsExperienceTime.is_required = 0
                                else if(this.maxYearsExperienceTime.is_required === true) this.maxYearsExperienceTime.is_required = 1
                            }
                        } else if(this.maxYearsExperienceTime.value.toString().trim() === "" && this.maxYearsExperienceTime.offset.toString().trim() === "" && this.maxYearsExperienceTime.weight.toString().trim() === "") {
                            this.maxYearsExperienceTime.value = ""
                            this.maxYearsExperienceTime.offset = ""
                            this.maxYearsExperienceTime.weight = ""
                        }
                    } else {
                        this.maxYearsExperienceTime.value = ""
                        this.maxYearsExperienceTime.offset = ""
                        this.maxYearsExperienceTime.weight = ""
                    }
                }

                let allSkills = [...this.technologySkillsArray, ...this.nonTechnologySkillsArray]

                let skillSet = new Set()
                let exists = false

                for (let obj of allSkills) {
                    if (skillSet.has(obj.skill)) {
                        exists = true
                    }
                    skillSet.add(obj.skill)
                }

                if (exists) {
                    errors.push('Two groups of skills cannot have the same name.')
                    this.successfull = false
                }

                var temptechnologySkillsArray = []
                for(var x=0; x < this.technologySkillsArray.length; x++) {
                    if(this.technologySkillsArray[x].skill.trim() === "") {
                        errors.push('Some profile skills fields are empty')
                        this.successfull = false
                        this.successfullHigherLevelProfile = false
                        break;
                    }

                    for (var y=0; y<temptechnologySkillsArray.length; y++) {
                        if(this.technologySkillsArray[x].skill === temptechnologySkillsArray[y]) {
                            errors.push('There are repeated profile skills')
                            this.successfull = false
                            this.successfullHigherLevelProfile = false
                            break;
                        }
                    }

                    temptechnologySkillsArray.push(this.technologySkillsArray[x].skill)
                }

                if(this.successfullHigherLevelProfile) {
                    for(var k=0; k < this.technologySkillsArray.length; k++) {
                        var itemsForProfile = this.technologySkillsArray[k].items
                        var tempArray = []

                        for (var l=0; l < itemsForProfile.length; l++) {
                            if(itemsForProfile[l].item.trim() === "" || itemsForProfile[l].weight.toString().trim() === "") {
                                errors.push('Some ' + this.technologySkillsArray[k].skill + ' fields are empty')
                                this.successfull = false
                                break;
                            }

                            if(itemsForProfile[l].weight <= 0) {
                                errors.push(this.technologySkillsArray[k].skill + ' weights must be greater than zero.')
                                this.successfull = false
                                break;
                            }

                            for (var m=0; m<tempArray.length; m++) {
                                if(itemsForProfile[l].item === tempArray[m]) {
                                    errors.push('There are repeated ' + this.technologySkillsArray[k].skill + ' items')
                                    this.successfull = false
                                    break;
                                }
                            }

                            tempArray.push(itemsForProfile[l].item)

                            if(this.successfull) {
                                itemsForProfile[l].weight = parseFloat(itemsForProfile[l].weight)
                                if(itemsForProfile[l].is_required === false) itemsForProfile[l].is_required = 0
                                else if(itemsForProfile[l].is_required === true) itemsForProfile[l].is_required = 1
                            }
                        }
                    }  
                }

                var tempnonTechnologySkillsArray = []
                for(var x=0; x < this.nonTechnologySkillsArray.length; x++) {
                    if(this.nonTechnologySkillsArray[x].skill.trim() === "") {
                        errors.push('Some non profile skills fields are empty')
                        this.successfull = false
                        this.successfullHigherLevelNonProfile = false
                        break;
                    }

                    for (var y=0; y<tempnonTechnologySkillsArray.length; y++) {
                        if(this.nonTechnologySkillsArray[x].skill === tempnonTechnologySkillsArray[y]) {
                            errors.push('There are repeated non profile skills')
                            this.successfull = false
                            this.successfullHigherLevelNonProfile = false
                            break;
                        }
                    }

                    tempnonTechnologySkillsArray.push(this.nonTechnologySkillsArray[x].skill)
                }

                if(this.successfullHigherLevelNonProfile) {
                    for(var k=0; k < this.nonTechnologySkillsArray.length; k++) {
                        var itemsForNonProfile = this.nonTechnologySkillsArray[k].items
                        var tempArray = []

                        for (var l=0; l < itemsForNonProfile.length; l++) {
                            if(itemsForNonProfile[l].item.trim() === "" || itemsForNonProfile[l].weight.toString().trim() === "") {
                                errors.push('Some ' + this.nonTechnologySkillsArray[k].skill + ' fields are empty')
                                this.successfull = false
                                break;
                            }

                            if(itemsForNonProfile[l].weight <= 0) {
                                errors.push(this.nonTechnologySkillsArray[k].skill + ' weights must be greater than zero.')
                                this.successfull = false
                                break;
                            }

                            for (var m=0; m<tempArray.length; m++) {
                                if(itemsForNonProfile[l].item === tempArray[m]) {
                                    errors.push('There are repeated ' + this.nonTechnologySkillsArray[k].skill + ' items')
                                    this.successfull = false
                                    break;
                                }
                            }

                            tempArray.push(itemsForNonProfile[l].item)

                            if(this.successfull) {
                                itemsForNonProfile[l].weight = parseFloat(itemsForNonProfile[l].weight)
                                if(itemsForNonProfile[l].is_required === false) itemsForNonProfile[l].is_required = 0
                                else if(itemsForNonProfile[l].is_required === true) itemsForNonProfile[l].is_required = 1
                            }
                        }
                    }  
                }
                
                if (this.successfull) {
                    if (this.maxYearsExperienceTime.value == "")
                        this.updatedProfile.profile_levels[this.index].profile_level_configuration.experience_time_max_years = null
                    else
                        this.updatedProfile.profile_levels[this.index].profile_level_configuration.experience_time_max_years = this.maxYearsExperienceTime
                    
                    this.updatedProfile.profile_levels[this.index].profile_level_configuration.experience_time_min_years = this.minYearsExperienceTime
                    
                    this.updatedProfile.profile_levels[this.index].profile_level_configuration.skills = []
                    for (var i=0; i<this.technologySkillsArray.length; i++) {
                        this.technologySkillsArray[i].skill = this.technologySkillsArray[i].skill.toLowerCase().replaceAll(' ','_')
                        this.updatedProfile.profile_levels[this.index].profile_level_configuration.skills.push(this.technologySkillsArray[i])
                    }

                    for (var j=0; j<this.nonTechnologySkillsArray.length; j++) {
                        this.nonTechnologySkillsArray[j].skill = this.nonTechnologySkillsArray[j].skill.toLowerCase().replaceAll(' ','_')
                        this.updatedProfile.profile_levels[this.index].profile_level_configuration.skills.push(this.nonTechnologySkillsArray[j])
                    }

                    ProfileConfigurationDataService.updateProfile(this.updatedProfile).then(
                        response => {
                            if (response.status == 200) {
                                
                                const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                                this.successfull = true;
                                this.message = "Profile succesfully updated!";
                                this.$router.push({ name: 'profile-configuration', params: { updateMessage: this.message, updateFlag: this.successfull }})
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                                
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    this.errorsMessages = errors
                }
                
            },
            isLevelTheMax(){
                let response = true 
                this.profile.profile_levels.forEach((profileLevel) => {
                    if(profileLevel.level > this.profileLevelToEdit.level)
                        response = false
                })
                return response
            },
            deleteItem() {
                if(this.selectedArray === 'education') {
                    this.$refs['delete-modal'].hide();
                    this.educationArray.splice(this.selectedItemIndex, 1);
                } else if(this.selectedArray === 'technologySkill') {
                    this.$refs['delete-modal'].hide();
                    this.technologySkillsArray.splice(this.selectedItemIndex, 1);
                } else if(this.selectedArray === 'technologyItem') {
                    this.$refs['delete-modal'].hide();
                    for(var i=0; i<this.technologySkillsArray.length; i++) {
                        if(this.selectedItem.skill === this.technologySkillsArray[i].skill) {
                            this.technologySkillsArray[i].items.splice(this.selectedItemIndex, 1);
                        }
                    }
                } else if(this.selectedArray === 'nonTechnologySkill') {
                    this.$refs['delete-modal'].hide();
                    this.nonTechnologySkillsArray.splice(this.selectedItemIndex, 1);
                } else if(this.selectedArray === 'nonTechnologyItem') {
                    this.$refs['delete-modal'].hide();
                    for(var i=0; i<this.nonTechnologySkillsArray.length; i++) {
                        if(this.selectedItem.skill === this.nonTechnologySkillsArray[i].skill) {
                            this.nonTechnologySkillsArray[i].items.splice(this.selectedItemIndex, 1);
                        }
                    }
                } else if(this.selectedArray === 'certifications') {
                    this.certificationsArray.splice(this.selectedItemIndex, 1);
                    this.availableCertifications = [];
                    this.availableCertificationsToShow = [];

                    const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
                    CertificationsDataService.findByArea(currentArea).then(
                        response => {
                            if (response.status == 200) {
                                var allCertifications = response.data
                            
                                this.$refs['delete-modal'].hide();

                                for(var x=0; x<allCertifications.length; x++) {
                                    var found2 = false
                                            for(var z=0; z<this.certificationsArray.length; z++) {
                                                if(this.certificationsArray[z].id == allCertifications[x].id) {
                                                    found2 = true
                                                }
                                                if(found2) break
                                            }

                                            if(!found2) {
                                                this.availableCertifications.push(allCertifications[x])
                                                this.availableCertificationsToShow.push({label: allCertifications[x].name, value: allCertifications[x].name + "-" + allCertifications[x].id})
                                            }
                                }
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            
                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    )
                }
                
            },
            close(index) {
                document.getElementById(index).classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            },
            closeSingle() {
                this.closed = true;
            },
            capitalizarPrimeraLetra(str) {
                return str.charAt(0).toUpperCase() + str.slice(1)
            },
        },
        emits: ["delete-education", "delete-frameworks", "programming-skills-delete", "other-skills-weight", "tools-weight"],
    };
</script>

<style scoped>
.added-education, .added-frameworks, .added-programming-skills, .added-other-skills, .added-tools, .added-certifications{
    margin-top: 1em;
    display: inline-block;
}

.slider {
    background-color: white !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px white !important;
    width: 90% !important;
    padding: 20px 10px 30px 10px !important;
}

.range-row {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle-top {
    margin-top: 0px;
}

.subtitle {
    margin-top: 25px;
}

.weightSlider {
    width: 100% !important;
}

.skillDiv {
    width: 12% !important;
}

.skillH6 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.sliderDiv {
    width: 65% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.valueDiv {
    width: 5% !important;
}

.valueP {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.finalHardSkills {
    margin-bottom: 1rem !important;
}

.finalLastYears {
    margin-bottom: 1rem !important;
}

.lastYearsInputFirst {
    width: 100px;
    margin-right: 60px;
}

.lastYearsInput {
    width: 100px;
    margin-right: 30px;
}

.inputProfileConfig {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 100%;
}

.divItems {
    text-align: left;
    margin-left: 30px;
    margin-right: 100px;
}

.alert-edit {
    padding: 5px !important;
    width: 100% !important;
}

.noDisplay {
    display: none !important;
}

.education-div {
  margin-bottom: 5px !important;
}

.left-div {
  position: relative;
}

.add-button {
  float: right;
}

.checkbox-required {
    float: none !important;
}

.add-icon {
    cursor: pointer !important;
}

.vsa-list {
	--vsa-border-color: white !important;
	--vsa-highlight-color: #d0e3ff !important;
    --vsa-max-width: 100% !important;
}

.trash-icon {
    color: #4FC0E8 !important;
}

.add-item {
    width: auto; 
    float: right;
    margin-bottom: 5px;
}

.left-col {
    margin-right: 1% !important;
    width: 49% !important;
}

.right-col {
    margin-left: 1% !important;
    width: 48% !important;
}

.technology-skills-div {
    margin-bottom: 15px;
}

.non-technology-skills-div {
    margin-top: 20px;
}

.exp-time-div {
    margin-top: 35px;
}
.items-to-skill {
    margin-left: 15px;
    margin-bottom: 15px;
    width: 95%;
}

.advanced-search-class {
    width: 100%;
    margin-bottom: 15px;
}

.total-weight-h5 {
    text-align: center !important;
}
</style>
