<template>
  	<div  v-bind:class = "{'table-container':!home, 'col-sm-12': home} " class="technologiesStyle my-2">
        <div id="messageDelete" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
	  		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
	  	</div>

  		<div class="row" style="margin-top: 1%;">
			<div class="col-sm-2">
				<b-pagination
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
					aria-controls="companiesList">
				</b-pagination>
			</div>
			<div class="col-sm-7">
				<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
				<button class="btn btn-primary table-selection-button" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
				<button class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
				<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete {{selectedItems.length}} rows</button>
			</div>
			<div class="col-sm-3">
				<b-form-input id="filter-input"
					v-model="filter"
					type="search"
					:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
			</div>
		</div>
		
	  	<b-table ref="companiesTable"
			id="testsList"
			:per-page="perPage"
			:current-page="currentPage"
			:fields="headers" 
			:items="companies"
			:filter="filter"
			select-mode="multi"
			responsive="sm"
			selectable
			@row-selected="onRowSelected">
			<template #cell(selected)="{ rowSelected }">
				<template v-if="rowSelected">
					<span aria-hidden="true">&check;</span>
					<span class="sr-only">Selected</span>
				</template>
				<template v-else>
					<span aria-hidden="true">&nbsp;</span>
					<span class="sr-only">Not Selected</span>
				</template>
			</template>
			<template #cell(created_at)="data">
				<p>{{ data.item.created_at | formatEnglishDate }}</p>
			</template>
			<template #cell(updated_at)="data">
				<p>{{ data.item.updated_at | formatEnglishDate }}</p>
			</template>
			<template #cell(options)="data">
				<router-link v-b-tooltip.hover title="Add admin" :to="{path: '/create-admin-user?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="user-plus" /></router-link>
				<router-link v-b-tooltip.hover title="Edit" :to="{path: '/update-company?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
			</template>
		</b-table>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete company
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} companies? </p>
				<p v-else> Do you really want to delete this company? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteCompany()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
	import CompanyDataService from "@/services/CompanyDataService";

	export default {
	    name: "CompaniesListComponent",
	    data() {
	    	return {
		        companies: [],
		        successfull: false,
		        message: "",
		        perPage: 10,
		        pageOptions: this.$paginationOptions,
	        	currentPage: 1,
	        	filter: null,
	        	selectedItems: [],
				selectedCompany: null,
				selectedCompanyIndex: null
			}
		},
		mounted() {
			this.getCompanies();
			this.perPage = this.$store.state.auth.user.user_settings.table_records_per_page
	  	},
		computed: {
			rows() {
				return this.companies.length
			}
		},
		props: {
		    home: Boolean,
		    headers: Array
		},
		methods: {
			getCompanies() {
				CompanyDataService.findAll().then(
					response => {
						if (200 == response.status){
							this.companies = response.data
						} else {
							this.message = "Error while getting the list of companies"
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = "There was an error while getting the list of companies"
						if (error.response.status === 300) {
							console.error('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			onRowSelected(items) {
				this.selectedItems = items;
			},
			selectAllRows() {
				this.$refs["companiesTable"].selectAllRows();
			},
			clearSelected() {
				this.$refs["companiesTable"].clearSelected();
			},
			showDeleteModalMultiple() {
				this.$refs['delete-modal'].show();
			},
			deleteCompany(){
				let selectedRows = this.selectedItems.map(element => element.id); 

				CompanyDataService.delete(selectedRows).then(
					response => {
						if (200 == response.status) {
							if (selectedRows.length > 1) {
								this.message = "Companies has been successfully deleted!"
							} else {
								this.message = "Company has been successfully deleted!"
							}
						} else {
							this.successfull = false
						}

						this.$refs['delete-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = "There was an error while deleting the company"
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			close() {
				document.getElementById("messageDelete").style.display = "none"
			}
		}
	};
</script>
