<template>
	<div class="col-sm-12 technologiesStyle my-2">
        <div class="row" style="margin-top: 1%;" v-if="areas.length > 0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-sm-2">
					<b-pagination
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="areasList"
					></b-pagination>
				</div>
				<div class="col-sm-1">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
				</div>
				<div class="col-sm-2">
					<button class="btn btn-primary table-selection-button" style="float: right;" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
				</div>
				<div class="col-sm-2">
					<button ref="clear-selected" class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
				</div>
				<div class="col-sm-2">
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete {{selectedItems.length}} rows</button> 
				</div>
				<div class="col-sm-3">
					<b-form-input 
						id="filter-input"
						v-model="filter"
						type="search"
						:placeholder="$t('others.search-placeholder-msg')"
					></b-form-input>
				</div>
			</div>
		</div>
		<b-table ref="areasTable" v-if="areas.length > 0"
			id="areasList"
			:per-page="perPage"
			:current-page="currentPage"
			:fields="headers" 
			:items="areas" 
			:filter="filter"
			select-mode="multi"
			responsive="sm"
			selectable
			@row-selected="onRowSelected">
	    	<template #cell(selected)="{ rowSelected }">
	    		<template v-if="rowSelected">
	    			<span aria-hidden="true">&check;</span>
	    			<span class="sr-only">Selected</span>
	    		</template>
	    		<template v-else>
	    			<span aria-hidden="true">&nbsp;</span>
	    			<span class="sr-only">Not Selected</span>
	    		</template>
	    	</template>
			<template #cell(name)="data">
		        {{ data.item.name }}
	      	</template>
			<template #cell(description)="data">
		        {{ data.item.description }}
	      	</template>
			<template #cell(options)="data">
		        <router-link v-b-tooltip.hover title="Add users" :to="{path: '/admin-configuration-areas-add-users?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="user-plus" /></router-link>
		        <router-link v-b-tooltip.hover title="Remove users" :to="{path: '/admin-configuration-areas-remove-users?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="user-minus" /></router-link>
				<router-link v-b-tooltip.hover title="Edit" :to="{path: '/admin-configuration-areas-update-area?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
	      	</template>
		</b-table>
		<template v-else>
			<h3 class="no-results-msg" v-if="!dataLoading"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
			<h3 class="no-results-msg" v-else><b-spinner class="align-middle analysis-spinner"></b-spinner>&nbsp;&nbsp;Loading data...</h3>
		</template>
		
		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete areas
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} areas? </p>
				<p v-else> Do you really want to delete this area? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteArea()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
    import AreasDataService from '@/services/AreasDataService';

	export default {
		name: 'AccessToAreasComponent',
		data() {
			return {
				dataLoading: false,
				areas: [],
				successfull: false,
				perPage: 10,
				currentPage: 1,
				filter: null,
				pageOptions: this.$paginationOptions,
				selectedItems: [],
				selectedArea: null,
				selectedAreaIndex: null,
				headers: [
					{ key: 'name', label: 'Area', sortable: true, tdClass: 'tdMedium' },
					{ key: 'description', label: 'Description', tdClass: 'tdBig' },
					{ key: 'options', label: 'Options', tdClass: 'tdSmall' }
				]
			}
	    },
		props: {
		},
	    computed: {
            rows() {
                return this.areas.length
            }
	    },
        mounted() {
			this.dataLoading = true
            AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
                response => {					
                    this.areas = response.data
					this.dataLoading = false
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
					this.dataLoading = false
                }
		    );
        },
	  	methods: {
	  		onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["areasTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["areasTable"].clearSelected();
	      	},
	      	showDeleteModalMultiple() {
	      		this.$refs['delete-modal'].show();
	      	},
			deleteArea(){
				var selectedRows = this.selectedItems.map(element => element.id); 

				AreasDataService.delete(selectedRows).then(
					response => {
						if (response.status == 200) {
							this.successfull = true
							
							this.areas = this.areas.filter(function(value, index) {
							    return selectedRows.indexOf(value.id) == -1;
							});

							this.$emit('deleteArea');
						} else {
							this.successfull = false
						}
						
						this.$refs['delete-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
					}
				);
	      	}
	    }
	};
</script>