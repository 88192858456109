<template>
	<div v-if="!pdf">
		<div class="row">
			<div class="col-sm-12">
				<h4 style="margin-top: 1%;">BEI/360 Evaluation Result</h4>
			</div>
		</div>
		<template v-if="evaluations.length > 0 && isUser">
			<b-table ref="userTestsTable" id="userTests" :fields="headers" :items="evaluations" :filter="filter"
				responsive="sm">
				<template #cell(ps_associated)="data">
					<p v-if="data.item.ps_associated != null">
						{{ data.item.ps_associated }}
					</p>
					<p v-else>-</p>
				</template>
				<template #cell(is_finished)="data">
					<p v-if="data.item.done != null && data.item.done">
						<font-awesome-icon class="completed-icon-yes" icon="check" />
					</p>
					<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
				</template>
				<template #cell(options)="data">
					<b-button v-b-tooltip.hover title="Show evaluation details" v-if="parseInt(data.item.realizations) > 0"
						@click="showEvaluationDetails(data.item)"><font-awesome-icon icon="eye" /> </b-button>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No
				evaluations found.</h3>
		</template>
	</div>
	<div v-else>
		<div class="row">
			<div class="col-sm-12">
				<h4
					style="margin-top: 2%; text-align: left;border-bottom: 5px solid;padding-bottom: 10px; margin-bottom: 35px; font-size: 1.5rem">
					BEI/360 Evaluation Result</h4>
			</div>
		</div>
		<template v-if="evaluations.length > 0">
			<b-table ref="userTestsTable" id="userTests" :fields="headers" :items="evaluations" :filter="filter"
				responsive="sm">
				<template #cell(is_finished)="data">
					<p v-if="data.item.done != null && data.item.done"><font-awesome-icon class="completed-icon-yes"
							icon="check" /></p>
					<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No
				evaluations found.</h3>
		</template>
	</div>
</template>

<script>
import SoftskillsDataService from "@/services/SoftskillsDataService";
import UserDataService from "@/services/UserDataService";
import RadarChart from "@/components/Others/Charts/RadarChart.js";
import SoftskillsEvaluationDownloader from "@/components/Others/SoftskillsEvaluationDownloader";

export default {
	name: "SoftSkillsResults",
	data() {
		return {
			users: [],
			evaluations: [],
			globalEvaluation: {},
			evaluators: {},
			isUser: null,
			allEvaluators: [],
			evaluationFinished: false,
			evaluationMinLevel: 'NA',
			graphLoaded: false,
			radarChartOptions: {
				elements: {
					line: {
						borderWidth: 3
					}
				},
				responsive: true,
				mantainAspectRatio: false,
				scale: {
					ticks: {
						beginAtZero: true,
						max: 100,
						min: 0
					}
				}
			},
			radarChartData: {
				labels: [],
				datasets: [],
				legend: {
					display: true,
					position: "right"
				}
			},
			dataLoaded: false
		}
	},
	props: {
		userID: Number,
		cvID: Number,
		userName: String,
		pdf: {
			type: Boolean,
			default: false
		}
	},
	components: {
		RadarChart,
		SoftskillsEvaluationDownloader
	},
	computed: {
		isAdmin() {
			if (this.$store.state.auth.user.role.name == 'admin')
				return true
			else
				return false
		},
		rows() {
			return this.evaluations.length
		},
		headers() {
			return [
				{ key: 'name', label: "Name" },
				{ key: 'ps_associated', label: "Associated Evaluation Process" },
				{ key: 'average_result', label: "Current rating" },
				{ key: 'realizations', label: 'Evaluations performed' },
				{ key: 'is_finished', label: 'Finished' },
				{ key: 'options', label: 'Options' }
			]
		},
		headers2() {
			return [
				{ key: 'evaluator', label: "Evaluator" },
				{ key: 'result', label: "Result" },
				{ key: 'options', label: 'Options' }
			]
		}
	},
	async mounted() {
		if (this.userID !== null && this.cvID !== null) {
			this.getUsersInfoEvaluation();
		}
	},
	methods: {
		async getUsersInfoEvaluation() {
			await UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					if (response.status == 200) {
						this.users = response.data

						for (let i = 0; i < this.users.length; i++) {
							if (this.userID === this.users[i].id) {
								if (this.users[i].role.id === 2) this.isUser = true
								else this.isUser = false
							}

							if (this.isUser !== null) break
						}

						if (this.isUser) {
							this.findUserEvaluationsSummary();
						} else {
							this.findCandidateLatestEvaluation();
						}
					}
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		async findUserEvaluationsSummary() {
			await SoftskillsDataService.findUserEvaluationsSummary(this.userID, this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					if (response.status == 200) {
						response.data.bei_evaluations.forEach(evaluation => {
							console.log('evaluation: ', evaluation)

							let tmpEvaluation = {
								id: evaluation.id,
								ps_id: -1,
								name: evaluation.name,
								average_result: null,
								realizations: null,
								is_finished: null,
								ps_associated: null
							}

							if (evaluation.users_evaluations[0].average_result !== null && evaluation.users_evaluations[0].average_result !== undefined) tmpEvaluation.average_result = evaluation.users_evaluations[0].average_result.toFixed(2)
							tmpEvaluation.realizations = evaluation.users_evaluations[0].realizations
							tmpEvaluation.is_finished = evaluation.users_evaluations[0].done
							
							console.log('tmpEvaluation: ', tmpEvaluation)
							if (tmpEvaluation.realizations > 0) this.evaluations.push(tmpEvaluation)
						})

						response.data.position_selection_evaluations.forEach(evaluation => {
							console.log('evaluation: ', evaluation)

							let tmpEvaluation = {
								id: evaluation.evaluation.id,
								ps_id: evaluation.id,
								name: evaluation.evaluation.name,
								average_result: null,
								realizations: null,
								is_finished: null,
								ps_associated: null
							}

							if (evaluation.evaluation.average_result !== null && evaluation.evaluation.average_result !== undefined) tmpEvaluation.average_result = evaluation.evaluation.average_result.toFixed(2)
							tmpEvaluation.realizations = evaluation.evaluation.realizations
							tmpEvaluation.is_finished = evaluation.evaluation.done
							tmpEvaluation.ps_associated = evaluation.name
							
							console.log('tmpEvaluation: ', tmpEvaluation)
							if (tmpEvaluation.realizations > 0) this.evaluations.push(tmpEvaluation)
						})


						console.log('this.evaluations: ', this.evaluations)

						this.radarChartOptions.scale.ticks.max = 100
						this.graphLoaded = this.graphLoaded ? false : true
					}
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		async findCandidateLatestEvaluation() {
			await SoftskillsDataService.findCandidateLatestEvaluation(this.cvID).then(
				response => {
					if (response.status == 200) {
						this.evaluations = response.data

						this.radarChartOptions.scale.ticks.max = this.evaluations.scores[0].competence.levels.length - 1
						this.graphLoaded = this.graphLoaded ? false : true
					}
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.error('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		async findEvaluationEvaluators(evaluation) {
			await SoftskillsDataService.findEvaluationEvaluators(evaluation.id).then(
				response => {
					if (response.status == 200) {
						evaluation.allEvaluators = response.data

						console.log('findEvaluationEvaluators: ', this.evaluations)
					}
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		compareNames(iterator, levelName) {
			if (iterator == levelName) {
				return true;
			} else return false;
		},
		createCustomData(evaluation) {
			evaluation.evaluatorsEvaluations = []

			let tmpIdEvaluatorsArray = evaluation.scores.map(function (e) {
				return e.evaluator.id
			})

			let idEvaluatorsArray = tmpIdEvaluatorsArray.filter(function (item, pos) {
				return tmpIdEvaluatorsArray.indexOf(item) == pos;
			})

			console.log('tmpIdEvaluatorsArray: ', tmpIdEvaluatorsArray)
			console.log('idEvaluatorsArray: ', idEvaluatorsArray)

			for (let i = 0; i < idEvaluatorsArray.length; i++) {
				let evaluatorEvaluationObject = {}

				if (evaluation.weights[0].min_level === null) evaluatorEvaluationObject.byGroups = true
				else evaluatorEvaluationObject.byGroups = false

				evaluatorEvaluationObject.scoresWithoutGroups = []
				evaluatorEvaluationObject.scoresByGroups = []

				if (evaluatorEvaluationObject.byGroups) {
					for (let j = 0; j < evaluation.weights.length; j++) {
						if (evaluation.weights[j].min_level === null) {
							let object = {
								id: evaluation.weights[j].id_soft_skill,
								name: evaluation.weights[j].soft_skill.name,
								weight: evaluation.weights[j].weight,
								competences: []
							}
							evaluatorEvaluationObject.scoresByGroups.push(object)
						}
					}

					for (let k = 0; k < evaluatorEvaluationObject.scoresByGroups.length; k++) {
						for (let x = 0; x < evaluation.scores.length; x++) {
							if (evaluation.scores[x].competence.id_group === evaluatorEvaluationObject.scoresByGroups[k].id) {
								evaluatorEvaluationObject.scoresByGroups[k].competences.push(evaluation.scores[x])
							}
						}
					}

					evaluatorEvaluationObject.evaluator = evaluatorEvaluationObject.scoresByGroups[0].competences[0].evaluator
					evaluatorEvaluationObject.max_level = evaluatorEvaluationObject.scoresByGroups[0].competences[0].competence.levels.length - 1
				} else {
					for (let j = 0; j < evaluation.scores.length; j++) {
						if (idEvaluatorsArray[i] === evaluation.scores[j].evaluator.id) {
							evaluatorEvaluationObject.evaluator = evaluation.scores[j].evaluator
							evaluatorEvaluationObject.max_level = evaluation.scores[j].competence.levels.length - 1
							evaluatorEvaluationObject.scoresWithoutGroups.push(evaluation.scores[j])
						}
					}
				}

				evaluation.evaluatorsEvaluations.push(evaluatorEvaluationObject)
			}

			for (let i = 0; i < evaluation.evaluatorsEvaluations.length; i++) {
				if (evaluation.evaluatorsEvaluations[i].byGroups) {
					for (let j = 0; j < evaluation.evaluatorsEvaluations[i].scoresByGroups.length; j++) {
						for (let k = 0; k < evaluation.evaluatorsEvaluations[i].scoresByGroups[j].competences.length; k++) {
							for (let x = 0; x < evaluation.weights.length; x++) {
								if (evaluation.evaluatorsEvaluations[i].scoresByGroups[j].competences[k].id_competence === evaluation.weights[x].id_soft_skill) {
									evaluation.evaluatorsEvaluations[i].scoresByGroups[j].competences[k].min_level = evaluation.weights[x].min_level
									evaluation.evaluatorsEvaluations[i].scoresByGroups[j].competences[k].weight = evaluation.weights[x].weight
								}
							}
						}
					}

					for (let y = 0; y < evaluation.evaluation_evaluators.length; y++) {
						if (evaluation.evaluatorsEvaluations[i].evaluator.id === evaluation.evaluation_evaluators[y].id_evaluator) {
							evaluation.evaluatorsEvaluations[i].result = evaluation.evaluation_evaluators[y].result
						}
					}
				} else {
					for (let j = 0; j < evaluation.evaluatorsEvaluations[i].scoresWithoutGroups.length; j++) {
						for (let k = 0; k < evaluation.weights.length; k++) {
							if (evaluation.evaluatorsEvaluations[i].scoresWithoutGroups[j].id_competence === evaluation.weights[k].id_soft_skill) {
								evaluation.evaluatorsEvaluations[i].scoresWithoutGroups[j].min_level = evaluation.weights[k].min_level
								evaluation.evaluatorsEvaluations[i].scoresWithoutGroups[j].weight = evaluation.weights[k].weight
							}
						}
					}

					for (let x = 0; x < evaluation.evaluation_evaluators.length; x++) {
						if (evaluation.evaluatorsEvaluations[i].evaluator.id === evaluation.evaluation_evaluators[x].id_evaluator) {
							evaluation.evaluatorsEvaluations[i].result = evaluation.evaluation_evaluators[x].result
						}
					}
				}
			}

			for (let evaluator of evaluation.evaluatorsEvaluations) {
				if (evaluator.byGroups) {
					let evaluatorName = evaluator.evaluator.name + ' ' + evaluator.evaluator.surname;
					this.evaluators[evaluatorName] = evaluator
				} else {
					let evaluatorName = evaluator.evaluator.name + ' ' + evaluator.evaluator.surname;
					this.evaluators[evaluatorName] = evaluator
				}
			}

			if (this.allEvaluators.length !== evaluation.evaluatorsEvaluations.length) {
				for (let evaluator in this.evaluators) {
					this.evaluators[evaluator].weight = 100 / evaluation.evaluatorsEvaluations.length
				}

			} else {
				this.allEvaluators.forEach(evaluatorEvaluation => {
					Object.entries(this.evaluators).forEach(([evaluatorName, evaluator]) => {
						if (evaluatorEvaluation.id_evaluator === evaluator.evaluator.id) {
							evaluator.weight = evaluatorEvaluation.weight
						}
					})
				})
			}

			this.allEvaluators.forEach(evaluatorEvaluation => {
				Object.entries(this.evaluators).forEach(([evaluatorName, evaluator]) => {
					if (evaluatorEvaluation.id_evaluator === evaluator.evaluator.id) {
						evaluator.evaluator.role = evaluatorEvaluation.role
					}
				})
			})

			for (let evaluator in this.evaluators) {
				this.globalEvaluation.result += (this.evaluators[evaluator].result * this.evaluators[evaluator].weight / 100)
			}

			if (evaluation.evaluatorsEvaluations[0].byGroups) {
				for (let i = 0; i < this.globalEvaluation.scoresByGroups.length; i++) {
					for (let j = 0; j < this.globalEvaluation.scoresByGroups[i].competences.length; j++) {
						for (let evaluator in this.evaluators) {
							this.globalEvaluation.scoresByGroups[i].competences[j].score += (this.evaluators[evaluator].scoresByGroups[i].competences[j].score * this.evaluators[evaluator].weight / 100)
						}
					}
				}
			} else {
				for (let i = 0; i < this.globalEvaluation.scoresWithoutGroups.length; i++) {
					for (let evaluator in this.evaluators) {
						this.globalEvaluation.scoresWithoutGroups[i].score += (this.evaluators[evaluator].scoresWithoutGroups[i].score * this.evaluators[evaluator].weight / 100)
					}
				}
			}

			let globalName = this.globalEvaluation.evaluator.name + ' ' + this.globalEvaluation.evaluator.surname
			this.evaluators[globalName] = this.globalEvaluation
			let tmpEvaluationMinLevel = 0

			if (this.evaluators['Global Result'].byGroups) {
				this.evaluators['Global Result'].scoresByGroups.forEach(group => {
					let competencesScore = 0
					group.competences.forEach(competence => {
						competencesScore += (competence.min_level * competence.weight / 100)
					})

					tmpEvaluationMinLevel += (group.weight * competencesScore / 100)
				})

				this.evaluationMinLevel = tmpEvaluationMinLevel.toString()
			} else {
				this.evaluators['Global Result'].scoresWithoutGroups.forEach(competence => {
					tmpEvaluationMinLevel += (competence.min_level * competence.weight / 100)
				})

				this.evaluationMinLevel = tmpEvaluationMinLevel.toString()
			}

			this.fillChart(evaluation)

			console.log('evaluation: ', evaluation)
		},
		fillChart(evaluation) {
			let labels = []

			let tmpCompetencesArray = evaluation.scores.map(function (e) {
				return e.competence.name
			})

			let tmpIdCompetencesArray = evaluation.scores.map(function (e) {
				return e.id_competence
			})

			let idsCompetencesArray = tmpIdCompetencesArray.filter(function (item, pos) {
				return tmpIdCompetencesArray.indexOf(item) == pos;
			})

			let tmpIdEvaluatorsArray = evaluation.scores.map(function (e) {
				return e.evaluator.id
			})

			let idEvaluatorsArray = tmpIdEvaluatorsArray.filter(function (item, pos) {
				return tmpIdEvaluatorsArray.indexOf(item) == pos;
			})

			labels = tmpCompetencesArray.filter(function (item, pos) {
				return tmpCompetencesArray.indexOf(item) == pos;
			})

			this.radarChartData.labels = labels

			this.radarChartData.datasets = []

			let weights = []
			let weightDataset = {}
			let weightDatasetGlobalResult = {}

			idsCompetencesArray.forEach(idCompetence => {
				let tmpWeights = evaluation.weights.filter(weight => parseInt(weight.id_soft_skill) === parseInt(idCompetence)).map(function (e) {
					return { evaluator: 'Min Level Required', competence: e.soft_skill.name, score: e.min_level }
				})

				weights.push(tmpWeights[0])

				const randomColor = Math.floor(Math.random() * 16777215).toString(16)
				let color = "#" + randomColor

				var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)

				var rgbResult = {}
				let rgbaColor = ''

				if (result !== null) {
					rgbResult = {
						r: parseInt(result[1], 16),
						g: parseInt(result[2], 16),
						b: parseInt(result[3], 16)
					}

					rgbaColor = 'rgba(' + rgbResult.r + ', ' + rgbResult.g + ', ' + rgbResult.b + ', 0.2)'
				} else {
					rgbaColor = 'rgba(0,0,0,0.2)'
				}


				weightDataset = {
					label: '',
					data: [],
					fill: true,
					backgroundColor: rgbaColor,
					borderColor: color,
					pointBackgroundColor: color,
					pointBorderColor: '#FFF',
					pointHoverBackgroundColor: '#FFF',
					pointHoverBorderColor: color
				}

				weights.forEach(weight => {
					weightDataset.label = weight.evaluator
					weightDataset.data.push(weight.score)

					if (weight.evaluator !== 'Min Level Required' && weight.evaluator !== 'Global Result') weightDataset.fill = false
				})
			})

			this.radarChartData.datasets.push(weightDataset)
			let tmpCompetences = []

			if (this.evaluators['Global Result'].byGroups) {
				idsCompetencesArray.forEach(idCompetence => {
					this.evaluators['Global Result'].scoresByGroups.forEach(group => {
						group.competences.forEach(competence => {
							if (parseInt(idCompetence) === competence.id_competence) {
								let tmpWeights = { evaluator: 'Global Result', competence: competence.competence.name, score: competence.score }
								tmpCompetences.push(tmpWeights)
							}
						})
					})
				})
			} else {
				idsCompetencesArray.forEach(idCompetence => {
					this.evaluators['Global Result'].scoresWithoutGroups.forEach(competence => {
						if (parseInt(idCompetence) === competence.id_competence) {
							let tmpWeights = { evaluator: 'Global Result', competence: competence.competence.name, score: competence.score }
							tmpCompetences.push(tmpWeights)
						}
					})
				})
			}

			const randomColor = Math.floor(Math.random() * 16777215).toString(16)
			let color = "#" + randomColor

			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)

			var rgbResult = {}
			let rgbaColor = ''

			if (result !== null) {
				rgbResult = {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16)
				}

				rgbaColor = 'rgba(' + rgbResult.r + ', ' + rgbResult.g + ', ' + rgbResult.b + ', 0.2)'
			} else {
				rgbaColor = 'rgba(0,0,0,0.2)'
			}


			weightDatasetGlobalResult = {
				label: '',
				data: [],
				fill: true,
				backgroundColor: rgbaColor,
				borderColor: color,
				pointBackgroundColor: color,
				pointBorderColor: '#FFF',
				pointHoverBackgroundColor: '#FFF',
				pointHoverBorderColor: color
			}

			tmpCompetences.forEach(tmpCompetence => {
				weightDatasetGlobalResult.label = tmpCompetence.evaluator
				weightDatasetGlobalResult.data.push(tmpCompetence.score)

				if (tmpCompetence.evaluator !== 'Min Level Required' && tmpCompetence.evaluator !== 'Global Result') weightDatasetGlobalResult.fill = false
			})

			this.radarChartData.datasets.push(weightDatasetGlobalResult)

			idEvaluatorsArray.forEach(idEvaluator => {
				let scores = evaluation.scores.filter(score => parseInt(score.evaluator.id) === parseInt(idEvaluator)).map(function (e) {
					return { evaluator: e.evaluator.name + ' ' + e.evaluator.surname, competence: e.competence.name, score: e.score }
				})

				const randomColor = Math.floor(Math.random() * 16777215).toString(16)
				let color = "#" + randomColor

				var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)

				var rgbResult = {}
				let rgbaColor = ''

				if (result !== null) {
					rgbResult = {
						r: parseInt(result[1], 16),
						g: parseInt(result[2], 16),
						b: parseInt(result[3], 16)
					}

					rgbaColor = 'rgba(' + rgbResult.r + ', ' + rgbResult.g + ', ' + rgbResult.b + ', 0.2)'
				} else {
					rgbaColor = 'rgba(0,0,0,0.2)'
				}

				let dataset = {
					label: '',
					data: [],
					fill: true,
					backgroundColor: rgbaColor,
					borderColor: color,
					pointBackgroundColor: color,
					pointBorderColor: '#FFF',
					pointHoverBackgroundColor: '#FFF',
					pointHoverBorderColor: color
				}

				scores.forEach(score => {
					dataset.label = score.evaluator
					dataset.data.push(score.score)

					if (score.evaluator !== 'Min Level Required' && score.evaluator !== 'Global Result') dataset.fill = false
				})

				this.radarChartData.datasets.push(dataset)
			})

			this.graphLoaded = this.graphLoaded ? false : true
			this.dataLoaded = this.dataLoaded ? false : true
		},
		showEvaluationDetails(evaluation) {
			console.log('evaluations: ', this.evaluations)
			console.log('evaluation: ', evaluation)
			this.$router.push('/softskills-evaluation-result-user?id_evaluation=' + evaluation.id + '&id_user=' + this.userID + '&id_position_selection=' + evaluation.ps_id)
		}
	}
};
</script>