<template>
	<div class="">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link to="/tests" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Tests</router-link>
			</div>
			<div class="whiteColor">Suite Tools > Tests List > Add Questions to test '{{test.title}}'</div>
		</div>

		<add-questions/>
	</div>
</template>

<script>
  	import AddQuestions from "@/components/TestComponents/AddQuestions.vue";
	import TestDataService from "@/services/TestDataService";
	import Test from '@/models/test';

	export default {
	  	name: 'AddQuestionsView',
			data: () => ({
				test: new Test('','','','','','','',
					'','','','','','','',
					'','')
			}),
	  	components: {
	      	AddQuestions
	    },
		mounted(){
		    TestDataService.findOne(this.$route.query.id).then(
				response => {
					this.test = response.data;
				},
				error => {
					console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>