<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: '/business-process' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" /> Back to Evaluation Process
        </router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb">
          Suite Tools >
        </router-link>
        <router-link :to="{ path: '/business-process' }" class="breadcumb">
          Evaluation Process >
        </router-link>
        <router-link :to="{ path: '/tests' }" class="final-breadcumb">Assessment Tests
        </router-link>
      </div>
    </div>
    <tests-business-process-list />
  </div>
</template>

<script>
import TestsBusinessProcessList from "@/components/TestComponents/TestsBusinessProcessList.vue";

export default {
  name: "TestBusinessProcessUser",
  components: {
    TestsBusinessProcessList,
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.nav-link-user-submenu {
  color: #13283b !important;
}
</style>