<template>
	<div>
		<h4 class="reports-title">Bubble diagram</h4>
		<bubble-chart 
			:ref="chartId"
			:chartId="chartId" 
			:xAxesCategory="xAxesCategory" 
			:yAxesValue="yAxesValue" 
			:xAxesTitle="xAxesTitle"
			:yAxesTitle="yAxesTitle"
			:bubbleSizeCategory="bubbleSizeCategory"
			:bubbleSizeTitle="bubbleSizeTitle"
			:data="data"></bubble-chart>
	</div>
</template>

<script>
	import BubbleChart from "@/components/Others/Charts/BubbleChart"
	
	export default {
		name: 'TestResultsBubbleGraph',
		data() {
			return {
				chartId: "chartTestsBubbleGraph",
				data: [
					{
						"id": 10,
						"level": "Low",
						"count": 250,
						"rate": 89 // 0 - 100 %
					}, {
						"id": 25,
						"level": "High",
						"count": 15,
						"rate": 9 // 0 - 100 %
					}, {
						"id": 10,
						"level": "Low",
						"count": 2,
						"rate": 56 // 0 - 100 %
					}, {
						"id": 12,
						"level": "High",
						"count": 1,
						"rate": 18 // 0 - 100 %
					}, {
						"id": 10,
						"level": "High",
						"count": 14,
						"rate": 67 // 0 - 100 %
					}, {
						"id": 78,
						"level": "Medium",
						"count": 22,
						"rate": 99 // 0 - 100 %
					}, {
						"id": 10,
						"level": "Medium",
						"count": 24,
						"rate": 20 // 0 - 100 %
					}, {
						"id": 54,
						"level": "Very High",
						"count": 25,
						"rate": 25 // 0 - 100 %
					}, {
						"id": 22,
						"level": "Low",
						"count": 67,
						"rate": 69 // 0 - 100 %
					}, {
						"id": 22,
						"level": "Medium",
						"count": 89,
						"rate": 78 // 0 - 100 %
					}, {
						"id": 9,
						"level": "Low",
						"count": 1,
						"rate": 100 // 0 - 100 %
					}
				],
				xAxesCategory: "id",
				yAxesValue: "level",
				bubbleSizeCategory: "count",
				xAxesTitle: "Question id",
				yAxesTitle: "Question level",
				bubbleSizeTitle: "Number of times asked",
			}
		},
		components: {
			BubbleChart
		}
	};
</script>

<style>
#chartTestsBubbleGraph {
    width: 94%;
    height: 500px;
    margin-bottom: 6%;
	margin-left: 3%;
	margin-right: 3%;
}
</style>