<template>
  	<div  v-bind:class = "{'table-container':!home, 'col-sm-12': home} " class="technologiesStyle my-2">
  		<div class="row">
			<div class="col-sm-2">
				<b-pagination
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
					aria-controls="testsList"
			    ></b-pagination>
			</div>
			<div class="col-sm-7">
				<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
				<div class="numPerPage paginationSelectorTable mx-3 my-2">Level of difficulty: </div>
				<b-form-select class="numPerPage paginationSelectorTable" v-model="difficultyModel" @change="filterTests" :options="levels"> </b-form-select>  
			</div>
			<div class="col-sm-3">
				<b-form-input 
					id="filter-input"
	              	v-model="filter"
	              	type="search"
	              	:placeholder="$t('others.search-placeholder-msg')"
            	></b-form-input>
			</div>
		</div>
		
	  	<b-table 
			id="testsList"
			:per-page="perPage"
			:current-page="currentPage"
			:fields="headers" 
			:items="filterTests()"
			:filter="filter"
			responsive="sm">
			<template #cell(title)="data">
				{{data.item.title}}
				<ul class="ti-tags small">
					<li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
						<div class="ti-content">
							<div class="ti-tag-center">
								<span>{{item.name}}</span>
							</div>
						</div>
					</li>
				</ul>
	      	</template>
			<template #cell(description)="data">
				<div v-if="data.item.description != null && data.item.description !='' ">
					{{data.item.description}}
				</div>
				<div v-else>
					No description provided
				</div>
	      	</template>
			<template #cell(done)="data">
				{{data.item.done}}
	      	</template>
			<template #cell(average_qualification)="data">
				<div v-if="data.item.average_qualification != null || data.item.average_qualification == ''">
					{{data.item.average_qualification}}
				</div>
				<div v-else>
					Not available
				</div>
	      	</template>
			<template #cell(difficulty_level)="data">
				<p v-if="data.item.numberQuestions != 0" class="difficultyLevelTag" :style="'background-color: ' + data.item.colorDiff[data.item.difficulty_level]">{{data.item.difficulty_level.toUpperCase()}}</p>
				<p v-else class="difficultyLevelTag" :style="'background-color: ' + data.item.colorDiff[data.item.difficulty_level]">{{data.item.difficulty_level.toUpperCase()}}</p>
	      	</template>
			<template #cell(created_at)="data">
				{{ data.item.created_at | formatEnglishDate }}
	      	</template>
			<template #cell(updated_at)="data">
				{{ data.item.updated_at | formatEnglishDate }}
	      	</template>
			<template #cell(options)="data">
				<router-link v-b-tooltip.hover title="Summary" :to="{path: '/exam-results?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
	      	</template>
		</b-table>
		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
	  		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
	  	</div>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";

	export default {
	    name: "TestListComponent",
	    data() {
	    	return {
		        tests: [],
		        successfull: false,
		        message: "",
		        perPage: 10,
		        pageOptions: this.$paginationOptions,
	        	currentPage: 1,
	        	filter: null,
				difficulties: [],
				colorDiff: {},
				testsId: [],
				difficultyModel: "All",
				levels: ["All", "Low", "Medium", "High","Very High" ]
			}
	    },
		mounted() {
			this.getTestBoard();
			this.getTests();
	  	},
	    computed: {
			rows() {
				return this.tests.length
			}
	    },
	    props: {
	    	home: Boolean,
	    	headers: Array
	    },
		methods: {
			getTests(){
				TestDataService.findByArea(this.$store.state.auth.user.id_area).then(
					response => {
						for(var i=0; i<response.data.length; i++) {
							this.testsId.push(response.data[i].id)
						}

						TestDataService.findQuestionsForTestsId(this.testsId).then(
							response2 => {
								for(var i=0; i<response2.data.length; i++) {
									var questions = response2.data[i].questions

									var averageDifficulty = 0
									var weightSum = 0

									for(var j=0; j<questions.length; j++) {
										var questionWeight = 0;
										var assigned = false;
										for(var k=0; k<this.difficulties.length; k++) {
											if(this.difficulties[k].id == questions[j].id_difficulty) {
												questionWeight = this.difficulties[k].level
												assigned = true;
											}

											if(assigned) break;
										}

										weightSum = weightSum + questionWeight;
									}

									averageDifficulty = weightSum / questions.length;
									for(k=0; k<response.data.length; k++) {
										var assigned2 = false;
										if(response.data[k].id == response2.data[i].test.id) {
											response.data[k].averageDifficulty = averageDifficulty.toFixed("2")
											assigned = true;
										}

										if(assigned2) break;
									}
									
								}
								this.tests = response.data

								for(var i=0; i<this.difficulties.length; i++) {
									this.colorDiff[this.difficulties[i].name] = this.difficulties[i].color
								}
								for(var i=0; i<this.tests.length; i++) {
									this.tests[i].colorDiff = this.colorDiff
								}
							},
							error => {
								console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())

								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
						);
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			getTestBoard(){
				TestDataService.getTestBoard(this.$store.state.auth.user.id_area).then(
					response => {
						this.difficulties = response.data.difficulties
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			filterTests(){
				if(this.difficultyModel == "All"){
					return this.tests;
				}
				else {
					return this.tests.filter(field => field.difficulty_level == this.difficultyModel);
				}
			},
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>
