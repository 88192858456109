<template>
    <div class="col-sm-12">
      <h1>{{$t('others.coming-soon')}}</h1>
    </div>
  </template>
  
  <script>
    export default {
      name: 'BeiConfigurationView'
    };
  </script>