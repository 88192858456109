<template>
	<div>
		<h4>Number of interviews created this month ({{numInterviewsCreated}} / {{configParams[0].value}})</h4>

		<div class="row" v-for="(config, index) in configParams" v-bind:key="config.title">
			<div class="col-sm-3">
				<p class="titulo_op">{{config.title}}</p>
			</div>
			<div class="form-group col-sm-1">
				<input min="1" type="number" oninput="validity.valid||(value=0)||(value='');" id="value_input" ref="value_input" v-model="config.value" />
			</div>
		</div>
	</div>
</template>

<script>
	import InterviewsDataService from '@/services/InterviewsDataService';

	export default {
	  	name: 'GeneralConfig',
	  	data() {
			return {
				numInterviewsCreated: 0,
				configParams: [
					{key: "limit_interviews_per_month", title: "Limit of number of interviews per month", value: 1000},
				],
			}
	    },
	    props: {
	    	areaConfig: Object
	    },
	    mounted() {
	    	this.configParams[0].value = this.areaConfig.limit_interviews_per_month;

	    	this.getNumInterviewCreatedThisMonth();
	    },
	    methods: {
	    	getNumInterviewCreatedThisMonth() {
	    		const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
		    	InterviewsDataService.findByArea(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.numInterviewsCreated = response.data.length;
							this.$forceUpdate();
						} 
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	    	}
	    }
	};
</script>