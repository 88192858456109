<template>
    <div>
        <attempt-test/>
    </div>
</template>
  
<script>
    import AttemptTest from "@/components/TestComponents/AttemptCandidate.vue"

    export default {
        name: 'AttemptCandidateTestView',
        components: {
            AttemptTest
        }
    }
</script>