<template>
  <FormulateForm
    ref="education_data"
    name="education_data"
    v-model="result">

    <FormulateInput
      type="group"
      name="education"
      label="Please, provide your educational history"
      add-label="+ Add education"
      :repeatable="true"
    >
      <div class="order">
        <FormulateInput @input="input"
          name="type" 
          :options="{UpperFP: 'Level 4 Vocational Training', MediumFP: 'Level 3 Vocational Training', HighSchool: 'Certificate of Higher Education', Degree: 'Graduate', Master: 'Master´s Degree', Other: 'Other'}"
          type="select"
          placeholder="Select an option"
          label="Please, select the most appropiate*" validation="required" />
        <FormulateInput v-if="isOther" name="education_other_type" label="Indicate what other type of studies you have (In case you selected 'Other' option)" />
        <FormulateInput @input="input" :validation-rules="{ education_type: typeEducationRule }" name="education_type" help="For degrees, indicate the degree you obtained" label="Field of Study*" />
        <FormulateInput @input="input" name="study_site" label="Name of the institution*" validation="required" />
        <div class="row order-pos-form">
            <div class="col-md-6">
              <FormulateInput @input="input" type="date" name="date_start" label="When did you start these studies?*" help="Date when you started these studies" :validation="'after:1970-01-01|before:'+ today" />
            </div>
            <div class="col-md-6">
              <FormulateInput @input="input" type="date" name="date_end" label="When did you finish these studies?*" help="Date when you finished these studies" :validation="'after:1970-01-01|before:'+ today" />
            </div>
          </div>
      </div>
    </FormulateInput>

    <FormulateInput
      type="group"
      name="languages"
      label="List all the languages you can speak"
      add-label="+ Add language"
      :repeatable="true"
    >
      <div class="row order-pos-form">
        <div class="col-md-6">
          <FormulateInput @input="input" name="language" label="What is the Language?" validation="required" />
        </div>
        <div class="col-md-6">
          <FormulateInput @input="input"
            name="level" 
            :options="{A1: 'A1 or equivalent', A2: 'A2 or equivalent', B1: 'B1 or equivalent', B2: 'B2 or equivalent', C1: 'C1 or equivalent', C2: 'C2 or equivalent'}"
            type="select"
            placeholder="Select an option"
            label="What is the Level?" validation="required" />
        </div>
      </div>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
  export default { 
    name: 'EducationAndLanguagesStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {},
        isSubmitted: false,
        isOther: false,
        today: null
      } 
    }, 
    mounted() {
      this.fetchData()
      var date = new Date();
      this.today = date.toISOString().split('T')[0]
    },
    methods: {
      async fetchData() {
        let data = await this.$parent.$parent.data;

        if (data.education != undefined && data.education != ''){
          this.result.education = data.education
          this.isSubmitted = true
        } else{
          this.result.education = []
          this.isSubmitted = false
        }

        if (data.languages != undefined && data.languages != ''){
          this.result.languages = data.languages
          this.isSubmitted = true
        } else{
          this.result.languages = []
          this.isSubmitted = false
        }

        if (this.isSubmitted)
          this.$emit('can-continue', {value: true});
        else
          this.$emit('can-continue', {value: false});
      },
      isValid() {
        let allValid = false;
        if (this.result.education != undefined && this.result.languages != undefined){
          this.result.education.forEach((r) => {
            if (Object.keys(r).length == 5 && Object.values(r).every(el => el != undefined)) {
              allValid = true;
            } else {
              allValid = false;
            }
          })

          this.result.languages.forEach((r) => {
            if (Object.keys(r).length == 2 && Object.values(r).every(el => el != undefined)) {
              allValid = true;
            } else {
              allValid = false;
            }
          })
        }

        return allValid;
      },
      input(){
        if (!this.isSubmitted){
          if (this.isValid()){
            this.$emit('can-continue', {value: true});
            this.isSubmitted = true;
          } else {
            this.isOther = false;
            this.result.education.forEach((ed) => {
              if (ed.type == 'Other')
                this.isOther = true;
            });
            
            this.$emit('can-continue', {value: false});
          }
        }
      },
      typeEducationRule({ value }) {
        if (value == 'Master' || value == 'Degree')
          return true

        return false
      }
    } 
  }
</script>