<template>
  	<div class="technologiesStyle my-2">
        <div class="row">
            <div class="col-sm-6">
                <h4 class="titleClass">Select a Company of Reference</h4>

                <select class="form-select" data-vv-name="reference_company_selector" v-model="referenceCompany" id="reference_company_selector" @change="onChangeReferenceCompanySelector($event)">
                    <option v-for="element in referenceCompanyList" v-bind:key="element.name" :value="element.name" >
                        {{ element.name }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6" v-if="referenceCompany !== null && referenceCompany !== undefined">
                <h4 class="titleClass">Select a target company</h4>

                <select class="form-select" data-vv-name="target_company_selector" v-model="targetCompany" id="target_company_selector" @change="onChangeTargetCompanySelector($event)">
                    <option v-for="element in targetCompanyList" v-bind:key="element.name" :value="element.name" >
                        {{ element.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="row" v-if="(referenceCompany !== null && referenceCompany !== undefined) && (targetCompany !== null && targetCompany !== undefined)">
            <div class="col-sm-6">
                <h4 class="titleClass">Clone options</h4>

                <div class="row col-sm-8">
                    <div class="col-sm-10">
                        <p class="titulo_op right-align-text">Basic information (languages, levels and tags)</p>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input checkbox-required" type="checkbox" v-model="cloneBasics">
                        </div>
                    </div>
                </div>

                <div class="row col-sm-8">
                    <div class="col-sm-10">
                        <p class="titulo_op right-align-text">Questions</p>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input checkbox-required" type="checkbox" v-model="cloneQuestions">
                        </div>
                    </div>
                </div>

                <div class="row col-sm-11" style="margin-left: 3%;">
                    <div id="message" v-if="message" :class="successful ? 'alert alert-success' : 'alert alert-danger'">
                        {{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
                    </div>

                    <div class="form-group col-sm-12 align-item-center" v-if="(referenceCompany !== null && referenceCompany !== undefined) && (targetCompany !== null && targetCompany !== undefined)">
                        <div class="col-sm-4 col-centered">
                            <input class="btn btn-primary form-submit" type="button" @click="clone()" value="Clone">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" v-if="(referenceCompany !== null && referenceCompany !== undefined) && (targetCompany !== null && targetCompany !== undefined)">
                <h4 class="titleClass">Clone remove options</h4>
                <div class="row col-sm-12">
                    <div class="col-sm-8">
                        <p class="titulo_op right-align-text">Remove basic information (languages, levels and tags)</p>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input checkbox-required" type="checkbox" v-model="removeBasics">
                        </div>
                    </div>
                </div>

                <div class="row col-sm-12">
                    <div class="col-sm-8">
                        <p class="titulo_op right-align-text">Remove questions</p>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input checkbox-required" type="checkbox" v-model="removeQuestions">
                        </div>
                    </div>
                </div>

                <div class="row col-sm-11" style="margin-left: 3%;">
                    <div id="message2" v-if="message2" :class="successful2 ? 'message alert alert-success' : 'message alert alert-danger'">
                        {{message2}} <b-button @click="close2" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
                    </div>

                    <div class="form-group col-sm-12 align-item-center" v-if="(referenceCompany !== null && referenceCompany !== undefined) && (targetCompany !== null && targetCompany !== undefined)">
                        <div class="col-sm-4 col-centered">
                            <input class="btn btn-primary form-submit" type="button" @click="remove()" value="Remove">
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import CompanyDataService from "@/services/CompanyDataService";

	export default {
	    name: "CloneCompaniesInfo",
	    data() {
	    	return {
                referenceCompanyList: [],
                targetCompanyList: [],
                referenceCompany: null,
                targetCompany: null,
                referenceCompanyObject: null,
                targetCompanyObject: null,
                cloneBasics: false,
                cloneQuestions: false,
                removeBasics: false,
                removeQuestions: false,
		  	    message: "",
	      	    successful: false,
		  	    message2: "",
	      	    successful2: false
			}
	    },
		mounted() {
			CompanyDataService.findAll().then(
				response => {
                    this.referenceCompanyList = response.data
				},
				error => {
					console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
				}
			);
	  	},
		watch: {
            cloneBasics(val) {
				if(!val) {
					this.cloneQuestions = false;
				}
			},
			cloneQuestions(val) {
				if(val) {
					this.cloneBasics = true;
				}
			}
		},
		methods: {
            onChangeReferenceCompanySelector() {
                this.targetCompanyList = []
                this.targetCompany = null

                for (let i = 0; i < this.referenceCompanyList.length; i++) {
                    if (this.referenceCompanyList[i].name !== this.referenceCompany){
                        this.targetCompanyList.push(this.referenceCompanyList[i])
                    }
                }

            },
            onChangeTargetCompanySelector() {
                console.log('')
            },
            clone() {
                if(!this.cloneBasics) {
                    this.message = "Please select, at least, Basic information option."
                    this.successful = false
                } else {
                    for (let i = 0; i < this.referenceCompanyList.length; i++) {
                        if (this.referenceCompanyList[i].name === this.referenceCompany){
                            this.referenceCompanyObject = this.referenceCompanyList[i]
                        }
                    }

                    for (let i = 0; i < this.targetCompanyList.length; i++) {
                        if (this.targetCompanyList[i].name === this.targetCompany){
                            this.targetCompanyObject = this.targetCompanyList[i]
                        }
                    }

                    if (!this.cloneQuestions) {
                        CompanyDataService.getCloneRelationship(this.referenceCompanyObject, this.targetCompanyObject).then(
                            response => {
                                if (response.status === 200) {
                                    
                                    if (response.data.questions) {
                                        CompanyDataService.removeQuestionsClonation(this.targetCompanyObject).then(
                                            response2 => {
                                                CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                                    response => {
                                                        CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                            response => {
                                                                this.successful = true
                                                                this.message = "Cloning successfully done!"
                                                            },
                                                            error => {
                                                                console.error((error.response && error.response.data) ||
                                                                    error.message ||
                                                                    error.toString())

                                                                if (error.response.status === 300) {
                                                                    console.error('Logout: Token expired')
                                                                    localStorage.removeItem('user');
                                                                    localStorage.removeItem('company');
                                                                    this.$store.state.auth.user = null
                                                                    this.$store.dispatch('auth/logout');
                                                                    this.$router.push('/login');
                                                                }
                                                            }
                                                        );
                                                    },
                                                    error => {
                                                        console.error((error.response && error.response.data) ||
                                                            error.message ||
                                                            error.toString())

                                                        if (error.response.status === 300) {
                                                            console.error('Logout: Token expired')
                                                            localStorage.removeItem('user');
                                                            localStorage.removeItem('company');
                                                            this.$store.state.auth.user = null
                                                            this.$store.dispatch('auth/logout');
                                                            this.$router.push('/login');
                                                        }
                                                    }
                                                );
                                            },
                                            error => {
                                                console.error((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.error('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    } else {
                                        if(response.data.basics) {
                                            CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                                response => {
                                                    CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                        response => {
                                                            this.successful = true
                                                            this.message = "Cloning successfully done!"
                                                        },
                                                        error => {
                                                            console.error((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );
                                                },
                                                error => {
                                                    console.error((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())
                                                    
                                                    if (error.response.status === 300) {
                                                        console.error('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        } else {
                                            CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                response => {
                                                    this.successful = true
                                                    this.message = "Cloning successfully done!"
                                                },
                                                error => {
                                                    console.error((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            },
                            error => {
                                console.error((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 404) {
                                    CompanyDataService.createCloneRelationship(this.referenceCompanyObject, this.targetCompanyObject).then(
                                        response => {
                                            CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                response => {
                                                    this.successful = true
                                                    this.message = "Cloning successfully done!"
                                                },
                                                error => {
                                                    console.error((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.error('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        },
                                        error => {
                                            console.error((error.response && error.response.data) ||
                                                error.message ||
                                                error.toString())

                                            if (error.response.status === 300) {
                                                console.error('Logout: Token expired')
                                                localStorage.removeItem('user');
                                                localStorage.removeItem('company');
                                                this.$store.state.auth.user = null
                                                this.$store.dispatch('auth/logout');
                                                this.$router.push('/login');
                                            }
                                        }
                                    );
                                }

                                if (error.response.status === 300) {
                                    console.error('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    } else {
                        CompanyDataService.getCloneRelationship(this.referenceCompanyObject, this.targetCompanyObject).then(
                            response => {
                                if (response.status === 200) {
                                    
                                    if (response.data.questions) {
                                        CompanyDataService.removeQuestionsClonation(this.targetCompanyObject).then(
                                            response2 => {
                                                CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                                    response => {
                                                        CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                            response => {
                                                                CompanyDataService.cloneQuestionsRepository(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                                    response => {
                                                                        this.successful = true
                                                                        this.message = "Cloning successfully done!"
                                                                    },
                                                                    error => {
                                                                        console.log((error.response && error.response.data) ||
                                                                            error.message ||
                                                                            error.toString())

                                                                        if (error.response.status === 300) {
                                                                            console.log('Logout: Token expired')
                                                                            localStorage.removeItem('user');
                                                                            localStorage.removeItem('company');
                                                                            this.$store.state.auth.user = null
                                                                            this.$store.dispatch('auth/logout');
                                                                            this.$router.push('/login');
                                                                        }
                                                                    }
                                                                );
                                                            },
                                                            error => {
                                                                console.log((error.response && error.response.data) ||
                                                                    error.message ||
                                                                    error.toString())

                                                                if (error.response.status === 300) {
                                                                    console.log('Logout: Token expired')
                                                                    localStorage.removeItem('user');
                                                                    localStorage.removeItem('company');
                                                                    this.$store.state.auth.user = null
                                                                    this.$store.dispatch('auth/logout');
                                                                    this.$router.push('/login');
                                                                }
                                                            }
                                                        );
                                                    },
                                                    error => {
                                                        console.log((error.response && error.response.data) ||
                                                            error.message ||
                                                            error.toString())

                                                        if (error.response.status === 300) {
                                                            console.log('Logout: Token expired')
                                                            localStorage.removeItem('user');
                                                            localStorage.removeItem('company');
                                                            this.$store.state.auth.user = null
                                                            this.$store.dispatch('auth/logout');
                                                            this.$router.push('/login');
                                                        }
                                                    }
                                                );
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    } else {
                                        if(response.data.basics) {
                                            CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                                response => {
                                                    CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                        response => {
                                                            CompanyDataService.cloneQuestionsRepository(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                                response => {
                                                                    this.successful = true
                                                                    this.message = "Cloning successfully done!"
                                                                },
                                                                error => {
                                                                    console.log((error.response && error.response.data) ||
                                                                        error.message ||
                                                                        error.toString())

                                                                    if (error.response.status === 300) {
                                                                        console.log('Logout: Token expired')
                                                                        localStorage.removeItem('user');
                                                                        localStorage.removeItem('company');
                                                                        this.$store.state.auth.user = null
                                                                        this.$store.dispatch('auth/logout');
                                                                        this.$router.push('/login');
                                                                    }
                                                                }
                                                            );
                                                        },
                                                        error => {
                                                            console.log((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );
                                                },
                                                error => {
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        } else {
                                            CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                response => {
                                                    CompanyDataService.cloneQuestionsRepository(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                        response => {
                                                            this.successful = true
                                                            this.message = "Cloning successfully done!"
                                                        },
                                                        error => {
                                                            console.log((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );
                                                },
                                                error => {
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            },
                            error => {
                                console.error((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())

                                if (error.response.status === 404) {
                                    CompanyDataService.createCloneRelationship(this.referenceCompanyObject, this.targetCompanyObject).then(
                                        response => {
                                            CompanyDataService.cloneBasics(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                response => {
                                                    CompanyDataService.cloneQuestionsRepository(this.referenceCompanyObject, this.targetCompanyObject).then(
                                                        response => {
                                                            this.successful = true
                                                            this.message = "Cloning successfully done!"
                                                        },
                                                        error => {
                                                            console.log((error.response && error.response.data) ||
                                                                error.message ||
                                                                error.toString())

                                                            if (error.response.status === 300) {
                                                                console.log('Logout: Token expired')
                                                                localStorage.removeItem('user');
                                                                localStorage.removeItem('company');
                                                                this.$store.state.auth.user = null
                                                                this.$store.dispatch('auth/logout');
                                                                this.$router.push('/login');
                                                            }
                                                        }
                                                    );
                                                },
                                                error => {
                                                    console.log((error.response && error.response.data) ||
                                                        error.message ||
                                                        error.toString())

                                                    if (error.response.status === 300) {
                                                        console.log('Logout: Token expired')
                                                        localStorage.removeItem('user');
                                                        localStorage.removeItem('company');
                                                        this.$store.state.auth.user = null
                                                        this.$store.dispatch('auth/logout');
                                                        this.$router.push('/login');
                                                    }
                                                }
                                            );
                                        },
                                        error => {
                                            console.log((error.response && error.response.data) ||
                                                error.message ||
                                                error.toString())

                                            if (error.response.status === 300) {
                                                console.log('Logout: Token expired')
                                                localStorage.removeItem('user');
                                                localStorage.removeItem('company');
                                                this.$store.state.auth.user = null
                                                this.$store.dispatch('auth/logout');
                                                this.$router.push('/login');
                                            }
                                        }
                                    );
                                }

                                if (error.response.status === 300) {
                                    console.log('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }
                            }
                        );
                    }
                }
            },
            remove() {
                if (!this.removeBasics && !this.removeQuestions) {
                    this.message2 = "Please select, at least, one option to remove."
                    this.successful2 = false
                } else {
                    for (let i = 0; i < this.referenceCompanyList.length; i++) {
                        if (this.referenceCompanyList[i].name === this.referenceCompany){
                            this.referenceCompanyObject = this.referenceCompanyList[i]
                        }
                    }

                    for (let i = 0; i < this.targetCompanyList.length; i++) {
                        if (this.targetCompanyList[i].name === this.targetCompany){
                            this.targetCompanyObject = this.targetCompanyList[i]
                        }
                    }

                    CompanyDataService.getCloneRelationship(this.referenceCompanyObject, this.targetCompanyObject).then(
                        response => {
                            if (response.status === 200) {
                                if (this.removeBasics && this.removeQuestions) {
                                    if (response.data.basics && response.data.questions) {
                                        CompanyDataService.removeQuestionsClonation(this.targetCompanyObject).then(
                                            response2 => {
                                                CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                                    response => {
                                                        this.successful2 = true
                                                        this.message2 = "Clone removed successfully!"
                                                    },
                                                    error => {
                                                        console.log((error.response && error.response.data) ||
                                                            error.message ||
                                                            error.toString())

                                                        if (error.response.status === 300) {
                                                            console.log('Logout: Token expired')
                                                            localStorage.removeItem('user');
                                                            localStorage.removeItem('company');
                                                            this.$store.state.auth.user = null
                                                            this.$store.dispatch('auth/logout');
                                                            this.$router.push('/login');
                                                        }
                                                    }
                                                );
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    } else {
                                        this.message2 = "There is no clone to remove for the selected options"
                                        this.successful2 = false
                                    }
                                } else if (this.removeBasics && !this.removeQuestions) {
                                    if (response.data.basics && !response.data.questions) {
                                        CompanyDataService.removeBasicsClonation(this.targetCompanyObject).then(
                                            response => {
                                                this.successful2 = true
                                                this.message2 = "Clone removed successfully!"
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    } else if (response.data.basics && response.data.questions) {
                                        this.message2 = "There are cloned questions, also select the option to remove them to be able to remove the basic information"
                                        this.successful2 = false
                                    } else {
                                        this.message2 = "There is no clone to remove for the selected options"
                                        this.successful2 = false
                                    }
                                } else if (!this.removeBasics && this.removeQuestions) {
                                    if (response.data.questions) {
                                        CompanyDataService.removeQuestionsClonation(this.targetCompanyObject).then(
                                            response2 => {
                                                this.successful2 = true
                                                this.message2 = "Clone removed successfully!"
                                            },
                                            error => {
                                                console.log((error.response && error.response.data) ||
                                                    error.message ||
                                                    error.toString())

                                                if (error.response.status === 300) {
                                                    console.log('Logout: Token expired')
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('company');
                                                    this.$store.state.auth.user = null
                                                    this.$store.dispatch('auth/logout');
                                                    this.$router.push('/login');
                                                }
                                            }
                                        );
                                    } else {
                                        this.message2 = "There is no clone to remove for the selected options"
                                        this.successful2 = false
                                    }
                                }
                            }
                        },
                        error => {
                            console.log((error.response && error.response.data) ||
                                error.message ||
                                error.toString())

                            if (error.response.status === 404) {
                                this.message2 = "There is no cloning between the selected companies."
                                this.successful2 = false
                            }

                            if (error.response.status === 300) {
                                console.log('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }
            },
			close() {
				document.getElementById("message").style.display = "none"
                this.successful = false
                this.message = ""
			},
			close2() {
				document.getElementById("message2").style.display = "none"
                this.successful2 = false
                this.message2 = ""
			}
		}
	};
</script>

<style scoped>
.componentMargins{
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin{
    padding-top: 0% !important;
}

.noMarginLow{
    padding-bottom: 0% !important;
}

.titleClass{
    margin-bottom: 3% !important;
    margin-top: 1% !important;
}

.resetColor{
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText{
    text-align: center !important;
}

.dropdown-content {
    z-index: 999;
    position: fixed;
    display: flex !important;
    margin: 0% !important; 
}

.checkbox-required {
    float: none !important;
}

.right-align-text {
    float: right !important;
}
</style>
