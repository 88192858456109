<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin-configuration-areas' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Areas
					Configuration</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/admin-configuration' }" class="breadcumb"> Configuration > </router-link>
				<router-link :to="{ path: '/admin-configuration-areas' }" class="breadcumb"> Areas > </router-link>
				<router-link :to="{ path: '/admin-configuration-areas-update-area' }" class="final-breadcumb"> Update
					Area </router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered">
			<update-area />
		</div>
	</div>
</template>

<script>
import UpdateArea from "@/components/AreasComponents/Update";

export default {
	name: 'UpdateAreaView',
	components: {
		UpdateArea
	},
	props: {
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.nav-link-user-submenu {
	color: #13283B !important;
}
</style>
