<template>
	<div v-bind:class="{ 'table-container': !home, 'col-sm-12': home }" class="technologiesStyle my-2">
		<h4>{{ $t('dashboards.areas.cvs.external-cvs') }} ({{ analyses.cvs.length }} CVs) <font-awesome-icon icon="info-circle" :title="$t('dashboards.areas.cvs.external-cvs-description')"/></h4>

		<div id="message" v-if="message" class="col-md-12 align-item-center alert"
			:class="successfull ? 'alert-success' : 'alert-danger'">
			{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
			</b-button>
		</div>

		<template v-if="analyses.cvs.length > 0">
			<div id="message2" v-if="analyses.area_status && message2" class="col-md-12 align-item-center mb-4">
				<h6 style="text-align: center !important;">{{ message2 }}</h6>
			</div>
			<div class="row mt-row" :class="analyses.area_status ? 'no-events' : ''">
				<div class="col-xl-3 col-lg-12">
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" limit="2"
						aria-controls="usersAnalyses"></b-pagination>
				</div>
				<div class="col-xl-6  col-lg-12">
					<b-form-select class="numPerPage paginationSelectorTable company-uploaded-cv-class"
						v-model="perPage" :options="pageOptions"></b-form-select>
					<button v-b-tooltip.hover class="btn btn-primary table-selection-button" type="button"
						@click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all
						rows</button>
					<button v-b-tooltip.hover class="btn btn-primary table-selection-button mb-1" type="button"
						@click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>
					<button v-b-tooltip.hover v-if="selectedItems.length > 0"
						class="btn btn-primary table-selection-button delete-button my-1" type="button"
						@click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete
						{{ selectedItems.length }} rows</button>
				</div>
				<div class="col-xl-3 col-lg-12 search-input">
					<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
				</div>
			</div>
			<div class="row mt-row" :class="analyses.area_status ? 'no-events' : ''">
			</div>

			<b-table ref="analysesTable" id="usersAnalyses" :per-page="perPage" :current-page="currentPage"
				:fields="headers" :items="analyses.cvs" :filter="filter" :filter-function="filterTable" responsive="sm"
				select-mode="multi" selectable @row-selected="onRowSelected"
				:class="analyses.area_status ? 'no-events' : ''">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(username)="data">
					<p v-if="data.item.name.trim() != ''">{{ data.item.name }}</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(phone_number)="data">
					<p v-if="data.item.phone_number != ''" v-b-tooltip.hover v-bind:title="data.item.phone_number">
						<font-awesome-icon icon="phone" />
					</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(profile)="data">
					<p v-if="data.item.main_profile != null">{{ data.item.main_profile.charAt(0).toUpperCase() +
		data.item.main_profile.slice(1) }}</p>
					<p v-else>N/A</p>
				</template>
				<template #cell(progress)="data">
					<p>{{ data.item.progress }}%</p>
				</template>
				<template #cell(anonymized)="data">
					<p v-if="data.item.anonymized != null && !data.item.anonymized"><font-awesome-icon
							class="completed-icon-no" icon="times" style="color: red;" /></p>
					<button v-else v-b-tooltip.hover :title="data.item.chatbot_token" class="btn btn-secondary"
						type="button" @click="copyToClipboard(data.item.chatbot_token)">Copy token</button>
				</template>
				<template #cell(createdAt)="data">
					<p>{{ data.item.created_at | formatEnglishDate }}</p>
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover :title="$t('dashboards.areas.cvs.show-external-cv')"
						:to="{ path: '/profile-analysis?id=' + data.item.id }"
						class="btn btn-secondary"><font-awesome-icon icon="eye" /></router-link>
					<button
						v-if="(data.item.email == undefined || data.item.email == '') && parseFloat(data.item.progress) !== 100"
						v-b-tooltip.hover title="This candidate does not have email"
						class="btn btn-secondary btnEmail"><font-awesome-icon icon="envelope"
							class="iconEmail" /></button>
					<button
						v-else-if="data.item.email !== undefined && data.item.email !== '' && parseFloat(data.item.progress) !== 100"
						@click="showSendModal(data.item.chatbot_token, data.item.email)" v-b-tooltip.hover
						title="Send chatbot invitation" class="btn btn-secondary"><font-awesome-icon
							icon="envelope" /></button>
				</template>
			</b-table>
			<b-alert :show="dismissCountDownSuccess" dismissible variant="success"
				@dismissed="dismissCountDownSuccess = 0" @dismiss-count-down="countDownChangedSuccess">
				<h5 class="align-items-center mb-3">Token copied!</h5>
				<b-progress variant="success" :max="dismissSecsSuccess" :value="dismissCountDownSuccess"
					style="height: 2px !important"></b-progress>
			</b-alert>
			<b-alert :show="dismissCountDownError" dismissible variant="error" @dismissed="dismissCountDownError = 0"
				@dismiss-count-down="countDownChangedError">
				<h5 class="align-items-center mb-3">Error copying token!</h5>
				<b-progress variant="error" :max="dismissSecsError" :value="dismissCountDownError"
					style="height: 2px !important"></b-progress>
			</b-alert>
		</template>
		<template v-else>
			<h3 v-if="numAreaProfiles > 0" class="no-results-msg"><font-awesome-icon icon="times-circle"
					class="black-color" /> No results found. Please, upload a CV.</h3>
			<h3 v-else class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
				found.</h3>
		</template>

		<b-modal id="send-modal" ref="send-modal" title="Send-modal" hide-footer>
			<template #modal-title>
				{{ $t('dashboards.admin.modules.analysis.send-chatbot-invitation-msg') }}
			</template>
			<div>
				<p>{{ $t('dashboards.admin.modules.analysis.send-chatbot-invitation-modal-msg') }} </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('send-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="sendChatbotInvitation()" value="Yes">
		</b-modal>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete profile analysis
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{ selectedItems.length }} profile
					analysis? </p>
				<p v-else> Do you really want to delete this profile analysis? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteAnalysis()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import UserDataService from '@/services/UserDataService';

export default {
	name: 'AnalysesListComponent',
	data() {
		return {
			analyses: [],
			message: "",
			message2: "Recalculation of profiles in process, you will be notified via email when completed.",
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			clickable: true,
			selectedChatbotToken: null,
			selectedEmail: null,
			selectedId: null,
			selectedIdIndex: null,
			selectedItems: [],
			certifications: [],
			education: [],
			educationModules: [],
			jobs: [],
			skills: [],
			experience: {},
			languages: [],
			yearsLeft: 0,
			dismissSecsSuccess: 4,
			dismissSecsError: 4,
			dismissCountDownSuccess: 0,
			dismissCountDownError: 0,
		}
	},
	props: {
		home: Boolean,
		numAreaProfiles: Number
	},
	mounted() {
		this.findCVsByArea();
	},
	computed: {
		rows() {
			return this.analyses.cvs.length
		},
		headers() {
			return [
				{ key: 'name', sortable: true, label: this.$t('dashboards.admin.modules.analysis.table-headers.name-column') }, { key: 'phone_number', label: this.$t('dashboards.admin.modules.analysis.table-headers.phone-column') },
				{ key: 'main_profile', sortable: true, label: 'Main Skill' }, { key: 'progress', sortable: true, label: 'Progress' },
				{ key: 'anonymized', sortable: true, label: 'Anonymized' },
				{ key: 'createdAt', sortable: true, label: this.$t('dashboards.admin.modules.analysis.table-headers.created-at-column') }, { key: 'options', label: 'Options' }
			]
		}
	},
	methods: {
		async findCVsByArea() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			await ProfileAnalysisDataService.findCandidatesCVsByArea(currentArea).then(
				async response => {
					if (response.status == 200) {
						console.log('response findCandidatesCVsByArea: ', response.data)
						let analyses = response.data
						this.analyses = analyses

						let objTmp = ""
						let obj = ""
						let main_profile = ""


						for (let i = 0; i < this.analyses.cvs.length; i++) {
							if (this.analyses.cvs[i].progress != null && this.analyses.cvs[i].progress != undefined && this.analyses.cvs[i].progress.length > 10) {
								objTmp = this.analyses.cvs[i].progress.replaceAll("'", "\"");
								obj = JSON.parse(objTmp);
								main_profile = Object.keys(obj).reduce(function (a, b) { return obj[a] > obj[b] ? a : b });
								this.analyses.cvs[i].main_profile = main_profile.charAt(0).toUpperCase() + main_profile.slice(1)
							}

							if (this.analyses.cvs[i].name.includes("Anonymous User")) this.analyses.cvs[i].anonymized = true
							else this.analyses.cvs[i].anonymized = false

							let totalFields = 5
							let countDefinedFields = 0

							if (this.analyses.cvs[i].certifications != '' && this.analyses.cvs[i].certifications != null) {
								let certifications = this.analyses.cvs[i].certifications
								certifications = certifications.replaceAll("'", "\"");
								certifications = JSON.parse(certifications)
								this.certifications = certifications

								this.certifications = this.certifications.map(name => name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

								countDefinedFields++;
							}

							if (this.analyses.cvs[i].skills != '' && this.analyses.cvs[i].skills != null) {
								let skills = this.analyses.cvs[i].skills
								skills = skills.replaceAll("'", "\"");
								skills = JSON.parse(skills)
								this.skills = skills

								Object.keys(this.skills).map((key, index) => {
									if (Array.isArray(this.skills[key])) {
										if (this.skills[key].length > 0) {
											this.skills[key] = this.skills[key].map(item => item?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
										} else {
											this.skills[key] = []
										}
									} else {
										this.skills[key] = []
									}
								});

								countDefinedFields++;
							}

							if (this.analyses.cvs[i].education != '' && this.analyses.cvs[i].education != null) {
								if (this.analyses.cvs[i].education.includes('study_site')) {
									let education = this.analyses.cvs[i].education
									education = education.replaceAll("'", "\"");
									education = JSON.parse(education)
									this.education = education
									let educationTypeTmp = ""

									this.education.forEach((education_item) => {
										if (education_item.type_education_date != null)
											educationTypeTmp = education_item.type_education_date
										else
											educationTypeTmp = education_item.type_education

										var educationModule = educationTypeTmp.substring(
											educationTypeTmp.indexOf(",") + 1,
											educationTypeTmp.lastIndexOf("(")
										);

										if (educationModule === "")
											educationModule = educationTypeTmp

										educationModule = educationModule.replace(',', '');
										educationModule = educationModule.replace("  ·", "");
										educationModule = educationModule.replace(" ·", "");

										this.educationModules.push(educationModule.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
									})
								} else {
									let education = this.analyses.cvs[i].education
									education = education.replaceAll("'", "\"");
									education = JSON.parse(education)
									this.education = education
									this.education.forEach((education_item) => {
										this.educationModules.push(education_item.degree.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

										education_item.study_site = education_item.institution
										education_item.type_education_date = education_item.degree + ' (' + education_item.end_date + ')'
									})
								}
								countDefinedFields++;
							}

							if (this.analyses.cvs[i].experience_jobs != '' && this.analyses.cvs[i].experience_jobs != null) {
								let jobs = this.analyses.cvs[i].experience_jobs;
								jobs = jobs.replaceAll("'", "\"");
								jobs = JSON.parse(jobs)
								this.jobs = jobs
								countDefinedFields++;
							}

							if (this.analyses.cvs[i].experience != '' && this.analyses.cvs[i].experience != null) {
								let experience = this.analyses.cvs[i].experience_time;
								let juniorMinYears = 1
								let middleMinYears = 4
								let seniorMinYears = 6

								experience = experience.replaceAll("'", "\"");
								experience = JSON.parse(experience)
								this.experience = experience

								if ((juniorMinYears - this.experience.years) > 0) {
									this.yearsLeft = juniorMinYears - this.experience.years
								} else if ((middleMinYears - this.experience.years) > 0) {
									this.yearsLeft = middleMinYears - this.experience.years
								} else if ((seniorMinYears - this.experience.years) > 0) {
									this.yearsLeft = seniorMinYears - this.experience.years
								} else {
									this.yearsLeft = 0
								}
							}

							if (this.analyses.cvs[i].languages != '' && this.analyses.cvs[i].languages != null) {
								let languages = this.analyses.cvs[i].languages;
								languages = languages.replaceAll("'", "\"");
								languages = JSON.parse(languages)
								this.languages = languages
								countDefinedFields++;
							}

							var definedPercentage = (countDefinedFields * 100) / totalFields;

							this.analyses.cvs[i].progress = definedPercentage.toFixed(2)

							this.analyses.cvs[i].validated = this.analyses.cvs[i].validated
						}
					} else {
						this.message = 'An error ocurred while retrieving analysis'
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		onRowSelected(items) {
			this.selectedItems = items;
		},
		selectAllRows() {
			this.$refs["analysesTable"].selectAllRows();
		},
		clearSelected() {
			this.$refs["analysesTable"].clearSelected();
		},
		showSendModal(token, email) {
			this.selectedChatbotToken = token
			this.selectedEmail = email
			this.$refs['send-modal'].show();
		},
		showDeleteModalMultiple() {
			this.$refs['delete-modal'].show();
		},
		sendChatbotInvitation() {
			UserDataService.sendChatbotInvitationToEmail(this.selectedChatbotToken).then(
				response => {
					if (response.status == 200) {
						this.successfull = true
						this.message = 'An invitation to email ' + this.selectedEmail + ' has been sent for completing his profile with Aris chabtot!.'
					} else {
						this.successfull = false
						this.message = 'An error ocurred while sending chatbot invitation email.'
					}
					this.$refs['send-modal'].hide();
				},
				error => {
					this.message = error.response.data.message
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		deleteAnalysis() {
			let selectedRows = this.selectedItems.map(element => element.id);

			ProfileAnalysisDataService.delete(selectedRows).then(
				response => {
					this.message = response.data.message
					if (response.status == 200) {
						this.successfull = true

						this.analyses.cvs = this.analyses.cvs.filter(function (value, index) {
							return selectedRows.indexOf(value.id) == -1;
						});
					}

					this.$refs['delete-modal'].hide();
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		copyToClipboard(str) {
			navigator.clipboard.writeText(str).then(
				() => {
					this.showAlertSuccess()
				},
				() => {
					this.showAlertError()
				}
			);
		},
		countDownChangedSuccess(dismissCountDownSuccess) {
			this.dismissCountDownSuccess = dismissCountDownSuccess
		},
		countDownChangedError(dismissCountDownError) {
			this.dismissCountDownError = dismissCountDownError
		},
		showAlertSuccess() {
			this.dismissCountDownSuccess = this.dismissSecsSuccess
		},
		showAlertError() {
			this.dismissCountDownError = this.dismissSecsError
		},
		close() {
			document.getElementById("message").style.display = "none"
		},
		filterTable(row, filter) {
			if (row.name?.toLowerCase().includes(filter.toLowerCase())) return true
			else return false
		}
	}
};
</script>

<style>
.btnEmail {
	cursor: not-allowed !important;
	opacity: 0.5 !important;
}

.iconEmail {
	color: grey !important;
}

.no-events {
	pointer-events: none !important;
	opacity: 0.5;
}
</style>