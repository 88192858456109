<template>
	<div class="row" v-if="!pdf">
		<div class="col-sm-12">
			<h4 style="margin-top: 1%;">Top Professional Reskilling Profiles</h4>

			<template v-if="recyclingProfiles.length > 0">
				<div class="row" v-for="(profile, index) in recyclingProfiles" :key="index">
					<div style="margin-bottom: 15px;">
						<div class="row">
							<div class="col-md-4 align-items-center">
								<img v-if="logos[profile.profile.toLowerCase()] !== null && logos[profile.profile.toLowerCase()] !== undefined && logos[profile.profile.toLowerCase()] !== ''"
									v-bind:src="logos[profile.profile.toLowerCase()]" height="50em"
									class="analysis-card-avatar--circle" v-b-tooltip.hover
									:title="profile.profile.charAt(0).toUpperCase() + profile.profile.slice(1)" />
								<b v-else>{{ profile.profile.toUpperCase() }}&nbsp;&nbsp;&nbsp;</b>
							</div>
							<div class="col-md-1 arrow-1"></div>
							<div class="col-md-4 align-items-center">
								<div v-for="item in profile.data" v-bind:key="item.profile">
									<img v-if="logos[item.profile.toLowerCase()] !== null && logos[item.profile.toLowerCase()] !== undefined && logos[item.profile.toLowerCase()] !== ''"
										v-bind:src="logos[item.profile.toLowerCase()]" v-b-tooltip.hover
										:title="item.profile" height="40em"
										class="analysis-card-avatar--circle col-3" />
									<b v-else>{{ item.profile.toUpperCase() }}&nbsp;&nbsp;&nbsp;</b>
								</div>
							</div>
							<div class="col-md-3 align-items-right">
								<button class="btn btn-secondary expand-button" type="button"
									v-b-toggle="'toggleTReskilling-' + index.toString()"
									@click="changeShowState(index)">
									<font-awesome-icon style="display: block;"
										v-bind:id="'iconTabShow-' + index.toString()" class="trash-icon"
										v-b-tooltip.hover title="Expand" icon="chevron-down" />
									<font-awesome-icon style="display: none;"
										v-bind:id="'iconTabNotShow-' + index.toString()" class="trash-icon"
										v-b-tooltip.hover title="Contract" icon="chevron-up" />
								</button>
							</div>
						</div>
					</div>
					<b-collapse v-bind:id="'toggleTReskilling-' + index.toString()">
						<update-result
							:sourceProfile="profile.profile.charAt(0).toUpperCase() + profile.profile.slice(1)"
							:recyclingProfiles="profile.data" :logos="logos" :styleLevels="styleLevels" />
					</b-collapse>
					<hr class="thicker">
				</div>
			</template>
			<template v-else>
				<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No
					updating profiles available</h3>
			</template>
		</div>
	</div>
	<div class="row" v-else>
		<div class="col-sm-12">
			<h4
				style="margin-top: 2%; text-align: left;border-bottom: 5px solid;padding-bottom: 10px; margin-bottom: 35px; font-size: 1.5rem">
				Top Professional Reskilling Profiles</h4>

			<template v-if="recyclingProfiles.length > 0">
				<div class="row" v-for="(profile, index) in recyclingProfiles" :key="index">
					<div style="margin-bottom: 15px;">
						<div class="row">
							<div class="col-md-4 align-items-center">
								<img v-if="logos[profile.profile.toLowerCase()] !== null && logos[profile.profile.toLowerCase()] !== undefined && logos[profile.profile.toLowerCase()] !== ''"
									v-bind:src="logos[profile.profile.toLowerCase()]" height="50em"
									class="analysis-card-avatar--circle" v-b-tooltip.hover
									:title="profile.profile.charAt(0).toUpperCase() + profile.profile.slice(1)" />
								<b v-else>{{ profile.profile.toUpperCase() }}&nbsp;&nbsp;&nbsp;</b>
							</div>
							<div class="col-md-1 arrow-1"></div>
							<div class="col-md-4 align-items-center">
								<div v-for="item in profile.data" v-bind:key="item.profile">
									<img v-if="logos[item.profile.toLowerCase()] !== null && logos[item.profile.toLowerCase()] !== undefined && logos[item.profile.toLowerCase()] !== ''"
										v-bind:src="logos[item.profile.toLowerCase()]" v-b-tooltip.hover
										:title="item.profile" height="40em"
										class="analysis-card-avatar--circle col-3" />
									<b v-else>{{ item.profile.toUpperCase() }}&nbsp;&nbsp;&nbsp;</b>
								</div>
							</div>
							<div class="col-md-3 align-items-right">
								<button class="btn btn-secondary expand-button" type="button"
									v-b-toggle="'toggleTReskilling-' + index.toString()"
									@click="changeShowState(index)">
									<font-awesome-icon style="display: block;"
										v-bind:id="'iconTabShow-' + index.toString()" class="trash-icon"
										v-b-tooltip.hover title="Expand" icon="chevron-down" />
									<font-awesome-icon style="display: none;"
										v-bind:id="'iconTabNotShow-' + index.toString()" class="trash-icon"
										v-b-tooltip.hover title="Contract" icon="chevron-up" />
								</button>
							</div>
						</div>
					</div>
					<b-collapse v-bind:id="'toggleTReskilling-' + index.toString()">
						<update-result
							:sourceProfile="profile.profile.charAt(0).toUpperCase() + profile.profile.slice(1)"
							:recyclingProfiles="profile.data" :logos="logos" :styleLevels="styleLevels" />
					</b-collapse>
					<hr class="thicker">
				</div>
			</template>
			<template v-else>
				<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No
					updating profiles available</h3>
			</template>
		</div>
	</div>
</template>

<script>
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
import UpdateResult from "@/components/AnalysisComponents/AnalysisResult/UpdateResultComponent";

export default {
	name: "TopUpdatingProfiles",
	data() {
		return {
			recyclingProfiles: [],
			perPage: 10,
			currentPage: 1,
			pageOptions: this.$paginationOptions,
			clickable: true,
			filter: null
		}
	},
	components: {
		UpdateResult
	},
	mounted() {
		this.getTopUpdatingProfiles()
	},
	props: {
		logos: Object,
		styleLevels: Object,
		pdf: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		changeShowState(index) {
			if (document.getElementById('iconTabShow-' + index.toString()).style.display == "block") {
				document.getElementById('iconTabShow-' + index.toString()).style.display = "none"
				document.getElementById('iconTabNotShow-' + index.toString()).style.display = "block"
			} else {
				document.getElementById('iconTabShow-' + index.toString()).style.display = "block"
				document.getElementById('iconTabNotShow-' + index.toString()).style.display = "none"
			}
		},
		async getTopUpdatingProfiles() {
			await ProfileAnalysisDataService.findTopRecycleProfilesByCVProfiles(this.$route.query.id, 5).then(
				response => {
					if (response.status == 200) {
						this.recyclingProfiles = response.data;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
	}
};
</script>
