<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option hidden value="">Select an area</option>
			<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:chartId="chartId" 
				:key="graphLoaded"
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:data="data"
				:tooltipNoDataMsg="tooltipNoDataMsg"
				:chartTitle = "graphTitle"></barr-chart>
		</div>
	</div>
</template>

<script>
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"
	import ReportsDataService from '@/services/ReportsDataService';

	export default {
		name: 'QuestionGraphByTechnology',
		data() {
			return {
				chartId: "chartQuestionGraphByTechnology",
				data: [],
				graphTitle: "Column diagrams displaying skills",
				xAxesCategory: "name",
				yAxesValue: "count",
				xAxesTitle: "Skill",
				yAxesTitle: "Number of questions",
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedAreaName: null,
				tooltipNoDataMsg: "Please, select an area"
			}
		},
		components: {
			BarrChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value
				this.graphLoading = true;

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea))
						this.selectedAreaName = area.name
						this.graphTitle = "Column diagrams displaying skills in " + this.selectedAreaName;
				});

				this.fetchData();
			},
			async fetchData() {
				await ReportsDataService.questionsPerProfileByArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.tooltipNoDataMsg = this.data.length > 0 ? this.tooltipNoDataMsg : "There are no skills or technologies in the selected area"; 
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>