<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div v-if="from.path == '/available-tests'" class="col-5 links-container">
				<router-link :to="{ path: '/available-tests' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to My Assessment Tests</router-link>
			</div>
			<div v-if="from.path == '/test-business-process-result'" class="col-5 links-container">
				<router-link
					:to="{ path: from.path + '?id_user=' + from.query.id_user + '&id_business_process=' + from.query.id_business_process + '&type=' + from.query.type }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
					Process Exams</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">Assessment Test "{{ results.title }}"</div>
		</div>
		<test-result :testID="testID" :userID="userID" :type="type" />
	</div>
</template>

<script>
import TestResult from "@/components/TestComponents/Result.vue";
import TestDataService from "@/services/TestDataService";

export default {
	name: 'TestResultView',
	components: {
		TestResult
	},
	data() {
		return {
			from: null,
			results: {},
			type: null
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from;
			console.log('from: ', from)
		});
	},
	computed: {
		userID() {
			return parseInt(this.$route.query.id_user);
		},
		testID() {
			return parseInt(this.$route.query.id);
		}
	},
	mounted() {
		this.type = this.$route.query.type
		TestDataService.findTestResult(this.$route.query.type, this.$route.query.id, this.$route.query.id_user).then(
			response => {
				this.results = response.data;
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}
</style>