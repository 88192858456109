<template>
  <div class="col-sm-12" v-if="from">
    <div v-if="from.path == '/softskills-list'" class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: '/softskills-list' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" />
          Back to Soft Skills Processes</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suit Tools > </router-link>
        <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
        <router-link :to="{ path: '/softskills-list' }" class="final-breadcumb"> Soft Skills Statistics </router-link>
      </div>
    </div>
    <div v-else-if="from.path == '/softskills-user'" class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: 'softskills-list' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" />
          Back to Soft Skills Processes</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suit Tools > </router-link>
        <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
        <router-link :to="{ path: '/softskills-list' }" class="final-breadcumb"> Soft Skills Statistics </router-link>
      </div>
    </div>
    <div v-else class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: 'business-process' + '?id=' + from.query.id_area }"
          class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Business
          Processes</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suit Tools > </router-link>
        <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
        <router-link :to="{ path: '/softskills-list' }" class="final-breadcumb"> Soft Skills Statistics </router-link>
      </div>
    </div>

    <div class="col-sm-8 col-centered">
      <StatisticsS4Component />
    </div>
  </div>
</template>

<script>
import StatisticsS4Component from "@/components/S4Components/Statistics.vue";

export default {
  name: 'StatisticsS4View',
  components: {
    StatisticsS4Component
  },
  data() {
    return {
      from: null,
      labelFrom: "",
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}
</style>