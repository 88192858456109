<template>
    <div class="form-container my-3 py-3">
        <div class="row">
            <div class="col-md-4">
                <div class="card-image">
                    <a href="javascript:void(0);">
                      <img v-bind:src="profileLogo" class="card-avatar card-avatar--circle">
                    </a>
                    <input id="logoImgFileInput" accept=".jpg" ref="logoImgFileInput" type="file" @change="onFileChange">
                    <p class="no-margin-bottom mt-4" style="text-align: left !important;"><font-awesome-icon icon="info-circle" />&nbsp;&nbsp;Files must have .jpg extension to be correctly shown in the user analysis view.</p>
                </div>
            </div>
            <div class="col-md-8 col-top-edit-profile">
                <form action class="form" @submit.prevent="handleEdit">
                    <div class="col-sm-12">
                        <div class="col-md-12 align-item-center">
                            <div class="row form-group">
                                <h3 id="name-profile-title">Name <b-button class="btn btn-info" v-b-tooltip.hover title="Name of the profile"><font-awesome-icon icon="info-circle" /></b-button></h3>
                                <input class="form-input" type="text" id="name-profile" v-validate="'required'" data-vv-name="name-profile" v-model="profileNameTitle">
                                    <div v-if="submitted && veeErrors.has('name-profile')" class="alert alert-danger">
                                    {{veeErrors.first('name-profile')}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-lg-5 align-item-center">
                        <div class="col-sm-12 row">
                            <div class="col-sm-6">
                                <input class="btn btn-primary form-submit" type="submit" value="Update Profile">
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-secondary btn-cancel" type="button" @click="cancelUpdate()"><font-awesome-icon icon="ban" /> Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="submitted" class="text-center text-loading mt-4">
                <b-spinner class="align-middle analysis-spinner"></b-spinner>
                <strong class="text-loading-msg"> Processing data...</strong>
            </div>
        </div>
	</div>
</template>

<script>
    import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

    export default {
        name: 'EditProfile',
        data() {
            return {
                profileNameTitle: "",
                profileLogo: '',
                submitted: false,
                successful: false,
                message: "",
                fileToUpload: ""
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            headers(){
                return [
                    { key: 'activated', sortable: true, label: 'Status', thStyle: { width: "5%" } },
                    { key: 'selectionProcess', sortable: true, label: 'Selection Process' },
                    { key: 'weight', sortable: true, label: 'Weight' }
                ]
            },
        },
        mounted() {
            ProfileConfigurationDataService.getProfile(this.$route.query.idProfile).then(
                response => {
                    this.profile = response.data
                    this.profileNameTitle = this.profile.name;
                    this.profileLogo = this.profile.logo == null ? '//ssl.gstatic.com/accounts/ui/avatar_2x.png' : (this.profile.logo.includes(this.$awsBucketRoute) ? this.profile.logo : this.$awsBucketRoute + this.profile.logo)  ;
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        methods: {
            cancelUpdate() {
                this.$router.replace({ name: "profile-configuration", params: {}});
            },
            handleEdit() {
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        let newTech = this.profile;
                        newTech.name = this.profileNameTitle;
                        newTech.logo = this.profileLogo;
                        ProfileConfigurationDataService.updateProfileData(newTech).then(
                            response => {
                              if (response.status == 200) {
                                this.successful = true;
                                this.message = "Profile updated successfully"
                                this.saveImage(this.profileLogo)
                                
                              } else {
                                this.message = response.data.message;
                                this.$router.push({ name: 'profile-configuration', params: { updateFlag: this.successful, updateMessage: this.message } })
                              }
                            },
                            error => {
                                console.error((error.response && error.response.data) ||
                                    error.message ||
                                    error.toString())
                                
                                if (error.response.status === 300) {
                                    console.error('Logout: Token expired')
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('company');
                                    this.$store.state.auth.user = null
                                    this.$store.dispatch('auth/logout');
                                    this.$router.push('/login');
                                }

                                this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successful, createMessage: this.message } })
                            }
                        );
                    } else {
                        this.message = "Please, insert a valid name";
                    }
                });
            },
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                  return
                }

                this.createImage(files[0]);
            },
            saveImage(base64Image) {
                let idProfile = this.$route.query.idProfile;
                let imageName = "images/"+this.currentUser.accessToken+"_"+idProfile+".png";
                var file = this.dataURLtoFile(base64Image,"imageName.jpeg");
                if(file  === null ) {
                    this.message = "Profile updated successfully"
                    this.successful = true;
                    this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successful, createMessage: this.message } })
                }
                ProfileConfigurationDataService.updateAvatar(file, imageName, idProfile, this.currentUser.accessToken, event => {}).then(
                    response => {
                      if (response.status == 200) {
                        this.profileLogo = this.$awsBucketRoute+imageName;
                      } else {
                        this.message = response.data.message
                        this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })
                      }
                    }
                ).then(files => {
                    this.message = "Profile updated successfully"
                    this.successful = true;
                    this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successful, createMessage: this.message } })
                })
                .catch((error) => {
                  this.message = "There was an error while processing your image";
                  this.$router.push({ name: 'profile-configuration', params: { createFlag: this.successfull, createMessage: this.message } })
                });
            },

            dataURLtoFile(dataurl, filename) {
                let arr = dataurl.split(',');
                if(arr.length == 1) return null;
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);
                    
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                
                return new File([u8arr], filename, {type:mime});
            },

            createImage(file) {
                var image = new Image()
                var reader = new FileReader()
                var vm = this

                reader.onload = (e) => {
                    var img = document.createElement("img");
                    img.onload = function (event) {
                        var maxW = 200;
                        var maxH = 200;
                        var canvas = document.createElement("canvas");
                        var iw = img.width;
                        var ih = img.height;
                        var scale = Math.min((maxW / iw), (maxH / ih));
                        var iwScaled = iw * scale;
                        var ihScaled = ih * scale;
                        canvas.width = iwScaled;
                        canvas.height = ihScaled;
                        
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

                        this.fileToUpload = canvas.toDataURL(file.type)
                    }
                    vm.profileLogo = e.target.result; 
                    img.src = e.target.result;
                }
                reader.readAsDataURL(file)
            }
        }
    };
</script>
<style>
.disabled-table {
    color: #b8b799;
    background-color: #EEEDED;
}
</style>