<template>
  <div id="chat-container">
    <chat :analysisRegistered="analysisRegistered"/>
  </div>
</template>

<script>
  import Chat from '@/components/ChatComponents/Chat.vue'

  export default {
    name: 'ChatbotView',
    components: {
      Chat
    },
    props: {
      analysisRegistered: {
        type: Boolean,
        default: false
      }
    }
  }
</script>