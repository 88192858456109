<template>
  	<div class="form-container">
  		<h4>{{ title }}</h4>
  		<form action class="form" @submit.prevent="handleUpdate">
			<div class="col-sm-12">
  				<div class="col-md-6 align-item-center">
  					<div class="form-group">
						<label>Name</label>
				      	<input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name" v-model="repository.name">
				      	<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					    	{{veeErrors.first('name')}}
					    </div>
			    	</div>
	  				<div class="form-group">
						<label>Description</label>
				      	<textarea class="form-input col-sm-12" id="description" rows="6" v-validate="'required'" data-vv-name="description" v-model="repository.description" maxlength="512"></textarea>
				      	<div v-if="submitted && veeErrors.has('description')" class="alert alert-danger">
					    	{{veeErrors.first('description')}}
					    </div>
			    	</div>
  				</div>
  			</div>
  			<div class="form-group col-lg-4 align-item-center">
  				<div class="col-sm-12">
					<div class="col-sm-6">
						<button class="btn btn-secondary btn-cancel" @click="backToRepositories()"><font-awesome-icon icon="ban" /> Cancel</button>
					</div>
					<div class="col-sm-6">
						<input class="btn btn-primary form-submit" type="submit" value="Update">
					</div>
  				</div>
			</div>
			<div v-if="message" class="col-md-6 align-item-center alert" :class="successfull ? 'alert-success' : 'alert-danger'">
		  		{{message}}
		  	</div>
		</form>
	</div>
</template>

<script>
	import RepositoryDataService from "@/services/RepositoryDataService";
	import Repository from '@/models/repository';

	export default {
	    name: "UpdateRepositoryComponent",
	    data() {
		  	return {
		  		title: "Update Repository",
		    	repository: new Repository('','','',this.$store.state.auth.user.id_area),
		        message: "",
			  	successfull: false,
			  	submitted: false
	    	}
		},
		mounted() {
			RepositoryDataService.findOne(this.$route.query.id).then(
				response => {
					this.repository = response.data
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
	  	},
		methods: {
			backToRepositories() {
				this.$router.push({ name: "repositories" });
			},
			handleUpdate() {
	  			this.submitted = true;
	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					RepositoryDataService.update(this.repository).then(
	  						response => {
	  							if (response.status == 200) {
	  								this.successful = true;
	  								this.$router.push({ name: "repositories", params: {repositoryUpdated: true} });
	  							} else {
	  								this.message = response.data.message
	  							}
	  						},
	  						error => {
	  							console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())
					          	this.message = error.response.data.message
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
	  						}
  						);
	  				} else {
	  					this.message = "Please, fill all the fields correctlly";
	  				}
	  			});
	  		}
		}
	};
</script>