export default class BusinessProcess {
  constructor(name, start_date, end_date, candidates, users, hard_skills_active, evaluation_360_active, cv_results_active, id_area) {
    this.name = name;
    this.start_date = start_date;
    this.end_date = end_date;
    this.candidates = candidates;
    this.users = users;
    this.hard_skills_weight = 33;
    this.hard_skills_min_score = 50;
    this.hard_skills_enabled = hard_skills_active;
    this.evaluation_360_weight = 33;
    this.evaluation_360_min_score = 50;
    this.evaluation_360_enabled = evaluation_360_active;
    this.cv_extractor_weight = 34;
    this.cv_extractor_min_score = 20;
    this.cv_extractor_enabled = cv_results_active;
    this.id_profile = id_area;
    this.soft_skills_weight = 0;
    this.soft_skills_min_score = 50;
    this.soft_skills_enabled = false;
  }
}