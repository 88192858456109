<template>
    <div v-if="results.ranking == undefined || results.ranking.length == 0" class="row"
        style="padding-top: 5% !important">
        <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.</h3>
    </div>
    <div v-else class="row">
        <div class="row col-md-12 results-filter-search-title">
            <h3 class="results-text">Results found: {{ results.ranking.length }} people evaluated</h3>
        </div>
        <div class="row col-md-12 results-filter-search-title">
            <h5 class="results-text">{{ infoText }}</h5>
        </div>
        <div class="cards-ranking cards2 col-md-10">
            <div class="row">
                <template v-for="(ranked_candidate, index) in results.ranking">
                    <div class="card candidate" v-bind:key="ranked_candidate.id">
                        <div class="row full-width m-2">
                            <div class="col-md-3">
                                <img v-if="ranked_candidate.userImage == null" :src="userImage" class="profile-img" />
                                <img v-else-if="ranked_candidate.userImage.includes($awsBucketRoute)"
                                    v-bind:src="ranked_candidate.userImage" class="profile-img" />
                                <img v-else v-bind:src="$awsBucketRoute + ranked_candidate.userImage"
                                    class="profile-img" />

                                <button class="btn btn-secondary btn-info"
                                    @click="onResultSelected(ranked_candidate.id_cv)">
                                    More info
                                </button>
                            </div>
                            <div class="col-md-9">
                                <div class="row title-row">
                                    <div class="col-sm-5">
                                        <template
                                            v-if="ranked_candidate.user_data !== null && ranked_candidate.user_data !== undefined">
                                            <h5 class="card-title">{{ ranked_candidate.user_data.name }}
                                                {{ ranked_candidate.user_data.surname }}</h5>
                                        </template>
                                        <template v-else>
                                            <h5 class="card-title">{{ ranked_candidate.name }}</h5>
                                        </template>
                                    </div>
                                    <div class="col-sm-7">
                                        <p class="card-text card-text-email position-ranking-text">Posición:
                                            <b>#{{ (index + 1) }}</b>
                                        </p>
                                    </div>
                                </div>
                                <div class="row top-skill-row">
                                    <div class="col-sm-3">
                                        <p class="card-text-top-skill">
                                            <b>Final score:&nbsp;&nbsp;</b>
                                        </p>
                                    </div>
                                    <div class="col-sm-9">
                                        <div>
                                            <b-progress>
                                                <b-progress-bar
                                                    class="removePadding progress-total general-score-progress"
                                                    :value="parseFloat(ranked_candidate.average_score).toFixed(2)"
                                                    variant="success" key="success" show-progress>
                                                    <span>{{ parseFloat(ranked_candidate.average_score).toFixed(2)
                                                        }}</span>
                                                </b-progress-bar>
                                            </b-progress>
                                        </div>
                                    </div>
                                </div>

                                <div class="row other-skills-row">
                                    <div class="col-sm-3">
                                        <p class="card-text-top-skill">
                                            <b>Scores/Tests:&nbsp;&nbsp;</b>
                                        </p>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4" v-if="ranked_candidate.cv_extractor_enabled">
                                                <b-progress>
                                                    <b-progress-bar
                                                        v-if="parseInt(ranked_candidate.top_profile_score) >= parseInt(results.business_process_data.cv_extractor_min_score)"
                                                        class="removePadding progress-small success-score-progress"
                                                        :value="ranked_candidate.top_profile_score" variant="success"
                                                        key="success" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('P')">
                                                        <span>P: {{ ranked_candidate.top_profile_score }}</span>
                                                    </b-progress-bar>
                                                    <b-progress-bar v-else
                                                        class="removePadding progress-small failed-score-progress"
                                                        :value="ranked_candidate.top_profile_score" variant="warning"
                                                        key="warning" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('P')">
                                                        <span>P: {{ ranked_candidate.top_profile_score }}</span>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </div>
                                            <div class="col-sm-4" v-if="ranked_candidate.hard_skills_enabled">
                                                <b-progress>
                                                    <b-progress-bar
                                                        v-if="parseInt(ranked_candidate.tests_score) >= parseInt(results.business_process_data.hard_skills_min_score)"
                                                        class="removePadding progress-small success-score-progress"
                                                        :value="ranked_candidate.tests_score" variant="success"
                                                        key="success" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('T')">
                                                        <span>T: {{ ranked_candidate.tests_score }}</span>
                                                    </b-progress-bar>
                                                    <b-progress-bar v-else
                                                        class="removePadding progress-small failed-score-progress"
                                                        :value="ranked_candidate.tests_score" variant="warning"
                                                        key="warning" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('T')">
                                                        <span>T: {{ ranked_candidate.tests_score }}</span>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </div>
                                            <div class="col-sm-4" v-if="ranked_candidate.evaluation_360_enabled">
                                                <b-progress>
                                                    <b-progress-bar
                                                        v-if="parseInt(ranked_candidate.ev_360_score) >= parseInt(results.business_process_data.evaluation_360_min_score)"
                                                        class="removePadding progress-small success-score-progress"
                                                        :value="ranked_candidate.ev_360_score" variant="success"
                                                        key="success" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('G')">
                                                        <span>G: {{ ranked_candidate.ev_360_score }}</span>
                                                    </b-progress-bar>
                                                    <b-progress-bar v-else
                                                        class="removePadding progress-small failed-score-progress"
                                                        :value="ranked_candidate.ev_360_score" variant="warning"
                                                        key="warning" show-progress v-b-tooltip.hover
                                                        :title="getNameOfSubmodule('G')">
                                                        <span>G: {{ ranked_candidate.ev_360_score }}</span>
                                                    </b-progress-bar>
                                                </b-progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessProcessService from '@/services/BusinessProcessService';
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService'; import BusinessProcess from '../../models/business_process';

export default {
    name: 'Ranking',
    data() {
        return {
            userImage: "http://" + location.host + '/images/icons/user.png',
            results: [],
            logos: {},
            nameLevels: {},
            technologies: null,
            infoText: 'Tests Performed:'
        }
    },
    props: {
        businessProcessId: String
    },
    mounted() {
        const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
        ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
            response => {
                if (response.status == 200) {
                    this.technologies = response.data
                    this.technologies.forEach((tech) => {
                        this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
                    });

                    this.getRankingResults()
                }
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        getRankingResults() {
            BusinessProcessService.ranking(this.businessProcessId).then(
                response => {
                    this.results = response.data;

                    this.results.ranking.forEach(candidate => {
                        candidate.cv_extractor_enabled = this.results.business_process_data.cv_extractor_enabled
                        candidate.hard_skills_enabled = this.results.business_process_data.hard_skills_enabled
                        candidate.soft_skills_enabled = this.results.business_process_data.soft_skills_enabled
                        candidate.evaluation_360_enabled = this.results.business_process_data.evaluation_360_enabled
                    })

                    let tmpArray = []

                    if (this.results.business_process_data.cv_extractor_enabled) tmpArray.push('Profile adjustment (P)')
                    if (this.results.business_process_data.hard_skills_enabled) tmpArray.push('Test (T)')
                    if (this.results.business_process_data.evaluation_360_enabled) tmpArray.push('360 (G)')

                    if (tmpArray.length === 0) {
                        this.infoText = this.infoText + ' No modules activated'
                    } else {
                        for (let i = 0; i < tmpArray.length; i++) {
                            if (i === (tmpArray.length - 1)) {
                                this.infoText = this.infoText + ' ' + tmpArray[i]
                            } else if (i === (tmpArray.length - 2)) {
                                this.infoText = this.infoText + ' ' + tmpArray[i] + ' y'
                            } else {
                                this.infoText = this.infoText + ' ' + tmpArray[i] + ','
                            }
                        }
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getNameOfSubmodule(key) {
            if (key == 'P') {
                return "Profile CV Results Score";
            } else if (key == 'T') {
                return "Hard Skills Tests Score";
            } else if (key == 'S') {
                return "Soft Skills Score";
            } else if (key == 'G') {
                return "BEI/360 Evaluation Score";
            }
        },
        onResultSelected(id) {
            let routeData = this.$router.resolve({ name: 'profile-analysis', query: { id: id, previousPage: 'businessProcessRanking', businessProcess: this.$route.query.id_business_process } });
            window.open(routeData.href, '_blank');
        }
    }
};
</script>

<style type="text/css" scoped>
.general-score-progress {
    background: #5ABBD1 !important;
    color: black;
}

.failed-score-progress {
    background: #FF9724 !important;
    color: black;
}

.success-score-progress {
    background: #AFE89E !important;
    color: black;
}

.item-body {
    width: 100%;
}

.result-list-item {
    transition: all 1s;
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0.75rem !important;
}

.result-list-enter,
.result-list-leave-to {
    max-height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: hidden;
}

.result-list-enter-to,
.result-list-leave {
    max-height: 80px;
}

.result-table-item {
    transition: all 1s;
}

.result-table-item>* {
    transition: all 1s;
    overflow: hidden;
}

.result-table-enter,
.result-table-leave-to {
    line-height: 0;
}

.result-table-enter>*,
.result-table-leave-to>* {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.result-table-enter-to,
.result-table-leave {
    line-height: 1.5;
}

.profile-img {
    margin: 8%;
    height: 7em;
    border-radius: 50%;
}

.title-row {
    margin-bottom: 4%;
}

.top-skill-row {
    margin-bottom: 0%;
}

.card-body {
    padding-top: 0;
}

.card-text-email {
    width: auto;
    float: left;
    margin-top: 1px;
}

.card-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.card-text-top-skill,
.card-text-other-skills {
    width: auto;
    float: left;
    margin-bottom: 0% !important;
}

.progress {
    margin-bottom: 5% !important;
    width: 100%;
}

.other-skills-div {
    margin-bottom: 0% !important;
    width: 50%;
}

.candidate {
    margin-bottom: 2%;
    width: 100%;
    cursor: default;
    margin: 1%;
}

.btn-info {
    width: 100%;
}

.results-text {
    text-align: center;
}

.position-ranking-text {
    width: 100%;
    text-align: right;
}

.white-color {
    color: white !important;
}

.buttons-div {
    margin: 0 auto !important;
    margin-top: 1% !important;
}

.button-style {
    width: auto;
    margin: 0 auto;
}

.filters-row {
    margin: 0 auto;
}

.filters-text {
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.info-text {
    text-align: center;
    margin-bottom: 0% !important;
    margin-left: 5% !important;
    margin-top: 5px !important;
    font-size: 20px;
    float: left;
    width: auto;
}

.auto-width {
    width: auto;
}

.progress {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.left-filters-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-button-title {
    float: left;
    width: auto;
    margin-top: 2%;
}

.radio-button-input {
    margin-left: 2% !important;
}

.radio-button-label {
    float: left;
    margin-left: 2px;
}
</style>