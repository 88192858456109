<template>
  <div class="col-sm-12">
  	<div class="links-container">
  		<a class="btn btn-secondary float-right" @click="backToConfiguration()"><font-awesome-icon icon="arrow-left" /> Back to Configuration</a>
  	</div>
    <edit-technology-configuration/>
  </div>
</template>

<script>
	import EditTechnologyConfiguration from "@/components/AnalysisComponents/EditTechnologyConfiguration.vue";

	export default {
    name: 'EditTechnologyConfigurationView',
    components: {
      EditTechnologyConfiguration
    },
    props: {
    },
    methods: {
      backToConfiguration() {
          this.$router.push({ path: 'config-profile'})
      }
    }
	};
</script>