<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link v-if="from.path != '/softskills-evaluation-result-user'" :to="{ path: '/evaluation-360' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to BEI/360
					Evaluation</router-link>
			</div>

			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/evaluation-360' }" class="breadcumb"> BEI/360 Evaluation > </router-link>
				<router-link :to="{ path: '/softskills-configuration-perform-evaluation' }" class="final-breadcumb">
					Evaluation Results</router-link>
			</div>
		</div>

		<div class="col-sm-12 px-4 col-centered">
			<evaluation-results />
		</div>
	</div>
</template>

<script>
import EvaluationResults from "@/components/SoftSkillsComponent/EvaluationResults.vue";

export default {
	data() {
		return {
			from: null
		}
	},
	components: {
		EvaluationResults
	},
	props: {
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
		close() {
			document.getElementById("message").style.display = "none"
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from;
		});
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}
</style>