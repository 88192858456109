import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'company/';

class CompanyDataService {

  findAll() {
    return http.get(API_URL_PATH + 'findAll', { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = [];
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })
    
    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  create(name, cvModule, hardSkillsModule, evaluation360Module, softSkillsModule, interviewModule) {
    const newCompany = {
      name: name,
      cv_module_enabled: cvModule,
      hard_skills_module_enabled: hardSkillsModule,
      evaluation_360_module_enabled: evaluation360Module,
      soft_skills_module_enabled: softSkillsModule,
      videointerviews_module_enabled: interviewModule
    }

    return http.post(API_URL_PATH + 'create', { company: newCompany }, { headers: authHeader() });
  }

  update(company) {
    const updatedCompany = {
      id: company.id,
      name: company.name,
      cv_module_enabled: company.cv_module_enabled,
      hard_skills_module_enabled: company.hard_skills_module_enabled,
      evaluation_360_module_enabled: company.evaluation_360_module_enabled,
      soft_skills_module_enabled: company.soft_skills_module_enabled,
      videointerviews_module_enabled: company.videointerviews_module_enabled
    }

    return http.put(API_URL_PATH + 'update', { company: updatedCompany }, { headers: authHeader() });
  }

  createCloneRelationship(referenceCompany, targetCompany) {
    return http.post(API_URL_PATH + 'createCloneRelationship', { id_reference_company: referenceCompany.id, id_target_company: targetCompany.id }, { headers: authHeader() });
  }

  cloneBasics(referenceCompany, targetCompany) {
    return http.post(API_URL_PATH + 'cloneBasics', { id_reference_company: referenceCompany.id, id_target_company: targetCompany.id }, { headers: authHeader() });
  }

  cloneQuestionsRepository(referenceCompany, targetCompany) {
    return http.post(API_URL_PATH + 'cloneQuestionsRepository', { id_reference_company: referenceCompany.id, id_target_company: targetCompany.id }, { headers: authHeader() });
  }

  getCloneRelationship(referenceCompany, targetCompany) {
    return http.get(API_URL_PATH + 'getCloneRelationship?id_reference_company='+ referenceCompany.id + '&id_target_company=' + targetCompany.id, { headers: authHeader() });
  }

  removeBasicsClonation(targetCompany) {
    return http.delete(API_URL_PATH + 'removeBasicsClonation?id_target_company='+targetCompany.id, { headers: authHeader() });
  }

  removeQuestionsClonation(targetCompany) {
    return http.delete(API_URL_PATH + 'removeQuestionsClonation?id_target_company='+targetCompany.id, { headers: authHeader() });
  }

  createAccountSettings(company_settings) {
    return http.post(API_URL_PATH + 'createAccountSettings', { company_settings: company_settings }, { headers: authHeader() });
  }

  updateAccountSettings(company_settings) {
    return http.put(API_URL_PATH + 'updateAccountSettings', { company_settings: company_settings }, { headers: authHeader() });
  }

  getAccountSettings(id_company){
    return http.get(API_URL_PATH + 'getAccountSettings?id_company=' +id_company, { headers: authHeader() } );
  }

  uploadLogo(imageFile, id_company, token, onUploadImage) {
    var formData = new FormData();
    formData.append("file", imageFile);
    formData.append("id_company", id_company);

    return http.put(API_URL_PATH + 'uploadLogo', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'x-access-token': token
      },
      onUploadImage
    });
  }

  getLogo (id_company) {
    return http.get(API_URL_PATH + 'getLogo?id_company=' + id_company, { headers: authHeader() } );
  }

}

export default new CompanyDataService();