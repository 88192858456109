<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:chartTitle = "graphTitle"
				:data="data"></barr-chart>
		</div>
	</div>
</template>

<script>
  import ReportsDataService from '@/services/ReportsDataService';
  import BarrChart from "@/components/Others/Charts/BarrChart.vue";

  export default {
    name: 'ExamsCVsByCompany',
    data() {
    	return {
    		chartId: "chartCVsGraphByCompany",
	    	data: [],
			temp: [],
			graphTitle: "",
	    	xAxesCategory: "name",
	    	yAxesValue: "total_cvs",
	    	xAxesTitle: "Area",
	    	yAxesTitle: "Number of CVs analysed",
			graphLoaded: false,
			graphLoading: false
    	}
    },
    components: {
    	BarrChart
    },
	methods: {
		async fetchDataGeneral() {
    		await ReportsDataService.analyzedCVsByCompanies().then(
				response => {
					if (response.status == 200) {
						this.data = response.data

						this.xAxesTitle = "Companies";

						this.graphLoaded = this.graphLoaded ? false : true
						this.graphLoading = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.graphLoading = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
    	},
    	async fetchDataCompany() {
    		await ReportsDataService.analyzedCVsByAreas(this.$store.state.auth.user.id_company).then(
				response => {
					if (response.status == 200) {
						this.data = response.data
						this.graphLoaded = this.graphLoaded ? false : true
						this.graphLoading = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.graphLoading = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
    	}
    },
	mounted() {
		this.graphLoading = true;

		if (this.$store.state.auth.user.role.name == 'superadmin'){
			this.isSuperAdmin = true;
			this.graphTitle = "User CVs analysed per company";
			
			this.fetchDataGeneral();
		} else {
			this.isSuperAdmin = false;
			this.graphTitle = "User CVs analysed per area";

			this.fetchDataCompany();
		}
  	}
  };
</script>