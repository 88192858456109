<template>
	<div class="table-container technologiesStyle">
		<template>
			<div class="row">
				<div class="col-sm-12">
					<div class="row">
						<template>
							<div class="row" style="margin-top: 1%;">
								<div class="col-sm-12" style="text-align: justify">
									<h6>Description:</h6>
								</div>
							</div>
							<div class="row" style="margin-bottom: 2%;">
								<div class="col-sm-12" style="text-align: justify">
									{{ description }}
								</div>
							</div>
							<div class="row">
								<div class="col-sm-2">
									<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
										aria-controls="selectionUsersList"></b-pagination>
								</div>
								<div class="col-sm-7">
									<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
										:options="pageOptions"></b-form-select>
								</div>
								<div class="col-sm-3">
									<b-form-input id="filter-input" v-model="filter" type="search"
										:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
								</div>
							</div>

							<b-table ref="selectionUsersTable" id="selectionUsersList" :per-page="perPage"
								:current-page="currentPage" :fields="headers" :items="selectionUsers.users_evaluations"
								:filter="filter" responsive="sm" style="cursor: default !important;">
								<template #cell(avatar)="data">
									<img v-if="data.item.user.avatar == null" :src="userImage" height="30em"
										class="analysis-card-avatar--circle" />
									<img v-else-if="data.item.user.avatar.includes($awsBucketRoute)"
										v-bind:src="data.item.user.avatar" height="30em"
										class="analysis-card-avatar--circle" />
									<img v-else v-bind:src="$awsBucketRoute + data.item.user.avatar" height="30em"
										class="analysis-card-avatar--circle" />
								</template>
								<template #cell(name)="data">
									{{ data.item.user.name }}
								</template>
								<template #cell(surname)="data">
									{{ data.item.user.surname }}
								</template>
								<template #cell(email)="data">
									{{ data.item.user.email }}
								</template>
								<template #cell(role)="data">
									{{ data.item.user.role.name.charAt(0).toUpperCase() +
										data.item.user.role.name.slice(1) }}
								</template>
								<template #cell(user_score)="data">
									<div v-if="data.item.user_score != null">
										{{ parseFloat(data.item.user_score).toFixed(2) }}
									</div>
									<div v-else>
										-
									</div>
								</template>
								<template #cell(options)="data" style="cursor: pointer !important;">
									<router-link v-if="data.item.user_score == null"
										:to="{ path: '/softskills-configuration-perform-evaluation?id=' + id_evaluation + '&id_evaluator=' + userLoggedIn.id + '&id_user=' + data.item.user.id + '&evaluation_process=' + evaluationProcessId }"
										v-b-tooltip.hover title="Complete evaluation" class="btn btn-secondary">
										<font-awesome-icon icon="play" />
									</router-link>
									<router-link v-else
										:to="{ path: '/softskills-configuration-evaluation-results?id=' + id_evaluation + '&id_evaluator=' + userLoggedIn.id + '&id_user=' + data.item.user.id + '&evaluation_process=' + evaluationProcessId }"
										v-b-tooltip.hover title="See evaluation result" class="btn btn-secondary">
										<font-awesome-icon icon="eye" />
									</router-link>
								</template>
							</b-table>
						</template>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'SelectionUsersEvalutedProgress',
	data() {
		return {
			headers: [
				{ key: 'avatar', label: 'Avatar', tdClass: 'tdSmall' },
				{ key: 'name', label: 'Name', sortable: true, tdClass: 'tdSmall' },
				{ key: 'surname', label: 'Surname', sortable: true, tdClass: 'tdMedium' },
				{ key: 'email', label: 'Email', tdClass: 'tdMedium' },
				{ key: 'role', label: 'Role', tdClass: 'tdSmall' },
				{ key: 'user_score', label: 'Score', sortable: true, tdClass: 'tdSmall' },
				{ key: 'options', label: 'Options', tdClass: 'tdSmall' },
			],
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			userImage: "http://" + location.host + '/images/icons/user.png',
			id_evaluation: this.selectionUsers.id,
			userLoggedIn: undefined
		}
	},
	components: {},
	props: {
		selectionUsers: Object,
		description: String,
		evaluationProcessId: Number
	},
	computed: {
		rows() {
			return this.selectionUsers.users_evaluations.length
		}
	},
	mounted() {
		this.userLoggedIn = JSON.parse(localStorage.getItem('user'));

		console.log('this.userLoggedIn: ', this.userLoggedIn)
	}
};
</script>

<style type="text/css" scoped>
.module-avatar {
	width: 35%;
	margin: 10px;
	border: 5px solid black;
	border-radius: 500px;
	-webkit-border-radius: 500px;
	-moz-border-radius: 500px;
}

.disabled-module {
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.align-text-module {
	text-align: center;
}
</style>