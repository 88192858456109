<template>
	<div class="dashboard">
		<div v-if="!showWelcome" class="welcome justify-content-center">
			<p class="welcome-message">{{$t('dashboards.general.welcome-back')}} {{currentUser.username}}!</p>
		</div>
		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName"
						:cardImage="card.image"
						:cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';

	export default {
		name: 'ExaminerDashboardView',
		components: {
			MenuCard,
			appFooter: FooterMini
		},
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			cards() {
				return [
					{
						"routeName": "/companies",
						"image": "/images/icons2024/admin_area_suitetools.svg",
						"title": "Companies",
						"description": "Management of registered companies"
					},
					{
						"routeName": "/superadmin-general-reports",
						"image": "images/icons2024/admin_reporting.svg",
						"title": this.$t('dashboards.admin.talent-management.title'),
						"description": this.$t('dashboards.admin.talent-management.description')
					}
				]
			}
		},
		props: {
			showWelcome: Boolean
		},
		mounted() {
		}
	};
</script>

<style scoped>
.welcome {
	background-color: #8080807e;
	padding-left: 0px;
    padding-right: 0px;
	height: 40px;
	font-size: 18px;
	font-weight: bold;
	position: relative;
}

#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.welcome-message {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	padding: 0;
	list-style-type: none;
}

.available-tests {
	color: #4FC0E8;
	font-weight: bold;
}
</style>