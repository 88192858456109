<template>
  <div class="col-sm-7 col-centered">
    <h5 class="changeFont">PRIVACY POLICY</h5>
    <hr class="thicker" style="color: #4FC0E8;height: 3px;">
    <div style="text-align: justify">
      <p>The privacy policy of <a class="linksInBlue" @click="openNewTab('http://00dev00.progradum.com/')">www.00dev00.progradum.com</a> is based on:</p>
      <p>- The exclusive request for essential data to provide portal services.</p>
      <p>- The processing of personal data in accordance with the provisions of Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights, and EU Regulation 2016-679.</p>
      <p>- The automatic collection of the minimum essential technical information to provide a good service, analyzing exclusively the browser being used and its version with the aim of selecting the most suitable style sheet for proper portal visualization, as well as the language and character set of your browser for the same reason.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyPolicyComponent',
    methods:{
      openNewTab (url) {
        window.open(url, "_blank")
      }
    }
  }
</script>
