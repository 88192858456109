export default class Question {
  constructor(id,question_title,question_content,answer_one,
  	answer_two,answer_three,answer_four,answer_one_correct,answer_two_correct,answer_three_correct,
  	answer_four_correct,answer_one_value,answer_two_value,answer_three_value,
    answer_four_value,multiselection,group_name,total_shown,total_responses,total_correct_responses,
  	total_shown_since_modify,total_responses_since_modify,total_correct_responses_since_modify,
    validated,id_language,id_difficulty_level,id_user_owner,id_user_modify, id_area) {
    this.id = id;
  	this.question_title = question_title;
    this.question_content = question_content;
  	this.answer_one = answer_one;
  	this.answer_two = answer_two;
  	this.answer_three = answer_three;
  	this.answer_four = answer_four;
  	this.answer_one_correct = answer_one_correct;
  	this.answer_two_correct = answer_two_correct;
  	this.answer_three_correct = answer_three_correct;
  	this.answer_four_correct = answer_four_correct;
    this.answer_one_value = answer_one_value;
    this.answer_two_value = answer_two_value;
    this.answer_three_value = answer_three_value;
    this.answer_four_value = answer_four_value;
    this.multiselection = multiselection;
  	this.group_name = group_name;
  	this.total_shown = total_shown;
  	this.total_responses = total_responses;
  	this.total_correct_responses = total_correct_responses;
  	this.total_shown_since_modify = total_shown_since_modify;
  	this.total_responses_since_modify = total_responses_since_modify;
  	this.total_correct_responses_since_modify = total_correct_responses_since_modify;
    this.validated = validated;
  	this.id_language = id_language;
  	this.id_difficulty_level = id_difficulty_level;
  	this.id_user_owner = id_user_owner;
  	this.id_user_modify = id_user_modify;
    this.id_area = id_area;
  }
}