<template>
	<div>
		<attempt-test/>
	</div>
</template>

<script>
  	import AttemptTest from "@/components/TestComponents/Attempt.vue";

	export default {
	  	name: 'AttemptTestView',
	  	components: {
	      	AttemptTest
	    }
	};
</script>