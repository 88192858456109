<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link :to="{ path: '/evaluation' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" /> Back to Evaluation</router-link>
        <router-link v-if="numAreaProfiles > 0" :to="{ path: '/create-analysis' }"
          class="btn btn-secondary float-right createClass" style="text-transform: uppercase;"><font-awesome-icon icon="upload" />
          {{ $t('dashboards.user.cvs.upload-cv-title') }}</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
        <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
        <router-link :to="{ path: '/user-analysis' }" class="final-breadcumb"> CV Results </router-link>
      </div>
    </div>

    <div id="message" v-if="(analysisRegistered || companyAnalysisRegistered) && !errorMsg"
      class="alert alert-success col-md-12" style="width: auto; color: black !important;">
      {{ $t('dashboards.user.cvs.cv-uploaded-msg') }} <b-button @click="close" class="cross-button"><font-awesome-icon
          class="cross" icon="times" /> </b-button>
    </div>

    <div id="message" v-if="(analysisRegistered || companyAnalysisRegistered) && errorMsg"
      class="alert alert-danger col-md-12" style="width: auto">
      {{ errorMsg }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
      </b-button>
    </div>

    <div class="row col-centered">
      <div class="col-sm-6">
        <company-users-list />
      </div>
      <div class="col-sm-6">
        <company-analyses-list :numAreaProfiles="numAreaProfiles" />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyUsersList from "@/components/AnalysisComponents/CompanyUsersList.vue";
import CompanyAnalysesList from "@/components/AnalysisComponents/CompanyAnalysesList.vue";
import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

export default {
  name: 'CompanyProfileView',
  components: {
    CompanyUsersList,
    CompanyAnalysesList
  },
  data() {
    return {
      numAreaProfiles: -1
    }
  },
  props: {
    analysisRegistered: {
      type: Boolean,
      default: false
    },
    companyAnalysisRegistered: {
      type: Boolean,
      default: false
    },
    companyAnalysisRegistered: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.checkNumberOfAreaProfiles();
  },
  methods: {
    checkNumberOfAreaProfiles() {
      const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
      ProfileConfigurationDataService.findProfileByArea(currentArea).then(
        response => {
          if (response.status == 200) {
            this.numAreaProfiles = response.data.length;
          }
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    close() {
      document.getElementById("message").style.display = "none"
    }
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

</style>
