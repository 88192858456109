<template>
	<div class="dashboard">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link :to="{path: '/talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
			</div>
			<div class="whiteColor">{{ title }}</div>
		</div>

		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName"
						:cardImage="card.image"
						:cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';

	export default {
		name: 'ScoreboardsView',
		data() {
			return {
				title: "Results",
				cards: [
					{
						"routeName": "/user-results",
						"image": "images/icons/registered_users_icon.svg",
						"title": "User",
						"description": "List of results of each user"
					},
					{				  		
						"routeName": "/exam-results-list",
						"image": "images/icons/questions_icon_section.svg",
						"title": "Exam",
						"description": "List of reports of each exam"
					},
				]
			}
		},
		components: {
			MenuCard,
			appFooter: FooterMini
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>