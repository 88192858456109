<template>
	<div class="dashboard">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link :to="{path: '/talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
				<router-link :to="{ path: '/admin/reports-examiner' }" class="final-breadcumb"> Examiner Reports </router-link>
			</div>
		</div>

		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName"
						:cardImage="card.image"
						:cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';
	import i18n from '@/i18n';

	export default {
		name: 'SubmenuTalentManagementAdmin',
		data() {
			return {
				title: i18n.t('dashboards.general.reporting').toString() + " > Examiner reports",
				cards: []
			}
		},
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
		components: {
			MenuCard,
			appFooter: FooterMini
		},
		mounted () {
			if (this.currentUser.company.hard_skills_module_enabled) this.cards.push({
				"routeName": "/examiner-exams-reports",
				"image": "/images/icons2024/admin_generalreportsexams.svg",
				"title": "Exams",
				"description": "List of reports based on existing exams"
			})
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>