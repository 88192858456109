<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/companies'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Companies</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/companies' }" class="breadcumb"> Companies > </router-link>
        <router-link :to="{ path: '/create-company' }" class="final-breadcumb"> Create Company </router-link>
      </div>
    </div>
    
    <div class="col-sm-12 col-centered">	
      <create-company/>
    </div>
    
    <b-modal id="cancel-modal" ref="cancel-modal" title="Cancel-modal" hide-footer>
      <template #modal-title>
        ¿Do you really want to exit?
      </template>
      <div>
        <p>If you exit now, changes will be lost. </p>
      </div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('cancel-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="backToCompanies()" value="Yes">
	  </b-modal>
  </div>
</template>

<script>
	import CreateCompany from "@/components/CompaniesComponents/Create.vue";

	export default {
    name: 'CreateCompanyView',
    components: {
      CreateCompany
    },
    props: {
    },
    methods: {
      backToCompanies() {
        this.$router.push({ path: 'companies'})
      }
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>