import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'area/';

class AreasDataService {
  
  create(area) {
    const newArea = {
      name: area.name,
      description: area.description,
      id_company: area.id_company
    }
    return http.post(API_URL_PATH + 'create', { area: newArea }, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  update(area) {
    const udpateArea = {
      id: area.id,
      name: area.name,
      description: area.description,
      id_company: area.id_company,
    }
    return http.put(API_URL_PATH + 'update', { area: udpateArea }, { headers: authHeader() });
  }

  findUsers(idArea) {
    return http.get(API_URL_PATH + 'findUsers?id_area='+idArea, { headers: authHeader() });
  }

  findUsersCandidate(idArea) {
    return http.get(API_URL_PATH + 'findUsersCandidate?id_area='+idArea, { headers: authHeader() });
  }

  findUsersAndCandidatesForBusinessProcess(idArea) {
    return http.get(API_URL_PATH + 'findUsersAndCandidatesForBusinessProcess?id_area='+idArea, { headers: authHeader() });
  }

  findByCompany(idCompany) {
    return http.get(API_URL_PATH + 'findByCompany?id_company='+idCompany, { headers: authHeader() });
  }

  addUsersToArea(idArea, userIds) {
    const body = {
      ids: userIds,
      id_area: idArea
    }
    return http.post(API_URL_PATH + 'addUsersToArea', body, { headers: authHeader() });
  }

  addUserToAreas(idUser, areasIds) {
    const body = {
      ids: areasIds,
      id_user: idUser
    }
    return http.post(API_URL_PATH + 'addUserToAreas', body, { headers: authHeader() });
  }

  removeUsersFromArea(idArea, userIds) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, userIds);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'removeUsersFromArea?id_area='+ idArea + "&" + params, { headers: authHeader() });
  }

  removeUserFromAreas(idUser, areasIds) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, areasIds);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'removeUserFromAreas?id_user='+ idUser + "&" + params, { headers: authHeader() });
  }

  getData(idUser, idArea) {
    return http.get(API_URL_PATH + 'getData?id_user=' + idUser + '&id_area=' + idArea, { headers: authHeader() });
  }

  registerAccess(idUser, idArea) {
    return http.put(API_URL_PATH + 'registerAccess', { id_user: idUser, id_area: idArea }, { headers: authHeader() });
  }
}

export default new AreasDataService();