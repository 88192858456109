<template>
	<div>
		<h4 v-if="selectedAreaName != null" class="reports-title">Registered certifications per profile in {{selectedAreaName}}</h4>
		<h4 v-else class="reports-title">Registered certifications per profile by areas</h4>
		<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option value="">Select an area</option>
      		<option v-for="area in areas" :value="area.id">{{area.name}}</option>
    	</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId" 
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:data="data"
				:tooltipNoDataMsg="tooltipNoDataMsg"></barr-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import BarrChart from "@/components/Others/Charts/BarrChart.vue"

	export default {
		name: 'RegisteredCertificationsByAreaProfiles',
		data() {
			return {
				chartId: "chartRegisteredCertificationsByAreasProfiles",
				data: [],
					temp: [],
				xAxesCategory: "name",
				yAxesValue: "total_certifications",
				xAxesTitle: "Profile Name",
				yAxesTitle: "Number of Certifications",
					graphLoaded: false,
					graphLoading: false,
					selectedArea: "",
					selectedAreaName: null,
					tooltipNoDataMsg: "Please, select an area."
			}
		},
		components: {
			BarrChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea))
						this.selectedAreaName = area.name
				});

				this.graphLoading = true;

				this.fetchData();
			},
			async fetchData() {
				await ReportsDataService.registeredCertificationsByAreaProfiles(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.graphLoaded = this.graphLoaded ? false : true

							if (Object.values(this.data).every(e => e.total_certifications === 0))
								this.data = [];

							this.tooltipNoDataMsg = this.data.length > 0 ? this.tooltipNoDataMsg : "There are no certifications in this area"; 
							this.graphLoading = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>