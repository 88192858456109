<template>
  	<div class="dashboard">
		<div class="secondNavbar"> 
			<div  class="links-container">
				<router-link v-if="isAdmin" :to="{path: '/admin'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
				<router-link v-if="isTM" :to="{path: '/talent_manager'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Home</router-link>
			</div>
			<div class="whiteColor">{{ title }}</div>
		</div>

		<div class="cards w-75">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName"
						:cardImage="card.image"
						:cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import FooterMini from "@/components/FooterMini.vue";
	import MenuCard from '@/components/MenuCard';
	import i18n from "@/i18n";

	export default {
		name: 'SubmenuTalentManagementAdmin',
		data() {
			return {
				title: i18n.t('dashboards.general.reporting').toString(),
				cards: []
			}
		},
		components: {
			MenuCard,
			appFooter: FooterMini
		},
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			},
			isTM(){
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'talent_manager') {
						return true;
					}
				}
				return false
			},
		},
		mounted () {
			this.cards.push({
				"routeName": "/reports-admin",
				"image": "images/icons2024/admin_generalreports.svg",
				"title": "General reports",
				"description": "General statistics and reports"
			})
			
			if (this.currentUser.company.hard_skills_module_enabled) this.cards.push({
				"routeName": "/admin-reports-examiner",
				"image": "images/icons2024/admin_examinerreports.svg",
				"title": "Examiner reports",
				"description": "List of reports of examiner role"
			})
			
			if (this.currentUser.company.hard_skills_module_enabled || this.currentUser.company.cv_module_enabled) this.cards.push({
				"routeName": "/admin-reports-validator",
				"image": "images/icons2024/admin_validatorreports.svg",
				"title": "Validator reports",
				"description": "List of reports of questions validator role "
			})
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>