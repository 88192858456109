import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'difficulty_level/';

class DifficultyDataService {

  getDifficultyBoard() {
    return http.get(API_URL_PATH + 'difficultyBoard', { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = [];
    Array.prototype.push.apply(idsTmp, ids);
  
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
  
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  create(difficulty) {
    const newDifficulty = {name: difficulty.name, level: parseInt(difficulty.level), id_area: difficulty.id_area, color: difficulty.color}
    return http.post(API_URL_PATH + 'create', { difficulty_level: newDifficulty }, { headers: authHeader() });
  }

  update(difficulty) {
    const updatedDifficulty = {name: difficulty.name, level: parseInt(difficulty.level), id_area: difficulty.id_area, color: difficulty.color}
    return http.put(API_URL_PATH + 'update', { id_difficulty:difficulty.id, difficulty_level: updatedDifficulty }, { headers: authHeader() });
  }

}

export default new DifficultyDataService();