<template>
  <div id="app">
    <nav class="row navbar navbar-expand navbar-dark"
      v-if="currentRouteName != 'chat' && currentRouteName != 'interview' && currentRouteName != 'attemptTestCandidate'"
      v-bind:class="currentUser ? 'bg-white logged-navbar' : ''" style="padding-bottom: 0px; padding-top: 0px;">

      <router-link v-if="!currentUser" to="/" class="float-left"><img class="top-menu"
          src="images/progradum_blanco_simple.svg"></router-link>

      <div v-if="!currentUser" class="row navbar-nav nav-bar-no-loggin px-0">
        <li class="nav-item-home">
          <router-link to="/" class="primary-btn nav-link home-buttons" style="text-decoration:none;">HOME</router-link>
        </li>
        <li class="nav-item-home">
          <router-link to="/login" class="primary-btn nav-link home-buttons"
            style="text-decoration:none;">LOGIN</router-link>
        </li>

        <li class="nav-item-home">
          <router-link to="/privacy-policy" class="primary-btn nav-link home-buttons"
            style="text-decoration:none;">PRIVACY POLICY</router-link>
        </li>
        <li class="nav-item-home">
          <router-link to="/cookies-policy" class="primary-btn nav-link home-buttons"
            style="text-decoration:none;">COOKIES POLICY</router-link>
        </li>
        <li class="nav-item-home">
          <router-link to="/data-protection-policy" class="primary-btn nav-link home-buttons"
            style="text-decoration:none;">DATA PROTECTION POLICY</router-link>
        </li>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <!-- ADMIN ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'admin'" class="row navbar-nav"
        style="width: 100% !important;">
        <a class="col-xl-1 col-lg-12 navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/admin">
            <img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt="" height="70em" />
          </router-link>
        </a>

        <div class="col-md-7 collapse navbar-collapse" id="navbarNav">
          <ul class="row w-100 navbar-nav" v-if="isAreaActive">
            <li class="col-md-4 mx-0  nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/admin" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.home-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="col-md-4 mx-0 nav-item-user">
              <a class="nav-link" href @click.prevent="showSubmenu">
                <router-link to="/admin" class="nav-link" style="text-decoration:none;">
                  {{ $t('dashboards.general.tools-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="col-md-4 mx-0 nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.help-menu-title') }}
                </router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" block class="col-xl-1 col-lg-12 mx-0 my-3 primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/admin-configuration-areas" style="text-decoration: none; color: #13283B">
              <font-awesome-icon icon="plus" /> {{ $t('dashboards.general.create-area') }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item v-if="isAreaActive" @click="changeBackToUrl('admin-configuration');exitArea(area)">
            <font-awesome-icon icon="cog" /> {{ $t('dashboards.general.go-to-company-configuration') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="isAreaActive" @click="changeBackToUrl('talent-management');exitArea(area)">
            <font-awesome-icon icon="chart-bar" /> {{ $t('dashboards.general.go-to-reporting') }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown"
          class="col-xl-1 col-lg-12 mx-0 my-3 primary-btn-user nav-link user-dropdown dropdown-dark"
          :text="currentUser.username" style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/admin-profile-settings"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="cog" />
              {{ $t('dashboards.general.settings-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;"
          class="col-md-1 dropdown-dark">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'admin'" v-show="true"
        class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <div v-if="$route.path === '/modules'">
                <router-link to="/modules" class="suite-tools-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
              <div v-else>
                <router-link to="/modules" class="nav-link-user-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
            </li>

            <li class="nav-item-user-submenu"
              v-if="currentUser.company.cv_module_enabled || currentUser.company.hard_skills_module_enabled || currentUser.company.evaluation_360_module_enabled">
              <div v-if="$route.path == '/settings'">
                <router-link to="/settings" class="suite-tools-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
              <div v-else>
                <router-link to="/settings" class="nav-link-user-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- USER ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'user'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/user"><img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt=""
              height="70em" /></router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/user" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.home-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/about" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.about-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">{{ $t('dashboards.general.tools-menu-title') }}</a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.help-menu-title') }}</router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown v-if="showAreas" id="areaDropdown" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown" :text="currentUser.username"
          style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/user-profile-settings"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="cog" />
              {{ $t('dashboards.general.settings-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'user' && currentRouteName != 'chat'"
        v-show="true" class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu" v-if="currentUser.company.hard_skills_module_enabled">
              <router-link to="/available-tests" class="nav-link-user-submenu"
                style="text-decoration:none;">{{ $t('dashboards.user.tests.title') }}</router-link>
            </li>
            <li class="nav-item-user-submenu" v-if="currentUser.company.cv_module_enabled">
              <router-link to="/analyses" class="nav-link-user-submenu"
                style="text-decoration:none;">{{ $t('dashboards.user.cvs.title') }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- EXAMINER ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'examiner'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/examiner"><img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt=""
              height="70em" /></router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/examiner" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.home-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/about" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.about-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">{{ $t('dashboards.general.tools-menu-title') }}</a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.help-menu-title') }}</router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" v-if="showAreas" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown" :text="currentUser.username"
          style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><font-awesome-icon icon="cog" />
            {{ $t('dashboards.general.settings-menu-title') }}</b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'examiner' && currentRouteName != 'chat'"
        v-show="true" class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu" v-if="currentUser.company.hard_skills_module_enabled">
              <router-link to="/tests" class="nav-link-user-submenu" style="text-decoration:none;">Hard
                Skills</router-link>
            </li>
            <li class="nav-item-user-submenu">
              <router-link to="/examiner-talent-management" class="nav-link-user-submenu"
                style="text-decoration:none;">{{
        $t('dashboards.general.reporting') }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- QUESTIONS VALIDATOR ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'validator'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/questions-validator"><img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo"
              alt="" height="70em" /></router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/questions-validator" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.home-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/about" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.about-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">{{ $t('dashboards.general.tools-menu-title') }}</a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.help-menu-title') }}</router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" v-if="showAreas" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown" :text="currentUser.username"
          style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><font-awesome-icon icon="cog" />
            {{ $t('dashboards.general.settings-menu-title') }}</b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'validator' && currentRouteName != 'chat'"
        v-show="true" class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <router-link to="/questions" class="nav-link-user-submenu"
                style="text-decoration:none;">Questions</router-link>
            </li>
            <li class="nav-item-user-submenu">
              <router-link to="/questions-validator-talent-management" class="nav-link-user-submenu"
                style="text-decoration:none;">{{ $t('dashboards.general.reporting') }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- CERTIFICATIONS VALIDATOR ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'certifier'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/questions-validator"><img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo"
              alt="" height="70em" /></router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/certifications-validator" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.home-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/about" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.about-menu-title') }}</router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">{{ $t('dashboards.general.tools-menu-title') }}</a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link"
                  style="text-decoration:none;">{{ $t('dashboards.general.help-menu-title') }}</router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" v-if="showAreas" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown" :text="currentUser.username"
          style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><font-awesome-icon icon="cog" />
            {{ $t('dashboards.general.settings-menu-title') }}</b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'certifier' && currentRouteName != 'chat'"
        v-show="true" class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <router-link to="/certifications" class="nav-link-user-submenu"
                style="text-decoration:none;">Questions</router-link>
            </li>
            <li class="nav-item-user-submenu">
              <router-link to="/certifications-validator-talent-management" class="nav-link-user-submenu"
                style="text-decoration:none;">{{ $t('dashboards.general.reporting') }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- SUPERADIMN ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'superadmin'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/superadmin">
            <img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt="" height="70em" />
          </router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/superadmin" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.home-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="showSubmenu">
                <router-link to="/superadmin" class="nav-link" style="text-decoration:none;">
                  {{ $t('dashboards.general.tools-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.help-menu-title') }}
                </router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown dropdown-dark"
          :text="currentUser.username" style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;" class="dropdown-dark">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'superadmin'" v-show="true"
        class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <div v-if="$route.path === '/companies'">
                <router-link to="/companies" class="suite-tools-submenu" style="text-decoration:none;"> Companies
                </router-link>
              </div>
              <div v-else>
                <router-link to="/companies" class="nav-link-user-submenu" style="text-decoration:none;"> Companies
                </router-link>
              </div>
            </li>

            <li class="nav-item-user-submenu">
              <div v-if="$route.path == '/superadmin-general-reports'">
                <router-link to="/superadmin-general-reports" class="suite-tools-submenu" style="text-decoration:none;">
                  {{
        $t('dashboards.general.reporting') }} </router-link>
              </div>
              <div v-else>
                <router-link to="/superadmin-general-reports" class="nav-link-user-submenu"
                  style="text-decoration:none;"> {{
        $t('dashboards.general.reporting') }} </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- TALENT MANAGER ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'talent_manager'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/talent_manager">
            <img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt="" height="70em" />
          </router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav" v-if="isAreaActive">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/talent_manager" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.home-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">
                  <router-link to="/admin" class="nav-link" style="text-decoration:none;">
                    {{ $t('dashboards.general.tools-menu-title') }}
                  </router-link>
                </a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.help-menu-title') }}
                </router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
          <b-dropdown-item v-if="isAreaActive" @click="changeBackToUrl('admin-configuration');exitArea(area)">
            <font-awesome-icon icon="cog" /> {{ $t('dashboards.general.go-to-company-configuration') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="isAreaActive" @click="changeBackToUrl('talent-management');exitArea(area)">
            <font-awesome-icon icon="chart-bar" /> {{ $t('dashboards.general.go-to-reporting') }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown dropdown-dark"
          :text="currentUser.username" style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/admin-profile-settings"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="cog" />
              {{ $t('dashboards.general.settings-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;" class="dropdown-dark">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'talent_manager'" v-show="true"
        class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <div v-if="$route.path === '/modules'">
                <router-link to="/modules" class="suite-tools-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
              <div v-else>
                <router-link to="/modules" class="nav-link-user-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
            </li>

            <li class="nav-item-user-submenu">
              <div v-if="$route.path == '/settings'">
                <router-link to="/settings" class="suite-tools-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
              <div v-else>
                <router-link to="/settings" class="nav-link-user-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--  MANAGER ROLE MENUS -->
      <div v-if="currentUser && currentUser.role.name === 'manager'" class="navbar-nav ml-auto"
        style="width: 100% !important;">
        <a class="navbar-brand mt-lg-0" href @click.prevent="notShowSubmenu">
          <router-link to="/manager">
            <img class="navbar-brand nav-bar-logo header-image" v-bind:src="logo" alt="" height="70em" />
          </router-link>
        </a>

        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav" v-if="isAreaActive">
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/talent_manager" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.home-menu-title') }}
                </router-link>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link">
                <a class="nav-link" href @click.prevent="showSubmenu">
                  <router-link to="/admin" class="nav-link" style="text-decoration:none;">
                    {{ $t('dashboards.general.tools-menu-title') }}
                  </router-link>
                </a>
              </a>
            </li>
            <li class="nav-item-user">
              <a class="nav-link" href @click.prevent="notShowSubmenu">
                <router-link to="/help" class="nav-link" style="text-decoration:none;">
                {{ $t('dashboards.general.help-menu-title') }}
                </router-link>
              </a>
            </li>
          </ul>
        </div>

        <b-dropdown id="areaDropdown" v-if="showAreas" block class="primary-btn-user dropdown-content">
          <template #button-content>
            <font-awesome-icon icon="users" class="black-color" /> {{ currentAreaName }}
          </template>
          <b-dropdown-item v-for="area in userAreas" :key="area.name" @click="onAreaChange(area)">
            {{ area.name.toUpperCase() }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="userDropdown" class="primary-btn-user nav-link user-dropdown dropdown-dark"
          :text="currentUser.username" style="padding-top: 12px !important;">
          <b-dropdown-item><router-link to="/user-profile"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="user" />
              {{ $t('dashboards.general.profile-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item><router-link to="/admin-profile-settings"
              style="text-decoration: none; color: #13283B"><font-awesome-icon icon="cog" />
              {{ $t('dashboards.general.settings-menu-title') }}</router-link></b-dropdown-item>
          <b-dropdown-item href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" />
            {{ $t('dashboards.general.logout-title') }}</b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="languageDropdown" right :text="$i18n.locale" style="padding-right: 10px;" class="dropdown-dark">
          <b-dropdown-item v-for="lang in langs" :key="lang.isoCode" @click="changeLang(lang)">
            <img :src="lang.flagSrc" height="20em" /> {{ lang.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div id="submenu" v-if="currentUser && currentUser.role.name === 'manager'" v-show="true"
        class="navbar-nav ml-auto full-width submenu">
        <div class="collapse navbar-collapse justify-content-center" id="subnavbarNav">
          <ul class="navbar-nav">
            <li class="nav-item-user-submenu">
              <div v-if="$route.path === '/modules'">
                <router-link to="/modules" class="suite-tools-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
              <div v-else>
                <router-link to="/modules" class="nav-link-user-submenu" style="text-decoration:none;"> Suite Tools
                </router-link>
              </div>
            </li>

            <li class="nav-item-user-submenu">
              <div v-if="$route.path == '/settings'">
                <router-link to="/settings" class="suite-tools-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
              <div v-else>
                <router-link to="/settings" class="nav-link-user-submenu" style="text-decoration:none;"> Settings
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </nav>

    <div class="chat-button-container"
      v-if="analyses.length != 0 && currentRouteName != 'chat' && !showAdminBoard && loggedIn && !analyses[0].chatbot_talk && analyses[0].progress !== 100">
      <button @click="redirectToChatbot()"><svg height="511pt" viewBox="1 -31 512 511" width="511pt"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M128 448.5c-8.836 0-16-7.164-16-16v-80H48c-26.512 0-48-21.492-48-48v-256C0 21.988 21.488.5 48 .5h416c26.512 0 48 21.488 48 48v256c0 26.508-21.488 48-48 48H230.625l-91.313 91.313c-3 3-7.07 4.687-11.312 4.687zm-80-416c-8.836 0-16 7.164-16 16v256c0 8.836 7.164 16 16 16h80c8.836 0 16 7.164 16 16v57.375l68.688-68.688c3-3 7.07-4.687 11.312-4.687h240c8.836 0 16-7.164 16-16v-256c0-8.836-7.164-16-16-16zm0 0">
          </path>
          <path
            d="M160 144.5c-17.672 0-32-14.328-32-32s14.328-32 32-32 32 14.328 32 32-14.328 32-32 32zm0 0M352 144.5c-17.672 0-32-14.328-32-32s14.328-32 32-32 32 14.328 32 32-14.328 32-32 32zm0 0M256 288.5c-52.996-.05-95.945-43.004-96-96 0-8.836 7.164-16 16-16s16 7.164 16 16c0 35.348 28.652 64 64 64s64-28.652 64-64c0-8.836 7.164-16 16-16s16 7.164 16 16c-.055 52.996-43.004 95.945-96 96zm0 0">
          </path>
        </svg></button>
    </div>
    <div class="recalculate-button-container" v-if="currentRouteName == 'profile-configuration'">
      <button v-if="isLoaded" class="recalculate-button" @click="showRecalculateProfilesModal()"><font-awesome-icon
          icon="sync-alt" color="white" />{{ $t('dashboards.areas.profile.recalculate') }}</button>
      <button v-else class="recalculating-button" @click="showRecalculateProfilesModal()" disabled><font-awesome-icon
          icon="sync-alt" color="white" />{{ $t('dashboards.areas.profile.recalculating') }}</button>
    </div>
    <div class="col-md-12" v-bind:class="currentUser ? 'bg-white full-height full-width' : ''">
      <router-view :showWelcome=show v-bind:class="currentUser ? 'main-container' :''" />
    </div>
    <b-modal id="recalculate-modal" ref="recalculate-modal" title="Recalculate-modal" hide-footer>
      <template #modal-title>
        {{ $t('dashboards.areas.profile.recalculation-title') }}
      </template>
      <div>
        {{ $t('dashboards.areas.profile.recalculation-description') }}
      </div>
      <input class="btn btn-primary form-submit" type="button" style="margin-right: 1%;"
        @click="closeRecalculateProfilesModal()" value="Cerrar">
    </b-modal>
  </div>
</template>

<script>
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import AreasDataService from '@/services/AreasDataService';

import $ from 'jquery'

export default {
  name: 'App',
  data: () => ({
    logo: 'images/logo_principal_01.svg',
    analyses: [],
    userAreas: [],
    currentAreaName: "Select an area",
    currentAreaId: 0,
    isAreaActive: false,
    show: false,
    maxLengthArea: 20,
    showAreas: false,
    flagAreaSearch: true,
    prevRoute: null,
    isLoaded: true,
    langs: [
      {
        isoCode: 'en',
        name: 'ENglish',
        flagSrc: 'images/flags/united-kingdom.png'
      },
      {
        isoCode: 'es',
        name: 'ESpañol',
        flagSrc: 'images/flags/spain.png'
      }
    ],
    backToUrl: '/admin'
  }),
  watch: {
  },
  mounted() {
    this.mountedMethod()
    this.findCVsByArea()
    this.$root.$on('mountedMethod', () => {
      this.mountedMethod()
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentUser() {
      if (this.$store.state.auth.user != undefined && this.$store.state.auth.user != null && this.userAreas.length == 0 && !this.flagAreaSearch) {
        this.flagAreaSearch = false
        this.mountedMethod()
      } else if (this.$store.state.auth.user == undefined || this.$store.state.auth.user == null) {
        this.userAreas = []
        this.flagAreaSearch = true
        this.$session.remove("tabID");
        this.currentAreaName = "Select an area";
      }
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.role.name) {
        if (this.currentUser.role.name === 'admin') {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    changeBackToUrl(route) {
      this.backToUrl = route
    },
    mountedMethod() {
      let guid = () => {
        let s4 = () => {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
      }

      // Check if area id is create
      if (!this.$session.get("tabID"))
        this.$session.set("tabID", guid());

      // Get user areas
      if (this.$store.state.auth.user) {
        if (this.$store.state.auth.user.role.name != 'superadmin') {
          this.getAreas();
        }
      }

      if (!this.showAdminBoard && this.$store.state.auth.status.loggedIn) {
        if (this.$store.state.auth.user.role.name != 'superadmin') {
          ProfileAnalysisDataService.findCVsByUser(this.$store.state.auth.user.id).then(
            response => {
              this.analyses = response.data
            },
            error => {
              console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())
            }
          );
        }
      }
    },
    async findCVsByArea() {
      if (this.$store.state.auth.user !== null && this.$store.state.auth.user !== undefined &&
        this.$store.state.auth.user.active_areas !== undefined && this.$store.state.auth.user.active_areas !== undefined &&
        Object.keys(this.$store.state.auth.user.active_areas).length > 0) {

        const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

        if (currentArea !== undefined) {
          await ProfileAnalysisDataService.findCVsByArea(currentArea).then(
            async response => {
              if (response.data.area_status) this.isLoaded = false
              else this.isLoaded = true
            },
            error => {
              console.log((error.response && error.response.data) ||
                error.message ||
                error.toString())

              if (error.response.status === 300) {
                console.log('Logout: Token expired')
                localStorage.removeItem('user');
                localStorage.removeItem('company');
                this.$store.state.auth.user = null
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
              }
            }
          );
        } else {
          this.isLoaded = false
        }
      } else {
        this.isLoaded = false
      }
    },
    changeArea(area, isAreaSelected) {
      // Change area
      let userTmp = this.$store.state.auth.user;

      // Get tab id
      var tabID = this.$session.get("tabID");

      // Append area to new tab
      if (!userTmp.active_areas)
        userTmp.active_areas = {};

      userTmp.active_areas[tabID] = area.id;

      this.currentAreaId = area.id

      // Store area with id of the tab
      localStorage.setItem('user', JSON.stringify(userTmp));

      this.currentAreaName = area.name.length > this.maxLengthArea ? area.name.substring(0, this.maxLengthArea).toUpperCase() + "..." : area.name.toUpperCase();

      // Redirect to main and reload page
      if (isAreaSelected) {
        if (this.$store.state.auth.user.role.name == 'admin') {
          this.$router.push('/admin');
          this.$router.go();
        } else if (this.$store.state.auth.user.role.name == 'talent_manager') {
          this.$router.push('/talent_manager');
          this.$router.go();
        } else {
          this.$router.go();
        }
      }
    },
    getAreas() {
      let currentAreaId = "";
      let currentArea = [];
      const currentTabID = this.$session.get("tabID");

      if (this.$store.state.auth.user.role.name == 'admin' || this.$store.state.auth.user.role.name == 'talent_manager') {
        AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.userAreas = response.data
              if (this.userAreas.length == 0) {
                this.flagAreaSearch = true
              }

              // Set current area
              if (this.$store.state.auth.user.active_areas != undefined) {
                // Set flag to no 
                this.isAreaActive = true;

                currentAreaId = this.$store.state.auth.user.active_areas[currentTabID];
                currentArea = this.userAreas.filter(area => area.id == currentAreaId).map(function (e) { return e })[0]

                if (currentArea != undefined)
                  this.changeArea(currentArea, false);
              } else {
                this.isAreaActive = false;
              }
            } else this.flagAreaSearch = true
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())
            this.flagAreaSearch = true
          }
        );

      } else {
        this.userAreas = this.$store.state.auth.user.areas
        if (this.userAreas.length == 0)
          this.flagAreaSearch = true
        this.showAreas = this.userAreas.length < 2 ? false : true;
        // Set current area
        if (this.$store.state.auth.user.active_areas != undefined) {
          currentAreaId = this.$store.state.auth.user.active_areas[currentTabID];
          currentArea = this.userAreas.filter(area => area.id == currentAreaId).map(function (e) { return e })[0]
          if (currentArea)
            this.changeArea(currentArea, false);
          else
            this.changeArea(this.userAreas[0], false);
        } else {
          this.changeArea(this.userAreas[0], false);
          this.isAreaActive
        }
      }

    },
    onAreaChange(area) {
      this.changeArea(area, true);
    },
    exitArea() {
      // Delete area in local storage
      let userTmp = this.$store.state.auth.user;
      userTmp.active_areas = undefined;
      localStorage.setItem('user', JSON.stringify(userTmp));

      // Delete id in session
      this.$session.remove("tabID");

      // Set area active to false
      this.isAreaActive = false;

      // Redirect to route 
      this.$router.push(this.backToUrl);

      // Reload page
      this.$router.go();
    },
    exitCurrentArea(route) {
      // Delete area in local storage
      let userTmp = this.$store.state.auth.user;
      userTmp.active_areas = undefined;
      localStorage.setItem('user', JSON.stringify(userTmp));

      // Delete id in session
      this.$session.remove("tabID");

      // Set area active to false
      this.isAreaActive = false;

      // Redirect to main 
      this.$router.push('/admin');

      // Reload page
      this.$router.go();
    },
    exitCurrentAreaTM() {
      // Delete area in local storage
      let userTmp = this.$store.state.auth.user;
      userTmp.active_areas = undefined;
      localStorage.setItem('user', JSON.stringify(userTmp));

      // Delete id in session
      this.$session.remove("tabID");

      // Set area active to false
      this.isAreaActive = false;

      // Redirect to main 
      this.$router.push('/talent_manager');

      // Reload page
      this.$router.go();
    },
    changeLang(lang) {
      this.$i18n.locale = lang.isoCode
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    showSubmenu() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    notShowSubmenu() {
      this.show = false;
    },
    onToggleCollapse(collapsed) {
      if (collapsed) {
        this.srcHeaderSidebar = "images/pro.svg"
        $('.navbar').addClass('collapsed-sidebar')
        $('.main-container').addClass('collapsed-sidebar')
      } else {
        this.srcHeaderSidebar = "images/progradum_blanco_simple.svg"
        $('.navbar').removeClass('collapsed-sidebar')
        $('.main-container').removeClass('collapsed-sidebar')
      }
    },
    redirectToChatbot() {
      ProfileAnalysisDataService.findLastByUser(this.$store.state.auth.user.id).then(
        response => {
          if (response.status == 200)
            this.$router.replace({ name: "chat", params: {}, query: { token: response.data.token } })
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      );
    },
    showRecalculateProfilesModal() {
      ProfileAnalysisDataService.recalculateProfiles(this.currentAreaId).then(
        response => {
          if (response.status == 200) {
            this.findCVsByArea()
            this.$refs['recalculate-modal'].show();
          }
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      );
    },
    closeRecalculateProfilesModal() {
      this.$refs['recalculate-modal'].hide();
    }
  },
  ready: function () {
    $('.v-sidebar-menu').on('click', function () {
      //
    });
  }
}
</script>
