<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link :to="{path: '/evaluation-360'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to BEI/360 Evaluation</router-link>
			</div>

			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/softskills' }" class="breadcumb"> Soft Skills > </router-link>
				<router-link :to="{ path: '/softskills-configuration-perform-evaluation' }" class="final-breadcumb"> Perform Evaluation</router-link>
			</div>
		</div>

		<div class="col-sm-8 col-centered">
			<complete-evaluation/>
		</div>
	</div>
</template>

<script>
  	import CompleteEvaluation from "@/components/SoftSkillsComponent/CompleteEvaluation.vue";

	export default {
	  	components: {
			CompleteEvaluation
	    },
	    props: {
	    },
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			}
      	},
		methods: {
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>