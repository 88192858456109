<template>
    <div class="col-sm-12">
        <div class="row secondNavbar">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/business-process' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
                    Process</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
                <router-link :to="{ path: '/business-process-ranking' }" class="final-breadcumb"> Create Evaluation
                    Process </router-link>
            </div>
        </div>

        <div class="col-sm-12 col-centered">
            <create-business-process />
        </div>
    </div>
</template>

<script>
import CreateBusinessProcess from "@/components/BusinessProcessComponents/CreateBP.vue";

export default {
    name: 'Create',
    components: {
        CreateBusinessProcess,
    }
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

</style>
