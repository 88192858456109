<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<router-link v-if="isAdmin || isManager" :to="{path: '/evaluation'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation</router-link>
				<router-link v-if="isExaminer" :to="{path: '/examiner'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back Home</router-link>
				<router-link v-if="isValidator" :to="{path: '/questions-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back Home</router-link>
				<router-link v-if="isCertifier" :to="{path: '/certifications-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back Home</router-link>
				<router-link v-if="isTM" :to="{path: '/talent_manager'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back Home</router-link>
				<router-link v-if="isUser" :to="{path: '/user'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back Home</router-link>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
				<router-link :to="{ path: '/evaluation-360' }" class="final-breadcumb"> BEI/360 Evaluation </router-link>
				<router-link :to="{ path: '/softskills' }" class="final-breadcumb"> > Soft Skills </router-link>
			</div>
			<div v-if="isManager" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
				<router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
				<router-link :to="{ path: '/evaluation-360' }" class="final-breadcumb"> BEI/360 Evaluation </router-link>
				<router-link :to="{ path: '/softskills' }" class="final-breadcumb"> > Soft Skills </router-link>
			</div>
			<div v-if="isExaminer || isValidator || isCertifier || isTM || isUser" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/evaluation-360' }" class="final-breadcumb"> BEI/360 Evaluation </router-link>
			</div>
		</div>

		<div class="col-sm-12 col-centered">
			<list/>
		</div>
	</div>
</template>

<script>
  	import List from "@/components/SoftSkillsComponent/List.vue";

	export default {
	  	name: 'SoftskillsView',
	  	components: {
	      	List
	    },
	    props: {
	    },
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			},
			isExaminer() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'examiner') {
						return true;
					}
				}
				return false;
			},
			isValidator() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'validator') {
						return true;
					}
				}
				return false;
			},
			isCertifier() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'certifier') {
						return true;
					}
				}
				return false;
			},
			isTM() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'talent_manager') {
						return true;
					}
				}
				return false;
			},
			isManager() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'manager') {
						return true;
					}
				}
				return false;
			},
			isUser() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'user') {
						return true;
					}
				}
				return false;
			}
      	},
		methods: {
		}
	};
</script>



<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>