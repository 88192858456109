<template>
	<div class="col-sm-12">
		<div class="row secondNavbar">
			<div class="col-5 links-container">
				<router-link :to="{ path: '/settings-bei-360' }" class="btn btn-secondary float-right"><font-awesome-icon
						icon="arrow-left" /> Back to BEI 360 settings</router-link>
				<router-link to="/softskills-configuration-create-evaluation"
					class="btn btn-secondary float-right createClass"><font-awesome-icon icon="plus" /> CREATE
					EVALUATION</router-link>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/settings-bei-360' }" class="breadcumb"> BEI 360 settings > </router-link>
				<router-link :to="{ path: '/softskills-configuration' }" class="final-breadcumb"> BEI/360 Evaluation
					Configuration</router-link>
			</div>
		</div>

		<div class="col-sm-12 px-4 col-centered">
			<evaluation-configuration />
		</div>
	</div>
</template>

<script>
import EvaluationConfiguration from "@/components/SoftSkillsComponent/EvaluationConfiguration.vue";

export default {
	components: {
		EvaluationConfiguration
	},
	props: {
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		}
	},
	methods: {
		close() {
			document.getElementById("message").style.display = "none"
		}
	}
};
</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}
</style>