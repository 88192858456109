<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link v-if="isAdmin" :to="{path: '/admin-reports-validator'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Validator Reports</router-link>
        <router-link v-else :to="{path: '/questions-validator-talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} </router-link>
        <reports-downloader :reportsTitle="reportsTitle" :documentTitle="documentTitle"/>
      </div>
      <div v-if="isAdmin" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/admin-reports-validator' }" class="breadcumb"> Validator Reports > </router-link>
        <router-link :to="{ path: '/questions-validator-cv-analysis-reports' }" class="final-breadcumb"> CV Analysis Reports </router-link>
      </div>
      <div v-else class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/questions-validator-talent-management' }" class="breadcumb"> {{$t('dashboards.general.reporting')}} > </router-link>
        <router-link :to="{ path: '/questions-validator-cv-analysis-reports' }" class="final-breadcumb"> CV Analysis Reports </router-link>
      </div>
    </div>
    

    <h4 class="reports-available-title">Reports available</h4>
    <select class="form-select report_selector" data-vv-name="area_selector" v-model="selectedReport" @change="onChange($event)" >
			<option :value="undefined">Select a report to view</option>
      <option value="cvs-by-area">Users CVs analysed by area</option>
      <option value="cvs-admin-by-date">Users CVs analysed per admins by date</option>
      <option value="cvs-by-date">User CVs analysed by date</option>
      <option value="cvs-by-category-profile">Users CVs analysed by category and profile</option>
		</select>

    <UsersGraphCVByCompany v-if="selectedReport == 'cvs-by-area'" ref="chart1" :areas="areas"/>
    <UsersGraphCVByAdmins v-if="selectedReport == 'cvs-admin-by-date'" ref="chart2" :areas="areas"/>
    <UsersGraphTotalCVByCompany v-if="selectedReport == 'cvs-by-date'" ref="chart3"/>
    <UsersGraphCVByLevels v-if="selectedReport == 'cvs-by-category-profile'" ref="chart4" :areas="areas"/>
  </div>
</template>

<script>
  import UsersGraphCVByCompany from "@/components/ReportsComponents/Users/UsersGraphCVByCompany.vue";
  import UsersGraphCVByAdmins from "@/components/ReportsComponents/Users/UsersGraphCVByAdmins.vue";
  import UsersGraphTotalCVByCompany from "@/components/ReportsComponents/Users/UsersGraphTotalCVByCompany.vue";
  import UsersGraphCVByLevels from "@/components/ReportsComponents/Users/UsersGraphCVByLevels.vue";
  import AreasDataService from "@/services/AreasDataService";
  import ReportsDownloader from "@/components/Others/ReportsDownloader";

  export default {
    name: 'CVAnalysisReportsView',
    components: {
      UsersGraphCVByCompany,
      UsersGraphCVByAdmins,
      UsersGraphTotalCVByCompany,
      UsersGraphCVByLevels,
      ReportsDownloader
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      }
    },
    data() {
      return {
        reportsTitle: "CV Analysis Reports",
        documentTitle: "question_validator_cv_analysis_reports.pdf",
        areas: [],
        selectedReport: undefined
      }
    },
    mounted() {
      if (this.isAdmin)
        this.getListOfAreasFromAdmin();
      else
        this.areas = this.$store.state.auth.user.areas;
    },
    methods: {
      async getListOfAreasFromAdmin() {
        await AreasDataService.findByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            if (response.status == 200) {
              this.areas = response.data
            }
          },
          error => {
            console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}

.report_selector {
  margin-left: 3%;
  margin-right: 3%; 
  margin-top: 2%;
  max-width: 94%;
}

.reports-available-title {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
}
</style>