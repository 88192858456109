<template>
  <ul class="tools-capsule">
    <li class="tools-capsule-data">{{ name }}</li>
    <li v-if="del" class="tools-capsule-data" @click="emitDelete(name)">
      <font-awesome-icon icon="times" />
    </li>
  </ul>
</template>

<script>
  export default {
    name: "ToolsCapsule",
    data() {
      return {};
    },
    components: {},
    props: {
      name: String,
      del: Boolean
    },
    methods: {
      emitDelete(name) {
        this.$emit("delete-tools", name);
      }
    }
  };
</script>

<style scoped>
.tools-capsule {
  list-style: none;
  padding: 7px;
  background: #13c7d479;
  border-radius: 12px;
  display: inline-flex;
  margin-right: 0.5em;
  margin-bottom: 0px ! important;
}

.tools-capsule-data {
  padding: 0 10px;
  line-height: 1;
  color: rgb(0, 0, 0);
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
</style>


