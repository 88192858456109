<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/languages'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Languages</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/languages' }" class="breadcumb"> Languages > </router-link>
        <router-link :to="{ path: '/update-language' }" class="final-breadcumb"> Update Language </router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered">	
  		<update-language/>
  	</div>
  </div>
</template>

<script>
  import UpdateLanguage from "@/components/LanguageComponents/Update.vue";

	export default {
    name: 'UpdateLanguageView',
    components: {
      UpdateLanguage
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>