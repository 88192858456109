<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/certifications'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Certifications</router-link>
      </div>
      <div v-if="isAdmin" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/certifications' }" class="breadcumb"> Certifications > </router-link>
        <router-link :to="{ path: '/update-certification' }" class="final-breadcumb"> Update Certification </router-link>
      </div>
      <div v-else class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/certifications' }" class="breadcumb"> Certifications > </router-link>
        <router-link :to="{ path: '/update-certification' }" class="final-breadcumb"> Update Certification </router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered">
      <update-certification/>
    </div>
  </div>
</template>

<script>
  import UpdateCertification from "@/components/CertificationsComponents/Update.vue";

	export default {
    name: 'UpdateCertificationView',
    components: {
      UpdateCertification
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isAdmin() {
        if (this.currentUser && this.currentUser.role) {
          if (this.currentUser.role.name === 'admin') {
            return true;
          }
        }
        return false;
      }
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>