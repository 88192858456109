<template>
	<div class="table-container  technologiesStyle">
		<template v-if="languages.length >0">
			<div class="row" style="margin-top: 1%;">
				<div class="col-xl-3 col-lg-4">
					<b-pagination
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="languagesList"
					></b-pagination>
				</div>
				<div class="col-xl-6 col-lg-5">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
					<button class="btn btn-primary table-selection-button" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /> Select all rows</button>  
					<button class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /> Clear All</button>  
					<button v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /> Delete {{selectedItems.length}} rows</button> 
				</div>
				<div class="col-xl-3 col-lg-3 margin-on-lg">
					<b-form-input 
						id="filter-input"
						v-model="filter"
						type="search"
						:placeholder="$t('others.search-placeholder-msg')"
					></b-form-input>
				</div>
			</div>
			
			<b-table ref="languagesTable"
				id="languagesList"
				:per-page="perPage"
				:current-page="currentPage"
				:fields="headers" 
				:items="languages" 
				:filter="filter"
				select-mode="multi"
				responsive="sm"
				selectable
				@row-selected="onRowSelected">
				<template #cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not Selected</span>
					</template>
				</template>
				<template #cell(name)="data">
					{{ data.item.name }}
				</template>
				<template #cell(created_at)="data">
					{{ data.item.created_at | formatEnglishDate}}
				</template>
				<template #cell(updated_at)="data">
					{{ data.item.updated_at | formatEnglishDate}}
				</template>
				<template #cell(options)="data">
					<router-link v-b-tooltip.hover title="Edit" :to="{path: '/update-language?id=' + data.item.id}" class="btn btn-secondary"><font-awesome-icon icon="edit" /></router-link>
				</template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
		</template>
		
		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete language
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} languages? </p>
				<p v-else> Do you really want to delete this language? </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="deleteLanguage()" value="Yes">
			<input class="btn btn-primary form-submit" style="margin-left: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
		</b-modal>
	</div>
</template>

<script>
	import LanguageDataService from '@/services/LanguageDataService';

	export default {
		name: 'LanguagesListComponent',
		data() {
			return {
				headers: [
					{ key: 'name', sortable: true, label: 'Name' }, { key: 'created_at', sortable: true, label: 'Created at' },{ key: 'updated_at', sortable: true, label: 'Updated at' },  { key: 'options', label: 'Options' }, 
				],
				message: "",
				successfull: false,
				perPage: 10,
				currentPage: 1,
				filter: null,
				pageOptions: this.$paginationOptions,
				selectedItems: [],
				selectedLanguage: null,
				selectedLanguageIndex: null
			}
	    },
		props: {
		    languages: Array
		},
	    computed: {
			rows() {
				return this.languages.length
			}
	    },
		mounted(){
			if(this.$store.state.auth.user.table_records_per_page != undefined)
				this.perPage = this.$store.state.auth.user.table_records_per_page
		},
	  	methods: {
	  		onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["languagesTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["languagesTable"].clearSelected();
	      	},
	      	showDeleteModalMultiple() {
	      		this.$refs['delete-modal'].show();
	      	},
			deleteLanguage(){
				let selectedRows = this.selectedItems.map(element => element.id); 

				LanguageDataService.delete(selectedRows).then(
					response => {
						if (response.status == 200) {
				      		this.successfull = true
							this.$emit('deleteLanguage');
				      	} else {
				      		this.successfull = false
				      	}
						
						this.$refs['delete-modal'].hide();
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
			close() {
				document.getElementById("messageDelete").style.display = "none"
			}
	    }
	};
</script>

<style scoped>
@media (max-width: 967px) {
	.margin-on-lg {
		margin-top: 1%;
	}
}
</style>