<template>
    <div class="col-sm-12 centered-margins">
        <div id="message" v-if="message"
            :class="successfull ? 'message alert alert-success' : 'message alert alert-danger'"
            style="margin-left: 2%; margin-right: 2%;">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>

        <div class="row">
            <div class="col-sm-3">
                <div class="row justify-content-md-right">
                    <div class="col-sm-12">
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-4">
                                <h6 class="skillH6 float-left">Max. CV Uploads:</h6>
                            </div>
                            <div class="col-sm-4">
                                <b-input type="number" id="maxUploads" class="lastYearsInputFirst" min="0"
                                    ref="maxUploads" step="1" v-model="settings.max_cv_uploads" />
                            </div>
                            <div class="col-sm-2">
                                <b-button class="btn btn-info" v-b-tooltip.hover
                                    title="Maximum number of CVs that can be uploaded at the same time"><font-awesome-icon
                                        icon="info-circle" /></b-button>
                            </div>
                        </div>
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-4">
                                <h6 class="skillH6 float-left">Max. CV file size (MB):</h6>
                            </div>
                            <div class="col-sm-4">
                                <b-input type="number" id="maxFileSize" class="lastYearsInputFirst" ref="maxFileSize"
                                    step="1" min="0" max="100000" v-model="settings.max_cv_uploads_filesize" />
                            </div>
                            <div class="col-sm-2">
                                <b-button class="btn btn-info" v-b-tooltip.hover
                                    title="Maximum upload size for a batch of CVs (in MB)"><font-awesome-icon
                                        icon="info-circle" /></b-button>
                            </div>
                        </div>
                        <div class="row mt-4 mb-4">
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-4">
                                <h6 class="skillH6 float-left">Anonymize CVs:</h6>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-check form-switch">
                                    <input v-if="settings.anonymize_token_use"
                                        class="form-check-input checkbox-required float-left" type="checkbox"
                                        v-model="settings.anonymize_token_use" checked>
                                    <input v-else class="form-check-input checkbox-required float-left" type="checkbox"
                                        v-model="settings.anonymize_token_use">
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <b-button class="btn btn-info" v-b-tooltip.hover
                                    :title="$t('dashboards.admin.settings.cv-anonymize')"><font-awesome-icon
                                        icon="info-circle" /></b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="row justify-content-md-right">
                    <div class="col-sm-12 mt-3">
                        <div class="row mt-4">
                            <div class="col-sm-5">
                                <h6 class="skillH6" style="float: right !important;">Logo:</h6>
                            </div>
                            <div class="col-sm-7">
                                <input id="logoImgFileInput2 float-left" accept=".jpg" ref="logoImgFileInput2"
                                    type="file" @change="onFileChange">
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-5 pt-0">
                            </div>
                            <div class="col-sm-7 pt-0">
                                <p class="no-margin-bottom text-left"><b-button class="btn btn-info"><font-awesome-icon
                                            icon="info-circle" />&nbsp;&nbsp;</b-button> Files must have .jpg extension
                                    to be correctly shown.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row justify-content-md-right">
                    <div class="col-sm-12 mt-3">
                        <div class="row mt-4">
                            <div class="col-sm-12">
                                <h6 class="skillH6 float-left">Former logo:</h6>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <a href="javascript:void(0);"
                                    v-if="companyLogo !== null && companyLogo !== undefined && !companyLogo.includes('null')"
                                    style="float: left !important; cursor: default">
                                    <img v-bind:src="companyLogo" class="card-avatar card-avatar--circle"
                                        style="height: 200px; width: auto; border-radius: 0; margin: 1% auto;">
                                </a>
                                <div v-else class="alert alert-info ml-4 my-0" role="alert" style="margin-left: 1%;">
                                    Logo sin definir
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-md-center company-buttons">
            <div class="col-sm-5">
                <button class="btn btn-primary form-submit" type="button" @click="update()">UPDATE</button>
                <button class="btn btn-secondary btn-cancel mt-5 ms-5" type="button"
                    @click="cancelUpdate()"><font-awesome-icon icon="ban" /> Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyDataService from '@/services/CompanyDataService';

export default {
    name: 'AdminSettingsComponent',
    data() {
        return {
            companyLogo: null,
            files: null,
            settings: {},
            successfull: false,
            message: ''
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        CompanyDataService.getLogo(this.$store.state.auth.user.id_company).then(
            response => {
                this.companyLogo = response.data.logo
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

        CompanyDataService.getAccountSettings(this.$store.state.auth.user.id_company).then(
            response => {
                this.settings = response.data
            },
            error => {
                this.content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        onFileChange(e) {
            this.files = e.target.files || e.dataTransfer.files
            if (!this.files.length) {
                return
            }

            if (this.files !== null) this.createImage(this.files[0]);
        },
        createImage(file) {
            var image = new Image()
            var reader = new FileReader()
            var vm = this

            reader.onload = (e) => {
                var img = document.createElement("img");
                img.onload = function (event) {
                    var maxW = 200;
                    var maxH = 200;
                    var canvas = document.createElement("canvas");
                    var iw = img.width;
                    var ih = img.height;
                    var scale = Math.min((maxW / iw), (maxH / ih));
                    var iwScaled = iw * scale;
                    var ihScaled = ih * scale;
                    canvas.width = iwScaled;
                    canvas.height = ihScaled;

                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

                    vm.fileToUpload = canvas.toDataURL(file.type)
                }
                vm.avatar = e.target.result;
                img.src = e.target.result;
            }
            reader.readAsDataURL(file)
        },
        cancelUpdate() {
            this.$router.replace({ name: "admin-configuration", params: {} });
        },
        update() {
            this.successfull = false
            if (this.settings.max_cv_uploads !== null && this.settings.max_cv_uploads !== undefined && this.settings.max_cv_uploads !== '' &&
                this.settings.max_cv_uploads_filesize !== null && this.settings.max_cv_uploads_filesize !== undefined && this.settings.max_cv_uploads_filesize !== '' &&
                this.settings.anonymize_token_use !== null && this.settings.anonymize_token_use !== undefined) {

                CompanyDataService.updateAccountSettings(this.settings).then(
                    response => {
                        if (this.files !== null && this.files !== undefined && this.files.length > 0) {
                            CompanyDataService.uploadLogo(this.files[0], this.$store.state.auth.user.id_company, this.currentUser.accessToken).then(
                                response => {
                                    if (response.status == 200) {
                                        this.companyLogo = response.data.logo
                                        this.message = "Logo and settings updated successfully!"
                                        this.successfull = true
                                    } else {
                                        this.message = "There was an error while updating settings";
                                        this.successfull = false
                                    }
                                },
                                error => {
                                    this.message = "There was an error while updating settings";
                                    this.successfull = false
                                }
                            );
                        } else {
                            this.message = "Settings updated successfully!"
                            this.successfull = true
                        }
                    },
                    error => {
                        this.message = "There was an error while updating settings";
                        this.successfull = false
                    }
                );
            } else {
                this.message = "There are some fields empty";
                this.successfull = false
            }
        },
        close() {
            document.getElementById("message").style.display = "none"
            this.successfull = false
            this.message = ""
        }
    }
};
</script>

<style scoped>
.componentMargins {
    margin-left: 20%;
    padding-right: 15%;
    margin-top: 3%;
    align-content: center !important;
    justify-content: center !important;
}

.noMargin {
    padding-top: 0% !important;
}

.noMarginLow {
    padding-bottom: 0% !important;
}

.titleClass {
    margin-bottom: 3% !important;
    margin-top: 1% !important;
}

.resetColor {
    background-color: #2c3e50 !important;
    border-color: #2c3e50 !important;
    margin-left: 1%;
}

.centerText {
    text-align: center !important;
}

.dropdown-content {
    z-index: 999;
    position: fixed;
    display: flex !important;
    margin: 0% !important;
}

.checkbox-required {
    float: none !important;
}

.right-align-text {
    float: right !important;
}

.row-margin {
    margin-bottom: 1%;
}
</style>
