<template>
	<div class="row" v-if="!pdf">
		<div class="col-sm-12">
			<h4 style="margin-top: 1%;">Top Required Profiles</h4>
			<div class="row">
				<div class="col-md-4"></div>
				<div class="col-md-6">
					<ul id="progressbarMain">
						<template v-if="levelsData[0] != undefined">
							<li v-if="levelsData[0].min_range !== 0"></li>
	<li v-for="level in levelsData" :style="{
		'--liBgColor': styleLevels[level.name.toLowerCase()].liBgColor,
		'--liColor': styleLevels[level.name.toLowerCase()].liColor,
		'--liContent': styleLevels[level.name.toLowerCase()].liContent,
		'--liAfterWidth': styleLevels[level.name.toLowerCase()].liAfterWidth,
		'--liMarginLeft': styleLevels[level.name.toLowerCase()].liMarginLeft,
		'--liTextColor': getContrastColor(styleLevels[level.name.toLowerCase()].liColor)
	}">{{ level.name }}</li>
						</template>
					</ul>
				</div>
				<div class="col-md-1"></div>
			</div>

			<div class="row" v-for="(value, tech, index) in sortedProgress" :key="tech">
				<div style="margin-bottom: 15px;">
					<div class="row">
						<div class="col-md-1">
							<img v-bind:src="logos[tech.toLowerCase()]" height="50em"
								class="analysis-card-avatar--circle" />
						</div>
						<div class="col-md-3 profile-center-analysis">
							<h5 class="profile-name-title">{{ tech.toUpperCase() }}</h5>
						</div>
						<div class="col-md-6">
							<template v-if="progress[tech] > 0">
								<progress-tech-header :progress="progress[tech]"
									:levelsData="levelsData"></progress-tech-header>
							</template>
							<template v-else>
								<p><b>0% - No classified</b></p>
							</template>
						</div>
						<div class="col-md-1 alig-items-right">
							<button v-if="index !== 0" class="btn btn-secondary expand-button" type="button"
								v-b-toggle="'toggleT-' + index.toString()" @click="changeShowState(index)">
								<font-awesome-icon style="display: block;" v-bind:id="'iconShow-' + index.toString()"
									class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
								<font-awesome-icon style="display: none;" v-bind:id="'iconNotShow-' + index.toString()"
									class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up" />
							</button>

							<button v-else class="btn btn-secondary expand-button" type="button"
								v-b-toggle="'toggleT-' + index.toString()" @click="changeShowState(index)">
								<font-awesome-icon style="display: none;" v-bind:id="'iconShow-' + index.toString()"
									class="trash-icon" v-b-tooltip.hover title="Expand" icon="chevron-down" />
								<font-awesome-icon style="display: block;" v-bind:id="'iconNotShow-' + index.toString()"
									class="trash-icon" v-b-tooltip.hover title="Contract" icon="chevron-up" />
							</button>
						</div>
					</div>
				</div>
				<b-collapse v-if="index === 0" v-bind:id="'toggleT-' + index.toString()" visible>
					<tech-analysis ref="tech-analysis" :technology="tech" :improvement="improvement[tech]"
						:progress="progress[tech]" :type="type[tech]" :data="data"
						:experienceProfile="experienceProfile[tech]" :technologyLevels="technologyLevels[tech]"
						:acommplishedReqs="acommplishedReqs" :userSkills="userSkills"
						:userCertifications="userCertifications" :userEducation="userEducation"
						:levelsData="levelsData">
					</tech-analysis>
				</b-collapse>
				<b-collapse v-else v-bind:id="'toggleT-' + index.toString()">
					<tech-analysis ref="tech-analysis" :technology="tech" :improvement="improvement[tech]"
						:progress="progress[tech]" :type="type[tech]" :data="data"
						:experienceProfile="experienceProfile[tech]" :technologyLevels="technologyLevels[tech]"
						:acommplishedReqs="acommplishedReqs" :userSkills="userSkills"
						:userCertifications="userCertifications" :userEducation="userEducation"
						:levelsData="levelsData">
					</tech-analysis>
				</b-collapse>
				<hr class="thicker">
			</div>
		</div>
	</div>
	<div class="row" v-else>
		<div class="col-sm-12">
			<section>
				<h4
					style="margin-top: 1%; text-align: left;border-bottom: 3px solid;padding-bottom: 9px; margin-bottom: 35px; font-size: 18.66px">
					Top Required Profiles</h4>
				<div class="row">
					<div class="col-md-9" style="margin: auto">
						<ul id="progressbarPDF">
							<li v-for="level in levelsData" :style="{
		'--liBgColor': styleLevels[level.name.toLowerCase()].liBgColor,
		'--liColor': styleLevels[level.name.toLowerCase()].liColor,
		'--liContent': styleLevels[level.name.toLowerCase()].liContent,
		'--liAfterWidth': styleLevels[level.name.toLowerCase()].liAfterWidth,
		'--liMarginLeft': styleLevels[level.name.toLowerCase()].liMarginLeft
	}">{{ level.name }}</li>
						</ul>
					</div>
				</div>
			</section>

			<section v-for="(value, tech, index) in sortedProgress" :key="tech">
				<div class="row" style="padding-left: 14px; padding-right: 14px;">
					<div>
						<div class="row">
							<div class="col-md-1">
								<img style="width: 100%; height: auto;"
									v-bind:src="logos[tech.toLowerCase()] + '?withheaders=yes'"
									class="analysis-card-avatar--circle tech-image" />
							</div>
							<div class="col-md-2 profile-center-analysis">
								<h5 class="profile-name-title"
									style="line-height: 2; font-size: 14.66px; margin-bottom: 5px">
									{{ tech.toUpperCase() }}
								</h5>
							</div>
							<div class="col-md-7" style="margin-top: 2px">
								<template v-if="progress[tech] > 0">
									<progress-tech-header :progress="progress[tech]" :levelsData="levelsData"
										class="progress-pdf"></progress-tech-header>
								</template>
								<template v-else>
									<p><b>0% - No classified</b></p>
								</template>
							</div>
						</div>
					</div>
					<b-collapse v-if="index === 0" v-bind:id="'toggleT-' + index.toString()" visible>
						<tech-analysis ref="tech-analysis" :technology="tech" :improvement="improvement[tech]"
							:progress="progress[tech]" :type="type[tech]" :data="data"
							:experienceProfile="experienceProfile[tech]" :technologyLevels="technologyLevels[tech]"
							:acommplishedReqs="acommplishedReqs" :userSkills="userSkills"
							:userCertifications="userCertifications" :userEducation="userEducation"
							:levelsData="levelsData" :pdf="true">
						</tech-analysis>
					</b-collapse>
					<b-collapse v-else v-bind:id="'toggleT-' + index.toString()">
						<tech-analysis ref="tech-analysis" :technology="tech" :improvement="improvement[tech]"
							:progress="progress[tech]" :type="type[tech]" :data="data"
							:experienceProfile="experienceProfile[tech]" :technologyLevels="technologyLevels[tech]"
							:acommplishedReqs="acommplishedReqs" :userSkills="userSkills"
							:userCertifications="userCertifications" :userEducation="userEducation"
							:levelsData="levelsData" :pdf="true">
						</tech-analysis>
					</b-collapse>
					<hr class="thicker" style="margin-bottom: 4px;">
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import TechAnalysis from '@/components/AnalysisComponents/TechAnalysis';
import ProgressTechHeader from "@/components/Others/ProgressTechHeader";

export default {
	name: "TopExtractedSkills",
	data() {
		return {
		}
	},
	components: {
		TechAnalysis,
		ProgressTechHeader
	},
	props: {
		improvement: Object,
		progress: Object,
		type: Object,
		data: Object,
		logos: Object,
		sortedProgress: Object,
		experienceProfile: Object,
		technologyLevels: Object,
		userSkills: Object,
		userCertifications: Array,
		userEducation: Array,
		acommplishedReqs: Object,
		levelsData: Array,
		styleLevels: Object,
		pdf: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
	},
	methods: {
		changeShowState(index) {
			if (document.getElementById('iconShow-' + index.toString()).style.display == "block") {
				document.getElementById('iconShow-' + index.toString()).style.display = "none"
				document.getElementById('iconNotShow-' + index.toString()).style.display = "block"
			} else {
				document.getElementById('iconShow-' + index.toString()).style.display = "block"
				document.getElementById('iconNotShow-' + index.toString()).style.display = "none"
			}
		},
		getContrastColor(hexColor) {
			hexColor = hexColor.replace('#', '');

			let r = parseInt(hexColor.substring(0, 2), 16);
			let g = parseInt(hexColor.substring(2, 4), 16);
			let b = parseInt(hexColor.substring(4, 6), 16);

			let brightness = (r * 299 + g * 587 + b * 114) / 1000;

			return brightness > 128 ? 'black' : 'white';
		}
	}
};
</script>

<style type="text/css" scoped>
#progressbarMain li:before,
#progressbarMain li:after {
	background: var(--liBgColor);
}

#progressbarMain li {
	color: var(--liColor) !important;
	margin-left: var(--liMarginLeft);
}

#progressbarMain li:before {
	color: #000000;
	counter-reset: liContent var(--liContent);
	content: counter(liContent) ' %';
}

#progressbarMain li:before {
	width: 50px;
	height: 50px;
	line-height: 45px;
	display: block;
	font-size: 20px;
	color: var(--liTextColor, #ff00ff);
	border-radius: 50%;
	margin: 0 auto 10px auto;
	padding: 2px
}

#progressbarMain li:after {
	content: '';
	height: 2px;
	position: absolute;
	left: 98%;
	top: 25px;
	z-index: 0;
	width: var(--liAfterWidth);
}

#progressbarMain li:before,
#progressbarMain li:after {
	background: var(--liBgColor);
}

#progressbarMain {
	margin-bottom: 30px;
	overflow: hidden;
	width: 100%;
}

#progressbarMain .trainee-active {
	color: #5ABBD1 !important;
}

#progressbarMain .junior-active {
	margin-left: 17%;
	color: #AFE89E !important;
}

#progressbarMain .middle-active {
	margin-left: 19%;
	color: #E8E379 !important;
}

#progressbarMain .senior-active {
	margin-left: 19%;
	color: #E89292 !important;
}

#progressbarMain li {
	list-style-type: none;
	font-size: 15px;
	float: left;
	position: relative;
	font-weight: 400
}

#progressbarMain #trainee:before {
	color: #000000;
	content: "0 %"
}

#progressbarMain #junior:before {
	color: #000000;
	content: "25 %"
}

#progressbarMain #middle:before {
	color: #000000;
	content: "50 %"
}

#progressbarMain #senior:before {
	color: #000000;
	content: "75 %"
}

#progressbarMain li.trainee-active:before,
#progressbarMain li.junior-active:before,
#progressbarMain li.middle-active:before,
#progressbarMain li.senior-active:before {
	width: 50px;
	height: 50px;
	line-height: 45px;
	display: block;
	font-size: 20px;
	color: #ffffff;
	border-radius: 50%;
	margin: 0 auto 10px auto;
	padding: 2px
}

#progressbarMain li.trainee-active:after,
#progressbarMain li.junior-active:after,
#progressbarMain li.middle-active:after,
#progressbarMain li.senior-active:after {
	content: '';
	height: 2px;
	position: absolute;
	left: 98%;
	top: 25px;
	z-index: 0;
}

#progressbarMain li.trainee-active:after {
	width: 301%;
}

#progressbarMain li.junior-active:after,
#progressbarMain li.middle-active:after {
	width: 340%;
}

#progressbarMain li.senior-active:after {
	width: 375%;
}

#progressbarMain li.trainee-active:before,
#progressbarMain li.trainee-active:after {
	background: #5ABBD1;
}

#progressbarMain li.junior-active:before,
#progressbarMain li.junior-active:after {
	background: #AFE89E;
}

#progressbarMain li.middle-active:before,
#progressbarMain li.middle-active:after {
	background: #E8E379;
}

#progressbarMain li.senior-active:before,
#progressbarMain li.senior-active:after {
	background: #E89292;
}

#progressbarPDF li:before,
#progressbarPDF li:after {
	background: var(--liBgColor);
}

#progressbarPDF li {
	color: var(--liColor) !important;
	margin-left: var(--liMarginLeft);
}

#progressbarPDF li:before {
	color: #000000;
	counter-reset: liContent var(--liContent);
	content: counter(liContent) ' %';
}

#progressbarPDF li:before {
	width: 40px;
	height: 40px;
	line-height: 37px;
	display: block;
	font-size: 15px;
	color: #ffffff;
	border-radius: 50%;
	margin: 0 auto 10px auto;
	padding: 2px
}

#progressbarPDF li:after {
	content: '';
	height: 2px;
	position: absolute;
	left: 98%;
	top: 25px;
	z-index: 0;
	width: var(--liAfterWidth);
}

#progressbarPDF li:before,
#progressbarPDF li:after {
	background: var(--liBgColor);
}

#progressbarPDF {
	margin-bottom: 30px;
	overflow: hidden;
	width: 100%;
}

#progressbarPDF .trainee-active {
	color: #5ABBD1 !important;
}

#progressbarPDF .junior-active {
	margin-left: 17%;
	color: #AFE89E !important;
}

#progressbarPDF .middle-active {
	margin-left: 19%;
	color: #E8E379 !important;
}

#progressbarPDF .senior-active {
	margin-left: 19%;
	color: #E89292 !important;
}

#progressbarPDF li {
	list-style-type: none;
	font-size: 15px;
	float: left;
	position: relative;
	font-weight: 400
}

#progressbarPDF #trainee:before {
	color: #000000;
	content: "0 %"
}

#progressbarPDF #junior:before {
	color: #000000;
	content: "25 %"
}

#progressbarPDF #middle:before {
	color: #000000;
	content: "50 %"
}

#progressbarPDF #senior:before {
	color: #000000;
	content: "75 %"
}

#progressbarPDF li.trainee-active:before,
#progressbarPDF li.junior-active:before,
#progressbarPDF li.middle-active:before,
#progressbarPDF li.senior-active:before {
	width: 50px;
	height: 50px;
	line-height: 45px;
	display: block;
	font-size: 20px;
	color: #ffffff;
	border-radius: 50%;
	margin: 0 auto 10px auto;
	padding: 2px
}

#progressbarPDF li.trainee-active:after,
#progressbarPDF li.junior-active:after,
#progressbarPDF li.middle-active:after,
#progressbarPDF li.senior-active:after {
	content: '';
	height: 2px;
	position: absolute;
	left: 98%;
	top: 25px;
	z-index: 0;
}

#progressbarPDF li.trainee-active:after {
	width: 301%;
}

#progressbarPDF li.junior-active:after,
#progressbarPDF li.middle-active:after {
	width: 340%;
}

#progressbarPDF li.senior-active:after {
	width: 375%;
}

#progressbarPDF li.trainee-active:before,
#progressbarPDF li.trainee-active:after {
	background: #5ABBD1;
}

#progressbarPDF li.junior-active:before,
#progressbarPDF li.junior-active:after {
	background: #AFE89E;
}

#progressbarPDF li.middle-active:before,
#progressbarPDF li.middle-active:after {
	background: #E8E379;
}

#progressbarPDF li.senior-active:before,
#progressbarPDF li.senior-active:after {
	background: #E89292;
}

.progress-pdf .full-progress-bar-analysis {
	height: 21px !important;
}
</style>
