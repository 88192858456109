import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["data", "options"],
  mounted() {
    this.renderBarChart();
  },
  computed: {
    chartData: function() {
      return this.data;
    }
  },
  methods: {
    renderBarChart: function() {
    this.renderChart(this.chartData,
      { responsive: true, maintainAspectRatio: false});    
    }
  },
  watch: {
    data: function() {
      this.$data._chart.destroy();
      this.renderBarChart();
    }
  }
};