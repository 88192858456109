<template>
	<div>
		<h4 v-if="selectedAreaName != null" class="reports-title">Registered certifications per profile level in {{selectedAreaName}}</h4>
		<h4 v-else class="reports-title">Registered certifications per profile level by areas</h4>
		<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option hidden value="">Select an area</option>
      		<option v-for="area in areas" :value="area.id">{{area.name}}</option>
    	</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
    	<div v-else>
			<pie-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId"
				:category="category" 
				:value="value"
				:tooltipNoDataMsg="tooltipNoDataMsg"
				:data="data"></pie-chart>
		</div>
	</div>
</template>

<script>
	import ReportsDataService from '@/services/ReportsDataService';
	import PieChart from "@/components/Others/Charts/PieChart.vue"

	export default {
		name: 'RegisteredCertificationsByAreaProfileLevels',
		data() {
			return {
				chartId: "chartRegisteredCertificationsByAreasProfileLevels",
				data: [],
				category: "name",
				value: "total_certifications",
					graphLoaded: false,
					graphLoading: false,
					selectedArea: "",
					selectedAreaName: null,
					tooltipNoDataMsg: "Please, select an area."
			}
		},
		components: {
			PieChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea))
						this.selectedAreaName = area.name
				});
				
				this.graphLoading = true;

				this.fetchData();
			},
			async fetchData() {
				await ReportsDataService.registeredCertificationsByAreaProfileLevels(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data

							if (Object.values(this.data).every(e => e.total_certifications === 0))
								this.data = [];

							this.tooltipNoDataMsg = this.data.length > 0 ? this.tooltipNoDataMsg : "There are no certifications in this area"; 
							this.graphLoading = false;
							this.graphLoaded = this.graphLoaded ? false : true
						}
						},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.graphLoading = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>