<template>
    <div class="col-sm-12 justify-content-center my-2">
        <div class="row noMargin">
            <div class="col-sm-12">
                <h4 class="titleClass">Set the parameters to search Professional Profiles</h4>
            </div>
        </div>
        <div class="row my-2 mx-3 ">
            <div class="col-xl-4 col-sm-12">
                <div class="row">
                    <label class="mt-2 text-left float-left ms-1" for="exampleFormControlSelect1">Select a profile*
                        <b-button class="btn btn-info" v-b-tooltip.hover
                            title="Select a profile of the current area to filter the CVs that match the profile, level and evaluation process in their analysis result"><font-awesome-icon
                                icon="info-circle" /></b-button></label>
                </div>
                <div class="row">
                    <select class="form-select" data-vv-name="technology_selector" v-model="techModel"
                        @change="onChangeTech($event)" id="technology_selector_questions_level">
                        <option v-for="element in technologies" v-bind:key="element.name" :value="element.name">
                            <template slot="button-content">
                                <img v-bind:src="element.logo" class="card-avatar card-avatar--circle" />
                                {{ element.name.toUpperCase() }}
                            </template>
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-sm-12 ">
                <div class="row">
                    <label class="mt-2 text-left float-left ms-1" for="exampleFormControlSelect1">Select a level*
                        <b-button class="btn btn-info" v-b-tooltip.hover
                            title="Select the level of the profile selected to filter the CVs that match the profile, level and evaluation process in their analysis result"><font-awesome-icon
                                icon="info-circle" /></b-button></label>
                </div>
                <div class="row">
                    <select class="form-select" data-vv-name="technology_selector" v-model="levelText"
                        @change="onChange($event)" id="technology_selector_questions_level">
                        <option v-for="element in profileLevels" v-bind:key="element.name" :value="element.name">
                            {{ element.name.toUpperCase() }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-sm-12 ">
                <div class="row">
                    <label class="mt-2 text-left float-left ms-1" for="exampleFormControlSelect1">Select a evaluation
                        process<b-button class="btn btn-info" v-b-tooltip.hover
                            title="(You must select a profile first) Select the evaluation process of the profile selected to filter the CVs that match the profile, level and evaluation process in their analysis result"><font-awesome-icon
                                icon="info-circle" /></b-button></label>
                </div>
                <div class="row">
                    <select class="form-select" data-vv-name="technology_selector" v-model="businessProcess"
                        @change="onChangeBusinessProcess($event)" id="technology_selector_questions_level">
                        <option value="null">
                            Select a evaluation process
                        </option>
                        <option v-for="element in businessProcessList" v-bind:key="element.name" :value="element.name">
                            {{ element.name.toUpperCase() }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-xl-4 mb-3 col-centered">
                <button block class="btn btn-primary col-md-6 button-style upskilling-search-btn"
                    @click="handleSearch()" v-b-tooltip.hover title="Search profile"><font-awesome-icon icon="search"
                        class="white-color" />
                    &nbsp;Search</button>
            </div>
        </div>
        <div class="col-sm-12" v-if="searchSuccessful">
            <h4 class="titleClass" @click="visibleAccomplish = !visibleAccomplish">Profiles that can upskill in
                "{{ techSearch }}"
                <font-awesome-icon v-if="!visibleAccomplish" class="trash-icon" v-b-tooltip.hover title="Expand"
                    icon="chevron-down" />
                <font-awesome-icon v-if="visibleAccomplish" class="trash-icon" v-b-tooltip.hover title="Contract"
                    icon="chevron-up" />
            </h4>
        </div>
        <div class="row my-2 noMargin" v-if="searchSuccessful && visibleAccomplish">
            <template v-if="profilesFound.length > 0">
                <div class="row" style="margin-top: 1%;">
                    <div class="col-sm-5">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" responsive
                            aria-controls="certificationsList"></b-pagination>
                    </div>
                    <div class="col-sm-3">
                        <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
                            :options="pageOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4">
                        <b-form-input id="filter-input" v-model="filter" type="search"
                            :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                    </div>
                </div>
                <b-table ref="certificationsTable" id="certificationsList" :per-page="perPage"
                    :current-page="currentPage" :fields="headers" :items="profilesFound" :filter="filter"
                    @row-selected="onResultSelected">
                    >
                    <template #cell(image)="data">
                        <img tdSmall v-bind:src="data.item.image" @error="imageLoadError($event, data.item)"
                            class="card-avatar card-avatar--circle imageAvatar" />
                    </template>
                    <template #cell(name)="data">
                        <p>{{ data.item.name }}</p>
                        <p :style="'background:' + data.item.color" class="levelStyle">{{ data.item.level.toUpperCase()
                            }}</p>
                    </template>
                    <template #cell(description)="data">
                        <p v-if="data.item.description != ''">{{ trimmedString(data.item.description) }}</p>
                        <p v-else>Description not available</p>
                    </template>
                    <template #cell(experience)="data">
                        <p v-if="data.item.experience_by_profile != undefined && data.item.experience_by_profile != ''">
                            {{ data.item.experience_by_profile.years }} years and
                            {{ data.item.experience_by_profile.months }} months</p>
                        <p v-else>Experience not available</p>
                    </template>
                    <template #cell(progress)="data">
                        <p :style="'background:' + data.item.color" class="percentajeStyle">{{ data.item.progress }}%
                        </p>
                    </template>
                    <template #cell(progress_until_next_level)="data">
                        <p :style="'background:' + colorMissing" class="percentajeStyle">
                            {{ data.item.progress_until_next_level }}% </p>
                    </template>
                    <template #cell(options)="data">
                        <button class="btn btn-secondary" @click="onResultSelected(data.item)"><font-awesome-icon
                                icon="eye" /></button>
                    </template>
                </b-table>
            </template>
            <template v-else>
                <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                    found.</h3>
            </template>
        </div>

        <div class="col-sm-12" v-if="searchSuccessful">
            <h4 class="titleClass" @click="visibleNear = !visibleNear">Profiles that can reskill into "{{ techSearch }}
                {{ levelSearch }}"
                <font-awesome-icon v-if="!visibleNear" class="trash-icon" v-b-tooltip.hover title="Expand"
                    icon="chevron-down" />
                <font-awesome-icon v-if="visibleNear" class="trash-icon" v-b-tooltip.hover title="Contract"
                    icon="chevron-up" />
            </h4>
        </div>
        <div class="row my-2 noMargin" v-if="searchSuccessful && visibleNear">
            <template v-if="profilesProximity.length > 0">
                <div class="row" style="margin-top: 1%;">
                    <div class="col-md-8 col-lg-5">
                        <b-pagination v-model="currentPageNear" :total-rows="rowsProximity" :per-page="perPageNear"
                            responsive aria-controls="certificationsList"></b-pagination>
                    </div>
                    <div class="col-lg-3 col-md-9">
                        <b-form-select class="numPerPage paginationSelectorTable" v-model="perPageNear"
                            :options="pageOptions"></b-form-select>
                    </div>
                    <div class="col-lg-4 margin-on-lg">
                        <b-form-input id="filter-input" v-model="filter" type="search"
                            :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                    </div>
                </div>
                <b-table ref="certificationsTable" style="overflow-x: auto;" id="certificationsList"
                    :per-page="perPageNear" :current-page="currentPageNear" :fields="headersNear"
                    :items="profilesProximity" :filter="filterProximity" @row-selected="onResultSelected">
                    >
                    <template #cell(image)="data">
                        <img v-bind:src="data.item.image" @error="imageLoadError($event, data.item)"
                            class="card-avatar card-avatar--circle imageAvatar" />
                    </template>
                    <template #cell(name)="data">
                        <p> {{ data.item.name }}</p>
                        <img v-bind:src="data.item.logo" class="card-avatar card-avatar--circle technologyAvatar" />
                        <p :style="'background:' + data.item.color" class="levelStyle">{{
                        data.item.profile_level_base.toUpperCase() }}</p>
                    </template>
                    <template #cell(description)="data">
                        <p v-if="data.item.description != ''">{{ trimmedString(data.item.description) }}</p>
                        <p v-else>Description not available</p>
                    </template>
                    <template #cell(hours)="data">
                        <p v-if="data.item.hours != ''">{{ data.item.hours }} hours <b-button
                                v-if="data.item.hours != 0" class="btn btn-info" v-b-tooltip.html
                                :title="data.item.hours_description"><font-awesome-icon icon="info-circle" /></b-button>
                        </p>
                    </template>
                    <template #cell(percentage)="data">
                        <p :style="'background:' + colorPerfil" class="percentajeStyle">{{ data.item.percentage }}% </p>
                    </template>
                    <template #cell(options)="data">
                        <button class="btn btn-secondary" @click="onResultSelected(data.item)"><font-awesome-icon
                                icon="eye" /></button>
                    </template>
                </b-table>
            </template>
            <template v-else>
                <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results
                    found.</h3>
            </template>
        </div>
    </div>
</template>

<script>
import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
import UserDataService from '@/services/UserDataService';
import SelectionProcessService from '@/services/SelectionProcessService';
import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
import FilterObj from '@/models/filter';

export default {
    name: 'Search',
    data() {
        return {
            selectedTechId: "",
            selectedLevelId: "",
            selectedbusinessProcessId: "",
            profileLevels: "",
            technologies: [],
            businessProcessList: [],
            businessProcess: null,
            levelText: "",
            techModel: "",
            techSearch: "",
            levelSearch: "",
            filterStructure: new FilterObj(this.$store.state.auth.user.active_areas[this.$session.get("tabID")], 'AND', ''),
            searchSuccessful: false,
            profilesFound: [],
            profilesProximity: [],
            results: "",
            perPage: 5,
            perPageNear: 5,
            currentPage: 1,
            currentPageNear: 1,
            filter: null,
            filterProximity: null,
            currentUrl: location.host,
            pageOptions: this.$paginationOptions,
            length: 100,
            visibleAccomplish: true,
            visibleNear: true,
            colorPerfil: "#5ABBD1",
            colorMissing: "#FF8888",
            headers: [
                { key: 'image', label: '', sortable: false, tdClass: 'tdXSmall' },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'progress', label: '% accomplished', sortable: true },
                { key: 'progress_until_next_level', label: '% left', sortable: true },
                { key: 'experience', label: 'Experience', sortable: false },
                { key: 'description', label: 'Description' },
                { key: 'options', label: 'Options' }
            ],
            headersNear: [
                { key: 'image', label: '', sortable: false, tdClass: 'tdXSmall' },
                { key: 'name', label: 'Name', sortable: true },
                { key: 'hours', label: 'Reskilling effort (hours)', sortable: true },
                { key: 'description', label: 'Description' },
                { key: 'options', label: 'Options' }
            ],
        }
    },
    computed: {
        rows() {
            return this.profilesFound.length
        },
        rowsProximity() {
            return this.profilesProximity.length
        },
    },
    watch: {
        techModel() {
            this.businessProcess = null
            this.profilesFound = []
            this.profilesProximity = []
            this.searchSuccessful = false
        },
        levelText() {
            this.profilesFound = []
            this.profilesProximity = []
            this.searchSuccessful = false
        }
    },
    mounted() {
        const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
        this.findLevelsConfigurationByArea(currentArea)
        this.findProfilesByArea(currentArea)
    },
    methods: {
        findLevelsConfigurationByArea(areaID) {
            ProfileConfigurationDataService.findLevelsConfigurationByArea(areaID).then(
                response => {
                    if (response.status == 200) {
                        this.technologies = response.data
                        for (var i = 0; i < this.technologies.length; i++)
                            this.technologies[i].logo = this.$awsBucketRoute + this.technologies[i].logo;
                        this.techModel = this.technologies[0].name
                        this.selectedTechId = this.technologies[0].id
                        this.findBusinessProcessByAreaAndProfile()
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        findProfilesByArea(areaID) {
            ProfileConfigurationDataService.findByArea(areaID).then(
                response => {
                    if (response.data.length <= 0 || response.data == undefined || response.data == null) {
                        this.$router.push('/modules');
                    } else {
                        this.profileLevels = response.data;
                        this.levelText = this.profileLevels[0].name
                        this.selectedLevelId = this.profileLevels[0].id
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.$router.push('/modules');

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        findBusinessProcessByAreaAndProfile() {
            SelectionProcessService.findByProfile(this.selectedTechId).then(
                response => {
                    if (200 == response.status) {
                        this.businessProcessList = response.data;
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.$router.push('/modules');

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        trimmedString(text) {
            text = text.toString()
            var string = text.length > this.length ? text.substring(0, this.length - 3) + "..." : text;
            return string;
        },
        imageLoadError(event, item) {
            if (item.id_user != null && item.id_user != undefined) {
                UserDataService.findOne(item.id_user).then(
                    response => {
                        if (response.data.avatar != undefined && response.data.avatar != null)
                            event.target.src = this.$awsBucketRoute + response.data.avatar;
                        else
                            item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
                    },
                    error => {
                        item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                )
            } else
                item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';

            event.target.src = "http://" + this.currentUrl + '/images/icons/user.png';
        },
        onResultSelected(item) {
            let id = item.id
            let routeData = this.$router.resolve({ name: 'profile-analysis', query: { id: id, previousPage: 'development' } });
            window.open(routeData.href, '_blank');
        },
        getColor(color) {
            for (let i = 0; i < this.profileLevels.length; i++) {
                if (this.profileLevels[i].name.toLowerCase() == color.toLowerCase())
                    return this.profileLevels[i].color
            }
        },
        onChangeTech(event) {
            if (event.target.value != undefined) {
                this.technologies.forEach(tech => {
                    if (event.target.value == tech.name) {
                        this.selectedTechId = tech.id;
                        this.findBusinessProcessByAreaAndProfile()
                    }
                })
            }
        },
        onChange(event) {
            if (event.target.value != undefined) {
                this.profileLevels.forEach(level => {
                    if (event.target.value == level.name)
                        this.selectedLevelId = level.id;
                });
            }
        },
        onChangeBusinessProcess(event) {
            if (event.target.value != undefined) {
                this.businessProcessList.forEach(businessProcess => {
                    if (event.target.value == businessProcess.name)
                        this.selectedbusinessProcessId = businessProcess.id;
                });
            }
        },
        handleSearch() {
            this.techSearch = this.techModel
            this.levelSearch = this.levelText
            this.profilesFound = []
            this.colorPerfil = this.getColor(this.levelText)
            this.findOrdered()
        },
        findOrdered() {
            console.log('findOrdered')
            this.profilesFound = []

            ProfileAnalysisDataService.findOrderedByPreviousProfileLevel(this.selectedTechId, this.selectedLevelId, this.selectedbusinessProcessId).then(
                response => {
                    console.log('response findOrdered: ', response.data)
                    if (response.status == 200) {
                        this.searchSuccessful = true
                        this.profilesFound = response.data
                        for (let i = 0; i < this.profilesFound.length; i++) {
                            if (this.profilesFound[i].progress_until_next_level < 10)
                                this.profilesFound[i].progress_until_next_level = this.profilesFound[i].progress_until_next_level.toString().slice(0, 4)
                            else this.profilesFound[i].progress_until_next_level = this.profilesFound[i].progress_until_next_level.toString().slice(0, 5)

                            this.profilesFound[i].color = this.getColor(this.profilesFound[i].level)
                        }
                    } else {
                        this.message = response.data.message
                        this.profilesFound = []
                    }

                    this.findProximity()
                },
                error => {
                    this.profilesFound = []
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    this.message = error.response.data.message
                    this.findProximity()

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        findProximity() {
            console.log('findProximity')
            this.profilesProximity = []

            ProfileAnalysisDataService.findSortedCVsByRecycleProfileProximity(this.selectedTechId, this.selectedLevelId, this.selectedbusinessProcessId).then(
                response => {
                    console.log('response findProximity: ', response.data)
                    if (response.status == 200) {
                        let profilesTmp = response.data

                        for (let j = 0; j < profilesTmp.length; j++) {
                            if (profilesTmp[j].isRepeated != true)
                                profilesTmp[j].isRepeated = false

                            for (let i = 0; i < this.profilesFound.length; i++) {
                                if (this.profilesFound[i].id == profilesTmp[j].id)
                                    profilesTmp[j].isRepeated = true
                            }
                        }
                        for (let i = 0; i < profilesTmp.length; i++) {
                            this.profilesProximity.push(profilesTmp[i])
                            let index = this.profilesProximity.length - 1
                            this.profilesProximity[index].color = this.getColor(this.profilesProximity[index].profile_level_base)
                            this.profilesProximity[index].logo = this.$awsBucketRoute + this.profilesProximity[index].profile_base_image
                            this.profilesProximity[index].hours_description = ''

                            for (let j = 0; j < this.profilesProximity[index].pending_items.length; j++) {
                                let text = ''
                                if (this.profilesProximity[index].pending_items[j].name !== undefined)
                                    text = '- ' + this.profilesProximity[index].pending_items[j].name + ': ' + this.profilesProximity[index].pending_items[j].hours + ' hours<br>'
                                else if (this.profilesProximity[index].pending_items[j].item !== undefined)
                                    text = '- ' + this.profilesProximity[index].pending_items[j].item + ': ' + this.profilesProximity[index].pending_items[j].hours + ' hours<br>'

                                this.profilesProximity[index].hours_description += text
                            }
                        }
                    } else {
                        this.profilesProximity = []
                        this.message = response.data.message
                    }

                    console.log('profilesProximity: ', this.profilesProximity)
                },
                error => {
                    this.profilesProximity = []
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.message = error.response.data.message

                    if (error.response.status === 300) {
                        console.error('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
    }
};
</script>

<style scoped>
@media (max-width: 992px) {
    .margin-on-lg {
        margin-top: 1rem !important;
    }
}
</style>