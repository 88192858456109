<template>
	<div>
		<h4 class="reports-title">Exams diagram by Marks</h4>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<barr-chart 
				:ref="chartId"
				:chartId="chartId"
				:key="graphLoaded"
				:xAxesCategory="xAxesCategory" 
				:yAxesValue="yAxesValue" 
				:xAxesTitle="xAxesTitle"
				:yAxesTitle="yAxesTitle"
				:data="data"></barr-chart>
		</div>
	</div>
</template>

<script>
import ReportsDataService from '@/services/ReportsDataService';
import BarrChart from "@/components/Others/Charts/BarrChart.vue"

  export default {
    name: 'UserExamsGraphByMarks',
    data() {
    	return {
    		chartId: "chartUserExamsGraphByMarks",
			data: [],
	    	xAxesCategory: "exam",
	    	yAxesValue: "mark",
	    	xAxesTitle: "Exam ID",
	    	yAxesTitle: "Student Mark",
	    	graphLoaded: false,
	    	graphLoading: false
    	}
    },
	props: {
		user: Object,
		userAreaID: Number
	},
    components: {
    	BarrChart
    },
    methods: {
    	async fetchData() {
    		await ReportsDataService.testResultsByUserInArea(this.user.id, this.userAreaID).then(
				response => {
					if (response.status == 200) {
						let testResults = response.data;
						testResults.forEach((result) => {
							let tmp = {}
							tmp.exam = result.id_test
							tmp.exam_title = result.test.title
							tmp.mark = result.average_qualification
							this.data.push(tmp)
						})
						
						this.graphLoaded = this.graphLoaded ? false : true;
						this.graphLoading = false;
					}
				},
				error => {
					this.graphLoading = false;
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
    	}
    },
    mounted() {
      	this.graphLoading = true;
    	this.fetchData();
  	}
  };
</script>