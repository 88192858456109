<template>
	<div class="row" v-if="!pdf">
		<h4 style="margin-top: 1%;">User Assessment Test Results</h4>

		<template v-if="userTests.length > 0">
	  		<div class="row mt-row">
				<div class="col-sm-3">
					<b-pagination
						v-model="currentPage"
						:total-rows="rows"
						:per-page="perPage"
						aria-controls="userTests"
				    ></b-pagination>
				</div>
				<div class="col-sm-7">
					<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
				</div>
				<div class="col-sm-2">
					<b-form-input 
						id="filter-input"
		              	v-model="filter"
		              	type="search"
		              	placeholder="Search"
	            	></b-form-input>
				</div>
			</div>
			
		  	<b-table ref="userTestsTable"
				id="userTests"
		      	:per-page="perPage"
		      	:current-page="currentPage"
				:fields="headers" 
				:items="userTests"
				:filter="filter" 
			    responsive="sm"
			    v-bind:class = "{'clickable':clickable}">
				<template #cell(title)="data">
			        <p v-if="data.item.title != ''">{{ data.item.title }}</p>
			        <p v-else>N/A</p>
		      	</template>
				<template #cell(tags)="data">
					<ul v-if="data.item.tags.length > 0" class="inline-tags">
			          	<li v-for="tag in data.item.tags" v-bind:key="tag" tabindex="0" class="ti-tag-user-test ti-valid inline-tags">
				          	<div class="ti-content"> 
				          		<div class="ti-tag-center">
				          			<span class="">{{tag}}</span>  
				          		</div> 
				          	</div>
				      	</li>
			        </ul>
			        <p v-else class="justify-text">No tags</p>
		      	</template>
				<template #cell(description)="data">
		          	<p v-if="data.item.description != null && data.item.description != ''" class="justify-text">{{ data.item.description }}</p>
		          	<p v-else class="justify-text">No description.</p>
		      	</template>
		      	<template #cell(language)="data">
		          	<p>{{ data.item.language }}</p>
		      	</template>
		      	<template #cell(average_qualification)="data">
			        <p :class="data.item.average_qualification > 5 ? 'completed-icon-yes' : 'completed-icon-no'">{{ data.item.average_qualification }}</p>
		      	</template>
				<template #cell(difficultyLevel)="data">
			        <p class="difficultyLevelTag" :style="'background-color: ' + colorDiff[data.item.difficulty_level]">{{data.item.difficulty_level.toUpperCase()}}</p>
		      	</template>
				<template #cell(completedAt)="data">
			        <p>{{ data.item.attempt_date | formatEnglishDate }}</p>
		      	</template>
				<template #cell(options)="data">
					<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show test results" class="btn btn-secondary" @click="data.toggleDetails">
						<font-awesome-icon icon="eye" />
					</button>
					<button v-else v-b-tooltip.hover title="Hide test results" class="btn btn-secondary" @click="data.toggleDetails">
						<font-awesome-icon icon="eye-slash" />
					</button>
		      	</template>
		      	<template #row-details="data">
			        <test-result :testID="data.item.id" :userID="userID"/>
			    </template>
			</b-table>
		</template>
		<template v-else>
			<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No tests found.</h3>
		</template>
	</div>
	<div class="row" v-else>
		<div class="col-sm-12">
			<h4 style="margin-top: 1%; text-align: left;border-bottom: 3px solid;padding-bottom: 9px; margin-bottom: 14px; font-size: 18.66px">User Assessment Test Results</h4>
			<template v-if="userTests.length > 0">
				<b-table style="font-size: 13px; padding-top: 0px; padding-right: 0px; padding-left: 0px; border: none; box-shadow: 0 0 0 0" ref="userTestsTable"
					id="userTests"
					:per-page="perPage"
					:current-page="currentPage"
					:fields="headersPDF" 
					:items="userTests"
					:filter="filter" 
					responsive="sm"
					v-bind:class = "{'clickable':clickable}">
					<template #cell(title)="data">
						<p v-if="data.item.title != ''">{{ data.item.title }}</p>
						<p v-else>N/A</p>
					</template>
					<template #cell(tags)="data">
						<ul v-if="data.item.tags.length > 0" class="inline-tags">
							<li style="font-size: 7px;margin-left: 10px !important; margin-bottom: 2px;" v-for="tag in data.item.tags" v-bind:key="tag" tabindex="0" class="ti-tag-user-test ti-valid inline-tags">
								<div class="ti-content"> 
									<div class="ti-tag-center">
										<span class="">{{tag}}</span>  
									</div> 
								</div>
							</li>
						</ul>
						<p v-else class="justify-text" style="margin-left: 10px !important">No tags</p>
					</template>
					<template #cell(description)="data">
						<p v-if="data.item.description != null && data.item.description != ''" class="justify-text">{{ data.item.description }}</p>
						<p v-else class="justify-text">No description.</p>
					</template>
					<template #cell(language)="data">
						<p>{{ data.item.language }}</p>
					</template>
					<template #cell(average_qualification)="data">
						<p v-if="data.item.average_qualification" :class="data.item.average_qualification > 5 ? 'completed-icon-yes' : 'completed-icon-no'">{{ parseFloat(data.item.average_qualification).toFixed(2) }}</p>
					</template>
					<template #cell(difficultyLevel)="data">
						<p class="difficultyLevelTag" :style="'background-color: ' + colorDiff[data.item.difficulty_level]">{{data.item.difficulty_level.toUpperCase()}}</p>
					</template>
					<template #cell(completedAt)="data">
						<p>{{ data.item.attempt_date | formatEnglishDate }}</p>
					</template>
					<template #cell(options)="data">
						<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show test results" class="btn btn-secondary" @click="data.toggleDetails">
							<font-awesome-icon icon="eye" />
						</button>
						<button v-else v-b-tooltip.hover title="Hide test results" class="btn btn-secondary" @click="data.toggleDetails">
							<font-awesome-icon icon="eye-slash" />
						</button>
					</template>
					<template #row-details="data">
						<test-result :testID="data.item.id" :userID="userID" :pdf="true"/>
					</template>
				</b-table>
			</template>
			<template v-else>
				<h3 class="no-results-profile-msg" style="font-size: 14.66px"><font-awesome-icon icon="times-circle" class="black-color"/> No tests found.</h3>
			</template>
		</div>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";
	import TestResult from "@/components/TestComponents/Result.vue";

	export default {
	  	name: "UserFinishedTestsResults",
	  	data() {
		  	return {
		        perPage: 10,
	        	currentPage: 1,
	        	pageOptions: this.$paginationOptions,
	        	clickable: true,
	        	filter: null,
	        	colorDiff: {},
	        	difficulties: [],
	        	userTests: [],
		  	}
		},
		components: {
			TestResult
		},
		props: {
			userID: Number,
			pdf: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			rows() {
				return this.userTests.length
			},
			headers(){
				return [
					{ key: 'title', sortable: true, label: "Title" }, 
					{ key: 'tags', label: "Tags" },
					{ key: 'description', label: 'Description' }, 
					{ key: 'language', label: 'Language' }, 
					{ key: 'average_qualification', label: 'Average Score' }, 
					{ key: 'difficultyLevel', sortable: true, label: "Level of difficulty"}, 
					{ key: 'completedAt', sortable: true, label: "Completed at" },
					{ key: 'options', label: 'Options' }
				]
			},
			headersPDF(){
				return [
					{ key: 'title', label: "Title" }, 
					{ key: 'tags', label: "Tags" },
					{ key: 'average_qualification', label: 'Average Score',  tdClass:'pdf-text-center'  },
					{ key: 'completedAt', label: "Completed at", tdClass:'pdf-text-center' }
				]
			} 
	    },
	    mounted() {
	    	this.getTestBoard();

			if (this.userID != -1) {
				if (this.userID != null){
					this.getUserFinishedTestResults();
				}
				else{
					this.getCandidateFinishedTestResults();
				}
			}
	    },
		methods: {
			getTestBoard() {
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				TestDataService.getTestBoard(currentArea).then(
					response => {
						if (response.status == 200){
							this.difficulties = response.data.difficulty_levels;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			getUserFinishedTestResults() {
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				TestDataService.userTestsResults(this.userID, currentArea).then(
					response => {
						if (response.status == 200){
							this.userTests = response.data.map(function (d) { return d.test });
							
							this.difficulties.forEach((difficulty) => {
								this.colorDiff[difficulty.name] = difficulty.color
							});
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			getCandidateFinishedTestResults() {
				const candidateId = this.$route.query.id
				const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
				TestDataService.candidateTestsResults(candidateId, currentArea).then(
					response => {
						if (response.status == 200){
							this.userTests = response.data.map(function (d) { return d.test });
							
							this.difficulties.forEach((difficulty) => {
								this.colorDiff[difficulty.name] = difficulty.color
							});
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>

<style type="text/css" scoped>
.justify-text {
	text-align: justify;
}

.pdf-text-center{
	text-align: left !important;
}
</style>