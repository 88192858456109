import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'language/';

class LanguageDataService {

  getLanguageBoard() {
    return http.get(API_URL_PATH + 'languageBoard', { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+parseInt(id), { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  create(language) {
    const newLanguage = {name: language.name, id_area: language.id_area}
    return http.post(API_URL_PATH + 'create', { language: newLanguage }, { headers: authHeader() });
  }

  update(language) {
    const updatedLanguage = {name: language.name}
    return http.put(API_URL_PATH + 'update', {  language: language }, { headers: authHeader() });
  }

}

export default new LanguageDataService();