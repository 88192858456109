<template>
	<div class="dashboard">
		<div class="secondNavbar">
			<div class="links-container">
				<router-link v-if="isAdmin" :to="{ path: '/admin' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
				<router-link v-else-if="isTM" :to="{ path: '/talent_manager' }"
					class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to
					Home</router-link>
			</div>
			<div class="whiteColor">{{ title }}</div>
		</div>
		<div class="cards">
			<div class="row full-width justify-content-center">
				<template v-for="card in cards">
					<menu-card :cardRouteName="card.routeName" :cardImage="card.image" :cardTitle="card.title"
						:cardDescription="card.description" v-bind:key="card.title"></menu-card>
				</template>
			</div>
		</div>
		<div class="fixed-bottom">
			<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
import FooterMini from "@/components/FooterMini.vue";
import MenuCard from '@/components/MenuCard';

export default {
	name: 'SubmenuModulesAdmin',
	data() {
		return {
			title: this.$t('dashboards.admin.modules.title'),
			cards: []
		}
	},
	components: {
		MenuCard,
		appFooter: FooterMini
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isAdmin() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'admin') {
					return true;
				}
			}
			return false;
		},
		isTM() {
			if (this.currentUser && this.currentUser.role) {
				if (this.currentUser.role.name === 'talent_manager') {
					return true;
				}
			}
			return false
		},
	},
	mounted() {
		this.cards.push({
			"routeName": "/business-process",
			"image": "images/icons2024/admin_area_suitetoolsevaluationprocess.svg",
			"title": "Evaluation Process",
			"description": "Search required profiles by reskilling configuration"
		})

		if (this.currentUser.company.cv_module_enabled || this.currentUser.company.hard_skills_module_enabled |
			this.currentUser.company.soft_skills_module_enabled || this.currentUser.company.evaluation_360_module_enabled ||
			this.currentUser.company.videointerviews_module_enabled) this.cards.push({
				"routeName": "/evaluation",
				"image": "images/icons2024/admin_area_suitetoolsevaluationresources.svg",
				"title": 'Evaluation Resources',
				"description": 'Manage the evaluation of the candidates in your company'
			})

		if (this.currentUser.company.cv_module_enabled) this.cards.push({
			"routeName": "/search-profile",
			"image": "images/icons2024/admin_area_suitetoolssearchprofiles.svg",
			"title": "Search Professional Profiles",
			"description": "Filter and search Professional Profiles in your company"
		})

		if (this.currentUser.company.cv_module_enabled) this.cards.push({
			"routeName": "/search-professional-update",
			"image": "images/icons2024/admin_area_suitetoolsdevelopment.svg",
			"title": "Development",
			"description": "Search required profiles by reskilling configuration"
		})
	}
};

</script>

<style scoped>
#app>.bg-white .main-container {
	max-width: 100%;
	float: none;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 2rem;
	padding: 0;
	list-style-type: none;
	margin-top: 6rem !important;
}

.section {
	width: 250px;
	margin-bottom: 2rem;
}
</style>