<template>
    <div class="col-sm-12">
        <div class="row">
            <div class="col-sm-12">
                <div class="row" style="margin-top: 1% !important">
                    <div class="col-sm-12">
                        <h3>{{ summary.name }}</h3>
                    </div>
                </div>
                <div class="analysis-profile-container" style="margin-top: 1% !important">
                    <div class="row">
                        <div class="col-sm-2">
                            <div class="card-image">
                                <img v-if="summary.evaluation.users_evaluations[0].user.avatar == null" :src="userImage"
                                    class="profile-img" style="margin-top: 5% !important" />
                                <img v-else-if="summary.evaluation.users_evaluations[0].user.avatar.includes($awsBucketRoute)"
                                    v-bind:src="data.item.evaluator.avatar" class="profile-img"
                                    style="margin-top: 5% !important" />
                                <img v-else v-bind:src="$awsBucketRoute + summary.evaluation.users_evaluations[0].user.avatar"
                                    class="profile-img" style="margin-top: 5% !important" />
                            </div>
                        </div>
                        <div class="col-sm-6 mx-2">
                            <div class="row">
                                <h5 style="text-align: left;">{{ summary.evaluation.users_evaluations[0].user.name }} {{
                            summary.evaluation.users_evaluations[0].user.surname }}</h5>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 pt-0 pb-0">
                                    <p style="text-align: left; font-size: 16px;">
                                        <font-awesome-icon icon="user" v-b-tooltip.hover title="User Role" />
                                        &nbsp;&nbsp;
                                        {{ summary.evaluation.users_evaluations[0].user.role.name.charAt(0).toUpperCase() +
                            summary.evaluation.users_evaluations[0].user.role.name.slice(1) }}
                                    </p>
                                </div>
                                <div class="col-sm-6 pt-0 pb-0">
                                    <p style="text-align: left; font-size: 16px;">
                                        <font-awesome-icon icon="envelope" v-b-tooltip.hover title="User Email" />
                                        &nbsp;&nbsp;{{ summary.evaluation.users_evaluations[0].user.email }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3" style="padding-top: 1%">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5 style="text-align: center;">Average Evaluation Result</h5>
                                </div>
                                <div class="col-sm-12">
                                    <h3 style="text-align: center;">{{
                            summary.evaluation.users_evaluations[0].average_result.toFixed(2) }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Description</h4>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="text-align: justify">
                        <p>{{ summary.evaluation.description }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Evaluators</h4>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row" style="margin-top: 1%;">
                            <div class="col-sm-2">
                                <b-pagination v-model="currentPageEvaluators" :total-rows="rowsEvaluators"
                                    :per-page="perPageEvaluators" aria-controls="evaluatorsList"></b-pagination>
                            </div>
                            <div class="col-sm-7">
                                <b-form-select class="numPerPage paginationSelectorTable" v-model="perPageEvaluators"
                                    :options="pageOptionsEvaluators"></b-form-select>
                            </div>
                            <div class="col-sm-3">
                                <b-form-input id="filter-input" v-model="filterEvaluators" type="search"
                                    :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                            </div>
                        </div>
                        <b-table ref="evaluatorsTable" id="evaluatorsList" :per-page="perPageEvaluators"
                            :current-page="currentPageEvaluators" :filter="filterEvaluators"
                            :items="summary.evaluation.evaluation_evaluators" :fields="headersEvaluators"
                            :tbody-tr-class="rowClassEvaluators" select-mode="multi" responsive="sm">
                            <template #cell(avatar)="data">
                                <img v-if="data.item.evaluator.avatar == null" :src="userImage" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else-if="data.item.evaluator.avatar.includes($awsBucketRoute)"
                                    v-bind:src="data.item.evaluator.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else v-bind:src="$awsBucketRoute + data.item.evaluator.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                            </template>
                            <template #cell(name)="data">
                                {{ data.item.evaluator.name }}
                            </template>
                            <template #cell(surname)="data">
                                {{ data.item.evaluator.surname }}
                            </template>
                            <template #cell(email)="data">
                                {{ data.item.evaluator.email }}
                            </template>
                            <template #cell(role)="data">
                                {{ data.item.role.charAt(0).toUpperCase() + data.item.role.slice(1) }}
                            </template>
                            <template #cell(weight)="data">
                                {{ data.item.weight }}
                            </template>
                            <template #cell(evaluation_result)="data">
                                <div v-if="data.item.user_evaluation_result != null">
                                    {{ data.item.user_evaluation_result.toFixed(2) }}
                                </div>
                                <div v-else>
                                    0
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <p v-if="data.item.user_evaluation_result != null"><font-awesome-icon icon="check"
                                        style="color: green !important" v-b-tooltip.hover
                                        title="The evaluation has been completed for this evaluator." />&nbsp;&nbsp;<font-awesome-icon
                                        icon="eye" @click="showEvaluation(data.item)"
                                        style="cursor: pointer !important;" /></p>
                                <font-awesome-icon v-else icon="times-circle" style="color: red !important"
                                    v-b-tooltip.hover
                                    title="The evaluation has not been completed for this evaluator." />
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        summary: Object,
    },
    mounted() {
        console.log('summary en el componente: ', this.summary)
    },
    data() {
        return {
            headersEvaluators: [
                { key: 'avatar', label: 'Avatar', tdClass: 'tdSmall' },
                { key: 'name', label: 'Name', tdClass: 'tdSmall' },
                { key: 'surname', label: 'Surname', tdClass: 'tdMedium' },
                { key: 'email', label: 'Email', tdClass: 'tdMedium' },
                { key: 'role', label: 'Role', tdClass: 'tdSmall' },
                { key: 'weight', label: 'Weight', sortable: true, tdClass: 'tdSmall' },
                { key: 'evaluation_result', label: 'Evaluation result', tdClass: 'tdSmall' },
                { key: 'status', label: 'Status', tdClass: 'tdSmall' },
            ],
            perPageEvaluators: 10,
            currentPageEvaluators: 1,
            filterEvaluators: null,
            pageOptionsEvaluators: this.$paginationOptions,
            userImage: "http://" + location.host + '/images/icons/user.png',
        }
    },
    computed: {
        rowsEvaluators() {
            return this.summary.evaluation.evaluation_evaluators.length
        },
    },
    methods: {
        rowClassEvaluators(item, type) {
            if (!item || type !== 'row') return
            if (item.user_evaluation_result != null) return 'validated-question'
        },
        showEvaluation(evaluation) {
            console.log('this.$route.query.id_position_selection: ', this.$route.query.id_position_selection)
            this.$router.push('/softskills-configuration-evaluation-results?id=' + this.$route.query.id_evaluation + '&id_user=' + this.summary.evaluation.users_evaluations[0].user.id + '&id_evaluator=' + evaluation.evaluator.id + '&evaluation_process=' + this.$route.query.id_position_selection)
        }
    },
};
</script>