<template>
  <editor-content :editor="editor"/>
</template>

<script>
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'

  export default {
    components: {
      EditorContent,
    },
    props: {
      value: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        editor: null,
      }
    },
    watch: {
      value(value) {
        const isSame = this.editor.getHTML() === value
        if (isSame) {
          return
        }
        this.editor.commands.setContent(this.value, false)
      }
    },
    mounted() {
      this.editor = new Editor({
        extensions: [
          StarterKit,
        ],
        content: this.value,
        onUpdate: () => {
          this.$emit('input', this.editor.getHTML())
        },
      })
    },
    beforeDestroy() {
      this.editor.destroy()
    }
  }
</script>