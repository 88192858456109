<template>
  <div class="footer">
    <div class="row">
  		<div class="col-sm-6">
        <div class="row">
          <div class="col-md-12">
    		    <img id="footer-img" src="images/icono_cuadrado_v1.png"><h2 id="footer-sign">></h2>
          </div>
        </div>
        <div class="row">
    			<template v-for="link in footerLinks">
            <div class="footer-links">
      				<a class="navbar-brand mt-2 mt-lg-0" :href="link.url">{{link.title}}</a>
            </div>
          </template>
        </div>
        <div class="row">
          <h3 id="footer-title" class="footer-title">{{footerTitle}}</h3>
        </div>
  		</div>
  		<div class="col-sm-6">
        <img id="footer-img-form" src="images/icons/arbol_footer.png"> 
  			<h2 id="contact-title" class="text-normal">{{contactTitle}}</h2>
        <contact-form-footer></contact-form-footer>
  		</div>
    </div>
  </div>
</template>

<script>
  import ContactFormFooter from "@/components/ContactForm.vue"

  export default {
    name: "Footer",
    components: {
      ContactFormFooter
    },
    data: () => ({
      footerTitle: "Progradum 2024 |",
      contactTitle: "Contact us",
      nameFieldTitle: "Name",
      emailFieldTitle: "Email",
      commentFieldTitle: "Comment",
      footerLinks: [
        {
          url: '',
          title: 'About'
        },
        {
          url: '',
          title: 'Help'
        },
        {
          url: '',
          title: 'FAQ'
        },
        {
          url: '/privacy-policy',
          title: 'Privacy Policy'
        },
        {
          url: '/cookies-policy',
          title: 'Cookies Policy'
        },
        {
          url: 'data-protection-policy',
          title: 'Data Protection Policy'
        }
      ]
    })
  };
</script>