<template>
	<div class="form-container">
		<horizontal-stepper ref="stepper" :key="reloadComponent" :steps="formSteps" :top-buttons="true"
			@completed-step="completeStep" @stepper-finished="finishForm"></horizontal-stepper>

		<b-modal id="finish-modal" ref="finish-modal" title="Finish-modal" hide-footer>
			<template #modal-title>
				You have fully edited the soft skills process
			</template>
			<div>
				<p>You will be redirected to the soft skills processes list </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="redirect()" value="Accept">
		</b-modal>
	</div>
</template>

<script>
import Vue from "vue";
import HorizontalStepper from 'vue-stepper';
import GeneralDataStepToEdit from '@/components/S4Components/GeneralDataStepToEdit.vue';
import SoftskillsStepToEdit from '@/components/S4Components/SoftskillsStepToEdit.vue';
import UsersStepToEdit from '@/components/S4Components/UsersStepToEdit.vue';

export default {
	name: "CreateS4ProcessComponent",
	components: {
		HorizontalStepper,
	},
	data() {
		return {
			title: "Create soft skills Process",
			reloadComponent: false,
			formSteps: [
				{
					icon: 'person',
					name: 'first',
					title: 'Basic data',
					subtitle: 'Basic information about the soft skills process',
					component: GeneralDataStepToEdit,
					top_buttons: true,
					completed: false
				},
				{
					icon: 'grade',
					name: 'second',
					title: 'Softskills',
					subtitle: 'Select the softskills that will compose the soft skills process',
					component: SoftskillsStepToEdit,
					top_buttons: true,
					completed: false
				},
				{
					icon: 'person',
					name: 'third',
					title: 'Select candidates',
					subtitle: 'Select the candidates who will be part of the soft skills process',
					component: UsersStepToEdit,
					top_buttons: true,
					completed: false
				},
			],
			s4Process: null,
			message: "",
			successfull: false,
			submitted: false,
		}
	},
	mounted() {
	},
	methods: {
		handleUpdate() {
			console.log('')
		},
		completeStep(payload) {
			this.formSteps.forEach((step) => {
				if (step.name === payload.name) {
					step.completed = true;
				}
			})

			if (payload.name == 'first') Vue.prototype.$typeOfProcess = this.$refs["stepper"].$children[0].typeOfProcess
		},
		finishForm(payload) {
			let newUserData = {};

			this.$refs["stepper"].$children.forEach((step) => {
				console.log('')
			});
		},
		close() {
			document.getElementById("message").style.display = "none"
		},
		redirect() {
			console.log('');
		}
	}
};
</script>