<template>
  <ul class="other-skills-capsule">
    <li class="other-skills-capsule-data">{{ name }}</li>
    <li v-if="del" class="other-skills-capsule-data" @click="emitDelete(name)">
      <font-awesome-icon icon="times" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "OthersCapsule",
  data() {
    return {};
  },
  components: {},
  props: {
    name: String,
    del: Boolean
  },
  methods: {
    emitDelete(name) {
      this.$emit("delete-other-skills", name);
    }
  }
};
</script>

<style scoped>
.other-skills-capsule {
  list-style: none;
  padding: 7px;
  background: #13c7d479;
  border-radius: 12px;
  display: inline-flex;
  margin-right: 0.5em;
  margin-bottom: 0px ! important;
}

.other-skills-capsule-data {
  padding: 0 10px;
  line-height: 1;
  color: rgb(0, 0, 0);
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
</style>


