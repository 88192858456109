<template>
  <div class="order-pos-form-top">
    <div class="row order-pos-form order-pos-form-bottom" v-for="(items, name, index) in skillItems" v-bind:key="index">
      <div class="col-md-5 skill-title-col">
        <p class="skill-title"><b>Please, indicate the "{{name.replace("_", " ")}}" you commonly use</b></p>
      </div>
      <div class="col-md-7">
        <multiselect
          :id="index + '_skills'"
          :options="items"
          :multiple="true" 
          :close-on-select="false" 
          :auto-apply="true" 
          :preserve-search="true"
          :taggable="true" 
          :preselect-first="false"
          :group-select="true"
          :max-height="250"
          v-model="selectedItems[name]" 
          group-values="sub_options"
          group-label="option"
          placeholder="Select one or more items" 
          label="name" 
          track-by="value"
          @select="selectOption"
          @remove="removeOption"
          >
        </multiselect>
      </div>
    </div>
    <div class="row order-pos-form order-pos-form-bottom">
      <div class="col-md-5 skill-title-col">
        <p class="skill-title"><b>Please, indicate other remarkable skills that you have and should be taken into consideration</b></p>
      </div>
      <div class="col-md-7 other-tags">
        <smart-tagz :types="selectedTagOthers" inputPlaceholder="Type a skill to add" :search="true" @searchByTags="addOthers"/>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
  import SmartTagz from '@/components/Others/SmartTagz'
  import Multiselect from 'vue-multiselect';

  export default { 
    name: 'SkillsStep',
    props: ['clickedNext', 'currentStep'],
    data () { 
      return { 
        result: {},
        selectedTagOthers: [],
        isSubmitted: false,
        selectedItems: {},
        skillItems: {},
        subSkills: []
      } 
    }, 
    components: {
      Multiselect,
      SmartTagz
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      addOthers(tags) {
        tags.forEach((tag) => {
          if (!this.result.skills["others"].includes(tag.text))
            this.result.skills["others"].push(tag.text)
        });

      }, 
      selectOption(option) {
        if (Array.isArray(option)){
          this.result.skills[option.group] = this.result.skills[option.group].concat(option.map(o => o.value));
        } else {
          this.result.skills[option.group].push(option.value);
        }
        this.$forceUpdate();
      },
      removeOption(option) {
        if (Array.isArray(option)){
          let indexesToRemove = option.map(o => this.result.skills[option.group].indexOf(o.value));
          this.result.skills[option.group] = this.result.skills[option.group].filter(function (obj, index) { return indexesToRemove.indexOf(index) == -1 });
        } else {
          this.result.skills[option.group].splice(this.result.skills[option.group].indexOf(option.value), 1);
        }
        this.$forceUpdate();
      },
      async fetchData() {
        let data = await this.$parent.$parent.data;

        if (data.skills != undefined && data.skills != ''){
          this.result.skills = data.skills
          this.isSubmitted = true
        } else{
          this.isSubmitted = false
        }

        this.$emit('can-continue', {value: true});

        const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

        ProfileConfigurationDataService.profileSkillsByArea(currentArea).then(
          response => {
            if (response.status == 200) {
              let skills = response.data;
              skills.forEach((skill) => {
                let skillsListTmp = [{option: "Select All", sub_options: []}];
                let itemTmp = [];
                let skillsItemTmp = []
                skill.items.forEach((item) => {
                  itemTmp.push({group: skill.name, value: item, name: item});
                })
                skillsListTmp[0].sub_options = itemTmp;

                this.skillItems[skill.name] = skillsListTmp;

                if (skill.name in this.result.skills){
                  let intersection = skill.items.filter(element => this.result.skills[skill.name].includes(element));
                  this.result.skills[skill.name] = intersection

                  let intersectionTmp = [];
                  intersection.forEach((i) => {
                    intersectionTmp.push({group: skill.name, value: i, name: i});
                  })
                  this.selectedItems[skill.name] = intersectionTmp;
                  
                } else {
                  this.result.skills[skill.name] = []
                  this.selectedItems[skill.name] = []
                }

              })
              this.$forceUpdate();
            } 
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())

            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>