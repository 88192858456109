<template>
	<div class="full-container">
		<div class="home-header">
			<div class="row header-row">
			    <appHomeHeader></appHomeHeader>
			    <div id="register-header-image" class="col-md-6">
					<img class="main form-image-logo" src="images/icons/arbol_azul.png">
					<div class="contact-form">
						<h1 class="form-title" style="font-size: 25px !important;">{{title}}</h1>
						<form action class="form" @submit.prevent="handleContact">
							<div class="form-group">
								<input v-model="contactName" v-validate="'required|min:4|max:20'" class="form-input username" type="text" data-vv-name="name" required placeholder="Name">
								<div v-if="submitted && veeErrors.has('username')" class="alert alert-danger">
									{{veeErrors.first('username')}}
								</div>
							</div>
							<div class="form-group">
								<input v-model="contactEmail" v-validate="'required|email|max:50'" class="form-input email" type="email" data-vv-name="email" required placeholder="Email">
								<div v-if="submitted && veeErrors.has('email')" class="alert alert-danger">
									{{veeErrors.first('email')}}
								</div>
							</div>
							<div class="row form-group" style="margin-left: 0px !important; padding-bottom: 0px !important;">
								<textarea class="textarea-message" placeholder="Comment" name="textarea" id="textarea" required=""
									v-model="message.text" 
									:maxlength="message.maxlength"></textarea>
							</div>
							<div class="row form-group">
								<span class="counter">{{ message.text.length }} / {{ message.maxlength }}</span>
							</div>
							<input class="btn btn-primary form-submit" type="submit" value="Submit">
						</form>
					</div>
				</div>
			</div>
			<appHomeBody></appHomeBody>
	    	<appFooter></appFooter>
		</div>
	</div>
</template>

<script>
	import Footer from "@/components/Footer.vue";
    import HomeHeader from "@/components/HomeHeader.vue";
    import HomeBody from "@/components/HomeBody.vue";

	export default {
		name: 'HomeContactForm',
		components: {
			appFooter: Footer,
			appHomeBody: HomeBody,
			appHomeHeader: HomeHeader
		},
		data() {
			return {
				submitted: false,
				successful: false,
				contactName: '',
				contactEmail: '',
				title: "Contact us",
				message: {
					text: "",
					maxlength: 255
				},
				submitted: false
			};
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.status.loggedIn;
			}
		},
		mounted() {
			const recaptcha = this.$recaptchaInstance

			if (recaptcha)
				recaptcha.showBadge()

			if (this.loggedIn) {
				if (this.$store.state.auth.user.role.name === 'user') {
					this.$router.push('/user');
				} else if (this.$store.state.auth.user.role.name === 'admin') {
					this.$router.push('/admin');
				} else if (this.$store.state.auth.user.role.name === 'examiner') {
					this.$router.push('/examiner');
				} else if (this.$store.state.auth.user.role.name === 'validator') {
					this.$router.push('/questions-validator');
				} else if (this.$store.state.auth.user.role.name === 'certifier') {
					this.$router.push('/certifications-validator');
				} else if (this.$store.state.auth.user.role.name === 'superadmin') {
					this.$router.push('/superadmin');
				}
			}
		},
		methods: {
			handleContact() {
				console.log('')
			}
		}
	};
</script>