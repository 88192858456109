<template>
  <div>
    <home-contact-form></home-contact-form>
  </div>
</template>

<script>
  import HomeContactForm from "@/components/UserForms/ContactForm";

  export default {
    name: "ContactView",
    components: {
      HomeContactForm
    }
  };
</script>