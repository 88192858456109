<template>
	<div>
		<h4 class="reports-title">Column diagram with results distribution</h4>
		<barr-chart 
      :ref="chartId"
      :chartId="chartId" 
      :key="graphLoaded"
      :xAxesCategory="xAxesCategory" 
      :yAxesValue="yAxesValue" 
      :xAxesTitle="xAxesTitle"
      :yAxesTitle="yAxesTitle"
      :data="data"></barr-chart>
	</div>
</template>

<script>
  import BarrChart from "@/components/Others/Charts/BarrChart.vue"
  import ReportsDataService from '@/services/ReportsDataService';

  export default {
    name: 'TestResultsGraphByUser',
    data() {
    	return {
    		chartId: "chartTestsGraphByUser",
    		graphLoaded: false,
				data: [],
	    	xAxesCategory: "range",
	    	yAxesValue: "count",
	    	xAxesTitle: "Marks (0-10)",
	    	yAxesTitle: "Number of users"
    	}
    },
    components: {
    	BarrChart
    },
    methods: {
      async fetchData() {
        await ReportsDataService.testsResultsDistributionByCompany(this.$store.state.auth.user.id_company).then(
          response => {
            this.data = response.data
            this.graphLoaded = this.graphLoaded ? false : true
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    },
    created() {
      this.fetchData();
    }
  };
</script>

<style>
#chartTestsGraphByUser {
  width: 94%;
  height: 500px;
  margin-bottom: 6%;
  margin-left: 3%;
  margin-right: 3%;
}
</style>