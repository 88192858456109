<template>
	<div>
		<h4 class="reports-title">Horizontal barrs diagram with answers selected by option (by question)</h4>
		<div class="row" v-for="test in data">
            <div class="col-md-2">
                <h3 class="graph-title">Question {{test.id}} <b-button class="btn btn-info" v-b-tooltip.hover v-bind:title="test.question"><font-awesome-icon icon="info-circle" /></b-button></h3>
            </div>
            <div class="col-md-10">
                <horizontal-stacked-barr 
                    :ref="chartId + test.id"
                    :key="graphLoaded"
                    :chartId="chartId + test.id" 
                    :xAxesCategory="xAxesCategory" 
                    :yAxesValue="yAxesValue" 
                    :xAxesTitle="xAxesTitle"
                    :yAxesTitle="yAxesTitle"
                    :isHighValueSet="true"
                    :isLabelPercentage="true"
                    :data="test.data"></horizontal-stacked-barr>
            </div>
        </div>
	</div>
</template>

<script>
  import HorizontalStackedBarr from "@/components/Others/Charts/HorizontalStackedBarr";
  import ReportsDataService from '@/services/ReportsDataService';

  export default {
    name: 'TestResultsGraphByTestQuestion',
    data() {
    	return {
    		chartId: "chartTestsGraphByTestQuestion",
            graphLoaded: false,
            data: [],
	    	xAxesCategory: "percOptionSelected",
	    	yAxesValue: "answer",
	    	xAxesTitle: "Percentage of answer selection",
	    	yAxesTitle: "Answers"
    	}
    },
    components: {
    	HorizontalStackedBarr
    },
    methods: {
        async fetchData() {
            await ReportsDataService.testQuestionsActivity(this.$route.query.id).then(
                response => {
                    response.data.forEach((d) => {
                        let tmp = {}
                        tmp.id = d.id_question
                        tmp.question = d.question.question_title

                        tmp.data = []
                        tmp.data[0] = {}
                        tmp.data[0].percOptionSelected = d.average_answer_one_selected_total_ocurrences.toFixed(2)
                        tmp.data[0].answer = "a)"
                        tmp.data[0].ocurrences = d.total_answer_one_ocurrences
                        tmp.data[0].totalOcurrences = d.total_ocurrences
                        tmp.data[0].userAnswer = (d.user_answer_one_selected === 0 || d.user_answer_one_selected === null) ? false : true
                        tmp.data[0].correct = d.question.answer_one_correct
                        if ((tmp.data[0].correct && tmp.data[0].userAnswer) || (tmp.data[0].correct && !tmp.data[0].userAnswer)) {
                            tmp.data[0].labelColor = "#C5E0B4"; // green
                        } else if (!tmp.data[0].correct && tmp.data[0].userAnswer) {
                            tmp.data[0].labelColor = "#FF5656"; // red
                        } else {
                            tmp.data[0].labelColor = "#FFFFFF"; // blue
                        }

                        tmp.data[1] = {}
                        tmp.data[1].percOptionSelected = d.average_answer_two_selected_total_ocurrences.toFixed(2)
                        tmp.data[1].answer = "b)"
                        tmp.data[1].ocurrences = d.total_answer_one_ocurrences
                        tmp.data[1].totalOcurrences = d.total_ocurrences
                        tmp.data[1].userAnswer = (d.user_answer_two_selected === 0 || d.user_answer_two_selected === null) ? false : true
                        tmp.data[1].correct = d.question.answer_two_correct
                        if ((tmp.data[1].correct && tmp.data[1].userAnswer) || (tmp.data[1].correct && !tmp.data[1].userAnswer)) {
                            tmp.data[1].labelColor = "#C5E0B4"; // green
                        } else if (!tmp.data[1].correct && tmp.data[1].userAnswer) {
                            tmp.data[1].labelColor = "#FF5656"; // red
                        } else {
                            tmp.data[1].labelColor = "#FFFFFF"; // blue
                        }

                        tmp.data[2] = {}
                        tmp.data[2].percOptionSelected = d.average_answer_three_selected_total_ocurrences.toFixed(2)
                        tmp.data[2].answer = "c)"
                        tmp.data[2].ocurrences = d.total_answer_one_ocurrences
                        tmp.data[2].totalOcurrences = d.total_ocurrences
                        tmp.data[2].userAnswer = (d.user_answer_three_selected === 0 || d.user_answer_three_selected === null) ? false : true
                        tmp.data[2].correct = d.question.answer_three_correct
                        if ((tmp.data[2].correct && tmp.data[2].userAnswer) || (tmp.data[2].correct && !tmp.data[2].userAnswer)) {
                            tmp.data[2].labelColor = "#C5E0B4"; // green
                        } else if (!tmp.data[2].correct && tmp.data[2].userAnswer) {
                            tmp.data[2].labelColor = "#FF5656"; // red
                        } else {
                            tmp.data[2].labelColor = "#FFFFFF"; // blue
                        }

                        tmp.data[3] = {}
                        tmp.data[3].percOptionSelected = d.average_answer_four_selected_total_ocurrences.toFixed(2)
                        tmp.data[3].answer = "d)"
                        tmp.data[3].ocurrences = d.total_answer_one_ocurrences
                        tmp.data[3].totalOcurrences = d.total_ocurrences
                        tmp.data[3].userAnswer = (d.user_answer_four_selected === 0 || d.user_answer_four_selected === null) ? false : true
                        tmp.data[3].correct = d.question.answer_four_correct
                        if ((tmp.data[3].correct && tmp.data[3].userAnswer) || (tmp.data[3].correct && !tmp.data[3].userAnswer)) {
                            tmp.data[3].labelColor = "#C5E0B4"; // green
                        } else if (!tmp.data[3].correct && tmp.data[3].userAnswer) {
                            tmp.data[3].labelColor = "#FF5656"; // red
                        } else {
                            tmp.data[3].labelColor = "#FFFFFF"; // blue
                        }

                        this.data.push(tmp)
                    })

                    this.graphLoaded = this.graphLoaded ? false : true
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        }
    },
    created() {
        this.fetchData();
    }
  };
</script>

<style>
.graph-title {
    margin-top: 32%;
    margin-left: 30%;
}
</style>