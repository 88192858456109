<template>
	<div class="form-container technologiesStyle" v-if="isFinishCreate">
		<div class="row">
			<div class="form-group col-12">
				<label>Name of the meeting*<b-button class="btn btn-info" v-b-tooltip.hover title="Name of the meeting"><font-awesome-icon icon="info-circle" /></b-button></label>
				<input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name" v-model="meeting.name">
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<label>Description*<b-button class="btn btn-info" v-b-tooltip.hover title="Description of the meeting"><font-awesome-icon icon="info-circle" /></b-button></label>
				<input class="form-input" type="text" id="description" v-validate="'required'" data-vv-name="description" v-model="meeting.description">
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<div class="row mb-2">
					<div class="col-md-6">
						<label>Time to display questions (in seconds)<b-button class="btn btn-info" v-b-tooltip.hover title="Time candidates will have to think after the question is displayed. By default is 30 seconds"><font-awesome-icon icon="info-circle" /></b-button></label>
						<input min="0" type="number" oninput="validity.valid||(value=0)||(value='');" id="value_input" ref="value_input" v-model="meeting.questionTime" />
					</div>
					<div class="form-group col-md-6">
						<label>Time to answer questions (in seconds)<b-button class="btn btn-info" v-b-tooltip.hover title="Time candidates will have to answer each question. By default is 2 minutes"><font-awesome-icon icon="info-circle" /></b-button></label>
						<input min="0" type="number" oninput="validity.valid||(value=0)||(value='');" id="value_input" ref="value_input" v-model="meeting.answerTime" />
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<div class="row mb-2">
					<div class="col-md-auto">
						<label>Number of tries/retakes<b-button class="btn btn-info" v-b-tooltip.hover title="Number of retakes each candidate will have to answer a question"><font-awesome-icon icon="info-circle" /></b-button></label>
					</div>
					<div class="form-group col-md-4">
						<input min="0" type="number" oninput="validity.valid||(value=0)||(value='');" id="value_input" ref="value_input" v-model="meeting.retakes" />
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<div class="row">
					<div class="col-sm-auto">
						<label>Candidates to add to the interview*<b-button class="btn btn-info" v-b-tooltip.hover :title="'Candidates from ' + currentAreaName + ' to include'"><font-awesome-icon icon="info-circle" /></b-button></label>
					</div>
					<div class="col-sm-8">
						<multiselect id="candidate_selector"
	                      :options="candidatesToShow"
	                      :multiple="true"
	                      :close-on-select="false" 
	                      :preserve-search="true"
	                      :taggable="true"
	                      :preselect-first="false"
	                      placeholder="Search or add a candidate to the interview"
	                      v-model="selectedCandidates"
	                      label="label"
	                      track-by="value"
	                      @select="selectOptionCandidate"
	                      @remove="removeOptionCandidate"></multiselect>
					</div>
				</div>
				<div v-if="submitted && veeErrors.has('description')" class="alert alert-danger">
					{{veeErrors.first('description')}}
				</div>
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<div class="row mb-2">
					<div class="col-md-6">
						<label>External candidates<b-button class="btn btn-info" v-b-tooltip.hover title="Candidates not registered in the platform to add to the interview via email"><font-awesome-icon icon="info-circle" /></b-button></label>
					</div>
					<div class="col-md-6">
						<button class="btn btn-primary" @click="addNewCandidateToList()">
							<font-awesome-icon class="white-color" icon="plus" /> Add candidate
						</button>
					</div>
				</div>

				<template v-if="externalCandidatesToAdd.length > 0">
					<div v-for="(val, index) in externalCandidatesToAdd" class="row">
						<div class="form-group col-md-3">
							<p>Email of the new candidate</p>
						</div>
						<div class="form-group col-md-8">
							<input type="email" id="value_input" ref="value_input" v-model="val.value" />
						</div>
						<div class="col-md-1 plus-minus-filter-btn">
							<button class="btn btn-secondary" @click="removeNewCandidateToList(index)" v-b-tooltip.hover title="Remove candidate"><font-awesome-icon icon="minus" /></button>
						</div>
					</div>
				</template>
				<template v-else>
					<h5 class="no-results-msg-interview"> No external candidates.</h5>
				</template>
			</div>
		</div>

		<div class="row mt-2">
			<div class="form-group col-12">
				<div class="row mb-2">
					<div class="col-md-6">
						<label>Interview questions* (at least 3)<b-button class="btn btn-info" v-b-tooltip.hover title="Questions to ask the candidates in the interview"><font-awesome-icon icon="info-circle" /></b-button></label>
					</div>
					<div class="col-md-6">
						<button class="btn btn-primary" @click="addNewQuestionToList()">
							<font-awesome-icon class="white-color" icon="plus" /> Add question
						</button>
					</div>
				</div>

				<template v-if="availableQuestions.length > 0">
					<div v-for="(val, index) in availableQuestions" class="row">
						<div class="form-group col-md-4">
							<p class="align-item-left">Question title</p>
							<input type="email" id="value_input" ref="value_input" v-model="val.title" />
						</div>
						<div class="form-group col-md-7">
							<p class="align-item-left">Question description</p>
							<input type="email" id="value_input" ref="value_input" v-model="val.description" />
						</div>
						<div class="col-md-1 plus-minus-filter-btn">
							<button class="btn btn-secondary" @click="removeNewQuestionToList(index)" v-b-tooltip.hover title="Remove question"><font-awesome-icon icon="minus" /></button>
						</div>
					</div>
				</template>
				<template v-else>
					<h5 class="no-results-msg-interview"> No questions added. You must add at least 3 questions to complete the interview</h5>
				</template>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
			{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		</div>

		<div class="form-group col-lg-4 align-item-center">
			<div class="row">
				<div class="col-sm-6">
					<button class="btn btn-secondary btn-cancel" type="button" @click="backToRepository()"><font-awesome-icon icon="ban" /> Cancel</button>
				</div>
				<div class="col-sm-6">
					<input class="btn btn-primary form-submit white-color" type="submit" @click="handleCreate" value="Create">
				</div>
			</div>
		</div>
	</div>

	<div class="form-container technologiesStyle" v-else>
		<div class="row">
			<div class="col-12">
				<div v-if="!isFinishCreate" class="text-center text-loading">
					<b-spinner class="align-middle analysis-spinner"></b-spinner>
					<strong class="text-loading-msg"> Creating interview...</strong>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InterviewsDataService from '@/services/InterviewsDataService';
	import GraphQLDataService from '@/services/GraphQLDataService';
	import UserDataService from '@/services/UserDataService';
	import Meeting from '@/models/meeting';
	import Multiselect from 'vue-multiselect';
	import SmartTagz from '@/components/Others/SmartTagz';
	
	export default {
		name: 'CreateVideoconference',
		data() {
			return {
				currentQuestionID: -1,
				currentAreaName: "",
				meeting: new Meeting('', this.$store.state.auth.user.id, '', '', '', '', [], [], 30, 120, 3, '', this.$store.state.auth.user.id_area),
				availableQuestions: [],
				candidatesToShow: [],
				selectedCandidates: [],
				candidatesToAdd: [],
				externalCandidatesToAddDb: [],
				totalCandidates: [],
				users: [],
				selectedTags: [],
				externalCandidatesToAdd: [],
				profiles: [],
				submitted: false,
				successful: false,
				message: "",
				isFinishCreate: true
			}
		},
		components: {
			Multiselect,
			SmartTagz
		},
		mounted() {
			this.currentAreaName = this.$store.state.auth.user.areas.filter(area => area.id == this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).map(function(e){return e.name})[0];
			this.getAvailableCandidates();
		},
	  	methods: {
			getAvailableCandidates() {
				UserDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
					response => {
						if (response.status == 200) {
							this.users = response.data;
							this.users.forEach((user) => {
								this.candidatesToShow.push({label: user.name + " " + user.surname, value: user.name + '-' + user.id, id: user.id, email: user.email})
							});
						} else {
							this.message = response.data.message;
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
  			backToRepository() {
				this.$router.push({ path: 'videoconferences'})
			},
			addTags(tags) {
				tags.forEach((tag) => {
					if (!this.meeting.tags.includes(tag.text))
						this.meeting.tags.push(tag.text.toString())
				});
			}, 
			changeURL:function(e){
				const url = e.target.value
			},
			addNewCandidateToList() {
				this.externalCandidatesToAdd.push({name: '', email: ''})
			},
			removeNewCandidateToList(index) {
				this.externalCandidatesToAdd.splice(index, 1)
			},
			addNewQuestionToList() {
				this.currentQuestionID++
				this.availableQuestions.push({title: '', description: ''})
			},
			removeNewQuestionToList(index) {
				this.availableQuestions.splice(index, 1)
			},
			selectOptionCandidate(option) {
				var object = {}

				var parsedOption = JSON.parse(JSON.stringify(option))

				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')

				this.candidatesToAdd.push(parseInt(valueSplit[1]))
			},
			removeOptionCandidate(option) {
				var parsedOption = JSON.parse(JSON.stringify(option))

				var valueSplit = parsedOption.value.split('-')

				const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
				this.candidatesToAdd.splice(index, 1)
			},
			addExternalCandidatesToInterviewDB() {
				InterviewsDataService.addExternalCandidatesToInterview(this.meeting.id, this.externalCandidatesToAddDb).then(
					response => {
						if (response.status != 200) {
							this.message = response.data.message;
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			addCandidatesToInterviewDB() {
				InterviewsDataService.addUsersToInterview(this.meeting.id, this.candidatesToAdd).then(
					response => {
						if (response.status == 200) {
							this.isFinishCreate = true
							this.$router.push({ name: "videoconferences", params: {videoconferenceRegistered: true}});
						} else {
							this.message = response.data.message;
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			addCandidatesToInterview(candidate) {
				GraphQLDataService.inviteUserToInterview(this.$apollo, this.meeting.id_interview, candidate).then(
					response => {
						if (response.networkStatus == 7 || response.status == 200) {
							console.log("");
						} else {
							this.message = response.data.message;
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			createInterviewDB(candidate) {
				InterviewsDataService.create(this.meeting, candidate).then(
					response => {
						if (response.status == 200) {
							this.meeting.id = response.data.id;

							if (this.externalCandidatesToAdd.length > 0){
								this.externalCandidatesToAdd.forEach((candidate) => {
									this.addExternalCandidatesToInterviewDB(candidate.email);
								});
							}

							this.totalCandidates.forEach((candidate) => {
								this.addCandidatesToInterview(candidate);
							})

							this.addCandidatesToInterviewDB();
						} else {
							this.message = (error.response && error.response.data) ||
								error.message ||
								error.toString();
							this.successfull = false;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
  			handleCreate() {
				if (this.meeting.name.length == 0) {
					this.message = "Please, enter the name of the meeting";
					this.successfull = false;
					return;
				}

				if (this.meeting.description.length == 0) {
					this.message = "Please, enter the description of the meeting";
					this.successfull = false;
					return;
				}

				if (this.candidatesToAdd.length == 0 && this.externalCandidatesToAdd.length == 0) {
					this.message = "Please, select at least one candidate to create the interview";
					this.successfull = false;
					return;
				}

				this.externalCandidatesToAdd.forEach((candidate) => {
					this.totalCandidates.push({name: candidate.name, phone: "", email: candidate.email})
					this.externalCandidatesToAddDb.push({id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")], candidate_name: candidate.name, candidate_email: candidate.email})
				})

				this.candidatesToAdd.forEach((candidateID) => {
					let candidateInfo = this.users.filter((c) => parseInt(c.id) == parseInt(candidateID));
					if (candidateInfo.length > 0)
						this.totalCandidates.push({name: candidateInfo[0].name + " " + candidateInfo[0].surname, phone: "", email: candidateInfo[0].email})
				})

				if (this.availableQuestions.length < 3) {
					this.message = "Please, you must add at least 3 questions to complete the interview.";
					this.successfull = false;
					return;
				}

				this.meeting.questions = this.availableQuestions;

					this.isFinishCreate = false
				
				GraphQLDataService.create(this.$apollo, this.meeting).then(
					response => {
						this.meeting.id_interview = response.data.Position.save.id;
						this.meeting.id_area = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

						this.createInterviewDB()
					},
					error => {
						console.error(error)
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						this.successfull = false;

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
  			},
			close() {
				document.getElementById("message").style.display = "none"
			}
	  	}
	};
</script>