<template>
  <div class="footer">
    <div class="row">
      <div class="col-md-1 logo">
        <img id="footer-img" src="images/icono_cuadrado_v1.png">
        <h2 id="footer-sign">></h2>
      </div>
      <div class="col-md-11 text-logo">
        <h3 class="text-title">{{footerTitle}}</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import ContactForm from "@/components/ContactForm.vue"

  export default {
    name: "FooterMini",
    components: {
      ContactForm
    },
    data: () => ({
      footerTitle: "| Progradum 2024"
    }),
  };
</script>