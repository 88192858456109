export default class Meeting {
  constructor(id, idCreator, idInterview, name, candidate, description, questions, tags, questionTime, answerTime, retakes, interviewDate, idArea) {
    this.id = id;
    this.id_creator = idCreator;
    this.id_interview = idInterview;
    this.candidate = candidate;
  	this.name = name;
    this.description = description;
    this.questions = questions;
    this.tags = tags;
    this.questionTime = questionTime;
    this.answerTime = answerTime;
    this.retakes = retakes;
  	this.interview_date = interviewDate;
    this.id_area = idArea;
  }
}