<template>
	<div>
		<h4 class="reports-title">Column diagram with answers distribution</h4>
    <select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedQuestion" @change="onChange($event)">
      <option hidden value="">Select a question</option>
      <option v-for="question in questions" :value="question.id">{{question.id}} - {{question.question_title}}</option>
    </select>
    <div v-if="graphLoading" class="text-center text-loading">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Loading graph...</strong>
    </div>
    <div class="row" v-else>
			<div v-if="selectedQuestionObj.id != undefined" class="col-sm-2">
				<h3 class="graph-title">Question {{selectedQuestionObj.id}} <b-button class="btn btn-info" v-b-tooltip.hover v-bind:title="selectedQuestionObj.question_title"><font-awesome-icon icon="info-circle" /></b-button></h3>
			</div>
			<div :class="selectedQuestionObj.id != undefined ? 'col-sm-10' : 'col-sm-12'">
				<horizontal-stacked-barr 
          :ref="chartId + selectedQuestionObj.id"
					:chartId="chartId + selectedQuestionObj.id"
				  :key="graphLoaded"
					:xAxesCategory="xAxesCategory" 
					:yAxesValue="yAxesValue" 
					:xAxesTitle="xAxesTitle"
					:yAxesTitle="yAxesTitle"
          :isHighValueSet="isHighValueSet"
          :isUserResult="isUserResult"
          :isLabelPercentage="isLabelPercentage"
					:data="selectedQuestionObj.data"
          :tooltipNoDataMsg="tooltipNoDataMsg"></horizontal-stacked-barr>
			</div>
    </div>
	</div>
</template>

<script>
  import ReportsDataService from '@/services/ReportsDataService';
  import HorizontalStackedBarr from "@/components/Others/Charts/HorizontalStackedBarr"

  export default {
    name: 'UserAnswersGraphByQuestion',
    data() {
    	return {
    		chartId: "chartUserAnswersGraphByQuestion",
        questions: [],
	    	xAxesCategory: "percOptionSelected",
	    	yAxesValue: "answer",
	    	xAxesTitle: "Percentage of answer selection",
	    	yAxesTitle: "Answers",
	    	isHighValueSet: true,
	    	isUserResult: true,
        isLabelPercentage: true,
        graphLoaded: false,
        graphLoading: false,
        selectedQuestion: "",
        selectedQuestionObj: {},
        tooltipNoDataMsg: "Please, select a question"
    	}
    },
    components: {
    	HorizontalStackedBarr
    },
    props: {
      user: Object,
      userAreaID: Number
    },
    methods: {
      onChange(event) {
        this.graphLoading = true;

        this.selectedQuestion = event.target.value

        this.questions.forEach((question) => {
          if (parseInt(this.selectedQuestion) == parseInt(question.id))
            this.selectedQuestionObj = question;
        });

        this.graphLoaded = this.graphLoaded ? false : true;
        this.graphLoading = false;          
      },
    	async fetchData() {
    		await ReportsDataService.userQuestionsActivityInArea(this.user.id, this.userAreaID).then(
		      response => {
		      	if (response.status == 200) {
              let tmpArray = [];
              let userQuestionsData = response.data;

              userQuestionsData.forEach((element) => {
                let tmp = {};
                tmp.id = element.id_question;
                tmp.question_title = element.question.question_title;
                tmp.data = [];
                tmp.data[0] = {};
                tmp.data[0].percOptionSelected = parseFloat(element.average_answer_one_selected_total_ocurrences).toFixed(2);
                tmp.data[0].ocurrences = element.total_answer_one_ocurrences;
                tmp.data[0].totalOcurrences = element.total_ocurrences;
                tmp.data[0].answer = "a)";
                tmp.data[0].userAnswer = (element.user_answer_one_selected === 0 || element.user_answer_one_selected === null) ? false : true;
                tmp.data[0].correct = element.question.answer_one_correct;
                tmp.data[1] = {};
                tmp.data[1].percOptionSelected = parseFloat(element.average_answer_two_selected_total_ocurrences).toFixed(2);
                tmp.data[1].ocurrences = element.total_answer_two_ocurrences;
                tmp.data[1].totalOcurrences = element.total_ocurrences;
                tmp.data[1].answer = "b)";
                tmp.data[1].userAnswer = (element.user_answer_two_selected === 0 || element.user_answer_two_selected === null) ? false : true;
                tmp.data[1].correct = element.question.answer_two_correct;
                tmp.data[2] = {};
                tmp.data[2].percOptionSelected = parseFloat(element.average_answer_three_selected_total_ocurrences).toFixed(2);
                tmp.data[2].ocurrences = element.total_answer_three_ocurrences;
                tmp.data[2].totalOcurrences = element.total_ocurrences;
                tmp.data[2].answer = "c)";
                tmp.data[2].userAnswer = (element.user_answer_three_selected === 0 || element.user_answer_three_selected === null) ? false : true;
                tmp.data[2].correct = element.question.answer_three_correct;
                tmp.data[3] = {};
                tmp.data[3].percOptionSelected = parseFloat(element.average_answer_four_selected_total_ocurrences).toFixed(2);
                tmp.data[3].ocurrences = element.total_answer_four_ocurrences;
                tmp.data[3].totalOcurrences = element.total_ocurrences;
                tmp.data[3].answer = "d)";
                tmp.data[3].userAnswer = (element.user_answer_four_selected === 0 || element.user_answer_four_selected === null) ? false : true;
                tmp.data[3].correct = element.question.answer_four_correct;
                tmp.data[4] = {};
                tmp.data[4].percOptionSelected = parseFloat(element.average_answer_empty_selected_total_ocurrences).toFixed(2);
                tmp.data[4].ocurrences = element.total_answer_empty_ocurrences;
                tmp.data[4].totalOcurrences = element.total_ocurrences;
                tmp.data[4].answer = "Empty";
                tmp.data[4].userAnswer = (element.user_answer_empty === 0 || element.user_answer_empty === null) ? false : true;
                tmp.data[4].correct = false;

                tmpArray.push(tmp);
              });

              this.questions = tmpArray;

              this.graphLoaded = this.graphLoaded ? false : true;
              this.graphLoading = false;
            }
			    },
		      error => {
            this.graphLoading = false;
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())

            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
		      }
		    );
    	}
    },
    mounted() {
      this.graphLoading = true;
      this.fetchData();
  	}
  };
</script>