<template>
  <div class="col-sm-7 col-centered "> 
    <h5 class="changeFont">COOKIE POLICY</h5>
    <hr class="thicker" style="color: #4FC0E8;height: 3px;">
    <div style="text-align: justify">
      <p>The Progradum website with the domain <a class="linksInBlue" @click="openNewTab('http://00dev00.progradum.com/')">www.00dev00.progradum.com</a> uses Cookies to personalize and facilitate User navigation. Cookies are associated only with an anonymous User and their computer and do not provide references that allow deducing personal data of the User. The User can configure their browser to notify and reject the installation of Cookies sent by the Progradum website, without affecting the User's ability to access the content. However, please note that, in any case, the quality of the website's operation may decrease.</p>
      <p class="makeItBold avoidLineJump">Strictly necessary cookies:</p>
      <p class="avoidLineJump">These are essential for the proper functioning of the page.</p>
      <p class="tab">• Wapli: session identifier (expires at the end of the browsing session)</p>
      <p class="tab">• Capli: Cookie policy notice (expires 6 months after installation)</p>
      <p class="makeItBold avoidLineJump">Web analytics cookies:</p>
      <p class="avoidLineJump">To obtain statistics from the page with Google Analytics.</p>
      <p class="tab">• _utma: expires 24 months after installation</p>
      <p class="tab">• _utmb: expires 30 minutes after installation of Google cookies</p>
      <p class="tab">• _utmv: expires 24 months after installation</p>
      <p class="tab">• _utmz: expires 6 months after installation</p>
      <p>To obtain statistics from the page with Google Analytics. More information about Google cookies at: <a class="linksInBlue" @click="openNewTab('http://www.google.es/intl/es/policies/technologies/cookies/')">http://www.google.es/intl/es/policies/technologies/cookies/</a></p>
      <p class="makeItBold">Other cookies:</p>
      <p class="tab makeItBold">• Facebook cookies</p>
      <p class="doubleTab">- datr: expires 24 months after installation</p>
      <p class="doubleTab">- locale: expires 7 days after installation</p>
      <p class="doubleTab">- reg_fb_gate: expires at the end of the browsing session</p>
      <p class="doubleTab">- wd: expires at the end of the browsing session</p>
      <p class="tab">Used for publishing, protecting, and recognizing products, services, and ads both on Facebook and off-site. More information about Facebook cookies at: <a class="linksInBlue" @click="openNewTab('https://www.facebook.com/help/cookies')">https://www.facebook.com/help/cookies</a></p>
      <p class="tab makeItBold">• Twitter cookies</p>
      <p class="doubleTab">- datr: expires 24 months after installation</p>
      <p class="doubleTab">- locale: expires 7 days after installation</p>
      <p class="doubleTab">- reg_fb_gate: expires at the end of the browsing session</p>
      <p class="doubleTab">- wd: expires at the end of the browsing session</p>
      <p class="tab">These cookies are used to identify Twitter. Twitter has the ability to track the browsing habits (the Tweet button on enabled websites) of users who do not have a Twitter account and who have never visited the Twitter website. By using the same browser to create a Twitter account later, this data collected in the past can theoretically be linked to the newly created profile. More information about Twitter cookies at: <a class="linksInBlue" @click="openNewTab('https://www.twitter.com/es/privacy')">https://www.twitter.com/es/privacy</a></p>
      <p class="makeItBold">Accepting or rejecting cookies in major browsers</p>
      <p>The user can freely decide whether to store the cookies used on this website on their hard drive. In this regard, the user can configure their own browser to accept or reject all cookies or to receive on-screen notice of the receipt of each cookie and decide at that time whether to install it on their device.</p>
      <p>If the user of <a class="linksInBlue" @click="openNewTab('http://00dev00.progradum.com/')">www.00dev00.progradum.com</a> does not wish to accept the recording of "cookies" on their computer, they can browse the portal without any restrictions.</p>
      <p>At any time, you can exercise your right to deactivate or delete cookies from this website. These actions are performed differently depending on the browser you are using. Below, we inform you how to accept or reject cookies in major browsers:</p>
      <p class="tab makeItBold">Internet Explorer</p>
      <p class="tab">1. Click on the "Tools" button and then on "Internet Options".</p>
      <p class="tab">2. Click on the "Privacy" tab and then on "Sites".</p>
      <p class="tab">3. In the "Website address" box, enter the complete address (URL) of the website whose privacy settings you want to customize.</p>
      <p class="tab">4. Then click "Block" or "Allow".</p>
      <p class="tab">5. Repeat steps 3 and 4 for each website you want to unblock or allow. When finished, double-click "OK".</p>
      <p class="tab">For more information, you can consult Microsoft Support or the browser's Help.</p>
      <p class="tab makeItBold">Google Chrome</p>
      <p class="tab">1. Click on the Chrome menu.</p>
      <p class="tab">2. Select "Settings".</p>
      <p class="tab">3. Click on "Show advanced options".</p>
      <p class="tab">4. In the "Privacy" section, click on the "Content settings" button.</p>
      <p class="tab">5. In the "Cookies" section, you can configure the options listed below: delete cookies, block cookies by default, allow cookies by default, include exceptions for cookies from specific domains or websites.</p>
      <p class="tab">For more information, you can consult Google Chrome Support or the browser's Help.</p>
      <p class="tab makeItBold">Mozilla Firefox</p>
      <p class="tab">1. Click on the "Tools" button and then on "Options".</p>
      <p class="tab">2. Click on the "Privacy" tab.</p>
      <p class="tab">3. In the "History" section, select "Use custom settings for history".</p>
      <p class="tab">4. Check the box to accept cookies and click "Exceptions" to select the websites that (always or never) authorize to install cookies on your device.</p>
      <p class="tab">For more information, you can consult Mozilla Firefox Support or the browser's Help.</p>
      <p class="tab makeItBold">Safari</p>
      <p class="tab">1. Access the "Safari" menu.</p>
      <p class="tab">2. Enter the "Preferences" section.</p>
      <p class="tab">3. Click on the "Privacy" tab and you can adjust the Cookies.</p>
      <p class="tab">For more information, you can consult Safari Support or the browser's Help.</p>
      <p class="tab makeItBold">Opera</p>
      <p class="tab">1. "Settings" > "Privacy and Security" > "Cookies".</p>
      <p class="tab">2. Cookie options control how Opera handles them. The default mode is to accept all cookies. Accepting cookies only from the visited site is to reject third-party cookies, for domains external to the one being visited. And not accepting cookies ever (rejecting all).</p>
      <p class="tab">For more information, you can consult Opera Support or the browser's Help. Important: To learn more about how to activate "private mode," carefully read the help section of your browser. If your browser is in "private mode," your navigation of our page may not be optimal, and some utilities may not work effectively.</p>
      <p class="makeItBold">Updates and changes to the cookie policy</p>
      <p>The Progradum website may modify this Cookie Policy based on legislative, regulatory requirements, or to adapt this policy to the instructions issued by the Spanish Data Protection Agency. Therefore, it is advisable for Users to visit it periodically.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CookiesPolicyComponent',
    methods:{
      openNewTab (url) {
        window.open(url, "_blank")
      }
    }
  }
</script>
