<template>
</template>

<script>
	import AuthDataService from "@/services/AuthDataService";

	export default {
	  	name: 'AccountActivation',
	  	components: {
	    },
	    mounted() {
            AuthDataService.confirmAccountRegister(this.$route.query.token).then(
		      	response => {
			    	this.$router.push({ name: "login", params: {previousRegister: true} })
                },
		      	error => {
					console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );	 
	    }
	};
</script>
