<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-6 links-container">
        <router-link :to="{ path: '/settings-exams' }" class="btn btn-secondary float-right"><font-awesome-icon
            icon="arrow-left" /> Back to exams settings</router-link>
        <router-link to="/create-tag" class="btn btn-secondary float-right createClass"><font-awesome-icon
            icon="plus" /> CREATE TAG</router-link>
      </div>
      <div class="col-6 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/settings-exams' }" class="breadcumb"> Exams settings > </router-link>
        <router-link :to="{ path: '/tags' }" class="final-breadcumb"> Tags </router-link>
      </div>
    </div>

    <div class="col-sm-12 col-centered mt-2">
      <div id="message" v-if="tagRegistered" class="alert alert-success align-item-center">
        Tag registered succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <div id="message2" v-if="tagUpdated" class="alert alert-success align-item-center">
        Tag updated succesfully! <b-button @click="close2" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <div id="message3" v-if="tagDeleted" class="alert alert-success align-item-center">
        Tag deleted succesfully! <b-button @click="close3" class="cross-button"><font-awesome-icon class="cross"
            icon="times" /> </b-button>
      </div>
      <list-tags :tags="tags" @deleteTag="deleteTag" />
    </div>
  </div>
</template>

<script>
import TagDataService from "@/services/TagDataService";
import ListTags from "@/components/TagComponents/List.vue";
import CreateTag from "@/components/TagComponents/Create.vue";

export default {
  name: 'ListTagsView',
  data() {
    return {
      tags: [],
      tagDeleted: false
    }
  },
  components: {
    ListTags,
    CreateTag
  },
  methods: {
    getTags() {
      TagDataService.findByArea((this.$store.state.auth.user.active_areas[this.$session.get("tabID")])).then(
        response => {
          this.tags = response.data
        },
        error => {
          console.log((error.response && error.response.data) ||
            error.message ||
            error.toString())

          if (error.response.status === 300) {
            console.log('Logout: Token expired')
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            this.$store.state.auth.user = null
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        }
      );
    },
    handleCreate(info) {
      this.getTags();
    },
    deleteTag() {
      this.tagDeleted = true
      this.getTags()
    },
    close() {
      document.getElementById("message").style.display = "none"
    },
    close2() {
      document.getElementById("message2").style.display = "none"
    },
    close3() {
      this.tagDeleted = false
      document.getElementById("message3").style.display = "none"
    }
  },
  mounted() {
    this.getTags();
  },
  props: {
    tagRegistered: {
      type: Boolean,
      default: false
    },
    tagUpdated: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.alert {
  margin: 0 30px;
}
</style>
