<template>
    <div class="form-container technologiesStyle">
        <div id="message" v-if="message"
            :class="successfull ? 'message alert alert-success' : 'message alert alert-danger'">
            {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" />
            </b-button>
        </div>

        <form action class="form" @submit.prevent="handleUpdate">
            <div class="row">
                <div class="col-sm-6 py-0">
                    <div class="row mt-3">
                        <h5 class="mb-0" style="text-align: left !important;">General information</h5>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 py-0">
                            <label class="mt-2" for="exampleFormControlSelect1"
                                style="float: left !important; margin-left: 1px !important;">Name *<b-button
                                    class="btn btn-info" v-b-tooltip.hover
                                    title="You must enter your name"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input type="text" class="form-control inputStyle" id="name" required v-model="user.name"
                                name="name" v-validate="'required'" data-vv-name="name" placeholder="Name" />
                            <div v-if="veeErrors.has('name')" class="alert alert-danger py-0 mt-2 mb-0">
                                {{ veeErrors.first('name') }}
                            </div>
                        </div>
                        <div class="col-sm-6 py-0">
                            <label class="mt-2" for="exampleFormControlSelect1"
                                style="float: left !important; margin-left: 1px !important;">Surname *<b-button
                                    class="btn btn-info" v-b-tooltip.hover
                                    title="You must enter your surname"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input type="text" class="form-control inputStyle" id="surname" required
                                v-model="user.surname" name="surname" v-validate="'required'" data-vv-name="surname"
                                placeholder="Surname" />
                            <div v-if="veeErrors.has('surname')" class="alert alert-danger py-0 mt-2 mb-0">
                                {{ veeErrors.first('surname') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 py-0">
                            <label class="mt-2" for="exampleFormControlSelect1"
                                style="float: left !important; margin-left: 1px !important;">Username *<b-button
                                    class="btn btn-info" v-b-tooltip.hover
                                    title="You must choose a username to login"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input type="text" class="form-control inputStyle" id="username" required
                                v-model="user.username" name="username" v-validate="'required|min:6|max:20'"
                                data-vv-name="username" placeholder="Username" disabled />
                            <div v-if="veeErrors.has('username')" class="alert alert-danger py-0 mt-2 mb-0">
                                {{ veeErrors.first('username') }}
                            </div>
                        </div>
                        <div class="col-sm-6 py-0">
                            <label class="mt-2" for="exampleFormControlSelect1"
                                style="float: left !important; margin-left: 1px !important;">Mail *<b-button
                                    class="btn btn-info" v-b-tooltip.hover
                                    title="You must enter a valid email address"><font-awesome-icon
                                        icon="info-circle" /></b-button></label>
                            <input class="form-control inputStyle" id="mail" required v-model="user.email"
                                v-validate="'required|email|max:50'" type="email" data-vv-name="email"
                                placeholder="Email" name="mail" disabled />
                            <div v-if="veeErrors.has('email')" class="alert alert-danger py-0 mt-2 mb-0">
                                {{ veeErrors.first('email') }}
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-sm-6 py-0">
                    <div class="row mt-3">
                        <h5 class="mb-2" style="text-align: left !important;">Avatar</h5>
                    </div>
                    <div class="row">
                        <input class="my-2" @change="onFileChange" type="file" accept=".jpg" id="chosenFile">
                    </div>
                    <div class="row my-2" v-if="avatar !== undefined && avatar !== null && !avatar.includes('null')">
                        <img class="formControl fileInput" :src="avatar" style="width: 20% !important;">
                    </div>
                    <div v-else class="alert alert-info ml-4" role="alert" style="margin-left: 1%;">
                        <font-awesome-icon icon="info-circle" /> Avatar sin definir
                    </div>
                    <div class="alert alert-warning col-sm-6">
                        {{ $t('dashboards.admin.profile.image-advertisment') }}
                    </div>
                </div>

                <div class="form-group row justify-content-center mt-2">
                    <div class="col-sm-2 mt-3">
                        <input class="btn btn-primary form-submit" type="submit" :disabled="formDidntChange"
                            value="Update Profile">
                    </div>
                </div>
            </div>
        </form>

        <update-password :userId="this.user.id" v-if="this.user.id != ''" />
    </div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import UpdatePassword from "@/components/UpdatePassword.vue";

export default {
    name: 'UpdateUserComponent',
    data() {
        return {
            users: [],
            user: {
                username: "",
                name: "",
                surname: "",
                email: "",
                password: "",
                role: "",
            },
            image: "",
            successfull: false,
            roleSelected: [],
            fileName: "",
            submitted: false,
            message: "",
            messageAvatar: "",
            selectedTags: [],
            tags: [],
            repositories: [],
            visibility: 'password',
            files: null,
            avatar: '',
            preview: null,
            chosenFile: null,
            formDidntChange: false,
            fileToUpload: null,
        }
    },
    components: {
        UpdatePassword,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        this.user = this.$store.state.auth.user

        if (this.currentUser.avatar !== undefined && this.currentUser.avatar !== null)
            this.avatar = this.currentUser.avatar

        UserDataService.findByCompany(this.$store.state.auth.user.id_company).then(
            response => {
                if (200 == response.status) {
                    this.users = response.data
                } else {
                    this.successfull = false
                    this.message = "Error while retrieving the information";
                }
            },
            error => {
                console.error((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                this.successfull = false
                this.message = "There was an error while retrieving the information";

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.fileName = files[0].name;
            this.createImage(files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = function (e) {
                var img = document.createElement("img");
                img.onload = function (event) {
                    var maxW = 200;
                    var maxH = 200;
                    var canvas = document.createElement("canvas");
                    var iw = img.width;
                    var ih = img.height;
                    var scale = Math.min((maxW / iw), (maxH / ih));
                    var iwScaled = iw * scale;
                    var ihScaled = ih * scale;
                    canvas.width = iwScaled;
                    canvas.height = ihScaled;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

                    vm.fileToUpload = canvas.toDataURL(file.type)
                }
                vm.avatar = e.target.result;
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);

        },
        saveImage(base64Image) {
            var imageName = "profiles/" + this.currentUser.accessToken + "_" + this.user.id + "_logo.png"
            var file = this.dataURLtoFile(base64Image, "imageName.jpeg");
            UserDataService.updateAvatar(file, imageName, this.user.id, this.currentUser.accessToken, event => { }).then(
                response => {
                    if (response.status == 200) {
                        this.avatar = response.data.avatar
                        this.user.avatar = this.avatar
                        let tmpUser = this.$store.state.auth.user
                        tmpUser.avatar = this.avatar
                        localStorage.setItem('user', JSON.stringify(tmpUser));
                        this.successfull = true
                        this.message = "Profile successfully updated!";
                    } else {
                        this.successfull = false
                        this.message = "There was an error while processing your image"
                    }
                }
            ).then(files => {
            })
                .catch((error) => {
                    this.successfull = false
                    this.message = "There was an error while processing your image";
                });
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        },
        handleUpdate() {
            this.submitted = true;
            this.successfull = true;

            if (!this.checkUsernameExists(this.user)) {
                if (!this.checkEmailExists(this.user)) {
                    if (this.user.name != '' || this.user.surname != '' || this.user.username != '' || this.user.email != '') {
                        this.$validator.validate().then(isValid => {
                            if (isValid) {
                                let data = { user: this.user, role: this.user.role.id, id_company: this.$store.state.auth.user.id_company };
                                UserDataService.update(data).then(
                                    response => {
                                        if (200 == response.status) {
                                            localStorage.setItem('user', JSON.stringify(this.user));
                                            this.saveImage(this.fileToUpload)
                                        } else {
                                            this.message = "Error while updating profile information";
                                            this.successfull = false
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())

                                        this.message = "There was an error while updating profile information";
                                        this.successfull = false;

                                        if (error.response.status === 300) {
                                            console.error('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            }
                        });
                    }
                } else {
                    this.message = "Email already exists!"
                    this.successfull = false
                }
            } else {
                this.message = "Username already exists!"
                this.successfull = false
            }
        },
        checkEmailExists(userProfile) {
            let exists = false

            this.users.forEach(user => {
                if (userProfile.email === user.email && userProfile.id !== this.user.id)
                    exists = true
            })

            return exists
        },
        checkUsernameExists(userProfile) {
            let exists = false
            this.users.forEach(user => {
                if (userProfile.username === user.username && userProfile.id !== this.user.id)
                    exists = true
            })

            return exists
        },
        close() {
            document.getElementById("message").style.display = "none"
            this.message = ''
        },
        closeMessageAvatar() {
            document.getElementById("messageAvatar").style.display = "none"
            this.message = ''
        }
    },
    props: {
        id_user: Number
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>