<template>
	<div class="technologiesStyle">
		<h4>Global results</h4>

		<table class="table ">
			<thead>
				<th>Title</th>
				<th>Level of difficulty</th>
				<th>Areas</th>
				<th>Language</th>
				<th>Number of attempts</th>
				<th>Penalty</th>
				<th>Av. score</th>
				<th>Created at</th>
			</thead>
			<tbody>
				<tr>
					<td>{{ test.title }}</td>
					<td>{{ test.difficulty }}</td>
					<td>
						<div class="tags-container">
							<ul class="inline-tags">
								<li v-for="tag in test.tags" v-bind:key="tag.id" tabindex="0"
									class="ti-tag ti-valid inline-tags">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span class="">{{ tag.name }}</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</td>
					<td>{{ test.language }}</td>
					<td>{{ test.done }}</td>
					<td>{{ test.incorrect_value }}</td>
					<td v-if="test.average_qualification !== null && test.average_qualification !== undefined">
						{{ test.average_qualification.toFixed(2) }}</td>
					<td v-else>-</td>
					<td>{{ test.created_at | formatEnglishDate }}</td>

				</tr>
			</tbody>
		</table>
		<table>
			<thead>
				<th class="px-4" style="text-align: left;">Description</th>
			</thead>
			<tbody>
				<tr>
					<td v-if="test.description !== undefined && test.description !== ''" style="text-align: left;">{{
						test.description }}</td>
					<td v-else style="text-align: left;">No description provided.</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import TestDataService from '@/services/TestDataService';

export default {
	name: 'GlobalResults',
	data() {
		return {
			users: [],
			test: {}
		}
	},
	mounted() {
		TestDataService.globalResults(this.$route.query.id).then(
			response => {
				console.log(response)
				if (response.status == 200) {
					console.log('test: ', response.data.test)
					this.test = response.data.test;
				}
			},
			error => {
				console.log((error.response && error.response.data) ||
					error.message ||
					error.toString())

				if (error.response.status === 300) {
					console.log('Logout: Token expired')
					localStorage.removeItem('user');
					localStorage.removeItem('company');
					this.$store.state.auth.user = null
					this.$store.dispatch('auth/logout');
					this.$router.push('/login');
				}
			}
		);
	}
};
</script>