import { render, staticRenderFns } from "./DashboardExaminer.vue?vue&type=template&id=59671ab6&scoped=true&"
import script from "./DashboardExaminer.vue?vue&type=script&lang=js&"
export * from "./DashboardExaminer.vue?vue&type=script&lang=js&"
import style0 from "./DashboardExaminer.vue?vue&type=style&index=0&id=59671ab6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59671ab6",
  null
  
)

export default component.exports