<template>
  <div class="col-sm-12">
    <div class="row secondNavbar">
      <div class="col-5 links-container">
        <router-link v-if="from.path == '/softskills-statistics'" :to="{ path: '/softskills-statistics?id=' + id_area }"
          class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Soft Skills
          Statistics</router-link>
        <router-link v-else-if="from.path == '/business-process'" :to="from.path"
          class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
          Process</router-link>
      </div>
      <div v-if="from.path == '/softskills-statistics'" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suit Tools > </router-link>
        <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
        <router-link :to="{ path: '/softskills-statistics?id=' + id_area }" class="breadcumb"> Soft Skills Statistics >
        </router-link>
        <router-link :to="{ path: '/softskills-user' + '?id_area=' + id_area + '&id=' + id }" class="final-breadcumb">
          User
        </router-link>
      </div>
      <div v-else-if="from.path == '/business-process'" class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/modules' }" class="breadcumb"> Suit Tools > </router-link>
        <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
        <router-link :to="{ path: '/softskills-user' + '?id_area=' + id_area + '&id=' + id }" class="final-breadcumb">
          User
          (Soft Skills
          Process)</router-link>
      </div>
    </div>

    <div class="col-sm-8 col-centered">
      <UserS4Component />
    </div>
  </div>
</template>

<script>
import UserS4Component from "@/components/S4Components/User.vue";

export default {
  name: 'UserS4View',
  components: {
    UserS4Component
  },
  data() {
    return {
      id_area: this.$route.query.id_area,
      id: this.$route.query.id,
      from: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
};
</script>

<style scoped>
#app>.bg-white .main-container {
  max-width: 100%;
  float: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}
</style>