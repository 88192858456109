<template>
	<div class="input-group color-picker" ref="colorpicker">
		<input type="text" class="form-control" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" :disabled="disableChanges"/>
		<span class="input-group-addon color-picker-container" v-if="!disableChanges">
			<span class="current-color-color-picker cursor-pointer" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
			<chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker"/>
		</span>
		<span class="input-group-addon color-picker-container" v-else>
			<span class="current-color-color-picker" :style="'background-color: ' + colorValue"></span>
		</span>
	</div>
</template>

<script>
	import { Chrome } from 'vue-color'
	
	export default {
		props: {
			color: String,
			disableChanges: Boolean
		},
		name: "ColorPickerSelector",
		components: {
			'chrome-picker': Chrome,
		},
		data() {
			return {
				colors: {
					hex: '#000000',
				},
				colorValue: '',
				displayPicker: false,
			}
		},
		created() {
			this.colorValue = this.color
		},
		methods: {
			setColor(color) {
				this.updateColors(color);
				this.colorValue = color;
			},
			updateColors(color) {
				if(color.slice(0, 1) == '#') {
					this.colors = {
						hex: color
					};
				}
				else if(color.slice(0, 4) == 'rgba') {
					var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
						hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
					this.colors = {
						hex: hex,
						a: rgba[3],
					}
				}
			},
			showPicker() {
				document.addEventListener('click', this.documentClick);
				this.displayPicker = true;
			},
			hidePicker() {
				document.removeEventListener('click', this.documentClick);
				this.displayPicker = false;
			},
			togglePicker() {
				this.displayPicker ? this.hidePicker() : this.showPicker();
			},
			updateFromInput() {
				this.updateColors(this.colorValue);
			},
			updateFromPicker(color) {
				this.colors = color;
				if(color.rgba.a == 1) {
					this.colorValue = color.hex;
				}
				else {
					this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
				}
			},
			documentClick(e) {
				var el = this.$refs.colorpicker,
					target = e.target;
				if(el !== target && !el.contains(target)) {
					this.hidePicker()
				}
			}
		},
		watch: {
			colorValue(val) {
				if(val) {
					this.updateColors(val);
					this.$emit('input', val);
				}
			}
		}
	}
</script>

<style scoped>
.vc-chrome {
	position: absolute;
	top: 35px;
	right: 0;
	z-index: 9;
}

.input-group {
	align-items: center !important;
}

.color-picker-container {
	margin-left: 10px !important;
}

.current-color-color-picker {
	width: 25px;
	height: 25px;
	display: inline-block;
	border-radius: 50%;
}

.cursor-pointer {
	cursor: pointer;
}
</style>