<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<select class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
			<option hidden value="">Select an area</option>
			<option v-for="area in areas" :value="area.id">{{area.name}}</option>
		</select>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<pie-chart 
				:ref="chartId"
				:chartId="chartId" 
				:key="graphLoaded"
				:category="category" 
				:value="value"
				:data="data"
				:tooltipNoDataMsg="tooltipNoDataMsg"></pie-chart>
		</div>
	</div>
</template>

<script>
	import PieChart from "@/components/Others/Charts/PieChart.vue";
	import ReportsDataService from '@/services/ReportsDataService';

	export default {
		name: 'QuestionGraphByLanguage',
		data() {
			return {
				chartId: "chartQuestionGraphByLanguage",
				data: [],
				graphTitle: "Validated questions per language",
				category: "name",
				value: "count",
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedAreaName: null,
				tooltipNoDataMsg: "Please, select an area"
			}
		},
		components: {
			PieChart
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.selectedArea = event.target.value
				this.graphLoading = true;

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea)){
						this.selectedAreaName = area.name
						this.graphTitle = "Validated questions per language in " + this.selectedAreaName;
					}
				});

				this.fetchData();
			},
			async fetchData() {
				await ReportsDataService.questionsPerLanguageByArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.tooltipNoDataMsg = this.data.length == 0 ? "There are no questions for this area" : "";
							this.graphLoaded = this.graphLoaded ? false : true;
							this.graphLoading = false;
						}
					},
					error => {
						this.graphLoading = false;
						console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>