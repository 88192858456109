<template>
  	<div class="form-container technologiesStyle">
  		<br>
  		<table class="table">
  			<thead>
  				<th>Title</th>
  				<th>Description</th>
  				<th>Categories</th>
  				<th>Activation dates</th>
  				<th>Time limit</th>
  				<th>Questions</th>
  				<th>Assessment test level of difficulty</th>
  				<th>Level of difficulty of the questions</th>
  				<th>Language</th>
  				<th>Wrong Answer Penalty</th>
  				<th>Limit of attempts</th>
  			</thead>
  			<tbody>
  				<tr>
  					<td>{{test.title}}</td>
  					<td>
  						<p v-if="test.description != ''">{{test.description}}</p>
  						<p v-else>Not set</p>
  					</td>
  					<td>
  						<ul class="inline-tags">
				          	<li v-for="tag in tags" v-bind:key="tag.id" tabindex="0" class="ti-tag ti-valid inline-tags">
					          	<div class="ti-content"> 
					          		<div class="ti-tag-center">
					          			<span class="">{{tag.name}}</span>  
					          		</div> 
					          	</div>
					      	</li>
				        </ul>
				    </td>
			        <td>
			        	<p v-if="test.start_date == null && test.end_date == null">Not set</p>
			        	<p v-else><span v-if="test.start_date != null">{{test.start_date | formatEnglishDate}}</span><span v-else>No start date set</span> - <span v-if="test.start_date != null">{{test.end_date | formatEnglishDate}}</span><span v-else>No end date set</span></p>
			        </td>
			        <td><p v-if="test.time_limit != null">{{test.time_limit}}</p><p v-else>Not set</p></td>
			        <td>{{test.number_questions}}</td>
			        <td>{{testDifficulty.name}}</td>
			        <td>
			        	<p v-if="test.questions_same_difficulty">{{testDifficulty.name}}</p>
			        	<p v-else>
			        		<span v-for="difficulty in difficulties" v-bind:key="difficulty.id">
			        			<p v-if="difficulty.level == testDifficulty.level || difficulty.level == testDifficulty.level-1 || difficulty.level == testDifficulty.level+1">{{difficulty.name}}</p>
			        		</span>
			        	</p>
			        </td>
			        <td>{{testLanguage.name}}</td>
			        <td>{{test.incorrect_value}}</td>
			        <td>{{test.number_attempts}}</td>
  				</tr>
  			</tbody>
  		</table>

  		<form action class="form" @submit.prevent="handleCreate">
	  		<div class="row">
				<label class="full-width align-left"><input class="form-input" type="radio" id="manual_mode" name="mode" value="manual" v-validate="'required'" data-vv-name="mode" v-model="selectedMode"><h5>Manual selection</h5></label>

		  		<div class="row" id="manual_settings_container" style="display: none;">
					<p>Number of selected questions: <b>{{ selectedRows.length }}</b></p>
					<p v-if="selectedRows.length > 0">Average difficulty: <b>{{ averageDifficultyLevel }}</b></p>

					<b-pagination
				      v-model="currentPage"
				      :total-rows="rows"
				      :per-page="perPage"
				    ></b-pagination>
					<b-table 
					id="my-table"
					:tbody-tr-class="tbodyRowClass"			      	
					:per-page="perPage"
			      	:current-page="currentPage"
					:fields="headers"
					:items="questions"
				    :select-mode="selectionMode"
				    responsive="sm"
				    ref="selectableTable"
				    selectable
				    @row-selected="onRowSelected"
					@row-clicked="rowClicked"
	    			v-bind:class = "{'clickable':clickable}">
						<template v-slot:cell(selected)="{ item, field: { key } }" >
							<div v-if="item[key]">
								<span aria-hidden="true">&check;</span>
					          	<span class="sr-only">Selected</span>
							</div>
							<div v-else>
					          <span aria-hidden="true">&nbsp;</span>
					          <span class="sr-only">Not selected</span>
							</div>
						</template>
						<template #cell(question_title)="data">
							<p class="align-left">{{data.item.question_title}}</p>
				      	</template>
				      	<template #cell(tags)="data">
							<ul class="ti-tags small">
								<li class="ti-tag ti-valid" v-for="item in data.item.tags" v-bind:key="item.id">
									<div class="ti-content">
										<div class="ti-tag-center">
											<span>{{item.name}}</span>
										</div>
									</div>
								</li>
							</ul>
				      	</template>
				      	<template #cell(difficulty)="data">
							<p>{{data.item.difficulty_level || data.item.difficulty}}</p>
				      	</template>
						<template #cell(options)="data">
							<button type="button" class="btn btn-secondary" @click="showQuestion(data.item)"><font-awesome-icon icon="eye" /></button>
				      	</template>
					</b-table>
				    <b-button @click="selectAllRows"><font-awesome-icon icon="check" /> Select all</b-button>
				    <b-button :disabled="selectedRows.length === 0" @click="clearSelected"><font-awesome-icon icon="ban" /> Clear selected</b-button>
		  		</div>
		  		<hr>

	  			<label class="full-width align-left"><input class="form-input" type="radio" name="mode" id="random_mode" value="random" v-validate="'required'" data-vv-name="mode" v-model="selectedMode"><h5>Automatic selection</h5></label>

		  		<div class="row" id="random_settings_container" style="display: none;">
	  				<div class="col-md-6 align-item-center">
		  				<p>Add questions automatically with the default assessment test parameters</p>
		  			</div>
		  		</div>

		  		<hr>
		  		<div v-if="submitted && veeErrors.has('mode')" class="alert alert-danger">
			    	{{veeErrors.first('mode')}}. Please, select at least one mode for adding questions to the test
			    </div>

		  		<div id="message" v-if="message != '' && submitted && !veeErrors.has('mode')" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
		      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
		      	</div>

		  		<div class="form-group row align-item-center">
					<input v-if="(!message && !submitted && test.number_questions == numberSelected) || (selectedMode != 'manual' && selectedMode != '')" class="btn btn-primary form-submit" type="submit" value="Add Questions">
				</div>

	  		</div>
	  	</form>

		<b-modal id="question-modal" size="lg" ref="question-modal">
			<question-modal :modalQuestion="modalQuestion"/>
		</b-modal>
	</div>
</template>

<script>
	import TestDataService from "@/services/TestDataService";
	import $ from 'jquery';
	import QuestionModal from '@/components/Others/Modals/Question';

	export default {
	    name: "AddQuestionsComponent",
	    data() {
  			return {
		    	headers: [
		          'selected', { key: 'question_title', label: 'Question title' }, { key: 'tags', label: 'Tags' }, { key: 'difficulty', label: 'Difficulty' }, { key: 'options', label: 'Preview' }
		        ],
		        questions: [],
				questionsToSave: [],
				questionsObject: {},
		        message: "",
		        averageDifficultyLevel: '',
		        selectionMode: 'multi',
	        	selectedQuestions: {1: []},
	        	selectedIds: {1: []},
				selectedPage: 1,
			  	selectedMode: "",
			  	successfull: false,
			  	numberSelected: 0,
			  	submitted: false,
			  	test: {},
			  	tags: [],
		        perPage: 20,
	        	currentPage: 1,
        		clickable: true,
        		modalQuestion: {},
        		testLanguage: {},
        		testDifficulty: {},
        		difficulties: []
	    	}
		},
		components: {
			QuestionModal
		},
	    computed: {
	      	rows() {
	        	return this.questions.length
	      	},
		    selectedRows() {
        		return this.questions.filter(item => item.selected)
      		}
	    },
		methods: {
			getTest(){
				TestDataService.findOne(this.$route.query.id).then(
					response => {
						this.test = response.data;
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			getTags(){
				TestDataService.getTags(this.$route.query.id).then(
					response => {
						this.tags = response.data;
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			findQuestionsForTest(){
				TestDataService.findQuestionsForTest(this.$route.query.id).then(
					response => {
						this.questions = response.data.questions
						var counter = 0
						var counter2 = 1
						for (var i=0; i<this.questions.length; i++) {
							if(counter < 20) {
								if(!(counter2 in this.questionsObject)) {
									this.questionsObject[counter2] = []
									i -= 1
								} else {
									this.questionsObject[counter2].push(this.questions[i])
									counter += 1
								}
							} else {
								counter = 0
								counter2 += 1
							}
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			getTestBoard(){
				const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
				TestDataService.getTestBoard(currentAreaID).then(
					response => {
						if (response.status == 200) {
							let difficulty_levels = response.data.difficulty_levels
							this.difficulties = difficulty_levels

							for (var i=0; i<difficulty_levels.length; i++) {
								if (difficulty_levels[i].id == this.test.id_difficulty_level) {
									this.testDifficulty = difficulty_levels[i]
									this.averageDifficultyLevel = difficulty_levels[i].name
									break;
								}
							}
							
							let languages = response.data.languages
							for (var j=0; j<languages.length; j++) {
								if (languages[j].id == this.test.id_language) {
									this.testLanguage = languages[j]
									break;
								}
							}
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			rowClicked(item) {
				if(item.selected){      
					this.$set(item, 'selected', false)
				} else {
					this.$set(item, 'selected', true)
				}
			},
      		showQuestion(record) {
      			this.modalQuestion = record
      			this.$refs['question-modal'].show()
		    },
			handleCreate() {
				this.submitted = true;

	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					var mode = "";
	  					if (this.selectedMode == 'manual') {
  							mode = 'manual'
		  					if (this.selectedQuestions.length == 0 || parseInt(this.selectedRows.length) != parseInt(this.test.number_questions)) {
		  						this.message = "Less than " + this.test.number_questions + " questions selected. Please, select the number of questions defined on test configuration";
		  						return;
		  					}
	  					} else {
	  						mode = 'default'
	  					}

	  					TestDataService.addQuestionsToTest(mode, this.$route.query.id, this.questionsToSave).then(
							response => {
								if (response.status == 200) {
									this.successfull = true;
									console.log('response data addquestionstotest: ', response)
									this.$router.push({ name: "tests", params: {questionsAdded: true} });
								} else {
									this.message = response.data.message
								}
							},
							error => {
								console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())
								
								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
					    );
	  				} else {
	  					this.message = "Please, fill all the required fields";
	  				}
	  			})
			},
			onRowSelected(items) {
				this.questionsToSave = items; 

				var selected = false
				items.forEach(item => {
					if (!this.selectedIds[this.selectedPage].includes(item.id)) {
						this.selectedQuestions[this.selectedPage].push(item)
						this.selectedIds[this.selectedPage].push(item.id)
						selected = true
					}
				})

				if(!selected) {
					items.forEach(item => {
						if (!this.selectedIds[this.selectedPage].includes(item.id)) {
							this.selectedQuestions[this.selectedPage].push(item)
							this.selectedIds[this.selectedPage].push(item.id)
							selected = true
						}
					})
				}

				this.numberSelected = this.selectedRows.length;

				if (parseInt(this.selectedRows.length) > parseInt(this.test.number_questions)) {
					this.message = "More than " + this.test.number_questions + " questions selected. Please, select the number of questions defined on test configuration";
					this.submitted = true;
		  			return;
				} else {
					var weightSum = 0;
					var averageDifficulty = 0;

					for(var i=0; i<this.selectedRows.length; i++) {
						var questionWeight;
						var assigned = false;
						for(var j=0; j<this.difficulties.length; j++) {
							if(this.difficulties[j].name == items[i].difficulty) {
								questionWeight = this.difficulties[j].level
								assigned = true;
							}

							if(assigned) break;
						}

						weightSum = weightSum + questionWeight;
					}

					averageDifficulty = weightSum / this.selectedRows.length;

					let lowerValueToCheck = 0;
					let upperValueToCheck = 0;

					let difficultiesTmp = [...this.difficulties]

					difficultiesTmp.unshift({level: 0, name: ""})

					difficultiesTmp.sort(function (a, b) {
						return a.level - b.level;
					});

					for (var i = 1; i < difficultiesTmp.length; i++){
						if (i == 0)
							lowerValueToCheck = difficultiesTmp[i].level
						else 
							lowerValueToCheck = difficultiesTmp[i].level - 0.25

						if (i+1 == difficultiesTmp.length)
							upperValueToCheck = difficultiesTmp[i].level + 5
						else 
							upperValueToCheck = difficultiesTmp[i+1].level + 0.25

						if (averageDifficulty >= lowerValueToCheck && averageDifficulty < upperValueToCheck) {
							this.averageDifficultyLevel = difficultiesTmp[i].name
						}
					}

					if(this.averageDifficultyLevel != this.testDifficulty.name){
						this.message = "The level of the selected questions (" +this.averageDifficultyLevel + ") is different from the level of the test (" + this.testDifficulty.name + ").";
						this.submitted = true;
						return;
					} else {
						this.message = "";
						this.submitted = false;
					}
				}
	      	},
	      	selectAllRows() {
		        this.$refs.selectableTable.selectAllRows()

				this.questions.forEach((item) => {
					this.$set(item, 'selected', true)
				})
	      	},
			clearSelected() {
		        this.$refs.selectableTable.clearSelected();

				this.selectedRows.forEach((item) => {
					this.$delete(item, 'selected')
				})
			},
		    tbodyRowClass(item) {
				if(item.selected)
					return ["b-table-row-selected", "table-primary"]
			},
			close() {
				this.message = "";
			}
		},
		mounted() {
			$('input[name="mode"]').click(function() {
				if ($(this).val() == "manual") {
					$('#manual_settings_container').css('display', 'block')
					$('#random_settings_container').css('display', 'none')
				} else {
					$('#manual_settings_container').css('display', 'none')
					$('#random_settings_container').css('display', 'block')
				}
			});

			$('input[name="percentages_configuration"]').click(function() {
				if ($(this).attr('id') == "manual_percentages") {
					$('#manual_percentages_configuration').css('display', 'block')
				} else {
					$('#manual_percentages_configuration').css('display', 'none')
				}
			});

			this.getTest();
			this.getTags();
			this.findQuestionsForTest();
		    this.getTestBoard();
	  	}
	};
</script>