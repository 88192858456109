<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div class="links-container">
        <router-link :to="{path: '/exam-results-list'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to test list</router-link>
        <reports-downloader :graphContent="graphContent" :documentTitle="documentTitle" :testListTitle="testListTitle"/>
      </div>
      <div class="whiteColor">Exam Results reports</div>
    </div>

    <test-results-graph-by-user ref="chart1"/>
    <test-results-graph-by-test-question ref="chart2"/>
    <test-results-graph-by-hit-and-miss ref="chart3"/>
    <test-results-bubble-graph ref="chart4"/>
  </div>
</template>

<script>
  import TestResultsGraphByUser from "@/components/ReportsComponents/Results/Tests/TestResultsGraphByUser.vue";
  import TestResultsGraphByTestQuestion from "@/components/ReportsComponents/Results/Tests/TestResultsGraphByTestQuestion.vue";
  import TestResultsGraphByHitAndMiss from "@/components/ReportsComponents/Results/Tests/TestResultsGraphByHitAndMiss.vue";
  import TestResultsBubbleGraph from "@/components/ReportsComponents/Results/Tests/TestResultsBubbleGraph.vue";

  export default {
    name: 'FeatureReportsView',
    components: {
      TestResultsGraphByUser,
      TestResultsGraphByTestQuestion,
      TestResultsGraphByHitAndMiss,
      TestResultsBubbleGraph
    },
    data() {
      return {
        graphContent: [
          {title: "Column diagram with results distribution"},
          {title: "Bubble diagram of test results"}
        ],
        documentTitle: "exam_results_reports.pdf",
        testListTitle: {
          "chart2": "Horizontal barrs diagram with answers selected by option of question",
          "chart3": "Horizontal barr diagram with correct/wrong answers distribution of question",
        }
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>