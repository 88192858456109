<template>
	<div v-bind:class = "{'table-container':!home, 'col-sm-12': home}" class="technologiesStyle my-2">
		<h4>Upcoming Recorded Video Interviews</h4>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>

		<template v-if="meetings.length > 0">
			<div>
				<div class="row title-row">
					<div class="col-sm-3">
						<b-pagination
					      v-model="currentPage"
					      :total-rows="rows"
					      :per-page="perPage"
					      aria-controls="userMeetings"
					    ></b-pagination>
					</div>
					<div class="col-sm-7">
						<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>

						<button v-b-tooltip.hover title="Select all rows" class="btn btn-primary table-selection-button" type="button" @click="selectAllRows"><font-awesome-icon icon="check" class="white-color" /></button>  
						<button v-b-tooltip.hover title="Clear All" class="btn btn-primary table-selection-button" type="button" @click="clearSelected"><font-awesome-icon icon="ban" class="white-color" /></button>  
						<button v-b-tooltip.hover :title="'Delete ' + selectedItems.length + ' rows'" v-if="selectedItems.length > 0" class="btn btn-primary table-selection-button delete-button" type="button" @click="showDeleteModalMultiple"><font-awesome-icon icon="trash" class="white-color" /></button>   
					</div>
					<div class="col-sm-2">
						<b-form-input 
							id="filter-input"
			              	v-model="filter"
			              	type="search"
			              	placeholder="Search"
		            	></b-form-input>
					</div>
				</div>
				
				<b-table ref="userMeetingsTable"
					id="userMeetings"
			      	:per-page="perPage"
			      	:current-page="currentPage"
					:fields="headers" 
					:items="meetings" 
					:filter="filter"
					select-mode="multi"
				    responsive="sm"
				    selectable
				    @row-selected="onRowSelected">
					<template #cell(id)="data">
				        {{ data.item.id }}
			      	</template>
					<template #cell(createdAt)="data">
				        {{ data.item.createdAt | formatEnglishDate }}
			      	</template>
			      	<template #cell(name)="data">
				        {{ data.item.name }}
			      	</template>
					<template #cell(options)="data">
						<button v-b-tooltip.hover title="Show info" class="btn btn-secondary" @click="showInfoModal(data.item)">
							<font-awesome-icon icon="info-circle" />
						</button>
						<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show recorded video interview results" class="btn btn-secondary" @click="data.toggleDetails">
							<font-awesome-icon icon="eye" />
						</button>
						<button v-else v-b-tooltip.hover title="Hide recorded video interview results" class="btn btn-secondary" @click="data.toggleDetails">
							<font-awesome-icon icon="eye-slash" />
						</button>
						<router-link v-b-tooltip.hover title="Edit recorded video interview" class="btn btn-secondary" :to="{path: 'update-videoconference?id=' + data.item.id}">
							<font-awesome-icon icon="edit" />
						</router-link>
						<button v-b-tooltip.hover title="Finish recorded video interview" class="btn btn-secondary" @click="showFinishInterviewModal(data.item)">
							<font-awesome-icon icon="video-slash" />
						</button>
					</template>
			      	<template #row-details="data">
				        <div class="row">
			      			<div class="col-md-6">
			      				<p class="align-item-left">Candidates that completed the interview</p>
			      			</div>
			      			<div class="col-md-6">
			      				<p class="align-item-left"><b>{{data.item.interviewStats.totalCompleted}}</b></p>
			      			</div>
			      		</div>
			      		<div class="row">
			      			<div class="col-md-6">
			      				<p class="align-item-left">Candidates that did not complete the interview</p>
			      			</div>
			      			<div class="col-md-6">
			      				<p class="align-item-left"><b>{{data.item.interviewStats.totalPending}}</b></p>
			      			</div>
			      		</div>
			      		<div class="row">
			      			<div class="col-md-6">
			      				<p class="align-item-left">Candidates that were rejected</p>
			      			</div>
			      			<div class="col-md-6">
			      				<p class="align-item-left"><b>{{data.item.interviewStats.totalArchived}}</b></p>
			      			</div>
			      		</div>
			      		<div class="row">
			      			<div class="col-md-6">
			      				<p class="align-item-left">Candidates that have selected and approved</p>
			      			</div>
			      			<div class="col-md-6">
			      				<p class="align-item-left"><b>{{data.item.interviewStats.totalFinalist}}</b></p>
			      			</div>
			      		</div>
			      		<hr>
			      		<div class="row">
			      			<div class="col-md-6">
			      				<p class="align-item-left">Total candidates</p>
			      			</div>
			      			<div class="col-md-6">
			      				<p class="align-item-left"><b>{{data.item.interviewStats.total}}</b></p>
			      			</div>
			      		</div>
				    </template>
				</b-table>
			</div>
		</template>

		<template v-else-if="meetings.length === 0 && dataLoading">
			<div v-if="dataLoading" class="text-center text-loading">
		      <b-spinner class="align-middle analysis-spinner"></b-spinner>
		      <strong class="text-loading-msg"> Loading upcoming video interviews...</strong>
		    </div>
		</template>
		
		<template v-else-if="meetings.length === 0 && !dataLoading">
			<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
		</template>

      	<b-modal size="xl" id="info-upcoming-modal" ref="info-upcoming-modal" title="info-upcoming-modal">
			<template v-if="selectedInterview != null" #modal-title>
				Interview "{{selectedInterview.name}}"
			</template>
			<div v-if="selectedInterview != null">
				<h6>{{selectedInterview.description}}</h6>
				<hr>
				<div class="row">
	      			<div class="col-md-6">
	      				<p class="align-item-left">Candidates that completed the interview</p>
	      			</div>
	      			<div class="col-md-6">
	      				<p class="align-item-left"><b>{{selectedInterview.interviewStats.totalCompleted}}</b></p>
	      			</div>
	      		</div>
	      		<div class="row">
	      			<div class="col-md-6">
	      				<p class="align-item-left">Candidates that did not complete the interview</p>
	      			</div>
	      			<div class="col-md-6">
	      				<p class="align-item-left"><b>{{selectedInterview.interviewStats.totalPending}}</b></p>
	      			</div>
	      		</div>
	      		<div class="row">
	      			<div class="col-md-6">
	      				<p class="align-item-left">Candidates that were rejected</p>
	      			</div>
	      			<div class="col-md-6">
	      				<p class="align-item-left"><b>{{selectedInterview.interviewStats.totalArchived}}</b></p>
	      			</div>
	      		</div>
	      		<div class="row">
	      			<div class="col-md-6">
	      				<p class="align-item-left">Candidates that have selected and approved</p>
	      			</div>
	      			<div class="col-md-6">
	      				<p class="align-item-left"><b>{{selectedInterview.interviewStats.totalFinalist}}</b></p>
	      			</div>
	      		</div>
	      		<hr>
	      		<div class="row">
	      			<div class="col-md-6">
	      				<p class="align-item-left">Total candidates</p>
	      			</div>
	      			<div class="col-md-6">
	      				<p class="align-item-left"><b>{{selectedInterview.interviewStats.total}}</b></p>
	      			</div>
	      		</div>

				<b-table ref="candidatesTable"
			        id="candidatesList"
			        :fields="candidatesTableHeaders" 
			        :items="selectedInterview.interviews">
			        <template #cell(completed)="dataCandidates">
		                <p v-if="dataCandidates.item.status == 'pending'"  class="pending-status">{{ dataCandidates.item.status.toUpperCase() }}</p>
		                <p v-else-if="dataCandidates.item.status == 'completed'"  class="active-status">{{ dataCandidates.item.status.toUpperCase() }}</p>
		                <p v-else-if="dataCandidates.item.status == 'archived'"  class="archived-status">DISCARDED</p>
		                <p v-else-if="dataCandidates.item.status == 'finalist'"  class="active-status">{{ dataCandidates.item.status.toUpperCase() }}</p>
		                <p v-else class="active-status">{{ dataCandidates.item.status.toUpperCase() }}</p>
		            </template>
			        <template #cell(name)="dataCandidates">
		                <p>{{ dataCandidates.item.candidate.name }}</p>
		            </template>
		            <template #cell(email)="dataCandidates">
		                <p>{{ dataCandidates.item.candidate.email }}</p>
		            </template>
		            <template #cell(finished)="dataCandidates">
		                <p v-if="dataCandidates.item.status == 'completed'">{{ formatMilliseconds(dataCandidates.item.completed) | formatEnglishDate }}</p>
		                <p v-else>Not completed</p>
		            </template>
		            <template #cell(questions)="dataCandidates">
		                <p>{{ dataCandidates.item.questions.length }}</p>
		            </template>
		            <template #cell(options)="dataCandidates">
		            	<button v-b-tooltip.hover title="Access to results in Hireflix" class="btn btn-secondary" @click="redirectToInterview(dataCandidates.item.url.private)">
							<font-awesome-icon icon="video" />
						</button>
		            </template>
		        </b-table>
			</div>
		</b-modal>

		<b-modal id="send-modal" ref="send-modal" title="Send-modal" hide-footer>
			<template #modal-title>
				Send meeting invitation
			</template>
			<div>
				<p>Do you really want to invite this user to this meeting? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('send-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="sendEmail()" value="Yes">
		</b-modal>

		<b-modal id="finish-interview-modal" ref="finish-interview-modal" title="Finish-interview-modal" hide-footer>
			<template #modal-title>
				Finish interview
			</template>
			<div>
				<p> Do you really want to finish this interview? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('finish-interview-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="finishInterview()" value="Yes">
		</b-modal>

		<b-modal id="delete-modal" ref="delete-modal" title="Delete-modal" hide-footer>
			<template #modal-title>
				Delete meeting
			</template>
			<div>
				<p v-if="selectedItems.length > 1"> Do you really want to delete {{selectedItems.length}} meetings? </p>
				<p v-else> Do you really want to delete this meeting? </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('delete-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="deleteMeeting()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
	import InterviewsDataService from '@/services/InterviewsDataService';
	import GraphQLDataService from '@/services/GraphQLDataService';
	import UserDataService from '@/services/UserDataService';

	export default {
		name: 'ComingMeetingsList',
		data() {
			return {
				headers: [
					{ key: 'id', sortable: true, label: 'Interview Id' },
					{ key: 'createdAt', sortable: true, label: 'Created at' },
					{ key: 'name', sortable: true, label: 'Name' },
					{ key: 'options', label: 'Options' }
				],
				candidatesTableHeaders: [
					{ key: 'completed', sortable: true, label: 'Status' },
					{ key: 'name', sortable: true, label: 'Name' },
					{ key: 'email', sortable: true, label: 'Email' },
					{ key: 'finished', sortable: true, label: 'Date completed' },
					{ key: 'questions', sortable: true, label: 'Number of questions' },
					{ key: 'options', label: 'Options' }
				],
				meetings: [],
				dataLoading: false,
				message: "",
				successfull: false,
				perPage: 10,
				pageOptions: this.$paginationOptions,
				currentPage: 1,
				selectedId: null,
				filter: null,
				selectedItems: [],
				selectedInterview: null,
				selectedInterviewIndex: null
			}
	    },
	    props: {
	    	home: Boolean
	    },
		mounted() {
			this.dataLoading = true;
			InterviewsDataService.findByCreator(this.$store.state.auth.user.id).then(
				response => {
					if (response.status == 200) {
						let meetingsTmp = response.data;

						if (meetingsTmp.length > 0)
							this.getUserOpenInterviews(meetingsTmp);
					} else {
						this.message = "An error ocurred while retrieving interviews.";
						this.successfull = false;
					}
				},
				error => {
					this.dataLoading = false;
					console.error((error.response && error.response.data) ||
			          error.message ||
			          error.toString())
					this.message = (error.response && error.response.data) ||
			          error.message ||
			          error.toString();
			        this.successfull = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
	  	},
	    computed: {
			rows() {
				return this.meetings.length
			}
	    },
	  	methods: {
	  		onRowSelected(items) {
		        this.selectedItems = items;
	      	},
	      	selectAllRows() {
	      		this.$refs["userMeetingsTable"].selectAllRows();
	      	},
	      	clearSelected() {
	      		this.$refs["userMeetingsTable"].clearSelected();
	      	},
			showSendModal(id) {
				this.selectedId = id
				this.$refs['send-modal'].show();
	      	},
	      	async getUserOpenInterviews(meetings) {
	      		await GraphQLDataService.getInterviewsFromUser(this.$apollo).then(
					response => {
						if (response.networkStatus == 7 || response.status == 200){
							let interviews = response.data.positions;

							this.meetings = interviews.filter(interview => meetings.some(meet => parseInt(interview.id) == parseInt(meet.id_interview) && interview.archived == null));

							this.meetings.forEach((meeting) => {
								let id = meetings.filter(m => parseInt(m.id_interview) == parseInt(meeting.id)).map(function(e){return e.id})[0]
								meeting.id_interview = meeting.id;
								meeting.id = id;
							})

							this.meetings.forEach((meeting) => {
								meeting.createdAt = new Date(meeting.createdAt);
								meeting.createdAt = meeting.createdAt.toString();
							})

							this.dataLoading = false;
						} else {
							this.message = "An error ocurred while retrieving interviews from user.";
							this.successfull = false;
							this.dataLoading = false;
						}
					},
					error => {
						this.dataLoading = false;
						this.message = error.response.data.message;
						this.successfull = false;
						console.error((error.response && error.response.data) ||
				          error.message ||
				          error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
	      	redirectToInterview(interviewLink){
	      		let interviewLinkTmp = interviewLink.replace("/interview/undefined", "/")
                window.open(interviewLinkTmp, '_blank');
	      	},
			sendEmail(){
				UserDataService.sendEmail(this.selectedId).then(
					response => {
						this.message = response.data.message
						if (response.status == 200) {
							this.successfull = true;
						} else {
							this.successfull = false;
						}
						this.$refs['send-modal'].hide();
					},
					error => {
						this.message = error.response.data.message;
						this.successfull = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
	      	showInfoModal(interview) {
	      		this.selectedInterview = interview;
	      		this.$refs['info-upcoming-modal'].show();
	      	},
	      	showFinishInterviewModal(interview) {
	      		this.selectedInterview = interview;
	      		this.$refs['finish-interview-modal'].show();
	      	},
	      	finishInterview() {
	      		GraphQLDataService.finishInterview(this.$apollo, this.selectedInterview.id_interview).then(
					response => {
						this.meetings = this.meetings.filter(function(value, index) {
						    return this.selectedInterview.id != value.id;
						});

						this.message = "Interview '" + this.selectedInterview.id.toString() + "' successfully finished!";
						this.successfull = true;
						this.$refs['finish-interview-modal'].hide();
					},
					error => {
						console.error((error.response && error.response.data) ||
				          error.message ||
				          error.toString())
			      		this.message = "An error ocurred while finishing selected interview from Hireflix.";
			      		this.successfull = false;
			      		this.$refs['finish-interview-modal'].hide();

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
	      	showDeleteModalMultiple() {
	      		this.$refs['delete-modal'].show();
	      	},
			deleteMeeting(){
				let selectedInterviewIDs = this.selectedItems.map(element => element.id_interview);
				let selectedRows = this.selectedItems.map(element => element.id);

				let itemsProcessed = 0;
				selectedInterviewIDs.forEach((interviewID) => {
					GraphQLDataService.delete(this.$apollo, interviewID).then(
						response => {
							itemsProcessed++;
							if (itemsProcessed === selectedRows.length) {
								this.deleteMeetingsFromDB(selectedRows);
							}
						},
						error => {
							console.error((error.response && error.response.data) ||
					          error.message ||
					          error.toString())
				      		this.message = (error.response && error.response.data) ||
					          error.message ||
					          error.toString();
				      		this.successfull = false;
				      		this.$refs['delete-modal'].hide();
							
							if (error.response.status === 300) {
								console.log('Logout: Token expired')
								localStorage.removeItem('user');
								localStorage.removeItem('company');
								this.$store.state.auth.user = null
								this.$store.dispatch('auth/logout');
								this.$router.push('/login');
							}
						}
					);
				})
	      	},
	      	deleteMeetingsFromDB(selectedRows) {
	      		InterviewsDataService.delete(selectedRows).then(
					response => {
						if (response.status == 200) {
							this.meetings = this.meetings.filter(function(value, index) {
							    return selectedRows.indexOf(value.id) == -1;
							});

							this.message = "Interviews selected successfully deleted";
							this.successfull = true;
						} else {
							this.message = "An error ocurred while deleting selected interviews.";
							this.successfull = false;
						}
						
						this.$refs['delete-modal'].hide();
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = (error.response && error.response.data) ||
				          error.message ||
				          error.toString();
						this.successfull = false;
						this.$refs['delete-modal'].hide();

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	},
	      	formatMilliseconds(date) {
	      		let newDate = new Date(date);
				return newDate.toString();
	      	},
			close() {
				document.getElementById("message").style.display = "none"
			}
	    }
	};
</script>