import gql from "graphql-tag";

class GraphQLDataService {

  create(handler, interview) {
    const createInterviewMutation = gql`
      mutation CREATE_POS($position: PositionInputType) {
        Position {
          save(position: $position) {
            id
          }
        }
      }
    `;

    const interviewTmp = {
      name: interview.name,
      description: interview.description,
      timeToThink: parseInt(interview.questionTime),
      timeToAnswer: parseInt(interview.answerTime),
      retakes: parseInt(interview.retakes),
      questions: interview.questions
    }

    const request = {
      mutation: createInterviewMutation,
      variables: {position: interviewTmp},
    }

    return handler.mutate(request);
  }

  update(handler, interview) {
    const createInterviewMutation = gql`
      mutation CREATE_POS($position: PositionInputType) {
        Position {
          save(position: $position) {
            id
          }
        }
      }
    `;

    const interviewTmp = {
      id: interview.id_interview,
      name: interview.name,
      description: interview.description,
      timeToThink: parseInt(interview.questionTime),
      timeToAnswer: parseInt(interview.answerTime),
      retakes: parseInt(interview.retakes),
      questions: interview.questions
    }

    const request = {
      mutation: createInterviewMutation,
      variables: {position: interviewTmp},
    }

    return handler.mutate(request);
  }

  getInterviewsFromUser(handler) {
    const getInterviewsFromUserMutation = gql`
      query GET_ID {
        positions {
          id
          name
          description
          active
          createdAt
          interviewStats {
            total
            totalPending
            totalCompleted
            totalArchived
            totalFinalist
          }
          archived
          interviews {
            owner {
              id
              name
              email
            }
            archived
            completed
            status
            expires
            candidate {
              name
              email
            }
            url {
              private
            }
            questions {
              title
              description
              answered
              score
              retakes
              timeToThink
              timeToAnswer
            }
          }
        }
      }
    `;

    const query = {
      query: getInterviewsFromUserMutation, 
    };

    return handler.query(query)
  }

  getInterviewInfo(handler, interviewID) {
    const getInterviewMutation = gql`
      query GET_INTER_INFO($position: String!) {
        position(id: $position) {
          id
          name
          description
          active
          createdAt
          timeToThink
          timeToAnswer
          interviewStats {
            total
            totalPending
            totalCompleted
            totalArchived
            totalFinalist
          }
          archived
          retakes
          interviews {
            owner {
              id
              name
              email
            }
            archived
            completed
            status
            expires
            candidate {
              name
              email
            }
            url {
              private
            }
            questions {
              title
              description
              answered
              score
              retakes
              timeToThink
              timeToAnswer
            }
          }
        }
      }
    `;

    const query = {
      query: getInterviewMutation,
      variables: { position: interviewID }
    }

    return handler.query(query);
  }

  delete(handler, interviewId) {
    const deleteInterviewMutation = gql`
      mutation DELETE_POS($positionId: String) {
        Position(id: $positionId) {
          delete
        }
      }
    `;

    const request = {
      mutation: deleteInterviewMutation,
      variables: {positionId: interviewId},
    }

    return handler.mutate(request);
  }

  finishInterview(handler, interviewID) {
    const finishInterviewMutation = gql`
      mutation ARCHIVE_POS($positionId: String, $archive: Boolean!) {
        Position(id: $positionId) {
          archive(archive: $archive) {
            archived
          }
        }
      }
    `;

    const request = {
      mutation: finishInterviewMutation,
      variables: {
        positionId: interviewID,
        archive: true,
      },
    }

    return handler.mutate(request);
  }

  getCandidatesFromInterview(handler, interviewID){
    const getCandidatesMutation = gql`
      query GET_CANDIDATES($position: String!) {
        interviews(position: $position) {
          candidate {
            name
            email
          }
        }
      }
    `;

    const query = {
      query: getCandidatesMutation, 
      variables: {position: interviewID}
    };

    return handler.query(query)
  }

  inviteUserToInterview(handler, interviewID, userInfo){
    const inviteUsersMutation = gql`
      mutation INVITE_TO($candidate: inputCandidate, $positionId: String) {
        Position(id: $positionId) {
          invite(candidate: $candidate) {
            status
          }
        }
      }
    `;

    const request = {
      mutation: inviteUsersMutation,
      variables: {
        positionId: interviewID,
        candidate: userInfo,
      },
    }

    return handler.mutate(request)
  }
  
}

export default new GraphQLDataService();