import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'interview/';

class InterviewsDataService {

  create(meeting, candidate) {
    const newMeeting = {
      id_creator: meeting.id_creator,
      id_interview: meeting.id_interview,
      candidate: candidate,
      name: meeting.name,
      description: meeting.acronym,
      id_area: meeting.id_area,
    }
    return http.post(API_URL_PATH + 'create', { interview: newMeeting }, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  update(meeting) {
    const meetingToUpdate = {
      name: meeting.name,
      description: meeting.acronym,
      interview_date: meeting.interview_date,
      id_area: meeting.id_area,
    }
    return http.put(API_URL_PATH + 'create', { interview: meetingToUpdate }, { headers: authHeader() });
  }

  findOne(idMeeting) {
    return http.get(API_URL_PATH + 'findOne?id=' + idMeeting, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area=' + idArea, { headers: authHeader() });
  }

  findByCreator(idUser) {
    return http.get(API_URL_PATH + 'findByCreator?id_user=' + idUser, { headers: authHeader() });
  }

  findUserInvitedInterviews(idUser) {
    return http.get(API_URL_PATH + 'findUserInvitedInterviews?id_user=' + idUser, { headers: authHeader() });
  }

  addUsersToInterview(interviewID, userIDs){
    const body = { id_interview: interviewID, users: userIDs };
    
    return http.post(API_URL_PATH + 'addUsersToInterview', body, { headers: authHeader() });
  }

  deleteUsersFromInterview(idInterview, userIDs){
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "id_interview=" + idInterview + "users=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&users=" + id
    })

    return http.delete(API_URL_PATH + 'deleteUsersFromInterview?' + params, { headers: authHeader() });
  }

  findCreatedByDate(idArea) {
    return http.get(API_URL_PATH + 'findCreatedByDate?id_area=' + idArea, { headers: authHeader() });
  }

  createAreaSettings(config) {
    const newConfig = {
      id_area: config.id_area
    }
    return http.post(API_URL_PATH + 'createAreaSettings', { interview_settings: newConfig }, { headers: authHeader() });
  }

  updateAreaInterviewSettings(newConfig) {
    return http.put(API_URL_PATH + 'updateAreaInterviewSettings', { interview_settings: newConfig }, { headers: authHeader() });
  }

  findAreaSettings(idArea) {
    return http.get(API_URL_PATH + 'findAreaSettings?id_area='+idArea, { headers: authHeader() });
  }

  addExternalCandidatesToInterview(candidates) {
    return http.post(API_URL_PATH + 'addExternalCandidatesToInterview', { interview_candidates: candidates }, { headers: authHeader() });
  }

  removeExternalCandidatesFromInterview(candidateIds) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, candidateIds);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;
    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'removeExternalCandidatesFromInterview?' + params, { headers: authHeader() });
  }

  findExternalCandidatesFromInterview(idInterview) {
    return http.get(API_URL_PATH + 'findExternalCandidatesFromInterview?id_interview=' + idInterview, { headers: authHeader() });
  }
  
}

export default new InterviewsDataService();