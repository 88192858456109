<template>
	<div class="row">
		<div :class="'col-sm-' + pageOptionRowsWidth">
			<b-pagination
				v-model="currentPage"
				:total-rows="rows"
				:per-page="perPage"
				aria-controls="technologiesTable"
		    ></b-pagination>
		</div>
		<div :class="'col-sm-' + numItemsOptionRowsWidth">
			<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
		</div>
		<div :class="'col-sm-' + searchBarrOptionRowsWidth">
			<b-form-input 
				id="filter-input"
              	v-model="filter"
              	type="search"
              	:placeholder="$t('others.search-placeholder-msg')"
        	></b-form-input>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FilterOptions',
		data(){
	    	return {
		        pageOptions: this.$paginationOptions
			}
	    },
	    props: {
	    	pageOptionRowsWidth: Number,
	    	numItemsOptionRowsWidth: Number,
	    	searchBarrOptionRowsWidth: Number,
	    	perPage: Number,
        	currentPage: Number,
        	filter: Object,
        	rows: Number
	    }
	}
</script>