<template>
	<div class="row" v-if="!pdf">
		<div class="col-sm-12">
			<h4 style="margin-top: 1%;">User Recorded Video Interview Results</h4>

			<template v-if="userVideoconferences.length > 0">
				<div v-if="dataLoading" class="text-center text-loading">
			      <b-spinner class="align-middle analysis-spinner"></b-spinner>
			      <strong class="text-loading-msg"> Loading recorded video interviews...</strong>
			    </div>
				<div v-else>
			  		<div class="row mt-row">
						<div class="col-sm-3">
							<b-pagination
						      v-model="currentPage"
						      :total-rows="rows"
						      :per-page="perPage"
						      aria-controls="userVideoconferences"
						    ></b-pagination>
						</div>
						<div class="col-sm-7">
							<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
						</div>
						<div class="col-sm-2">
							<b-form-input 
								id="filter-input"
				              	v-model="filter"
				              	type="search"
				              	placeholder="Search"
			            	></b-form-input>
						</div>
					</div>
					
				  	<b-table ref="userVideoconferencesTable"
						id="userVideoconferences"
				      	:per-page="perPage"
				      	:current-page="currentPage"
						:fields="headers" 
						:items="userVideoconferences"
						:filter="filter" 
					    responsive="sm">
						<template #cell(name)="data">
					        <p v-if="data.item.name != ''">{{ data.item.name }}</p>
					        <p v-else>N/A</p>
				      	</template>
						<template #cell(questions)="data">
				          	<p>{{ data.item.result.questions.length }}</p>
				      	</template>
				      	<template #cell(status)="data">
				          	<p v-if="data.item.result.status == 'pending'" class="pending-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else-if="data.item.result.status == 'completed'" class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else-if="data.item.result.status == 'archived'" class="archived-status">DISCARDED</p>
			                <p v-else-if="data.item.result.status == 'finalist'" class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
				      	</template>
						<template #cell(completedAt)="data">
					        <p>{{ data.item.createdAt | formatEnglishDate }}</p>
				      	</template>
						<template #cell(options)="data">
							<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show test results" class="btn btn-secondary" @click="data.toggleDetails">
								<font-awesome-icon icon="eye" />
							</button>
							<button v-else v-b-tooltip.hover title="Hide test results" class="btn btn-secondary" @click="data.toggleDetails">
								<font-awesome-icon icon="eye-slash" />
							</button>
				      	</template>
				      	<template #row-details="data">
				      		<div class="row">
				      			<p class="align-left mt-2 mb-2"><b>Description:</b> {{data.item.description}}</p>
				      		</div>
				      		<b-table ref="candidatesQuestionsTable"
						        id="candidatesQuestionsList"
						        :fields="candidatesQuestionsTableHeaders" 
						        :items="data.item.result.questions">
						        <template #cell(title)="dataQuestions">
					                <p class="align-left">{{ dataQuestions.item.title }}</p>
					            </template>
						        <template #cell(description)="dataQuestions">
					                <p v-if="dataQuestions.item.description != null && dataQuestions.item.description.length > 0">{{ dataQuestions.item.description }}</p>
					                <p v-else>No description</p>
					            </template>
					            <template #cell(score)="dataQuestions">
					                <p v-if="dataQuestions.item.score != null">{{ dataQuestions.item.score }}</p>
					                <p v-else>N/A</p>
					            </template>
					            <template #cell(answered)="dataQuestions">
					            	<p v-if="dataQuestions.item.answered != null"><font-awesome-icon class="completed-icon-yes" icon="check" /></p>
	          						<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
					            </template>
					        </b-table>
					    </template>
					</b-table>
				</div>
			</template>
			<template v-else>
				<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No recorded video interviews found.</h3>
			</template>
		</div>
	</div>
	<div class="row" v-else>
		<div class="col-sm-12">
			<h4 style="margin-top: 2%; text-align: left;border-bottom: 5px solid;padding-bottom: 10px; margin-bottom: 35px; font-size: 1.5rem">User Recorded Video Interview Results</h4>

			<template v-if="userVideoconferences.length > 0">
				<div v-if="dataLoading" class="text-center text-loading">
			      <b-spinner class="align-middle analysis-spinner"></b-spinner>
			      <strong class="text-loading-msg"> Loading recorded video interviews...</strong>
			    </div>
				<div v-else>
				  	<b-table ref="userVideoconferencesTable"
						id="userVideoconferences"
				      	:per-page="perPage"
				      	:current-page="currentPage"
						:fields="headers" 
						:items="userVideoconferences"
						:filter="filter" 
					    responsive="sm">
						<template #cell(name)="data">
					        <p v-if="data.item.name != ''">{{ data.item.name }}</p>
					        <p v-else>N/A</p>
				      	</template>
						<template #cell(questions)="data">
				          	<p>{{ data.item.result.questions.length }}</p>
				      	</template>
				      	<template #cell(status)="data">
				          	<p v-if="data.item.result.status == 'pending'" class="pending-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else-if="data.item.result.status == 'completed'" class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else-if="data.item.result.status == 'archived'" class="archived-status">DISCARDED</p>
			                <p v-else-if="data.item.result.status == 'finalist'" class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
			                <p v-else class="active-status">{{ data.item.result.status.toUpperCase() }}</p>
				      	</template>
						<template #cell(completedAt)="data">
					        <p>{{ data.item.createdAt | formatEnglishDate }}</p>
				      	</template>
						<template #cell(options)="data">
							<button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show test results" class="btn btn-secondary" @click="data.toggleDetails">
								<font-awesome-icon icon="eye" />
							</button>
							<button v-else v-b-tooltip.hover title="Hide test results" class="btn btn-secondary" @click="data.toggleDetails">
								<font-awesome-icon icon="eye-slash" />
							</button>
				      	</template>
				      	<template #row-details="data">
				      		<div class="row">
				      			<p class="align-left mt-2 mb-2"><b>Description:</b> {{data.item.description}}</p>
				      		</div>
				      		<b-table ref="candidatesQuestionsTable"
						        id="candidatesQuestionsList"
						        :fields="candidatesQuestionsTableHeaders" 
						        :items="data.item.result.questions">
						        <template #cell(title)="dataQuestions">
					                <p class="align-left">{{ dataQuestions.item.title }}</p>
					            </template>
						        <template #cell(description)="dataQuestions">
					                <p v-if="dataQuestions.item.description != null && dataQuestions.item.description.length > 0">{{ dataQuestions.item.description }}</p>
					                <p v-else>No description</p>
					            </template>
					            <template #cell(score)="dataQuestions">
					                <p v-if="dataQuestions.item.score != null">{{ dataQuestions.item.score }}</p>
					                <p v-else>N/A</p>
					            </template>
					            <template #cell(answered)="dataQuestions">
					            	<p v-if="dataQuestions.item.answered != null"><font-awesome-icon class="completed-icon-yes" icon="check" /></p>
	          						<p v-else><font-awesome-icon class="completed-icon-no" icon="times" /></p>
					            </template>
					        </b-table>
					    </template>
					</b-table>
				</div>
			</template>
			<template v-else>
				<h3 class="no-results-profile-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No recorded video interviews found.</h3>
			</template>
		</div>
	</div>
</template>

<script>
	import InterviewsDataService from '@/services/InterviewsDataService';
	import GraphQLDataService from '@/services/GraphQLDataService';

	export default {
	    name: "UserVideoconferencesResults",
	    data() {
		  	return {
		        perPage: 10,
		    	currentPage: 1,
		    	pageOptions: this.$paginationOptions,
		    	clickable: true,
		    	filter: null,
		    	dataLoading: false,
		    	colorDiff: {},
		    	userVideoconferences: [],
		  	}
		},
		computed: {
			rows() {
				return this.userVideoconferences.length
			},
			headers(){
				return [
					{ key: 'name', sortable: true, label: "Title" }, 
					{ key: 'questions', label: "Questions" },
					{ key: 'status', label: 'Candidate status' }, 
					{ key: 'completedAt', sortable: true, label: "Completed at" },
					{ key: 'options', label: 'Options' }
				]
			},
			candidatesQuestionsTableHeaders() {
				return [
					{ key: 'title', sortable: true, label: "Question title" }, 
					{ key: 'description', label: 'Description' }, 
					{ key: 'score', sortable: true, label: "Score" },
					{ key: 'answered', label: "Question answered" },
				]
			}
        },
	    mounted() {
	    	this.dataLoading = true;

	    	if (this.userID != null)
	    		this.getUserVideoconferences();
	    },
	    props: {
			userID: Number,
			userEmail: String,
			pdf: {
				type: Boolean,
				default: false
			}
	    },
	    methods: {
		  	changeShowState(index) {
				if(document.getElementById('iconTabShow-' + index.toString()).style.display == "block") {
					document.getElementById('iconTabShow-' + index.toString()).style.display = "none"
					document.getElementById('iconTabNotShow-' + index.toString()).style.display = "block"
				} else {
					document.getElementById('iconTabShow-' + index.toString()).style.display = "block"
					document.getElementById('iconTabNotShow-' + index.toString()).style.display = "none"
				}
			},
			getUserVideoconferences() {
				InterviewsDataService.findUserInvitedInterviews(this.userID).then(
		            response => {
		            	if (response.status == 200) {
		            		let userInterviewsTmp = response.data;

							if (userInterviewsTmp.length > 0)
								this.getUserOpenInterviews(userInterviewsTmp);
		            	} else {
		            		this.dataLoading = false;
							this.message = response.data.message;
						}
		            },
		            error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.dataLoading = false;
						this.message = (error.response && error.response.data) ||
							error.message ||
							error.toString();
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
		            }
		        );
			},
			async getUserOpenInterviews(meetings) {
	      		await GraphQLDataService.getInterviewsFromUser(this.$apollo).then(
					response => {
						if (response.networkStatus == 7 || response.status == 200){
							let interviews = response.data.positions;
							
							this.userVideoconferences = interviews.filter(interview => 
								interview.interviews.length > 0
							).filter(interview => 
								interview.interviews.some(candidate => 
									candidate.candidate.email == this.userEmail && candidate.completed != null
								)
							)
							
							this.userVideoconferences.forEach((meeting) => {
								let id = 13;
								meeting.id_interview = meeting.id;
								meeting.id = id;
								meeting.result = meeting.interviews.filter(candidate => candidate.candidate.email == this.userEmail)[0];
							})

							this.userVideoconferences.forEach((meeting) => {
								meeting.createdAt = new Date(meeting.createdAt);
								meeting.createdAt = meeting.createdAt.toString();
							})
							this.dataLoading = false;
						} else {
							this.dataLoading = false;
							this.message = "An error ocurred while retrieving interviews from user.";
							this.successfull = false;
						}
					},
					error => {
						this.dataLoading = false;
						this.message = error.response.data.message;
						this.successfull = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
	      	}
	    }
	};
</script>