import { render, staticRenderFns } from "./EditQuestions.vue?vue&type=template&id=4ef7219d&scoped=true&"
import script from "./EditQuestions.vue?vue&type=script&lang=js&"
export * from "./EditQuestions.vue?vue&type=script&lang=js&"
import style0 from "./EditQuestions.vue?vue&type=style&index=0&id=4ef7219d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef7219d",
  null
  
)

export default component.exports