<template>
    <div class="col-sm-12">
        <div class="row">
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Description</h4>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="text-align: justify">
                        <p v-if="description !== null && description !== '' && description !== undefined">
                            {{ description }}</p>
                        <p v-else>Sin descripción</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Evaluators</h4>
                </div>
                <div class="row" v-if="evaluators.length > 0">
                    <div class="col-sm-12">
                        <div class="row" style="margin-top: 1%;">
                            <div class="col-sm-2">
                                <b-pagination v-model="currentPageEvaluators" :total-rows="rowsEvaluators"
                                    :per-page="perPageEvaluators" aria-controls="evaluatorsList"></b-pagination>
                            </div>
                            <div class="col-sm-7">
                                <b-form-select class="numPerPage paginationSelectorTable" v-model="perPageEvaluators"
                                    :options="pageOptionsEvaluators"></b-form-select>
                            </div>
                            <div class="col-sm-3">
                                <b-form-input id="filter-input" v-model="filterEvaluators" type="search"
                                    :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                            </div>
                        </div>
                        <b-table ref="evaluatorsTable" id="evaluatorsList" :per-page="perPageEvaluators"
                            :current-page="currentPageEvaluators" :filter="filterEvaluators" :items="evaluators"
                            :fields="headersEvaluators" :tbody-tr-class="rowClassEvaluators" select-mode="multi"
                            responsive="sm">
                            <template #cell(avatar)="data">
                                <img v-if="data.item.evaluator.avatar == null" :src="userImage" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else-if="data.item.evaluator.avatar.includes($awsBucketRoute)"
                                    v-bind:src="data.item.evaluator.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else v-bind:src="$awsBucketRoute + data.item.evaluator.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                            </template>
                            <template #cell(name)="data">
                                {{ data.item.evaluator.name }}
                            </template>
                            <template #cell(surname)="data">
                                {{ data.item.evaluator.surname }}
                            </template>
                            <template #cell(email)="data">
                                {{ data.item.evaluator.email }}
                            </template>
                            <template #cell(role)="data">
                                {{ data.item.role.charAt(0).toUpperCase() + data.item.role.slice(1) }}
                            </template>
                            <template #cell(weight)="data">
                                {{ data.item.weight }}
                            </template>
                            <template #cell(total_evaluated_users)="data">
                                {{ data.item.total_evaluated_users }}
                            </template>
                            <template #cell(status)="data">
                                <font-awesome-icon v-if="data.item.total_evaluated_users == evaluated.length"
                                    icon="check" style="color: green !important" v-b-tooltip.hover
                                    title="The evaluation has been completed for this evaluator." />
                                <font-awesome-icon v-else icon="times-circle" style="color: red !important"
                                    v-b-tooltip.hover
                                    title="The evaluation has not been completed for this evaluator." />
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row" v-else>
                    <h6>Bei/360 Evaluation without evaluators.</h6>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Evaluated</h4>
                </div>
                <div class="row" v-if="evaluated.length > 0">
                    <div class="col-sm-12">
                        <div class="row" style="margin-top: 1%;">
                            <div class="col-sm-2">
                                <b-pagination v-model="currentPageEvaluated" :total-rows="rowsEvaluated"
                                    :per-page="perPageEvaluated" aria-controls="evaluatedList"></b-pagination>
                            </div>
                            <div class="col-sm-7">
                                <b-form-select class="numPerPage paginationSelectorTable" v-model="perPageEvaluated"
                                    :options="pageOptionsEvaluated"></b-form-select>
                            </div>
                            <div class="col-sm-3">
                                <b-form-input id="filter-input" v-model="filterEvaluated" type="search"
                                    :placeholder="$t('others.search-placeholder-msg')"></b-form-input>
                            </div>
                        </div>
                        <b-table ref="evaluatedTable" id="evaluatedList" :per-page="perPageEvaluated"
                            :current-page="currentPageEvaluated" :items="evaluated" :fields="headersEvaluated"
                            :filter="filterEvaluated" :tbody-tr-class="rowClassEvaluated" select-mode="multi"
                            responsive="sm">
                            <template #cell(avatar)="data">
                                <img v-if="data.item.user.avatar == null" :src="userImage" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else-if="data.item.user.avatar.includes($awsBucketRoute)"
                                    v-bind:src="data.item.user.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                                <img v-else v-bind:src="$awsBucketRoute + data.item.user.avatar" height="30em"
                                    class="analysis-card-avatar--circle" />
                            </template>
                            <template #cell(name)="data">
                                {{ data.item.user.name }}
                            </template>
                            <template #cell(surname)="data">
                                {{ data.item.user.surname }}
                            </template>
                            <template #cell(email)="data">
                                {{ data.item.user.email }}
                            </template>
                            <template #cell(role)="data">
                                {{ data.item.user.role.name.charAt(0).toUpperCase() + data.item.user.role.name.slice(1)
                                }}
                            </template>
                            <template #cell(score)="data">
                                <div v-if="data.item.average_result != null">
                                    {{ data.item.average_result }}
                                </div>
                                <div v-else>
                                    0
                                </div>
                            </template>
                            <template #cell(realizations)="data">
                                {{ data.item.realizations }}
                            </template>
                            <template #cell(status)="data">
                                <font-awesome-icon v-if="data.item.done" icon="check" style="color: green !important"
                                    v-b-tooltip.hover title="The evaluation is complete for this user." />
                                <font-awesome-icon v-else icon="times-circle" style="color: red !important"
                                    v-b-tooltip.hover title="The evaluation is not complete for this user." />
                            </template>
                            <template #cell(options)="data">
                                <router-link v-b-tooltip.hover title="Show user evaluation summary"
                                    :to="{ path: '/softskills-evaluation-result-user?id_evaluation=' + $route.query.id + '&id_user=' + data.item.user.id + '&id_position_selection=-1' }"
                                    class="btn btn-secondary">
                                    <font-awesome-icon icon="eye" />
                                </router-link>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row" v-else>
                    <h6>Bei/360 Evaluation without users.</h6>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mb-3 h4-div-class">
                    <h4 class="h4-class">Skills Map</h4>
                </div>
                <div class="row">
                    <div class="container-fluid">
                        <div class="row" v-for="(group, index) in competencesToEvaluate" :key="group.id">
                            <div class="col-12 padding-0">
                                <div class="row">
                                    <div class="col-1">
                                        <p class="title-groups margin-top-10">Group</p>
                                    </div>
                                    <div class="col-10">
                                        <div class="row">
                                            <div class="col-9">
                                                <p class="float-left margin-top-1-5">{{ group.name }}</p>
                                            </div>
                                            <div class="col-3">
                                                <p class="p-weight-evaluation-group-results">Weight:
                                                    &nbsp;&nbsp;&nbsp;{{ group.weight }}%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <button class="btn btn-secondary margin-top-10" type="button"
                                            v-b-toggle="'toggleG-' + index.toString()"
                                            @click="changeShowGroupState(index)">
                                            <font-awesome-icon style="display: none;"
                                                v-bind:id="'iconShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Expand" icon="chevron-down" />
                                            <font-awesome-icon style="display: block;"
                                                v-bind:id="'iconNotShowG-' + index.toString()" class="trash-icon"
                                                v-b-tooltip.hover title="Contract" icon="chevron-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <b-collapse visible v-bind:id="'toggleG-' + index.toString()">
                                <div class="row">
                                    <div class="col-10 col-centered">
                                        <div class="row"
                                            v-for="(competence, index_competence) in group.selectedCompetences"
                                            :key="group.id + '-' + competence.id">
                                            <div class="col-12 padding-0">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <p class="title-groups margin-top-5">Competence</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <div class="row">
                                                            <div class="col-9">
                                                                <p class="float-left margin-top-1-8">{{ competence.name
                                                                    }}
                                                                </p>
                                                            </div>
                                                            <div class="col-3">
                                                                <p class="p-weight-evaluation-competence-results">
                                                                    Weight:
                                                                    &nbsp;&nbsp;&nbsp;{{ competence.weight }}%</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <button class="btn btn-secondary margin-top-15" type="button"
                                                            v-b-toggle="'toggleC-' + index.toString() + '-' + index_competence.toString()"
                                                            @click="changeShowCompetenceFromGroupState(index, index_competence)">
                                                            <font-awesome-icon style="display: none;"
                                                                v-bind:id="'iconShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Expand"
                                                                icon="chevron-down" />
                                                            <font-awesome-icon style="display: block;"
                                                                v-bind:id="'iconNotShowC-' + index.toString() + '-' + index_competence.toString()"
                                                                class="trash-icon" v-b-tooltip.hover title="Contract"
                                                                icon="chevron-up" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <b-collapse visible
                                                v-bind:id="'toggleC-' + index.toString() + '-' + index_competence.toString()">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row align-items-center">
                                                            <p class="title-min-level">Levels availables</p>
                                                        </div>
                                                        <div class="row">
                                                            <b-form-group
                                                                :name="'competence-' + index.toString() + '-' + index_competence.toString()"
                                                                class="form-group-margin">
                                                                <b-list-group>
                                                                    <b-list-group-item v-for="(level, index_level) in competence.levels" :key="level.id" v-bind:class="competence.min_level - 1 == index_level + 1 ? 'radio-button-class active' : 'radio-button-class'">
                                                                        <p
                                                                            style="text-align: left !important;">Level
                                                                            {{ index_level + 1 }}:&nbsp;&nbsp;&nbsp;{{ level.name }}
                                                                        </p>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-collapse>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EvaluationResultsAdmin from "@/components/SoftSkillsComponent/EvaluationResultsAdmin.vue";
import SoftskillsDataService from "@/services/SoftskillsDataService";

export default {
    components: {
        EvaluationResultsAdmin
    },
    props: {
        evaluated: Array,
        evaluators: Array,
        groupsWeights: Array,
        title: String,
        description: String,
    },
    data() {
        return {
            headersEvaluated: [
                { key: 'avatar', label: 'Avatar', tdClass: 'tdSmall' },
                { key: 'name', label: 'Name', tdClass: 'tdSmall' },
                { key: 'surname', label: 'Surname', tdClass: 'tdMedium' },
                { key: 'email', label: 'Email', tdClass: 'tdMedium' },
                { key: 'role', label: 'Role', tdClass: 'tdSmall' },
                { key: 'realizations', label: 'Evaluations Performed', tdClass: 'tdSmall' },
                { key: 'score', label: 'Score', tdClass: 'tdSmall' },
                { key: 'status', label: 'Status', tdClass: 'tdSmall' },
                { key: 'options', label: 'Options', tdClass: 'tdSmall' },
            ],
            perPageEvaluated: 10,
            currentPageEvaluated: 1,
            filterEvaluated: null,
            pageOptionsEvaluated: this.$paginationOptions,
            headersEvaluators: [
                { key: 'avatar', label: 'Avatar', tdClass: 'tdSmall' },
                { key: 'name', label: 'Name', tdClass: 'tdSmall' },
                { key: 'surname', label: 'Surname', tdClass: 'tdMedium' },
                { key: 'email', label: 'Email', tdClass: 'tdMedium' },
                { key: 'role', label: 'Role', tdClass: 'tdSmall' },
                { key: 'weight', label: 'Weight', sortable: true, tdClass: 'tdSmall' },
                { key: 'total_evaluated_users', label: 'Users evaluated', tdClass: 'tdSmall' },
                { key: 'status', label: 'Status', tdClass: 'tdSmall' },
            ],
            perPageEvaluators: 10,
            currentPageEvaluators: 1,
            filterEvaluators: null,
            pageOptionsEvaluators: this.$paginationOptions,
            userImage: "http://" + location.host + '/images/icons/user.png',
            skillsMap: [],
            competencesByGroups: [],
            competencesToEvaluate: [],
        }
    },
    computed: {
        rowsEvaluated() {
            return this.evaluated.length
        },
        rowsEvaluators() {
            return this.evaluators.length
        },
    },
    methods: {
        rowClassEvaluated(item, type) {
            if (!item || type !== 'row') return
            if (item.done) return 'validated-question'
        },
        rowClassEvaluators(item, type) {
            if (!item || type !== 'row') return
            if (item.total_evaluated_users == this.evaluated.length) return 'validated-question'
        },
        changeShowGroupState(index) {
            if (document.getElementById('iconShowG-' + index.toString()).style.display == "block") {
                document.getElementById('iconShowG-' + index.toString()).style.display = "none"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowG-' + index.toString()).style.display = "block"
                document.getElementById('iconNotShowG-' + index.toString()).style.display = "none"
            }
        },
        changeShowCompetenceFromGroupState(index, index_competence) {
            if (document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display == "block") {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
            } else {
                document.getElementById('iconShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "block"
                document.getElementById('iconNotShowC-' + index.toString() + '-' + index_competence.toString()).style.display = "none"
            }
        },
    },
    mounted() {
        SoftskillsDataService.findCompanyCompetencesDirectory(this.$store.state.auth.user.id_company).then(
            response => {
                this.skillsMap = response.data

                if (this.skillsMap.length > 0) {
                    this.competencesByGroups = this.skillsMap

                    if (this.skillsMap[0].type === "group") {
                        this.groupsWeights.forEach(weight => {
                            this.competencesByGroups.forEach(group => {
                                if (group.id === weight.id_soft_skill) {
                                    this.competencesToEvaluate.push(group)
                                }
                            })
                        })

                        this.competencesToEvaluate.forEach(group => {
                            group.selectedCompetences = []
                        })

                        this.groupsWeights.forEach(weight => {
                            this.competencesToEvaluate.forEach(group => {
                                if (group.id === weight.id_soft_skill) {
                                    group.weight = weight.weight
                                }

                                group.competences.forEach(competence => {
                                    if (competence.id === weight.id_soft_skill) {
                                        competence.weight = weight.weight
                                        competence.min_level = weight.min_level
                                        competence.levels.shift()
                                        console.log('competence: ', competence)
                                        group.selectedCompetences.push(competence)
                                    }
                                })
                            })
                        })
                    }
                }

                console.log('competences: ', this.competencesToEvaluate)
            },
            error => {
                this.dataLoading = false
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    }
};
</script>

<style>
.list-group-item.active {
    background-color: #62c4e4 !important;
    border-color: #62c4e4 !important;
}
</style>