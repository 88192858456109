import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'tag/';

class TagDataService {

  getTagBoard() {
    return http.get(API_URL_PATH + 'tagBoard', { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  create(tag) {
    const newTag = {name: tag.name, id_area: tag.id_area }
    return http.post(API_URL_PATH + 'create', { tag: newTag }, { headers: authHeader() });
  }

  update(tag) {
    const updatedTag = {name: tag.name}
    return http.put(API_URL_PATH + 'update', { id_tag:tag.id, tag: updatedTag }, { headers: authHeader() });
  }

}

export default new TagDataService();