<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<button @click="$router.go(-1)" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to questions</button>
			</div>
			<div v-if="isAdmin" class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/questions' }" class="breadcumb"> Questions > </router-link>
				<router-link :to="{ path: '/udpate-question' }" class="final-breadcumb"> Update Question </router-link>
			</div>
			<div v-else class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/questions' }" class="breadcumb"> Questions > </router-link>
				<router-link :to="{ path: '/udpate-question' }" class="final-breadcumb"> Update Question </router-link>
			</div>
		</div>
		
		<div class="col-sm-12 col-centered">	
			<update-question :testFlag= "testFlag" :questionIndex="questionIndex" :id_test="id_test"/>
		</div>
	</div>
</template>

<script>
  	import UpdateQuestion from "@/components/QuestionComponents/Update.vue";

	export default {
	  	name: 'UpdateQuestionView',
	  	data () {
			return {
				question: {}
			}
	  	},
	  	components: {
	      	UpdateQuestion
	    },
	    computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			}
	    },
	    methods: {
	    },
		props: {
		    testFlag: Boolean,
		    questionIndex: Number,
		    id_test: Number
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>