<template>
	<div>
		<div class="row" v-for="(config, index) in configParams" v-bind:key="config.title">
			<div class="row">
				<div class="col-sm-1">
					<font-awesome-icon :icon="config.icon" />
					<p class="titulo_op">{{config.title}}</p>
				</div>
				<div class="col-sm-auto">
					<div class="form-check form-switch dichotomic-switch config-video-switch">
				       	<input class="form-check-input checkbox-required" type="checkbox" v-model="config.isSet" checked>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  	name: 'NotificationsConfig',
	  	data() {
	      return {
	        configParams: [
	        	{key: "email_notification_activated", icon: "envelope", title: "Via email", isSet: true},
	        	{key: "sms_notification_activated", icon: "sms", title: "Via SMS", isSet: false},
	        ],
	      }
	    },
	    props: {
	    	areaConfig: Object
	    },
	    mounted() {
	    	this.configParams[0].isSet = this.areaConfig.email_notification_activated;
	    	this.configParams[1].isSet = this.areaConfig.sms_notification_activated;

	    	this.$forceUpdate();
	    }
	};
</script>