<template>
	<div class="form-container technologiesStyle ">
		<form action class="form" @submit.prevent="handleCreate">
			<div id="message" v-if="message" class="pt-4 col-md-12 align-item-center alert"
				:class="successfull ? 'alert-success' : 'alert-danger'">
				{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>
			<div v-if="availableQuestions != null" class="mt-4 col-md-12 align-item-center"
				v-bind:class="{ 'alert alert-danger': !flagAvailableQuestions, 'alert alert-info': flagAvailableQuestions }">
				<b>Available questions: {{ availableQuestions }}</b>
			</div>

			<div class="row form-group align-item-center">
				<div class="row">
					<div class="col-sm-6">
						<input v-if="ableToCreateTest" class="btn btn-primary" type="submit" value="Create">
						<input v-else class="btn btn-primary" type="submit" value="Create" disabled>
					</div>
					<div class="col-sm-6">
						<button class="btn btn-secondary btn-cancel" @click="backToTests()"><font-awesome-icon
								icon="ban" /> Cancel</button>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<h4>General Settings <b-button class="btn btn-info" v-b-tooltip.hover
						title="You must specify the name and definition of the new test"><font-awesome-icon
							icon="info-circle" /></b-button></h4>
				<div class="row">
					<div class="row form-group">
						<label>Title*</label>
						<input class="form-input" type="text" id="title" v-validate="'required'" data-vv-name="title"
							v-model="test.title">
						<div v-if="submitted && veeErrors.has('title')" class="alert alert-danger">
							{{ veeErrors.first('title') }}
						</div>
					</div>
					<div class="row form-group">
						<label>Description</label>
						<textarea class="form-input col-sm-12" id="description" rows="6" data-vv-name="description"
							v-model="test.description" maxlength="256"></textarea>
						<div v-if="submitted && veeErrors.has('description')" class="alert alert-danger">
							{{ veeErrors.first('description') }}
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<h4>Filters* <b-button class="btn btn-info" v-b-tooltip.hover
						title="In the filters section you must specify the tags of the new test"><font-awesome-icon
							icon="info-circle" /></b-button></h4>
				<multiselect :options="tagsToShow" :multiple="true" :close-on-select="false" :preserve-search="true"
					:taggable="true" :preselect-first="false" placeholder="Search or add a tag" v-model="selectedTags"
					label="label" track-by="value" @select="selectOption" @remove="removeOption"></multiselect>
			</div>

			<div class="row mt-3">
				<h4>Timing <b-button class="btn btn-info" v-b-tooltip.hover
						title="In the timing section you must specify the dates the new test"><font-awesome-icon
							icon="info-circle" /></b-button></h4>
				<div class="row">
					<div class="col-sm-4">
						<div class="row form-group">
							<label>Start date*</label>
							<input class="form-input" type="date" data-vv-name="start_date" v-model="test.start_date">
						</div>
					</div>
					<div class="col-sm-4">
						<div class="row form-group">
							<label>End date*</label>
							<input class="form-input" type="date" data-vv-name="end_date" v-model="test.end_date">
						</div>
					</div>
					<div class="col-sm-4">
						<div class="row form-group">
							<label>Time limit (minutes)</label>
							<input class="form-input" type="number" data-vv-name="time_limit" v-model="test.time_limit">
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<h4>Other configurations <b-button class="btn btn-info" v-b-tooltip.hover
						title="In the other configurations section you must specify additional parameters of the new test"><font-awesome-icon
							icon="info-circle" /></b-button></h4>
				<div class="row">
					<div class="col-sm-6">
						<div class="row">
							<div class="row form-group">
								<label>Level of difficulty*</label>
								<select class="form-select" v-validate="'required'" v-model="test.id_difficulty_level"
									data-vv-name="difficulty" id="difficulty">
									<option v-for="item in difficulties" v-bind:key="item.id" v-bind:value="item.id">
										{{ item.name.toUpperCase() }}
									</option>
								</select>
								<div v-if="submitted && veeErrors.has('difficulty')" class="alert alert-danger">
									{{ veeErrors.first('difficulty') }}
								</div>
							</div>
						</div>
						<div class="row">
							<div class="row form-group">
								<label>Number of questions</label>
								<input class="form-input right-short-input" v-validate="'numeric|max_value:' + availableQuestions" type="number" id="number_questions" min="1"
									data-vv-name="number_questions" v-model="test.number_questions">
									<div v-if="submitted && veeErrors.has('number_questions')" class="alert alert-danger">
										The number of questions must be equal or less than available questions
									</div>
							</div>
						</div>
						<div class="row">
							<div class="row form-group">
								<label>Questions level of difficulty*</label>
								<br>
								<label><input class="form-input" type="radio" name="difficulty_configuration" value="1"
										v-model="test.questions_same_difficulty" style="margin-top: 11px;"> 
										Same level of difficulty for all questions 
										<b-button class="btn btn-info" v-b-tooltip.hover
											title="All questions of the exam will be of the selected difficulty level">
											<font-awesome-icon icon="info-circle" />
										</b-button>
								</label>
								<br>
								<label><input class="form-input" type="radio" name="difficulty_configuration" value="0"
										v-model="test.questions_same_difficulty" style="margin-top: 11px;"> 
										Average difficulty for questions
										<b-button class="btn btn-info" v-b-tooltip.hover
											title="Questions of the exam will be of one upper level and one lower level of the selected difficulty level">
											<font-awesome-icon icon="info-circle" />
										</b-button>
								</label>
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="row">
							<div class="row form-group">
								<label>Language*</label>
								<select class="form-select" v-validate="'required'" v-model="test.id_language"
									data-vv-name="language" id="language">
									<option v-for="item in languages" v-bind:key="item.id" v-bind:value="item.id">
										{{ item.name.toUpperCase() }}
									</option>
								</select>
								<div v-if="submitted && veeErrors.has('language')" class="alert alert-danger">
									{{ veeErrors.first('language') }}
								</div>
							</div>
						</div>

						<div class="row">
							<div class="row form-group">
								<label>Wrong answer penalty (%)*</label>
								<input class="form-input right-short-input" type="number" step="any"
									data-vv-name="incorrect_value" v-model="test.incorrect_value">
							</div>
						</div>

						<div class="row">
							<div class="row form-group">
								<label>Maximum attempts</label>
								<input class="form-input right-short-input" type="number" min="1"
									data-vv-name="number_attempts" v-model="test.number_attempts">
							</div>
						</div>

						<div class="row">
							<div class="row form-group">
								<label><input class="form-input right-short-input" type="checkbox"
										data-vv-name="shuffle" v-model="test.shuffle">Shuffle questions</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<b-modal id="modal" ref="modal" title="Modal" hide-footer>
			<template #modal-title>
				Error!
			</template>
			<div>
				<p>There are no available questions with this parameters. Please, change test configuration </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="$bvModal.hide('modal')" value="Ok">
		</b-modal>

		<b-modal id="modal-tags" ref="modal-tags" title="Modal tags" hide-footer>
			<template #modal-title>
				Error!
			</template>
			<div>
				<p>There are no available questions for all the tags. Please, change tags configuration </p>
			</div>
			<input class="btn btn-primary form-submit" type="button" @click="$bvModal.hide('modal-tags')" value="Ok">
		</b-modal>
	</div>
</template>

<script>
import TestDataService from "@/services/TestDataService";
import QuestionDataService from "@/services/QuestionDataService";
import Test from '@/models/test';
import $ from 'jquery'
import Multiselect from 'vue-multiselect'

export default {
	name: "TestListComponent",
	data() {
		return {
			title: "Create Test",
			test: new Test('', '', '', '0', '', '', '1',
				'', '', '', '', '', '', '',
				'', '', ''),
			message: "",
			successfull: false,
			submitted: false,
			difficulties: [],
			languages: [],
			selectedTags: [],
			tags: [],
			tagsToShow: [],
			tagsToAdd: [],
			availableQuestions: null,
			flagAvailableQuestions: true,
			ableToCreateTest: true
		}
	},
	components: {
		Multiselect
	},
	methods: {
		getTestBoard() {
			const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
			TestDataService.getTestBoard(currentAreaID).then(
				response => {
					if (response.status == 200) {
						this.difficulties = response.data.difficulty_levels
						this.languages = response.data.languages

						let tempTags = response.data.tags;
						for (var i = 0; i < tempTags.length; i++) {
							this.tags.push(tempTags[i])
							this.tagsToShow.push({ label: tempTags[i].name, value: tempTags[i].name + '-' + tempTags[i].id, id: tempTags[i].id })
						}
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		findQuestions() {
			let bodyParams = {}

			if (this.tagsToAdd.length != 0) {
				bodyParams.tags = this.tagsToAdd.map(function (tag) { return tag.id })
			}
			else {
				this.flagAvailableQuestions = false
				this.availableQuestions = null
				return
			}

			let selectedDifficulties = []
			if (this.test.id_difficulty_level != undefined && this.test.id_difficulty_level != '') {
				if (this.test.questions_same_difficulty == '1') {
					this.test.questions_average_difficulty = 0;
					selectedDifficulties.push(this.test.id_difficulty_level)
				} else {
					var levelSelected;
					this.test.questions_average_difficulty = 1;
					for (var i = 0; i < this.difficulties.length; i++) {
						if (this.difficulties[i].id == this.test.id_difficulty_level) {
							levelSelected = this.difficulties[i].level;
							break;
						}
					}
					for (var i = 0; i < this.difficulties.length; i++) {
						if (this.difficulties[i].level == levelSelected || this.difficulties[i].level == levelSelected - 1 || this.difficulties[i].level == levelSelected + 1) {
							selectedDifficulties.push(this.difficulties[i].id)
						}
					}
				}
				bodyParams.difficulty_levels = selectedDifficulties
			}

			if (this.test.id_language != undefined && this.test.id_language != '') {
				bodyParams.language = this.test.id_language
			}

			this.getAvailableQuestions(bodyParams);
		},
		getAvailableQuestions(bodyParams) {
			this.ableToCreateTest = true
			const currentTabID = this.$session.get("tabID");
			bodyParams.id_area = this.$store.state.auth.user.active_areas[currentTabID];

			QuestionDataService.availableQuestions(bodyParams).then(
				response => {
					if (response.status == 200) {
						this.availableQuestions = response.data.number_questions
						if (this.availableQuestions == 0) {
							this.availableQuestions = null
							this.flagAvailableQuestions = false;
							this.showAlert();
						} else if (!response.data.tags_flag) {
							this.ableToCreateTest = false
							this.showAlertTags();
						} else {
							this.flagAvailableQuestions = true
						}
					} else {
						this.message = "An error ocurred while filtering questions"
					}
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		selectOption(option) {
			var object = {}

			var parsedOption = JSON.parse(JSON.stringify(option))

			object.label = parsedOption.label
			object.value = parsedOption.value
			var valueSplit = parsedOption.value.split('-')
			object.id = parseInt(valueSplit[1])
			this.tagsToAdd.push(object)

			this.findQuestions()
		},
		removeOption(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))

			var valueSplit = parsedOption.value.split('-')

			const index = this.tagsToAdd.map(object => object.id).indexOf(parseInt(valueSplit[1]));
			this.tagsToAdd.splice(index, 1)

			this.findQuestions()
		},
		backToTests() {
			this.$router.push({ path: 'tests' })
		},
		handleCreate() {
			console.log('handle create')
			this.submitted = true;
			if (this.test.shuffle == "")
				this.test.shuffle = 0;

			console.log('antes de validator')
			this.$validator.validate().then(isValid => {
				if (!isValid) {
					this.message = "Please, fill all the fields correctlly";
					return;
				}

				if (this.test.start_date == "" && this.test.end_date == "") {
					this.message = "Please, set the date range in which the test is available";
					return;
				}

				if (new Date(this.test.end_date) < new Date(this.test.start_date)) {
					this.message = "Please, set the date range correctly. End date is before start date";
					return;
				}

				if (this.test.incorrect_value == "") {
					this.message = "Please, set the value of an incorrect question punishment"
					return;
				}

				if (this.test.questions_average_difficulty == "" && this.test.questions_same_difficulty == "") {
					this.message = "Please, select the questions difficulty configuration"
					return;
				}

				if (this.tagsToAdd.length == 0) {
					this.message = "Select at least one tag for test's categorization";
					return;
				}

				const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
				TestDataService.create(this.test, this.tagsToAdd, currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.successful = true;
							this.$router.push({ path: 'test-add-questions', query: { id: response.data.id_test } })
						} else {
							this.message = response.data.message
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						this.message = error.response.data.message

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			});
		},
		showAlert() {
			this.$refs['modal'].show();
		},
		showAlertTags() {
			this.$refs['modal-tags'].show();
		},
		close() {
			document.getElementById("message").style.display = "none"
		}
	},
	mounted() {
		this.getTestBoard();

		$('select').on('change', () => {
			this.findQuestions();
		});

		$('input[type=radio]').on('change', () => {
			this.findQuestions();
		});

	},
};
</script>
