<template>
	<div>
      <h4 class="reports-title">Horizontal barr diagram with correct/wrong answers distribution by question</h4>
      <div class="row" v-for="test in data">
          <div class="col-md-2">
             <h3 class="graph-title">Question {{test.id}} <b-button class="btn btn-info" v-b-tooltip.hover v-bind:title="test.question"><font-awesome-icon icon="info-circle" /></b-button></h3>
          </div>
          <div class="col-md-10">
              <horizontal-stacked-barr 
                  :ref="chartId + test.id"
                  :key="graphLoaded"
                  :chartId="chartId + test.id" 
                  :xAxesCategory="xAxesCategory" 
                  :yAxesValue="yAxesValue" 
                  :xAxesTitle="xAxesTitle"
                  :yAxesTitle="yAxesTitle"
                  :isDichotomic="true"
                  :data="test.data"></horizontal-stacked-barr>
          </div>
			</div>
	</div>
</template>

<script>
  import HorizontalStackedBarr from "@/components/Others/Charts/HorizontalStackedBarr";
  import ReportsDataService from '@/services/ReportsDataService';

  export default {
    name: 'TestResultsGraphByHitAndMiss',
    data() {
    	return {
    		chartId: "chartTestsGraphByHitAndMiss",
	    	graphLoaded: false,
        data: [],
	    	xAxesCategory: "perc",
	    	yAxesValue: "answer",
	    	xAxesTitle: "Answers",
	    	yAxesTitle: "Percentage"
    	}
    },
    components: {
    	HorizontalStackedBarr
    },
    methods: {
      async fetchData() {
        await ReportsDataService.testQuestionsResults(this.$route.query.id).then(
          response => {
            response.data.forEach((d) => {
              let tmp = {}
              tmp.id = d.id_question
              tmp.question = d.question.question_title

              tmp.data = []
              tmp.data[0] = {}
              tmp.data[0].perc = d.average_correct_ocurrences.toFixed(2)
              tmp.data[0].answer = "Correct"
              tmp.data[0].userAnswer = d.is_correct === true ? true : false
              tmp.data[1] = {}
              tmp.data[1].perc = d.average_wrong_ocurrences.toFixed(2)
              tmp.data[1].answer = "Wrong"
              tmp.data[1].userAnswer = d.is_correct === false ? true : false
              this.data.push(tmp)
            })

            this.graphLoaded = this.graphLoaded ? false : true
          },
          error => {
            console.log((error.response && error.response.data) ||
              error.message ||
              error.toString())
            
            if (error.response.status === 300) {
              console.log('Logout: Token expired')
              localStorage.removeItem('user');
              localStorage.removeItem('company');
              this.$store.state.auth.user = null
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
      }
    },
    created() {
      this.fetchData();
    }
  };
</script>

<style>
  .graph-title {
  	margin-top: 32%;
    margin-left: 30%;
  }
</style>