<template>
    <div class="form-container my-2">
        <div class="col-sm-12">
            <div class="row">
                <div id="message" v-if="message"
                    :class="successfull ? 'message alert alert-success' : 'message alert alert-danger'">
                    {{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
                            icon="times" /> </b-button>
                </div>
            </div>

            <div class="row col-md-12 styleFilters" v-if="filters.length === 0">
                <div class="col-md-12 left-filters-row">
                    <h3 class="filters-text auto-width">Filters:</h3>
                    <p class="info-text auto-width">Without filters</p>
                    <button class="btn btn-secondary float-right" @click="addFilter()" v-b-tooltip.hover
                        title="Add new filter row"><font-awesome-icon icon="plus" /> &nbsp;Add filter</button>
                </div>
            </div>

            <div class="row col-md-12 styleFilters" v-else>
                <div class="col-md-12 left-filters-row">
                    <h3 class="filters-text auto-width">Filters:</h3>
                    <button class="btn btn-secondary float-right" @click="addFilter()" v-b-tooltip.hover
                        title="Add new filter row"><font-awesome-icon icon="plus" /> &nbsp;Add filter</button>
                </div>
            </div>

            <div class="row col-md-10 styleFilters mx-auto">
                <component v-for="(filter, index) in filters" :ref="'filter_' + index"
                    @filterRemoveIndex="onFilterRemove" v-on:childMessage="showMessageFromComponent" :key="index"
                    :is="filter" :filterIndex="index" :numFilters="numFilters" />
            </div>

            <div class="container col-md-8 styleFilters" v-if="filters.length > 1">
                <strong class="radio-button-title">Options:</strong>
                <div class="form-check">
                    <input class="form-check-input radio-button-input" type="radio" name="exampleRadios"
                        id="exampleRadios1" value="and" v-model="isOR" style="width: 16px !important;" checked>
                    <label class=" form-check-label radio-button-label" for="exampleRadios1">
                        All filters required
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input radio-button-input" type="radio" name="exampleRadios"
                        id="exampleRadios2" value="or" v-model="isOR" style="width: 16px !important;">
                    <label class="form-check-label radio-button-label" for="exampleRadios2">
                        Only one filter required
                    </label>
                </div>
            </div>

            <div class="row col-md-4 buttons-div mt-3" v-if="filters.length > 0">
                <button class="btn btn-primary col-md-6 button-style" @click="handleSearch()" v-b-tooltip.hover
                    title="Search profile"><font-awesome-icon icon="search" class="white-color" /> &nbsp;Search</button>
                <button class="btn btn-primary col-md-6 button-style" @click="deleteAllFilters()" v-b-tooltip.hover
                    title="Delete profile analysis"><font-awesome-icon icon="ban" class="white-color" /> &nbsp;Clear
                    All</button>
            </div>

            <div class="col-md-12 results-filter-search-title">
                <h3 class="results-text">Results found: {{ results.length }}</h3>
            </div>

            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                :paginate-elements-by-height="1400" filename="professional_profile_search_report" :pdf-quality="2"
                :manual-pagination="false" pdf-format="a4" pdf-content-width="800px" ref="html2Pdf">
                <pdf-professional-profile-search slot="pdf-content" />
            </vue-html2pdf>
            <div class="row w-100 card-container">
                <div class="cards-ranking cards2 col-md-6">
                    <div class="row">
                        <div v-for="item in results" :key="item.id" class="card candidate">
                            <div class="row full-width m-2">
                                <div class="col-md-3">
                                    <img class="profile-img" v-bind:src="item.userImage"
                                        @error="imageLoadError($event, item)" />
                                    <button class="btn btn-secondary btn-info" @click="onResultSelected(item.id)">More
                                        info
                                    </button>
                                </div>
                                <div class="col-md-9">
                                    <div class="row title-row">
                                        <div class="col-12">
                                            <h5 class="card-title">{{ item.name }}</h5>
                                            <p style="font-size: 10px !important" v-if="item.email">({{ item.email }})
                                            </p>
                                            <p style="font-size: 10px !important" v-else>(email not available)</p>
                                        </div>
                                    </div>
                                    <div class="row top-skill-row">
                                        <p class="card-text-top-skill">
                                            <template v-if="logos[item.top_skill.toLowerCase()] !== null">
                                                <b>Top Profile Skill:&nbsp;&nbsp;</b> <img v-b-tooltip.hover
                                                    :title="item.top_skill"
                                                    v-bind:src="logos[item.top_skill.toLowerCase()]" height="30em"
                                                    class="analysis-card-avatar--circle" />
                                                &nbsp;&nbsp;{{ capitalizarPrimeraLetra(item.top_skill_level) }}
                                            </template>
                                            <b v-else>Top Profile Skill:&nbsp;&nbsp;</b>
                                            &nbsp;&nbsp;{{ capitalizarPrimeraLetra(item.top_skill_level) }}
                                        </p>
                                        <b-progress>
                                            <b-progress-bar class="removePadding" :value="item.top_perc"
                                                variant="success" key="success"
                                                :style="'background:' + item.color_top + '!important' + ';color: black;'"
                                                show-progress>
                                                <span>{{ item.top_skill }}: {{ item.top_perc.toFixed(2) }} %</span>
                                            </b-progress-bar>
                                        </b-progress>
                                    </div>

                                    <div class="row other-skills-row">
                                        <p class="card-text-other-skills"><b>Other Top Profile Skills:</b></p>
                                        <div class="row other-skills-div" v-if="item.top_skills_sorted.length > 0">
                                            <template v-for="(item) in item.top_skills_sorted">
                                                <img v-if="logos[item.toLowerCase()] !== null"
                                                    v-bind:src="logos[item.toLowerCase()]" v-bind:key="item"
                                                    v-b-tooltip.hover
                                                    :title="capitalizarPrimeraLetra(item) + ' ' + capitalizarPrimeraLetra(nameLevels[item.toLowerCase()])"
                                                    height="30em" class="analysis-card-avatar--circle col-3" />
                                                <p v-else v-bind:src="logos[item.toLowerCase()]" v-bind:key="item"> {{
                    capitalizarPrimeraLetra(item) + ' ' +
                    capitalizarPrimeraLetra(nameLevels[item.toLowerCase()]) }} </p>
                                            </template>
                                        </div>
                                        <div class="row other-skills-div" v-else>
                                            <p style="text-align: left;"> - </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Filter from '@/components/Others/Filter';
import FilterObj from '@/models/filter';
import ProfileAnalysisDataService from '@/services/ProfileAnalysisDataService';
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
import StarRating from 'vue-star-rating'
import UserDataService from '../../services/UserDataService';
import VueHtml2pdf from 'vue-html2pdf';
import PdfProfessionalProfileSearch from './pdfProfessionalProfileSearch.vue';

export default {
    name: 'SearchFilterAnalysisComponent',
    data() {
        return {
            filters: [],
            filterStructure: new FilterObj(this.$store.state.auth.user.active_areas[this.$session.get("tabID")], 'AND', ''),
            successfull: false,
            results: [],
            profileLevels: "",
            isOR: 'and',
            paginate: ["paginatedResults"],
            pageSize: 5,
            message: '',
            logos: {},
            nameLevels: {},
            colorPerfil: "#5ABBD1",
            currentUrl: location.host,
            technologies: null,
            numFilters: 0,
            filterStructureEmpty: { id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")], operator: 'AND', filters: [] }
        }
    },
    watch: {
        filters: function () {
            if (this.filters.length === 0) {
                this.findWithFilter(this.filterStructureEmpty)
            }
            if (this.filters.length <= 1) {
                this.isOR = 'and'
            }
        }
    },
    components: {
        StarRating,
        VueHtml2pdf,
        PdfProfessionalProfileSearch,
    },
    mounted() {
        const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
        ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
            response => {
                if (response.status == 200) {
                    this.technologies = response.data
                    console.log('this.technologies: ', this.technologies)

                    this.technologies.forEach((tech) => {
                        if (tech.logo !== null && tech.logo !== undefined) this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
                        else this.logos[tech.name.toLowerCase()] = null
                    });

                    this.findWithFilter(this.filterStructureEmpty)
                }
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );

        ProfileConfigurationDataService.findByArea(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
            response => {
                if (response.data.length <= 0 || response.data == undefined || response.data == null)
                    this.profileLevels = [];
                else {
                    this.profileLevels = response.data;
                }
            },
            error => {
                console.log((error.response && error.response.data) ||
                    error.message ||
                    error.toString())
                this.$router.push('/modules');

                if (error.response.status === 300) {
                    console.log('Logout: Token expired')
                    localStorage.removeItem('user');
                    localStorage.removeItem('company');
                    this.$store.state.auth.user = null
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            }
        );
    },
    methods: {
        capitalizarPrimeraLetra(str) {
            if (str != undefined)
                return str.charAt(0).toUpperCase() + str.slice(1)
            else
                return str
        },
        findWithFilter(filter) {
            ProfileAnalysisDataService.filterCVs(filter).then(
                response => {
                    this.results = []
                    if (response.status == 200) {
                        let resultsTmp = response.data
                        resultsTmp.forEach(profile => {
                            let profileTmp = profile
                            if (!profile.image.includes('amazon')) profileTmp.userImage = profile.image;
                            else if (profile.user !== null) profileTmp.userImage = profileTmp.userImage = profile.user.avatar;
                            else profileTmp.userImage = "http://" + this.currentUrl + '/images/icons/user.png';

                            if (profile.user !== null) profileTmp.name = profile.user.name + ' ' + profile.user.surname

                            let lastEvaluation = {}
                            let maxScore = 0

                            let progressTmp = profileTmp.progress

                            if (progressTmp !== null) {
                                progressTmp = progressTmp.replaceAll("'", "\"");
                                progressTmp = JSON.parse(progressTmp)

                                progressTmp = Object.fromEntries(Object.entries(progressTmp).sort((a, b) => a[1] - b[1]).reverse())
                                let sortedProgressTmp = Object.fromEntries(Object.entries(progressTmp).slice(0, 5));

                                progressTmp = sortedProgressTmp

                                // Primero, encuentra el valor más alto
                                const maxValue = Math.max(...Object.values(progressTmp));

                                // Luego, filtra todas las claves que tienen el valor máximo
                                const maxKeys = Object.keys(progressTmp).filter(key => progressTmp[key] === maxValue);
                                let topSkillTmp = ""

                                if (maxKeys.length > 1 && filter.filters.length > 0) {
                                    if (filter.filters.some(item => item.field === "type")) {
                                        topSkillTmp = filter.filters.filter(item => item.field === "type")[0].keyFields[0].key
                                    } else {
                                        topSkillTmp = maxKeys[0];
                                    }
                                } else {
                                    topSkillTmp = Object.keys(progressTmp).reduce((a, b) => progressTmp[a] > progressTmp[b] ? a : b); // Get key with highest value;
                                }

                                if (topSkillTmp.charAt(0).match(/[a-z]/i)) // check if first character is a letter
                                    profileTmp.top_skill = topSkillTmp.charAt(0).toUpperCase() + topSkillTmp.slice(1)
                                else
                                    profileTmp.top_skill = topSkillTmp.charAt(0) + topSkillTmp.charAt(1).toUpperCase() + topSkillTmp.slice(2)

                                profileTmp.top_perc = progressTmp[topSkillTmp]
                                profileTmp.color_top = this.getColor(profileTmp.top_perc)
                                var sortedTopSkills = []

                                let typeTmp = profileTmp.type
                                typeTmp = typeTmp.replaceAll("'", "\"");
                                typeTmp = JSON.parse(typeTmp)
                                this.nameLevels = typeTmp

                                let progressKeys = Object.keys(progressTmp);
                                for (let i = 1; i < progressKeys.length; i++) {
                                    sortedTopSkills.push(progressKeys[i])
                                }

                                profileTmp.top_skills_sorted = sortedTopSkills

                                let topSkillLevelTmp = typeTmp[profileTmp.top_skill.toLowerCase()] // Get key with highest value

                                profileTmp.top_skill_level = topSkillLevelTmp.toUpperCase()

                                this.results.push(profileTmp)
                            }
                        })

                        this.successfull = true
                        if (resultsTmp.length > 0) {
                            this.message = ""
                        }
                    } else {
                        this.message = response.data.message
                    }
                },
                error => {
                    this.results = []
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    this.message = error.response.data.message

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getColor(min_range) {
            if (this.profileLevels.length != 0) {
                if (this.profileLevels[0].color != undefined) {
                    let color = this.profileLevels[0].color
                    for (let i = 0; i < this.profileLevels.length; i++) {
                        if (this.profileLevels[i].min_range < min_range)
                            color = this.profileLevels[i].color
                        else break
                    }
                    return color
                }
            }
        },
        addFilter() {
            this.filters.push(Filter)
            this.numFilters = this.filters.length
        },
        onFilterRemove(index) {

            let tmpArray = []

            this.filters.splice(index, 1, null)

            this.numFilters = this.filters.length
        },
        onResultSelected(id) {
            let routeData = this.$router.resolve({ name: 'profile-analysis', query: { id: id, previousPage: 'filter' } });
            window.open(routeData.href, '_blank');
        },
        showMessageFromComponent(msg) {
            this.message = msg
        },
        deleteAllFilters() {
            this.filters = []
            this.numFilters = 0
        },
        handleSearch() {
            let filter = {}
            let data = {}
            let ref = ""

            if (this.filters.length == 0) {
                this.findWithFilter(this.filterStructureEmpty)
                return
            }

            let filters = []
            for (var i = 0; i < this.filters.length; i++) {
                if (this.filters[i] !== null) {
                    filter = {}

                    ref = 'filter_' + i
                    data = this.$refs[ref][0]

                    if (data.fieldName != undefined && data.fieldName != null) {
                        filter.field = data.fieldName
                    } else {
                        this.message = 'No field selected'
                        return
                    }

                    filter.fieldType = data.fieldType

                    let keyFields = []
                    if ('object' == filter.fieldType) {
                        data.objValues.forEach(item => {
                            let keyF = {}

                            if (item.key != undefined && item.key != null) {
                                if ('skills' == data.fieldName || 'progress' == data.fieldName) {
                                    if (item.key.split(" ").length > 0)
                                        keyF.key = item.key.split(" ").join("_").toLowerCase().replace("in_", "")
                                    else
                                        keyF.key = item.key.toLowerCase().replace("in_", "").replace("in_", "")
                                } else {
                                    keyF.key = item.key
                                }
                            } else {
                                this.message = 'No key selected'
                                return
                            }

                            if (item.value != undefined && item.value != null) {
                                keyF.value = item.value
                            } else {
                                this.message = 'No value selected for given key'
                                return
                            }

                            if (item.operation != undefined && item.operation != null) {
                                keyF.operation = item.operation
                            } else {
                                this.message = 'No operation selected for given key'
                                return
                            }

                            keyF.is_numeric = data.isNumeric == false ? 0 : 1

                            keyFields.push(keyF)
                        })

                        filter.keyFields = keyFields
                    } else if ('array' == filter.fieldType) {
                        if (data.isListOfVariable) {
                            data.listValues.forEach(function (item) {
                                let keyF = {}
                                keyF.key = []
                                keyF.level = 0

                                if (item.value != undefined && item.value != null) {
                                    keyF.value = item.value
                                } else {
                                    this.message = 'No value selected for given key'
                                    return
                                }

                                if (item.operation != undefined && item.operation != null) {
                                    keyF.operation = item.operation
                                } else {
                                    this.message = 'No operation selected for given key'
                                    return
                                }

                                keyF.is_numeric = data.isNumeric == false ? 0 : 1

                                keyFields.push(keyF)
                            })
                        } else {
                            data.listObjValues.forEach(function (item) {
                                let keyF = {}
                                keyF.level = 1

                                if (item.key != undefined && item.key != null) {
                                    keyF.key = item.key
                                } else {
                                    this.message = 'No key selected'
                                    return
                                }

                                if (item.value != undefined && item.value != null) {
                                    keyF.value = item.value
                                } else {
                                    this.message = 'No value selected for given key'
                                    return
                                }

                                if (item.operation != undefined && item.operation != null) {
                                    keyF.operation = item.operation
                                } else {
                                    this.message = 'No operation selected for given key'
                                    return
                                }

                                keyF.is_numeric = data.isNumeric == false ? 0 : 1

                                keyFields.push(keyF)
                            })
                        }

                        if ('experience_jobs' == data.fieldName) {
                            if (data.fromDateExpTime != new Date().getFullYear() || data.toDateExpTime != new Date().getFullYear()) {
                                filter.fromDateExpTime = parseInt(data.fromDateExpTime);
                                filter.toDateExpTime = parseInt(data.toDateExpTime);
                            }

                            let obj = data.listObjValues.find(e => e.key === 'description');
                            if (obj != undefined && obj != null) {
                                filter.description = obj.value
                            }
                        }

                        filter.keyFields = keyFields
                    } else if ('variable' == filter.fieldType) {
                        if (data.operation != undefined && data.operation != null) {
                            filter.operation = data.operation
                        } else {
                            this.message = 'No operation selected'
                            return
                        }

                        if (data.value != undefined && data.value != null) {
                            filter.value = data.value //value
                        } else {
                            this.message = 'No value given for filter'
                            return
                        }

                        filter.is_numeric = data.isNumeric == false ? 0 : 1
                    }

                    filters.push(filter)
                }
            }

            this.filterStructure.filters = filters

            if (this.isOR === "or") {
                this.filterStructure.operator = "OR"
            } else {
                this.filterStructure.operator = "AND"
            }

            this.findWithFilter(this.filterStructure)
        },
        imageLoadError(event, item) {
            if (item.id_user != null && item.id_user != undefined) {
                UserDataService.findOne(item.id_user).then(
                    response => {
                        if (response.data.avatar != undefined && response.data.avatar != null)
                            event.target.src = this.$awsBucketRoute + response.data.avatar;
                        else
                            item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
                    },
                    error => {
                        item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';
                        console.log((error.response && error.response.data) ||
                            error.message ||
                            error.toString())

                        if (error.response.status === 300) {
                            console.log('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    })
            } else
                item.userImage = "http://" + this.currentUrl + '/images/icons/user.png';

            event.target.src = "http://" + this.currentUrl + '/images/icons/user.png';
        },
        close() {
            this.message = ""
            document.getElementById("message").style.display = "none"
        },
        downloadPdf() {
            this.$refs.html2Pdf.generatePdf()
        }
    }
};
</script>

<style type="text/css" scoped>
.item-body {
    width: 100%;
}

.result-list-item {
    transition: all 1s;
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0.75rem !important;
}

.result-list-enter,
.result-list-leave-to {
    max-height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: hidden;
}

.result-list-enter-to,
.result-list-leave {
    max-height: 80px;
}

.result-table-item {
    transition: all 1s;
}

.result-table-item>* {
    transition: all 1s;
    overflow: hidden;
}

.result-table-enter,
.result-table-leave-to {
    line-height: 0;
}

.result-table-enter>*,
.result-table-leave-to>* {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.result-table-enter-to,
.result-table-leave {
    line-height: 1.5;
}

.profile-img {
    margin: 8%;
    height: 7em;
    border-radius: 50%;
}

.title-row {
    margin-bottom: 4%;
}

.top-skill-row {
    margin-bottom: 0%;
}

.card-body {
    padding-top: 0;
}

.card-text-email {
    width: auto;
    float: left;
    margin-top: 1px;
}

.card-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.card-text-top-skill,
.card-text-other-skills {
    width: auto;
    float: left;
    margin-bottom: 0% !important;
}

.progress {
    margin-bottom: 5% !important;
    width: 50%;
}

.other-skills-div {
    margin-bottom: 0% !important;
    width: 50%;
}

.candidate {
    margin-bottom: 2%;
    width: 100%;
    margin: 1%;
}

.btn-info {
    width: 100%;
}

.results-text {
    text-align: center;
}

.white-color {
    color: white !important;
}

.buttons-div {
    margin: 0 auto !important;
    margin-top: 1% !important;
}

.button-style {
    width: auto;
    margin: 0 auto;
}

.filters-row {
    margin: 0 auto;
}

.filters-text {
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.info-text {
    text-align: center;
    margin-bottom: 0% !important;
    margin-left: 5% !important;
    margin-top: 5px !important;
    font-size: 20px;
    float: left;
    width: auto;
}

.auto-width {
    width: auto;
}

.progress {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.left-filters-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.radio-button-title {
    float: left;
    width: auto;
    margin-top: 2%;
}

.radio-button-input {
    margin-left: 2% !important;
}

.radio-button-label {
    float: left;
    margin-left: 2px;
}
</style>