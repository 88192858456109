<template>
	<div class="form-container technologiesStyle mt-2">        
		<div class="col-sm-12">
			<div class="form-group">
				<label>Name *</label>
				<input class="form-input" type="text" v-validate="'required'" required v-model="tag.name" data-vv-name="name">
				<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
					{{veeErrors.first('name')}}
				</div>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>
          
		<div class="form-group row justify-content-center">
			<div class="col-sm-1">
				<button class="btn btn-primary form-submit" type="button" @click="handleUpdate()">Update</button>
			</div>
			<div class="col-sm-1">
				<button class="btn btn-secondary btn-cancel" @click="backToTags()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>
	</div>
</template>

<script>
	import TagDataService from "@/services/TagDataService";
	import Tag from '@/models/tag';

	export default {
	    name: "UpdateTagComponent",
	    data() {
		  	return {
		  		title: "Update Tag",
		    	tag: new Tag('','',this.$store.state.auth.user.id_area),
		        message: "",
			  	successfull: false,
			  	submitted: false
	    	}
		},
		mounted() {
			TagDataService.findOne(this.$route.query.id).then(
				response => {
					this.tag = response.data
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = error.response.data.message

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
	  	},
		methods: {
			backToTags() {
				this.$router.push({ name: "tags"});
			},
			handleUpdate() {
	  			this.submitted = true
				this.successful = true

	  			this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					TagDataService.update(this.tag).then(
	  						response => {
	  							if (response.status == 200) {
	  								this.successful = true;
	  								this.$router.push({ name: "tags", params: {tagUpdated: true} });
	  							}
	  						},
	  						error => {
								this.successful = false
								this.message = error.response.data.message

	  							console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())

								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
	  						}
  						);
	  				}
	  			});
	  		},
			close() {
				this.message = ""
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>