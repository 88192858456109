<template>
  <div id="home-header-title" class="col-lg-6 col-md-12">
    <img id="header-title-logo" class="main" src="images/logo_tagline_01.png">
    <div>
      <router-link to="/contact" class="btn btn-primary red-btn">CONTACT US</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "HomeHeader"
  };
</script>
