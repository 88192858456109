<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/difficulties'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Difficulty Levels</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/difficulties' }" class="breadcumb"> Levels of difficulty > </router-link>
        <router-link :to="{ path: '/update-difficulty' }" class="final-breadcumb"> Update Difficulty </router-link>
      </div>
    </div>
	
    <div class="col-sm-12 col-centered">	
  		<update-difficulty/>
  	</div>
  </div>
</template>

<script>
  import UpdateDifficulty from "@/components/DifficultyComponents/Update.vue";

	export default {
    name: 'UpdateDifficultyView',
    components: {
      UpdateDifficulty
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>