<template>
	<div class="col-sm-12">
		<div class="row secondNavbar"> 
			<div class="col-5 links-container">
				<a class="btn btn-secondary float-right" @click="backToConfiguration()"><font-awesome-icon icon="arrow-left" /> Back to Configuration</a>
			</div>
			<div class="col-7 noMargin whiteColor">
				<router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
				<router-link :to="{ path: '/config-profile' }" class="breadcumb"> Configuration of the Levels of Expertise > </router-link>
				<router-link :to="{ path: '/edit-config-profile' }" class="final-breadcumb"> Edit {{profileNameTitle.toUpperCase()}} Required Profile Configuration </router-link>
			</div>
		</div>
		
		<div class="col-sm-12 col-centered px-5">	
			<edit-profile-configuration/>
		</div>
		<b-modal id="cancel-modal" ref="cancel-modal" title="Cancel-modal" hide-footer>
			<template #modal-title>
				¿Do you really want to exit?
			</template>
			<div>
				<p>If you exit now, changes will be lost. </p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button" @click="$bvModal.hide('cancel-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="backToConfiguration()" value="Yes">
		</b-modal>
	</div>
</template>

<script>
	import EditProfileConfiguration from "@/components/AnalysisComponents/EditProfileConfiguration.vue";
	import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";

	export default {
	  	name: 'EditProfileConfigurationView',
	  	components: {
	      	EditProfileConfiguration
	    },
		data() {
			return {
				profile: null,
				profileNameTitle: ""
			}
		},
   		props: {
      	},
		mounted() {
			ProfileConfigurationDataService.getProfile(this.$route.query.idProfile).then(
				response => {
					if (response.status == 200) {
						this.profile = response.data

						this.profileNameTitle = this.profile.name
						for(var i=0; i<this.profile.profile_levels.length; i++) {
							if(this.profile.profile_levels[i].id === parseInt(this.$route.query.idProfileLevel)) {
								this.profileNameTitle = this.profile.profile_levels[i].name
							}
						}
					} else {
						console.error("Error in getProfile")
					}
				})
		},
		methods: {
			backToConfiguration() {
				this.$router.push({ path: 'config-profile'})
			}
		}
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>