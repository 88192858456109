<template>
    <div class="form-container my-3">
        <template v-for="(errorMessage, index) in errorsMessages">
            <div id="message" class="alert alert-danger" :ref="'message-' + index">
                {{errorMessage}} <b-button @click="close(index)" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
            </div>
        </template>

        <div class="row mt-2 justify-content-center">
            <div class="col-6">
                <div class="row mt-2">
                    <div class="col-sm-3">
                    </div>
                    <div class="col-sm-9">
                        <div class="card-image logo-company">
                            <a href="javascript:void(0);">
                              <img v-bind:src="profileLogo" class="card-avatar card-avatar--circle mb-2">
                            </a>
                            <input id="logoImgFileInput2" style="float: left !important;" accept=".jpg" ref="logoImgFileInput2" type="file" @change="onFileChange">
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 pt-0">
                    </div>
                    <div class="col-sm-9 pt-0">
                        <p class="no-margin-bottom text-center"><b-button class="btn btn-info"><font-awesome-icon icon="info-circle" />&nbsp;&nbsp;</b-button>&nbsp;&nbsp;Files must have .jpg extension to be correctly shown.</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row mt-2">
                    <h5>Name of the company<b-button class="btn btn-info" v-b-tooltip.hover title="You must choose a name for your company"><font-awesome-icon icon="info-circle" /></b-button></h5>
                    <input type="text" id="name" class="inputProfileConfig w-50" ref="nameInput" v-model="company.name" placeholder="Enter name"/>
                </div>

                <div class="row mt-2">
                    <div class="col-sm-6">
                        <h5 class="ml-2">Modules to include<b-button class="btn btn-info" v-b-tooltip.hover title="You must choose a the active modules to work with in your company"><font-awesome-icon icon="info-circle" /></b-button></h5>
                        <div class="form-check form-switch ml-2">
                            <input class="form-check-input" type="checkbox" id="cvModule" v-model="company.cv_module_enabled">
                            <label class="form-check-label float-left" for="cvModule">CV Analysis Module</label>
                        </div>
                        <div class="form-check form-switch ml-2">
                            <input class="form-check-input" type="checkbox" id="hardSkillsModule" v-model="company.hard_skills_module_enabled">
                            <label class="form-check-label float-left" for="hardSkillsModule">Hard Skills Module</label>
                        </div>
                        <div class="form-check form-switch ml-2">
                            <input class="form-check-input" type="checkbox" id="evaluation360Module" v-model="company.evaluation_360_module_enabled">
                            <label class="form-check-label float-left" for="evaluation360Module">BEI/360 Evaluation Module</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-2">
                <button class="btn btn-primary form-submit" type="button" @click="handleUpdate()">Update Company</button>
            </div>
            <div class="col-2">
                <button class="btn btn-secondary btn-cancel" type="button" @click="showCancelModal()"><font-awesome-icon icon="ban" /> Cancel</button>
            </div>
        </div>
	</div>
</template>

<script>
    import CompanyDataService from "@/services/CompanyDataService";

    export default {
        name: 'CreateCompanyComponent',
        data() {
            return {
                company: {},
                name: "",
                cvModule: false,
                hardSkillsModule: false,
                evaluation360Module: false,
                errorsMessages: [],
                profileLogo: "",
                files: [],
                successfull: null
            }
        },
        mounted() {
            this.getCompanyInfo();
            this.getLogo();
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        methods: {
            onFileChange (e) {
                this.files = e.target.files || e.dataTransfer.files
                if (!this.files.length) {
                    return
                }

                if (this.files !== null) this.createImage(this.files[0]);
            },
            createImage (file) {
                var reader = new FileReader()
                var vm = this

                reader.onload = (e) => {
                    vm.profileLogo = e.target.result
                }
                reader.readAsDataURL(file)
            },
            getCompanyInfo() {
                CompanyDataService.findOne(this.$route.query.id).then(
                    response => {
                        if (200 == response.status) {
                            this.company = response.data
                        } else {
                            this.errorsMessages.push("Error while getting the information of the company")
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        this.errorsMessages.push("There was an error while getting the information of the company from the database") 

                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },  
            getLogo() {
                CompanyDataService.getLogo(this.$route.query.id).then(
                    response => {
                        if (200 == response.status) {
                            this.profileLogo = response.data.logo == null ? '//ssl.gstatic.com/accounts/ui/avatar_2x.png' : (response.data.logo.includes(this.$awsBucketRoute) ? response.data.logo : this.$awsBucketRoute + response.data.logo)  ;
                        } else {
                            this.errorsMessages.push("Error while getting the logo of the company")
                        }
                    },
                    error => {
                        console.error((error.response && error.response.data) ||
                            error.message ||
                            error.toString())
                        
                        if (error.response.status === 300) {
                            console.error('Logout: Token expired')
                            localStorage.removeItem('user');
                            localStorage.removeItem('company');
                            this.$store.state.auth.user = null
                            this.$store.dispatch('auth/logout');
                            this.$router.push('/login');
                        }
                    }
                );
            },
            uploadLogoCompany () {
                if (this.files !== null && this.files !== undefined && this.files.length > 0) {
                    CompanyDataService.uploadLogo(this.files[0], this.$route.query.id, this.currentUser.accessToken).then(
                        response => {
                            if (response.status == 200) {
                                this.$router.push({ name: "companies", params: {companyUpdated: true} });
                            } else {
                                this.errorsMessages.push("There was an error while updating company logo");
                            }
                        },
                        error => {
                            console.error((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            this.errorsMessages.push("There was an error while updating company logo");

                            if (error.response.status === 300) {
                                console.error('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    this.$router.push({ name: "companies", params: {companyUpdated: true} });
                }
            },
            showCancelModal() {
                this.backToCompanies()
            },
            backToCompanies() {
                this.$router.push({ path: 'companies'})
            },
            async handleUpdate() {
                this.successfull = true
                var errors = []

                if(this.company.name.toString().trim().length == 0) {
                    errors.push('The name of the company is empty. Please, fill the name')     
                    this.successfull = false
                } else if (!this.company.cv_module_enabled && !this.company.hard_skills_module_enabled && !this.company.evaluation_360_module_enabled) {
                    errors.push('Please, select at least one module')     
                    this.successfull = false
                } else {
                    await CompanyDataService.findAll().then(
                        response => {
                            if (200 == response.status) {
                                let companies = response.data

                                companies.forEach(company => {
                                    if(company.name.toLowerCase() === this.name.toString().toLowerCase() && this.company.name.toLowerCase() !== company.name.toLowerCase()) {
                                        errors.push('This company is already created. Please, change the name of the company')     
                                        this.successfull = false
                                    } 
                                })
                            } else {
                                errors.push("Error while checking if company is repeated")
                            }
                        },
                        error => {
                            console.error((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            errors.push("There was an error while checking if the company given is repeated") 

                            if (error.response.status === 300) {
                                console.error('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                }

                if(this.successfull) {
                    CompanyDataService.update(this.company).then(
                        response => {
                            if (response.status == 200) {
                                let companySettings = {id_company: this.company.id}

                                CompanyDataService.updateAccountSettings(companySettings).then(
                                    response => {
                                        if (200 == response.status) {
                                            this.successful = true;
                                            this.uploadLogoCompany()
                                        } else {
                                        this.errorsMessages.push("Error while updating company settings")
                                        }
                                    },
                                    error => {
                                        console.error((error.response && error.response.data) ||
                                            error.message ||
                                            error.toString())
                                        this.errorsMessages.push("There was an error while updating the company settings") 
                                        
                                        if (error.response.status === 300) {
                                            console.error('Logout: Token expired')
                                            localStorage.removeItem('user');
                                            localStorage.removeItem('company');
                                            this.$store.state.auth.user = null
                                            this.$store.dispatch('auth/logout');
                                            this.$router.push('/login');
                                        }
                                    }
                                );
                            } else {
                                this.errorsMessages.push("Error while updating company information")
                            }
                        },
                        error => {
                            console.error((error.response && error.response.data) ||
                                error.message ||
                                error.toString())
                            this.errorsMessages.push("There was an error while updating the company information") 
                            
                            if (error.response.status === 300) {
                                console.error('Logout: Token expired')
                                localStorage.removeItem('user');
                                localStorage.removeItem('company');
                                this.$store.state.auth.user = null
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/login');
                            }
                        }
                    );
                } else {
                    this.errorsMessages = errors
                }
            },
            close(index) {
                const messageType = "message-" + index;
                this.$refs[messageType][0].classList.add("noDisplay");
                this.errorsMessages.splice(index, 1);
            }
        }
    };
</script>

<style scoped>
.slider {
    background-color: white !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px white !important;
    width: 90% !important;
    padding: 20px 10px 30px 10px !important;
}

.range-row {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.subtitle-top {
    margin-top: 0px;
}

.subtitle {
    margin-top: 25px;
}

.weightSlider {
    width: 100% !important;
}

.skillDiv {
    width: 12% !important;
}

.skillH6 {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.sliderDiv {
    width: 65% !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.valueDiv {
    width: 5% !important;
}

.valueP {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.finalHardSkills {
    margin-bottom: 1rem !important;
}

.finalLastYears {
    margin-bottom: 1rem !important;
}

.lastYearsInput {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 60px;
}

.inputProfileConfig {
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    width: 95%;
    margin-bottom: 15px;
    margin-left: 13px;
}

.divItems {
    text-align: left;
    margin-left: 30px;
    margin-right: 100px;
}

.alert-edit {
    padding: 5px !important;
    width: 100% !important;
    margin-left: 30px;
}

.noDisplay {
    display: none !important;
}
</style>