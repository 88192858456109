<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div  class="links-container">
        <router-link :to="{path: '/talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} menu</router-link>
        <reports-downloader :graphContent="graphContent" :documentTitle="documentTitle"/>
      </div>
      <div class="whiteColor">Questions reports</div>
    </div>

    <question-graph-by-technology ref="chart1"/>
    <question-graph-by-language ref="chart2"/>
    <question-graph-by-level ref="chart3"/>
    <question-graph-by-language-technology ref="chart4"/>
    <validated-questions-graph-by-company ref="chart5"/>
    <tests-results-by-company-difficulty-level ref="chart6"/>
  </div>
</template>

<script>
  import QuestionGraphByTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByTechnology.vue";
  import QuestionGraphByLanguage from "@/components/ReportsComponents/Questions/QuestionGraphByLanguage.vue";
  import QuestionGraphByLevel from "@/components/ReportsComponents/Questions/QuestionGraphByLevel.vue";
  import QuestionGraphByLanguageTechnology from "@/components/ReportsComponents/Questions/QuestionGraphByLanguageTechnology.vue";
  import ValidatedQuestionsGraphByCompany from "@/components/ReportsComponents/Questions/ValidatedQuestionsGraphByCompany.vue";
  import TestsResultsByCompanyDifficultyLevel from "@/components/ReportsComponents/Questions/TestsResultsByCompanyDifficultyLevel.vue";
  
  export default {
    name: 'FeatureReportsView',
    components: {
      QuestionGraphByTechnology,
      QuestionGraphByLanguage,
      QuestionGraphByLevel,
      QuestionGraphByLanguageTechnology,
      ValidatedQuestionsGraphByCompany,
      TestsResultsByCompanyDifficultyLevel
    },
    data() {
      return {
        graphContent: [
          {title: "Questions by technology"},
          {title: "Questions by language"},
          {title: "Questions by level"},
          {title: "Questions by language and technology"},
          {title: "Validated questions by company"},
          {title: "Results by company difficulty level"},
        ],
        documentTitle: "question_reports.pdf"
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>