<template>
  <div class="">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/companies'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Companies</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/companies' }" class="breadcumb"> Companies > </router-link>
        <router-link :to="{ path: '/create-admin-user' }" class="final-breadcumb"> Create Admin User </router-link>
      </div>
    </div>
	
    <div class="col-sm-12 col-centered">
      <create-admin-user/>
    </div>
  </div>
</template>

<script>
  import CreateAdminUser from "@/components/CompaniesComponents/CreateAdminUser.vue";

	export default {
  	name: 'CreateAdminUserView',
  	components: {
      CreateAdminUser
    },
    computed: {
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>