<template>
    <div v-if="results == undefined || results.length == 0" class="row" style="padding-top: 5% !important">
        <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No results found.</h3>
    </div>
    <div v-else class="row">
        <div class="row col-md-6 results-filter-search-title">
            <h3 class="results-text">Results found: {{ results.length }} people evaluated</h3>
        </div>
        <div class="cards-ranking cards2 col-md-10">
            <div class="row">
                <template v-for="(ranked_candidate, index) in results">
                    <div class="card candidate" v-bind:key="ranked_candidate.id">
                        <div class="row full-width m-2">
                            <div class="col-md-3">
                                <img v-if="ranked_candidate.userImage == null" :src="userImage" class="profile-img" />
                                <img v-else-if="ranked_candidate.userImage.includes($awsBucketRoute)"
                                    v-bind:src="ranked_candidate.userImage" class="profile-img" />
                                <img v-else v-bind:src="$awsBucketRoute + ranked_candidate.userImage"
                                    class="profile-img" />

                                <button class="btn btn-secondary btn-info"
                                    @click="onResultSelected(ranked_candidate.id)">
                                    More info
                                </button>
                            </div>
                            <div class="col-md-9">
                                <div class="row title-row">
                                    <div class="col-sm-9">
                                        <h5 class="card-title">{{ ranked_candidate.name }}</h5>
                                        <p v-if="ranked_candidate.email">({{ ranked_candidate.email }})</p>
                                        <p v-else>(email not available)</p>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="card-text card-text-email position-ranking-text">Posición:
                                            <b>#{{ (index + 1) }}</b></p>
                                    </div>
                                </div>

                                <div class="row top-skill-row">
                                    <div class="col-sm-7">
                                        <p class="card-text-top-skill">
                                            <b>Top Profile Skill:&nbsp;&nbsp;</b>
                                            <img v-if="logos[ranked_candidate.top_profile.toLowerCase()] != null"
                                                v-b-tooltip.hover :title="ranked_candidate.top_profile"
                                                :src="logos[ranked_candidate.top_profile.toLowerCase()]" height="30em"
                                                class="analysis-card-avatar--circle" />
                                            <span v-else>{{ ranked_candidate.top_profile }}</span>
                                            &nbsp;&nbsp; {{
        ranked_candidate.top_profile_expertise_level.charAt(0).toUpperCase() +
        ranked_candidate.top_profile_expertise_level.slice(1) }}
                                        </p>
                                    </div>
                                    <div class="col-sm-5">
                                        <b-progress>
                                            <b-progress-bar class="removePadding ranking-color"
                                                :value="ranked_candidate.top_profile_score" variant="primary"
                                                key="primary" show-progress>
                                                <span
                                                    class="black-color">{{ ranked_candidate.top_profile_expertise_level.charAt(0).toUpperCase()
        + ranked_candidate.top_profile_expertise_level.slice(1) }}: {{
        ranked_candidate.top_profile_score }} %</span>
                                            </b-progress-bar>
                                        </b-progress>
                                    </div>
                                </div>

                                <div class="row other-skills-row">
                                    <p class="card-text-other-skills"><b>Other Top Profile Skills:</b></p>
                                    <div class="row other-skills-div">
                                        <img v-for="(item) in ranked_candidate.other_profiles"
                                            :src="logos[item.profile.toLowerCase()]" v-bind:key="item.profile"
                                            v-b-tooltip.hover :title="item.profile" height="30em"
                                            class="analysis-card-avatar--circle col-3" />
                                    </div>
                                </div>

                                <div class="row top-skill-row">
                                    <div class="col-sm-3">
                                        <p class="card-text-top-skill">
                                            <b>BEI/360 Evaluation:&nbsp;&nbsp;</b>
                                        </p>
                                    </div>
                                    <div class="col-sm-4">
                                        <p class="ranking-title-value"></p>
                                    </div>
                                    <div class="col-sm-5">
                                        <b-progress>
                                            <b-progress-bar class="removePadding ranking-color"
                                                :value="ranked_candidate.ev_360_score" variant="primary" key="primary"
                                                show-progress>
                                                <span class="black-color"> &nbsp;&nbsp; Score:
                                                    {{ranked_candidate.ev_360_score}} %</span>
                                            </b-progress-bar>
                                        </b-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
import SoftskillsDataService from '@/services/SoftskillsDataService';

export default {
    name: 'Ranking',
    data() {
        return {
            results: [],
            logos: {},
            technologies: null,
            userImage: "http://" + location.host + '/images/icons/user.png',
        }
    },
    mounted() {
        this.getLevelsFromCompany()
    },
    methods: {
        getLevelsFromCompany() {
            const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
            ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
                response => {
                    if (response.status == 200) {
                        this.technologies = response.data
                        this.technologies.forEach((tech) => {
                            this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
                        });

                        this.getRankingResults(currentArea)
                    }
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getRankingResults(currentArea) {
            SoftskillsDataService.ranking(currentArea).then(
                response => {
                    this.results = response.data;
                },
                error => {
                    console.error((error.response && error.response.data) ||
                        error.message ||
                        error.toString())

                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
            );
        },
        getNameOfSubmodule(key) {
            if (key == 'P') {
                return "Profile CV Results Score";
            } else if (key == 'T') {
                return "Hard Skills Tests Score";
            } else if (key == 'S') {
                return "Soft Skills Score";
            } else if (key == 'G') {
                return "BEI/360 Evaluation Score";
            }
        },
        onResultSelected(id) {
            let routeData = this.$router.resolve({ name: 'profile-analysis', query: { id: id, previousPage: '360evaluation' } });
            window.open(routeData.href, '_blank');
        },
        close() {
            this.message = ""
            document.getElementById("message").style.display = "none"
        }
    }
};
</script>

<style type="text/css" scoped>
.ranking-color {
    background: #62C4E4 !important;
}

.general-score-progress {
    background: #5ABBD1 !important;
    color: black;
}

.failed-score-progress {
    background: #FF9724 !important;
    color: black;
}

.success-score-progress {
    background: #AFE89E !important;
    color: black;
}

.item-body {
    width: 100%;
}

.result-list-item {
    transition: all 1s;
    max-width: 100%;
    margin-bottom: 0;
    border-radius: 0.75rem !important;
}

.result-list-enter,
.result-list-leave-to {
    max-height: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow: hidden;
}

.result-list-enter-to,
.result-list-leave {
    max-height: 80px;
}

.result-table-item {
    transition: all 1s;
}

.result-table-item>* {
    transition: all 1s;
    overflow: hidden;
}

.result-table-enter,
.result-table-leave-to {
    line-height: 0;
}

.result-table-enter>*,
.result-table-leave-to>* {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.result-table-enter-to,
.result-table-leave {
    line-height: 1.5;
}

.profile-img {
    margin: 8%;
    height: 7em;
    border-radius: 50%;
}

.title-row {
    margin-bottom: 4%;
}

.top-skill-row {
    margin-bottom: 0%;
}

.card-body {
    padding-top: 0;
}

.card-text-email {
    width: auto;
    float: left;
    margin-top: 1px;
}

.card-title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.card-text-top-skill,
.card-text-other-skills {
    width: auto;
    float: left;
    margin-bottom: 0% !important;
}

.progress {
    margin-bottom: 5% !important;
    width: 100%;
}

.other-skills-div {
    margin-bottom: 0% !important;
    width: 50%;
}

.candidate {
    margin-bottom: 2%;
    width: 100%;
    cursor: pointer;
    margin: 1%;
}

.btn-info {
    width: 100%;
}

.results-text {
    text-align: center;
}

.position-ranking-text {
    width: 100%;
    text-align: right;
}

.white-color {
    color: white !important;
}

.buttons-div {
    margin: 0 auto !important;
    margin-top: 1% !important;
}

.button-style {
    width: auto;
    margin: 0 auto;
}

.filters-row {
    margin: 0 auto;
}

.filters-text {
    text-align: center;
    margin-bottom: 0% !important;
    float: left;
    width: auto;
}

.info-text {
    text-align: center;
    margin-bottom: 0% !important;
    margin-left: 5% !important;
    margin-top: 5px !important;
    font-size: 20px;
    float: left;
    width: auto;
}

.auto-width {
    width: auto;
}

.progress {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.left-filters-row {
    display: flex;
    align-items: center;
    justify-content: centers;
}

.radio-button-title {
    float: left;
    width: auto;
    margin-top: 2%;
}

.radio-button-input {
    margin-left: 2% !important;
}

.radio-button-label {
    float: left;
    margin-left: 2px;
}
</style>