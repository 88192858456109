import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'profile/';
const API_URL_PATH_PROFILE = 'profile_level/';

class ProfileConfigurationDataService {

  findLevelsConfigurationByArea(idArea){
    return http.get(API_URL_PATH + 'findLevelsConfigurationByArea?id_area='+idArea, { headers: authHeader() });
  }

  findProfileByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  getProfile(idProfile) {
    return http.get(API_URL_PATH + 'findOne?id='+idProfile, { headers: authHeader() });
  }

  getProfileLevelWeightsByName(profileName) {
    return http.get(API_URL_PATH + 'getProfileLevelWeightsByName?name='+profileName, { headers: authHeader() });
  }

  getProfilesLevelsWeightsByArea(idArea) {
    return http.get(API_URL_PATH + 'getProfilesLevelsWeightsByArea?id_area='+idArea, { headers: authHeader() });
  }

  updateProfileData(profile) {
    delete profile.logo;
    
    return http.put(API_URL_PATH + 'updateProfile', { profile: profile }, { headers: authHeader() });
  }

  updateProfile(profile) {
    return http.put(API_URL_PATH + 'updateProfileLevels', { profile: profile }, { headers: authHeader() });
  }

  updateAvatar(imageFile, imageName,  id_profile, token, onUploadImage) {
    var formData = new FormData();
    formData.append("file", imageFile);

    return http.put(API_URL_PATH + 'updateAvatar', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "id_profile": id_profile,
        'x-access-token': token, 
        'filename': imageName
      },
      onUploadImage
    });
  }

  createProfile(name, idArea) {
    let profile = {name: name, logo: null, id_area: idArea}
    return http.post(API_URL_PATH + 'create', { profile: profile }, { headers: authHeader() });
  }

  profileSkillsByArea(idArea) {
    return http.get(API_URL_PATH + 'profileSkillsByArea?id_area='+idArea, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(API_URL_PATH + 'delete?id='+id, { headers: authHeader() });
  }

  cloneProfileLevel(id_profile, id_profile_level_origin , id_profile_level_destiny ) {
    const body = {id_profile: id_profile, id_profile_level_origin : id_profile_level_origin , id_profile_level_destiny : id_profile_level_destiny  };
    return http.put(API_URL_PATH + 'cloneProfileLevel', body, { headers: authHeader() });
  }

  recycledProfilesByProfile(id_profile,id_area) {
    return http.get(API_URL_PATH + 'recycledProfilesByProfile?id_profile='+id_profile+"&id_area="+id_area, { headers: authHeader() });
  }                                                

  findProfileLevelsByArea(idArea) {
    return http.get(API_URL_PATH + 'findProfileLevelsByArea?id_area=' + idArea , { headers: authHeader() });
  }

  findProfileLevel(id_profile, id_level){
    return http.get(API_URL_PATH + 'findProfileLevel?id_profile='+id_profile+"&id_level="+id_level,{ headers: authHeader() });
  }

  compareProfilesLevels(id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target) {
    return http.get(API_URL_PATH_PROFILE + 'compareProfilesLevels?id_profile_reference=' + id_profile_reference + '&id_profile_level_reference=' +id_profile_level_reference +
    '&id_profile_level_target=' + id_profile_level_target + '&id_profile_target=' +id_profile_target, { headers: authHeader() });
  }

  createProfileLevelsEffortComparison(id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target) {
    return http.post(API_URL_PATH_PROFILE + 'createProfileLevelsEffortComparison', { id_profile_reference: id_profile_reference,
      id_profile_level_reference: id_profile_level_reference, id_profile_level_target: id_profile_level_target,id_profile_target: id_profile_target },
      { headers: authHeader() });
  }
  
  findRecycleProfilesEffort (id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target) {
    return http.get(API_URL_PATH + 'findRecycleProfilesEffort?id_profile_reference=' + id_profile_reference + '&id_profile_level_reference=' +id_profile_level_reference +
    '&id_profile_level_target=' + id_profile_level_target + '&id_profile_target=' +id_profile_target, { headers: authHeader() });
  }

  createRecycleProfilesEffort (id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target, effort) {
    const body = { id_profile_reference: id_profile_reference, id_profile_level_reference: id_profile_level_reference, id_profile_target: id_profile_target,
    id_profile_level_target: id_profile_level_target,  effort: effort };
    return http.post(API_URL_PATH + 'createRecycleProfilesEffort ', body, { headers: authHeader() });
  }
 
  updateRecycleProfilesEffort(id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target, effort) {
    const body = { id_profile_reference: id_profile_reference, id_profile_level_reference: id_profile_level_reference, id_profile_target: id_profile_target,
     id_profile_level_target: id_profile_level_target,  effort: effort };
    return http.put(API_URL_PATH + 'updateRecycleProfilesEffort ', body, { headers: authHeader() });
  }

  deleteRecycleProfilesEffort (id_profile_reference,id_profile_level_reference, id_profile_target, id_profile_level_target) {
    return http.delete(API_URL_PATH + 'deleteRecycleProfilesEffort?id_profile_reference=' + id_profile_reference + '&id_profile_level_reference=' +id_profile_level_reference +
    '&id_profile_level_target=' + id_profile_level_target + '&id_profile_target=' +id_profile_target, { headers: authHeader() });
  }

  updateRecycleProfile(id_profile,id_parent_profile_level,recycled_profiles) {
    return http.put(API_URL_PATH + 'updateRecycleProfile', { id_parent_profile:id_profile,id_parent_profile_level:id_parent_profile_level,recycled_profiles:recycled_profiles}, { headers: authHeader() });
  }

  deleteRecycledProfilesByProfile(id_profile) {
    return http.delete(API_URL_PATH + 'deleteRecycledProfilesByProfile?id_profile='+id_profile, { headers: authHeader() });
  }

  create(level) {
    var profile_level = {name: level.name, level: parseInt(level.level),min_range: parseInt(level.min_range), id_area: level.id_area, color: level.color}
    return http.post(API_URL_PATH_PROFILE + 'create', { profile_level: profile_level }, { headers: authHeader() });
  }

  findOne(idLevel) {
    return http.get(API_URL_PATH_PROFILE + 'findOne?id='+idLevel, { headers: authHeader() });
  }

  update(level) {
    var profile_level = {id: level.id, name: level.name, level: parseInt(level.level), min_range: parseInt(level.min_range), color: level.color}
    return http.put(API_URL_PATH_PROFILE + 'update',{ profile_level: profile_level }, 
    { headers: authHeader() });
  }

  deleteLevel(idLevel) {
    return http.delete(API_URL_PATH_PROFILE + 'delete?id='+idLevel, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH_PROFILE + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  ranking(idProfile) {
    return http.get(API_URL_PATH + 'ranking?id_profile='+idProfile, { headers: authHeader() });
  }

}

export default new ProfileConfigurationDataService();