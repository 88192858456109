<template>
	<div class="form-container technologiesStyle mt-3">
		<div class="row">
			<div class="col-sm-4">
				<div class="form-group">
					<div class="row">
						<label>Company tags categorization *</label>
					</div>
					<div class="row">
						<multiselect
							:options="tagsToShow"
							:multiple="true"
							:close-on-select="false" 
							:preserve-search="true"
							:taggable="true"
							:preselect-first="false"
							placeholder="Search or add a tag"
							v-model="selectedTags"
							label="label"
							track-by="value"
							@select="selectOption"
							@remove="removeOption"></multiselect>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="form-group">
					<div class="row">
						<label>Level of difficulty *</label>
					</div>
					<div class="row">
						<select class="form-select" id="difficulty" v-validate="'required'" v-model="question.id_difficulty_level"  data-vv-name="difficulty">
							<option v-for="item in difficulties" v-bind:key="item.id" v-bind:value="item.id">
								{{ item.name.toUpperCase() }}
							</option>
						</select>
						<div v-if="submitted && veeErrors.has('difficulty')" class="alert alert-danger">
							{{veeErrors.first('difficulty')}}
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="form-group">
					<div class="row">
						<label>Question language *</label>
					</div>
					<div class="row">
						<select class="form-select" id="language" v-validate="'required'" v-model="question.id_language"  data-vv-name="language">
							<option v-for="item in languages" v-bind:key="item.id" v-bind:value="item.id">
								{{ item.name.toUpperCase() }}
							</option>
						</select>
						<div v-if="submitted && veeErrors.has('language')" class="alert alert-danger">
							{{veeErrors.first('language')}}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12">
				<div class="form-group">
					<label>Question title *<b-button class="btn btn-info" v-b-tooltip.hover title="You must write the wording of the question."><font-awesome-icon icon="info-circle" /></b-button></label>

					<input class="form-input" type="text" id="question_title" v-validate="'required'" required v-model="question.question_title" data-vv-name="question_title" style="width: 99% !important;">
					<div v-if="submitted && veeErrors.has('question_title')" class="alert alert-danger">
						{{veeErrors.first('question_title')}}
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="form-group">
					<label>Question content<b-button class="btn btn-info" v-b-tooltip.hover title="You must write complementary content to the cuestion, such as code."><font-awesome-icon icon="info-circle" /></b-button></label>

					<tiptap v-model="question.question_content"/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Answer one *</label>
							<input class="form-input" type="text" id="answer_one" v-validate="'required'" required v-model="question.answer_one" data-vv-name="answer_one">
							<div v-if="submitted && veeErrors.has('answer_one')" class="alert alert-danger">
								{{veeErrors.first('answer_one')}}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1">
						<div class="form-group">
							<label><input class="form-input correct-answer" type="checkbox" id="answer_one_correct" v-model="question.answer_one_correct"> Is correct</label>
						</div>
					</div>
					<div class="col-sm-3 more-correct-answer pt-0">
						<div class="form-group row">
							<div class="col-sm-7">
								<label style="float: right !important;">Set value of answer (%): </label>
							</div>
							<div class="col-sm-5">
								<input class="form-input inline answer_value pt-0" disabled id="answer_one_correct_value" max="100" min="10" type="number" v-model="question.answer_one_value">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Answer two *</label>
							<input class="form-input" type="text" id="answer_two" v-validate="'required'" required v-model="question.answer_two" data-vv-name="answer_two">
							<div v-if="submitted && veeErrors.has('answer_two')" class="alert alert-danger">
								{{veeErrors.first('answer_two')}}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1">
						<div class="form-group">
							<label><input class="form-input correct-answer" type="checkbox" id="answer_two_correct" v-model="question.answer_two_correct"> Is correct</label>
						</div>
					</div>
					<div class="col-sm-3 more-correct-answer pt-0">
						<div class="form-group row">
							<div class="col-sm-7">
								<label style="float: right !important;">Set value of answer (%): </label>
							</div>
							<div class="col-sm-5">
								<input class="form-input inline answer_value pt-0" disabled id="answer_two_correct_value" max="100" min="10" type="number" v-model="question.answer_two_value">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Answer three *</label>
							<input class="form-input" type="text" id="answer_three" v-validate="'required'" required v-model="question.answer_three" data-vv-name="answer_three">
							<div v-if="submitted && veeErrors.has('answer_three')" class="alert alert-danger">
								{{veeErrors.first('answer_three')}}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1">
						<div class="form-group">
							<label><input class="form-input correct-answer" type="checkbox" id="answer_three_correct" v-model="question.answer_three_correct"> Is correct</label>
						</div>
					</div>
					<div class="col-sm-3 more-correct-answer pt-0">
						<div class="form-group row">
							<div class="col-sm-7">
								<label style="float: right !important;">Set value of answer (%): </label>
							</div>
							<div class="col-sm-5">
								<input class="form-input inline answer_value pt-0" disabled id="answer_three_correct_value" max="100" min="10" type="number" v-model="question.answer_three_value">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Answer four *</label>
							<input class="form-input" type="text" id="answer_four" v-validate="'required'" required v-model="question.answer_four" data-vv-name="answer_four">
							<div v-if="submitted && veeErrors.has('answer_four')" class="alert alert-danger">
								{{veeErrors.first('answer_four')}}
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1">
						<div class="form-group">
							<label><input class="form-input correct-answer" type="checkbox" id="answer_four_correct" v-model="question.answer_four_correct"> Is correct</label>
						</div>
					</div>
					<div class="col-sm-3 more-correct-answer pt-0">
						<div class="form-group row">
							<div class="col-sm-7">
								<label style="float: right !important;">Set value of answer (%): </label>
							</div>
							<div class="col-sm-5">
								<input class="form-input inline answer_value pt-0" disabled id="answer_four_correct_value" max="100" min="10" type="number" v-model="question.answer_four_value">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      	</div>
          
		<div class="form-group row justify-content-center">
			<div class="col-sm-1">
				<input class="btn btn-primary form-submit" type="button" @click="handleUpdate" value="Update">
			</div>
			<div class="col-sm-1">
				<button v-if="testFlag" type="button" class="btn btn-secondary btn-cancel" @click="backToTestQuestionsEdit()"><font-awesome-icon icon="ban" /> Cancel</button>
				<button v-else class="btn btn-secondary btn-cancel" type="button" @click="cancelUpdate()"><font-awesome-icon icon="ban" /> Cancel</button>
			</div>
		</div>
	</div>
</template>

<script>
	import QuestionDataService from '@/services/QuestionDataService';
	import Question from '@/models/question';
	import $ from 'jquery'
	import Tiptap from '@/components/Others/Tiptap'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'UpdateQuestionComponent',
		data() {
			return {
				question: new Question('','','',
					'','','','','','','',
					'','','','','','',
					'','','','','','','','',''),
				difficulties: [],
				languages: [],
				submitted: false,
				successful: false,
				message: "",
				selectedTags: [],
				tags: [],
				tagsToShow: [],
				tagsToAdd: [],
				initializedTags: [],
				multiselection: null,
				del: true
			}
		},
		components: {
		    Tiptap,
		    Multiselect
		},
		mounted() {
			$('.correct-answer').click(function() {
				var numberSelectedAnswers =  $(".correct-answer:checked").length;
				if (numberSelectedAnswers > 1) {
					$('.more-correct-answer').css('display', 'block');
					$('.clean-answers-values').css('display', 'block');
					if ($('#answer_one_correct').is(":checked")) {
						$('#answer_one_correct_value').removeAttr('disabled');
					}
					if ($('#answer_two_correct').is(":checked")) {
						$('#answer_two_correct_value').removeAttr('disabled');
					}
					if ($('#answer_three_correct').is(":checked")) {
						$('#answer_three_correct_value').removeAttr('disabled');
					}
					if ($('#answer_four_correct').is(":checked")) {
						$('#answer_four_correct_value').removeAttr('disabled');
					}
				} else {
					$('input[type="radio"]').prop('checked', false);
					$('.more-correct-answer').css('display', 'none');
					$('.clean-answers-values').css('display', 'none');
					if(!$('#answer_one_correct_value').attr('disabled')) {
					   $('#answer_one_correct_value').attr('disabled', 'true');
					}
					if(!$('#answer_two_correct_value').attr('disabled')) {
					   $('#answer_two_correct_value').attr('disabled', 'true');
					}
					if(!$('#answer_three_correct_value').attr('disabled')) {
					   $('#answer_three_correct_value').attr('disabled', 'true');
					}
					if(!$('#answer_four_correct_value').attr('disabled')) {
					   $('#answer_four_correct_value').attr('disabled', 'true');
					}
				}
			});

			$('.clean-answers-values').click(function() {
				$('.answer_value').val('');
			});

		    this.getQuestionBoard();
		    this.getDataFromQuestion();
		    this.getTags();
	  	},
	  	methods: {
	  		checkIfExistsMoreThanOneCorrectAnswer (question) {
				var countCorrectAnswers = 0;
	  			if (question.answer_one_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (question.answer_two_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (question.answer_three_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (question.answer_four_correct) {
	  				countCorrectAnswers += 1;
	  			}
				if (countCorrectAnswers > 1) {
					$('.more-correct-answer').css('display', 'block');
					$('.clean-answers-values').css('display', 'block');
					if (question.answer_one_correct) {
						$('#answer_one_correct_value').removeAttr('disabled');
					}
					if (question.answer_two_correct) {
						$('#answer_two_correct_value').removeAttr('disabled');
					}
					if (question.answer_three_correct) {
						$('#answer_three_correct_value').removeAttr('disabled');
					}
					if (question.answer_four_correct) {
						$('#answer_four_correct_value').removeAttr('disabled');
					}
				}	
			},
	  		getQuestionBoard(){
	  			const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			    QuestionDataService.getQuestionBoard(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.difficulties = response.data.difficulty_levels
							this.languages = response.data.languages

							let tempTags = response.data.tags;
							tempTags.forEach((tag) => {
								this.tags.push(tag)
								this.tagsToShow.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id})
							});
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
	  		},
	  		getDataFromQuestion(){
	  			QuestionDataService.findOne(this.$route.query.id).then(
					response => {
						if (response.status == 200) {
							this.question = response.data
							this.question.id_user_modify = this.$store.state.auth.user.id

							if (!this.question.multiselection) {
								this.multiselection = false
							} else {
								this.multiselection = true
							}

							this.checkIfExistsMoreThanOneCorrectAnswer(this.question);
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
	  		},
	  		getTags(){
	  			QuestionDataService.getTags(this.$route.query.id).then(
					response => {
						if (response.status == 200) {
							let tempTags = response.data;
							tempTags.forEach((tag) => {
								this.tagsToAdd.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id});
								this.selectedTags.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id});
							});
						}
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
	  		},
	  		selectOption(option) {
				var object = {}

				var parsedOption = JSON.parse(JSON.stringify(option))

				object.label = parsedOption.label
				object.value = parsedOption.value
				object.id = parsedOption.id
				var valueSplit = parsedOption.value.split('-')
				object.id = parseInt(valueSplit[1])
				this.tagsToAdd.push(object)
			},
			removeOption(option) {
				var parsedOption = JSON.parse(JSON.stringify(option))

				var valueSplit = parsedOption.value.split('-')

				const index = this.tagsToAdd.map(object => object.id).indexOf(parseInt(valueSplit[1]));
				this.tagsToAdd.splice(index, 1)
			},
	  		cancelUpdate() {
		      	this.$router.replace({ name: "questions", params: {}, query: { id_repository:  this.question.id_repository} });
	  		},
	  		checkAnswersValues() {
				var countCorrectAnswers = 0;
				this.multiselection = false
	  			if (this.question.answer_one_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (this.question.answer_two_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (this.question.answer_three_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (this.question.answer_four_correct) {
	  				countCorrectAnswers += 1;
	  			}
	  			if (countCorrectAnswers > 1) {
	  				var totalValue = 0;
					this.multiselection = true
	  				if (this.question.answer_one_value != "") {
	  					totalValue += parseFloat(this.question.answer_one_value)
	  				}
	  				if (this.question.answer_two_value != "") {
	  					totalValue += parseFloat(this.question.answer_two_value)
	  				}
	  				if (this.question.answer_three_value != "") {
	  					totalValue += parseFloat(this.question.answer_three_value)
	  				}
	  				if (this.question.answer_four_value != "") {
	  					totalValue += parseFloat(this.question.answer_four_value)
	  				}

	  				if (totalValue !== 100) {
	  					return false;
	  				}
	  			}
	  			return true;
	  		},
	  		handleUpdate() {
	  			this.submitted = true
				this.successful = true

	  			if (this.question.answer_one_correct != '' || this.question.answer_two_correct != '' || this.question.answer_three_correct != '' || this.question.answer_four_correct != '') {
	  				this.$validator.validate().then(isValid => {
		  				if (isValid) {
		  					this.question.question_content = this.question.question_content.replace("<p>", "").replace("</p>", "")
		  					this.question.question_content = this.question.question_content.trim()

		  					this.question.question_title = this.question.question_title.replace("<p>", "").replace("</p>", "")
		  					this.question.question_title = this.question.question_title.trim()

		  					if (this.question.question_title.length != 0) {
			  					if (this.tagsToAdd.length != 0) {
				  					if (this.checkAnswersValues()) {
										if (this.multiselection != null) {
					  						if (this.multiselection || this.multiselection == "true") {
					  							this.question.multiselection = 1
					  						} else {
					  							this.question.multiselection = 0
					  						}
				  						}
				  						
				  						const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
					  					QuestionDataService.update(this.question, this.tagsToAdd, currentAreaID).then(
					  						response => {
					  							if (response.status == 200) {
					  								this.successful = true;
					  								this.$router.push({ name: "questions", params: {questionUpdated: true}, query: { id_repository:  this.$route.query.id_repository} });
					  							} else {
					  								this.message = response.data.message
					  							}
					  						},
					  						error => {
					  							console.log((error.response && error.response.data) ||
													error.message ||
													error.toString())
												this.message = error.response.data.message

												if (error.response.status === 300) {
													console.log('Logout: Token expired')
													localStorage.removeItem('user');
													localStorage.removeItem('company');
													this.$store.state.auth.user = null
													this.$store.dispatch('auth/logout');
													this.$router.push('/login');
												}
					  						}
				  						);
				  					} else {
										this.successfull = false
				  						this.message = "All correct answers value must be 100% combined"
				  					}
			  					} else {
									this.successfull = false
			  						this.message = "Select at least one tag for question's categorization"
			  					}
		  					} else {
								this.successfull = false
		  						this.message = "Please, fill the title of the question"
		  					}
		  				}
		  			});
	  			} else {
					this.successfull = false
	  				this.message = "Please select at least one answer as correct"
	  			}
	  		},
	  		backToTestQuestionsEdit() {
	  			this.$router.replace({ name: "test-edit-questions", params: {testFlag: true, questionIndex: this.questionIndex}, query: {id: this.id_test} })
	  		},
			close() {
				document.getElementById("message").style.display = "none"
			}
	  	},
		props: {
		    testFlag: Boolean,
		    questionIndex: Number,
		    id_test: Number
		}
	};
</script>

<style scoped>
.left-col {
	margin-top: 1% !important;
	margin-bottom: 1% !important;
    margin-left: 0.8rem !important;
    width: 98% !important;
}

.label-title {
	text-align: left;
}
</style>