import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'certification/';

class CertificationsDataService {

  create(certification) {
    const newCertification = {
      name: certification.name,
      acronym: certification.acronym,
      entity: certification.entity,
      expiration_years: certification.expiration_years,
      url: certification.url,
      duration: certification.duration,
      validated: certification.validated,
      slug: certification.slug,
      id_area: certification.id_area,
    }
    return http.post(API_URL_PATH + 'create', { certification: newCertification,  }, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+id, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  update(certification, profile_tech) {
    const newCertification = {
      id: certification.id,
      name: certification.name,
      acronym: certification.acronym,
      entity: certification.entity,
      expiration_years: certification.expiration_years,
      url: certification.url,
      duration: certification.duration,
      validated: certification.validated,
      slug: certification.slug,
      id_area: certification.id_area,
    }
    return http.put(API_URL_PATH + 'update', { certification: newCertification, profile_tech: profile_tech }, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  validate(id) {
    const body = {
      id: id
    }
    return http.put(API_URL_PATH + 'validate', body, { headers: authHeader() });
  }

}

export default new CertificationsDataService();