<template>
  <div class="col-sm-12">
    <div class="secondNavbar"> 
      <div  class="links-container">
        <router-link :to="{path: '/talent-management'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> {{ $t('dashboards.general.back-reporting') }} menu</router-link>
        <reports-downloader :graphContent="graphContent" :documentTitle="documentTitle"/>
      </div>
      <div class="whiteColor">{{ $t('dashboards.general.reporting') }} > General reports > General</div>
    </div>

    <UsersRegistredGraphByRole ref="chart1"/>
    <UsersGraphByCompany ref="chart2"/>
    <ExamsGraphByCompany ref="chart3"/>
    <QuestionsGraphByCompany ref="chart4"/>
    <ProfilesGraphByCompany ref="chart5"/>
    <CVsGraphByCompany ref="chart6"/>
  </div>
</template>

<script>
  import UsersRegistredGraphByRole from "@/components/ReportsComponents/General/UsersRegistredGraphByRole.vue";
  import UsersGraphByCompany from "@/components/ReportsComponents/General/UsersGraphByCompany.vue";
  import ExamsGraphByCompany from "@/components/ReportsComponents/General/ExamsGraphByCompany.vue";
  import QuestionsGraphByCompany from "@/components/ReportsComponents/General/QuestionsGraphByCompany.vue";
  import ProfilesGraphByCompany from "@/components/ReportsComponents/General/ProfilesGraphByCompany.vue";
  import CVsGraphByCompany from "@/components/ReportsComponents/General/CVsGraphByCompany.vue";
  
  export default {
    name: 'GeneralReportsView',
    components: {
      UsersRegistredGraphByRole,
      UsersGraphByCompany,
      ExamsGraphByCompany,
      QuestionsGraphByCompany,
      ProfilesGraphByCompany,
      CVsGraphByCompany
    },
    data() {
      return {
        graphContent: [
          {title: "Users registered by role"},
          {title: "Users registered by company"},
          {title: "Exams by company"},
          {title: "Questions by company"},
          {title: "Profiles created by company"},
          {title: "Users CVs analyzed by company"}
        ],
        documentTitle: "user_reports.pdf"
      }
    }
  };
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>