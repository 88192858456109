<template>
    <div class="col-sm-12">
        <div class="row secondNavbar" v-if="$route.query.previousPage === 'development'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/search-professional-update' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Professional
                    Updating Search</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/search-professional-update' }" class="breadcumb"> Professional Updating
                    Search > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === 'filter'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/search-profile' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to Search Profile</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/search-profile' }" class="breadcumb"> Search Profile > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === 'tests'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/tests-ranking' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to Tests Ranking</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
                <router-link :to="{ path: '/tests' }" class="breadcumb"> Assessment Tests > </router-link>
                <router-link :to="{ path: '/tests-ranking' }" class="breadcumb"> Ranking > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === '360evaluation'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/evaluation-360-ranking' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to BEI/360 Evaluation
                    Ranking</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
                <router-link :to="{ path: '/evaluation-360' }" class="breadcumb"> BEI/360 Evaluation > </router-link>
                <router-link :to="{ path: '/evaluation-360-ranking' }" class="breadcumb"> Ranking > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === 'softskills-list'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/soft-skills-ranking' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Soft Skills
                    Ranking</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/evaluation' }" class="breadcumb"> Evaluation > </router-link>
                <router-link :to="{ path: '/softskills-list' }" class="breadcumb"> Soft Skills > </router-link>
                <router-link :to="{ path: '/soft-skills-ranking' }" class="breadcumb"> Ranking > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === 'businessProcess'">
            <div class="col-5 links-container">
                <router-link :to="{ path: '/business-process' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
                    Process List</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else-if="$route.query.previousPage === 'businessProcessRanking'">
            <div class="col-5 links-container">
                <router-link
                    :to="{ path: '/business-process-ranking?id_business_process=' + $route.query.businessProcess }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Evaluation
                    Process Ranking</router-link>
            </div>
            <div class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process > </router-link>
                <router-link
                    :to="{ path: '/business-process-ranking?id_business_process=' + $route.query.businessProcess }"
                    class="breadcumb"> Evaluation Process Ranking > </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>
        <div class="row secondNavbar" v-else>
            <div class="col-5 links-container">
                <router-link v-if="from.path == '/business-process'" class="btn btn-secondary float-right"
                    :to="from"><font-awesome-icon icon="arrow-left" /> Back to Evaluation Process </router-link>
                <router-link v-else-if="currentUserRoleIsAdmin || isTM" :to="{ path: '/user-analysis' }"
                    class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to CV
                    Results</router-link>
                <router-link v-else :to="{ path: '/analyses' }" class="btn btn-secondary float-right"><font-awesome-icon
                        icon="arrow-left" /> Back to CV Results</router-link>
            </div>
            <div v-if="!currentUserRoleIsAdmin" class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/analyses' }" class="breadcumb"> Professional Profile Analysis >
                </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Detail </router-link>
            </div>

            <div v-else class="col-7 noMargin whiteColor">
                <router-link :to="{ path: '/modules' }" class="breadcumb"> Suite Tools > </router-link>
                <router-link v-if="from.path != '/business-process'" :to="{ path: '/user-analysis' }" class="breadcumb">
                    CV Results > </router-link>
                <router-link v-else :to="{ path: '/business-process' }" class="breadcumb"> Evaluation Process >
                </router-link>
                <router-link :to="{ path: '/profile-analysis' }" class="final-breadcumb"> Professional Profile Analysis
                </router-link>
            </div>
        </div>

        <analysis2 />
    </div>
</template>

<script>
import Analysis2 from "@/components/AnalysisComponents/Analysis2.vue";

export default {
    name: 'AnalysisView',
    components: {
        Analysis2
    },
    data() {
        return {
            from: null,
        }
    },
    mounted() {
    },
    computed: {
        currentUserRoleIsAdmin() {
            if (this.$store.state.auth.user.role.name == "admin") {
                return true;
            } else {
                return false;
            }
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        isTM() {
            if (this.currentUser && this.currentUser.role) {
                if (this.currentUser.role.name === 'talent_manager' || this.currentUser.role.name === 'manager') {
                    return true;
                }
            }
            return false
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from;
        });
    },
};
</script>

<style scoped>
#app>.bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}
</style>