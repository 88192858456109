import http from "../http-common";
import authHeader from './AuthHeaderService';

const API_URL_PATH = 'test/';

class TestDataService {

  getTestBoard(idArea) {
    return http.get(API_URL_PATH + 'createBoard?id_area='+idArea, { headers: authHeader() });
  }
  
  findQuestionsForTest(id) {
    return http.get(API_URL_PATH + 'findQuestionsForTest?id='+parseInt(id), { headers: authHeader() });
  }

  findQuestionsForTestsId(ids) {
    return http.post(API_URL_PATH + 'findQuestionsForTestsId', { ids: ids}, { headers: authHeader() });
  }

  findByArea(idArea) {
    return http.get(API_URL_PATH + 'findByArea?id_area='+idArea, { headers: authHeader() });
  }

  findUserResultsByBusinessProcess (idUser, idBusinessProcess) {
    return http.get(API_URL_PATH + 'findUserResultsByBusinessProcess?id_user='+idUser + '&id_business_process=' + idBusinessProcess, { headers: authHeader() });
  }

  findCandidateResultsByBusinessProcess (idCandidate, idBusinessProcess) {
    return http.get(API_URL_PATH + 'findCandidateResultsByBusinessProcess?id_candidate='+idCandidate + '&id_business_process=' + idBusinessProcess, { headers: authHeader() });
  }

  findTestsForUser(idUser, idArea) {
    return http.get(API_URL_PATH + 'findTestsForUser?id_user=' + idUser + '&id_area=' + idArea, { headers: authHeader() });
  }

  findOne(id) {
    return http.get(API_URL_PATH + 'findOne?id='+parseInt(id), { headers: authHeader() });
  }

  create(test, tags, idArea) {
    if (test.number_attempts == "") {
        test.number_attempts = 1;
    }
    if (test.number_questions == "") {
        test.number_questions = 20;
    }
    if (test.time_limit == '') {
      test.time_limit = null
    }
    test.average_qualification = null
    test.id_area= idArea

    return http.post(API_URL_PATH + 'create', { test: test, tags: tags, id_area: idArea }, { headers: authHeader() });
  }

  update(test, tags, idArea) {
    if (test.number_attempts == "") {
        test.number_attempts = 1;
    }
    if (test.number_questions == "") {
        test.number_questions = 20;
    }
    if (test.time_limit == '') {
      test.time_limit = null
    }
    test.average_qualification = null
    test.id_area= idArea

    const body = { test: test, tags: tags, id_area: idArea};
    return http.put(API_URL_PATH + 'update', body, { headers: authHeader() });
  }

  addQuestionsToTest(mode, id_test, questions) {
    return http.post(API_URL_PATH + 'addQuestions', { mode: mode, id_test: id_test, questions: questions }, { headers: authHeader() });
  }

  delete(ids) {
    let idsTmp = []
    Array.prototype.push.apply(idsTmp, ids);
    
    const firstElement = idsTmp.shift();
    let params = "ids=" + firstElement;

    idsTmp.forEach((id) => {
      params += "&ids=" + id
    })

    return http.delete(API_URL_PATH + 'bulkDelete?' + params, { headers: authHeader() });
  }

  getTags(id_test) {
    return http.get(API_URL_PATH + 'tags?id='+id_test, { headers: authHeader() });
  }

  getQuestionsForAttempt(id_test) {
    return http.get(API_URL_PATH + 'getQuestionsForAttempt?id='+id_test, { headers: authHeader() });
  }

  getQuestionsForCandidateAttempt(id_test, token, id_position_selection) {
    return http.get(API_URL_PATH + 'getQuestionsForCandidateAttempt?id_test='+id_test + '&token=' + token + '&id_position_selection=' + id_position_selection, { headers: authHeader() });
  }

  createAttempt(id_test, id_user) {
    return http.get(API_URL_PATH + 'createAttempt?id_test='+ id_test +"&id_user=" +id_user , { headers: authHeader() });
  }

  createCandidateAttempt(test_id, token, selection_process_id) {
    return http.post(API_URL_PATH + 'createCandidateAttempt', { test_id: test_id, token: token, selection_process_id: selection_process_id }, { headers: authHeader() });
  }

  updateAttempt(id_attempt, questions) {
    return http.post(API_URL_PATH + 'updateAttempt', { id_attempt: id_attempt, questions: questions }, { headers: authHeader() });
  }

  updateCandidateAttempt(id_attempt, questions, selection_process_id) {
    return http.put(API_URL_PATH + 'updateCandidateAttempt', { id_attempt: id_attempt, questions: questions, selection_process_id: parseFloat(selection_process_id) }, { headers: authHeader() });
  }

  findTestResult(user_type, id_test, id_user) {
    if (user_type == 'candidate')
      return http.get(API_URL_PATH + 'findCandidateTestResult?id_test=' + id_test + '&id_candidate=' + id_user , { headers: authHeader() });
    else
      return http.get(API_URL_PATH + 'findTestResult?id_test=' + id_test + '&id_user=' + id_user , { headers: authHeader() });
  }

  userTestsResults(idUser, idArea) {
    return http.get(API_URL_PATH + 'userTestsResults?id_user='+ idUser +"&id_area=" +idArea , { headers: authHeader() });
  }

  candidateTestsResults(idCandidate, idArea) {
    return http.get(API_URL_PATH + 'candidateTestsResults?id_candidate='+ idCandidate +"&id_area=" +idArea , { headers: authHeader() });
  }

  globalResults(id) {
    return http.get(API_URL_PATH + 'globalResults?id=' + id, { headers: authHeader() });
  }

  ranking(idArea) {
    return http.get(API_URL_PATH + 'ranking?id_area=' + idArea, { headers: authHeader() });
  }

  getTestQuestionsPreview(id) {
    return http.get(API_URL_PATH + 'testQuestionsPreview?id=' + id, { headers: authHeader() });
  }
}

export default new TestDataService();