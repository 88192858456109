<template>
	<div>
		<h4 class="reports-title">{{graphTitle}}</h4>
		<div class="row">
			<div class="col-md-3">
				<select id="area-selector-graph" class="form-select area_selector" data-vv-name="area_selector" v-model="selectedArea" @change="onChange($event)">
					<option hidden value="">Select an area</option>
					<option v-for="area in areas" :value="area.id">{{area.name}}</option>
				</select>
			</div>
			<div class="pad col-sm-9"> 
				<multiselect
					class="multiselect-tag-graph"
					:options="tagsToShow"
					:multiple="true"
					:close-on-select="false" 
	        		:preserve-search="true"
					:taggable="true"
					:preselect-first="false"
					placeholder="Search and add one or more tags"
					v-model="selectedTags"
					label="label"
					track-by="value"
					@select="selectOption"
					@remove="removeOption"></multiselect>
			</div>			
		</div>
		<div v-if="graphLoading" class="text-center text-loading">
			<b-spinner class="align-middle"></b-spinner>
			<strong> Loading graph...</strong>
		</div>
		<div v-else>
			<pie-chart 
				:ref="chartId"
				:key="graphLoaded"
				:chartId="chartId"
				:category="category" 
				:value="value"
				:tooltipNoDataMsg="tooltipNoDataMsg"
				:data="data"></pie-chart>
		</div>
	</div>
</template>

<script>
	import TagDataService from '@/services/TagDataService';
	import ReportsDataService from '@/services/ReportsDataService';
	import PieChart from "@/components/Others/Charts/PieChart.vue"
	import BarrChart from "@/components/Others/Charts/BarrChart.vue";
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'QuestionGraphByLevel',
		data() {
			return {
				chartId: "chartQuestionGraphByLevel",
				selectedTagId: 2,
				graphTitle: "Validated questions per level of difficulty and skills",
				selectedTags: [],
				tags: [],
				tagsToShow: [],
				tagsToAdd: [],
				graphLoaded: false,
				graphLoading: false,
				selectedArea: "",
				selectedAreaName: null,
				data: [],
				category: "name",
				value: "count",
				tooltipNoDataMsg: "Please, select an area.",
			}
		},
		components: {
			PieChart,
			BarrChart,
			Multiselect
		},
		props: {
			areas: Array
		},
		methods: {
			onChange(event) {
				this.data = [];
				this.selectedTags = [];
				this.tagsToAdd = [];
				this.tags = [];
				this.tagsToShow = []

				this.selectedArea = event.target.value
				this.tooltipNoDataMsg = "Please, select one or more tags to visualize the data.";

				this.areas.forEach((area) => {
					if (parseInt(area.id) == parseInt(this.selectedArea)){
						this.selectedAreaName = area.name
						this.graphTitle = "Validated questions per level of difficulty and skills in " + this.selectedAreaName;
					}
				});

				this.getTagsFromArea();
			},
			selectOption(option) {
				var object = {}
				var parsedOption = JSON.parse(JSON.stringify(option))

				object.label = parsedOption.label
				object.value = parsedOption.value
				var valueSplit = parsedOption.value.split('-')
				object.id = parseInt(valueSplit[1])
				this.tagsToAdd.push(object)
				
				this.graphLoading = true;
				this.fetchData();
			},
			removeOption(option) {
				var parsedOption = JSON.parse(JSON.stringify(option))
				var valueSplit = parsedOption.value.split('-')

				const index = this.tagsToAdd.map(object => object.id).indexOf(parseInt(valueSplit[1]));
				this.tagsToAdd.splice(index, 1)
				
				this.graphLoading = true;
				this.fetchData();
			},
			async fetchData() {
				const tagIDs = this.tagsToAdd.map(function(e) { return e.id });
				await ReportsDataService.questionsPerDifficultyLevelByAreaTechnologies(this.selectedArea, tagIDs).then(
					response => {
						if (response.status == 200) {
							this.data = response.data
							this.tooltipNoDataMsg = this.data.length == 0 ? "There are no questions for the selected area and skills" : "";
							this.graphLoaded = this.graphLoaded ? false : true
							this.graphLoading = false;
						}
					},
					error => {
						this.graphLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			},
			getTagsFromArea() {
				TagDataService.findByArea(this.selectedArea).then(
					response => {
						if (response.status == 200) {
							let tempTags = response.data;
							tempTags.forEach((tag) => {
								this.tags.push(tag)
								this.tagsToShow.push({label: tag.name, value: tag.name + '-' + tag.id, id: tag.id})
							}) 

							this.graphLoaded = this.graphLoaded ? false : true;
						}
					},
					error => {
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		}
	};
</script>