<template>
	<div v-if="results.length != 0" class="table-container technologiesStyle">
		<template>
			<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'"
				style="margin-left: 2%; margin-right: 2%;">
				{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" /> </b-button>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<div class="row" style="cursor: default !important;">
						<div class="col-sm-4">
							<img :class="results.business_process_data.cv_extractor_enabled ? 'module-avatar' : 'module-avatar disabled-module'"
								src="images/icons/cv_results_icon.svg"></img>
							<h6 v-if="results.business_process_data.cv_extractor_enabled" class="align-text-module">CV
								Results (Active)</h6>
							<h6 v-else class="align-text-module">CV Results (Disabled)</h6>
							<label v-if="results.business_process_data.cv_extractor_enabled">Min Score: {{
		results.business_process_data.cv_extractor_min_score }} / </label>
							<label v-if="results.business_process_data.cv_extractor_enabled"> Weight: {{
		results.business_process_data.cv_extractor_weight }} </label>
						</div>
						<div class="col-sm-4">
							<img :class="results.business_process_data.hard_skills_enabled ? 'module-avatar' : 'module-avatar disabled-module'"
								src="images/icons/hardskills_icon.svg"></img>
							<h6 v-if="results.business_process_data.hard_skills_enabled" class="align-text-module">Hard
								Skills (Active)&nbsp;&nbsp;
								<b-button id="hard-skills-button" class="btn btn-info">
									<font-awesome-icon icon="info-circle" />
								</b-button>
								<b-tooltip 
									target="hard-skills-button" 
									triggers="hover focus" 
									placement="top" 
									data-html="true">
									<p style="margin-bottom: 0 !important;">Selected Tests:</p>
									<p style="color: white !important; margin-bottom: 0 !important;" v-for="test in tests" v-bind:key="test.id">- {{test.title}}</p>
								</b-tooltip>
							</h6>
							<h6 v-else class="align-text-module">Hard Skills (Disabled)</h6>
							<label v-if="results.business_process_data.hard_skills_enabled">Min Score: {{
		results.business_process_data.hard_skills_min_score }} / </label>
							<label v-if="results.business_process_data.hard_skills_enabled"> Weight: {{
		results.business_process_data.hard_skills_weight }} </label>
						</div>
						<div class="col-sm-4">
							<img :class="results.business_process_data.evaluation_360_enabled ? 'module-avatar' : 'module-avatar disabled-module'"
								src="images/icons/bei_icon.svg"></img>
							<h6 v-if="results.business_process_data.evaluation_360_enabled" class="align-text-module">
								BEI/360 Evaluation (Active)
								<b-button id="evaluation-button" class="btn btn-info">
									<font-awesome-icon icon="info-circle" />
								</b-button>
								<b-tooltip 
									target="evaluation-button" 
									triggers="hover focus" 
									placement="top" 
									data-html="true">
									<p>Selected Evaluation: {{ evaluation.name }}</p>
								</b-tooltip>
									
							</h6>
							<h6 v-else class="align-text-module">BEI/360 Evaluation (Disabled)</h6>
							<label v-if="results.business_process_data.evaluation_360_enabled">Min Score: {{
		results.business_process_data.evaluation_360_min_score }} /</label>
							<label v-if="results.business_process_data.evaluation_360_enabled"> Weight: {{
		results.business_process_data.evaluation_360_weight }} </label>
						</div>
					</div>
					<div class="row">
						<template v-if="results.ranking.length > 0">
							<div class="row" style="margin-top: 1%;">
								<div class="col-sm-2">
									<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
										aria-controls="selectionProcessCandidatesList"></b-pagination>
								</div>
								<div class="col-sm-7">
									<b-form-select class="numPerPage paginationSelectorTable" v-model="perPage"
										:options="pageOptions"></b-form-select>
								</div>
								<div class="col-sm-3">
									<b-form-input id="filter-input" v-model="filter" type="search"
										:placeholder="$t('others.search-placeholder-msg')"></b-form-input>
								</div>
							</div>

							<b-table ref="selectionProcessCandidatesTable" id="selectionProcessCandidatesList"
								:per-page="perPage" :current-page="currentPage" :fields="headers"
								:items="results.ranking" :filter="filter" responsive="sm"
								style="cursor: default !important;">
								<template #cell(logo)="data">
									<img v-b-tooltip.hover :title="data.item.logo" v-bind:src="data.item.logo"
										height="30em" class="analysis-card-avatar--circle" />
								</template>
								<template #cell(name)="data">
									<template v-if="data.item.user_data !== undefined">
										{{ data.item.user_data.name }} {{ data.item.user_data.surname }}
									</template>
									<template v-else>
										{{ data.item.name }}
									</template>
								</template>
								<template #cell(progress)="data">
									<candidate-progress-business-process :selectionProcess="data.item"
										:businessProcessData="results.business_process_data" />
								</template>
								<template #cell(final_score)="data">
									<b>{{ parseFloat(data.item.average_score).toFixed(2) }} / 100</b>
								</template>
								<template #cell(options)="data" style="cursor: pointer !important;">
									<button v-b-tooltip.hover title="Remind to complete the process"
										class="btn btn-secondary" v-if="(!data.item.completed && data.item.passed) || (data.item.id_360_evaluation != undefined &&
										data.item.id_360_evaluation != null && data.item.ev_360_score == null)"
										@click="showSendModal(data.item)">
										<font-awesome-icon icon="paper-plane" />
									</button>
									<button v-b-tooltip.hover title="The candidate/user has not passed the last test"
										class="btn btn-secondary disabled-icon"
										v-else-if="!data.item.completed && !data.item.passed">
										<font-awesome-icon icon="paper-plane" />
									</button>
									<button v-b-tooltip.hover title="The candidate/user has completed the process"
										class="btn btn-secondary disabled-icon" v-else-if="data.item.completed">
										<font-awesome-icon icon="paper-plane" />
									</button>
								</template>
							</b-table>
						</template>
						<template v-else>
							<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No
								candidates available for this evaluation process.</h3>
						</template>
					</div>
				</div>
			</div>
		</template>

		<b-modal id="send-modal" ref="send-modal" title="Send-modal" hide-footer>
			<template #modal-title>
				Send remainder email
			</template>
			<div>
				<p>Do you really want to send {{ selectedUserNameSurname }} an email reminding him/her to complete the
					business
					process?</p>
			</div>
			<input class="btn btn-primary form-submit" style="margin-right: 1%;" type="button"
				@click="$bvModal.hide('send-modal')" value="No">
			<input class="btn btn-primary form-submit" type="button" @click="sendEmail()" value="Yes">
		</b-modal>
	</div>
	<div v-else>
		<h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color" /> No candidates available
			for
			this evaluation process.</h3>
	</div>
</template>

<script>
import BusinessProcessService from '@/services/BusinessProcessService';
import CandidateProgressBusinessProcess from "@/components/BusinessProcessComponents/CandidateProgressBusinessProcess";
import UserDataService from "@/services/UserDataService";

export default {
	name: 'SelectionProcessCandidatesProgress',
	data() {
		return {
			headers: [
				{ key: 'logo', label: '' },
				{ key: 'name', sortable: true, label: 'Name' },
				{ key: 'progress', sortable: true, label: 'Progress', tdClass: 'tdBig' },
				{ key: 'final_score', sortable: true, label: 'Final Score' },
				{ key: 'options', label: 'Options' },
			],
			message: "",
			successfull: false,
			perPage: 10,
			currentPage: 1,
			filter: null,
			pageOptions: this.$paginationOptions,
			results: [],
			selectedUserNameSurname: null,
			selectedEmail: null,
			selectedUserId: null,
			selectedProcessId: null,
			isUser: false,
			htmlEvaluation: ''
		}
	},
	components: {
		CandidateProgressBusinessProcess
	},
	props: {
		id: Number,
		tests: Array,
		evaluation: Object
	},
	computed: {
		rows() {
			return this.results.ranking.length
		}
	},
	mounted() {
		this.htmlEvaluation = `
			<ul style="margin: 0; padding-left: 20px;">
				<li>` + this.evaluation.name + `</li>
				<li>Punto 2</li>
				<li>Punto 3</li>
			</ul>
		`
		this.getRankingResults();
	},
	methods: {
		checkHighlight(data) {
			return 'custom-highlight'
		},
		showSendModal(item) {
			if (item.user_data !== undefined && item.user_data !== null) {
				this.isUser = true
				this.selectedUserId = item.user_data.id
				this.selectedProcessId = this.results.business_process_data.id
				this.selectedUserNameSurname = item.user_data.name + ' ' + item.user_data.surname
				this.selectedEmail = item.user_data.email
			} else {
				this.isUser = false
				this.selectedUserId = item.id_cv
				this.selectedProcessId = this.results.business_process_data.id
				this.selectedUserNameSurname = item.name
				this.selectedEmail = item.email
			}

			this.$refs['send-modal'].show();
		},
		sendEmail() {
			if (this.isUser) {
				UserDataService.sendReminderEmailBusinessProcess(this.selectedUserId, this.selectedProcessId).then(
					response => {
						if (response.status == 200) {
							this.successfull = true
							this.message = 'An invitation to email ' + this.selectedEmail + ' has been sent for completing his/her evaluation process!.'
						} else {
							this.successfull = false
							this.message = 'An error ocurred while sending chatbot invitation email.'
						}
						this.$refs['send-modal'].hide();
					},
					error => {
						this.message = error.response.data.message
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			} else {
				UserDataService.sendReminderEmailBusinessProcessToCandidate(this.selectedUserId, this.selectedProcessId).then(
					response => {
						if (response.status == 200) {
							this.successfull = true
							this.message = 'An invitation to email ' + this.selectedEmail + ' has been sent for completing his/her evaluation process!.'
						} else {
							this.successfull = false
							this.message = 'An error ocurred while sending chatbot invitation email.'
						}
						this.$refs['send-modal'].hide();
					},
					error => {
						this.successfull = false
						this.message = error.response.data.message
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			}
		},
		onCandidateSelected(userID) {
			let routeData = this.$router.resolve({ name: 'profile-analysis', query: { id: userID, previousPage: 'businessProcess' } });
			window.open(routeData.href, '_blank');
		},
		getRankingResults() {
			BusinessProcessService.ranking(this.id).then(
				response => {
					this.results = response.data;

					this.results.ranking.forEach(result => {
						result.completed = false
						result.passed = false

						if (this.results.business_process_data.cv_extractor_enabled && this.results.business_process_data.cv_extractor_min_score > result.top_profile_score)
							result._rowVariant = 'secondary'

						this.checkPassed(result, this.results.business_process_data)
					})

					console.log('results.ranking: ', this.results.ranking)
					console.log('results.business_process_data: ', this.results.business_process_data)
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		checkCompleted(item, business_process_data) {
			if (item.is_finished) return true
			else return false
		},
		checkPassed(item, business_process_data) {
			if (this.checkCompleted(item, business_process_data)) {
				item.completed = true
			} else {
				item.completed = false

				let passed = true

				if (item.top_profile_score !== null && item.top_profile_score !== undefined) {
					if (business_process_data.cv_extractor_enabled && item.top_profile_score < business_process_data.cv_extractor_min_score) passed = false
					else if (item.tests_score !== null && item.tests_score !== undefined) {
						if (business_process_data.hard_skills_enabled && item.tests_score < business_process_data.hard_skills_min_score) passed = false
						else if (item.ev_360_score !== null && item.ev_360_score !== undefined) {
							if (business_process_data.evaluation_360_enabled && item.ev_360_score < business_process_data.evaluation_360_min_score) passed = false
						}
					}
				}
				if (passed) item.passed = true
				else item.passed = false
			}
			return true
		},
		close() {
			document.getElementById("message").style.display = "none"
			this.successfull = false
			this.message = ""
		}
	}
};
</script>

<style type="text/css" scoped>
.module-avatar {
	width: 20%;
	margin: 10px;
	border: 5px solid black;
	border-radius: 500px;
	-webkit-border-radius: 500px;
	-moz-border-radius: 500px;
}

.disabled-module {
	opacity: 0.4;
	filter: alpha(opacity=40);
}

.align-text-module {
	text-align: left !important;
}

.disabled-icon {
	opacity: 0.4;
	cursor: default;
}

.custom-highlight {
	background-color: yellow;
}
</style>
