<template>
	<form action class="form" @submit.prevent="handleUpdate">
		<div class="form-container technologiesStyle">
			<div class="row">
				<div class="form-group col-12">
					<label>Name*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Name of the evaluation process"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name"
						v-model="bprocess.name">
				</div>
			</div>

			<div class="row mt-2">
				<div class="form-group col-12">
					<div class="row">
						<div class="col-sm-auto">
							<label>Profile*<b-button class="btn btn-info" v-b-tooltip.hover
									title="Profile associated to the process"><font-awesome-icon
										icon="info-circle" /></b-button></label>
						</div>
						<div class="col-sm-10">
							<multiselect id="profile_selector" :options="profiles" :close-on-select="true"
								:preserve-search="true" :taggable="true" :preselect-first="false" :option-width="140"
								placeholder="Search or add a profile to the evaluation process"
								v-model="bprocess.profile_associated">
								<template slot="singleLabel" slot-scope="props">
									<img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
									<span class="option__desc"><span class="option__title">{{ props.option.name
											}}</span></span>
								</template>
								<template slot="option" slot-scope="props">
									<img class="option__image selector-img" :src="$awsBucketRoute + props.option.logo">
									<span class="option__desc"><span class="option__title">{{ props.option.name
											}}</span></span>
								</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-sm-6">
					<div class="row form-group">
						<label>Start date*</label>
						<input class="form-input" type="date" data-vv-name="start_date" v-model="bprocess.start_date"
							v-validate="'required'">
					</div>
				</div>
				<div class="col-sm-6">
					<div class="row form-group">
						<label>End date*</label>
						<input class="form-input" type="date" data-vv-name="end_date" v-model="bprocess.end_date"
							v-validate="'required'" :min="bprocess.start_date">
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-9">
					<label>CV Results Enabled*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Activate cv results module"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<div class="form-check form-switch dichotomic-switch config-switch">
						<input class="form-check-input checkbox-required" type="checkbox"
							v-model="bprocess.cv_extractor_enabled">
					</div>
				</div>
				<div class="form-group col-3">
					<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
						oninput="validity.valid||(value=0);" v-model="bprocess.cv_extractor_weight"
						:disabled="bprocess.cv_extractor_enabled ? false : true">
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-9">
					<label>Hard Skills Enabled*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Activate hard skills module"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<div class="form-check form-switch dichotomic-switch config-switch">
						<input class="form-check-input checkbox-required" type="checkbox"
							v-model="bprocess.hard_skills_enabled">
					</div>
				</div>
				<div class="form-group col-3">
					<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
						oninput="validity.valid||(value=0);" v-model="bprocess.hard_skills_weight"
						:disabled="bprocess.hard_skills_enabled ? false : true">
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-9">
					<label>Soft Skills Enabled*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Activate soft skills module"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<div class="form-check form-switch dichotomic-switch config-switch">
						<input class="form-check-input checkbox-required" type="checkbox"
							v-model="bprocess.soft_skills_enabled">
					</div>
				</div>
				<div class="form-group col-3">
					<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
						oninput="validity.valid||(value=0);" v-model="bprocess.soft_skills_weight"
						:disabled="bprocess.soft_skills_enabled ? false : true">
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-9">
					<label>BEI/360 Evaluation Enabled*<b-button class="btn btn-info" v-b-tooltip.hover
							title="Activate BEI/360 Evaluation module"><font-awesome-icon
								icon="info-circle" /></b-button></label>
					<div class="form-check form-switch dichotomic-switch config-switch">
						<input class="form-check-input checkbox-required" type="checkbox"
							v-model="bprocess.evaluation_360_enabled">
					</div>
				</div>
				<div class="form-group col-3">
					<input class="form-input" v-validate="'required'" max="100000" min="0" type="number"
						oninput="validity.valid||(value=0);" v-model="bprocess.evaluation_360_weight"
						:disabled="bprocess.evaluation_360_enabled ? false : true">
				</div>
			</div>

			<div class="row mt-4">
				<div class="form-group col-12">
					<div class="row">
						<div class="col-sm-auto">
							<label>Candidates to add to the process*<b-button class="btn btn-info" v-b-tooltip.hover
									:title="'Candidates from ' + currentAreaName + ' to include'"><font-awesome-icon
										icon="info-circle" /></b-button></label>
						</div>
						<div class="col-sm-8">
							<multiselect id="candidate_selector" :options="userList" :multiple="true"
								:close-on-select="false" :preserve-search="true" :taggable="true"
								:preselect-first="true" :group-select="true"
								placeholder="Search or add a candidate to the evaluation process"
								v-model="selectedCandidates" label="label" track-by="value" group-values="sub_options"
								group-label="option" @select="selectOptionCandidate" @remove="removeOptionCandidate">
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<b-table ref="candidatesTable" id="candidatesList" :fields="headers" :items="tableCandidates"
					select-mode="multi" responsive="sm">
					<template #cell(avatar)="data">
						<img v-if="data.item.avatar == null" :src="userImage" height="30em"
							class="analysis-card-avatar--circle" />
						<img v-else-if="data.item.user.avatar.includes($awsBucketRoute)"
							v-bind:src="data.item.user.avatar" height="30em" class="analysis-card-avatar--circle" />
						<img v-else v-bind:src="$awsBucketRoute + data.item.user.avatar" height="30em"
							class="analysis-card-avatar--circle" />
					</template>
					<template #cell(name)="data">
						{{ data.item.user.name }} {{ data.item.user.surname }}
					</template>
				</b-table>
			</div>

			<div class="form-group col-lg-4 align-item-center">
				<div class="row">
					<div class="col-sm-6">
						<button class="btn btn-secondary btn-cancel" type="button"
							@click="backToRepository()"><font-awesome-icon icon="ban" /> Cancel</button>
					</div>
					<div class="col-sm-6">
						<input class="btn btn-primary form-submit white-color" type="submit" value="Update">
					</div>
				</div>
			</div>
			<div id="message" v-if="message != ''" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
				{{ message }} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross"
						icon="times" />
				</b-button>
			</div>
		</div>
	</form>
</template>

<script>
import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
import BusinessProcessService from '@/services/BusinessProcessService';
import AreasDataService from '@/services/AreasDataService';
import Multiselect from 'vue-multiselect';

export default {
	name: 'Update',
	data() {
		return {
			bprocess: {},
			currentAreaName: "",
			headers: [
				{ key: 'avatar', label: '' },
				{ key: 'name', sortable: true, label: 'Name' },
			],
			message: "",
			successful: false,
			candidatesToShow: [],
			selectedCandidates: [],
			candidatesToAdd: [],
			tableCandidates: [],
			logos: [],
			users: [],
			profiles: [],
			userList: [],
			userImage: "http://" + location.host + '/images/icons/user.png'
		}
	},
	components: {
		Multiselect
	},
	mounted() {
		this.currentAreaName = this.$store.state.auth.user.areas.filter(area => area.id == this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).map(function (e) { return e.name })[0];
		this.getAvailableCandidates();
		this.getAreaProfiles();
		this.getBusinessProcess();
	},
	methods: {
		getBusinessProcess() {
			BusinessProcessService.findOne(this.$route.query.id).then(
				response => {
					this.bprocess = response.data;

					this.bprocess.start_date = this.bprocess.start_date.split('T')[0];
					this.bprocess.end_date = this.bprocess.end_date.split('T')[0]

					this.bprocess.profile_associated = this.bprocess.profile;

					this.bprocess.users.forEach(candidate => {
						this.candidatesToAdd.push(candidate.id)
						this.selectedCandidates.push({ label: candidate.name, value: candidate.name + '-' + candidate.id, id: candidate.id, email: candidate.email });
					})

					this.bprocess.users.forEach(candidate => {
						let userToAdd = this.users.filter(user => candidate.id == user.user.id)[0]
						this.tableCandidates.push(userToAdd)
					})
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		getAvailableCandidates() {
			AreasDataService.findUsersCandidate(this.$store.state.auth.user.active_areas[this.$session.get("tabID")]).then(
				response => {
					if (response.status == 200) {
						this.users = response.data;
						this.users.forEach((user) => {
							this.candidatesToShow.push({ label: user.user.name + " " + user.user.surname, value: user.user.name + '-' + user.user.id, id: user.user.id, email: user.user.email })
						});

						let userListTmp = [{ option: "Select all", sub_options: [] }]
						userListTmp[0].sub_options = this.candidatesToShow
						this.userList = userListTmp;
					} else {
						this.message = response.data.message;
						this.successful = false;
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())
					this.message = (error.response && error.response.data) ||
						error.message ||
						error.toString();
					this.successful = false;

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		getAreaProfiles() {
			const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
			ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
				response => {
					if (response.status == 200) {
						this.profiles = response.data
						this.profiles.forEach((tech) => {
							this.logos[tech.name.toLowerCase()] = tech.logo.includes(this.$awsBucketRoute) ? tech.logo : this.$awsBucketRoute + tech.logo
						});
					}
				},
				error => {
					console.error((error.response && error.response.data) ||
						error.message ||
						error.toString())

					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
			);
		},
		selectOptionCandidate(option) {
			var object = {}
			var parsedOption = JSON.parse(JSON.stringify(option))

			object.label = parsedOption.label
			object.value = parsedOption.value
			var valueSplit = parsedOption.value.split('-')

			this.candidatesToAdd.push(parseInt(valueSplit[1]))

			let userToAdd = this.users.filter(user => parseInt(valueSplit[1]) == user.user.id)[0]
			this.tableCandidates.push(userToAdd)
		},
		removeOptionCandidate(option) {
			var parsedOption = JSON.parse(JSON.stringify(option))
			var valueSplit = parsedOption.value.split('-')
			const index = this.candidatesToAdd.indexOf(parseInt(valueSplit[1]));
			this.candidatesToAdd.splice(index, 1)

			this.tableCandidates = this.tableCandidates.filter(user => user.user.id != parseInt(valueSplit[1]))
		},
		backToRepository() {
			this.$router.push({ path: 'business-process' })
		},
		handleUpdate() {
			this.$validator.validate().then(isValid => {
				if (!isValid) {
					this.successful = false;
					this.message = "Please, fill all the fields correctlly";
					return;
				}

				this.bprocess.candidates = this.candidatesToAdd;
				this.bprocess.id = this.$route.query.id;
				this.bprocess.id_profile = this.bprocess.profile_associated.id;

				BusinessProcessService.update(this.bprocess).then(
					() => {
						BusinessProcessService.removeUsersFromBusinessProcess(this.$route.query.id).then(
							response => {
								BusinessProcessService.addUsersToBusinessProcess(this.$route.query.id, this.candidatesToAdd).then(
									response => {
										this.successful = true;
										this.message = response.data.message;
									},
									error => {
										console.log((error.response && error.response.data) ||
											error.message ||
											error.toString())

										if (error.response.status === 300) {
											console.log('Logout: Token expired')
											localStorage.removeItem('user');
											localStorage.removeItem('company');
											this.$store.state.auth.user = null
											this.$store.dispatch('auth/logout');
											this.$router.push('/login');
										}
									}
								);
							},
							error => {
								console.log((error.response && error.response.data) ||
									error.message ||
									error.toString())

								this.message = error.response.data.message.replace("Bad Request.", "");

								if (error.response.status === 300) {
									console.log('Logout: Token expired')
									localStorage.removeItem('user');
									localStorage.removeItem('company');
									this.$store.state.auth.user = null
									this.$store.dispatch('auth/logout');
									this.$router.push('/login');
								}
							}
						);
					},
					error => {
						console.log((error.response && error.response.data) ||
							error.message ||
							error.toString())

						this.message = error.response.data.message.replace("Bad Request.", "");

						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
				);
			});
		},
		close() {
			this.message = "";
		}
	}
};
</script>

<style type="text/css" scoped>
.selector-img {
	width: 8% !important;
}
</style>