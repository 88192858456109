<template>
	<div class="form-container technologiesStyle">
		<form action class="form" @submit.prevent="handleUpdate">
			<div class="col-sm-12">
  				<div class="col-md-12 align-item-center">
					<div class="row">
						<div class="form-group col-9">
							<label>Name of the certification *<b-button class="btn btn-info" v-b-tooltip.hover title="Name of the certification"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="text" id="name" v-validate="'required'" data-vv-name="name" v-model="certification.name">
							<div v-if="submitted && veeErrors.has('name')" class="alert alert-danger">
								{{veeErrors.first('name')}}
							</div>
						</div>
						<div class="form-group col-3">
							<label>Acronym<b-button class="btn btn-info" v-b-tooltip.hover title="Acronym of the certification"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="text" id="acronym" v-model="certification.acronym">
						</div>
					</div>
  				</div>
  			</div>
			<div class="col-sm-12">
  				<div class="col-md-12 align-item-center">
					<div class="row">
						<div class="form-group col-6">
							<label>Certifying entity *<b-button class="btn btn-info" v-b-tooltip.hover title="Entity (company or course) that certifies the certification"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="text" id="entity" v-validate="'required'" data-vv-name="entity" v-model="certification.entity">
							<div v-if="submitted && veeErrors.has('entity')" class="alert alert-danger">
								{{veeErrors.first('entity')}}
							</div>
						</div>
						<div class="form-group col-6">
							<label>Validity period of the certification *<b-button class="btn btn-info" v-b-tooltip.hover title="Number of years the certificate is valid through"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="number" data-vv-name="expiration_years" v-model="certification.expiration_years">
						</div>
					</div>
  				</div>
  			</div>

			<div class="col-sm-12">
  				<div class="col-md-12 align-item-center">
					<div class="row">
						<div class="form-group col-6">
							<label>Link to the course<b-button class="btn btn-info" v-b-tooltip.hover title="URL to the course completed to achieve the certification"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="url" id="url" data-vv-name="url" v-model="certification.url" @input="changeURL($event)" @change="changeURL($event)">
						</div>
						<div class="form-group col-6">
							<label>Certification course duration (in hours)<b-button class="btn btn-info" v-b-tooltip.hover title="Time required to complete the certification"><font-awesome-icon icon="info-circle" /></b-button></label>
							<input class="form-input" type="number" data-vv-name="duration" v-model="certification.duration">
						</div>
					</div>
  				</div>
  			</div>

			<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
	      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
	      	</div>

			<div class="form-group col-lg-4 align-item-center">
				<div class="row">
					<div class="col-sm-6">
						<input class="btn btn-primary form-submit" type="submit" value="Update">
					</div>
					<div class="col-sm-6">
						<button v-if="testFlag" type="button" class="btn btn-secondary btn-cancel" @click="backToTestQuestionsEdit()"><font-awesome-icon icon="ban" /> Cancel</button>
						<button v-else class="btn btn-secondary btn-cancel" type="button" @click="cancelUpdate()"><font-awesome-icon icon="ban" /> Cancel</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import CertificationsDataService from '@/services/CertificationsDataService';
	import ProfileConfigurationDataService from '@/services/ProfileConfigurationDataService';
	import Certification from '@/models/certification';

	export default {
		name: 'UpdateQuestionComponent',
		data() {
			return {
				certification: new Certification('', '', '', '', '', '', '', false, this.$store.state.auth.user.active_areas[this.$session.get("tabID")]),
				technologies: [],
				profiles: [],
				id_profile: "",
				id_technology: "",
				submitted: false,
				successfull: false,
				urlValid: false,
				message: ""
			}
		},
		mounted() {
		    CertificationsDataService.findOne(this.$route.query.id).then(
				response => {
					this.certification = response.data
					this.id_technology = response.data.profiles[0].profile_level_certification.id_profile_level; // IMPORTANT --> IT will be more than one technology
					this.id_profile = response.data.profiles[0].id;
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );	

		    const currentArea = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];
		    ProfileConfigurationDataService.findLevelsConfigurationByArea(currentArea).then(
				response => {
					let technologiesResponse = response.data;

					let technologiesTmp = [];
					for (var i = 0; i < technologiesResponse.length; i++){
						let tech = {};
						tech.id = technologiesResponse[i].id;
						tech.name = technologiesResponse[i].name.charAt(0).toUpperCase() + technologiesResponse[i].name.slice(1);

						technologiesTmp.push(tech)
					}

					let profilesTmp = [];
					let profilesResponse = technologiesResponse[0].profile_levels;
					for (var i = 0; i < profilesResponse.length; i++){
						let profile = {}
						profile.id = profilesResponse[i].id
						profile.name = profilesResponse[i].name

						profilesTmp.push(profile)
					}

					this.profiles = profilesTmp;
					this.technologies = technologiesTmp;
				},
				error => {
					console.log((error.response && error.response.data) ||
						error.message ||
						error.toString())
					
					if (error.response.status === 300) {
						console.log('Logout: Token expired')
						localStorage.removeItem('user');
						localStorage.removeItem('company');
						this.$store.state.auth.user = null
						this.$store.dispatch('auth/logout');
						this.$router.push('/login');
					}
				}
		    );
	  	},
	  	methods: {
	  		cancelUpdate() {
		      	this.$router.replace({ name: "certifications", params: {}});
	  		},
			changeURL:function(e){
				const url = e.target.value
			},
	  		handleUpdate() {
	  			this.submitted = true
				this.successfull = true

  				this.$validator.validate().then(isValid => {
	  				if (isValid) {
	  					if (this.certification.name.length != 0) {
	  						if (parseInt(this.certification.expiration_years) > 0) {
	  							this.certification.expiration_years = parseInt(this.certification.expiration_years) 

	  							if (parseInt(this.certification.duration) > 0) {
	  								this.certification.duration = parseInt(this.certification.duration)

									let profile_tech = [{id_profile_level: this.id_technology, id_profile: this.id_profile}]
				  					CertificationsDataService.update(this.certification, profile_tech).then(
				  						response => {
				  							if (response.status == 200) {
				  								this.successfull = true;
				  								this.$router.push({ name: "certifications", params: {certificationUpdated: true}});
				  							}
				  						},
				  						error => {
											this.successfull = false
									        this.message = error.response.data.message

				  							console.log((error.response && error.response.data) ||
												error.message ||
												error.toString())
											
											if (error.response.status === 300) {
												console.log('Logout: Token expired')
												localStorage.removeItem('user');
												localStorage.removeItem('company');
												this.$store.state.auth.user = null
												this.$store.dispatch('auth/logout');
												this.$router.push('/login');
											}
				  						}
			  						);
			  					} else {
									this.successfull = false
			  						this.message = "Please, insert more than 1 hour of duration for the course"
			  					}
		  					} else {
								this.successfull = false
		  						this.message = "Please, insert more than 1 year of expiration"
		  					}
	  					} else {
							this.successfull = false
	  						this.message = "Please, fill the title of the certification"
	  					}
	  				}
	  			});
	  		},
	  		backToTestQuestionsEdit() {
	  			this.$router.replace({ name: "test-edit-questions", params: {testFlag: true, questionIndex: this.questionIndex}, query: {id: this.id_test} })
	  		},
			close() {
				document.getElementById("message").style.display = "none"
			}
	  	},
		props: {
		    testFlag: Boolean,
		    questionIndex: Number,
		    id_test: Number
		}
	};
</script>