<template>
    <div class="row" style="cursor: default !important;">
        <div v-if="businessProcessData.cv_extractor_enabled" class="col">
            <router-link v-if="selectionProcess.top_profile_score != null"
                :to="{ path: '/profile-analysis?id=' + selectionProcess.id_cv }" style="cursor: pointer !important;">
                <img :class="selectionProcess.top_profile_score < businessProcessData.cv_extractor_min_score ? 'module-avatar-failed' : 'module-avatar-completed'"
                    src="images/icons/cv_results_icon.svg"></img>
            </router-link>
            <div v-else style="cursor: default !important;">
                <img :class="selectionProcess.top_profile_score != null ? 'module-avatar-completed' : 'module-avatar-incompleted'"
                    src="images/icons/cv_results_icon.svg"></img>
            </div>
        </div>

        <template v-if="selectionProcess.user_data !== undefined && selectionProcess.user_data !== null">
            <div v-if="businessProcessData.hard_skills_enabled" class="col">
                <router-link v-if="selectionProcess.tests_score != null"
                    :to="{ path: '/test-business-process-result?id_user=' + selectionProcess.user_data.id + '&id_business_process=' + businessProcessData.id + '&type=user' }"
                    style="cursor: pointer !important;">
                    <img :class="selectionProcess.tests_score < businessProcessData.hard_skills_min_score ? 'module-avatar-failed' : 'module-avatar-completed'"
                        src="images/icons/hardskills_icon.svg"></img>
                </router-link>
                <div v-else style="cursor: default !important;">
                    <img :class="selectionProcess.tests_score != null ? 'module-avatar-completed' : 'module-avatar-incompleted'"
                        src="images/icons/hardskills_icon.svg"></img>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="businessProcessData.hard_skills_enabled" class="col">
                <router-link v-if="selectionProcess.tests_score != null"
                    :to="{ path: '/test-business-process-result?id_user=' + selectionProcess.id_cv + '&id_business_process=' + businessProcessData.id + '&type=candidate' }"
                    style="cursor: pointer !important;">
                    <img :class="selectionProcess.tests_score < businessProcessData.hard_skills_min_score ? 'module-avatar-failed' : 'module-avatar-completed'"
                        src="images/icons/hardskills_icon.svg"></img>
                </router-link>
                <div v-else style="cursor: default !important;">
                    <img :class="selectionProcess.tests_score != null ? 'module-avatar-completed' : 'module-avatar-incompleted'"
                        src="images/icons/hardskills_icon.svg"></img>
                </div>
            </div>
        </template>



        <template v-if="selectionProcess.user_data !== undefined && selectionProcess.user_data !== null">
            <div v-if="businessProcessData.evaluation_360_enabled" class="col">
                <router-link v-if="selectionProcess.ev_360_score != null"
                    :to="{ path: '/softskills-evaluation-result-user?id_evaluation=' + selectionProcess.id_360_evaluation + '&id_user=' + selectionProcess.user_data.id + '&id_position_selection=' + businessProcessData.id }"
                    style="cursor: pointer !important;">
                    <img :class="selectionProcess.ev_360_score < businessProcessData.evaluation_360_min_score ? 'module-avatar-failed' : 'module-avatar-completed'"
                        src="images/icons/bei_icon.svg"></img>
                </router-link>
                <div v-else style="cursor: default !important;">
                    <img :class="selectionProcess.ev_360_score != null ? 'module-avatar-completed' : 'module-avatar-incompleted'"
                        src="images/icons/bei_icon.svg"></img>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="businessProcessData.evaluation_360_enabled" class="col">
                <div style="cursor: default !important;">
                    <img class="module-avatar-incompleted" src="images/icons/bei_icon.svg"></img>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CandidateProgressBusinessProcess',
    props: {
        selectionProcess: Object,
        businessProcessData: Object
    },
    data() {
        return {
            id_area: this.$store.state.auth.user.active_areas[this.$session.get("tabID")]
        }
    },
    mounted() {
        if (this.selectionProcess.user_data === undefined) this.selectionProcess.user_data = { id: this.selectionProcess.id_cv }
        console.log('selectionProcess: ', this.selectionProcess)
        console.log('businessProcessData: ', this.businessProcessData)
    }
};
</script>

<style type="text/css" scoped>
.module-avatar-completed {
    width: 55%;
    border: 5px solid #7AC943;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.module-avatar-failed {
    width: 55%;
    border: 5px solid #FF8000;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.module-avatar-incompleted {
    width: 55%;
    border: 5px solid red;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.click {
    cursor: default !important;
}
</style>