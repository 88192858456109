<template>
  <div class="col-sm-12">
    <div class="row secondNavbar"> 
      <div class="col-5 links-container">
        <router-link :to="{path: '/config-profile'}" class="btn btn-secondary float-right"><font-awesome-icon icon="arrow-left" /> Back to Configuration of the Levels of Expertise</router-link>
      </div>
      <div class="col-7 noMargin whiteColor">
        <router-link :to="{ path: '/settings' }" class="breadcumb"> Settings > </router-link>
        <router-link :to="{ path: '/config-profile' }" class="breadcumb"> Configuration of the Levels of Expertise > </router-link>
        <router-link :to="{ path: '/recycledtags' }" class="final-breadcumb"> Reskilling </router-link>
      </div>
    </div>	

  	<div class="col-sm-12">
      <div id="message" v-if="technologiesUpdated" class="alert alert-success col-md-6 align-item-center">
        Technologies updated succesfully! <b-button @click="close" class="cross-button"><font-awesome-icon  class="cross" icon="times" /> </b-button>
      </div>
      <div class="col-sm-8 justify-content-center col-centered">	
  		  <recycled-tags/>
      </div>
  	</div>
  </div>
</template>

<script>
  import RecycledTags from "@/components/TagComponents/RecycledTags.vue";

	export default {
    name: 'ListTagsView',
    data() {
      return {
        tags: [],
        technologies: []
      }
    },
    components: {
      RecycledTags
    },
    methods: {
      close() {
        document.getElementById("message").style.display = "none"
      },
      close2() {
        document.getElementById("message2").style.display = "none"
      }
    },
    props: {
      technologiesUpdated: {
        type: Boolean,
        default: false
      }
    }
	};
</script>

<style scoped>
#app > .bg-white .main-container {
    max-width: 100%;
    float: none;
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-top: 0px !important; 
}
</style>
