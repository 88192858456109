<template>
	<div v-if="dataLoading" class="text-center text-loading">
      <b-spinner class="align-middle analysis-spinner"></b-spinner>
      <strong class="text-loading-msg"> Loading data...</strong>
    </div>
  	<div v-else class="technologiesStyle col-sm-12 my-2 table-container">
  		<div id="message" v-if="message" class="alert" :class="successfull ? 'alert-success' : 'alert-danger'">
      		{{message}} <b-button @click="close" class="cross-button"><font-awesome-icon class="cross" icon="times" /> </b-button>
      	</div>
		<button @click="generatePDF">PDF</button>
		<vue-html2pdf
			:show-layout="true"
			:float-layout="false"
			:enable-download="false"
			:preview-modal="false"
			:paginate-elements-by-height="920"
			filename="hee hee"
			:pdf-quality="2"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			:html-to-pdf-options="htmlToPdfOptions"
			@progress="onProgress($event)"
			@hasStartedGeneration="hasStartedGeneration()"
			@hasGenerated="hasGenerated($event)"
			@beforeDownload="beforeDownload($event)"
			ref="html2Pdf"
		>
			<section class="technologiesStyle col-sm-12 my-2 table-container" slot="pdf-content" ref="pdf-content" style="background-color: white !important; padding: 90px !important; padding-top: 0px !important">
				<section>
					<user-basic-information :name="data.name"
						:anonymized="data.anonymized"
						:token="data.chatbot_token"
						:userImage="data.image"
						:currentProfileLevelColor="currentProfileLevelColor"
						:topProfileName="topProfileName"
						:topProfileLevel="topProfileLevel"
						:lastJob="lastJob"
						:links="links"
						:data="data"
						:pdf="true"/>
				</section>

				<section>
					<top-extracted-skills :data="data"
						:improvement="improvement"
						:progress="progress"
						:type="type"
						:sortedProgress="sortedProgress"
						:logos="logos"
						:experienceProfile="experienceProfile"
						:technologyLevels="technologyLevels"
						:acommplishedReqs="includedItems"
						:userSkills="skills"
						:levelsData="levelsData"
						:styleLevels="styleLevels"
						:userCertifications="certifications"
						:userEducation="educationModules"
						:pdf="true"/>
				</section>
			
				<section><user-finished-tests-results :key="userID" :userID="userID" :pdf="true"/></section>
					
				<section><upskilling-profile :key="updateProfiles" :logos="logos" :styleLevels="styleLevels" :pdf="true"/></section>            
					
				<div class="html2pdf__page-break"></div> 
					
				<personal-data :data="data"
					:awards="awards"
					:certifications="certifications"
					:keywords="keywords"
					:links="links"
					:skills="skills"
					:education="education"
					:jobs="jobs"
					:languages="languages"
					:experience="experience"
					:pdf="true"/>
			</section>
		</vue-html2pdf>
		<section class="technologiesStyle col-sm-12 my-2 table-container" ref="pdf-content-landscape" style="background-color: white !important">
			<section><user-finished-tests-results :key="userID" :userID="userID" :pdf="true"/></section>
		</section>
		<h1></h1>
		<user-basic-information :name="data.name"
			:anonymized="data.anonymized"
			:token="data.chatbot_token"
			:userImage="data.image"
			:currentProfileLevelColor="currentProfileLevelColor"
			:topProfileName="topProfileName"
			:topProfileLevel="topProfileLevel"
			:lastJob="lastJob"
			:links="links"
			:data="data"
			:pdf="true"/>

		<template v-if="!userIsAdmin">
			<p v-if="data.chatbot_talk || definedPercentage == 100" class="alert alert-success alert-profile">
				You have fully completed your professional profile. Thank you for your time!
			</p>
			<p v-else class="alert alert-primary alert-profile alert-interview">
				<b>{{definedProfilePercentage}}.<br>
					<p v-if="!userIsAdmin">Please access our <a @click="redirectToChatbot()" style="text-decoration: underline;">
						Chatbot Interview</a> or the <a @click="redirectToForm()" style="text-decoration: underline;">form interview</a> to fill the missing data.
					</p>
				</b>
			</p>
		</template>

		<top-extracted-skills :data="data"
			:improvement="improvement"
			:progress="progress"
			:type="type"
			:sortedProgress="sortedProgress"
			:logos="logos"
			:experienceProfile="experienceProfile"
			:technologyLevels="technologyLevels"
			:acommplishedReqs="includedItems"
			:userSkills="skills"
			:levelsData="levelsData"
			:styleLevels="styleLevels"
			:userCertifications="certifications"
			:userEducation="educationModules"/>
	
		<soft-skills-results :key="cvID" :userName="data.name" :userID="userID" :cvID="cvID"/>

		<three-hundred-sixty-results :key="uniqueID" :userName="data.name" :userID="userID" :cvID="cvID"/>

		<user-finished-tests-results :key="userID" :userID="userID"/>

		<user-videoconferences-results :userID="userID" :userEmail="userEmail"/>

		<top-updating-profiles :key="updateProfiles" :logos="logos" :styleLevels="styleLevels"/>
		
		<upskilling-profile :key="updateProfiles" :logos="logos" :styleLevels="styleLevels"/>
		
		<personal-data :data="data"
			:awards="awards"
			:certifications="certifications"
			:keywords="keywords"
			:links="links"
			:skills="skills"
			:education="education"
			:jobs="jobs"
			:languages="languages"
			:experience="experience"/>
	</div>
</template>

<script>
	import ProfileAnalysisDataService from "@/services/ProfileAnalysisDataService";
	import ProfileConfigurationDataService from "@/services/ProfileConfigurationDataService";
	import UserBasicInformation from "@/components/AnalysisComponents/AnalysisResult/UserBasicInformation";
	import TopExtractedSkills from "@/components/AnalysisComponents/AnalysisResult/TopExtractedSkills";
	import PersonalData from "@/components/AnalysisComponents/AnalysisResult/PersonalData";
	import PersonalDataPDF from "@/components/AnalysisComponents/AnalysisResult/PersonalDataPDF";
	import TopUpdatingProfiles from "@/components/AnalysisComponents/AnalysisResult/TopUpdatingProfiles";
	import UpskillingProfile from "@/components/AnalysisComponents/AnalysisResult/UpskillingProfile";
	import UserFinishedTestsResults from "@/components/AnalysisComponents/AnalysisResult/UserFinishedTestsResults";
	import SoftSkillsResults from "@/components/AnalysisComponents/AnalysisResult/SoftSkillsResults";
	import ThreeHundredSixtyResults from "@/components/AnalysisComponents/AnalysisResult/ThreeHundredSixtyResults";
	import UserVideoconferencesResults from "@/components/AnalysisComponents/AnalysisResult/UserVideoconferencesResults";
    import VueHtml2pdf from 'vue-html2pdf'
	import moment from  'moment'

	export default {
	    name: "ProfileAnalysisComponent",
	    components: {
	    	UserBasicInformation,
	    	SoftSkillsResults,
	    	ThreeHundredSixtyResults,
	    	UserVideoconferencesResults,
	    	TopExtractedSkills,
	    	PersonalData,
			PersonalDataPDF,
	    	TopUpdatingProfiles,
			UpskillingProfile,
	    	UserFinishedTestsResults,
            VueHtml2pdf
	    },
	    data() {
		  	return {
                htmlToPdfOptions: {
                    margin: [15, 0, 25, 0],

					filename: `profile.pdf`,

					image: {
						type: 'jpeg', 
						quality: 1
					},

					enableLinks: true,

					html2canvas: {
						scale: 2,
						useCORS: true
					},

					jsPDF: {
						unit: 'mm',
						format: 'a4',
						orientation: 'portrait'
					}
                },
		  		data: {},
		  		awards: [],
		  		certifications: [],
		  		keywords: [],
		  		links: [],
		  		education: [],
		  		jobs: [],
		  		skills: {},
		  		experience: {},
		  		languages: [],
		  		improvement: {},
		  		includedItems: {},
		  		experienceProfile: {},
		  		uniqueID: "",
		  		progress: {},
		  		type: {},
		  		educationModules: [],
		  		sortedProgress: {},
		        message: "",
			  	successfull: false,
			  	updateProfiles: false,
			  	dataLoading: false,
			  	definedProfilePercentage: '',
			  	definedPercentage: 0,
			  	userID: null,
			  	cvID: null,
				userEmail: null,
				levelsData: [],
				styleLevels: {},
				technologiesProfilesWeights: [],
				logos: {},
				technologyLevels: {},
		  		topProfileName: "",
		  		topProfileLevel: "",
		  		currentProfileLevelColor: "",
		  		profileColors: {},
		  		lastJob: "",
		  		currentUrl: location.host
			}
		},
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
			userIsAdmin() {
				if (this.currentUser && this.currentUser.role) {
					return this.currentUser.role.name === 'admin';
				}
				return false;
			}
		},
		mounted() {
			const currentAreaID = this.$store.state.auth.user.active_areas[this.$session.get("tabID")];

			this.uniqueID = this._uid;

			this.dataLoading = true;

			this.getProfilesFromArea(currentAreaID);
		    this.getProfilesLevelsWeightsByArea(currentAreaID);
	  	},
		methods: {
            async beforeDownload ({ html2pdf, options, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
					const date = moment(String(new Date())).format('DD/MM/YYYY')
                    for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
						pdf.text('Informe de candidato: ' + this.data.name + ' (' + date + ')', (pdf.internal.pageSize.getWidth() * 0.1), (pdf.internal.pageSize.getHeight() - 10))
                        pdf.text('pág. ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.78), (pdf.internal.pageSize.getHeight() - 10))
                    } 
				}).save()
            },
            generatePDF(){
                var opt= {
                    margin: [5, 0, 10, 0],
                    filename: `profile.pdf`,
                    enableLinks: true,
                    image: {
                        type: 'jpeg', 
                        quality: 1
                    },
                    html2canvas: {
                        useCORS: true,
                        enableLinks: true,
                        scale: 2
                    },
                    jsPDF: {
                        format: 'a4',
                        orientation: 'portrait'
                    }
                }
                var opt2= {
                    filename: `profile.pdf`,
                    enableLinks: true,
                    image: {
                        type: 'jpeg', 
                        quality: 1
                    },
                    html2canvas: {
                        useCORS: true,
                        enableLinks: true,
                        scale: 2
                    },
                    jsPDF: {
                        format: 'a4',
                        orientation: 'landscape'
                    }
                }
                
                this.$refs.html2Pdf.generatePdf()
            },
			async fetchData() {
				await ProfileAnalysisDataService.findOne(this.$route.query.id).then(
					response => {
						if (response.status == 200) {
							try {
								this.data = response.data

								if (this.data.name.includes("Anonymous User")) this.data.anonymized = true
								else this.data.anonymized = false

								this.userID = this.data.id_user;
								this.cvID = parseInt(this.$route.query.id)
								this.userEmail = this.data.email

								if (this.data.image === undefined || this.data.image === '' || this.data.image.length == 0)
									this.data.image = "http://" + this.currentUrl + '/images/icons/user.png';

								let progressTmp = this.data.progress
								progressTmp = progressTmp.replaceAll("'", "\"");
								this.progress = JSON.parse(progressTmp)

								let typeTmp = this.data.type
								typeTmp = typeTmp.replaceAll("'", "\"");
								this.type = JSON.parse(typeTmp)
								
								var totalFields = 5
								var countDefinedFields = 0
								
								if (this.data.awards != '' && this.data.awards != null) {
									let awards = this.data.awards
									awards = awards.replaceAll("'", "\"");
									awards = JSON.parse(awards)
									this.awards = awards
								}
								
								if (this.data.certifications != '' && this.data.certifications != null) {
									let certifications = this.data.certifications
									certifications = certifications.replaceAll("'", "\"");
									certifications = JSON.parse(certifications)
									this.certifications = certifications

									this.certifications = this.certifications.map(name => name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

									countDefinedFields++;
								}
								
								if (this.data.keywords != '' && this.data.keywords != null) {
									let keywords = this.data.keywords
									keywords = keywords.replaceAll("'", "\"");
									keywords = JSON.parse(keywords)
									this.keywords = keywords
								}
								
								if (this.data.links != '' && this.data.links != null) {
									let links = this.data.links
									links = links.replaceAll("'", "\"");
									links = JSON.parse(links)
									this.links = links
								}

								if (this.data.skills != '' && this.data.skills != null) {
									let skills = this.data.skills
									skills = skills.replaceAll("'", "\"");
									skills = JSON.parse(skills)
									this.skills = skills

									Object.keys(this.skills).map((key, index) => {
										if (this.skills[key].length > 0)
											this.skills[key] = this.skills[key].map(item => item.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
										else
											this.skills[key] = []
									});
									
									countDefinedFields++;
								}

								if (this.data.education != '' && this.data.education != null) {
									let education = this.data.education
									education = education.replaceAll("'", "\"");
									education = JSON.parse(education)
									this.education = education
									let educationTypeTmp = ""
									this.education.forEach((education_item) =>{
										if (education_item.type_education_date != null)
											educationTypeTmp = education_item.type_education_date
										else
											educationTypeTmp = education_item.type_education

										var educationModule = educationTypeTmp.substring(
											educationTypeTmp.indexOf(",") + 1, 
											educationTypeTmp.lastIndexOf("(")
										);

										if (educationModule === "")
											educationModule = educationTypeTmp

										educationModule = educationModule.replace(',', '');
										educationModule = educationModule.replace("  ·", "");
										educationModule = educationModule.replace(" ·", "");

										this.educationModules.push(educationModule.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
									})
									countDefinedFields++;
								}
								
								if (this.data.experience_jobs != '' && this.data.experience_jobs != null) {
									let jobs = this.data.experience_jobs;
									jobs = jobs.replaceAll("'", "\"");
									jobs = JSON.parse(jobs)
									this.jobs = jobs

									if (this.jobs.length > 0) {
										this.lastJob = this.jobs[0].job + ", " + this.jobs[0].company
									}

									countDefinedFields++;
								}

								if (this.data.experience_time != '' && this.data.experience_time != null) {
									let experience = this.data.experience_time;

									experience = experience.replaceAll("'", "\"");
									experience = JSON.parse(experience)
									this.experience = experience
								}
								
								if (this.data.languages != '' && this.data.languages != null) {
									let languages = this.data.languages;
									languages = languages.replaceAll("'", "\"");
									languages = JSON.parse(languages)
									this.languages = languages
									countDefinedFields++;
								}
								
								if (this.data.improvement != '' && this.data.improvement != null) {
									let improvementTmp = this.data.improvement;
									improvementTmp = improvementTmp.replaceAll("'", "\"");
									this.improvement = JSON.parse(improvementTmp)
								}

								if (this.data.included_items != '' && this.data.included_items != null) {
									let includedItemsTmp = this.data.included_items;
									includedItemsTmp = includedItemsTmp.replaceAll("'", "\"");
									this.includedItems = JSON.parse(includedItemsTmp)
								}

								if (this.data.experience_by_profile != '' && this.data.experience_by_profile != null) {
									let experienceByProfileTmp = this.data.experience_by_profile;
									experienceByProfileTmp = experienceByProfileTmp.replaceAll("'", "\"");
									this.experienceProfile = JSON.parse(experienceByProfileTmp)
								}

								let sortedProgressTmp = Object.fromEntries(Object.entries(this.progress).sort((a, b) => a[1] - b[1]).reverse())
								this.sortedProgress = Object.fromEntries(Object.entries(sortedProgressTmp).slice(0, 5));

								this.topProfileName = Object.keys(this.sortedProgress)[0]
								this.topProfileLevel = this.type[this.topProfileName]
								this.topProfileLevel = this.topProfileLevel.charAt(0).toUpperCase() + this.topProfileLevel.slice(1);
								this.currentProfileLevelColor = this.profileColors[this.topProfileLevel.toLowerCase()]
								this.topProfileName = this.topProfileName.charAt(0).toUpperCase() + this.topProfileName.slice(1);

								let graphArrayData = []
								Object.entries(this.sortedProgress).forEach(([techKey, progressValue]) => {
									let graphElement = {}
									let tech = techKey

									graphElement['name'] = tech.charAt(0).toUpperCase() + tech.slice(1);
									graphElement['tech'] = tech
									graphElement['percent'] = progressValue
									graphElement['title'] = this.type[tech]

									let profileObj = this.technologiesProfilesWeights.find((profile) => profile.name.toLowerCase() == tech.toLowerCase());
									graphElement['img'] = this.$awsBucketRoute + profileObj.logo;

									graphArrayData.push(graphElement)
								});
								this.graphData = graphArrayData

								var definedPercentage = (countDefinedFields*100)/totalFields;
								this.definedPercentage = definedPercentage

								if (!this.userIsAdmin)
									this.definedProfilePercentage = definedPercentage.toFixed(2)+" % of your CV information is completed"
								else
									this.definedProfilePercentage = definedPercentage.toFixed(2)+" % of this CV information is completed"

								for (let profileWeight of this.technologiesProfilesWeights) {
									let techName = profileWeight.name.toLowerCase();
									this.technologyLevels[techName] = { 'trainee': {}, 'junior': {}, 'middle': {}, 'senior': {} };

									for (let profile of profileWeight.profile_levels) {
										let profName = profile.name.toLowerCase() 
										this.technologyLevels[techName][profName] = {}

										this.technologyLevels[techName][profName].skills = (profile.skills != "") ? JSON.parse(profile.skills) : []
										this.technologyLevels[techName][profName].education = (profile.education != "") ? JSON.parse(profile.education) : []
										this.technologyLevels[techName][profName].experience_time_max_years = JSON.parse(profile.experience_time_max_years)
										this.technologyLevels[techName][profName].experience_time_min_years = JSON.parse(profile.experience_time_min_years)
										this.technologyLevels[techName][profName].certifications = (profile.certifications != "") ? profile.certifications : []

										this.technologyLevels[techName][profName].skills.map((skill, index) => {
											Object.entries(this.technologyLevels[techName][profName].skills[index].items).map((item, itemIndex) => {
												this.technologyLevels[techName][profName].skills[index].items[itemIndex]['item'] = this.technologyLevels[techName][profName].skills[index].items[itemIndex]['item'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
											})
										});

										this.technologyLevels[techName][profName].education.map((education, index) => {
											this.technologyLevels[techName][profName].education[index]['level'] = this.technologyLevels[techName][profName].education[index]['level'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
										});

										this.technologyLevels[techName][profName].certifications.map((cert, index) => {
											this.technologyLevels[techName][profName].certifications[index]['name'] = this.technologyLevels[techName][profName].certifications[index]['name'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
										});
									}
								}

								this.dataLoading = false;
							} catch (e) {
								this.dataLoading = false;
								this.message = "There was an error while parsing the profiles analysis data. Please, contact your admin!"
							}
						}
					},
					error => {
						this.dataLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			async getProfilesFromArea(currentAreaID){
				await ProfileConfigurationDataService.findByArea(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.levelsData = response.data

							let afterWidth = 300;
							let marginLeft = 0;
							this.levelsData.forEach((profileLevel) => {
								this.profileColors[profileLevel.name.toLowerCase()] = profileLevel.color;
								this.styleLevels[profileLevel.name.toLowerCase()] = {
									liColor: profileLevel.color,
									liBgColor: profileLevel.color,
									liContent: parseInt(profileLevel.min_range),
									liAfterWidth: afterWidth + "%",
									liMarginLeft: marginLeft + "%"
								}
								marginLeft = 17;
								afterWidth = afterWidth + 40;
							});

							this.updateProfiles = this.updateProfiles ? false : true;
						}
					},
					error => {
						this.dataLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			async getProfilesLevelsWeightsByArea(currentAreaID) {
				await ProfileConfigurationDataService.getProfilesLevelsWeightsByArea(currentAreaID).then(
					response => {
						if (response.status == 200) {
							this.technologiesProfilesWeights = response.data
							this.technologiesProfilesWeights.forEach((profile) => {
								if (profile.logo !== undefined && profile.logo !== null) this.logos[profile.name.toLowerCase()] = profile.logo.includes(this.$awsBucketRoute) ? profile.logo : this.$awsBucketRoute + profile.logo
								else this.logos[profile.name.toLowerCase()] = ''
							});

							this.updateProfiles = this.updateProfiles ? false : true;
							this.fetchData();
						}
					},
					error => {
						this.dataLoading = false;
						console.error((error.response && error.response.data) ||
							error.message ||
							error.toString())
						
						if (error.response.status === 300) {
							console.log('Logout: Token expired')
							localStorage.removeItem('user');
							localStorage.removeItem('company');
							this.$store.state.auth.user = null
							this.$store.dispatch('auth/logout');
							this.$router.push('/login');
						}
					}
			    );
			},
			redirectToChatbot() {
	      		this.$router.replace({ name: "chat", params: {}, query: {token: this.data.chatbot_token} })
	      	},
	      	redirectToForm() {
	      		this.$router.replace({ name: "interview-form", params: {}, query: {token: this.data.chatbot_token} })
	      	},
			close() {
				document.getElementById("message").style.display = "none"
			}
		}
	};
</script>

<style scoped>
.profile-center-analysis {
	margin: 10px;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
}

::v-deep .layout-container{
	background-color: rgb(122, 122, 122) !important;
}

::v-deep .html2pdf__page-break {
	margin: 0 !important;
}
</style>