<template>
    <div class="row technology-skills-div mt-2 mx-4">
        <div class="row mt-3" v-if="isAdmin || isTM || isManager">
            <router-link v-b-tooltip.hover title="Go to ranking for BEI/360 Evaluation evaluated candidates" :to="{path: '/evaluation-360-ranking'}" class="btn btn-primary ranking-btn white-color align-item-center"><font-awesome-icon class="white-color" icon="trophy" /> BEI/360 Evaluation Ranking</router-link>
        </div>

        <div class="row mt-3 mb-2">
            <h4>Non-reusable BEI/360 Evaluations</h4>
        </div>

        <template v-if="evaluations.length >0">
            <div class="row" style="margin-top: 1%;">
                <div class="col-xl-2 col-lg-3">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="evaluationsList"
                    ></b-pagination>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <b-form-select class="numPerPage paginationSelectorTable" v-model="perPage" :options="pageOptions"></b-form-select>
                </div>
                <div class="col-xl-4 col-lg-3 search-input">
                    <b-form-input 
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        :placeholder="$t('others.search-placeholder-msg')"
                    ></b-form-input>
                </div>
            </div>
                
            <b-table ref="evaluationsTable"
                id="evaluationsList"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="headers" 
                :items="evaluations" 
                :filter="filter"
                select-mode="multi"
                responsive="sm">
                <template #cell(name)="data">
                    {{ data.item.name }}
                </template>
                <template #cell(role)="data">
                    {{ data.item.evaluation_evaluators[0].role.charAt(0).toUpperCase() + data.item.evaluation_evaluators[0].role.slice(1) }}
                </template>
                <template #cell(created_at)="data">
                    {{data.item.created_at | formatEnglishDate}}
                </template>
                <template #cell(options)="data">
                    <button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show candidate list" class="btn btn-secondary" @click="data.toggleDetails">
                        <font-awesome-icon icon="eye" />
                    </button>
                    <button v-else v-b-tooltip.hover title="Hide candidate list" class="btn btn-secondary" @click="data.toggleDetails">
                        <font-awesome-icon icon="eye-slash" />
                    </button>
                </template>
                <template #row-details="data">
                    <selection-users-evaluted-progress  :selectionUsers="data.item" :description="data.item.description" :evaluationProcessId="-1" />
                </template>
            </b-table>
        </template>
        <template v-else>
            <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
        </template>

        <div class="row mt-3 mb-2">
            <h4>BEI/360 Evaluations of Evaluation Processes</h4>
        </div>

        <template v-if="reusableEvaluations.length >0">
            <div class="row" style="margin-top: 1%;">
                <div class="col-xl-2 col-lg-3">
                    <b-pagination
                        v-model="currentPageReusable"
                        :total-rows="rowsReusable"
                        :per-page="perPageReusable"
                        aria-controls="evaluationsList"
                    ></b-pagination>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <b-form-select class="numPerPage paginationSelectorTable" v-model="perPageReusable" :options="pageOptions"></b-form-select>
                </div>
                <div class="col-xl-4 col-lg-3 search-input">
                    <b-form-input 
                        id="filter-input"
                        v-model="filterReusable"
                        type="search"
                        :placeholder="$t('others.search-placeholder-msg')"
                    ></b-form-input>
                </div>
            </div>
                
            <b-table ref="reusableEvaluationsTable"
                id="reusableEvaluationsList"
                :per-page="perPageReusable"
                :current-page="currentPageReusable"
                :fields="headersReusable" 
                :items="reusableEvaluations" 
                :filter="filterReusable"
                select-mode="multi"
                responsive="sm">
                <template #cell(name)="data">
                    {{ data.item.name }}
                </template>
                <template #cell(role)="data">
                    {{ data.item.evaluation.evaluation_evaluators[0].role.charAt(0).toUpperCase() + data.item.evaluation.evaluation_evaluators[0].role.slice(1) }}
                </template>
                <template #cell(created_at)="data">
                    {{data.item.created_at | formatEnglishDate}}
                </template>
                <template #cell(options)="data">
                    <button v-if="!data.detailsShowing" v-b-tooltip.hover title="Show candidate list" class="btn btn-secondary" @click="data.toggleDetails">
                        <font-awesome-icon icon="eye" />
                    </button>
                    <button v-else v-b-tooltip.hover title="Hide candidate list" class="btn btn-secondary" @click="data.toggleDetails">
                        <font-awesome-icon icon="eye-slash" />
                    </button>
                </template>
                <template #row-details="data">
                    <selection-users-evaluted-progress :selectionUsers="data.item.evaluation" :description="data.item.evaluation.description" :evaluationProcessId="data.item.id" />
                </template>
            </b-table>
        </template>
        <template v-else>
            <h3 class="no-results-msg"><font-awesome-icon icon="times-circle" class="black-color"/> No results found.</h3>
        </template>
    </div>
</template>

<script>
    import SoftskillsDataService from '@/services/SoftskillsDataService';
    import SelectionUsersEvalutedProgress from "@/components/SoftSkillsComponent/SelectionUsersEvalutedProgress";

	export default {
	  	name: 'EvaluationConfigurationComponent',
        data() {
		  	return {
                evaluations: [],
                reusableEvaluations: [],
                maxScore: 0,
                itemToDelete: '',
        	    selectedItems: [],
                selectedItem: null,
                selectedItemIndex: null,
                perPage: 10,
                currentPage: 1,
                filter: null,
                perPageReusable: 10,
                currentPageReusable: 1,
                filterReusable: null,
                pageOptions: this.$paginationOptions,
                successfull: false,
                message: "",
                headers: [
                    { key: 'name', label: 'Name', sortable: true, tdClass: 'tdMedium' },
                    { key: 'role', label: 'Your role', sortable: true, tdClass: 'tdMedium' },
                    { key: 'created_at', label: 'Date', sortable: true, tdClass: 'tdMedium' },
                    { key: 'options', label: 'Options', tdClass: 'tdMedium' }
                ],
                headersReusable: [
                    { key: 'name', label: 'Name', sortable: true, tdClass: 'tdMedium' },
                    { key: 'role', label: 'Your role', sortable: true, tdClass: 'tdMedium' },
                    { key: 'created_at', label: 'Date', sortable: true, tdClass: 'tdMedium' },
                    { key: 'options', label: 'Options', tdClass: 'tdMedium' }
                ]
	    	}
		},
		components: {
            SelectionUsersEvalutedProgress
		},
	    computed: {
            rows() {
                return this.evaluations.length
            },
            rowsReusable() {
                return this.reusableEvaluations.length
            },
			currentUser() {
				return this.$store.state.auth.user;
			},
			isAdmin() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'admin') {
						return true;
					}
				}
				return false;
			},
			isTM() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'talent_manager') {
						return true;
					}
				}
				return false;
			},
			isManager() {
				if (this.currentUser && this.currentUser.role) {
					if (this.currentUser.role.name === 'manager') {
						return true;
					}
				}
				return false;
			}
	    },
        mounted() {
			SoftskillsDataService.findEvaluationsForEvaluator(this.$store.state.auth.user.id).then(
                response => {
                    console.log('findEvaluationsForEvaluator: ', response.data)
                    this.evaluations = response.data.non_reusable_evaluations;
                    this.reusableEvaluations = response.data.position_selection_evaluations;
                },
                error => {
                    console.log((error.response && error.response.data) ||
                        error.message ||
                        error.toString())
                    
                    if (error.response.status === 300) {
                        console.log('Logout: Token expired')
                        localStorage.removeItem('user');
                        localStorage.removeItem('company');
                        this.$store.state.auth.user = null
                        this.$store.dispatch('auth/logout');
                        this.$router.push('/login');
                    }
                }
		    );
        },
		watch: {
		},
	};
</script>