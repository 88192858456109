<template>
	<div>
		<h4>Basic parameters</h4>
		<div class="row" v-for="(config) in configParams.basic" v-bind:key="config.title">
			<div class="col-sm-3">
				<p class="titulo_op">{{config.title}}</p>
			</div>
			<div class="form-group col-sm-1">
				<input type="number" id="value_input" min="0" ref="value_input" v-model="config.value" />
			</div>
		</div>

		<h4>Questions parameters</h4>
		<div class="row" v-for="(config) in configParams.questions" v-bind:key="config.title">
			<div class="col-sm-3">
				<p class="titulo_op">{{config.title}}</p>
			</div>
			<div class="form-group col-sm-1">
				<input type="number" id="value_input" min="0" ref="value_input" v-model="config.value" />
			</div>
		</div>

		<h4>Others</h4>
		<div class="row" v-for="(config) in configParams.others" v-bind:key="config.title">
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-auto">
						<p class="titulo_op">{{config.title}}</p>
					</div>
					<div class="col-sm-auto">
						<div class="form-check form-switch dichotomic-switch config-video-switch">
					       	<input class="form-check-input checkbox-required" type="checkbox" v-model="config.isSet" checked>
					    </div>
					</div>
				</div>
				
				<div class="row">
					<p class="align-left content_op">
			        	{{config.description}}
				    </p>
				    <template v-if="'allowSaveChats' in config && config.isSet">
		            	<b-form-group label="">
							<b-form-radio v-model="config.save.saveChatForAll" name="some-radios" value="all"> All</b-form-radio>
							<b-form-radio v-model="config.save.saveChatForAll" name="some-radios" value="hosts"> Only Hosts</b-form-radio>
					    </b-form-group>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  	name: 'MeetingsConfig',
	  	data() {
			return {
				configParams: {
					basic: [
						{ key: "tries_per_question", title: "Number of tries/retakes", value: 3 },
						{ key: "max_candidates_per_interview", title: "Max. number of candidates per interview", value: 5 }
					],
					questions: [
						{ key: "max_questions_per_interview", title: "Max. number of questions per interview", value: 5 },
						{ key: "time_to_display_questions", title: "Time to display questions (in seconds)", value: 30 },
						{ key: "time_to_answer_question", title: "Time to answer questions (in seconds)", value: 120 }
					],
					others: [
						{ key: "security_option", title: "Require all meetings to be protected with a security option", description: "Require all meetings to be protected with one of the following security options: an access code, waiting room or 'Only authenticated users can join meetings'. If no security option is enabled, all meetings will be protected with a waiting room.", isSet: false },
						{ key: "waiting_room_option", title: "Waiting room", description: "When participants join a meeting, place them in a waiting room and ask the host to admit them. a waiting room and ask the host to admit them individually. individually. Enable waiting room automatically disables the setting that allows participants to join before the host. join before the host.", isSet: true },
						{ key: "chat_autosave_option", title: "Response save", description: "Allow participants to save responses", isSet: false, allowSaveChats: false, save:
							{ key: "save_participants_responses", title:"Save participants response", description: "", isSet: false, saveChatForAll: "all" }
						},
					],
				}
			}
	    },
	    props: {
	    	areaConfig: Object
	    },
	    mounted() {
	    	this.configParams.basic[0].value = this.areaConfig.tries_per_question;
	    	this.configParams.basic[1].value = this.areaConfig.max_candidates_per_interview;

	    	this.configParams.questions[0].value = this.areaConfig.max_questions_per_interview;
	    	this.configParams.questions[1].value = this.areaConfig.time_to_display_questions;
	    	this.configParams.questions[2].value = this.areaConfig.time_to_answer_question;

	    	this.configParams.others[0].isSet = this.areaConfig.security_option;
	    	this.configParams.others[1].isSet = this.areaConfig.waiting_room_option;
	    	this.configParams.others[2].isSet = this.areaConfig.chat_autosave_option;
			this.configParams.others[2].save.saveChatForAll = this.areaConfig.save_participants_responses;

	    	this.$forceUpdate();
	    }
	};
</script>